import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";

import AtlasButton from "components/Button";
import CustomFormInput from "components/Input/formInput";
import Phone from "../components/Phone";

const From = ({
  isActive,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  handleAccordionChange,
  handleAccordionClose,
}) => {
  const [values, setValues] = useState({
    sender_email: "",
    sender_name: "",
  });

  useEffect(() => {
    if (isChecked) {
      setValues({
        sender_email: selectedEmailCampaign.sender_email,
        sender_name: selectedEmailCampaign.sender_name,
      });
    } else {
      setValues({
        sender_email: "",
        sender_name: "",
      });
    }
  }, [isActive]);

  const handleClose = () => {
    setValues({
      sender_email: "",
      sender_name: "",
    });

    handleAccordionClose();
  };

  const handleChange = (key, val) => {
    setValues((values) => ({ ...values, [key]: val }));
  };

  const handleSave = () => {
    const newEmailCampaign = {
      ...selectedEmailCampaign,
      sender_email: values.sender_email,
      sender_name: values.sender_name,
      status: 1,
    };

    onChangeEmailCampaignsHOC("selectedEmailCampaign", newEmailCampaign);
    updateEmailCampaign(newEmailCampaign);
    handleAccordionClose();
  };

  const isChecked = selectedEmailCampaign.sender_email;

  return (
    <ExpansionPanel
      square={true}
      expanded={isActive}
      className={"expansion-panel"}
    >
      <ExpansionPanelSummary
        classes={{
          root: "expansion-panel-summary",
          content: "expansion-panel-summary-content",
        }}
      >
        <div className={"expansion-panel-summary-wrapper"}>
          <FaCheckCircle
            className={`check-icon${isChecked ? " is-checked" : ""}`}
          />

          <div>
            <h4 className={"title mb-3"}>From</h4>

            {isChecked && (
              <p className={"expansion-panel-summary-text"}>
                <strong>{selectedEmailCampaign.sender_name}</strong>{" "}
                <span>&lt;{selectedEmailCampaign.sender_email}&gt;</span>
              </p>
            )}
            {!isChecked && (
              <small className={"text-muted"}>
                Who is sending this email campaign?
              </small>
            )}
          </div>

          {isActive && (
            <AtlasButton
              type={"button"}
              className={"close"}
              onClick={handleClose}
            >
              &times;
            </AtlasButton>
          )}
          {!isActive && (
            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={handleAccordionChange("from")}
              tooltip={true}
              tooltipID={`${isChecked ? "Edit" : "Add"} sender`}
              tooltipPosition={"top"}
              tooltipChildren={`${isChecked ? "Edit" : "Add"} sender`}
            >
              <span className={"button-text"}>
                {isChecked ? "Edit" : "Add"} sender
              </span>

              <BsPencilSquare className={"button-icon"} />
            </AtlasButton>
          )}
        </div>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={"expansion-panel-details__from"}>
        <div className={"d-flex flex-column justify-content-between"}>
          <div>
            <div className={"mb-3"}>
              <h2 className={"at-form-input__title text-capitalize"} required>
                Email Address
              </h2>

              <CustomFormInput
                type={"email"}
                value={values.sender_email}
                onChangeValue={(val) => handleChange("sender_email", val)}
                placeholder={"your.email@example.com"}
                required={true}
              />
            </div>

            <div>
              <h2 className={"at-form-input__title text-capitalize"} required>
                Name
              </h2>

              <CustomFormInput
                type={"text"}
                value={values.sender_name}
                onChangeValue={(val) => handleChange("sender_name", val)}
                placeholder={"Your name"}
                required={true}
              />
            </div>
          </div>

          <div className={"d-flex mb-4 g-3"}>
            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--success"}
              onClick={handleSave}
            >
              Save
            </AtlasButton>

            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={handleClose}
            >
              Cancel
            </AtlasButton>
          </div>
        </div>

        <div className={"position-relative text-center"}>
          <small className={"text-muted tiny-text"}>
            Actual email preview may vary depending on the email client.
          </small>

          <Phone
            selectedEmailCampaign={selectedEmailCampaign}
            values={values}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default From;
