import React, { useState } from "react";
import _ from "lodash";

import CustomTypeahead from "components/Typeahead/new";
import AtInput from "components/Input/formInput";
import CustomCard from "components/Card";
import CustomDialog from "components/Dialog";
import AtlasCloseButton from "components/Button/prev";
import CustomButton from "components/Button";
import { MdContentCopy } from "react-icons/md";
import AtlasSnackbar from "components/Snackbar";
import LoadingModal from "components/LoadingModal";
import { PulseLoader } from "react-spinners";

const GenerateFileName = ({
  projectList,
  onVideoTemplateLoad,

  onProjectListLoad,
  getProjectListing,
  onChangeVideoTemplateHOC,
  onClickClose,
}) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [unitType, setUnitType] = useState("");
  const [filename, setFileName] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);

  const onChangeTypeaheadSearch = _.debounce(
    (val) => onHandleTypeaheadSearch(val),
    1000,
  );

  const onHandleTypeaheadSearch = (val) => {
    setSelectedProject(null);

    return val.length > 0
      ? getProjectListing(val)
      : onChangeVideoTemplateHOC([], "projectList");
  };

  const copyFileName = () => {
    navigator.clipboard.writeText(filename);
    setShowSnackBar(true);
  };

  const generateFileName = () => {
    let tmp = unitType.replace(/ /g, "_");
    let reconstructName = `${selectedProject.id}-${selectedProject.slug}-${tmp}`;
    setFileName(reconstructName);
  };

  const renderCardContent = () => (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClickClose()} />
        <h4 className="at-card__title">Generate Template File Name</h4>
      </div>
      <div className={"row"}>
        <div className="col-md-6">
          <div className="d-flex">
            <strong className="mr-2 mb-2">Project</strong>
            <PulseLoader
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={onProjectListLoad}
            />
          </div>
          <CustomTypeahead
            filterBy={["internal_name"]}
            disabled={onProjectListLoad}
            typeaheadId={"project-list"}
            options={projectList ? projectList : []}
            selectedValue={selectedProject ? [selectedProject] : []}
            labelKey={"internal_name"}
            onSearch={(val) => onChangeTypeaheadSearch(val)}
            onSelect={(val) =>
              val && val.length > 0 && setSelectedProject(val[0])
            }
            childrenHead={(rowItem) => <p>{rowItem.internal_name}</p>}
            highlighterData={[
              (option) => `Project ID: ${option.id}`,
              <br />,
              (option) => `Slug: ${option.slug}`,
            ]}
          />
        </div>
        <div className="col-md-6 mt-2">
          <strong>Unit Type</strong>
          <AtInput
            value={unitType}
            onChangeValue={(value) => setUnitType(value)}
            disabled={false}
          />
        </div>
      </div>
      <div className="d-flex">
        <CustomButton
          className={"btn-new btn-new--success"}
          children={"Generate"}
          disabled={!selectedProject || !unitType}
          onClick={() => generateFileName()}
        />
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onClickClose()}
        >
          Cancel
        </button>
      </div>
      {filename && (
        <div className="d-flex align-items-center mt-2 ml-2">
          <strong>Generated Filename: {filename}</strong>
          <CustomButton
            containerStyle={{ marginLeft: 20 }}
            style={{
              color: "#FFFF",
              backgroundColor: "#F18E05",
              borderRadius: "50%",
              width: 35,
              height: 35,
            }}
            children={<MdContentCopy style={{ width: 20, height: 20 }} />}
            isIconButton={true}
            onClick={() => copyFileName()}
          />
        </div>
      )}
      <AtlasSnackbar
        open={showSnackBar}
        handleClose={() => setShowSnackBar(false)}
        message={"Filename copied into clipboard. You can now paste the data."}
      />
    </>
  );

  return (
    <CustomDialog open={true}>
      <CustomCard className={`h-100`} cardContent={renderCardContent()} />
      {onVideoTemplateLoad && <LoadingModal />}
    </CustomDialog>
  );
};

export default GenerateFileName;
