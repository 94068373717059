import { Card } from "@material-ui/core";
import { Component } from "react";
import { compose } from "redux";
import { ToastContainer } from "react-toastify";

import StepCounters from "./components/stepCounters";
import TeamInfo from "./components/teamInfo";
import PersonalInfo from "./components/personalInfo";
import Verification from "./components/verification";
import SupportButtonGroups from "components/Button/supportGroups";
import LoadingModal from "components/LoadingModal";
import ReferralError from "./components/referralError";

import VersionCheckHOC from "actions/versionCheck";
import AgentRegistrationHOC from "./action";
import BranchHOC from "./action/branch";
import CountryHOC from "./action/country";
import DirectLeaderHOC from "./action/directLeader";
import OtpTacHOC from "./action/otpTac";
import ResendVerificationHOC from "./action/resendVerification";

import { getTranslation } from "./assets";
import JuwaiIQILogo from "assets/images/juwai_iqi_logo.jpg";

import { clearItem } from "utils/tokenStore";

import "./index.scss";

class Register extends Component {
  componentDidMount = () => {
    clearItem("IQI_ATLAS_JWT_TOKEN");
    const referalCode = this.props.match.params.id;
    if (referalCode && referalCode !== "") {
      this.props.getReferalInfo(referalCode);
    }
    this.props.getCountrySelection();
  };

  componentDidUpdate = (pp) => {
    if (
      pp.bodyContentType !== this.props.bodyContentType &&
      this.props.bodyContentType === 0
    ) {
      this.props.onChangeOtpTacHOC("", "otpVerificationMessage");
      this.props.onChangeOtpTacHOC("", "tacVerificationMessage");
    }
  };

  renderBodyContent = () => {
    switch (this.props.bodyContentType) {
      case 0:
        return <TeamInfo {...this.props} />;
      case 1:
        return <PersonalInfo {...this.props} />;
      case 2:
        return (
          <Verification
            {...this.props}
            mobile_contact_number={
              this.props.createNewAgent.mobile_contact_number
            }
            email={this.props.createNewAgent.email}
          />
        );
      default:
        return null;
    }
  };

  render = () => {
    const language = this.props.createNewAgent.is_japan_team ? "jp" : "en";

    return (
      <>
        {!this.props.validReferralCode && <ReferralError />}
        {this.props.validReferralCode && (
          <>
            <Card
              classes={{ root: "register-container" }}
              style={{ padding: 30, boxShadow: "none" }}
            >
              <div className={"text-center"}>
                <img
                  src={JuwaiIQILogo}
                  alt={"JuwaiLogo"}
                  style={{ height: 200 }}
                />
                <span
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    display: "block",
                  }}
                >
                  {getTranslation("agent.signup.title", language)}
                </span>
              </div>
              <StepCounters bodyContentType={this.props.bodyContentType} language={language} />
              {this.renderBodyContent()}
            </Card>
            {(this.props.onLoadAgentRegister ||
              this.props.onLoadOtpTac ||
              this.props.onLoadCountry ||
              this.props.onLoadBranch) && <LoadingModal />}
            <SupportButtonGroups />

            <ToastContainer style={{ minWidth: 350, zIndex: 9999 }} />
          </>
        )}
      </>
    );
  };
}

export default compose(
  VersionCheckHOC,
  BranchHOC,
  CountryHOC,
  ResendVerificationHOC,
  OtpTacHOC,
  DirectLeaderHOC,
  AgentRegistrationHOC
)(Register);
