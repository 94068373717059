import React, { useState } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import { createTicketPricingLib } from "../assets";

const CreateTicketType = ({ onClose, createTicketType, onLoadTicketType }) => {
  const [ticketData, onChangeTicketData] = useState({
    title: "",
    remarks: "",
    type_id: "",
    is_seat_limited: "",
    maximum_seat: "",
    is_joint_ticket: "",
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(ticketData);
    temp[context] = val;

    onChangeTicketData(temp);
  };

  return (
    <>
      <ModalDialog
        title={"Create Ticket Type"}
        onClose={onClose}
        maxWidth={"md"}
        onLoad={onLoadTicketType}
        children={
          <div className={"form-row gy-3"}>
            {createTicketPricingLib.map((item) => (
              <>
                {item.type === "radio" && item.onShow(ticketData) && (
                  <div key={item.value} className={`col-md-6`}>
                    <label
                      style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}
                    >
                      {item.label}
                    </label>
                    <br />
                    <AtlasRadioGroup
                      horizontal={true}
                      checkedValue={ticketData[item.value]}
                      containerClassName={"w-100"}
                      selectedRadioValue={(val) =>
                        onChangeField(val, item.value)
                      }
                      options={item.options}
                    />
                  </div>
                )}
                {item.type !== "radio" && item.onShow(ticketData) && (
                  <div key={item.value} className={`col-md-6`}>
                    <label
                      style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}
                    >
                      {item.label}
                    </label>
                    <br />
                    <CustomFormInput
                      type={item.type}
                      required={item.value !== "remarks"}
                      value={ticketData[item.value]}
                      onChangeValue={(val) => onChangeField(val, item.value)}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        }
        footer={
          <div className="d-flex g-3">
            <CustomButton
              onClick={() => createTicketType(ticketData)}
              disabled={createTicketPricingLib.some((item) =>
                item.onDisabled(ticketData),
              )}
              className={"d-flex btn-new btn-new--success"}
              children={"Submit"}
            />
            <CustomButton
              onClick={onClose}
              className={"btn-new btn-new--outline-secondary"}
              children={"Cancel"}
            />
          </div>
        }
      />
    </>
  );
};

export default CreateTicketType;
