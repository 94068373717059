import React, { Component } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";

import "stylesheets/components/radio-group/index.scss";

class RadioGroup extends Component {
  render() {
    const {
      mode = "new",
      wrapperClass = "",
      containerClassName,
      componentClassName,
      footerClass = "",
      options,
      renderLabel,
      specialChecker,
      checkedValue,
      disabled,
      required,
      status,
      instructions,
      horizontal = true,
      mandatory,
      valueKey,
      labelKey,
    } = this.props;
    return (
      <div className={`${wrapperClass}`}>
        <div
          className={`at-radio-set position-relative ${containerClassName}`}
          style={{
            display: horizontal ? "inline-block" : "block",
          }}
        >
          {mode === "new" && (
            <div
              className={`d-flex flex-wrap g-2 ${horizontal ? "" : "flex-column"}`}
            >
              {options.map((item, index) => {
                const optionValue = valueKey ? item?.[valueKey] : item.value;
                const optionLabel = labelKey ? item?.[labelKey] : item.label;
                return (
                  <button
                    type={"button"}
                    key={`${optionValue}-${index}`}
                    disabled={
                      disabled || (item.onDisable && item?.onDisable(item))
                    }
                    className={`btn-radio ${checkedValue === optionValue ? "btn-radio-selected" : "btn-radio-outline"}`}
                    onClick={
                      !disabled
                        ? () => this.props.selectedRadioValue(optionValue)
                        : () => {}
                    }
                  >
                    {optionLabel}
                  </button>
                );
              })}
            </div>
          )}
          {mode === "old" &&
            options &&
            options.length > 0 &&
            options.map((item, index) => {
              const optionValue = valueKey ? item?.[valueKey] : item.value;
              const optionLabel = labelKey ? item?.[labelKey] : item.label;

              return (
                <div
                  key={`${item.value}-${index}`}
                  className={`
                    at-radio-container 
                    ${options.length - 1 !== index ? "mb-2" : ""} 
                    ${componentClassName || ""}
                  `}
                  style={{
                    display: horizontal ? "inline-block" : "block",
                    marginRight: horizontal ? 20 : "0",
                  }}
                >
                  <input
                    className="at-radio__input"
                    type="radio"
                    key={index}
                    id={optionValue}
                    value={optionValue}
                    checked={optionValue === checkedValue}
                    readOnly={true}
                  />
                  <label
                    className={`${disabled ? "at-radio__label-disabled" : "at-radio__label "}`}
                    onClick={
                      !disabled
                        ? () => this.props.selectedRadioValue(optionValue)
                        : () => {}
                    }
                  >
                    {specialChecker && specialChecker(item.uploaded)}
                    {!specialChecker && (
                      <div className="at-radio__checker"></div>
                    )}
                    <p className="at-radio__content">
                      {renderLabel && renderLabel(optionValue, optionLabel)}
                      {!renderLabel && optionLabel}
                      {mandatory &&
                        mandatory.length > 0 &&
                        _.includes(mandatory, optionValue) && (
                          <span className="at-radio__required">
                            {" "}
                            **Required
                          </span>
                        )}
                      {status && item.uploaded && (
                        <span className="at-radio__upload">
                          <AtlasIcon svgHref={"atlas-tick-circle"} />
                          {"Uploaded"}
                        </span>
                      )}
                    </p>
                  </label>
                </div>
              );
            })}
        </div>
        {mode === "new" && required && (
          <div
            className={`at-input_footer ${footerClass}`}
            style={ required ? { marginBottom: "1rem" } : {} }
          >
            <div className="at-autosuggest__required">required *</div>
          </div>
        )}
      </div>
    );
  }
}

export default RadioGroup;