import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";

import AtlasButton from "components/Button";
import AtlasCloseButton from "components/Button/close";
import CKEditor from "components/CKEditor";

const StatusTypes = [
  { name: "Draft", value: 1 },
  { name: "Published", value: 2 },
  { name: "Cancelled", value: 3 },
];

const EventTrainingForm = ({ onClose, confirmReject }) => {
  const [reject_reason, onChangeReject] = useState("");

  return (
    <>
      <AtlasCloseButton
        buttonStyle={{ marginLeft: "auto" }}
        containerStyle={{ marginBottom: 10 }}
        onClick={onClose}
      />
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <h2 className="at-card__title text-center">
            Reject the registration?
          </h2>
        </Grid>
        <Grid item xs={12}>
          <CKEditor
            className="at-custom_ckeditor-reject"
            placeholder={"Write your reject reason here ..."}
            disabled={false}
            hideToolbar={true}
            data={reject_reason}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChangeReject(data);
            }}
          />
        </Grid>
      </Grid>
      <div className="d-flex justify-content-center" style={{ marginTop: 40 }}>
        <AtlasButton
          disabled={_.isEmpty(reject_reason)}
          className={"at-btn at-btn--lg at-btn--success mr-2"}
          onClick={() => confirmReject(reject_reason)}
          children={"Yes"}
        />
        <AtlasButton
          type={"button"}
          className="at-btn at-btn--lg at-btn--danger ml-2"
          children={"No"}
          onClick={() => onClose()}
        />
      </div>
    </>
  );
};

export default EventTrainingForm;
