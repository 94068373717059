import { Paper, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";

import withEmailLists from "../actions/lists";

import { getColorBadge } from "dictionary/badgeColor";
import { statusOptions } from "../assets";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const Summary = ({
  selectedEmailCampaign,
  selectedEmailList,
  getSelectedEmailList,
}) => {
  const [emailLists, setEmailLists] = useState([]);

  const {
    id,
    status,
    subject,
    sender_name,
    sender_email,
    scheduled_at,
    email_list_ids,
    recipient_count,
    processed_count,
    deferred_count,
    delivered_count,
    dropped_count,
    bounced_count,
  } = selectedEmailCampaign;

  useEffect(() => {
    setEmailLists([]);
  }, []);

  useEffect(() => {
    if (email_list_ids && email_list_ids.length > 0) {
      email_list_ids.forEach(getSelectedEmailList);
    }
  }, [email_list_ids]);

  useEffect(() => {
    const { id, name } = selectedEmailList;

    if (
      selectedEmailList.hasOwnProperty("id") &&
      !emailLists.some((list) => list.id === id)
    ) {
      setEmailLists([...emailLists, { id, name }]);
    }
  }, [selectedEmailList, emailLists]);

  const getStatusProperty = (key) =>
    statusOptions.find((item) => item.value === status)?.[key];

  return (
    <Paper className={"p-4"} elevation={1}>
      <div className={"at-email-campaigns__preview-summary"}>
        <div className={"row"}>
          <div className={"col-md-3 mb-4 mb-md-0"}>
            <div className={"form-group"}>
              <label>
                <strong>Subject</strong>
              </label>
              <p>{subject}</p>
            </div>

            <div className={"form-group"}>
              <label>
                <strong>From</strong>
              </label>
              <p>{sender_name}</p>
              <p>{sender_email}</p>
            </div>

            <div className={"form-group"}>
              <label>
                <strong>Reply to</strong>
              </label>
              <p>{sender_email}</p>
            </div>

            <div className={"form-group"}>
              <label>
                <strong>Included Lists</strong>
              </label>

              {emailLists && emailLists.length > 0 && (
                <div className={"d-flex align-items-center"}>
                  <p>
                    {emailLists && emailLists.length} list
                    {emailLists && emailLists.length !== 1 ? "s" : ""}
                  </p>

                  <DarkTooltip
                    classes={{ tooltip: "tooltip-arrow top" }}
                    placement={"top"}
                    enterTouchDelay={50}
                    title={emailLists.map((list) => (
                      <p key={list.id}>{list.name}</p>
                    ))}
                  >
                    <FaInfoCircle
                      className={"ml-1"}
                      style={{ width: 21, height: 21, color: "#447DF7" }}
                    />
                  </DarkTooltip>
                </div>
              )}
              {emailLists && emailLists.length === 0 && <p>N/A</p>}
            </div>

            <div className={"form-group"}>
              <label>
                <strong>Delivered on</strong>
              </label>

              {scheduled_at && (
                <p>{moment(scheduled_at).format("dddd DD-MM-YYYY, HH:mm")}</p>
              )}
              {!scheduled_at && <p>N/A</p>}
            </div>

            <div className={"form-group mb-0"}>
              <label>
                <strong>Campaign Number</strong>
              </label>
              <p>{id}</p>
            </div>
          </div>

          <div className={"col-md-9"}>
            <div className={"form-row"}>
              <div className={"col-md-6"}>
                <div className={"card mb-4 p-2 text-center"}>
                  <div
                    className={"d-flex align-items-center mb-3"}
                    style={{ gap: 16 }}
                  >
                    <p>0%</p>

                    <div
                      className={"progress mb-0 flex-fill"}
                      style={{ height: 8 }}
                    >
                      <div
                        className={"progress-bar bg-primary"}
                        role={"progressbar"}
                        style={{
                          width: `${
                            recipient_count > 0
                              ? Math.round(
                                  (processed_count / recipient_count) * 100,
                                )
                              : 0
                          }%`,
                        }}
                      ></div>
                    </div>

                    <p>100%</p>
                  </div>

                  <div
                    className={"d-flex justify-content-center align-items-end"}
                  >
                    <h4 className={"text-primary mr-1"}>
                      <strong>{processed_count}</strong>
                    </h4>

                    <p className={"text-muted"}>
                      {recipient_count > 0
                        ? Math.round((processed_count / recipient_count) * 100)
                        : 0}
                      %
                    </p>
                  </div>

                  <small>Processed</small>
                </div>
              </div>

              <div className={"col-md-6"}>
                <div className={"card mb-4 p-2 text-center"}>
                  <div
                    className={"d-flex align-items-center mb-3"}
                    style={{ gap: 16 }}
                  >
                    <p>0%</p>

                    <div
                      className={"progress mb-0 flex-fill"}
                      style={{ height: 8 }}
                    >
                      <div
                        className={"progress-bar"}
                        role={"progressbar"}
                        style={{
                          width: `${
                            recipient_count > 0
                              ? Math.round(
                                  (deferred_count / recipient_count) * 100,
                                )
                              : 0
                          }%`,
                          backgroundColor: "#027A48",
                        }}
                      ></div>
                    </div>

                    <p>100%</p>
                  </div>

                  <div
                    className={"d-flex justify-content-center align-items-end"}
                  >
                    <h4 className={"mr-1"} style={{ color: "#027A48" }}>
                      <strong>{deferred_count}</strong>
                    </h4>

                    <p className={"text-muted"}>
                      {recipient_count > 0
                        ? Math.round((deferred_count / recipient_count) * 100)
                        : 0}
                      %
                    </p>
                  </div>

                  <small>Deferred</small>
                </div>
              </div>
            </div>

            <div>
              <div className={"d-flex align-items-center mb-2"}>
                <h5 className={"mr-3"}>Status</h5>
                <span
                  className={`at-status_badge`}
                  style={{ ...getColorBadge(getStatusProperty("colorName")) }}
                >
                  {getStatusProperty("label")}
                </span>
              </div>

              <div className={"form-row"}>
                <div className={"col-md-3 col-6 mb-3 mb-md-0"}>
                  <h5 className={"mb-0"}>
                    <strong>{recipient_count}</strong>
                  </h5>

                  <small>Recipients</small>
                </div>

                <div className={"col-md-3 col-6 mb-3 mb-md-0"}>
                  <div className={"d-flex align-items-end"}>
                    <h5 className={"text-primary mr-1"}>
                      <strong>{delivered_count}</strong>
                    </h5>

                    <small className={"text-muted"}>
                      {recipient_count > 0
                        ? Math.round((delivered_count / recipient_count) * 100)
                        : 0}
                      %
                    </small>
                  </div>

                  <small>Delivered</small>
                </div>

                <div className={"col-md-3 col-6"}>
                  <div className={"d-flex align-items-end"}>
                    <h5 className={"mr-1"} style={{ color: "#B54707" }}>
                      <strong>{dropped_count}</strong>
                    </h5>

                    <small className={"text-muted"}>
                      {recipient_count > 0
                        ? Math.round((dropped_count / recipient_count) * 100)
                        : 0}
                      %
                    </small>
                  </div>

                  <small>Dropped</small>
                </div>

                <div className={"col-md-3 col-6"}>
                  <div className={"d-flex align-items-end"}>
                    <h5 className={"text-danger mr-1"}>
                      <strong>{bounced_count}</strong>
                    </h5>

                    <small className={"text-muted"}>
                      {recipient_count > 0
                        ? Math.round((bounced_count / recipient_count) * 100)
                        : 0}
                      %
                    </small>
                  </div>

                  <small>Bounced</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default withEmailLists(Summary);
