import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import DatePicker from "react-datepicker";
import Moment from "moment";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import isEmptyValue from "utils/isEmpty";
import { createTicketPricingFields } from "../assets";

const CreateTicketPricing = ({ onLoad, onClose, createTicketPricing }) => {
  const [ticketPricingData, onChangeTicketPricing] = useState({
    title: "",
    price: "",
    start_date: "",
    end_date: "",
    allowed_join_ticket: "",
  });

  const onChangeField = (value, context) => {
    let temp = _.cloneDeep(ticketPricingData);
    temp[context] = value;
    onChangeTicketPricing(temp);
  };

  return (
    <ModalDialog
      title={"Create Ticket Pricing"}
      onLoad={onLoad}
      onClose={onClose}
      children={
        <>
          {createTicketPricingFields.map((item) => (
            <Grid key={item.id} container spacing={16} className={"mb-3"}>
              {item.type === "radio" && (
                <Grid item md={7} xs={12}>
                  <h4 className="at-form-input__title">{item.label}</h4>
                  <AtlasRadioGroup
                    horizontal={true}
                    checkedValue={ticketPricingData[item.value]}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => onChangeField(val, item.value)}
                    options={item.options}
                  />
                </Grid>
              )}
              {["text", "number"].indexOf(item.type) > -1 && (
                <Grid item md={7} xs={12}>
                  <h4 className="at-form-input__title">{item.label}</h4>
                  <CustomFormInput
                    type={item.type}
                    required={true}
                    value={ticketPricingData[item.value]}
                    onChangeValue={(val) => onChangeField(val, item.value)}
                  />
                </Grid>
              )}
              {item.type === "date" && (
                <Grid item md={7} xs={12}>
                  <h2 className="at-form-input__title">{item.label}</h2>
                  <div className="at-form-input">
                    <AtlasIcon
                      className={"at-form-input__calendar"}
                      svgHref={"atlas-calendar2"}
                    />
                    <DatePicker
                      className="at-form-input__input"
                      value={ticketPricingData[item.value]}
                      onChange={(value) =>
                        onChangeField(
                          Moment(value).format("DD MMM YYYY"),
                          item.value,
                        )
                      }
                      placeholderText={"DD MMM YYYY"}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          ))}
          <Grid container md={12} xs={12}>
            <CustomButton
              containerStyle={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
              onClick={() => createTicketPricing(ticketPricingData)}
              isIconButton={false}
              disabled={Object.values(ticketPricingData).some((value) =>
                isEmptyValue(value),
              )}
              className={"d-flex btn-new btn-new--success"}
              children={"Submit"}
              tooltip={false}
              tooltipChildren={"Submit"}
            />
            <CustomButton
              containerStyle={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
              onClick={onClose}
              isIconButton={false}
              className={"d-flex btn-new btn-new--outline-secondary ml-2"}
              children={"Cancel"}
              tooltip={false}
              tooltipChildren={"Cancel"}
            />
          </Grid>
        </>
      }
    />
  );
};

export default CreateTicketPricing;
