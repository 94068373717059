import React from "react";
import Moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import AtlasSelect from "components/Select/new";
import DatePicker from "react-datepicker";

import AtlasCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import AtlasAutosuggest from "components/Autosuggest";
import ModuleFormHeader from "components/Form/header";
import CustomTypeahead from "components/Typeahead/new";
import CustomFormInput from "components/Input/formInput";

import "stylesheets/containers/subsales-claims/index.scss";

const commission_types = [
  {
    value: 1,
    label: "Percentage",
  },
  {
    value: 2,
    label: "Amount",
  },
  {
    value: 3,
    label: "No commission sharing",
  },
];

const subsalesType = [
  { id: 1, label: "Sale", value: 1 },
  { id: 2, label: "Rental", value: 2 },
];

const TempCobrokeLetter = ({
  invoicing_branches,
  agencies,
  selected_agency,
  type_id,
  invoicing_branch_id,
  letter_date,
  property_address,
  sales_tax_percentage,
  commission_type_id,
  valid_from,
  valid_to,
  agent_commission,
  co_broker_commission,
  closed_editing,
  full_name,
  email,
  contact_number,
  ren_tag,
  use_display_name,
  show_professional_fee_percentage,
  professional_fee_percentage,
  onChange,

  storedAddresses,

  onLoadCobrokeLetter,
}) => {
  const conAgentInfo = [
    {
      title: "Full Name",
      label: "full_name",
      value: full_name,
    },
    {
      title: "Email",
      label: "email",
      value: email,
    },
    {
      title: "Contact Number",
      label: "contact_number",
      value: contact_number,
    },
    {
      title: "Ren Tag",
      label: "ren_tag",
      value: ren_tag,
    },
  ];

  return (
    <>
      <div className="at-form__content">
        <ModuleFormHeader
          title={"Letter Details"}
          className={"mb-3"}
          moduleIcon={"atlas-document-text"}
        />
        <div className="at-form_fields_cont">
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Select Agency</h2>
            <div>
              <CustomTypeahead
                typeaheadId={"agencies"}
                options={agencies}
                labelKey={"label"}
                filterBy={["label"]}
                onSelect={(val) => onChange("selected_agency", val)}
                selectedValue={selected_agency}
                childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                highlighterData={[
                  (option) =>
                    `Registration Number: ${option.registration_number} | Contact: ${option.phone_number}`,
                  <br />,
                  (option) => option.address,
                ]}
                disabled={closed_editing}
              />
            </div>
          </div>
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Type</h2>
            <CustomRadioGroup
              checkedValue={type_id}
              options={subsalesType}
              selectedRadioValue={(val) => onChange("type_id", val)}
              disabled={closed_editing}
            />
          </div>
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Select a branch</h2>
            <AtlasSelect
              value={invoicing_branch_id}
              onChange={(e) => onChange("invoicing_branch_id", e.target.value)}
              disabled={closed_editing}
            >
              {invoicing_branches &&
                invoicing_branches.length > 0 &&
                invoicing_branches.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </AtlasSelect>
          </div>
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Letter Date</h2>
            <div
              className={`at-form-input ${closed_editing ? "at-form-input__disabled" : ""}`}
            >
              <AtlasIcon
                className={"at-form-input__calendar"}
                svgHref={"atlas-calendar-input"}
              />
              <DatePicker
                className="at-form-input__input"
                value={letter_date}
                onChange={(val) =>
                  onChange("letter_date", Moment(val).format("DD MMM YYYY"))
                }
                placeholderText={"DD MMM YYYY"}
                disabled={closed_editing}
              />
            </div>
          </div>
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Property Address</h2>
            <AtlasAutosuggest
              placeholder={"Property Address"}
              inputStyle={{ borderColor: "#e0e0e0" }}
              value={property_address || ""}
              updateValue={(val) => onChange("property_address", val)}
              options={storedAddresses}
              disabled={closed_editing}
            />
          </div>
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Valid From</h2>
            <div className="d-flex">
              <div
                className={`at-form-input w-100 mb-0 ${closed_editing ? "at-form-input__disabled" : ""}`}
              >
                <AtlasIcon
                  className={"at-form-input__calendar"}
                  svgHref={"atlas-calendar-input"}
                />
                <DatePicker
                  className="at-form-input__input"
                  value={valid_from}
                  onChange={(val) =>
                    onChange("valid_from", Moment(val).format("DD MMM YYYY"))
                  }
                  placeholderText={"DD MMM YYYY"}
                  disabled={closed_editing}
                />
              </div>
              <button
                className="btn-new-sm btn-new--outline-secondary ml-2"
                onClick={() => onChange("valid_from", "")}
                disabled={closed_editing}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Commission Types</h2>
            <CustomRadioGroup
              checkedValue={commission_type_id}
              options={commission_types}
              selectedRadioValue={(val) => onChange("commission_type_id", val)}
              disabled={closed_editing}
            />
            {commission_type_id === 1 && (
              <div className={"at-form_comm_cont mt-3 text-left"} style={{ backgroundColor: '#f3f4f6' }}>
                <p>
                  shared on basis as below based on the full commission received
                  on the Rental/Sale price
                </p>
                <div className={"at-form_comm_breakdown_agent-cont"}>
                  <input
                    type={"number"}
                    className="agent-comm-input"
                    value={agent_commission}
                    onChange={(e) =>
                      onChange("agent_commission", e.target.value)
                    }
                    disabled={closed_editing}
                    placeholder={"0"}
                  />
                  % (Agent Commission)
                </div>
                <div className={"at-form_comm_breakdown_cobroke-cont"}>
                  <input
                    type={"number"}
                    className="agent-comm-input"
                    value={co_broker_commission}
                    onChange={(e) =>
                      onChange("co_broker_commission", e.target.value)
                    }
                    disabled={closed_editing}
                    placeholder={"0"}
                  />
                  % (Co-broke Commission)
                </div>
              </div>
            )}
            {commission_type_id === 2 && (
              <>
                <div
                  className={"at-form_comm_cont mt-3 text-left"}
                  style={{ backgroundColor: "#f3f4f6" }}
                >
                  <p>
                    paid RM{" "}
                    <input
                      type={"number"}
                      className="agency-fees-input ml-1"
                      value={co_broker_commission}
                      onChange={(e) =>
                        onChange("co_broker_commission", e.target.value)
                      }
                      disabled={closed_editing}
                    />{" "}
                    to your agency plus {sales_tax_percentage}% SST on the fee.
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="at-form_field-col-6">
            <h2 className="at-form-input__title">Valid To</h2>
            <div className="d-flex">
              <div
                className={`at-form-input w-100 mb-0 ${closed_editing ? "at-form-input__disabled" : ""}`}
              >
                <AtlasIcon
                  className={"at-form-input__calendar"}
                  svgHref={"atlas-calendar-input"}
                />
                <DatePicker
                  className="at-form-input__input mb-0"
                  value={valid_to}
                  onChange={(val) =>
                    onChange("valid_to", Moment(val).format("DD MMM YYYY"))
                  }
                  placeholderText={"DD MMM YYYY"}
                  disabled={closed_editing}
                />
              </div>
              <button
                className="btn-new-sm btn-new--outline-secondary ml-2"
                onClick={() => onChange("valid_to", "")}
                disabled={closed_editing}
              >
                Clear
              </button>
            </div>
          </div>
          {[1, 2].indexOf(commission_type_id) > -1 && (
            <div className="at-form_field-col-12 at-form_comm-desktop_col">
              {commission_type_id === 1 && (
                <div
                  className={"at-form_comm_cont-desktop text-left max-width-grey"}
                  style={{ backgroundColor: '#f3f4f6'}}
                >
                  <p>
                    shared on basis as below based on the full commission
                    received on the Rental/Sale price
                  </p>
                  <div className={"at-form_comm_breakdown_agent-cont"}>
                    <input
                      type={"number"}
                      className="agent-comm-input"
                      value={agent_commission}
                      onChange={(e) =>
                        onChange("agent_commission", e.target.value)
                      }
                      disabled={closed_editing}
                      placeholder={"0"}
                    />
                    % (Agent Commission)
                  </div>
                  <div className={"at-form_comm_breakdown_cobroke-cont"}>
                    <input
                      type={"number"}
                      className="agent-comm-input"
                      value={co_broker_commission}
                      onChange={(e) =>
                        onChange("co_broker_commission", e.target.value)
                      }
                      disabled={closed_editing}
                      placeholder={"0"}
                    />
                    % (Co-broke Commission)
                  </div>
                </div>
              )}
              {commission_type_id === 2 && (
                <div
                  className={
                    "at-form_comm_cont-desktop text-left max-width-grey"
                  }
                >
                  <p>
                    paid RM{" "}
                    <input
                      type={"number"}
                      className="agency-fees-input ml-1"
                      value={co_broker_commission}
                      onChange={(e) =>
                        onChange("co_broker_commission", e.target.value)
                      }
                      disabled={closed_editing}
                    />{" "}
                    to your agency plus {sales_tax_percentage}% SST on the fee.
                  </p>
                </div>
              )}
            </div>
          )}
          <div className="at-form_field-col-12 d-flex flex-column">
            <AtlasCheckbox
              content={
                "Use my display name in the letter ( Default is using full name )"
              }
              checked={use_display_name}
              disabled={closed_editing}
              onChangeCheckboxValue={(event) =>
                onChange("use_display_name", event.target.checked)
              }
            />
          </div>
          {type_id === 1 && commission_type_id !== 3 && (
            <div className="at-form_field-col-12 d-flex flex-column">
              <AtlasCheckbox
                labelStyle={{ alignItems: "center" }}
                disabled={closed_editing}
                checked={show_professional_fee_percentage}
                onChangeCheckboxValue={(event) =>
                  onChange(
                    "show_professional_fee_percentage",
                    event.target.checked,
                  )
                }
                content={
                  <>
                    Show Professional Fee Percentage
                    {show_professional_fee_percentage &&
                      commission_type_id !== 3 && (
                        <span className={"ml-3"}>
                          Valid to
                          <input
                            style={{ width: 50, maxWidth: 100 }}
                            type={"number"}
                            className="agency-fees-input"
                            value={`${professional_fee_percentage}`}
                            onChange={(e) =>
                              onChange(
                                "professional_fee_percentage",
                                parseFloat(e.target.value),
                              )
                            }
                            disabled={closed_editing}
                          />
                        </span>
                      )}
                  </>
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className="at-form__content" style={{ borderRadius: "0.375rem" }}>
        <ModuleFormHeader
          title={"Co-Agent Info"}
          className={"mb-3"}
          moduleIcon={"atlas-user-tie"}
        />
        <div className="at-form_fields_cont">
          {conAgentInfo.map((item) => {
            return (
              <div className="at-form_field-col-6">
                <h2 className="at-form-input__title">{item.title}</h2>
                <CustomFormInput
                  type="text"
                  value={item.value}
                  onChangeValue={(val) => onChange(item.label, val)}
                  disabled={closed_editing}
                />
              </div>
            );
          })}
        </div>
      </div>
      {onLoadCobrokeLetter && <LoadingModal />}
    </>
  );
};

export default TempCobrokeLetter;
