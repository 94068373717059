import { getItem } from "utils/tokenStore";

const token =
  window.location.href.indexOf("/admin-impersonate") > -1
    ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
    : getItem("IQI_ATLAS_JWT_TOKEN");

export const AdminRoutingData = [
  {
    name: "Dashboard",
    icon: "atlas-grid-5",
    navigateTo: "/dashboard/statistic",
    type: "menu",
  },
  {
    name: "User Management",
    icon: "atlas-user-management",
    children: [
      {
        id: 31,
        name: "Users",
        icon: "atlas-profile-circle",
        navigateTo: "/dashboard/admin/user-management",
      },
      {
        id: 45,
        name: "Permission Groups",
        icon: "atlas-key",
        navigateTo: "/dashboard/superadmin/permission-group-user",
      },
      {
        id: 10,
        name: "New Agent Approvals",
        icon: "atlas-profile-tick",
        navigateTo: "/dashboard/admin/agent-approval",
      },
      {
        name: "My Admin Roles",
        icon: "atlas-admin-roles",
        navigateTo: "/dashboard/admin/forms/admin-roles-management",
      },
      {
        id: 32,
        name: "OTP Verification",
        icon: "atlas-shield-tick",
        navigateTo: "/dashboard/admin/verification",
      },
      {
        id: 57,
        name: "License Renewal",
        icon: "atlas-personalcard",
        navigateTo: "/dashboard/admin/rea-renewal",
      },
      {
        id: 42,
        name: "Agreements",
        icon: "atlas-cobroke-centre",
        navigateTo: "/dashboard/admin/agreement-management",
      },
    ],
  },
  {
    name: "Branch Management",
    icon: "atlas-branch-management",
    children: [
      {
        id: 47,
        name: "QR Based Door Entry",
        icon: "atlas-qr-door",
        navigateTo: "/dashboard/admin/qr-door-entry",
      },
      {
        id: [59, 61],
        name: "Meeting Rooms",
        icon: "atlas-meeting-rooms",
        navigateTo: "/dashboard/admin/meeting-room",
      },
      {
        id: [58, 60],
        name: "Meeting Hub",
        icon: "atlas-people-linear",
        navigateTo: "/dashboard/admin/meeting-hub",
      },
    ],
  },
  {
    name: "Event Management",
    icon: "atlas-event-management",
    children: [
      {
        id: 63,
        name: "Events",
        icon: "atlas-calendar-circle",
        navigateTo: "/dashboard/admin/event-training",
      },
      {
        name: "IQI Calendar",
        icon: "atlas-calendar",
        navigateTo: "/dashboard/iqi-calendar",
      },
    ],
  },
  {
    name: "Subsales Management",
    icon: "atlas-subsales-managements",
    children: [
      {
        id: 14,
        name: "Listing Centre",
        icon: "atlas-building-3",
        navigateTo: "/dashboard/superadmin/subsales",
      },
      {
        id: 13,
        name: "Confirmation Forms Requests",
        icon: "atlas-note-add",
        navigateTo: "/dashboard/admin/forms/confirmation-forms-requests",
      },
      {
        id: 15,
        name: "Subsales Claims",
        icon: "atlas-subsales-claims",
        navigateTo: "/dashboard/admin/subsales-claims",
      },
      {
        id: 33,
        name: "Agent's Subsales Accesibility",
        icon: "atlas-lock-circle",
        navigateTo: "/dashboard/superadmin/subsales-accessibility",
      },
      {
        id: 34,
        name: "Co-Agencies",
        icon: "atlas-building",
        navigateTo: "/dashboard/admin/co-agency",
        type: "menu",
      },
      { id: 13, label: "FORMS" },
      {
        id: 13,
        name: "Appointment Letters",
        icon: "atlas-appointment-letter",
        navigateTo: "/dashboard/admin/forms/appointment-letter-management",
      },
      {
        id: 13,
        name: "Confirmation Forms",
        icon: "atlas-confirmation-form",
        navigateTo: "/dashboard/admin/forms/confirmation-forms-management",
      },
      {
        id: 13,
        name: "Co-broke Letters",
        icon: "atlas-cobroke-letter",
        navigateTo: "/dashboard/admin/forms/cobroke-letter-management",
      },
      {
        id: 13,
        name: "Authorisation Forms to Secure Property",
        icon: "atlas-authorisation-form",
        navigateTo: "/dashboard/admin/forms/authorisation-form-management",
      },
      {
        id: 13,
        name: "Other Forms",
        icon: "atlas-document-sketch",
        navigateTo: "/dashboard/admin/forms/paper-forms-management",
      },
    ],
  },
  {
    id: 2,
    name: "Global Network Management",
    icon: "atlas-global-network",
    children: [
      {
        name: "Global Network",
        icon: "atlas-global",
        navigateTo: "/dashboard/global-network",
      },
    ],
  },
  {
    name: "Marketing Centre",
    icon: "atlas-marketing-centre",
    children: [
      {
        id: 21,
        name: "Leads",
        icon: "atlas-leads",
        navigateTo: "/dashboard/admin/lead-management",
      },
      {
        id: 28,
        name: "Presentation Centre",
        icon: "atlas-presentation-chart",
        navigateTo: "/dashboard/admin/presentation-centre",
      },
      {
        id: 29,
        name: "Video Templates",
        icon: "atlas-video-vertical",
        navigateTo: "/dashboard/admin/video-template-management",
      },
      {
        id: 29,
        name: "Personalised Videos",
        icon: "atlas-video-add",
        navigateTo: "/dashboard/admin/personalised-video",
      },
      {
        id: 46,
        name: "Graphic Templates",
        icon: "atlas-gallery-add",
        navigateTo: "/dashboard/admin/graphic-studio-template",
      },
      {
        id: 47,
        name: "Graphic Studio",
        icon: "atlas-gallery-edit",
        navigateTo: "/dashboard/admin/graphic-studio",
      },
      {
        id: 48,
        name: "Email Templates",
        icon: "atlas-sms-tracking",
        navigateTo: "/dashboard/admin/email-templates",
      },
    ],
  },
  {
    name: "Atlas Store",
    icon: "atlas-bag-happy",
    children: [
      {
        id: 35,
        name: "Products",
        icon: "atlas-element-equal",
        navigateTo: "/dashboard/admin/product-management",
      },
      {
        id: 36,
        name: "Orders",
        icon: "atlas-bag-tick-2",
        navigateTo: "/dashboard/admin/order-management",
      },
      {
        id: 37,
        name: "Coupons",
        icon: "atlas-ticket-discount",
        navigateTo: "/dashboard/admin/coupon-management",
      },
      {
        id: 38,
        name: "Name Card Batches",
        icon: "atlas-personalcard",
        navigateTo: "/dashboard/admin/namecard-management",
      },
    ],
  },
  {
    name: "Project Management",
    icon: "atlas-project-management",
    children: [
      {
        id: 3,
        name: "Project Centre",
        icon: "atlas-building-4",
        navigateTo: "/dashboard/project-listing",
      },
      {
        id: 62,
        name: "Projects",
        icon: "atlas-projects",
        navigateTo: "/dashboard/admin/project-management",
      },
      {
        id: 6,
        name: "Project Sales",
        icon: "atlas-project-sales",
        navigateTo: "/dashboard/admin/sale-management",
      },
    ],
  },
  {
    name: "Training Management",
    icon: "atlas-training-management",
    children: [
      {
        id: 39,
        name: "Training Attendances",
        icon: "atlas-training-attendance",
        navigateTo: "/dashboard/admin/training-centre",
      },
      {
        id: 40,
        name: "Exam Results",
        icon: "atlas-exam-results",
        navigateTo: "/dashboard/admin/exam-management",
      },
      {
        id: 20,
        name: "Tutorials",
        icon: "atlas-bookmark-2",
        navigateTo: "/dashboard/admin/tutorials",
      },
    ],
  },
  {
    id: 55,
    name: "Analytics Centre",
    icon: "atlas-chart-3",
    children: [
      {
        name: "User Activities",
        icon: "atlas-chart-2",
        navigateTo: "/dashboard/admin/analytic-centre",
      },
      {
        name: "Admin Activities",
        icon: "atlas-chart-4",
        navigateTo: "/dashboard/admin/admin-activities",
      },
      {
        name: "Secondary Market",
        icon: "atlas-diagram",
        navigateTo: "/dashboard/analytic-centre",
      },
    ],
  },
  {
    id: 64,
    name: "Due Diligence Hub",
    icon: "atlas-amla-report",
    children: [
      {
        name: "Customer Due Diligence",
        icon: "atlas-user-search",
        navigateTo: "/dashboard/admin/cdd",
      },
      {
        name: "AMLA Report",
        icon: "atlas-clipboard-text",
        navigateTo: "/dashboard/admin/amla-report",
      },
    ],
  },
  {
    name: "Valuations",
    icon: "atlas-valuations",
    children: [
      {
        id: 27,
        name: "Real Estate/Corporate",
        icon: "atlas-valuations",
        navigateTo: "/dashboard/valuations/corporate",
      },
    ],
  },
  {
    name: "Dashboard Management",
    icon: "atlas-grid-edit",
    children: [
      {
        id: 41,
        name: "Newsfeeds",
        icon: "atlas-document-text",
        navigateTo: "/dashboard/admin/newsfeed-management",
      },
      {
        id: 43,
        name: "MOTD",
        icon: "atlas-messages-1",
        navigateTo: "/dashboard/admin/motd-management",
      },
    ],
  },
  {
    name: "App Management",
    icon: "atlas-mobile",
    children: [
      {
        id: 44,
        name: "App Themes",
        icon: "atlas-brush-4",
        navigateTo: "/dashboard/admin/app-theme-management",
      },
    ],
  },
  {
    name: "IQI Platforms",
    icon: "atlas-iqi-platforms",
    children: [
      {
        id: 17,
        name: "IQI Drive",
        icon: "atlas-folder",
        navigateTo: "/dashboard/iqi-drive/list",
      },
      {
        id: 18,
        name: "IQI Store",
        icon: "atlas-shopping-card",
        navigateTo: "",
        href: "https://store.iqiglobal.com/",
      },
      {
        id: 22,
        name: "IQI Academy",
        icon: "atlas-teacher",
        navigateTo: "",
        href: `https://academy.iqiglobal.com/atlas-academy-login?token=${token}`,
      },
      {
        name: `JIQI's Cafe`,
        icon: "atlas-jiqi-cafe",
        navigateTo: "/dashboard/jiqi-cafe",
      },
    ],
  },
];
