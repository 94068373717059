export const replaceJSX = (str, replacement) => {
  const result = [];
  const keys = Object.keys(replacement);
  const getRegExp = () => {
      const regexp = [];
      keys.forEach((key) => regexp.push(`{{${key}}}`));
      return new RegExp(regexp.join('|'));
  };
  str.split(getRegExp()).forEach((item, i) => {
      result.push(item, replacement[keys[i]]);
  });
  return result;
};