import { Fragment } from "react";
import _ from "lodash";
import { FormHelperText, FormLabel, MenuItem } from "@material-ui/core";
import { toast } from "react-toastify";
import { HiPlus } from "react-icons/hi";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import AtlasButton from "components/Button";
import AtSelectNew from "components/Select/new";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasAutosuggest from "components/Autosuggest";
import CustomFormInput from "components/Input/formInput";

import {
  personalDDFieldData,
  companyDDFieldData,
  declineFieldData,
} from "../assets/dueDiligence";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DueDiligenceContent = ({
  doc,
  disabled,
  newContact,
  selectedClaim,
  selectedContact,
  type,
  setDoc,
  onChangeNewContact,
  onChangeContactTypeHOC,
}) => {
  const due_diligence_fields = newContact.is_company
    ? companyDDFieldData
    : personalDDFieldData;
  const due_diligence_information = newContact.due_diligence_information || {
    due_diligence_type_id: 1,
    financing_method_id: "",
    signing_link: "",
    salary_range_id: "",
    employer_name: "",
    copies_info: {},
    transaction_purpose_id: "",
    nature_of_business: "",
    beneficial_owners: "",
    business_type_id: "",
    directors: [],
    shareholders: [],
    senior_management: "",
    declared_bankrupt: false,
    ssm_copy: {},
    supporting_document: {},
  };

  const onChangeFieldValue = (val, context) => {
    let temp = _.cloneDeep(due_diligence_information);
    temp[context] = val;
    onChangeNewContact("due_diligence_information", temp);
  };

  const onChangeSharePortion = (val, index) => {
    if (val <= 100) {
      let temp = _.cloneDeep(due_diligence_information);
      temp.shareholders[index]["percentage"] = val;
      onChangeNewContact("due_diligence_information", temp);
    }
  };

  const onChangeFile = (fileItem, value) => {
    const valueKey = value.replace("_url", "");
    if (fileItem.length > 0 && fileItem[0].file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          setDoc({
            ...doc,
            [valueKey]: {
              file_name: fileItem[0].file.name,
              file: e.target.result,
            },
          });
        };

        reader.readAsDataURL(fileItem[0].file);
      } catch (e) {
        console.log(e);
      }
    } else {
      setDoc({ ...doc, [valueKey]: {} });
    }
  };

  const renderField = (item) => (
    <div key={item.value} className={"grid-half-col"}>
      <h2 className="at-form-input__title">{item.title}</h2>
      {item.type === "select" && (
        <AtSelectNew
          disabled={disabled}
          value={due_diligence_information[item.value]}
          onChange={(e) => onChangeFieldValue(e.target.value, item.value)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {item.options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ fontSize: 14 }}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </AtSelectNew>
      )}
      {item.type === "document" && (
        <>
          {selectedClaim.case_closed &&
            selectedContact.copies_info[item.value] && (
              <div className="d-flex align-items-center">
                <FiCheckCircle
                  style={{
                    width: 20,
                    height: 20,
                    color: "green",
                    marginRight: 10,
                  }}
                />
                Attached
              </div>
            )}
          {selectedClaim.case_closed &&
            !selectedContact.copies_info[item.value] && (
              <div className="d-flex align-items-center">
                <FiXCircle
                  style={{
                    width: 20,
                    height: 20,
                    color: "red",
                    marginRight: 10,
                  }}
                />
                No Attachment
              </div>
            )}
          {!selectedClaim.case_closed &&
            selectedContact.copies_info[item.value] && (
              <div className="d-flex mt-10">
                <AtlasButton
                  type={"button"}
                  className={"btn-new btn-new--secondary mr-2"}
                  onClick={() =>
                    window.open(
                      selectedContact.copies_info[item.value],
                      "_blank",
                    )
                  }
                  children={"View Attachment"}
                />
                <AtlasButton
                  type={"button"}
                  className={"btn-new btn-new--danger"}
                  disabled={disabled}
                  onClick={() => {
                    let temp = _.cloneDeep(selectedContact);
                    temp.copies_info[item.value] = "";
                    onChangeContactTypeHOC(temp, "selectedContact");
                  }}
                  children={"Remove Attachment"}
                />
              </div>
            )}
          {!selectedClaim.case_closed &&
            !due_diligence_information.copies_info[item.value] && (
              <>
                <FilePond
                  disabled={disabled}
                  allowMultiple={false}
                  acceptedFileTypes={["image/*", "application/pdf"]}
                  labelIdle={`Click here to upload ${item.title}`}
                  fullWidth
                  maxFiles={1}
                  onupdatefiles={(fileItems) =>
                    onChangeFile(fileItems, item.value)
                  }
                />
                <FormHelperText>
                  Accepted file types: jpg, jpeg, png, pdf.
                </FormHelperText>
                {item.helpText && (
                  <FormHelperText>{item.helpText}</FormHelperText>
                )}
              </>
            )}
        </>
      )}
      {item.type === "autosuggest" && (
        <AtlasAutosuggest
          disabled={disabled}
          placeholder={item.placeholder || item.title}
          value={due_diligence_information[item.value] || ""}
          updateValue={(val) => onChangeFieldValue(val, item.value)}
          options={item.options || []}
        />
      )}
      {item.type === "radio" && (
        <AtlasRadioGroup
          disabled={disabled}
          horizontal={true}
          options={item.options}
          checkedValue={due_diligence_information[item.value]}
          selectedRadioValue={(val) => onChangeFieldValue(val, item.value)}
        />
      )}
      {!["select", "document", "autosuggest", "radio"].includes(item.type) && (
        <CustomFormInput
          type={"text"}
          className={"mb-0"}
          disabled={disabled}
          value={due_diligence_information[item.value]}
          onChangeValue={(val) => {
            let temp = "";

            switch (item.type) {
              case "alphanumeric":
                temp = val.replace(/[^$0-9a-zA-Z]/g, "");
                break;

              case "number":
                temp = val.replace(/[^$0-9]/g, "");
                break;

              default:
                temp = val;
                break;
            }
            onChangeFieldValue(temp, item.value);
          }}
          inputError={""}
          placeholder={item.placeholder}
        />
      )}
    </div>
  );

  return (
    <>
      <p className="d-flex at-card__description mb-2 fs-2">
        The Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of
        Unlawful Activities Act 2001 requires us to collect certain information
        to aid in countering money laundering and other related activities.
        Parties to a transaction are required to provide this information which
        you should record accurately and securely here. <br />
        For further information about the requirements for Customer Due
        Diligence, please refer to this page from Bank Negara. You can also send
        this link to your clients.
      </p>
      <div className="d-flex my-3">
        <button
          className={
            "btn-new btn-new--secondary at-due-diligence__description-link"
          }
          onClick={() =>
            window.open("https://amlcft.bnm.gov.my/customer-due-diligence")
          }
        >
          <AtlasIcon svgHref={"atlas-link-2-linear"} />
          Learn About CDD Guidelines
        </button>
        <button
          className={
            "btn-new btn-new--outline-secondary at-due-diligence__description-link"
          }
          onClick={() => {
            navigator.clipboard.writeText(
              "https://amlcft.bnm.gov.my/customer-due-diligence",
            );
            toast.warn("Copied to Clipboard");
          }}
        >
          <AtlasIcon style={{ color: "#3C82F6" }} svgHref={"atlas-copy-bold"} />
          Copy Link
        </button>
      </div>
      {!newContact.is_company && (
        <CustomCheckbox
          disabled={disabled}
          labelClassname={"ml-0 w-100 mb-3"}
          content={
            <span className="fw-500">
              Check this box if the customer declined to share information
            </span>
          }
          checked={
            due_diligence_information &&
            due_diligence_information.due_diligence_type_id === 2
          }
          onChangeCheckboxValue={() =>
            onChangeFieldValue(
              due_diligence_information.due_diligence_type_id === 2 ? 1 : 2,
              "due_diligence_type_id",
            )
          }
        />
      )}
      {Boolean(selectedClaim.external_agency_id) &&
        ((["Vendor", "Landlord", "Owner"].indexOf(type) > -1 && selectedClaim.external_agency_representative_id === 1) ||
          (["Purchaser", "Tenant", "Buyer"].indexOf(type) > -1 && selectedClaim.external_agency_representative_id === 2)) && (
          <CustomCheckbox
            disabled={disabled}
            labelClassname={"ml-0 w-100 mb-3"}
            content={
              <span className="fw-500">
                Check this box if the party is represented by a Co-Agency
              </span>
            }
            checked={
              due_diligence_information &&
              due_diligence_information.due_diligence_type_id === 3
            }
            onChangeCheckboxValue={() =>
              onChangeFieldValue(
                due_diligence_information.due_diligence_type_id === 3 ? 1 : 3,
                "due_diligence_type_id",
              )
            }
          />
        )}
      {due_diligence_information.due_diligence_type_id === 2 && (
        <form className="grid-control">
          {declineFieldData.map((item) => renderField(item))}
        </form>
      )}
      {due_diligence_information.due_diligence_type_id === 1 && (
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          {due_diligence_fields
            .filter(
              (item) =>
                item.value !== "financing_method_id" ||
                selectedContact.type_id === 2,
            )
            .map((item, index) => (
              <Fragment key={index}>
                {item.value === "directors" && (
                  <div key={item.value} className={"col-12 mb-3"}>
                    <div className="d-flex align-items-center">
                      <h2 className="at-form-input__title">{item.title}</h2>
                      <AtlasButton
                        type={"button"}
                        children={
                          <HiPlus
                            className="due-diligence__form-btn"
                            style={{ background: "#F0631D" }}
                          />
                        }
                        tooltip={true}
                        disabled={disabled}
                        tooltipID={`due-diligence-add-director`}
                        tooltipPosition={"top"}
                        tooltipChildren={"Add Director"}
                        onClick={() => {
                          let temp = _.cloneDeep(due_diligence_information);
                          if (!temp.directors) {
                            temp.directors = [];
                          }
                          temp.directors.push("");
                          Promise.all([
                            onChangeNewContact(
                              "due_diligence_information",
                              temp,
                            ),
                          ]).then(() => {
                            let focusElement = document.getElementById(
                              `due_diligence_director-${temp.directors.length - 1}`,
                            );
                            focusElement.querySelector("input").focus();
                          });
                        }}
                      />
                    </div>
                    {due_diligence_information.directors &&
                      due_diligence_information.directors.map(
                        (director, index) => {
                          return (
                            <div
                              className="due-diligence__form-container d-flex align-items-center flex-wrap g-3"
                              id={`due_diligence_director-${index}`}
                            >
                              <CustomFormInput
                                type={"text"}
                                containerClass="col-md-6 mb-0"
                                placeholder="Type something"
                                disabled={disabled}
                                value={director}
                                onChangeValue={(val) => {
                                  let temp = _.cloneDeep(
                                    due_diligence_information,
                                  );
                                  temp.directors[index] = val;
                                  onChangeNewContact(
                                    "due_diligence_information",
                                    temp,
                                  );
                                }}
                              />
                              <AtlasButton
                                className="btn-new btn-new--danger"
                                children={"Delete"}
                                disabled={disabled}
                                tooltip={true}
                                tooltipID={`due-diligence-remove-director-${index}`}
                                tooltipPosition={"top"}
                                tooltipChildren={"Delete Director"}
                                onClick={() => {
                                  if (
                                    due_diligence_information.directors.length >
                                    0
                                  ) {
                                    let temp = _.cloneDeep(
                                      due_diligence_information,
                                    );
                                    temp.directors.splice(index, 1);
                                    onChangeNewContact(
                                      "due_diligence_information",
                                      temp,
                                    );
                                  }
                                }}
                              />
                            </div>
                          );
                        },
                      )}
                  </div>
                )}
                {item.value === "shareholders" && (
                  <div key={item.value} className={"col-12 mb-3"}>
                    <div className="d-flex align-items-center">
                      <h2 className="at-form-input__title">{item.title}</h2>
                      <AtlasButton
                        type={"button"}
                        children={
                          <HiPlus
                            className="due-diligence__form-btn"
                            style={{ background: "#F0631D" }}
                          />
                        }
                        tooltip={true}
                        disabled={disabled}
                        tooltipID={`due-diligence-add-director`}
                        tooltipPosition={"top"}
                        tooltipChildren={"Add Director"}
                        onClick={() => {
                          let temp = _.cloneDeep(due_diligence_information);
                          if (!temp.shareholders) {
                            temp.shareholders = [];
                          }
                          temp.shareholders.push({
                            name: "",
                            type: "",
                            percentage: "",
                          });
                          Promise.all([
                            onChangeNewContact(
                              "due_diligence_information",
                              temp,
                            ),
                          ]).then(() => {
                            let focusElement = document.getElementById(
                              `due_diligence_shareholder-${temp.shareholders.length - 1}`,
                            );
                            focusElement
                              .querySelector(".create-participant_form-input")
                              .firstChild.focus();
                          });
                        }}
                      />
                    </div>
                    {due_diligence_information.shareholders &&
                      due_diligence_information.shareholders.map(
                        (shareholder, index) => {
                          return (
                            <div
                              className="due-diligence__form-container"
                              id={`due_diligence_shareholder-${index}`}
                            >
                              <div className="at-form_fields_cont">
                                <div className="at-form_field-col-6">
                                  <FormLabel className="at-form-input__title fw-400">
                                    Shareholder Name
                                  </FormLabel>
                                  <CustomFormInput
                                    type={"text"}
                                    containerClass="col-md-6"
                                    disabled={disabled}
                                    className={"create-participant_form-input"}
                                    value={shareholder.name}
                                    onChangeValue={(val) => {
                                      let temp = _.cloneDeep(
                                        due_diligence_information,
                                      );
                                      temp.shareholders[index]["name"] = val;
                                      onChangeNewContact(
                                        "due_diligence_information",
                                        temp,
                                      );
                                    }}
                                  />
                                </div>
                                <div className="at-form_field-col-4">
                                  <FormLabel className="at-form-input__title fw-400">
                                    Type of Share
                                  </FormLabel>
                                  <AtlasRadioGroup
                                    horizontal={true}
                                    disabled={disabled}
                                    checkedValue={shareholder.type}
                                    containerClassName={"w-100"}
                                    selectedRadioValue={(val) => {
                                      let temp = _.cloneDeep(
                                        due_diligence_information,
                                      );
                                      temp.shareholders[index]["type"] = val;
                                      onChangeNewContact(
                                        "due_diligence_information",
                                        temp,
                                      );
                                    }}
                                    options={[
                                      {
                                        value: "Preference Shares",
                                        label: "Preference Shares",
                                      },
                                      {
                                        value: "Ordinary Shares",
                                        label: "Ordinary Shares",
                                      },
                                    ]}
                                  />
                                </div>
                                <div className="at-form_field-col-2">
                                  <FormLabel className="at-form-input__title fw-400">
                                    Percentage %
                                  </FormLabel>
                                  <CustomFormInput
                                    type={"number"}
                                    disabled={disabled}
                                    value={shareholder.percentage}
                                    onChangeValue={(val) =>
                                      onChangeSharePortion(val, index)
                                    }
                                  />
                                </div>
                              </div>
                              <AtlasButton
                                className="btn-new btn-new--danger mt-10"
                                children={"Delete"}
                                disabled={disabled}
                                tooltip={true}
                                tooltipID={`due-diligence-remove-shareholder-${index}`}
                                tooltipPosition={"top"}
                                tooltipChildren={"Delete Shareholder"}
                                onClick={() => {
                                  if (
                                    due_diligence_information.shareholders
                                      .length > 0
                                  ) {
                                    let temp = _.cloneDeep(
                                      due_diligence_information,
                                    );
                                    temp.shareholders.splice(index, 1);
                                    onChangeNewContact(
                                      "due_diligence_information",
                                      temp,
                                    );
                                  }
                                }}
                              />
                            </div>
                          );
                        },
                      )}
                  </div>
                )}
                {item.value !== "directors" &&
                  item.value !== "shareholders" &&
                  renderField(item)}
              </Fragment>
            ))}
        </form>
      )}
    </>
  );
};

export default DueDiligenceContent;
