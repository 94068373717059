export const getTranslation = (key, language) => {
  const translation = japaneseTranslation.find((translation) => translation.key === key);
  if (translation) {
    return translation[language || 'en'];
  }
  return key;
}

export const japaneseTranslation = [
  {
    key: "required",
    en: "required *",
    jp: "*必须",
  }
];