import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";
import CustomRadioGroup from "components/RadioGroup";

const formData = [
  { label: "Agency Name", value: "name", col: 6, type: "text" },
  {
    label: "Board Registration No.",
    value: "registration_number",
    col: 6,
    type: "text",
  },
  { label: "Address", value: "address", col: 12, type: "textarea" },
  { label: "Phone Number", value: "phone_number", col: 6, type: "text" },
  { label: "Fax No.", value: "fax_number", col: 6, type: "text" },
  { label: "Email", value: "email", col: 6, type: "text" },
  { label: "ERP ID", value: "reference_id", col: 6, type: "text" },
];

const VisibleOptions = status_id => status_id === 1
  ? [
    { value: 1, label: "Pending" }
  ]
  : [
    { value: 2, label: "Active" },
    { value: 3, label: "Inactive" }
  ]

const SstRegistered = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

const EditForm = ({
  permissionsChecker,
  selectedAgency,
  onLoadCoAgency,

  updateAgency,
  onClose,
}) => {
  const [editForm, onChangeForm] = useState({
    address: "",
    email: "",
    fax_number: "",
    name: "",
    phone_number: "",
    registration_number: "",
    status_id: "",
    sst_registered: ""
  });
  const { can_update } = permissionsChecker;

  useEffect(() => {
    selectedAgency && onChangeForm(selectedAgency);
  }, [selectedAgency]);

  const onChangeField = useCallback(
    (val, context) => {
      let temp = _.cloneDeep(editForm);
      temp[context] = val;

      onChangeForm(temp);
    },
    [editForm],
  );

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={onClose} />
        <h4 className="at-card__title">Edit Agency</h4>
      </div>
      <Grid container spacing={16}>
        {formData.map((item) => (
          <Grid item md={item.col} xs={12}>
            <label className="at-form-label">{item.label}</label>
            <CustomFormInput
              type={item.type}
              disabled={!can_update}
              value={editForm[item.value]}
              onChangeValue={(val) => onChangeField(val, item.value)}
            />
          </Grid>
        ))}
        <Grid item md={6} xs={12}>
          <label className="at-form-label">Status</label>
          <CustomRadioGroup
            checkedValue={editForm.status_id}
            horizontal={true}
            options={VisibleOptions(editForm.status_id)}
            selectedRadioValue={(val) => onChangeField(val, "status_id")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <label className="at-form-label">SST Registered?</label>
          <p className="at-radio__content">{editForm.sst_registered ? "Yes" : "No"}</p>
        </Grid>
      </Grid>
      <div className="d-flex mt-4">
        {can_update && (
          <button
            type={"button"}
            className="btn-new btn-new--success mr-2"
            onClick={() => updateAgency(editForm)}>
            Update
          </button>
        )}
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={onClose}
        >
          Close
        </button>
      </div>
      {onLoadCoAgency && <LoadingModal />}
    </>
  );
};

export default EditForm;
