import { useState } from "react";
import { MarvelDevices } from "react-css-devices";
import Frame from "react-frame-component";
import ReactHtmlParser from "react-html-parser";
import { BiDesktop } from "react-icons/bi";
import { BsChevronRight, BsPencilSquare } from "react-icons/bs";
import { FiRotateCcw, FiSend } from "react-icons/fi";
import { GiSmartphone } from "react-icons/gi";
import { connect } from "react-redux";
import { CardActions, CardContent } from "@material-ui/core";

import AtlasButton from "components/Button";
import Switch from "components/Button/toggle";
import AtlasEmailModal from "components/Modal/email";

import withSendTestEmail from "actions/sendTestEmail";

const EmailPreview = ({
  email,
  handleClose,
  selectedEmailTemplate,
  toggleEmailPreview,
  toggleSetup,
  onLoadSendTestEmail,
  showEmailDialog,
  onChangeSendTestEmailHOC,
  sendTestEmail,
}) => {
  const [view, setView] = useState("desktop");
  const [orientation, setOrientation] = useState("portrait");

  const { html_code, subject } = selectedEmailTemplate;

  const handleRotate = () => {
    setOrientation(orientation === "portrait" ? "landscape" : "portrait");
  };

  const toggleView = () => {
    setView(view === "desktop" ? "mobile" : "desktop");
  };

  return (
    <>
      <nav className={"breadcrumbs"}>
        <AtlasButton
          type={"button"}
          className={"breadcrumb-item"}
          onClick={toggleSetup}
        >
          Back: Setup
        </AtlasButton>

        <BsChevronRight className={"icon"} />

        <p className={"breadcrumb-item semi-bold-breadcrumb-item"}>Design</p>
      </nav>

      <CardContent className={"at-email-templates__form__card-content"}>
        <div
          className={"d-flex justify-content-between align-items-center mb-3"}
        >
          <div className={"d-flex align-items-center"}>
            <div className={"switch-wrapper"}>
              <BiDesktop className={"desktop-icon"} />

              <Switch
                className={"desktop-switch"}
                currentState={view === "desktop"}
                onToggleButton={toggleView}
              />

              <GiSmartphone className={"phone-icon"} />
            </div>

            <div className={"button-group"}>
              {view === "mobile" && (
                <AtlasButton
                  type={"button"}
                  className={"btn-new btn-new--outline-secondary rotate-button"}
                  onClick={handleRotate}
                >
                  <FiRotateCcw className={"rotate-icon"} />
                  Rotate
                </AtlasButton>
              )}

              <AtlasButton
                type={"button"}
                className={"btn-new btn-new--outline-secondary"}
                onClick={() =>
                  onChangeSendTestEmailHOC("showEmailDialog", true)
                }
              >
                Send a test
              </AtlasButton>
            </div>
          </div>

          <AtlasButton
            type={"button"}
            className={
              "btn-new btn-new--outline-secondary edit-email-content-button"
            }
            onClick={toggleEmailPreview}
          >
            Edit the email content
          </AtlasButton>

          <div className={"mobile-button-group"}>
            {view === "mobile" && (
              <AtlasButton
                type={"button"}
                className={"btn-new btn-new--outline-secondary"}
                onClick={handleRotate}
                tooltip={true}
                tooltipID={"rotate"}
                tooltipPosition={"top"}
                tooltipChildren={"Rotate"}
              >
                <FiRotateCcw />
              </AtlasButton>
            )}

            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onChangeSendTestEmailHOC("showEmailDialog", true)}
              tooltip={true}
              tooltipID={"send-test-email"}
              tooltipPosition={"top"}
              tooltipChildren={"Send test email"}
            >
              <FiSend />
            </AtlasButton>

            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={toggleEmailPreview}
              tooltip={true}
              tooltipID={"edit-email-content"}
              tooltipPosition={"top"}
              tooltipChildren={"Edit email content"}
            >
              <BsPencilSquare />
            </AtlasButton>
          </div>
        </div>

        <AtlasEmailModal
          title={"Send Email Template"}
          loading={onLoadSendTestEmail}
          showSendEmail={showEmailDialog}
          closeSendEmail={() =>
            onChangeSendTestEmailHOC("showEmailDialog", false)
          }
          submitEmail={(val) =>
            sendTestEmail({
              recipients: val,
              template_id: selectedEmailTemplate.id,
            })
          }
        />

        {view === "desktop" && (
          <div className={"email-preview"}>
            <div className={"email-header"}>
              <p className={"mb-1"}>
                <strong>From:</strong> {email}
              </p>

              <p>
                <strong>Subject:</strong> {subject}
              </p>
            </div>

            <div className={"email-body"}>
              {html_code && (
                <Frame className={"iframe"}>{ReactHtmlParser(html_code)}</Frame>
              )}
            </div>
          </div>
        )}
        {view !== "desktop" && (
          <div className={"device-wrapper"}>
            <MarvelDevices
              deviceName={"ipad"}
              orientation={orientation}
              transform={0.5}
            >
              <div className={"device-email-body"}>
                {html_code && (
                  <Frame className={"iframe"}>
                    {ReactHtmlParser(html_code)}
                  </Frame>
                )}
              </div>
            </MarvelDevices>
          </div>
        )}
      </CardContent>

      <CardActions className={"at-email-templates__form__card-actions"}>
        <AtlasButton
          type={"button"}
          onClick={handleClose}
          className={"btn-new btn-new--outline-secondary m-0"}
        >
          Close
        </AtlasButton>
      </CardActions>
    </>
  );
};

const mapStateToProps = (state) => ({ email: state.profileReducer.email });

export default withSendTestEmail(connect(mapStateToProps)(EmailPreview));
