import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Put, Get } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      reassignSubmissions: {},

      cobrokeRequests: {
        data: [],
        meta: {
          page: 1,
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      sentCobrokeRequests: {
        data: [],
        meta: {
          page: 1,
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      cobrokeRequestPages: [1],
      sentCobrokeRequestsPages: [1],
      totalPendingRequest: 0,
      totalRequestCount: 0,

      receivedLastPage: {
        page: 1,
        searchParams: "",
      },
    };

    load = (param) => this.setState({ loading: param });

    onChangeCobrokeHOC = (val, context) => this.setState({ [context]: val });

    getRequestCount = (id) =>
      Get(
        `/co_broke_requests/agents/${id}/total_pending`,
        this.getPendingRequestSuccess,
        this.getPendingRequestError,
        this.load,
      );
    getPendingRequestSuccess = ({ pending, total }) =>
      this.setState({
        totalPendingRequest: pending,
        totalRequestCount: total,
      });
    getPendingRequestError = (error) => requestError(error);

    updateCobrokeStatus = (id, status_id, mode) =>
      Put(
        `/co_broke_requests/${id}`,
        { status_id },
        (payload) => this.updateCobrokeStatusSuccess(payload, mode),
        this.updateCobrokeStatusError,
        this.load,
      );
    updateCobrokeStatusSuccess = (payload, mode) => {
      if (mode === "view") {
        return this.props.getSelectedSubsales(payload.listing_id);
      }
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      const { page, searchParams } = this.state.receivedLastPage;
      this.getReceivedCobrokeRequest(id, page, searchParams);
      this.getRequestCount(id);
    };
    updateCobrokeStatusError = (error) => requestError(error);

    getReceivedCobrokeRequest = (id, page, searchParams) => {
      this.setState(
        {
          receivedLastPage: { page, searchParams },
        },
        () => {
          Get(
            `/co_broke_requests/received/${id}?${searchParams}page=${page}`,
            this.getReceivedCobrokeRequestSuccess,
            this.getReceivedCobrokeRequestError,
            this.load,
          );
        },
      );
    };
    getReceivedCobrokeRequestSuccess = ({ data, meta }) => {
      let tempPages = [];
      let tempData = _.map(data, (item) => ({
        ...item,
        requested_at: Moment(item.requested_at).format("DD-MM-YYYY"),
        // requested_at: Moment( item.requested_at ).format( 'DD-MM-YYYY h:mm a' )
      }));
      for (let index = 0; index < meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        cobrokeRequests: {
          meta,
          data: tempData,
        },
        cobrokeRequestPages: tempPages,
      });
    };
    getReceivedCobrokeRequestError = (error) => requestError(error);

    getSentCobrokeRequest = (id, page, searchParams) =>
      Get(
        `/co_broke_requests/sent/${id}?${searchParams}page=${page}`,
        this.getSentCobrokeRequestSuccess,
        this.getSentCobrokeRequestError,
        this.load,
      );
    getSentCobrokeRequestSuccess = ({ data, meta }) => {
      let tempPages = [];
      let tempData = _.map(data, (item) => {
        return {
          ...item,
          requested_at: Moment(item.requested_at).format("DD-MM-YYYY"),
        };
      });
      for (let index = 0; index < meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        sentCobrokeRequests: {
          data: tempData,
          meta,
        },
        sentCobrokeRequestsPages: tempPages,
      });
    };
    getSentCobrokeRequestError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          totalPendingRequest={this.state.totalPendingRequest}
          cobrokeRequests={this.state.cobrokeRequests}
          sentCobrokeRequests={this.state.sentCobrokeRequests}
          cobrokeRequestPages={this.state.cobrokeRequestPages}
          sentCobrokeRequestsPages={this.state.sentCobrokeRequestsPages}
          totalRequestCount={this.state.totalRequestCount}
          onLoadCobroke={this.state.loading}
          onChangeCobrokeHOC={this.onChangeCobrokeHOC}
          getReceivedCobrokeRequest={this.getReceivedCobrokeRequest}
          getRequestCount={this.getRequestCount}
          getSentCobrokeRequest={this.getSentCobrokeRequest}
          updateCobrokeStatus={this.updateCobrokeStatus}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
