import React from "react";
import _ from "lodash";
import { MdOpenInBrowser } from "react-icons/md";
import { ImWhatsapp, ImTelegram, ImYoutube, ImLink } from "react-icons/im";
import { FaFileDownload, FaFacebook } from "react-icons/fa";
import { toast } from "react-toastify";

import CustomButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";

import "stylesheets/components/share/index.scss";

const Share = ({
  title,
  youtubeLink,
  containerClass,
  containerStyle = {},
  contentClass,
  mediaURL,
  shareLink,
  encodedLink,
  downloadTarget,
  downloadTooltip,
  onClickDownload,
  onClickClose,
  showElement,
}) => {
  const shareComponents = [
    {
      title: "Whatsapp",
      background: "#12B76A",
      children: (
        <ImWhatsapp style={{ width: 25, height: 25, color: "white" }} />
      ),
      tooltipChildren: "Share in WhatsApp",
      tooltipID: "share-whatsapp",
      onClick: () =>
        window.open(
          `https://api.whatsapp.com/send/?phone&text=${encodedLink ? encodedLink : shareLink}`,
          "_blank",
        ),
    },
    {
      title: "Facebook",
      background: "#3b5998",
      children: (
        <FaFacebook style={{ width: 25, height: 25, color: "white" }} />
      ),
      tooltipChildren: "Share in Facebook",
      tooltipID: "share-facebook",
      onClick: () =>
        window.open(`http://www.facebook.com/sharer.php?u=${shareLink}`),
    },
    {
      title: "Telegram",
      background: "#60A5FA",
      children: (
        <ImTelegram style={{ width: 25, height: 25, color: "white" }} />
      ),
      tooltipChildren: "Share in Telegram",
      tooltipID: "share-telegram",
      onClick: () =>
        window.open(
          `https://t.me/share/url?url=${encodedLink ? encodedLink : shareLink}`,
          "_blank",
        ),
    },
    {
      title: "Youtube",
      background: "#D92D20",
      children: <ImYoutube style={{ width: 25, height: 25, color: "white" }} />,
      tooltipChildren: "Share in Youtube",
      tooltipID: "share-youtube",
      onClick: () => window.open(youtubeLink, "_blank"),
    },
    {
      title: "Download",
      background: "#FF8E00",
      children: (
        <FaFileDownload style={{ width: 25, height: 25, color: "white" }} />
      ),
      tooltipChildren: downloadTooltip || "Download",
      tooltipID: "download-media",
      onClick: () => onClickDownload(mediaURL),
    },
    {
      title: "Open in New Tab",
      background: "#4B5563",
      children: (
        <MdOpenInBrowser style={{ width: 25, height: 25, color: "white" }} />
      ),
      tooltipChildren: "Open in New Tab",
      tooltipID: "open-new-tab",
      onClick: () => window.open(shareLink, "_blank"),
    },
    {
      title: "Copy Link",
      background: "#4B5563",
      children: <ImLink style={{ width: 25, height: 25, color: "white" }} />,
      tooltipChildren: "Copy Link",
      tooltipID: "copy-url",
      onClick: () => onClickCopy(),
    },
  ];

  const onClickCopy = () => {
    navigator.clipboard.writeText(shareLink);
    toast.warn("Link Copied");
  };

  const RenderCardContent = () => (
    <div
      className={`${containerClass || ""}`}
      style={{ width: "fit-content", margin: "auto", ...containerStyle }}
    >
      <div
        className={`w-100 my-auto d-flex overflow-auto flex-wrap justify-content-center ${contentClass || ""}`}
        style={{gridGap: 20}}>
        {shareComponents.map((item, index) => {
          if (_.includes(showElement, item.title)) {
            return (
              <div className="at-share-modal__button-container" key={index}>
                {item.title === "Download" && (
                  <a
                    href={mediaURL}
                    download={true}
                    children={item.children}
                    onClick={onClickDownload}
                    target={downloadTarget || "_blank"}
                    className={"at-share-modal__button mb-2"}
                    style={{ backgroundColor: item.background }}
                  />
                )}
                {item.title === "Youtube" && (
                  <CustomButton
                    containerClass={"at-share-modal__button mb-2"}
                    containerStyle={{ backgroundColor: item.background }}
                    children={item.children}
                    tooltip={true}
                    tooltipChildren={item.tooltipChildren}
                    tooltipPosition={"top"}
                    tooltipID={item.tooltipID}
                    onClick={item.onClick}
                  />
                )}
                {item.title !== "Download" && item.title !== "Youtube" && (
                  <CustomButton
                    containerClass={"at-share-modal__button mb-2"}
                    containerStyle={{ backgroundColor: item.background }}
                    children={item.children}
                    tooltip={true}
                    tooltipChildren={item.tooltipChildren}
                    tooltipPosition={"top"}
                    tooltipID={item.tooltipID}
                    onClick={() => item.onClick()}
                  />
                )}
                {item.showContent && item.showContent()}
                {item.title}
              </div>
            );
          }
        })}
      </div>
    </div>
  );

  return (
    <ModalDialog
      title={title}
      removeFooter={true}
      onClose={onClickClose}
      children={<RenderCardContent />}
    />
  );
};

export default Share;
