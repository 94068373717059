export const getTranslation = (key, language) => {
  const translation = japaneseTranslation.find((translation) => translation.key === key);
  if (translation) {
    return translation[language];
  }
  return key;
}

export const japaneseTranslation = [
  //Team Info
  {
    key: "agent.signup.title",
    en: "IQI Agent Signup",
    jp: "IQI エージェントのサインアップ",
  },
  {
    key: "agent.signup.subtitle",
    en: "Sign-up to become an IQI agent with these three simple steps.",
    jp: "次の 3 つの簡単な手順で IQI エージェントに登録してください。",
  },
  {
    key: "agent.signup.progress1",
    en: "Team Info",
    jp: "チーム情報",
  },
  {
    key: "agent.signup.progress2",
    en: "Personal Info",
    jp: "個人情報",
  },
  {
    key: "agent.signup.progress3",
    en: "Verification",
    jp: "検証",
  },
  {
    key: "step",
    en: "Step",
    jp: "ステップ",
  },
  {
    key: "agent.signup.invalid_code",
    en: "Invalid Code",
    jp: "無効なコード",
  },
  {
    key: "agent.signup.resend_code",
    en: "Resend link will be available after",
    jp: "コードを再送信",
  },
  {
    key: "agent.signup.seconds",
    en: "seconds",
    jp: "秒",
  },
  {
    key: "agent.signup.paragraph1",
    en: "You must be referred to sign up as an agent through an existing IQI referrer.",
    jp: "エージェントとしてサインアップするには、既存の IQI 紹介者を通じて紹介される必要があります。",
  },
  {
    key: "agent.signup.paragraph2",
    en: "If the referrer is incorrect, please get the referral link from the correct leader.",
    jp: "紹介者が正しくない場合は、正しいリーダーから紹介リンクを取得してください。",
  },
  {
    key: "team",
    en: "Team",
    jp: "チーム",
  },
  {
    key: "agent.signup.referral",
    en: "has been assigned as your referrer.",
    jp: "があなたの紹介者として割り当てられました。",
  },
  {
    key: "next",
    en: "Next",
    jp: "続く",
  },
  {
    key: "back",
    en: "Back",
    jp: "戻る",
  },
  {
    key: "submit",
    en: "Submit",
    jp: "提出する",
  },
  //Personal Info
  {
    key: "agent.signup.first_name",
    en: "First Name (Based on Identification document)",
    jp: "名 （IDカードによる）",
  },
  {
    key: "agent.signup.last_name",
    en: "Last Name (Based on Identification document)",
    jp: "姓 （IDカードによる）",
  },
  {
    key: "agent.signup.email",
    en: "Email",
    jp: "メールアドレス",
  },
  {
    key: "agent.signup.confirm_email",
    en: "Confirm Email",
    jp: "メールアドレス確認",
  },
  {
    key: "agent.signup.mobile_no",
    en: "Mobile No.",
    jp: "電話番号",
  },
  {
    key: "agent.sigup.branch",
    en: "Branch",
    jp: "支店",
  },
  {
    key: "agent.signup.branch_placeholder",
    en: "Select Branch",
    jp: "支店を選択してください",
  },
  {
    key: "agent.signup.identity_number",
    en: "Identification document number",
    jp: "身分証明書番号（運転免許証、Myナンバーカード、パスポートもしくは保険証の番号）",
  },
  {
    key: "agent.signup.terms_section1",
    en: "By signing up, you agree to the Terms of Service, Privacy Policy,",
    jp: "サインアップすると、利用規約、プライバシー ポリシー、"
  },
  {
    key: "agent.signup.terms_section2",
    en: "and cookie policy.",
    jp: "Cookie ポリシーに同意したことになります。"
  },
  {
    key: "agent.signup.terms2",
    en: "I confirm that I am not currently attached to any other real estate agency or company and agree to work exclusively with IQI as a Real Estate Negotiator or Agent. I acknowledge that if I work for another real estate company or agency during my tenure in IQI, I will be liable to pay any damages IQI may incur as a result of my breach of this exclusivity clause. In addition to any damages, I also agree to pay MYR 20,000 in administration fees to IQI in respect of such breach.",
    jp: "現在、他の不動産代理店や不動産会社に所属していないことを確認し、不動産交渉人または不動産エージェントとして IQI と独占的に働くことに同意します. IQI での在職中に他の不動産会社または不動産代理店で働いた場合、この独占条項の違反により IQI が被った損害を支払う責任があることを認めます。. 損害賠償に加えて、私はかかる違反に関して IQI に管理手数料として 20,000 リンギットを支払うことにも同意します。"
  },
  {
    key: "agent.signup.your_mobile_number",
    en: "Your mobile number is",
    jp: "あなたの電話番号は",
  },
  {
    key: "agent.signup.your_mobile_number2",
    en: "",
    jp: "です。",
  },
  {
    key: "agent.signup.matched_email",
    en: "The confirmed email matches with the email.",
    jp: "確認されたメールアドレスはメールと一致します。",
  },
  {
    key: "agent.signup.not_matched_email",
    en: "The confirmed email has to be identical as email.",
    jp: "確認されたメールアドレスはメールと同一である必要があります。",
  },
  {
    key: "agent.signup.mobile_no_placeholder",
    en: "e.g. 186667890",
    jp: "例: 1012345678"
  },
  {
    key: "agent.signup.mobile_no_helptext",
    en: "",
    jp: "最初の0を排除して入力して下さい。(例: 03-3333-3333→3-3333-3333)"
  },
  //Verification
  {
    key: "agent.signup.verify_email_mobile",
    en: "Verify your email and mobile number by entering the codes sent to you.",
    jp: "登録したEメールアドレスと電話番号それぞれに認証コードが送られます。それぞれ受信した認証コードを \n入力してください。 "
  },
  {
    key: "agent.signup.verify_email_label",
    en: "Email Verification Code",
    jp: "メールアドレス認証コード"
  },
  {
    key: "agent.signup.verify_mobile_label",
    en: "Mobile Verification Code",
    jp: "モバイル認証コード"
  },
  {
    key: "agent.signup.verify_code",
    en: "Verify Code",
    jp: "コードを確認する"
  },
  {
    key: "agent.signup.verify_email",
    en: "Key in the code sent to your email, and click 'Verify Code' button to proceed.",
    jp: "メールアドレスに送信されたコードを入力し、[コードを確認] ボタンをクリックして続行します。"
  },
  {
    key: "agent.signup.verify_mobile",
    en: "Key in the code sent to your mobile number, and click 'Verify Code' button to proceed.",
    jp: "電話番号に送信されたコードを入力し、[コードを確認] ボタンをクリックして続行します。"
  },
  {
    key: "agent.signup.didnt_get_code",
    en: "Didn't get the code?",
    jp: "コードを取得できませんでしたか?"
  },
  {
    key: "agent.signup.resend_email",
    en: "Resend email verification code",
    jp: "メールアドレス認証コードを再送信する"
  },
  {
    key: "agent.signup.resend_mobile",
    en: "Resend mobile verification code",
    jp: "モバイル認証コードを再送信する"
  },
  //general
  {
    key: "agent.signup.registration_successful",
    en: "Registration Successful",
    jp: "登録に成功"
  },
  {
    key: "agent.signup.registration_invalid",
    en: "Invalid Registration",
    jp: "無効な登録"
  },
  {
    key: "agent.signup.button_ok",
    en: "OK",
    jp: "わかりました"
  }
]