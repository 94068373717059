export const PMRoutingData = [
  {
    id: 3,
    name: "Project Centre",
    icon: "atlas-building-4",
    navigateTo: "/dashboard/project-listing",
  },
  {
    id: 3,
    name: "Projects",
    icon: "icon-manage-project",
    navigateTo: "/dashboard/admin/project-management",
  },
];
