import React from "react";
import _ from "lodash";

import AtlasCloseButton from "components/Button/prev";
import EventTrainingForm from "./form";
import LoadingModal from "components/LoadingModal";

import CreateEventHOC from "../actions/createEvent";

const CreateEventContent = ({
  data,
  createEvent,
  onChangeEventHOC,
  createNewEvent,
  branchRegions,
  onChangeFileCreate,
  onChangeFieldCreate,
  onLoadCreateEvent,
}) => {
  const onChangeField = (val, context) => {
    if (context === "type_id") {
      if (val === 1) {
        onChangeFieldCreate(null, "training_type_id");
      }
    }
    if (context === "branch_region_id") {
      let tmpBranchRegion = _.find(branchRegions, { id: val });
      onChangeFieldCreate(tmpBranchRegion.address, "location");
      onChangeFieldCreate(tmpBranchRegion.google_map_url, "location_url");
    }
    onChangeFieldCreate(val, context);
  };

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton
          onClick={() => onChangeEventHOC(false, "showCreateEventDialog")}
        />
        <h4 className="at-card__title">{"New Event"}</h4>
      </div>
      <EventTrainingForm
        mode={"create"}
        data={data}
        eventDetails={createNewEvent}
        submitEventForm={() => createEvent(createNewEvent)}
        type_id={createNewEvent.type_id}
        branchRegions={branchRegions}
        onChangeFile={onChangeFileCreate}
        onChangeField={onChangeField}
        onClose={() => onChangeEventHOC(false, "showCreateEventDialog")}
      />
      {onLoadCreateEvent && <LoadingModal />}
    </>
  );
};

export default CreateEventHOC(CreateEventContent);
