import React from "react";
import { Tooltip } from "@material-ui/core";
import { IoMdDocument } from "react-icons/io";

import AtlasCloseButton from "components/Button/close";

const DocumentElement = ({
  fileData,
  onClickOpenDoc,
  onRemoveDoc,
  can_update,
  isUnsaved,
}) => {
  return (
    <Tooltip
      placement={"top"}
      enterTouchDelay={50}
      title={"Click to view document."}
    >
      <div
        style={{ position: "relative", width: "max-content", margin: "auto" }}
      >
        {fileData.type === "pdf" && (
          <IoMdDocument
            style={{
              height: 120,
              cursor: "zoom-in",
              color: isUnsaved ? "#808080" : "#f18e03",
            }}
            onClick={() => onClickOpenDoc(fileData)}
          />
        )}
        {fileData.type === "image" && (
          <img
            style={{
              objectFit: "cover",
              height: 120,
              width: 120,
              borderRadius: "0.4rem",
              cursor: "zoom-in",
              filter: "brightness(0.5)",
              backgroundColor: "rgba(0,0,0,0.75)",
            }}
            src={fileData.document}
            alt=""
            onClick={() => onClickOpenDoc(fileData)}
          />
        )}
        <strong
          className={"w-100"}
          style={{
            color: "#FFFF",
            fontSize: "0.7em",
            right: 0,
            top: "50%",
            position: "absolute",
          }}
        >
          {isUnsaved ? "Unsaved" : "Uploaded"}
        </strong>
        {can_update && (
          <AtlasCloseButton
            containerStyle={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            buttonStyle={{
              width: 25,
              height: 25,
              justifyContent: "center",
              backgroundColor: "transparent",
            }}
            closeIconStyle={{ width: 22 }}
            onClick={() => onRemoveDoc()}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default DocumentElement;
