import React from "react";
import Moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";

import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";

const StatusForm = ({
  dataBasicInfo,

  onChangeNewHOC,
}) => {
  const onChangeValue = (val, state) => {
    let temp = _.cloneDeep(dataBasicInfo);
    temp[state] = val;
    onChangeNewHOC(temp, "dataBasicInfo");
  };

  const renderSnackbarContent = () => (
    <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
      <li>
        Instantly activate your listing by selecting "Active" on the Status
        field. Or choose "Draft" to save your record for future posting.
      </li>
      <li>
        Click the "Submit" button to create the listing, and you will be
        redirected to the editing page to add photos.
      </li>
    </ul>
  );

  return (
    <div className="at-form__content">
      <div className="at-subsales-listing__view-title mb-20">
        <AtlasIcon
          svgHref={"atlas-send-2"}
          style={{ fill: "#F0631D", marginRight: 10 }}
        />
        Status
      </div>
      <div className="grid-control">
        <section className="grid-full-col">
          <h2 className="at-form-input__title mr-10">Available Date</h2>
          <div className="at-form-input">
            <AtlasIcon
              className={"at-form-input__calendar"}
              svgHref={"atlas-calendar-input"}
            />
            <DatePicker
              className="at-form-input__input"
              dateFormat="YYYY-MM-DD"
              showYearDropdown={true}
              placeholderText="Select available date"
              value={dataBasicInfo.availableDate || null}
              onChange={(val) =>
                onChangeValue(Moment(val).format("YYYY-MM-DD"), "availableDate")
              }
            />
          </div>
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title mr-10">Status</h2>
          <AtlasRadioGroup
            checkedValue={dataBasicInfo.status_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeValue(val, "status_id")}
            options={[
              { value: 0, label: "Draft" },
              { value: 1, label: "Active" },
            ]}
          />
        </section>
        <section className="grid-full-col">
          <AlertBox
            mode={"info"}
            hideIcon={true}
            description={renderSnackbarContent()}
          />
        </section>
      </div>
    </div>
  );
};

export default StatusForm;
