export const SidebarMenuData = (token) => [
  {
    name: "Dashboard",
    icon: "atlas-grid-5",
    navigateTo: "/dashboard/statistic",
    type: "menu",
  },
  {
    name: "Team Hub",
    icon: "atlas-profile2user",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        name: "My PA",
        icon: "atlas-tag-user",
        navigateTo: "/dashboard/personal/personal-assistant",
      },
      {
        name: "My Team",
        icon: "atlas-profile-circle",
        navigateTo: "/dashboard/team/my-team",
      },
      {
        name: "Team Sales",
        icon: "atlas-dollar-square",
        navigateTo: "/dashboard/team/team-sales",
      },
      {
        name: "Project Sales",
        icon: "atlas-project-sales",
        navigateTo: "/dashboard/team/project-sales",
      },
      {
        name: "New Agent Approvals",
        icon: "atlas-profile-add",
        navigateTo: "/dashboard/team/pending-approval",
      },
      {
        name: "Meeting Hub",
        icon: "atlas-people-linear",
        navigateTo: "/dashboard/team/meeting-hub",
      },
    ],
  },
  {
    name: "Insights & Reports",
    icon: "atlas-graph",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        name: "Analytics Centre",
        icon: "atlas-diagram",
        navigateTo: "/dashboard/analytic-centre",
      },
      {
        name: "Campaign Tracker",
        icon: "atlas-gift",
        navigateTo: "/dashboard/reports/campaign-trackers",
      },
      { label: "TEAM" },
      {
        name: "Top Agent Group Sales",
        icon: "atlas-cup",
        navigateTo: "/dashboard/reports/group-project-sales",
      },
      {
        name: "Subsales Booked Tracker",
        icon: "atlas-subsales-booked-tracker",
        navigateTo: "/dashboard/reports/subsales-booked-tracker",
      },
      {
        name: "Leader Team Sales Ranking",
        icon: "atlas-medal-star",
        navigateTo: "/dashboard/reports/leader-team-sales-ranking",
      },
      {
        name: "Underperforming Members",
        icon: "atlas-trend-down",
        navigateTo: "/dashboard/team/team-performance",
      },
      {
        name: "Recruitment Growth Targets",
        icon: "atlas-user-octogan",
        navigateTo: "/dashboard/team/recruitment-growth-targets",
      },
      { label: "PERSONAL" },
      {
        name: "My Sales",
        icon: "atlas-my-sales",
        navigateTo: "/dashboard/personal/my-sales",
      },
    ],
  },
  {
    name: "Real Estate Radar",
    icon: "atlas-home-hastag",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        name: "Project Centre",
        icon: "atlas-building-4",
        navigateTo: "/dashboard/project-listing",
        type: "menu",
      },
      {
        name: "Listing Centre",
        icon: "atlas-building-3",
        navigateTo: "/dashboard/personal/subsales",
      },
      {
        name: "IQI Drive",
        icon: "atlas-folder",
        navigateTo: "/dashboard/iqi-drive/list",
      },
      {
        name: "Co-broke Centre",
        icon: "atlas-cobroke-centre",
        navigateTo: "/dashboard/personal/cobroke",
      },
      {
        name: "Valuations",
        icon: "atlas-valuations",
        navigateTo: "/dashboard/valuations/corporate",
      },
      { label: "SUBSALES" },
      {
        name: "Appointment Letters",
        icon: "atlas-appointment-letter",
        navigateTo: "/dashboard/personal/forms/appointment-letter",
      },
      {
        name: "Confirmation Forms",
        icon: "atlas-confirmation-form",
        navigateTo: "/dashboard/personal/forms/confirmation-form",
        contentURL: [
          "/dashboard/personal/forms/confirmation-form",
          "/dashboard/personal/forms/confirmation-form-request",
        ],
      },
      {
        name: "Co-broke Letters",
        icon: "atlas-cobroke-letter",
        navigateTo: "/dashboard/personal/forms/cobroke-letter",
      },
      {
        name: "Authorisation Forms to Secure Property",
        icon: "atlas-authorisation-form",
        navigateTo: "/dashboard/personal/forms/authorisation-form",
      },
      {
        name: "Subsales Claims",
        icon: "atlas-subsales-claims",
        navigateTo: "/dashboard/personal/subsales-claims",
      },
    ],
  },
  {
    name: "Engagement Hub",
    icon: "atlas-user-square",
    navigateTo: "",
    type: "accordion",
    children: [
      { label: "MARKETING" },
      {
        name: "Presentation Centre",
        icon: "atlas-presentation-chart",
        navigateTo: "/dashboard/presentation-centre",
      },
      {
        name: "Video Creator",
        icon: "atlas-video-play",
        navigateTo: "/dashboard/video-creator",
      },
      {
        name: "Graphic Studio",
        icon: "atlas-gallery-edit",
        navigateTo: "/dashboard/graphic-studio",
      },
      {
        name: "Requests Form",
        icon: "atlas-request-form",
        href: `https://agentforms.iqiglobal.com/atlas-agentforms-login?token=${token}`,
      },
      { label: "CRM" },
      {
        name: "My Contacts",
        icon: "atlas-my-contact",
        navigateTo: "/dashboard/personal/contacts",
      },
      {
        name: "Leads",
        icon: "atlas-personalcard",
        navigateTo: "/dashboard/personal/lead-management",
      },
    ],
  },
  {
    name: "Financial Hub",
    icon: "atlas-moneys",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        name: "Payment Vouchers",
        icon: "atlas-receipt-item",
        navigateTo: "/dashboard/personal/payment-vouchers",
      },
      {
        name: "New Project Sales",
        icon: "atlas-project-sales",
        navigateTo: "/dashboard/personal/report-new-sales",
      },
    ],
  },
  {
    name: "IQI Calendar",
    icon: "atlas-calendar",
    navigateTo: "/dashboard/iqi-calendar",
  },
  {
    name: "Global Network",
    icon: "atlas-global",
    navigateTo: "/dashboard/global-network",
  },
  {
    name: "License Renewal",
    icon: "atlas-personalcard",
    navigateTo: "/dashboard/personal/rea-renewal",
  },
  {
    name: "Learning Hub",
    icon: "atlas-book-1",
    navigateTo: "",
    type: "accordion",
    children: [
      {
        name: "My Trainings",
        icon: "atlas-book",
        navigateTo: "/dashboard/training-centre/my-training",
      },
      {
        name: "IQI Academy",
        icon: "atlas-teacher",
        navigateTo: "",
        href: `https://academy.iqiglobal.com/atlas-academy-login?token=${token}`,
      },
      {
        name: "Tutorials",
        icon: "atlas-bookmark-2",
        navigateTo: "/dashboard/tutorials",
      },
    ],
  },
  {
    name: "Store Hub",
    icon: "atlas-shop",
    type: "accordion",
    children: [
      {
        name: "Atlas Store",
        icon: "atlas-bag",
        navigateTo: "/marketing-centre/products-catalog",
      },
      {
        name: "IQI Store",
        icon: "atlas-shopping-card",
        navigateTo: "",
        href: "https://store.iqiglobal.com/",
      },
      {
        name: `JIQI's Cafe`,
        icon: "atlas-jiqi-cafe",
        navigateTo: "/dashboard/jiqi-cafe",
      },
    ],
  },
];
