import React from "react";
import { Button, Tooltip } from "@material-ui/core";

import WhatsappImage from "assets/images/whatsapp.png";

import "./index.scss";

const SupportButton = () => {
  return (
    <Tooltip
      placement={"left"}
      title={"Please click here to contact our support."}
    >
      <button
        className="at-support-group-btn"
        onMouseDown={() => {
          window.open(
            `https://api.whatsapp.com/send?phone=60127815559&text=Hey%20Atlas%20Support%21%20I%27m%20facing%20some%20issues%20here%20and%20need%20help%20from%20you%20%3A%29%20`,
          );
        }}
        onTouchStart={() => {
          window.open(
            `https://api.whatsapp.com/send?phone=60127815559&text=Hey%20Atlas%20Support%21%20I%27m%20facing%20some%20issues%20here%20and%20need%20help%20from%20you%20%3A%29%20`,
          );
        }}
      >
        <img src={WhatsappImage} style={{ width: "40px", height: "40px" }} />
      </button>
    </Tooltip>
  );
};

export default SupportButton;
