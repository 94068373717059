import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NewSvgIcon from "components/Icon/new";
import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmationModal from "components/Modal/delete";
import AtlasTable from "components/NewTable";
import CreateSubmission from "./Create";
import UpdateSubmission from "./Update";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasSimpleTable from "components/NewTable/simple";

import { statusColor } from "dictionary/subsales";
import { getColorBadge } from "dictionary/badgeColor";
import SubmissionHOC from "./actions";
import DocumentsHOC from "./actions/documents";
import SubsalesAdminHOC from "../../actions/admin";
import permissionsChecker from "utils/permissionsChecker";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const headerData = [
  {
    header: "Type",
    accessor: "type",
    searchFlag: "type",
    columnStyle: { width: "50%" },
  },
  {
    header: "Status",
    accessor: "status",
    columnClass: "at-column-responsive_center",
    contentClass: "at-column-responsive_center",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => {
      const { status = "" } = rowData;
      const { colorName = "" } = _.find(statusColor, { value: status }) || {};

      return (
        <div
          className="at-status_badge at-status_badge-responsive"
          style={{
            ...getColorBadge(colorName),
            whiteSpace: "nowrap",
          }}
        >
          {status}
        </div>
      );
    },
  },
  {
    header: "Admin",
    hidesSort: true,
    searchFlag: `admin`,
    renderColumn: (rowData) => {
      const { admin, type_id, status_id, senior_admin } = rowData;
      return (
        <>
          {!(!admin && [7, 8].includes(type_id) && status_id === 1) && (
            <>
              <div className="d-flex align-items-center mb-5">
                <DarkTooltip
                  enterTouchDelay={50}
                  classes={{ tooltip: "tooltip-arrow top" }}
                  placement={"top"}
                  title={"Assigned Admin"}
                >
                  <div className="at-nested_table-admin-label">A</div>
                </DarkTooltip>
                {admin || "N/A"}
              </div>
              <div className="d-flex align-items-center">
                <DarkTooltip
                  enterTouchDelay={50}
                  classes={{ tooltip: "tooltip-arrow top" }}
                  placement={"top"}
                  title={"Reviewing Admin"}
                >
                  <div className="at-nested_table-admin-label">R</div>
                </DarkTooltip>
                {senior_admin || "N/A"}
              </div>
            </>
          )}
        </>
      );
    },
  },
  {
    header: "Submission Date",
    accessor: "submitted_date",
    searchFlag: "submitted_date",
    columnStyle: { width: "70%" },
  },
  {
    header: "Approved Date",
    accessor: "approved_date",
    searchFlag: "approved_date",
    columnStyle: { width: "70%" },
  },
];

const refundHeaderData = [
  {
    header: "Name",
    accessor: "participant_name",
  },
  {
    header: "Type",
    accessor: "type",
  },
  {
    header: "Amount",
    accessor: "amount",
  },
  {
    header: "Remark",
    accessor: "remark",
  },
];

class Submission extends Component {
  componentDidMount = () => {
    this.props.getSubmissions(this.props.selected_claim.id);
  };

  editClick = (val) => {
    this.props.getSelectedSubmission(
      this.props.selected_claim.id,
      val.id,
      "edit",
    );
    this.props.getDocuments(val.id);
  };

  renderCountDownText = () => {
    const { countDownTime } = this.props;

    if (countDownTime && countDownTime > 0) {
      return (
        <p>
          {`Please wait for ${countDownTime} seconds cooling-off period for creating another submission.`}
        </p>
      );
    }
  };

  renderTableHeading = () => {
    const { can_create } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { commRate } = this.props;

    return (
      <div className={`at-table_heading`}>
        <div className="d-flex grid_gap-2 align-items-center flex-wrap">
          <NewSvgIcon
            svgHref="confirmation-form_document-text"
            className="at-icon_svg-table_heading-title"
          />
          <h5 className={`fw-600`} style={{ maxWidth: "fit-content" }}>
            My Claims
          </h5>
          {!this.props.countDownTime && can_create && (
            <CustomButton
              className={"btn-new btn-new--primary"}
              children={
                <>
                  <IoMdAdd style={{ height: 18, width: 18 }} />
                  Fill in My Claim
                </>
              }
              onClick={() =>
                this.props.onChangeSubmissionHOC("showCreateSubmission", true)
              }
            />
          )}
        </div>
        {this.renderCountDownText()}
      </div>
    );
  };

  renderCardContent = () => {
    const { can_update, can_destroy } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const menuExpand = this.props.data.sideMenuReducer.sideMenuExpand;

    return (
      <>
        <div>
          <AtlasTable
            className={"mb-100"}
            columns={headerData}
            rowData={this.props.submissions}
            pagination={true}
            renderExtraContent={this.renderTableHeading}
            actionColumnContent={[
              {
                name: "edit",
                onShow: () => can_update,
                onClick: (rowData) => this.editClick(rowData),
              },
              {
                name: "details",
                onShow: () => !can_update,
                onClick: (rowData) => this.editClick(rowData),
              },
              {
                name: "delete",
                onShow: () => can_destroy,
                onClick: (rowData) => {
                  this.props.onChangeSubmissionHOC(
                    "showDeleteSubmission",
                    true,
                  );
                  this.props.onChangeSubmissionHOC("toDeleteSubmission", {
                    subsales_claim_id: rowData.sub_sale_claim_id,
                    submission_id: rowData.id,
                  });
                },
              },
            ]}
          />
        </div>
        <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() => this.props.onClickNav("Documents")}
          >
            <p>Documents</p>
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onCloseForm()}
          >
            Close
          </button>
        </div>
      </>
    );
  };

  render = () => {
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const { refund_details } = this.props.selectedSubmission;

    return (
      <>
        {this.renderCardContent()}
        {this.props.showCreateSubmission && (
          <CreateSubmission
            admins={this.props.admins}
            onClose={() =>
              this.props.onChangeSubmissionHOC("showCreateSubmission", false)
            }
            showConfirmCreateSubmission={this.props.showConfirmCreateSubmission}
            onChangeSubmissionHOC={this.props.onChangeSubmissionHOC}
            getRefundParticipants={this.props.getRefundParticipants}
            showCloseConfirmation={this.props.showCloseConfirmation}
            isCreateSubmissionError={this.props.isCreateSubmissionError}
            onLoadAdmins={this.props.onLoadAdmins}
            onLoadSubmissions={this.props.onLoadSubmissions}
            createSubmission={this.props.createSubmission}
            onCloseCopyRemarkConfirmation={this.props.onCloseCopyRemarkConfirmation}
            refundParticipants={this.props.refundParticipants}
            role={tmpProfileReducer.role}
            selected_claim={this.props.selected_claim}
            getAdmins={this.props.getAdmins}
          />
        )}
        {this.props.showUpdateSubmission && (
          <UpdateSubmission
            onLoadAdmins={this.props.onLoadAdmins}
            showReassignModal={this.props.showReassignModal}
            approvalCountDownTime={this.props.approvalCountDownTime}
            onChangeSubmissionHOC={this.props.onChangeSubmissionHOC}
            selectedSubmission={this.props.selectedSubmission}
            initialSubmission={this.props.initialSubmission}
            onLoadSubmissions={this.props.onLoadSubmissions}
            onLoadDocuments={this.props.onLoadDocuments}
            documents={this.props.documents}
            selected_claim={this.props.selected_claim}
            role={tmpProfileReducer.role}
            admins={this.props.admins}
            senior_admins={this.props.senior_admins}
            disabledApprove={this.props.disabledApprove}
            showSeniorApproveConfirmation={
              this.props.showSeniorApproveConfirmation
            }
            is_subsale_admin={tmpProfileReducer.is_subsale_admin}
            getAdmins={this.props.getAdmins}
            getPendingReassign={this.props.getPendingReassign}
            showResubmitSubmission={this.props.showResubmitSubmission}
            resubmitSubmission={this.props.resubmitSubmission}
            updateSubmission={this.props.updateSubmission}
            approveSubmission={this.props.approveSubmission}
            rejectSubmission={this.props.rejectSubmission}
            completeSubmission={this.props.completeSubmission}
            uploadDocument={this.props.uploadDocument}
            deleteDocument={this.props.deleteDocument}
            downloadDocument={this.props.downloadDocument}
            sendNotification={this.props.sendNotification}
            seniorApproveSubmission={this.props.seniorApproveSubmission}
            rejectReason={this.props.rejectReason}
            showCompleteConfirmation={this.props.showCompleteConfirmation}
            showRejectSubmissionReason={this.props.showRejectSubmissionReason}
            showApproveConfirmation={this.props.showApproveConfirmation}
            getSubmittedSubsaleClaims={this.props.getSubmittedSubsaleClaims}
          />
        )}
        {this.props.showViewSubmission && (
          <ModalDialog
            title={"Refund Details"}
            onClose={() =>
              this.props.onChangeSubmissionHOC("showViewSubmission", false)
            }
            children={
              <AtlasSimpleTable
                className={"mb-100"}
                columns={refundHeaderData}
                rowData={refund_details}
                hideSearch={true}
                pagination={true}
                emptyStateMessage="You don't have any participants."
              />
            }
          />
        )}
        <DeleteConfirmationModal
          open={this.props.showDeleteSubmission}
          loading={this.props.onLoadSubmissions}
          positiveAction={() => this.props.deleteSelectedSubmission()}
          negativeAction={() =>
            this.props.onChangeSubmissionHOC("showDeleteSubmission", false)
          }
          message={`You will not able to recover the data`}
        />
        {this.props.onLoadSubmissions && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  SubsalesAdminHOC,
  SubmissionHOC,
  DocumentsHOC,
)(Submission);
