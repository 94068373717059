import React, { Component } from "react";
import _ from "lodash";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import AtlasButton from "components/Button";
import AtlasTable from "components/NewTable";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";
import CustomTypeahead from "components/Typeahead/new";

import AgentsHOC from "../actions/agents";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
);

const TransferTypes = [
  { label: "Event", value: 1, id: "transfer-event-form" },
  { label: "Participant", value: 2, id: "transfer-participant-form" },
];

class TransferTicket extends Component {
  filepond = null;

  state = {
    newEventId: "",
    transferTicket: {
      to_user_id: "",
      statement_of_approval: "",
      statement_of_approval_file_name: "",
    },
  };

  onChangeTypeaheadSearch = _.debounce((val) => {
    val.length > 0
      ? this.props.getAgentsBySearch(val)
      : this.props.onChangeAgentsHOC([], "agentList");
  }, 600);

  onChangeTypeaheadEventSearch = _.debounce((val) => {
    val.length > 0
      ? this.props.getEventBySearch(val)
      : this.props.onChangeTransferTicketHOC("eventList", []);
  }, 600);

  onSelectUploadImage = (fileItems) => {
    let tempFile = _.cloneDeep(this.state.transferTicket);

    try {
      if (fileItems.length > 0 && fileItems[0].file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          let fileResult = await e.target.result;
          tempFile.statement_of_approval_file_name =
            await fileItems[0].file.name;
          tempFile.statement_of_approval = await fileResult;
          this.setState({
            transferTicket: tempFile,
          });
        };
        reader.readAsDataURL(fileItems[0].file);
      }
    } catch (e) {
      console.log(e);
    }
  };

  onClickRemoveUploadImage = (image) => {
    let tempFile = _.cloneDeep(this.state.transferTicket);

    tempFile.statement_of_approval = "";
    tempFile.statement_of_approval_file_name = "";

    this.setState({ transferTicket: tempFile });
  };

  renderUploadPaymentProof = () => {
    return (
      <FilePond
        ref={(ref) => (this.filePond = ref)}
        style={{ height: 50, marginTop: "10px" }}
        acceptedFileTypes={["image/*"]}
        allowMultiple={false}
        fullWidth
        onremovefile={(err, fileItem) => this.onClickRemoveUploadImage()}
        onupdatefiles={(fileItems) => this.onSelectUploadImage(fileItems)}
        allowImageValidateSize={true}
        imageValidateSizeMinWidth={640}
        imageValidateSizeMinHeight={480}
        imageValidateSizeLabelImageSizeTooSmall={"Image size is too small"}
        imageValidateSizeLabelExpectedMinSize={
          "Minimum image size is 640 × 480"
        }
      />
    );
  };

  render = () => {
    return (
      <>
        <div className="at-card__header">
          <AtlasCloseButton
            onClick={() => {
              this.props.onChangeTransferTicketHOC("showTransferTicket", false);
              this.props.onChangeTransferTicketHOC("transferType", 1);
            }}
          />
          <h4 className="at-card__title">Event Transfer Hub</h4>
        </div>
        <section className="grid-full-col">
          <h2 className="at-form-input__title mb-2">{"Select Transfer Type"}</h2>
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={this.props.transferType}
            containerClassName={"w-100 mb-4"}
            selectedRadioValue={(val) =>
              this.props.onChangeTransferTicketHOC("transferType", val)
            }
            options={TransferTypes}
          />
        </section>
        {this.props.transferType === 1 && (
          <div className="grid-control grid_gap-2">
            <section className="grid-half-col" style={{ height: 100 }}>
              <h2 className="at-form-input__title mb-2">
                {"Transfer to the selected event"}
              </h2>
              <CustomTypeahead
                typeaheadId={"new_event"}
                showClearButton={true}
                onClear={() => this.setState({ newEventId: "" })}
                options={this.props.eventList || []}
                onSelect={(val) => {
                  if (val && val.length > 0) {
                    this.setState({ newEventId: val[0].id });
                  }
                }}
                selectedValue={
                  _.find(this.props.eventList, { id: this.state.newEventId })
                    ? [
                        _.find(this.props.eventList, {
                          id: this.state.newEventId,
                        }),
                      ]
                    : []
                }
                labelKey={"name"}
                filterBy={["name"]}
                onSearch={(val) => {
                  this.setState({ newEventId: "" });
                  this.onChangeTypeaheadEventSearch(val);
                }}
                childrenHead={(rowItem) => (
                  <div className="at-attendee__agent-detail-info">
                    <p>{rowItem.name}</p>
                    <p style={{ fontSize: "unset" }}>
                      Event Date: {rowItem.eventDate}
                    </p>
                    <br />
                  </div>
                )}
              />
            </section>
            <section className="grid-full-col">
              <div className="d-flex">
                <AtlasButton
                  className={"btn-new btn-new--success"}
                  children={"Transfer Now"}
                  disabled={
                    _.find(this.props.eventList, { id: this.state.newEventId })
                      ? false
                      : true
                  }
                  onClick={() =>
                    this.props.transferTicketEventRequest(
                      this.props.selectedAttendee.id,
                      this.state.newEventId,
                    )
                  }
                />
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary ml-2"
                  onClick={() => {
                    this.setState({ newEventId: "" });
                    this.props.onChangeTransferTicketHOC("eventList", []);
                    this.props.onChangeTransferTicketHOC(
                      "showTransferTicket",
                      false,
                    );
                    this.props.onChangeTransferTicketHOC("transferType", 1);
                  }}
                >
                  Cancel
                </button>
              </div>
            </section>
          </div>
        )}
        {this.props.transferType === 2 && (
          <div className="grid-control grid_gap-2">
            <section className="grid-half-col" style={{ height: 100 }}>
              <h2 className="at-form-input__title mb-2">
                {"Transfer to the selected agent below"}
              </h2>
              <CustomTypeahead
                showClearButton={true}
                onClear={() => {
                  let tmp = _.cloneDeep(this.state.transferTicket);
                  tmp.to_user_id = "";
                  this.setState({ transferTicket: tmp });
                }}
                labelKey={"full_name"}
                typeaheadId={"agent"}
                filterBy={["full_name", "email", "mobile_contact_number"]}
                options={this.props.agentList || []}
                onSearch={(val) => {
                  let tmp = _.cloneDeep(this.state.transferTicket);
                  tmp.to_user_id = "";
                  this.setState({ transferTicket: tmp });
                  this.onChangeTypeaheadSearch(val);
                }}
                selectedValue={
                  _.find(this.props.agentList, {
                    id: this.state.transferTicket.to_user_id,
                  })
                    ? [
                        _.find(this.props.agentList, {
                          id: this.state.transferTicket.to_user_id,
                        }),
                      ]
                    : []
                }
                onSelect={(val) => {
                  if (val && val.length > 0) {
                    let tmp = _.cloneDeep(this.state.transferTicket);
                    tmp.to_user_id = val[0].id;
                    return this.setState({ transferTicket: tmp });
                  }
                }}
                helpText={"e.g. Full Name, Email, Mobile Number"}
                childrenHead={(rowItem) => <p>{rowItem.name}</p>}
                highlighterData={[
                  (option) =>
                    `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                ]}
              />
            </section>
            <section className="grid-half-col">
              <h2 className="at-form-input__title mb-2">
                Upload proof of payment
              </h2>
              {this.renderUploadPaymentProof()}
            </section>
            <section className="grid-full-col">
              <div className="d-flex">
                <AtlasButton
                  className={"btn-new btn-new--success"}
                  children={"Transfer Now"}
                  disabled={Object.values(this.state.transferTicket).some(
                    (value) => !value,
                  )}
                  onClick={() =>
                    this.props.transferTicketRequest(
                      this.props.selectedAttendee.id,
                      this.state.transferTicket,
                    )
                  }
                />
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary ml-2"
                  onClick={() => {
                    this.props.onChangeTransferTicketHOC(
                      "showTransferTicket",
                      false,
                    );
                    this.props.onChangeTransferTicketHOC("transferType", 1);
                  }}
                >
                  Cancel
                </button>
              </div>
            </section>
          </div>
        )}
        {(this.props.onLoadTransferTicket ||
          this.props.onLoadInternalAgents) && <LoadingModal />}
      </>
    );
  };
}

export default AgentsHOC(TransferTicket);
