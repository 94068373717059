import React from "react";

import juwaiLogo from "assets/images/juwai_logo_only.png";

const JuwaiSubs = ({ agent_juwai_account }) => {
  return (
    <section className="at-form__content">
      <div className="at-subsales-listing__view-title mb-20">
        <img
          className="at-subsales-listing__view-title-img"
          src={juwaiLogo}
          alt="Juwai Asia"
          style={{ marginRight: 10 }}
        />
        Your Juwai Account Details
      </div>
      <hr />
      {!agent_juwai_account && (
        <>
          <p className={"mb-10"}>
            You have not yet subscribed to any of the Juwai packages. Therefore
            your listings will be rejected when exporting them to the Juwai
            website
          </p>
          <p className={"mb-10"}>
            If you have recently subscribed to a Juwai package, please take note
            that it will take 24 hours for Atlas to import your Juwai account
            here.
          </p>
        </>
      )}
      {agent_juwai_account && (
        <>
          <p className={"mb-10"}>{`Status: ${agent_juwai_account.status}`}</p>
          <p
            className={"mb-10"}
          >{`Package: ${agent_juwai_account.package_name}`}</p>
          <p
            className={"mb-10"}
          >{`Active total quota: ${agent_juwai_account.active_total_quota}`}</p>
          <p
            className={"mb-10"}
          >{`Total listings: ${agent_juwai_account.total_listings}`}</p>
          <p
            className={"mb-10"}
          >{`Total published listing: ${agent_juwai_account.total_published_listings}`}</p>
          <p
            className={"mb-10"}
          >{`Account expiration date: ${agent_juwai_account.formated_expiration_date}`}</p>
        </>
      )}
    </section>
  );
};

export default JuwaiSubs;
