import React, { useState } from "react";
import _ from "lodash";
import { CircularProgress, Typography, Tooltip } from "@material-ui/core";

import LoadingModal from "components/LoadingModal";
import ShareModal from "components/Share";
import PartyCard from "components/Card/partyCard";

import ResendContractHOC from "../../../../actions/resendContract";

const ProcessSection = ({
  status,
  can_update,
  can_create,
  tempPercentage,
  onLoadResend,
  signingStatusId,
  eProcessPartyData = [],
  selected_appointment_letter,
  menuExpand,

  getParty,
  getSigningStatus,
  onChangePartyHOC,
  downloadCompletedForm,
  resendContract,
  renderNavButton,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [recipient, setRecipient] = useState("");

  return (
    <div className={"mb-3"}>
      <div className="d-flex flex-wrap grid_gap-1 mb-3">
        {(signingStatusId === `3` || signingStatusId === `1`) && can_update && (
          <button
            className={"btn-new btn-new--outline-danger"}
            onClick={() => onChangePartyHOC(true, "showResetConfirmationModal")}>
            Reset the e-Signing process
          </button>
        )}
        {signingStatusId === `2` && (
          <div className="download-action-section">
            <button
              className="btn-new btn-new--secondary"
              onClick={() =>
                downloadCompletedForm(
                  selected_appointment_letter.id,
                  `${selected_appointment_letter.transaction_number}_e_process.pdf`,
                )
              }>
              {`Download Appointment Letter`}
            </button>
          </div>
        )}
        {signingStatusId === "1" && (
          <button
            className={"btn-new btn-new--secondary"}
            onClick={() => {
              getParty(selected_appointment_letter.id);
              getSigningStatus(selected_appointment_letter.id);
            }}>
            Check Status
          </button>
        )}
      </div>
      <div className="at-form__content d-flex flex-column grid_gap-2">
        <div className="d-flex flex-wrap grid_gap-1">
          <h5
            style={{ fontWeight: 500, height: "fit-content", margin: "auto 0" }}>
            {"Recipients in the signing process"}
          </h5>
          <button
            className={"btn-new btn-new--outline-grey color-black"}
            onClick={() => onChangePartyHOC(true, "showSigningHistory")}>
            View Activity
          </button>
        </div>
        <div className={"d-flex align-items-center"}>
          <Tooltip title={"Percentage of signed recipients"}>
            <div
              className={"d-flex justify-content-center align-items-center"}
              style={{ position: "relative", width: 40 }}>
              <CircularProgress
                size={40}
                style={{ background: "#FFFF", borderRadius: "50%" }}
                thickness={4}
                color={"primary"}
                variant="determinate"
                value={tempPercentage}/>
              <Typography
                style={{ position: "absolute" }}
                variant="caption"
                color="textSecondary">
                {`${parseInt(tempPercentage)}%`}
              </Typography>
            </div>
          </Tooltip>
          <strong
            style={{
              color: status === "Stopped" ? "#D93335" : "#2ECC71",
              margin: "0.3rem 0",
              marginLeft: 10,
            }}>
            e-Signing for appointment letter is now
            <span style={{ textTransform: "lowercase" }}>{` ${status}`}</span>
          </strong>
        </div>
        <h5 className={"at-card__description fs-2"}>
          Signing Order is set as below:
        </h5>
      </div>
      {eProcessPartyData.length > 0 && (
        <div className="party-card_cont mb-3">
          {eProcessPartyData.map((partyData, index) => (
            <PartyCard
              containerClass={"party-card-new"}
              key={index}
              index={index}
              type={"appointment"}
              data={partyData}
              can_create={can_create}
              resendContract={() =>
                resendContract({
                  form_id: selected_appointment_letter.id,
                  form_type: 3,
                  form_contact_id: partyData.form_contact_id,
                })
              }
              onClickShare={(val) => {
                setShareLink(val.signing_link);
                setRecipient(val.full_name);
                setShowShareModal(true);
              }}
              onClickRefresh={() => {
                getParty(selected_appointment_letter.id);
                getSigningStatus(selected_appointment_letter.id);
              }}
            />
          ))}
        </div>
      )}
      <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
        {signingStatusId === `1` && can_update && (
          <button
            disabled={
              selected_appointment_letter.isLocked ||
              !_.values(eProcessPartyData).some((val) => val.status_id === 2)
            }
            className={"btn-new btn-new--success"}
            onClick={() =>
              onChangePartyHOC(true, "showCompleteConfirmationModal")
            }
          >
            Complete e-Signing Process
          </button>
        )}
        {renderNavButton && renderNavButton()}
      </div>
      {showShareModal && (
        <ShareModal
          title={"Share the e-signing link"}
          shareLink={shareLink}
          containerClass={"at-share-modal"}
          containerStyle={{minHeight: "initial"}}
          encodedLink={`Hi ${recipient}, here is your Digital Appointment Letter for property at ${selected_appointment_letter.property_address}\n ${encodeURIComponent(shareLink)}`}
          showElement={["Whatsapp", "Telegram", "Open in New Tab"]}
          onClickClose={() => setShowShareModal(false)}
        />
      )}
      {onLoadResend && <LoadingModal />}
    </div>
  );
};

export default ResendContractHOC(ProcessSection);
