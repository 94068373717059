import React, { Component } from "react";
import _ from "lodash";
import bowser from "bowser";

import { connect } from "react-redux";
import { Get, Put } from "utils/axios";
import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { requestSuccess, requestError } from "utils/requestHandler";

const TrainingStatusModalHOC = (WrappedComponent) => {
  class TrainingStatusModalWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,

      showPaymentProofModal: false,
      showResignationLetterModal: false,

      approvalStatusContent: {},
      from: "",
      bankDetail: {},
      paymentProof: {},
      paymentProofFileName: "",
      paymentProofFileType: "",
      employmentProof: {},
      employmentProofFileName: "",
      employmentProofFileType: "",
      resignationLetter: {},
      resignationLetterFileName: "",
      resignationLetterType: "",
      uploadedPaymentUrl: "",
      uploadedResignationUrl: "",
      tabContentType: "Payment Proof",
      currencyCode: "",
    };

    load = (param) => this.setState({ loading: param });

    onChangeTrainingStatusHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeFile = (fileItems, file, fileName, fileType) => {
      if (fileItems.length > 0 && fileItems[0].file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.setState({
            [fileName]: fileItems[0].file.name,
            [file]: e.target.result,
            [fileType]: fileItems[0].file.type.split("/").pop(),
          });
        };
        reader.readAsDataURL(fileItems[0].file);
      } else {
        this.setState({
          [fileName]: {},
          [file]: "",
          [fileType]: "",
        });
      }
    };

    getApprovalStatus = () => {
      const { agent_status_id, status_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      Get(
        `/registrations/approval_status`,
        this.getApprovalStatusSuccess,
        this.getApprovalStatusError,
        this.load
      );
    };

    getApprovalStatusSuccess = (payload) => {
      const { payment_method_id, id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      this.setState(
        {
          approvalStatusContent: payload,
          currencyCode: payload.currency_code,
        },
        () => {
          if (payload.admin_check_status === 3) {
            let temp = "Resignation Letter";

            if (payload.has_payment_issue) {
              temp =
                payment_method_id === 3
                  ? "Proof Of Employment"
                  : "Payment Proof";
            }
            this.setState({ tabContentType: temp });
            this.getPaymentStatus(id);
          }
        }
      );
    };
    getApprovalStatusError = (error) => requestError(error);

    // reupload resignation letter
    onClickSubmitResignationLetter = () => {
      const { id, email } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      var result = bowser.getParser(window.navigator.userAgent);

      let tmp = {};
      tmp.id = id;
      tmp.resignation_letter = this.state.resignationLetter;
      tmp.resignation_letter_file_name = this.state.resignationLetterFileName;
      tmp.resignation_letter_file_type = this.state.resignationLetterType;
      tmp.device =
        "You are using " +
        result.parsedResult.browser.name +
        " v" +
        result.parsedResult.browser.version +
        " on " +
        result.parsedResult.os.name;
      tmp.agent_registration = email;

      Put(
        `/registrations/resignation_letter`,
        tmp,
        this.onClickSubmitResignationLetterSuccess,
        this.onClickSubmitResignationLetterFailed,
        this.load
      );
    };
    onClickSubmitResignationLetterSuccess = (payload) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      this.getPaymentStatus(id);
      requestSuccess(payload.message);
      this.getApprovalStatus();
    };
    onClickSubmitResignationLetterFailed = (error) => requestError(error);

    // reupload payment proof
    onClickSubmitPaymentProof = () => {
      const { id, payment_method_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      let tmp = {};
      tmp.id = id;

      if (payment_method_id === 3) {
        tmp.employment_proof = this.state.employmentProof;
        tmp.employment_proof_file_name = this.state.employmentProofFileName;
        tmp.employment_proof_file_type = this.state.employmentProofFileType;
      } else {
        tmp.payment_proof = this.state.paymentProof;
        tmp.payment_proof_file_name = this.state.paymentProofFileName;
        tmp.payment_proof_file_type = this.state.paymentProofFileType;
      }
      Put(
        `/registrations/payment`,
        tmp,
        this.onClickSubmitPaymentProofSuccess,
        this.onClickSubmitPaymentProofFailed,
        this.load
      );
    };
    onClickSubmitPaymentProofSuccess = (payload) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      this.getPaymentStatus(id);
      requestSuccess(payload.message);
      this.getApprovalStatus();
    };
    onClickSubmitPaymentProofFailed = (error) => requestError(error);

    // get payment status and file url
    getPaymentStatus = (val) => {
      Get(
        `/registrations/payment_method/${val}`,
        this.getProfileSuccess,
        this.getProfileFailed,
        this.load
      );
    };
    getProfileSuccess = (payload) =>
      this.setState({
        uploadedPaymentUrl: payload.payment_proof_url,
        uploadedResignationUrl: payload.resignation_letter_url,
      });
    getProfileFailed = (error) => requestError(error);

    getBankInformation = (id) =>
      Get(
        `/registrations/fee?branch_id=${id}`,
        this.getBankInformationSuccess,
        this.getBankInformationError,
        this.load
      );
    getBankInformationSuccess = (payload) =>
      this.setState({ bankDetail: payload });
    getBankInformationError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          from={this.state.from}
          bankDetail={this.state.bankDetail}
          currencyCode={this.state.currencyCode}
          employmentProof={this.state.employmentProof}
          employmentProofFileName={this.state.employmentProofFileName}
          employmentProofFileType={this.state.employmentProofFileType}
          tabContentType={this.state.tabContentType}
          resignationLetter={this.state.resignationLetter}
          resignationLetterFileName={this.state.resignationLetterFileName}
          resignationLetterType={this.state.resignationLetterType}
          paymentProof={this.state.paymentProof}
          uploadedPaymentUrl={this.state.uploadedPaymentUrl}
          uploadedResignationUrl={this.state.uploadedResignationUrl}
          showResignationLetterModal={this.state.showResignationLetterModal}
          showPaymentProofModal={this.state.showPaymentProofModal}
          onLoadTrainingStatus={this.state.loading}
          approvalStatusContent={this.state.approvalStatusContent}
          getApprovalStatus={this.getApprovalStatus}
          getPaymentStatus={this.getPaymentStatus}
          getBankInformation={this.getBankInformation}
          onChangeFile={this.onChangeFile}
          onClickSubmitResignationLetter={this.onClickSubmitResignationLetter}
          onClickSubmitPaymentProof={this.onClickSubmitPaymentProof}
          onChangeTrainingStatusHOC={this.onChangeTrainingStatusHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getCurrentUserProfile,
    getCurrentSignInProfile,
  })(TrainingStatusModalWrappedComponent);
};

export default TrainingStatusModalHOC;
