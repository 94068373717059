import React, { useState, useEffect } from "react";
import _, { debounce } from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { Select, MenuItem } from "@material-ui/core";
import CurrencyInput from "react-currency-input-field";

import AlertBox from "components/AlertBox";
import DuoTab from "components/Tab/duo";
import SpecialConditionCKEditor from "./specialCondition";
import FormDialog from "../../components/formDialog";

import { validationCheck } from "utils/checkNull";
import { PaymentTypes } from "../assets";
import subsaleForm from "../assets/subsalesLetter";

const TempSubsaleConfirmationForm = ({
  addressReducer = {
    address: [],
  },
  autoCalculatedList,
  menuExpand,
  FormTabs,
  onAutoCalculateDownPaymentPercentage,
  onChangeEditConfirmationForm,
  onChangeFocus,
  onChangeBlur,
  closedEditing,
  onChange,
  selectedConfirmationForm,
  saveTnC,
  onClose,
  renderNavButton,
}) => {
  const {
    extra_conditions,
    transaction_number,
    confirmation_date,
    property_address,
    purchase_price,
    sales_tax_percentage,
    down_payment_amount,
    down_payment_percentage,
    balance_pay_within_days,
    balance_pay_within_another_days,
    balance_deposit_amount,
    balance_deposit_percentage,
    balance_purchase_price,
    balance_purchase_price_percentage,
    chargeable_interest_percentage,
    execution_days,
    has_extra_conditions,
    special_conditions,
    earnest_deposit_amount,
    deposit_payment_method_id,
    deposit_cheque_number,
    stake_holder_id,
    stake_holder_company,
    stake_holder_registration_number,
    professional_fee_percentage,
    vacant_posession_day,
  } = selectedConfirmationForm;

  const [switchMode, setSwitchMode] = useState(
    window.innerWidth > 1024 ? "Contract View" : "Form View",
  );
  //View: 1. contract 2. formlist

  const tmpAddress = validationCheck(property_address) ? property_address : "";

  useEffect(() => {
    const onChangeViewMode = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 1024) {
        if (switchMode === "Contract View") {
          setSwitchMode("Form View");
        }
      } else if (screenWidth > 1024) {
        if (switchMode === "Form View") {
          setSwitchMode("Contract View");
        }
      }
    };

    const debounceOnChangeView = debounce(onChangeViewMode, 400);

    window.addEventListener("resize", debounceOnChangeView);
    return () => window.removeEventListener("resize", debounceOnChangeView);
  }, [window.innerWidth]);

  const renderExtraConditionCheck = () => (
    <>
      <AlertBox
        mode={"info"}
        title={""}
        containerClassName="mb-3"
        description={
          <>
            <div style={{ fontSize: 14 }}>
              Untick the checkbox below will print your special conditions right
              after clauses 13 continuously.
            </div>
            <div style={{ fontSize: 14 }}>
              Tick the checkbox below will print your special conditions in a
              new page.
            </div>
          </>
        }
      />
      <div
        style={{ display: "flex", alignItems: "start", marginBottom: "1rem" }}
      >
        <input
          style={{ marginRight: 10, marginTop: "0.35rem" }}
          type={"checkbox"}
          id={"extra_condition"}
          name={"extra_condition"}
          checked={has_extra_conditions}
          onChange={(e) => onChange("has_extra_conditions", e.target.checked)}
          disabled={closedEditing}
        />
        <span for={"extra_condition"}>
          {" "}
          I want the special conditions in a new page.{" "}
        </span>
      </div>
    </>
  );

  const renderContractLayout = () => (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-end">
        <h5 className="fw-600"> Letter of Confirmation For Sale </h5>
        <div style={{ zIndex: "999" }}>
          <p style={{ marginBottom: 10 }}>{` ${transaction_number}`}</p>
          Date:{" "}
          <DatePicker
            className="subsales-datepicker"
            popperPlacement={"bottom-end"}
            value={
              Moment(confirmation_date).isValid()
                ? Moment(confirmation_date).format("DD MMM YYYY")
                : confirmation_date
            }
            onChange={(val) =>
              onChange("confirmation_date", Moment(val).format("YYYY-MM-DD"))
            }
            placeholderText={"DD MMM YYYY"}
            disabled={closedEditing}
            calendarClassName="react-datepicker-popper"
          />
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        By executing this Letter of Confirmation, both Purchaser(s) and the
        Vendor(s) unequivocally agreed that the entire terms and conditions of
        this Letter shall be read collaboratively to be applicable to both
        parties where explicitly specified.
      </div>
      <hr />
      <div style={{ marginBottom: 10 }}>
        Purchaser(s):
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section)`}
        </span>
      </div>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        having inspected the said property mentioned below, hereby offer to
        purchase the said property on an "As is Where Is Basis" with the
        following terms and conditions:-
      </div>
      <ol>
        <li>
          <div className="d-flex flex-wrap">
            <p className="mr-1">Address of said Property:</p>
            <p className="fw-600">{tmpAddress || "N/A"}</p>
          </div>
        </li>
        <li>
          Purchase Price: RM
          <CurrencyInput
            className="subsales-letter-input"
            style={{ paddingLeft: "0.2rem" }}
            name={"purchase_price"}
            defaultValue={1000}
            disabled={closedEditing}
            value={purchase_price}
            onBlur={() => onChangeBlur("purchase_price")}
            onFocus={() => onChangeFocus("purchase_price")}
            onValueChange={(value) => onChange("purchase_price", value)}
          />
          only.
        </li>
        <li>
          The Parties agree that the payments will be as follows:-
          <ol type="a">
            <li>
              Earnest Deposit of RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"down_payment_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={down_payment_amount}
                onBlur={() => onChangeBlur("down_payment_amount")}
                onFocus={() => onChangeFocus("down_payment_amount")}
                onValueChange={(value) =>
                  onChange("down_payment_amount", value)
                }
              />
              being
              <input
                type={"number"}
                className="subsales-letter-input"
                value={down_payment_percentage}
                onBlur={() => onChangeBlur("down_payment_percentage")}
                onFocus={() => onChangeFocus("down_payment_percentage")}
                onChange={(e) =>
                  onChange("down_payment_percentage", e.target.value)
                }
                disabled={closedEditing}
              />{" "}
              % of the Purchase Price, upon signing of this Letter of
              Confirmation for Sale;
            </li>
            <li>
              Balance Deposit of RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"balance_deposit_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={balance_deposit_amount}
                onBlur={() => onChangeBlur("balance_deposit_amount")}
                onFocus={() => onChangeFocus("balance_deposit_amount")}
                onValueChange={(value) =>
                  onChange("balance_deposit_amount", value)
                }
              />
              being
              <input
                type={"number"}
                className="subsales-letter-input"
                value={balance_deposit_percentage}
                onBlur={() => onChangeBlur("balance_deposit_percentage")}
                onFocus={() => onChangeFocus("balance_deposit_percentage")}
                onChange={(e) =>
                  onChange("balance_deposit_percentage", e.target.value)
                }
                disabled={closedEditing}
              />
              % of the Purchase Price, upon signing of the Sale And Purchase
              Agreement;and
            </li>
            <li>
              Balance Purchase Price of RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"balance_purchase_price"}
                defaultValue={1000}
                disabled={closedEditing}
                value={balance_purchase_price}
                onBlur={() => onChangeBlur("balance_purchase_price")}
                onFocus={() => onChangeFocus("balance_purchase_price")}
                onValueChange={(value) =>
                  onChange("balance_purchase_price", value)
                }
              />
              being
              <input
                type={"number"}
                className="subsales-letter-input"
                value={balance_purchase_price_percentage}
                onBlur={() => onChangeBlur("balance_purchase_price_percentage")}
                onFocus={() =>
                  onChangeFocus("balance_purchase_price_percentage")
                }
                onChange={(e) =>
                  onChange("balance_purchase_price_percentage", e.target.value)
                }
                disabled={closedEditing}
              />
              %) of the Purchase Price, within
              <input
                type={"number"}
                className="subsales-letter-input"
                value={balance_pay_within_days}
                onBlur={() => onChangeBlur("balance_pay_within_days")}
                onFocus={() => onChangeFocus("balance_pay_within_days")}
                onChange={(e) =>
                  onChange("balance_pay_within_days", e.target.value)
                }
                disabled={closedEditing}
              />
              days [from the date of the Sale and Purchase Agreement, or from
              the date of the Purchaser’s Or Purchaser’s Solicitors receipt of
              the Letter of Consent to Transfer from the State Authority/Consent
              from the Developer, as the case may be, and whichever is later].
              Upon expiration of the
              <input
                className="subsales-letter-input"
                value={balance_pay_within_days}
                type="number"
                disabled={closedEditing}
                onBlur={() => onChangeBlur("balance_pay_within_days")}
                onFocus={() => onChangeFocus("balance_pay_within_days")}
                onChange={(e) =>
                  onChange("balance_pay_within_days", e.target.value)
                }
                style={{ width: "80px" }}
              />
              days, if any amounts remain unpaid in respect of the Balance
              Purchase Price, to grant an extension period of up to
              <input
                type={"number"}
                className="subsales-letter-input"
                value={balance_pay_within_another_days}
                onBlur={() => onChangeBlur("balance_pay_within_another_days")}
                onFocus={() => onChangeFocus("balance_pay_within_another_days")}
                onChange={(e) =>
                  onChange("balance_pay_within_another_days", e.target.value)
                }
                disabled={closedEditing}
              />
              days at an interest rate of
              <input
                type={"number"}
                className="subsales-letter-input"
                value={chargeable_interest_percentage}
                onBlur={() => onChangeBlur("chargeable_interest_percentage")}
                onFocus={() => onChangeFocus("chargeable_interest_percentage")}
                onChange={(e) =>
                  onChange("chargeable_interest_percentage", e.target.value)
                }
                disabled={closedEditing}
              />
              % per annum calculated on a daily basis.
            </li>
          </ol>
        </li>
        <li>
          Vacant Possession to be given within
          <input
            className="subsales-letter-input"
            type="number"
            value={vacant_posession_day}
            onBlur={() => onChangeBlur("vacant_posession_day")}
            onFocus={() => onChangeFocus("vacant_posession_day")}
            onChange={(e) => onChange("vacant_posession_day", e.target.value)}
            disabled={closedEditing}
          />
          days upon the Vendor or Vendor’s Solicitors receipt of the Balance
          Purchase Price.
        </li>
        <li>
          Execution:
          <ol type={"a"}>
            <li>
              I/We hereby undertake to execute the Sale and Purchase Agreement
              within
              <input
                type={"number"}
                className="subsales-letter-input"
                value={execution_days}
                onBlur={() => onChangeBlur("execution_days")}
                onFocus={() => onChangeFocus("execution_days")}
                onChange={(e) => onChange("execution_days", e.target.value)}
                disabled={closedEditing}
              />{" "}
              working days from the date of acceptance of this offer by the
              Vendor, failing to do so will result in the Earnest Deposit being
              forfeited and this agreement shall be treated as null and void.
              Thereafter, neither party shall have any rights or claims
              whatsoever over the other party.
            </li>
            <li>
              In the event that the title, for any reason whatsoever, cannot be
              transferred to the Purchaser(s) (Except where there is a default
              on the part of the Purchaser), the Vendor(s) shall refund the
              Earnest Deposit to the Purchaser(s) forthwith.
            </li>
          </ol>
        </li>
        <li>
          Purchaser(s) Acceptance:
          <ol type={"i"}>
            <li>
              I/We hereby attach the sum of RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"earnest_deposit_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={earnest_deposit_amount}
                onBlur={() => onChangeBlur("earnest_deposit_amount")}
                onFocus={() => onChangeFocus("earnest_deposit_amount")}
                onValueChange={(value) =>
                  onChange("earnest_deposit_amount", value)
                }
              />
              by {deposit_payment_method_id === 2 && `A/C Payee `}
              <Select
                style={{ width: "150px" }}
                disabled={closedEditing}
                value={deposit_payment_method_id}
                onChange={(e) =>
                  onChange("deposit_payment_method_id", e.target.value)
                }
              >
                {PaymentTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>{" "}
              {deposit_payment_method_id === 2 && (
                <>
                  (Cheque Number:
                  <input
                    className="subsales-letter-input"
                    value={deposit_cheque_number || ""}
                    type={"text"}
                    onChange={(e) =>
                      onChange("deposit_cheque_number", e.target.value)
                    }
                    disabled={closedEditing}
                  />
                  )
                </>
              )}
              payable to (
              <input
                className="mr-2"
                type={"radio"}
                name={"party-selection"}
                value={1}
                checked={stake_holder_id === 1}
                onChange={(e) =>
                  onChange("stake_holder_id", parseInt(e.target.value, 10))
                }
                disabled={closedEditing}
              />
              {`IQI REALTY SDN BHD `}
              <input
                className="mx-2"
                type={"radio"}
                name={"party-selection"}
                value={2}
                checked={stake_holder_id === 2}
                onChange={(e) =>
                  onChange("stake_holder_id", parseInt(e.target.value, 10))
                }
                disabled={closedEditing}
              />
              {`Lawyer)`}{" "}
              {stake_holder_id === 2 && (
                <div style={{ border: "1px solid #000", padding: 10 }}>
                  <div>
                    <span> Lawyer Company Name: </span>
                    <input
                      type={"text"}
                      className="subsales-letter-input"
                      style={{ width: "500px" }}
                      value={stake_holder_company ? stake_holder_company : ""}
                      onChange={(e) =>
                        onChange("stake_holder_company", e.target.value)
                      }
                      disabled={closedEditing}
                    />
                  </div>
                  <div>
                    <span> Lawyer Company Registration Number: </span>
                    <input
                      type={"text"}
                      className="subsales-letter-input"
                      style={{ width: "500px" }}
                      value={
                        stake_holder_registration_number
                          ? stake_holder_registration_number
                          : ""
                      }
                      onChange={(e) =>
                        onChange(
                          "stake_holder_registration_number",
                          e.target.value,
                        )
                      }
                      disabled={closedEditing}
                    />
                  </div>
                </div>
              )}
              as stakeholder, being the Earnest Deposit for the purchase of the
              said property. In the event that the Vendor(s) reject(s) this
              offer, the said Earnest Deposit is to be refundable to the
              Purchaser(s) within five (5) working days.
            </li>
            <li>
              I/We hereby further agree that in the event of the transaction
              being aborted by the Vendor(s) for whatsoever reason, IQI REALTY
              SDN BHD is entitled to 50% of any liquidated damages compensated
              by the Vendor(s) to me/us.
            </li>
          </ol>
        </li>
        <li>
          Vendor(s) Acceptance:
          <br />
          I/We hereby accept the offer as presented above and also undertake to
          sign the Sale and Purchase Agreement within the said period as stated
          in paragraph 5(a) above. In the event that I/We reject this offer for
          any reason whatsoever after signing of this letter of confirmation,
          I/We undertake to refund the Earnest Deposit, and in addition, I/We
          shall pay liquidated damages or equivalent sum of the Earnest Deposit
          to the Purchaser(s) as compensation, or the Purchaser(s) shall be
          entitled to claim the remedy of specific performance against me/us at
          my/our own costs and expenses. However, the liquidated damages shall
          not be paid by me/us when the title cannot be transfered/the consent
          cannot be obtained by the developer/In the hands of relevant
          authorities which are not within my/our control. I/We, the Vendor(s)
          agree, and undertake to pay the standard professional fee of
          <input
            type={"number"}
            className="subsales-letter-input"
            value={professional_fee_percentage}
            onBlur={() => onChangeBlur("professional_fee_percentage")}
            onFocus={() => onChangeFocus("professional_fee_percentage")}
            onChange={(val) =>
              onChange("professional_fee_percentage", val.target.value)
            }
            disabled={closedEditing}
          />
          % of the purchase price upon signing the Sale and Purchase Agreement,
          plus
          <input
            type={"number"}
            className="subsales-letter-input"
            value={sales_tax_percentage}
            onBlur={() => onChangeBlur("sales_tax_percentage")}
            onFocus={() => onChangeFocus("sales_tax_percentage")}
            onChange={(val) =>
              onChange("sales_tax_percentage", val.target.value)
            }
            disabled={closedEditing}
          />
          % SST on the mentioned fee. We hereby authorize IQI REALTY SDN BHD to
          collect the deposit and to act as our stakeholder for the deposit.
          I/We irrevocably instruct IQI Realty Sdn Bhd to deduct the agreed
          Professional Fee from the Earnest Deposit and release the remaining
          Earnest Deposit (if any) to us and/or our appointed lawyer once the
          Sale and Purchase Agreement is signed and stamped. In the event that 
          the Earnest Deposit is paid but the transaction is aborted by either 
          Purchaser or Vendor before the Sale and Purchase Agreement is signed, 
          I/We, the Vendor, nevertheless agree to pay IQI Realty Sdn Bhd a fee of 
          50% of the Earnest Deposit or forfeitable deposit.
        </li>
        <li>
          I/We (Purchaser & Vendor) fully indemnify IQI REALTY SDN BHD against
          all claims by any party, if for any reason whatsoever, the Sale and
          Purchase Agreement is not executed after the Earnest Deposit has been
          collected.{" "}
        </li>
        <li>
          Additional conditions:
          <input
            className="subsales-letter-input"
            type="text"
            style={{ width: "100%", maxWidth: "100%" }}
            placeholder={
              has_extra_conditions &&
              "If the condition is short enough to put in one line, write it here"
            }
            value={special_conditions || ""}
            onChange={(e) => {
              return has_extra_conditions
                ? () => {}
                : onChange("special_conditions", e.target.value);
            }}
            disabled={has_extra_conditions || closedEditing}
          />
          <div>
            <span style={{ color: "red", marginLeft: 5 }}>
              If you have conditions that more than one line, please key in
              conditions in the field "Edit your special conditions here" right
              after clauses 13.
            </span>
          </div>
        </li>
        <li>
          This Letter of Confirmation for Sale shall only be effective upon
          being duly signed by the Vendor(s) and *Purchaser(s).
        </li>
        <li>
          All signatories to this document hereby acknowledge and agree to comply with the Malaysia Anti-Corruption Commission Act 2009 and Amendment Act - Section 17A of the MACC Act 2009 (MACC 2009), Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 (AML/CFT), 
          Personal Data Protection Act 2010 and further authorise IQI Realty Sdn Bhd and/or its group companies to disclose their information to third parties as necessary, inter alia, 
          at the request of regulatory authorities. For reference, parties may refer to our website at <a target="_blank" href="https://www.iqiglobal.com/amla">https://www.iqiglobal.com/amla</a> and <a target="_blank" href="https://www.iqiglobal.com/privacy-policy">https://www.iqiglobal.com/privacy-policy</a>.
        </li>
        <li>
          Each party further represents and agrees that each has duly reviewed
          all aspects of this Letter and fully understands the terms and
          conditions thereof, has authority to enter into this Letter and has
          executed this Letter based upon such party’s own judgment, and
          knowingly, voluntarily, and without duress, agrees to all of the terms
          set forth in this Letter.
        </li>
        <li>
          All payments/cheques should be made directly to{" "}
          <b>IQI REALTY SDN BHD</b>. Bank:{" "}
          <b>MAYBANK, ACC NO: 5641-6444-1931</b> (Client Account). Should you
          require a receipt, please ask for a receipt of the total amount paid
          and highlight any discrepancy between the receipt and the amount paid
          (should there be any).
        </li>
      </ol>
    </>
  );

  const renderFormListLayout = () => {
    const { address = [] } = addressReducer;
    return (
      <FormDialog
        formData={subsaleForm({ address })}
        states={{ autoCalculated: onAutoCalculateDownPaymentPercentage }}
        data={selectedConfirmationForm}
        autoCalculatedList={autoCalculatedList}
        onChangeBlur={onChangeBlur}
        onChangeFocus={onChangeFocus}
        closedEditing={closedEditing}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      <div className="at-form__content at-new_container-20">
        <h5 className="at-card_content-title">{"Letter Of Confirmation"}</h5>
        <p className="at-content-subtitle">
          Enter the details of the form below. ‘Form View’ provides a simplified
          version of the contract allowing you to quickly enter details on your
          mobile phone.
        </p>
      </div>
      <DuoTab
        sections={FormTabs}
        selectedSection={switchMode}
        onSelectSection={(val) => {
          setSwitchMode(val);
          if (val === "Contract View") {
            onChangeEditConfirmationForm(
              "onAutoCalculateDownPaymentPercentage",
              true,
            );
          } else {
            onChangeEditConfirmationForm(
              "onAutoCalculateDownPaymentPercentage",
              false,
            );
          }
        }}
      />
      <div
        className="at-form__content at-form-tnc bg-white mb-3 p-3"
        style={{ borderRadius: "0.375rem" }}
      >
        {switchMode === "Contract View" && renderContractLayout()}
        {switchMode === "Form View" && renderFormListLayout()}
      </div>
      <div className="at-form__content">
        <SpecialConditionCKEditor
          type={"subsale"}
          closedEditing={closedEditing}
          additionalContent={renderExtraConditionCheck()}
          conditionText={extra_conditions}
          updateConditionText={(param) => onChange("extra_conditions", param)}
        />
      </div>
      <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
        <button disabled={closedEditing} className={`btn-new btn-new--success`} onClick={saveTnC}>
          Save Terms & Conditions
        </button>
        {renderNavButton && renderNavButton()}
      </div>
    </>
  );
};

export default TempSubsaleConfirmationForm;
