import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import {
  categories,
  bedroom_options,
  bathroom_options,
  car_park_options,
  tenure_types,
  title_types,
  unit_types,
  furnishing_statuses,
  directions,
  occupancies,
  measurements,
} from "dictionary/subsales";

import { requestError } from "utils/requestHandler";

const iPropertyType = [
  1, 2, 3, 4, 5, 6, 10, 11, 75, 76, 77, 78, 79, 80, 81, 82,
];

const DefaultCobrokeSetting = {
  co_broke_portion_by: "percentage",
  total_portion: 0,
  my_portion: 0,
  balance: 0,
};

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],

      selectedCategory: {},
      tmpPropertyTypes: [],
      selectedPropertyType: {},
      address: "",
      property_name: "",
      selectedCountry: {},
      selectedState: {},
      postalCode: "",
      township: "",
      sub_area: "",
      description: "",
      zh_description: "",
      hidden_description: "",
      code: "",

      selectedMeasurement: {},
      builtUp: 0,
      landArea: "",
      selectedCurrency: {},
      askingPrice: "",
      askingPricePerMeasurementUnit: "",

      availableDate: null,
      highriseName: "",
      hidden_address: "",
      selectedBedroomOption: {},
      selectedBathroomOption: {},
      selectedCarparkOption: {},
      selectedTenureType: {},
      selectedTitleType: {},
      selectedUnitType: {},
      selectedFurnishingStatus: {},
      selectedDirection: {},
      selectedOccupancies: {},
      all_day_security: false,
      bbq: false,
      business_centre: false,
      cafetria: false,
      club_house: false,
      gymnasium: false,
      jacuzzi: false,
      jogging_track: false,
      mini_market: false,
      nursery: false,
      parking: false,
      playground: false,
      salon: false,
      sauna: false,
      squash_court: false,
      swimming_pool: false,
      tennis_court: false,
      wading_pool: false,
      otherFacilities: "",
      selectedStatus: 1,
      monthlyMaintainanceFee: "",
      isMonthlyMaintainanceFeePerMeasurementUnit: false,
      youtubeLink: "",
      juwai_link: "",
      edge_prop_asset_id: "",
      exported_iqi_link: "",
      exported_juwai_asia_link: "",
      isPublishedIQI: false,
      isPublishedEdge: false,
      isExportedIProperty: false,
      isPublishedJuwai: false,
      isExportedJuwai: false,
      open_for_internal_co_broke: true,
      co_broke_settings: DefaultCobrokeSetting,

      showLoadingModal: false,
    };

    processUserSubsales = () => {
      const { countries, currencies } = this.props.data.dictionaryReducer;
      const {
        type_id,
        category_id,
        property_type_id,
        address,
        property_name,
        code,
        hidden_address,
        bathrooms,
        bedrooms,
        tenure_id,
        country_id,
        asking_price_currency,
        state_id,
        postal_code,
        township,
        sub_area,
        description,
        zh_description,
        hidden_description,
        car_parks,
        title_type_id,
        unit_type_id,
        furnishing_status_id,
        direction_id,
        occupancy_id,
        available_date,
        all_day_security,
        bbq,
        business_centre,
        cafetria,
        club_house,
        gymnasium,
        jacuzzi,
        jogging_track,
        mini_market,
        nursery,
        parking,
        playground,
        salon,
        sauna,
        squash_court,
        swimming_pool,
        tennis_court,
        wading_pool,
        high_rise_name,
        measurement_id,
        built_up,
        land_area,
        asking_price_cents,
        asking_price_per_measurement_unit_cents,
        monthly_maintainance_fee_cents,
        is_monthly_maintainance_fee_per_measurement_unit,
        youtube_link,
        status_id,
        published_to_iqi,
        published_to_juwai,
        published_to_edge_prop,
        juwai_link,
        exported_to_juwai,
        exported_iqi_link,
        exported_juwai_asia_link,
        other_facilities,
        exported_iproperty_link,
        open_for_internal_co_broke,
        edge_prop_asset_id = "",
        co_broke_settings,
      } = this.props.subsalesEntryCreationProps;
      if (category_id) {
        let selectedCategory = _.find(categories, { id: category_id });
        this.setState({ selectedCategory: selectedCategory });
        this.processPropertyType(selectedCategory, property_type_id);
      }
      if (country_id) {
        let selectedCountry = _.find(countries, { id: country_id });
        let selectedState = _.find(
          selectedCountry ? selectedCountry.states : [],
          { id: state_id },
        );
        this.setState({
          selectedCountry: selectedCountry ? selectedCountry : [],
          selectedState: selectedState,
        });
      }
      if (measurement_id) {
        let selectedMeasurement = _.find(measurements, { id: measurement_id });
        this.setState({ selectedMeasurement: selectedMeasurement });
      }
      if (bathrooms !== null) {
        let selectedBathroomOption = _.find(bathroom_options, {
          id: bathrooms,
        });
        this.setState({ selectedBathroomOption: selectedBathroomOption });
      }
      if (bedrooms !== null) {
        let selectedBedroomOption = _.find(bedroom_options, { id: bedrooms });
        this.setState({ selectedBedroomOption: selectedBedroomOption });
      }
      if (tenure_id) {
        let selectedTenureType = _.find(tenure_types, { id: tenure_id });
        this.setState({ selectedTenureType: selectedTenureType });
      }
      if (car_parks !== null) {
        let selectedCarparkOption = _.find(car_park_options, { id: car_parks });
        this.setState({ selectedCarparkOption: selectedCarparkOption });
      }
      if (title_type_id) {
        let selectedTitleType = _.find(title_types, { id: title_type_id });
        this.setState({ selectedTitleType: selectedTitleType });
      }
      if (unit_type_id) {
        let selectedUnitType = _.find(unit_types, { id: unit_type_id });
        this.setState({ selectedUnitType: selectedUnitType });
      }
      if (furnishing_status_id) {
        let selectedFurnishingStatus = _.find(furnishing_statuses, {
          id: furnishing_status_id,
        });
        this.setState({ selectedFurnishingStatus: selectedFurnishingStatus });
      }
      if (direction_id) {
        let selectedDirection = _.find(directions, { id: direction_id });
        this.setState({ selectedDirection: selectedDirection });
      }
      if (occupancy_id) {
        let selectedOccupancies = _.find(occupancies, { id: occupancy_id });
        this.setState({ selectedOccupancies: selectedOccupancies });
      }
      if (available_date) {
        this.setState({ availableDate: available_date || null });
      }
      this.setState({
        highriseName: high_rise_name,
        type_id,
        address,
        property_name,
        code: code,
        hidden_address,
        postalCode: postal_code,
        township: township,
        sub_area,
        description: description,
        zh_description,
        hidden_description,
        selectedStatus: status_id,
        builtUp: built_up,
        landArea: land_area,
        selectedCurrency: _.find(currencies ? currencies.name : "", {
          value: asking_price_currency,
        }),
        askingPrice: asking_price_cents,
        askingPricePerMeasurementUnit: asking_price_per_measurement_unit_cents,
        monthlyMaintainanceFee: monthly_maintainance_fee_cents,
        isMonthlyMaintainanceFeePerMeasurementUnit:
          is_monthly_maintainance_fee_per_measurement_unit,
        all_day_security,
        bbq,
        business_centre,
        cafetria,
        club_house,
        gymnasium,
        jacuzzi,
        jogging_track,
        mini_market,
        nursery,
        parking,
        playground,
        salon,
        sauna,
        squash_court,
        swimming_pool,
        tennis_court,
        wading_pool,
        youtubeLink: youtube_link,
        juwai_link,
        exported_iqi_link,
        edge_prop_asset_id: edge_prop_asset_id,
        exported_juwai_asia_link: exported_juwai_asia_link,
        isPublishedIQI: published_to_iqi,
        isPublishedEdge: published_to_edge_prop,
        isPublishedJuwai: published_to_juwai,
        isExportedJuwai: exported_to_juwai,
        isExportedIProperty: exported_iproperty_link,
        otherFacilities: other_facilities,
        open_for_internal_co_broke,
        co_broke_settings: co_broke_settings || DefaultCobrokeSetting,
      });
    };

    processPropertyType = (selectedCategory, property_type_id) => {
      let tmpPropertyTypes = selectedCategory
        ? selectedCategory.group_types.map((item) => {
            let children = item.children.map((child) => {
              let tmpData = {
                id: child.id,
                name: child.name,
                label: child.name,
                value: child.id,
                groupName: item.name,
              };
              return tmpData;
            });
            let tmpParent = {
              name: item.name,
              children: children,
            };
            return tmpParent;
          })
        : [];
      let selectedPropertyType = {};
      tmpPropertyTypes.map((item) => {
        item.children.map((child) => {
          if (child.id === property_type_id) {
            selectedPropertyType = child;
          }
        });
      });
      this.setState({
        tmpPropertyTypes: tmpPropertyTypes,
        selectedPropertyType: selectedPropertyType,
      });
    };

    onChangeValue = (title, val) => {
      switch (title) {
        case "Property Types":
          return this.setState({ selectedPropertyType: val }, () => {
            if (iPropertyType.indexOf(val.id) === -1) {
              this.setState({ property_name: "" });
              this.props.onChangeSubsalesHOC([], "propertyOptions");
            }
          });
        case "Subsale Type":
          return this.setState({ type_id: val });
        case "Highrise":
          return this.setState({ highriseName: val });
        case "Address":
          return this.setState({ address: val });
        case "Property Name":
          return this.setState({ property_name: val });
        case "Hidden Address":
          return this.setState({ hidden_address: val });
        case "Country":
          return this.setState(
            {
              selectedCountry: val,
              selectedState: {},
              township: "",
              sub_area: "",
            },
            () => {
              this.props.onChangeSubsalesHOC([], "townshipArray");
              this.props.onChangeSubsalesHOC([], "subAreaArray");
              if (val.id !== 1) {
                this.props.onChangeSubsalesHOC([], "propertyOptions");
              }
            },
          );
        case "State":
          return this.setState(
            { selectedState: val, township: "", sub_area: "" },
            () => {
              this.props.getSelectedTownShip(val.id);
            },
          );
        case "Post Code":
          return this.setState({ postalCode: val });
        case "Township":
          return this.setState({ township: val, sub_area: "" }, () =>
            this.props.getSelectedSubArea(val),
          );
        case "Sub Area":
          return this.setState({ sub_area: val });
        case "Measurement Unit":
          return this.setState({ selectedMeasurement: val });
        case "Built Up":
          return this.setState({ builtUp: val });
        case "Land Area":
          return this.setState({ landArea: val });
        case "Currency":
          return this.setState({ selectedCurrency: val });
        case "Asking Price":
          return this.setState({ askingPrice: val });
        case "Asking Price Per Measurement Unit":
          return this.setState({ askingPricePerMeasurementUnit: val });
        case "Monthly Maintenance Fee":
          return this.setState({ monthlyMaintainanceFee: val });
        case "Code":
          return this.setState({ code: val });

        case "Bedrooms":
          return this.setState({ selectedBedroomOption: val });
        case "Bathrooms":
          return this.setState({ selectedBathroomOption: val });
        case "Carparks":
          return this.setState({ selectedCarparkOption: val });
        case "Tenure":
          return this.setState({ selectedTenureType: val });
        case "Title Type":
          return this.setState({ selectedTitleType: val });
        case "Unit Type":
          return this.setState({ selectedUnitType: val });
        case "Furnishing Status":
          return this.setState({ selectedFurnishingStatus: val });
        case "Direction":
          return this.setState({ selectedDirection: val });
        case "Occupancies":
          return this.setState({ selectedOccupancies: val });
        case "Status":
          return this.setState({ selectedStatus: val });

        case "Youtube Link":
          return this.setState({ youtubeLink: val });
        case "Other Facilities":
          return this.setState({ otherFacilities: val });

        case "Description":
          return this.setState({ description: val });
        case "Edge Prop Asset ID":
          return this.setState({ edge_prop_asset_id: val });
        case "Chinese Description":
          return this.setState({ zh_description: val });
        case "Hidden Description":
          return this.setState({ hidden_description: val });
      }
    };

    onCheckCheckboxValue = (title) => {
      switch (title) {
        case "All Day Security":
          return this.setState((prevState) => ({
            all_day_security: !prevState.all_day_security,
          }));
        case "BBQ":
          return this.setState((prevState) => ({ bbq: !prevState.bbq }));
        case "Business Centre":
          return this.setState((prevState) => ({
            business_centre: !prevState.business_centre,
          }));
        case "Cafeteria":
          return this.setState((prevState) => ({
            cafetria: !prevState.cafetria,
          }));
        case "Club House":
          return this.setState((prevState) => ({
            club_house: !prevState.club_house,
          }));
        case "Gymnasium":
          return this.setState((prevState) => ({
            gymnasium: !prevState.gymnasium,
          }));
        case "Jacuzzi":
          return this.setState((prevState) => ({
            jacuzzi: !prevState.jacuzzi,
          }));
        case "Jogging Track":
          return this.setState((prevState) => ({
            jogging_track: !prevState.jogging_track,
          }));
        case "Mini Market":
          return this.setState((prevState) => ({
            mini_market: !prevState.mini_market,
          }));
        case "Nursery":
          return this.setState((prevState) => ({
            nursery: !prevState.nursery,
          }));
        case "Parking":
          return this.setState((prevState) => ({
            parking: !prevState.parking,
          }));
        case "Playground":
          return this.setState((prevState) => ({
            playground: !prevState.playground,
          }));
        case "Salon":
          return this.setState((prevState) => ({ salon: !prevState.salon }));
        case "Sauna":
          return this.setState((prevState) => ({ sauna: !prevState.sauna }));
        case "Squash Court":
          return this.setState((prevState) => ({
            squash_court: !prevState.squash_court,
          }));
        case "Swimming Pool":
          return this.setState((prevState) => ({
            swimming_pool: !prevState.swimming_pool,
          }));
        case "Tennis Court":
          return this.setState((prevState) => ({
            tennis_court: !prevState.tennis_court,
          }));
        case "Wading Pool":
          return this.setState((prevState) => ({
            wading_pool: !prevState.wading_pool,
          }));
        case "Monthly maintainance fee per measurement unit":
          return this.setState((prevState) => ({
            isMonthlyMaintainanceFeePerMeasurementUnit:
              !prevState.isMonthlyMaintainanceFeePerMeasurementUnit,
          }));
        case "Is Published IQI":
          return this.setState((prevState) => ({
            isPublishedIQI: !prevState.isPublishedIQI,
          }));
        case "Is Published Juwai":
          return this.setState((prevState) => ({
            isPublishedJuwai: !prevState.isPublishedJuwai,
          }));
        case "Is Published EdgeProp":
          return this.setState((prevState) => ({
            isPublishedEdge: !prevState.isPublishedEdge,
          }));
      }
    };

    onClickUpdateDetails = () => {
      const { id } = this.props.subsalesEntryCreationProps;
      let dataToSubmit = {
        listing: {
          code: this.state.code,
          type_id: this.state.type_id,
          property_name: this.state.property_name,
          category_id: this.state.selectedCategory.id,
          property_type_id: this.state.selectedPropertyType.id,
          country_id: this.state.selectedCountry.id,
          state_id: this.state.selectedState ? this.state.selectedState.id : "",
          edge_prop_asset_id: this.state.edge_prop_asset_id || "",
          description: this.state.description ? this.state.description : "",
          zh_description: this.state.zh_description
            ? this.state.zh_description
            : "",
          hidden_description: this.state.hidden_description
            ? this.state.hidden_description
            : "",
          township: this.state.township,
          sub_area: this.state.sub_area,
          high_rise_name: this.state.highriseName,
          postal_code: this.state.postalCode,
          address: this.state.address,
          hidden_address: this.state.hidden_address,
          measurement_id: this.state.selectedMeasurement.id,
          built_up: this.state.builtUp,
          land_area: this.state.landArea,
          asking_price_currency: this.state.selectedCurrency
            ? this.state.selectedCurrency.value
            : "",
          asking_price_cents: this.state.askingPrice,
          asking_price_per_measurement_unit_cents:
            this.state.askingPricePerMeasurementUnit,
          // remark, [ not needed ]
          bedrooms: this.state.selectedBedroomOption
            ? this.state.selectedBedroomOption.id
            : "",
          bathrooms: this.state.selectedBathroomOption
            ? this.state.selectedBathroomOption.id
            : "",
          car_parks: this.state.selectedCarparkOption
            ? this.state.selectedCarparkOption.id
            : "",
          tenure_id: this.state.selectedTenureType
            ? this.state.selectedTenureType.id
            : "",
          title_type_id: this.state.selectedTitleType
            ? this.state.selectedTitleType.id
            : "",
          // land_title_type_id, [ignore]
          furnishing_status_id: this.state.selectedFurnishingStatus
            ? this.state.selectedFurnishingStatus.id
            : "",
          direction_id: this.state.selectedDirection
            ? this.state.selectedDirection.id
            : "",
          occupancy_id: this.state.selectedOccupancies
            ? this.state.selectedOccupancies.id
            : "",
          unit_type_id: this.state.selectedUnitType
            ? this.state.selectedUnitType.id
            : "",
          monthly_maintainance_fee_cents: this.state.monthlyMaintainanceFee,
          is_monthly_maintainance_fee_per_measurement_unit:
            this.state.isMonthlyMaintainanceFeePerMeasurementUnit,

          youtube_link: this.state.youtubeLink,
          available_date: this.state.availableDate,

          bbq: this.state.bbq,
          parking: this.state.parking,
          jogging_track: this.state.jogging_track,
          playground: this.state.playground,
          squash_court: this.state.squash_court,
          tennis_court: this.state.tennis_court,
          business_centre: this.state.business_centre,
          gymnasium: this.state.gymnasium,
          mini_market: this.state.mini_market,
          swimming_pool: this.state.swimming_pool,
          salon: this.state.salon,
          all_day_security: this.state.all_day_security,
          club_house: this.state.club_house,
          jacuzzi: this.state.jacuzzi,
          nursery: this.state.nursery,
          sauna: this.state.sauna,
          wading_pool: this.state.wading_pool,
          cafetria: this.state.cafetria,
          other_facilities: this.state.otherFacilities,
          status_id: this.state.selectedStatus,
          published_to_juwai: this.state.isPublishedJuwai,
          published_to_iqi: this.state.isPublishedIQI,
          published_to_edgeprop: this.state.isPublishedEdge,

          open_for_internal_co_broke: this.state.open_for_internal_co_broke,
          ...(this.state.open_for_internal_co_broke && {
            co_broke_settings: this.state.co_broke_settings,
          }),
        },
      };

      if (
        (this.state.open_for_internal_co_broke &&
          _.values(this.state.co_broke_settings).some((val) => !val)) ||
        _.values(this.state.co_broke_settings).length < 1
      ) {
        return requestError(
          "Please fill up the Co-broke Commission to proceed",
        );
      }

      this.props.updateSubsales(id, dataToSubmit);
    };

    onChangeDetailsHOC = (val, context) => this.setState({ [context]: val });

    selectedListingCategory = (val) => {
      let tmpSelectedCategory = _.find(categories, { id: val });
      this.setState(
        {
          selectedCategory: tmpSelectedCategory,
        },
        () => {
          this.processPropertyType(tmpSelectedCategory, "");
        },
      );
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            selectedCategory={this.state.selectedCategory}
            tmpPropertyTypes={this.state.tmpPropertyTypes}
            selectedPropertyType={this.state.selectedPropertyType}
            address={this.state.address}
            property_name={this.state.property_name}
            selectedCountry={this.state.selectedCountry}
            selectedState={this.state.selectedState}
            postalCode={this.state.postalCode}
            township={this.state.township}
            sub_area={this.state.sub_area}
            description={this.state.description}
            zh_description={this.state.zh_description}
            hidden_description={this.state.hidden_description}
            code={this.state.code}
            type_id={this.state.type_id}
            co_broke_settings={this.state.co_broke_settings}
            open_for_internal_co_broke={this.state.open_for_internal_co_broke}
            selectedMeasurement={this.state.selectedMeasurement}
            builtUp={this.state.builtUp}
            landArea={this.state.landArea}
            askingPrice={this.state.askingPrice}
            selectedCurrency={this.state.selectedCurrency}
            askingPricePerMeasurementUnit={
              this.state.askingPricePerMeasurementUnit
            }
            availableDate={this.state.availableDate}
            highriseName={this.state.highriseName}
            hidden_address={this.state.hidden_address}
            selectedBedroomOption={this.state.selectedBedroomOption}
            selectedBathroomOption={this.state.selectedBathroomOption}
            selectedCarparkOption={this.state.selectedCarparkOption}
            selectedTenureType={this.state.selectedTenureType}
            selectedTitleType={this.state.selectedTitleType}
            selectedUnitType={this.state.selectedUnitType}
            selectedFurnishingStatus={this.state.selectedFurnishingStatus}
            selectedDirection={this.state.selectedDirection}
            selectedOccupancies={this.state.selectedOccupancies}
            all_day_security={this.state.all_day_security}
            bbq={this.state.bbq}
            business_centre={this.state.business_centre}
            cafetria={this.state.cafetria}
            club_house={this.state.club_house}
            gymnasium={this.state.gymnasium}
            jacuzzi={this.state.jacuzzi}
            jogging_track={this.state.jogging_track}
            mini_market={this.state.mini_market}
            nursery={this.state.nursery}
            parking={this.state.parking}
            playground={this.state.playground}
            salon={this.state.salon}
            sauna={this.state.sauna}
            squash_court={this.state.squash_court}
            swimming_pool={this.state.swimming_pool}
            tennis_court={this.state.tennis_court}
            wading_pool={this.state.wading_pool}
            otherFacilities={this.state.otherFacilities}
            selectedStatus={this.state.selectedStatus}
            monthlyMaintainanceFee={this.state.monthlyMaintainanceFee}
            isMonthlyMaintainanceFeePerMeasurementUnit={
              this.state.isMonthlyMaintainanceFeePerMeasurementUnit
            }
            youtubeLink={this.state.youtubeLink}
            juwai_link={this.state.juwai_link}
            exported_juwai_asia_link={this.state.exported_juwai_asia_link}
            exported_iqi_link={this.state.exported_iqi_link}
            isPublishedEdge={this.state.isPublishedEdge}
            isExportedJuwai={this.state.isExportedJuwai}
            isExportedIProperty={this.state.isExportedIProperty}
            isPublishedIQI={this.state.isPublishedIQI}
            isPublishedJuwai={this.state.isPublishedJuwai}
            processUserSubsales={this.processUserSubsales}
            onChangeValue={this.onChangeValue}
            onChangeDetailsHOC={this.onChangeDetailsHOC}
            onCheckCheckboxValue={this.onCheckCheckboxValue}
            onClickUpdateDetails={this.onClickUpdateDetails}
            selectedListingCategory={this.selectedListingCategory}
            {...this.props}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
