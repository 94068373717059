import React, { useEffect } from "react";
import _ from "lodash";
import Moment from "moment";
import { Tooltip } from "@material-ui/core";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import DeleteConfirmation from "components/Modal/delete";
import ConfirmationModal from "components/Modal/confirmation"
import AtPaginatedTable from "components/NewPaginatedTable";
import TableUserInfo from "components/NewPaginatedTable/components/UserInfo";
import RoomBookingForm from "./form";
import PaymentForm from "./payment";
import RejectForm from "./rejectForm";

import { getColorBadge } from "dictionary/badgeColor";
import MeetingRoomBookingHOC from "../actions/booking";
import permissionsChecker from "utils/permissionsChecker";

const StatusColor = [
  { value: "Confirmed", colorName: "Green" },
  { value: "In Use", colorName: "Yellow" },
  { value: "Completed", colorName: "Cyan" },
  { value: "Pending Approval", colorName: "Yellow" },
  { value: "Rejected", colorName: "Red" },
  { value: "Cancelled", colorName: "Red" },
];

const columnData = (props) => {

  return ([
    { header: "Meeting Room", 
      renderColumn: (rowData) => {
        return (
          <>
            {
              rowData.meeting_room_type_id === 2 && (
                <div
                  className="at-status_badge mb-2"
                  style={{ ...getColorBadge("Blue") }}
                >
                  {rowData.meeting_room_type}
                </div>
              )
            }
            <span style={{fontSize: 12}}>{rowData.meeting_room_name}</span>
          </> 
        )
      }
    },
    { header: "Event Name", 
      columnStyle: { width: "80%" },
      contentClass: "at-column-responsive_left",
      customClass: "at-column-responsive_left",
      renderColumn: (rowData) => {
        return (
          <>
            <div className="at-mybooking-subject-col">
              <span style={{fontSize: 14, fontWeight: 500}}>{rowData.event_subject}</span>
              {
                (rowData.meeting_room_type_id === 2 && rowData.purpose) && (
                  <div className="at-booking-subject-purpose-row">
                    <AtlasIcon
                      svgHref={"atlas-message-2"}
                      style={{marginRight: 4 }}
                    />
                    <span>{rowData.purpose}</span>
                  </div>
                )
              }
            </div>
            <div className="at-mobile-subject-col">
              <span style={{fontSize: 14, fontWeight: 500}}>{rowData.event_subject}</span>
            </div>
          </>
        );
      }
    },
    {
      header: "Date Time (Duration)",
      renderColumn: (rowData) => (
        <>
          <label style={{ fontWeight: 500, fontSize: 12 }}>Start At:</label>
          <p>
            {rowData.start_time
              ? Moment(rowData.start_time).format("DD MMM YYYY HH:mm")
              : "-"}
          </p>
          <label style={{ fontWeight: 500, fontSize: 12 }}>End At:</label>
          <p>
            {rowData.end_time
              ? Moment(rowData.end_time).format("DD MMM YYYY HH:mm")
              : "-"}
          </p>
          <label style={{ fontWeight: 500, fontSize: 12 }}>Duration:</label>
          <p>{rowData.duration ? `${rowData.duration} mins` : "-"}</p>
        </>
      ),
    },
    {
      header: "Booked By",
      renderColumn: (rowData) => (
        <TableUserInfo
          name={rowData?.user_info?.name ?? "-"}
          team_name={rowData?.user_info?.team ?? "-"}
          email={rowData?.user_info?.email ?? "-"}
          phone_number={rowData?.user_info?.mobile_contact_number ?? "-"}
        />
      ),
    },
    {
      header: "Status",
      contentClass: "at-column-responsive_center",
      customClass: "at-column-responsive_center",
      renderColumn: (rowData) => {
        const { colorName = "" } =
          _.find(StatusColor, { value: rowData.status }) || {};
  
        return (
          <>
            <div
              className="at-status_badge"
              style={{ ...getColorBadge(colorName) }}
            >
              {rowData?.status ?? "N/A"}
            </div>
            {
              rowData?.status === "Pending Approval" && (
                <>
                  <div className="d-flex">
                    <Tooltip
                      classes={{ popper: "popper", tooltip: "tool-tip" }}
                      placement={"top"}
                      title={"Approve Booking"}
                    >
                      <button 
                        className="at-table-approve-button mt-1 mr-1"
                        style={{ background: "#12B76A" }}
                        onClick={() => {
                          props.onChangeRoomBooking(rowData, "selectedRoomBooking")
                          props.onChangeRoomBooking(true, "showApproveModal")
                        }}
                      > 
                        <AtlasIcon svgHref={"atlas-tick-circle"} style={{ width: 16, height: 16, fill: "white" }}/>
                      </button>
                    </Tooltip>
                    <Tooltip
                      classes={{ popper: "popper", tooltip: "tool-tip" }}
                      placement={"top"}
                      title={"Reject Booking"}
                    >
                      <button 
                        className="at-table-approve-button mt-1"
                        style={{ background: "#F04438" }}
                        onClick={() => {
                          props.onChangeRoomBooking(rowData, "selectedRoomBooking")
                          props.onChangeRoomBooking(true, "showRejectModal")
                        }}
                      >
                        <AtlasIcon svgHref={"atlas-close-circle"} style={{ width: 16, height: 16, fill: "white" }}/>
                      </button>
                    </Tooltip>
                    <div className="d-flex at-mobile-view__controller mt-2 g-2">
                      <CustomButton
                        className={"btn-new btn-new--outline-secondary w-100"}
                        style={{ background: "#12B76A", color: "white" }}
                        containerStyle={{ minWidth: 120, flex: "1 1 0" }}
                        onClick={() => {
                          props.onChangeRoomBooking(rowData, "selectedRoomBooking")
                          props.onChangeRoomBooking(true, "showApproveModal")
                        }}
                        children={
                          <>
                            <AtlasIcon svgHref={"atlas-tick-circle"} style={{ width: 16, height: 16, fill: "white" }}/>
                            Approve
                          </>
                        }
                      />
                      <CustomButton
                        className={"btn-new btn-new--outline-secondary w-100"}
                        style={{ background: "#F04438", color: "white" }}
                        containerStyle={{ minWidth: 120, flex: "1 1 0" }}
                        onClick={() => {
                          props.onChangeRoomBooking(rowData, "selectedRoomBooking")
                          props.onChangeRoomBooking(true, "showRejectModal")
                        }}
                        children={
                          <>
                            <AtlasIcon svgHref={"atlas-close-circle"} style={{ width: 16, height: 16, fill: "white" }}/>
                            Reject
                          </>
                        }
                      />
                  </div>
                  </div>
                </>
              )
            }
            {
              (rowData.meeting_room_type_id ===1 && rowData?.status === "Confirmed") && (
                <>
                  <div className="d-flex">
                    <Tooltip
                      classes={{ popper: "popper", tooltip: "tool-tip" }}
                      placement={"left"}
                      title={"Cancel Booking"}
                    >
                      <button 
                        className="at-table-approve-button mt-1"
                        style={{ background: "#F04438" }}
                        onClick={() => {
                          props.onChangeRoomBooking(rowData, "selectedRoomBooking")
                          props.onChangeRoomBooking(true, "showCancelModal")
                        }}
                      >
                        <AtlasIcon svgHref={"atlas-close-circle"} style={{ width: 16, height: 16, fill: "white" }}/>
                      </button>
                    </Tooltip>
                    <div className="d-flex at-mobile-view__controller mt-2 g-2">
                      <CustomButton
                        className={"btn-new btn-new--outline-secondary w-100"}
                        style={{ background: "#F04438", color: "white" }}
                        containerStyle={{ minWidth: 120, flex: "1 1 0" }}
                        onClick={() => {
                          props.onChangeRoomBooking(rowData, "selectedRoomBooking")
                          props.onChangeRoomBooking(true, "showCancelModal")
                        }}
                        children={
                          <>
                            <AtlasIcon svgHref={"atlas-close-circle"} style={{ width: 16, height: 16, fill: "white" }}/>
                            Cancel
                          </>
                        }
                      />
                    </div>
                  </div>
                </>
              )
            }
            {
              rowData?.status === "Rejected" && (
                <div className="at-booking-reject-box">
                  {rowData?.rejection_reason ? rowData.rejection_reason : "N/A" } 
                </div>
              )
            }
          </>
        );
      },
    },
  ])
}
  

const MeetingRoomBooking = (props) => {
  const { can_create, can_update, can_delete } = permissionsChecker("Meeting Hub - Booking History", props.data);

  useEffect(() => {
    props.getMeetingRooms();
    props.getRoomBooking(1, "");
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(props.searchParams);
    let branchObj = _.find(temp, { value: "meeting_room_branch_id_eq" });
    branchObj.options = props.branches;
    props.onChangeRoomBooking(temp, "searchParams");
  }, [props.branches]);

  const renderCheckBoxActions = () => (
    <div className="at-meeting-room__table-title">
      <h4>Room Bookings</h4>
      {can_create && (
        <button
          className="btn-new btn-new--primary ml-2"
          onClick={() => props.onChangeRoomBooking(true, "showCreateModal")}
        >
          <AtlasIcon svgHref={"atlas-add-linear"} />
          New Booking
        </button>
      )}
    </div>
  );

  return (
    <>
      <AtPaginatedTable
        columns={columnData(props)}
        rowData={props.roomBookings || []}
        meta={props.roomBookingsMeta}
        actionColumnContent={[
          {
            name: "edit",
            onShow: () => can_update,
            disabled: (rowData) => rowData.status === "Cancelled",
            onClick: (rowData) => props.getSelectedRoomBooking(rowData.id),
          },
          {
            name: "delete",
            onShow: () => can_delete,
            disabled: (rowData) => ["Cancelled", "Completed"].includes(rowData.status),
            onClick: (rowData) =>
              Promise.all([
                props.onChangeRoomBooking(rowData, "selectedRoomBooking"),
              ]).then(() =>
                props.onChangeRoomBooking(true, "showDeleteConfirmation"),
              ),
          },
        ]}
        searchParams={props.searchParams}
        onChangeSearchParams={(val) =>
          props.onChangeRoomBooking(val, "searchParams")
        }
        getListAPI={props.getRoomBooking}
        totalPages={props.roomBookingsPage}
        renderCheckBoxActions={renderCheckBoxActions}
      />
      {props.showCreateModal && (
        <ModalDialog
          title={"New Booking"}
          onLoad={props.onLoadRoomBooking}
          fullWidth={true}
          onClose={() => props.onChangeRoomBooking(false, "showCreateModal")}
          children={
            <RoomBookingForm
              {...props}
              mode={"create"}
              onClose={() =>
                props.onChangeRoomBooking(false, "showCreateModal")
              }
            />
          }
        />
      )}
      {props.showUpdateModal && (
        <ModalDialog
          title={"Edit Booking"}
          onLoad={props.onLoadRoomBooking}
          fullWidth={true}
          onClose={() => props.onChangeRoomBooking(false, "showUpdateModal")}
          children={
            <RoomBookingForm
              {...props}
              mode={"update"}
              onClose={() => {
                props.onChangeRoomBooking(false, "showUpdateModal")
                props.onChangeRoomBooking({}, "selectedRoomBooking")
              }}
            />
          }
        />
      )}
      {props.showPaymentModal && (
        <ModalDialog
          title={"New Payment"}
          maxWidth="md"
          responsiveSize="md"
          onLoad={props.onLoadRoomBooking}
          fullHeight={true}
          onClose={() => props.onChangeRoomBooking(false, "showPaymentModal")}
          children={<PaymentForm {...props} />} />
      )}
      <DeleteConfirmation
        open={props.showDeleteConfirmation}
        title={"Cancel Meeting Room Booking"}
        message={"Are you sure you want to cancel this meeting room booking?"}
        positiveText={"Cancel Booking"}
        negativeText={"Close"}
        negativeAction={() =>
          props.onChangeRoomBooking(false, "showDeleteConfirmation")
        }
        positiveAction={() =>
          props.deleteRoomBooking(props.selectedRoomBooking.id)
        }
      />
      <ConfirmationModal
        open={props.showApproveModal}
        loading={props.onLoadRoomBooking}
        message={"Are you sure to approve selected booking?"}
        title={"Approve Booking"}
        modalClass="at-access_case_file-password_modal"
        negativeAction={() => props.onChangeRoomBooking(false, "showApproveModal")}
        negativeText={"Cancel"}
        positiveAction={() => props.approveRoomBooking(props.selectedRoomBooking.id)}
        positiveText={"Approve"}
      />
      {props.showRejectModal && (
        <ModalDialog
          title={"Reject Booking"}
          contentClasses="mb-0"
          maxWidth="sm"
          responsiveSize="sm"
          onLoad={props.onLoadRoomBooking}
          onClose={() => props.onChangeRoomBooking(false, "showRejectModal")}
          children={<RejectForm {...props} />}
        />
      )}
      <ConfirmationModal
        title={"Cancel Booking"}
        loading={props.onLoadRoomBooking}
        open={props.showCancelModal}
        message={"Are you sure you want to cancel this booking?"}
        positiveAction={() => props.cancelRoomBooking(props.selectedRoomBooking.id)}
        negativeAction={() => props.onChangeRoomBooking(false, "showCancelModal")}
      />
      {props.onLoadRoomBooking && <LoadingModal />}
    </>
  );
};

export default MeetingRoomBookingHOC(MeetingRoomBooking);
