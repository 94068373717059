import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put, Delete } from "utils/axios";
import { refreshToken } from "actions/login";
import { storeLastView } from "actions/lastView";
import { getBranches } from "actions/branch";
import { getAllDictionaryData } from "actions/dictionary";

const SubsalesAdminStatus = [
  { id: 1, name: "On Call" },
  { id: 2, name: "Offline" },
  { id: 3, name: "Disabled" },
];

const SubsalesAdminsHOC = (WrappedComponent) => {
  class SubsalesAdminsWrappedComponent extends Component {
    state = {
      subsales_admins: {
        data: [],
        meta: {},
      },
      subsales_admins_total_page: [],
      selected_subsales_admin: {
        id: null,
        type_id: null,
        user_id: null,
        branch_ids: [],
      },
      searchParams: [
        {
          label: "Admin Full name",
          value: "user_full_name_or_user_display_name_cont",
          type: "input",
          param: "",
        },
        {
          label: "Admin Email",
          value: "user_email_cont",
          type: "input",
          param: "",
        },
        {
          label: "Type",
          value: "type_id_eq",
          type: "select",
          param: "",
          options: [
            { label: "SubSale Admin", value: 1 },
            { label: "Agent Registration", value: 2 },
            { label: "Lead CRM", value: 3 },
            { label: "Finance", value: 4 },
            { label: "SubSale Senior Admin", value: 5 },
            { label: "Project Admin", value: 6 },
            { label: "T/A Stamping Admin", value: 7 },
            { label: "Finance REA Renewal", value: 8 },
            { label: "SubSale REA Renewal", value: 9 },
          ],
        },
        {
          label: "Status ID",
          value: "status_id_eq",
          type: "radio",
          param: "",
          options: [
            { label: "On Call", value: "1" },
            { label: "Offline", value: "2" },
            { label: "Disabled", value: "3" },
          ],
        },
      ],
      showNewSubsaleAdminDialog: false,
      showEditSubsaleAdminDialog: false,
      showDeleteConfirmation: false,

      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSubsalesAdminHOC = (val, context) =>
      this.setState({ [context]: val });

    getSubsalesAdmins = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      Get(
        `/admin/admin_roles?${search}page=${page}`,
        this.getSubsalesAdminsSuccess,
        this.getSubsalesAdminsError,
        this.load,
      );
    };
    getSubsalesAdminsSuccess = (payload) => {
      let tmp = [];
      const { admin_roles } = this.props.data.dictionaryReducer;
      payload &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tmpRoles = _.find(admin_roles, { id: item.type_id });
          let tmpStatus = _.find(SubsalesAdminStatus, { id: item.status_id });
          let data = {
            ...item,
            admin_name: item.user.display_name,
            type: tmpRoles ? tmpRoles.name : "",
            status: tmpStatus ? tmpStatus.name : "",
          };
          tmp.push(data);
        });
      let tmpPayload = {
        data: tmp,
        meta: payload.meta,
      };

      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      return this.setState({
        subsales_admins: tmpPayload,
        subsales_admins_total_page: tmpTotalPages,
      });
    };
    getSubsalesAdminsError = (error) => requestError(error);

    getSelectedSubsaleAdmin = (id) =>
      Get(
        `/admin/admin_roles/${id}`,
        this.getSelectedSubsaleAdminSuccess,
        this.getSelectedSubsaleAdminError,
        this.load,
      );
    getSelectedSubsaleAdminSuccess = (payload) =>
      this.setState({
        selected_subsales_admin: {
          ...payload,
          display_name: payload.user.display_name || "N/A",
        },
        showEditSubsaleAdminDialog: true,
      });
    getSelectedSubsaleAdminError = (error) => requestError(error);

    createSubsalesAdmin = (data) =>
      Post(
        `/admin/admin_roles`,
        data,
        this.createSubsalesAdminSuccess,
        this.createSubsalesAdminError,
        this.load,
      );
    createSubsalesAdminSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesAdmins(currentPage, searchParams);
      this.setState({ showNewSubsaleAdminDialog: false });
      requestSuccess("Admin is created successfully.");
    };
    createSubsalesAdminError = (error) => requestError(error);

    updateSubsalesAdmin = (id, data) =>
      Put(
        `/admin/admin_roles/${id}`,
        data,
        this.updateSubsalesAdminSuccess,
        this.updateSubsalesAdminError,
        this.load,
      );
    updateSubsalesAdminSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesAdmins(currentPage, searchParams);
      requestSuccess("Admin Role is updated successfully.");
    };
    updateSubsalesAdminError = (error) => requestError(error);

    deleteSubsalesAdmin = (id) =>
      Delete(
        `/admin/admin_roles/${id}`,
        this.deleteSubsalesAdminSuccess,
        this.deleteSubsalesAdminError,
        this.load,
      );
    deleteSubsalesAdminSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesAdmins(currentPage, searchParams);
      this.setState({ showDeleteConfirmation: false });
      requestSuccess("Role assigned successfully!");
    };
    deleteSubsalesAdminError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            subsales_admins={this.state.subsales_admins}
            subsales_admins_total_page={this.state.subsales_admins_total_page}
            selected_subsales_admin={this.state.selected_subsales_admin}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showNewSubsaleAdminDialog={this.state.showNewSubsaleAdminDialog}
            showEditSubsaleAdminDialog={this.state.showEditSubsaleAdminDialog}
            searchParams={this.state.searchParams}
            onLoadSubsalesAdmins={this.state.loading}
            onChangeSubsalesAdminHOC={this.onChangeSubsalesAdminHOC}
            getSubsalesAdmins={this.getSubsalesAdmins}
            getSelectedSubsaleAdmin={this.getSelectedSubsaleAdmin}
            createSubsalesAdmin={this.createSubsalesAdmin}
            updateSubsalesAdmin={this.updateSubsalesAdmin}
            deleteSubsalesAdmin={this.deleteSubsalesAdmin}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getBranches,
    getAllDictionaryData,
    refreshToken,
    storeLastView,
  })(SubsalesAdminsWrappedComponent);
};

export default SubsalesAdminsHOC;
