import React, { useState } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";

import "./index.scss";

const MotdOverlay = ({
  onChangeMOTDModalHOC,
  motd,
  updateMOTDActivity,
  onLoad,
}) => {
  const [timer, setTimer] = useState("");

  const onChangeMOTD = (context, val) => {
    let temp = _.cloneDeep(motd);
    temp[context] = val;
    onChangeMOTDModalHOC(temp, "motd");
  };

  const playFinish = (duration) => {
    if (duration) {
      let tmp = setTimeout(() => {
        motd.audio.pause();
        onChangeMOTD("play", false);
      }, duration);
      setTimer(tmp);
    } else {
      clearTimeout(timer);
    }
  };

  const onClickPlay = () => {
    motd.audio.play();
    onChangeMOTD("play", true);
    updateMOTDActivity("1");
    playFinish(motd.audio.duration * 1000);
  };

  const onClickPause = () => {
    motd.audio.pause();
    onChangeMOTD("play", false);
    playFinish();
  };

  const skipAndClose = () => {
    if (motd.audio_url) {
      motd.audio.pause();
    }
    onChangeMOTD("play", false);
    updateMOTDActivity("2");
    onChangeMOTDModalHOC(false, "showMOTD");
    playFinish();
  };

  const renderCardContent = () => (
    <div className="m-auto position-relative">
      <img
        className="at-template__motd-image"
        src={motd.image_url}
        alt={motd.name}
      />
      <div className="at-at-template__motd-play-btn-container">
        {motd.audio_url && (
          <CustomButton
            className={"at-btn at-template__motd-play-btn"}
            children={motd.play ? "PAUSE" : "PLAY"}
            onClick={() => (motd.play ? onClickPause() : onClickPlay())}
          />
        )}
        {motd.quote_audio_attached_events &&
          motd.quote_audio_attached_events.length > 0 &&
          motd.quote_audio_attached_events.map((event) => (
            <CustomButton
              className={
                "btn-new btn-new--primary at-template__motd-covention-btn"
              }
              children={event.button_label}
              onClick={() =>
                window.open(
                  `${window.location.origin}/#/dashboard/iqi-calendar/events?event_id=${event.attached_event.id}`,
                  "_blank",
                )
              }
            />
          ))}
      </div>
      <CustomButton
        containerStyle={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        className={"at-btn at-template__motd-close-btn"}
        children={motd.play ? "Skip and close" : "Close"}
        onClick={() => skipAndClose()}
      />
    </div>
  );

  return (
    <div className="at-template__motd-overlay">
      {renderCardContent()}
      {onLoad && <LoadingModal />}
    </div>
  );
};

export default MotdOverlay;
