import React, { Fragment, useState } from "react";
import _ from "lodash";
import { FormHelperText, FormLabel, MenuItem } from "@material-ui/core";
import { toast } from "react-toastify";
import { HiPlus } from "react-icons/hi";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import ShareModal from "components/Share";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomCheckbox from "components/Checkbox";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasTable from "components/NewPaginatedTable";
import AtlasAutosuggest from "components/Autosuggest";
import CustomFormInput from "components/Input/formInput";
import AtSelectNew from "components/Select/new";
import AtlasButton from "components/Button";

import {
  companyDDFieldData,
  companyTableColumn,
  personalDDFieldData,
  personalTableColumn,
  declineFieldData
} from "./assets";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const statusBadge = [
  {
    id: 1,
    value: "Submitted",
    colorName: "Green",
  },
  {
    id: 2,
    value: "Cancel",
    colorName: "Grey",
  },
  {
    id: 3,
    value: "Cancel",
    colorName: "Grey",
  },
];

const DueDiligenceContent = ({
  doc,
  form_type,
  isView,
  updateObj,
  onLoadParty,
  selected_form,
  selectedContact,

  setDoc,
  onClose,
  onChangePartyHOC,
  onChangeUpdateObj,
  downloadCompletedForm,
  getSelectedFormContact,
}) => {
  const due_diligence_field =
    selectedContact.signee_type_id === "1"
      ? companyDDFieldData
      : personalDDFieldData;
  const due_diligence_information = updateObj.due_diligence_information || {
    due_diligence_type_id: "",
    financing_method_id: "",
    signing_link: "",
    salary_range_id: "",
    employer_name: "",
    copies_info: {},
    transaction_purpose_id: "",
    nature_of_business: "",
    beneficial_owners: "",
    business_type_id: "",
    directors: [],
    shareholders: [],
    senior_management: "",
  };

  const [shareDetail, setShareDetail] = useState({});

  const onChangeDueDiligence = (val) =>
    onChangeUpdateObj({
      ...updateObj,
      due_diligence_information: val,
    });

  const onChangeFieldValue = (val, context) => {
    let temp = _.cloneDeep(due_diligence_information);
    temp[context] = val;
    onChangeDueDiligence(temp);
  };

  const onChangeSharePortion = (val, index) => {
    if (val <= 100) {
      let temp = _.cloneDeep(due_diligence_information);
      temp.shareholders[index]["percentage"] = val;
      onChangeDueDiligence(temp);
    }
  };

  const onChangeFile = (fileItem, value) => {
    const valueKey = value.replace("_url", "");
    if (fileItem.length > 0 && fileItem[0].file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          setDoc({
            ...doc,
            [valueKey]: {
              file_name: fileItem[0].file.name,
              file: e.target.result,
            },
          });
        };

        reader.readAsDataURL(fileItem[0].file);
      } catch (e) {
        console.log(e);
      }
    } else {
      setDoc({ ...doc, [valueKey]: {} });
    }
  };

  const renderEsigningTable = () => {
    return (
      <>
        <AtlasTable
          columns={
            selectedContact.signee_type_id === "1"
              ? companyTableColumn
              : personalTableColumn
          }
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={[selectedContact]}
          actionColumnContent={[
            {
              name: "share",
              onShow: (rowData) =>
                rowData.indemnity_information.indemnity_status_id === 1,
              onClick: (rowData) =>
                setShareDetail({
                  share_link:
                    rowData.indemnity_information.indemnity_signing_link,
                  recipient: rowData.full_name,
                  showShareModal: true,
                }),
            },
            {
              name: "download",
              onShow: (rowData) =>
                rowData.indemnity_information.indemnity_status_id === 2,
              onClick: (rowData) =>
                downloadCompletedForm({
                  formId: rowData.form_id,
                  formType: 5,
                  form_contact_id: rowData.id,
                }),
            },
          ]}
        />
        <div className="d-flex mt-2">
          {selectedContact.indemnity_information.indemnity_status_id === 1 && (
            <AtlasButton
              className="btn btn-primary mr-2"
              children={"Check Status"}
              disabled={isView}
              onClick={() =>
                getSelectedFormContact(
                  selectedContact.form_id,
                  selectedContact.id,
                )
              }
            />
          )}
          {selectedContact.signee_type_id === "1" && (
            <AtlasButton
              className="btn btn-outline-secondary"
              children={"Close"}
              onClick={() => onClose()}
            />
          )}
        </div>
      </>
    );
  };

  const renderField = item => {
    return (
      <section key={item.value} className={item.className || 'grid-half-col'}>
        <h2 className="at-form-input__title">{item.title}</h2>
        {item.type === "select" && (
          <AtSelectNew
            disabled={isView}
            value={due_diligence_information[item.value]}
            onChange={(e) =>
              onChangeFieldValue(e.target.value, item.value)
            }
            style={{ display: "flex", flexDirection: "column" }}
          >
            {item.options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ width: "100%", fontSize: 14 }}
                classes={{ root: `at-select__dropdown-item` }}
                disabled={option.disabled}
              >
                {option.label}
              </MenuItem>
            ))}
          </AtSelectNew>
        )}
        {item.type === "document" && (
          <>
            { (form_type === "confirmation" && selected_form.claim_status !== "Pending") &&
              selectedContact.copies_info[item.value] && (
                <div className="d-flex align-items-center">
                  <FiCheckCircle
                    style={{
                      width: 20,
                      height: 20,
                      color: "green",
                      marginRight: 10,
                    }}
                  />
                  Attached
                </div>
              )}
            { (form_type === "confirmation" && selected_form.claim_status !== "Pending") &&
              !selectedContact.copies_info[item.value] && (
                <div className="d-flex align-items-center">
                  <FiXCircle
                    style={{
                      width: 20,
                      height: 20,
                      color: "red",
                      marginRight: 10,
                    }}
                  />
                  No Attachment
                </div>
              )}
            { (form_type !== "confirmation" || (form_type === "confirmation" && selected_form.claim_status === "Pending")) &&
              selectedContact.copies_info[item.value] && (
                <div className="d-flex mt-10">
                  <CustomButton
                    type={"button"}
                    className={"btn-new btn-new--secondary mr-2"}
                    onClick={() =>
                      window.open(
                        selectedContact.copies_info[item.value],
                        "_blank",
                      )
                    }
                    children={"View Attachment"}
                  />
                  <CustomButton
                    type={"button"}
                    className={"btn-new btn-new--danger"}
                    disabled={isView}
                    onClick={() => {
                      let temp = _.cloneDeep(selectedContact);
                      temp.copies_info[item.value] = "";
                      onChangePartyHOC(temp, "selectedContact");
                    }}
                    children={"Remove Attachment"}
                  />
                </div>
              )}
            { (form_type !== "confirmation" || (form_type === "confirmation" && selected_form.claim_status === "Pending")) &&
              !selectedContact.copies_info[item.value] && (
                <>
                  <FilePond
                    disabled={isView}
                    allowMultiple={false}
                    acceptedFileTypes={[
                      "image/*",
                      "application/pdf",
                    ]}
                    labelIdle={`Click here to upload ${item.title}`}
                    fullWidth
                    maxFiles={1}
                    onupdatefiles={(fileItems) =>
                      onChangeFile(fileItems, item.value)
                    }
                  />
                  <FormHelperText>
                    Accepted file types: jpg, jpeg, png, pdf.
                  </FormHelperText>
                </>
              )}
          </>
        )}
        {item.type === "autosuggest" && (
          <AtlasAutosuggest
            placeholder={item.placeholder || item.title}
            disabled={isView}
            value={due_diligence_information[item.value] || ""}
            updateValue={(val) =>
              onChangeFieldValue(val, item.value)
            }
            options={item.options || []}
          />
        )}
        {item.type === "radio" && (
          <AtlasRadioGroup
            horizontal={true}
            disabled={isView}
            options={item.options}
            checkedValue={due_diligence_information[item.value]}
            selectedRadioValue={(val) =>
              onChangeFieldValue(val, item.value)
            }
          />
        )}
        {!["select", "document", "autosuggest", "radio"].includes(
          item.type,
        ) && (
          <CustomFormInput
            type={"text"}
            disabled={isView}
            value={due_diligence_information[item.value]}
            onChangeValue={(val) => {
              let temp = "";

              switch (item.type) {
                case "alphanumeric":
                  temp = val.replace(/[^$0-9a-zA-Z]/g, "");
                  break;

                case "number":
                  temp = val.replace(/[^$0-9]/g, "");
                  break;

                default:
                  temp = val;
                  break;
              }
              onChangeFieldValue(temp, item.value);
            }}
            inputError={""}
            placeholder={item.placeholder}
          />
        )}
      </section>
    )
  }

  return (
    <>
      <p className="d-flex at-card__description fs-2">
        The Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of
        Unlawful Activities Act 2001 requires us to collect certain information
        to aid in countering money laundering and other related activities.
        Parties to a transaction are required to provide this information which
        you should record accurately and securely here. <br />
        For further information about the requirements for Customer Due
        Diligence, please refer to this page from Bank Negara. You can also send
        this link to your clients.
      </p>
      <div className="d-flex my-3">
        <button
          className={
            "btn-new btn-new--secondary at-due-diligence__description-link"
          }
          onClick={() =>
            window.open("https://amlcft.bnm.gov.my/customer-due-diligence")
          }
        >
          <AtlasIcon svgHref={"atlas-link-2-linear"} />
          Learn about CDD Guidelines
        </button>
        <button
          className={
            "btn-new btn-new--outline-secondary at-due-diligence__description-link"
          }
          onClick={() => {
            navigator.clipboard.writeText(
              "https://amlcft.bnm.gov.my/customer-due-diligence",
            );
            toast.warn("Copied to Clipboard");
          }}
        >
          <AtlasIcon style={{ color: "#3B82F6" }} svgHref={"atlas-copy-bold"} />
          Copy Link
        </button>
      </div>
      {selectedContact.signee_type_id === "0" && (
        <CustomCheckbox
          disabled={isView}
          labelClassname={"ml-0 w-100 mb-3"}
          content={
            <span className="fw-500">
              Check this box if the customer declined to share information
            </span>
          }
          checked={
            due_diligence_information &&
            due_diligence_information.due_diligence_type_id === 2
          }
          onChangeCheckboxValue={() =>
            onChangeFieldValue(
              due_diligence_information.due_diligence_type_id === 2 ? 1 : 2,
              "due_diligence_type_id",
            )
          }
        />
      )}
      {
        due_diligence_information.due_diligence_type_id === 2 && (
          <form className="grid-control">
            {declineFieldData.map((item) => renderField(item))}
          </form>
        )
      }
      {due_diligence_information.due_diligence_type_id === 1 && (
        <form
          className="grid-control grid_gap-2"
          onSubmit={(e) => e.preventDefault()}
        >
          {due_diligence_field.map((item, index) => {
            if (
              item.value !== "financing_method_id" ||
              selectedContact.party_id === 1
            ) {
              return (
                <Fragment key={index}>
                  {item.value === "directors" && (
                    <section key={item.value} className={item.className}>
                      <div className="d-flex align-items-center">
                        <h2 className="at-form-input__title">{item.title}</h2>
                        <AtlasButton
                          type={"button"}
                          children={
                            <HiPlus
                              className="due-diligence__form-btn"
                              style={{ background: "orange" }}
                            />
                          }
                          tooltip={true}
                          disabled={isView}
                          tooltipID={`due-diligence-add-director`}
                          tooltipPosition={"top"}
                          tooltipChildren={"Add Director"}
                          onClick={() => {
                            let temp = _.cloneDeep(due_diligence_information);
                            temp.directors.push("");
                            Promise.all([onChangeDueDiligence(temp)]).then(() => {
                              let focusElement = document.getElementById(
                                `due_diligence_director-${temp.directors.length - 1}`,
                              );
                              focusElement.querySelector("input").focus();
                            });
                          }}
                        />
                      </div>
                      {due_diligence_information.directors &&
                        due_diligence_information.directors.map(
                          (director, index) => {
                            return (
                              <div
                                className="due-diligence__form-container d-flex align-items-center flex-wrap"
                                id={`due_diligence_director-${index}`}
                              >
                                <CustomFormInput
                                  type={"text"}
                                  disabled={isView}
                                  className={"col-md-6 mt-10 mr-10"}
                                  value={director}
                                  onChangeValue={(val) => {
                                    let temp = _.cloneDeep(
                                      due_diligence_information,
                                    );
                                    temp.directors[index] = val;
                                    onChangeDueDiligence(temp);
                                  }}
                                />
                                <AtlasButton
                                  className="btn btn-danger mt-10"
                                  children={"Delete"}
                                  disabled={isView}
                                  tooltip={true}
                                  tooltipID={`due-diligence-remove-director-${index}`}
                                  tooltipPosition={"top"}
                                  tooltipChildren={"Delete Director"}
                                  onClick={() => {
                                    if (
                                      due_diligence_information.directors.length >
                                      0
                                    ) {
                                      let temp = _.cloneDeep(
                                        due_diligence_information,
                                      );
                                      temp.directors.splice(index, 1);
                                      onChangeDueDiligence(temp);
                                    }
                                  }}
                                />
                              </div>
                            );
                          },
                        )}
                    </section>
                  )}
                  {item.value === "shareholders" && (
                    <section key={item.value} className={item.className}>
                      <div className="d-flex align-items-center">
                        <h2 className="at-form-input__title">{item.title}</h2>
                        <AtlasButton
                          type={"button"}
                          children={
                            <HiPlus
                              className="due-diligence__form-btn"
                              style={{ background: "orange" }}
                            />
                          }
                          tooltip={true}
                          disabled={isView}
                          tooltipID={`due-diligence-add-director`}
                          tooltipPosition={"top"}
                          tooltipChildren={"Add Director"}
                          onClick={() => {
                            let temp = _.cloneDeep(due_diligence_information);
                            temp.shareholders.push({
                              name: "",
                              type: "",
                              percentage: "",
                            });
                            Promise.all([onChangeDueDiligence(temp)]).then(() => {
                              let focusElement = document.getElementById(
                                `due_diligence_shareholder-${temp.shareholders.length - 1}`,
                              );
                              focusElement
                                .querySelector(".create-participant_form-input")
                                .firstChild.focus();
                            });
                          }}
                        />
                      </div>
                      {due_diligence_information.shareholders &&
                        due_diligence_information.shareholders.map(
                          (shareholder, index) => {
                            return (
                              <div
                                className="due-diligence__form-container"
                                id={`due_diligence_shareholder-${index}`}
                              >
                                <div className="row">
                                  <div className="col-md-6">
                                    <FormLabel>Shareholder Name</FormLabel>
                                    <CustomFormInput
                                      type={"text"}
                                      disabled={isView}
                                      className={"create-participant_form-input"}
                                      value={shareholder.name}
                                      onChangeValue={(val) => {
                                        let temp = _.cloneDeep(
                                          due_diligence_information,
                                        );
                                        temp.shareholders[index]["name"] = val;
                                        onChangeDueDiligence(temp);
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <FormLabel>Type of Share</FormLabel>
                                    <AtlasRadioGroup
                                      horizontal={true}
                                      disabled={isView}
                                      checkedValue={shareholder.type}
                                      containerClassName={"w-100"}
                                      selectedRadioValue={(val) => {
                                        let temp = _.cloneDeep(
                                          due_diligence_information,
                                        );
                                        temp.shareholders[index]["type"] = val;
                                        onChangeDueDiligence(temp);
                                      }}
                                      options={[
                                        {
                                          value: "Preference Shares",
                                          label: "Preference Shares",
                                        },
                                        {
                                          value: "Ordinary Shares",
                                          label: "Ordinary Shares",
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <FormLabel>Percentage %</FormLabel>
                                    <CustomFormInput
                                      type={"number"}
                                      disabled={isView}
                                      value={shareholder.percentage}
                                      onChangeValue={(val) =>
                                        onChangeSharePortion(val, index)
                                      }
                                    />
                                  </div>
                                </div>
                                <AtlasButton
                                  className="btn btn-danger mt-10"
                                  children={"Delete"}
                                  disabled={isView}
                                  tooltip={true}
                                  tooltipID={`due-diligence-remove-shareholder-${index}`}
                                  tooltipPosition={"top"}
                                  tooltipChildren={"Delete Shareholder"}
                                  onClick={() => {
                                    if (
                                      due_diligence_information.shareholders
                                        .length > 0
                                    ) {
                                      let temp = _.cloneDeep(
                                        due_diligence_information,
                                      );
                                      temp.shareholders.splice(index, 1);
                                      onChangeDueDiligence(temp);
                                    }
                                  }}
                                />
                              </div>
                            );
                          },
                        )}
                    </section>
                  )}
                  {item.value !== "directors" &&
                    item.value !== "shareholders" && renderField(item) }
                </Fragment>
              );
            }
          })}
        </form>
      )}
      {shareDetail && shareDetail.showShareModal && (
        <ShareModal
          title={"Share the e-signing link"}
          shareLink={shareDetail.share_link}
          containerClass={"at-share-modal"}
          contentClass={"justify-content-center"}
          encodedLink={`Hi ${shareDetail.recipient}, here is your Digital Confirmation Form for property at ${selected_form.property_address}\n ${encodeURIComponent(shareDetail.share_link)}`}
          showElement={["Whatsapp", "Telegram", "Open in a new tab"]}
          onClickClose={() =>
            setShareDetail({
              share_link: "",
              recipient: "",
              showShareModal: false,
            })
          }
        />
      )}
      {onLoadParty && <LoadingModal />}
    </>
  );
};

export default DueDiligenceContent;
