import { Card, CardActions, CardContent } from "@material-ui/core";
import { BsChevronRight } from "react-icons/bs";

import AtlasButton from "components/Button";
import Form from "../components/Form";

import isEmptyValue from "utils/isEmpty";

const Setup = ({
  categories,
  selectedEmailTemplate,
  onChangeEmailTemplatesHOC,
  toggleSetup,
  updateEmailTemplate,
}) => {
  const handleClose = () => {
    onChangeEmailTemplatesHOC("emailTemplateDialog", "");
  };

  const handleChange = (key, val) => {
    onChangeEmailTemplatesHOC("selectedEmailTemplate", {
      ...selectedEmailTemplate,
      [key]: val,
    });
  };

  const handleUpdate = () => {
    const {
      attachment,
      categories,
      content,
      html_code,
      name,
      status_id,
      subject,
    } = selectedEmailTemplate;

    const formData = new FormData();

    formData.append("name", name.trim());
    formData.append("type_id", 1);
    formData.append("status_id", parseInt(status_id));
    formData.append("html_code", html_code);

    if (!isEmptyValue(attachment)) {
      if (attachment && attachment.url) {
        formData.append("new_attachment", false);
      } else {
        formData.append("new_attachment", true);
        formData.append("attachment", attachment);
      }
    } else {
      formData.append("new_attachment", true);
      formData.append("attachment", "");
    }

    formData.append("content[body]", JSON.stringify(content));
    formData.append("extra[subject]", subject.trim());

    for (const category of categories) {
      formData.append(
        "sorts[]",
        category && category.customOption ? category.label : category,
      );
    }

    updateEmailTemplate(formData, "setup");
  };

  return (
    <Card className={"at-email-templates__form__card"}>
      <nav className={"breadcrumbs"}>
        <p className={"breadcrumb-item semi-bold-breadcrumb-item"}>Setup</p>

        <BsChevronRight className={"icon"} />

        <AtlasButton
          type={"button"}
          className={"breadcrumb-item"}
          onClick={toggleSetup}
        >
          Next: Design
        </AtlasButton>
      </nav>

      <CardContent className="at-email-templates__form__card-content">
        <Form
          values={selectedEmailTemplate}
          categories={categories}
          attachment={selectedEmailTemplate.attachment}
          context={"edit"}
          handleChange={handleChange}
        />
      </CardContent>

      <CardActions className={"at-email-templates__form__card-actions"}>
        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--success m-0"}
          onClick={handleUpdate}
          disabled={
            selectedEmailTemplate.name === "" ||
            selectedEmailTemplate.subject === ""
          }
        >
          Update
        </AtlasButton>

        <AtlasButton
          type={"button"}
          onClick={handleClose}
          className={"btn-new btn-new--outline-secondary m-0"}
        >
          Close
        </AtlasButton>
      </CardActions>
    </Card>
  );
};

export default Setup;
