import React, { useEffect, useState, useCallback, useMemo } from "react";
import _ from "lodash";

import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import CustomFormInput from "components/Input/formInput";
import AtlasIcon from "components/Icon/atlasIcon";

import RecommendationHOC from "./action";
import isEmptyValue from "utils/isEmpty";
import permissionsChecker from "utils/permissionsChecker";
import { numberWithCommas } from "utils/thousandSeparator";

const NotificationCheckBoxes = [
  { label: "Email", value: "email" },
  { label: "App notification", value: "app" },
  { label: "System notification", value: "system" },
];

const onCheckSettingValue = (key, value) => {
  if (key === "radius") {
    return value <= 0;
  } else if (key !== "notify_via") {
    return isEmptyValue(value);
  } else return false;
};

export default RecommendationHOC(function Recommendation({
  data,
  isNewSetting,
  recommendationSetting,
  subsalesEntryCreationProps,
  onLoadRecommendation,

  getRecomendationSetting,
  updateRecommendation,
  createRecomendationSetting,
}) {
  const { can_update, can_create } = permissionsChecker("Listing Centre", data);

  let { asking_price_cents } = subsalesEntryCreationProps;

  const [settingsForm, onChangeForm] = useState({
    min_price: `${asking_price_cents ? asking_price_cents * 0.8 : 0}`,
    max_price: `${asking_price_cents ? asking_price_cents * 1.2 : 0}`,
    radius: "5",
    notify_via: ["email", "app", "system"],
  });
  const [submitMode, onChangeMode] = useState("create");

  useEffect(() => {
    if (recommendationSetting) {
      onChangeForm(recommendationSetting);
      onChangeMode("update");
    }
  }, [recommendationSetting]);

  let onChangeField = useCallback(
    (val, context) => {
      onChangeForm({
        ...settingsForm,
        [context]: val,
      });
    },
    [settingsForm],
  );

  useEffect(() => {
    if (subsalesEntryCreationProps.recommendation_settings) {
      getRecomendationSetting(
        subsalesEntryCreationProps.recommendation_settings.id,
      );
    }
  }, []);

  let disableSubmit = useMemo(() => {
    return _.keys(settingsForm).some((key) =>
      onCheckSettingValue(key, settingsForm[key]),
    );
  }, [settingsForm]);

  return (
    <>
      <div className="at-form__content ">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-shake-hand"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          Recommendation Range
        </div>
        <section className="grid-control">
          <section className="grid-full-col">
            <p>
              You can customise the key attributes that the Cobroke
              Recommendation Engine will use to find potential agents for this
              listing.
              <br />
              For example, if you only want to find agents who have transacted
              property within a 3km radius of your listing or a specific price
              range, enter those values below.
            </p>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Within Radius (KM)
            </h2>
            <CustomFormInput
              type="text"
              numericOnly={true}
              value={settingsForm.radius}
              onChangeValue={(val) =>
                onChangeField(
                  numberWithCommas(`${val.replace(/[^$0-9.]/g, "")}`),
                  "radius",
                )
              }
              required={true}
            />
          </section>
          <section className="grid-half-col">
            <div>
              <h2 className="at-form-input__title" required>
                Price From ({subsalesEntryCreationProps.asking_price_currency})
              </h2>
              <CustomFormInput
                type="text"
                required={true}
                numericOnly={true}
                disabled={
                  (!isNewSetting && !can_update) ||
                  (isNewSetting && !can_create)
                }
                value={settingsForm.min_price}
                onChangeValue={(val) =>
                  onChangeField(
                    val
                      ? numberWithCommas(`${val.replace(/[^$0-9.]/g, "")}`)
                      : "",
                    "min_price",
                  )
                }
              />
            </div>
          </section>
          <section className="grid-half-col">
            <div>
              <h2 className="at-form-input__title" required>
                Price To ({subsalesEntryCreationProps.asking_price_currency})
              </h2>
              <CustomFormInput
                type="text"
                required={true}
                numericOnly={true}
                disabled={
                  (!isNewSetting && !can_update) ||
                  (isNewSetting && !can_create)
                }
                value={settingsForm.max_price}
                onChangeValue={(val) =>
                  onChangeField(
                    val
                      ? numberWithCommas(`${val.replace(/[^$0-9.]/g, "")}`)
                      : "",
                    "max_price",
                  )
                }
              />
            </div>
          </section>
        </section>
      </div>
      <div className="at-form__content">
        <div className="at-subsales-listing__view-title mb-20">
          <AtlasIcon
            svgHref={"atlas-notification-bing"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          Notification
        </div>
        <p className="at-card__description mb-2">
          Atlas will send you notification when your recommendations are ready
          and when agents have accepted or declined your co-broke requests.
          <br />
          Choose how you would like to receive those notifications.
        </p>
        {NotificationCheckBoxes.map((checkbox) => (
          <CustomCheckbox
            content={checkbox.label}
            checked={settingsForm.notify_via.indexOf(checkbox.value) > -1}
            onChangeCheckboxValue={(e) => {
              let temp = _.cloneDeep(settingsForm);
              temp.notify_via.indexOf(checkbox.value) > -1
                ? _.remove(temp.notify_via, (o) => o === checkbox.value)
                : (temp.notify_via = [...temp.notify_via, checkbox.value]);
              onChangeForm(temp);
            }}
          />
        ))}
      </div>
      <section className="grid-full-col">
        <button
          type={"button"}
          disabled={disableSubmit}
          className="btn-new btn-new--success mt-20"
          onClick={() =>
            submitMode === "create"
              ? createRecomendationSetting({
                  entity_id: subsalesEntryCreationProps.id,
                  settings: settingsForm,
                })
              : updateRecommendation({
                  id: subsalesEntryCreationProps.recommendation_settings.id,
                  settings: settingsForm,
                })
          }
        >
          Submit
        </button>
      </section>
      {onLoadRecommendation && <LoadingModal />}
    </>
  );
});
