import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SupportButtonGroups from "components/Button/supportGroups";

class NDAForm extends Component {
  render = () => {
    return (
      <>
        <Card style={{ marginTop: 20, padding: 50 }}>
          <CardContent>
            <h5 style={{ textAlign: "center", marginBottom: 20 }}>
              {" "}
              REAL ESTATE NEGOTIATOR NONDISCLOSURE AGREEMENT{" "}
            </h5>

            <p>
              {" "}
              This agreement (the "Agreement") is entered into by IQI REALTY
              SDN. BHD. (1113417U) ("Company")
            </p>

            <p style={{ paddingTop: 20 }}>
              In consideration of the REN's appointment with the Company agree
              as follows:
            </p>

            <p
              style={{ fontWeight: "bold", paddingTop: 20, paddingBottom: 10 }}
            >
              {" "}
              1. Company's Trade Secrets{" "}
            </p>

            <p>
              In the performance of REN's job duties with Company, the REN will
              be exposed to Company's Confidential Information. "Confidential
              Information" means information or material that is commercially
              valuable to the Company and not generally known or readily
              ascertainable in the industry. This includes, but is not limited
              to
            </p>

            <ol type="a">
              <li>
                information concerning the Company's business, business
                including business strategies, cost information, profits, sales
                information, accounting and unpublished financial information,
                business plans, markets and marketing methods, customer lists
                and customer information, purchasing techniques, supplier lists
                and supplier information and advertising and marketing
                strategies;
              </li>
              <li>
                {" "}
                information submitted by the Company's customers, suppliers,
                employees, consultants or coventure partners with the Company
                for study, evaluation or use;
              </li>
              <li>
                information concerning the Company's employees, including
                salaries, strengths, weaknesses and skills;
              </li>
              <li>
                Any other information not generally known to the public which,
                if misused or disclosed, could reasonably be expected to
                adversely affect Company’s business.
              </li>
            </ol>

            <p style={{ fontWeight: "bold", paddingBottom: 10 }}>
              {" "}
              2. Nondisclosure of Trade Secrets{" "}
            </p>

            <p>
              The REN shall keep the Company's Confidential Information, whether
              or not prepared or developed by the REN, in the strictest
              confidence. The REN will not disclose such information to anyone
              outside Company without the Company's prior written consent. Nor
              will the REN make use of any Confidential Information for the
              REN's own purposes or the benefit of anyone other than Company.
            </p>

            <p>
              {" "}
              However, the REN shall have no obligation to treat as confidential
              any information which:
            </p>

            <ol type="a">
              <li>
                was in the REN's possession or known to the REN, without an
                obligation to keep it confidential, before such information was
                disclosed to the REN by the Company;
              </li>
              <li>
                is or becomes public knowledge through a source other than the
                REN and through no fault of the REN
              </li>
            </ol>

            <p
              style={{ fontWeight: "bold", paddingTop: 20, paddingBottom: 10 }}
            >
              {" "}
              3. Return of Materials{" "}
            </p>

            <p>
              {" "}
              When the REN’s appointment with the Company ends, for whatever
              reason, the REN will promptly deliver to the Company all originals
              and copies of all documents, records, software programs, media and
              other materials containing any Confidential Information. The REN
              will also return to the Company all Tag, equipment, stationeries,
              files, software programs and other personal property belonging to
              Company.
            </p>

            <p
              style={{ fontWeight: "bold", paddingTop: 20, paddingBottom: 10 }}
            >
              {" "}
              4. Confidentiality Obligation Survives Employment{" "}
            </p>

            <p>
              {" "}
              The REN's obligation to maintain the confidentiality and security
              of Confidential Information remains effective even after the REN's
              appointment with the Company ends and continues for so long as
              such the Confidential Information remains a trade secret.
            </p>

            <p
              style={{ fontWeight: "bold", paddingTop: 20, paddingBottom: 10 }}
            >
              {" "}
              5. General Provisions{" "}
            </p>

            <ol type="a">
              <li>
                {" "}
                Relationships: Nothing contained in this Agreement shall be
                deemed to make the REN a partner or joint venturer of the
                Company for any purpose.{" "}
              </li>
              <li>
                {" "}
                Severability: If a court finds any provision of this Agreement
                invalid or unenforceable, the remainder of this Agreement shall
                be interpreted so as best to effect the intent of the Company
                and the REN.{" "}
              </li>
              <li>
                Integration: This Agreement expresses the complete understanding
                of the parties with respect to the subject matter and supersedes
                all prior proposals, agreements, representations and
                understandings. This Agreement may not be amended except in
                writing signed by both the Company and the REN.
              </li>
              <li>
                Waiver: The failure to exercise any right provided in this
                Agreement shall not be a waiver of prior or subsequent rights.
              </li>
              <li>
                Injunctive Relief: Any misappropriation of any of the
                Confidential Information in violation of this Agreement may
                cause Company irreparable harm, the amount of which may be
                difficult to ascertain, and therefore the REN agrees that the
                Company shall have the right to apply to a court of competent
                jurisdiction for an order enjoining any such further
                misappropriation and for such other relief as the Company deems
                appropriate. This right is to be in addition to the remedies
                otherwise available to Company.
              </li>
              <li>
                Indemnity: The REN agrees to indemnify Company against any and
                all losses, damages, claims or expenses incurred or suffered by
                Company as a result of the REN's breach of this Agreement.
              </li>
              <li>
                Attorney Fees and Expenses: In a dispute arising out of or
                related to this Agreement, the prevailing party shall have the
                right to collect from the other party its reasonable attorney
                fees and costs and necessary expenditures.
              </li>
              <li>
                Governing Law and Jurisdiction. This Agreement shall be governed
                in accordance with the laws of Malaysia.
              </li>
              <li>
                Successors & Assigns. This Agreement shall bind each party's
                heirs, successors and assigns. The Company may assign this
                Agreement to any party at any time. The REN shall not assign any
                of his or her rights or obligations under this Agreement without
                the Company's prior written consent. Any assignment or transfer
                in violation of this section shall be void.
              </li>
              <li>
                Social Media. You explicitly agree to not create any Facebook or
                other social media accounts that could be interpreted as
                representing IQI directly. You also explicity agree to not
                create listings on digital map or location listing services such
                as Google Maps
              </li>
            </ol>

            <p
              style={{ fontWeight: "bold", paddingTop: 20, paddingBottom: 10 }}
            >
              {" "}
              6. Signatures{" "}
            </p>

            <p>
              {" "}
              The REN has carefully read all of this Agreement and agrees that
              all of the restrictions set forth are fair and reasonably required
              to protect Company's interests. The REN has received a copy of
              this Agreement as signed by the parties.
            </p>
          </CardContent>
        </Card>
        <SupportButtonGroups />
      </>
    );
  };
}

export default NDAForm;
