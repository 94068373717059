import React from "react";

import juwaiLogo from "assets/images/juwai_logo_only.png";

const fieldData = [
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Package",
    value: "package_name",
  },
  {
    label: "Active total quota",
    value: "active_total_quota",
  },
  {
    label: "Total listings",
    value: "total_listings",
  },
  {
    label: "Total published listing",
    value: "total_published_listings",
  },
  {
    label: "Account expiration date",
    value: "formated_expiration_date",
  },
];

const JuwaiSubs = ({ agent_juwai_account }) => {
  return (
    <div className="at-form__content">
      <div className="at-subsales-listing__view-title mb-20">
        <img
          className="at-subsales-listing__view-title-img"
          src={juwaiLogo}
          alt="Juwai Asia"
          style={{ marginRight: 10 }}
        />
        Your Juwai Account Details
      </div>
      {!agent_juwai_account && (
        <>
          <p className={"fs-2 mb-10"}>
            You have not yet subscribed to any of the Juwai packages. Therefore
            your listings will be rejected when exporting them to the Juwai
            website
          </p>
          <p className={"fs-2 mb-10"}>
            If you have recently subscribed to a Juwai package, please take note
            that it will take 24 hours for Atlas to import your Juwai account
            here.
          </p>
        </>
      )}
      {agent_juwai_account &&
        fieldData.map((item, index) => (
          <div key={index} className="at-subsales-listing__view-details-item">
            <label>{item.label}</label>
            {agent_juwai_account[item.value]}
          </div>
        ))}
    </div>
  );
};

export default JuwaiSubs;
