import React, { Component } from "react";
import EmailValidator from "email-validator";
import Cookies from "js-cookie";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomCheckbox from "components/Checkbox";
import CustomFormInput from "components/Input/formInput";

import "./login.scss";

class LoginForm extends Component {
  state = {
    email: "",
    emailValid: false,
    password: "",
    passwordValid: false,
    passwordVisible: false,
    rememberMe: Cookies.get("is_saved_login_info"),
  };

  componentDidMount = () => {
    if (this.state.rememberMe === "true") {
      this.setState({
        email: Cookies.get("saved_email"),
        password: Cookies.get("saved_password"),
        passwordValid: true,
        emailValid: true,
      });
    }
  };

  onChangeEmail(val) {
    return this.setState({ email: val.trim() }, () => {
      this.setState({
        emailValid: EmailValidator.validate(this.state.email),
      });
    });
  }

  onChangePassword(val) {
    return this.setState({ password: val }, () => {
      this.setState({
        passwordValid: this.state.password.length > 0,
      });
    });
  }

  onClickSignIn() {
    if (this.state.email === "" || this.state.password === "") {
      this.props.errorPresence(true, "Email or password cannot be empty");
    } else {
      if (!this.state.emailValid) {
        this.props.errorPresence(true, "Email is invalid!");
      }
      if (!this.state.passwordValid) {
        this.props.errorPresence(true, "Password is invalid");
      }
    }
    if (this.state.emailValid && this.state.passwordValid) {
      this.props.emailPasswordCheckPassed(true, {
        email: this.state.email,
        password: this.state.password,
      });
    }
    if (this.state.rememberMe === "true") {
      Cookies.set("saved_email", this.state.email);
      Cookies.set("saved_password", this.state.password);
    }
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      return this.onClickSignIn();
    }
  };

  render() {
    return (
      <div
        className="login-form-container g-3 mb-4"
        tabIndex="0"
        onKeyPress={(event) => this.handleKeyPress(event)}
      >
        <div className="grid-control">
          <section className="grid-full-col">
            <h4 className="at-form-input__title">Email</h4>
            <CustomFormInput
              type="text"
              value={this.state.email}
              onChangeValue={(val) => this.onChangeEmail(val)}
              placeholder={"Email"}
            />
          </section>
          <section className="grid-full-col">
            <h4 className="at-form-input__title">Password</h4>
            <CustomFormInput
              type={this.state.passwordVisible ? "text" : "password"}
              value={this.state.password}
              onChangeValue={(val) => this.onChangePassword(val)}
              placeholder={"Password"}
              additionalContent={
                this.state.passwordVisible ? (
                  <AtlasIcon
                    className="eye-visibility"
                    svgHref={"atlas-vuesax-eye-slash"}
                    onClick={() =>
                      this.setState({
                        passwordVisible: !this.state.passwordVisible,
                      })
                    }
                  />
                ) : (
                  <AtlasIcon
                    className="eye-visibility"
                    svgHref={"atlas-vuesax-eye"}
                    onClick={() =>
                      this.setState({
                        passwordVisible: !this.state.passwordVisible,
                      })
                    }
                  />
                )
              }
            />
          </section>
        </div>
        <p style={{ color: "red", fontSize: 14 }}>{this.props.errorMessage}</p>
        <div className="pad-ver">
          <CustomCheckbox
            content={"Remember me"}
            checked={this.state.rememberMe === "true"}
            onChangeCheckboxValue={(e) => {
              this.setState(
                {
                  rememberMe:
                    this.state.rememberMe === "true" ? "false" : "true",
                },
                () => {
                  Cookies.set("is_saved_login_info", this.state.rememberMe);
                  if (this.state.rememberMe === "true") {
                    Cookies.set("saved_email", this.state.email);
                    Cookies.set("saved_password", this.state.password);
                  } else {
                    Cookies.set("saved_email", "");
                    Cookies.set("saved_password", "");
                  }
                },
              );
            }}
          />
          <a
            onClick={() => this.props.onClickForgetPassword()}
            style={{
              marginLeft: "auto",
              marginTop: "0.2rem",
              color: "#007BFF",
            }}
          >
            Forgot Password?
          </a>
        </div>
        <div className="mt-10 text-center">
          <button
            className="btn-new btn-new--primary text-uppercase w-100"
            type="submit"
            onClick={() => this.onClickSignIn()}
          >
            Sign In
          </button>
        </div>
      </div>
    );
  }
}

export default LoginForm;