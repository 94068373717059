import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";

import AtlasCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomTextarea from "components/Input/textarea";
import AlertBox from "components/AlertBox";
import AtlasFormInput from "components/Input/formInput";

import "./confirmation.scss";

const ConfirmationModal = ({
  mode,
  open,
  loading,
  message,
  title,
  autoFocus,
  maxWidth,
  hideIcon,
  hideAction,
  fieldLabel,
  inputType = "textarea",
  containerStyle,
  isSensitiveInfo,
  inputError,
  modalClass,

  onClose,
  onKeyPress = () => {},
  positiveAction,
  negativeAction,
  positiveText,
  negativeText,

  cardMessageStyle,
  hideNegative,
  disabledPositive,
  disabledNegative,
  cardStyle,
}) => {
  const [remarks, onChangeRemarks] = useState("");

  useEffect(() => {
    !open && onChangeRemarks("");
  }, [open]);

  const renderIconType = () => {
    switch (mode) {
      case "alert":
        return (
          <AtlasIcon
            svgHref={"atlas-danger"}
            style={{ fill: "#F04438", backgroundColor: "#FEE4E2" }}
          />
        );
      case "check":
        return (
          <AtlasIcon
            svgHref={"atlas-tick-circle"}
            style={{ fill: "#3B82F6", backgroundColor: "#DBEAFE" }}
          />
        );
      case "success":
        return (
          <AtlasIcon
            svgHref={"atlas-tick-circle"}
            style={{ fill: "#12B76A", backgroundColor: "#D1FADF" }}
          />
        );
      case "lock":
        return (
          <AtlasIcon
            svgHref={"atlas-lock"}
            style={{ fill: "#3B82F6", backgroundColor: "#DBEAFE" }}
          />
        );
      default:
        return (
          <AtlasIcon
            svgHref={"atlas-info-circle"}
            style={{ fill: "#3B82F6", backgroundColor: "#DBEAFE" }}
          />
        );
    }
  };

  const renderCardContent = () => {
    return (
      <>
        <div style={{ textAlign: "center", ...cardStyle }}>
          {onClose && (
            <button
              className="btn-new btn-new--outline-secondary"
              style={{ position: "absolute", top: 8, right: 8 }}
              onClick={() => onClose()}
            >
              <AtlasIcon
                svgHref={"atlas-X"}
                style={{ width: 14, height: 14, color: "#374151" }}
              />
            </button>
          )}
          {!hideIcon && (
            <>
              <div className="at-confirmation_modal-icon">
                {renderIconType()}
              </div>
              <br />
            </>
          )}
          <h4 className="fs-4 fw-600">{title || "Are you sure"}</h4>
          <p className={"fs-2 mt-10 mb-4"} style={{ ...cardMessageStyle }}>
            {message}
          </p>
          {inputError && (
            <AlertBox
              disabledClose={true}
              containerClass="mb-3"
              mode={"danger"}
              description={inputError}
            />
          )}
          {fieldLabel && (
            <>
              {inputType !== "textarea" && (
                <AtlasFormInput
                  type="text"
                  autoFocus={autoFocus}
                  checkedPressEnterToSend={true}
                  onEnterToSend={(val) => onKeyPress(remarks)}
                  containerStyle={{ width: "100%", display: "block" }}
                  value={remarks}
                  onChangeValue={(val) => onChangeRemarks(val)}
                />
              )}
              {inputType === "textarea" && (
                <CustomTextarea
                  rows="3"
                  autoFocus={autoFocus}
                  type={isSensitiveInfo ? "password" : "text"}
                  value={remarks}
                  checkedPressEnterToSend={true}
                  onEnterToSend={(val) => onKeyPress(remarks)}
                  placeholder={fieldLabel}
                  onChangeValue={(value) => onChangeRemarks(value)}
                  style={{ width: "100%", display: "block" }}
                />
              )}
            </>
          )}
          {!hideAction && (
            <div className={`d-flex justify-content-center pt-4 g-3`}>
              {negativeAction && (
                <button
                  className="btn-new btn-new--outline-secondary w-100"
                  onClick={() => negativeAction()}
                >
                  {negativeText || "No"}
                </button>
              )}
              {positiveAction && (
                <button
                  disabled={disabledPositive}
                  className="btn-new btn-new--primary w-100"
                  onClick={() => positiveAction(remarks)}
                >
                  {positiveText || "Yes"}
                </button>
              )}
            </div>
          )}
        </div>
        {loading && <LoadingModal />}
      </>
    );
  };
  return (
    <Dialog
      classes={{
        root: `at-confirmation_modal-responsive ${modalClass || ""}`,
        paper: "at-confirmation_modal-paper",
      }}
      maxWidth={maxWidth || "sm"}
      open={open}
    >
      <AtlasCard
        className={"at-confirmation_card-responsive bg-white"}
        containerStyle={containerStyle}
        cardContent={renderCardContent()}
      />
    </Dialog>
  );
};

export default ConfirmationModal;
