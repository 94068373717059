import { Dialog, DialogActions } from "@material-ui/core";
import { useEffect, useState } from "react";
import { compose } from "redux";

import AtlasButton from "components/Button";
import WelcomeMessage from "./components/welcomeMessage";
import WelcomeHeader from "./components/welcomeHeader";
import StepCounters from "./components/localAgent/stepCounters";
import AdditionalDetails from "./components/localAgent/additionalDetails";
import PersonalInfoForm from "./components/localAgent/personalInfoForm";
import Upload from "./components/localAgent/upload";
import InternationalPersonalInfoForm from "./components/internationalAgent/internationalPersonalInfoForm";
import InternationalAdditionalDetail from "./components/internationalAgent/internationalAdditionalDetail";
import InternationalUpload from "./components/internationalAgent/internationalUpload";

import WithLogout from "actions/logout";
import WelcomeModalTemplateHOC from "./action";
import { getTranslation } from "./assets";

import "./index.scss";

const modalSequenceLibrary = [
  {
    id: 0,
    label: "",
  },
  {
    id: 1,
    label: "agent.profile.title1", //Personal Details
  },
  {
    id: 2,
    label: "agent.profile.title2", //Additional Details
  },
  {
    id: 3,
    label: "agent.profile.title3" //Upload These Files
  },
];

const WelcomeModal = (props) => {
  const {
    updateNewAgent,
    currentModalSequence,
    getAllDictionaryData,
    getBranchInfo,
    data,
    onChangeField,
    branch_option,
    onClickBackModalSequence,
    onClickNextModalSequence,
    onClickSubmitUpdateData,
    onChangeProfileHOC,
  } = props;

  const [disableButtonS1, setDisableButtonS1] = useState(true);
  const [disableButtonS2, setDisableButtonS2] = useState(true);

  const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  const { country_id, branch_name } = tmpProfileReducer;
  const language = tmpProfileReducer.country_id === 14 ? "jp" : "en"

  useEffect(() => {
    getAllDictionaryData();
    getBranchInfo(tmpProfileReducer);
  }, []);

  useEffect(() => {
    const hasMissingFieldsS1 = () => {
      return (
        updateNewAgent.gender_id === 0 ||
        !updateNewAgent.date_of_birth ||
        !updateNewAgent.display_name ||
        (country_id === 14 && updateNewAgent.marital_status_id === 0) ||
        (country_id === 1 && 
          (updateNewAgent.race_id === 0 || 
          typeof updateNewAgent.race_id === "undefined" || 
          updateNewAgent.marital_status_id === 0)) ||
        updateNewAgent.branch_id === 0
      )
    };
    const hasMissingFieldsS2 = () => {
      return (
        (country_id === 1 && (
          !updateNewAgent.agent_registration_attributes.previous_company ||
          !updateNewAgent.agent_registration_attributes.previous_company_job_title )) ||
        (country_id === 14 && (
          !updateNewAgent.bank_account_type_id ||
          !updateNewAgent.bank_branch || !updateNewAgent.agent_registration_attributes.area_of_activity ||
          !updateNewAgent.current_address_1 || !updateNewAgent.current_address_2 || !updateNewAgent.current_address_3 || !updateNewAgent.current_address_4 || 
          (!updateNewAgent.bank_id && !updateNewAgent.agent_registration_attributes.new_bank_name) ||
          !updateNewAgent.agent_registration_attributes.emergency_contact_relationship )) ||
        ([1, 14].includes(country_id) && (
          !updateNewAgent.bank_owner_name || 
          !updateNewAgent.bank_account_number )) ||
        (country_id !== 14 && (
          !updateNewAgent.current_address || 
          updateNewAgent.agent_registration_attributes.is_undischarged_bankrupt_person === "" ||
          updateNewAgent.agent_registration_attributes.has_been_convicted_crime === ""
        )) ||
        !updateNewAgent.agent_registration_attributes.emergency_person_name ||
        !updateNewAgent.agent_registration_attributes.emergency_contact_number
      );
    };
    if (currentModalSequence === 1) {
      setDisableButtonS1(hasMissingFieldsS1());
    } else if (currentModalSequence === 2) {
      setDisableButtonS2(hasMissingFieldsS2());
    }
  }, [updateNewAgent]);

  const onChangeFormData = (val, id) => {
    onChangeField(
      {
        ...updateNewAgent.agent_registration_attributes,
        [id]: val,
      },
      "agent_registration_attributes"
    );
  };

  const renderDialogContent = () => {
    return (
      <>
        <StepCounters
          language={language}
          bodyContentType={currentModalSequence - 1}
          modalSequenceLibrary={modalSequenceLibrary}
        />
        <div className={"container pt-4"} style={{ maxWidth: 768 }}>
          {renderWelcomeDialogContent()}
        </div>
        <DialogActions className={"g-3 mt-4 mb-4 d-flex justify-content-center"}>
          {renderDialogActionContent()}
        </DialogActions>
      </>
    );
  };

  const renderWelcomeDialogContent = () => {
    switch (currentModalSequence) {
      case 1:
        return (
          <PersonalInfoForm
            tmpProfileReducer={tmpProfileReducer}
            branch_option={branch_option}
            branch_name={branch_name}
            updateNewAgent={updateNewAgent}
            onChangeField={onChangeField}
          />
        );

      case 2:
        return (
          <AdditionalDetails
            tmpProfileReducer={tmpProfileReducer}
            data={data}
            updateNewAgent={updateNewAgent}
            onChangeField={onChangeField}
            onChangeFormData={onChangeFormData}
            onChangeProfileHOC={onChangeProfileHOC}
          />
        );

      case 3:
        return (
          <Upload
            tmpProfileReducer={tmpProfileReducer}
            updateNewAgent={updateNewAgent}
            onChangeField={onChangeField}
          />
        );
      default:
        break;
    }
  };

  const renderDialogActionContent = () => {
    switch (currentModalSequence) {
      case 1:
        return (
          <div className={"d-flex align-items-center g-3"}>
            <AtlasButton
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onClickBackModalSequence()}
              disabled={currentModalSequence === 0}
            >
              {getTranslation("back", language)}
            </AtlasButton>
            <AtlasButton
              className={"btn-new btn-new--primary"}
              onClick={() => onClickNextModalSequence()}
              disabled={disableButtonS1}
            >
              {getTranslation("next", language)}
            </AtlasButton>
          </div>
        );

      case 2:
        return (
          <div className={"d-flex align-items-center g-3"}>
            <AtlasButton
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onClickBackModalSequence()}
              disabled={currentModalSequence === 0}
            >
              {getTranslation("back", language)}
            </AtlasButton>
            <AtlasButton
              className={"btn-new btn-new--primary"}
              onClick={() => onClickNextModalSequence()}
              disabled={disableButtonS2}
            >
              {getTranslation("next", language)}
            </AtlasButton>
          </div>
        );

      case 3:
        return (
          <div className={"d-flex align-items-center g-3"}>
            <AtlasButton
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onClickBackModalSequence()}
            >
              {getTranslation("back", language)}
            </AtlasButton>
            <AtlasButton
              className={"btn-new btn-new--primary"}
              onClick={onClickSubmitUpdateData}
              disabled={
                !updateNewAgent.agent_registration_attributes.ic_front_file_name ||
                (updateNewAgent.agent_registration_attributes.has_been_agent &&
                  ((country_id === 1 && !updateNewAgent.agent_registration_attributes.resignation_letter_file_name) ||
                  (country_id === 14 && !updateNewAgent.agent_registration_attributes.previous_company)))
              }
            >
              {getTranslation("submit", language)}
            </AtlasButton>
          </div>
        );
      default:
        return null;
    }
  };

  const renderInternationalContent = () => {
    return (
      <>
        <StepCounters
          language={language}
          bodyContentType={currentModalSequence - 1}
          modalSequenceLibrary={modalSequenceLibrary}
        />
        <div className={"container pt-4"} style={{ maxWidth: 768 }}>
          {renderInternationalWelcomeDialogContent()}
        </div>
        <DialogActions
          className={"g-3 mt-4 mb-4 d-flex justify-content-center"}
        >
          {renderInternationalDialogActionContent()}
        </DialogActions>
      </>
    );
  };

  const renderInternationalWelcomeDialogContent = () => {
    switch (currentModalSequence) {
      case 1:
        return (
          <InternationalPersonalInfoForm
            tmpProfileReducer={tmpProfileReducer}
            branch_option={branch_option}
            branch_name={branch_name}
            updateNewAgent={updateNewAgent}
            onChangeField={onChangeField}
          />
        );

      case 2:
        return (
          <InternationalAdditionalDetail
            tmpProfileReducer={tmpProfileReducer}
            updateNewAgent={updateNewAgent}
            onChangeField={onChangeField}
            onChangeProfileHOC={onChangeProfileHOC}
            onChangeFormData={onChangeFormData}
          />
        );

      case 3:
        return (
          <InternationalUpload
            tmpProfileReducer={tmpProfileReducer}
            updateNewAgent={updateNewAgent}
            onChangeField={onChangeField}
            onChangeProfileHOC={onChangeProfileHOC}
          />
        );
      default:
        break;
    }
  };

  const renderInternationalDialogActionContent = () => {
    switch (currentModalSequence) {
      case 1:
        return (
          <div className={"d-flex align-items-center g-3"}>
            <AtlasButton
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onClickBackModalSequence()}
              disabled={currentModalSequence === 0}
            >
              {getTranslation("back", language)}
            </AtlasButton>
            <AtlasButton
              className={"btn-new btn-new--primary"}
              onClick={() => onClickNextModalSequence()}
              disabled={disableButtonS1}
            >
              {getTranslation("next", language)}
            </AtlasButton>
          </div>
        );

      case 2:
        return (
          <div className={"d-flex align-items-center g-3"}>
            <AtlasButton
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onClickBackModalSequence()}
              disabled={currentModalSequence === 0}
            >
              {getTranslation("back", language)}
            </AtlasButton>
            <AtlasButton
              className={"btn-new btn-new--primary"}
              onClick={() => onClickNextModalSequence()}
              disabled={disableButtonS2}
            >
              {getTranslation("next", language)}
            </AtlasButton>
          </div>
        );

      case 3:
        return (
          <div className={"d-flex align-items-center g-3"}>
            <AtlasButton
              className={"btn-new btn-new--outline-secondary"}
              onClick={() => onClickBackModalSequence()}
            >
              {getTranslation("back", language)}
            </AtlasButton>
            <AtlasButton
              className={"btn-new btn-new--primary"}
              onClick={onClickSubmitUpdateData}
              disabled={
                !updateNewAgent.agent_registration_attributes.ic_back_file_name ||
                !updateNewAgent.agent_registration_attributes.ic_front_file_name
              }
            >
              {getTranslation("submit", language)}
            </AtlasButton>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      fullScreen
      classes={{ paper: "dialog_paper", root: "dialog_root" }}
      open
    >
      <WelcomeHeader {...props} tmpProfileReducer={tmpProfileReducer} />
      {currentModalSequence === 0 && <WelcomeMessage {...props} tmpProfileReducer={tmpProfileReducer} />}
      {currentModalSequence !== 0 && (
        <>
          {[1, 14].includes(country_id)
            ? renderDialogContent()
            : renderInternationalContent()}
        </>
      )}
    </Dialog>
  );
};

export default compose(WelcomeModalTemplateHOC, WithLogout)(WelcomeModal);
