import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";

import AtlasButton from "components/Button";
import CustomFormInput from "components/Input/formInput";
import Phone from "../components/Phone";

const Subject = ({
  isActive,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  handleAccordionChange,
  handleAccordionClose,
}) => {
  const [values, setValues] = useState({
    subject: "",
    preview_text: "",
  });

  useEffect(() => {
    if (isChecked) {
      setValues({
        subject: selectedEmailCampaign.subject,
        preview_text: selectedEmailCampaign.preview_text,
      });
    } else {
      setValues({
        subject: "",
        preview_text: "",
      });
    }
  }, [isActive]);

  const handleClose = () => {
    setValues({
      subject: "",
      preview_text: "",
    });

    handleAccordionClose();
  };

  const handleChange = (key, val) => {
    setValues((values) => ({ ...values, [key]: val }));
  };

  const handleSave = () => {
    const newEmailCampaign = {
      ...selectedEmailCampaign,
      subject: values.subject,
      preview_text: values.preview_text,
      status: 1,
    };

    onChangeEmailCampaignsHOC("selectedEmailCampaign", newEmailCampaign);
    updateEmailCampaign(newEmailCampaign);
    handleClose();
  };

  const isChecked = selectedEmailCampaign.subject;

  return (
    <ExpansionPanel
      square={true}
      expanded={isActive}
      className={"expansion-panel"}
    >
      <ExpansionPanelSummary
        classes={{
          root: "expansion-panel-summary",
          content: "expansion-panel-summary-content",
        }}
      >
        <div className={"expansion-panel-summary-wrapper"}>
          <FaCheckCircle
            className={`check-icon${isChecked ? " is-checked" : ""}`}
          />

          <div>
            <h4 className={"title mb-3"}>Subject</h4>

            {isChecked && (
              <>
                <p className={"expansion-panel-summary-text"}>
                  <strong>Subject:</strong>{" "}
                  <span>{selectedEmailCampaign.subject}</span>
                </p>

                <p className={"expansion-panel-summary-text"}>
                  <strong>Preview:</strong>{" "}
                  <span>{selectedEmailCampaign.preview_text}</span>
                </p>
              </>
            )}
            {!isChecked && (
              <small className={"text-muted"}>
                Add a subject line for this campaign.
              </small>
            )}
          </div>

          {isActive && (
            <AtlasButton
              type={"button"}
              className={"close"}
              onClick={handleClose}
            >
              &times;
            </AtlasButton>
          )}
          {!isActive && (
            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={handleAccordionChange("subject")}
              tooltip={true}
              tooltipID={`${isChecked ? "Edit" : "Add"} subject`}
              tooltipPosition={"top"}
              tooltipChildren={`${isChecked ? "Edit" : "Add"} subject`}
            >
              <span className={"button-text"}>
                {isChecked ? "Edit" : "Add"} subject
              </span>

              <BsPencilSquare className={"button-icon"} />
            </AtlasButton>
          )}
        </div>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={"expansion-panel-details__subject"}>
        <div className={"d-flex flex-column justify-content-between"}>
          <div>
            <div className={"mb-3"}>
              <h2 className={"at-form-input__title text-capitalize"} required>
                Subject Line
              </h2>

              <CustomFormInput
                type={"text"}
                value={values.subject}
                onChangeValue={(val) => handleChange("subject", val)}
                placeholder={"Your subject"}
                required={true}
              />
            </div>

            <div className={"mb-3"}>
              <h2 className={"at-form-input__title text-capitalize"} required>
                Preview Text
              </h2>

              <CustomFormInput
                type={"text"}
                value={values.preview_text}
                onChangeValue={(val) => handleChange("preview_text", val)}
                placeholder={"Your preview text"}
                required={true}
              />
            </div>
          </div>

          <div className={"d-flex mb-4 g-3"}>
            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--success"}
              onClick={handleSave}
            >
              Save
            </AtlasButton>

            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={handleClose}
            >
              Cancel
            </AtlasButton>
          </div>
        </div>

        <div className={"position-relative text-center"}>
          <small className={"text-muted tiny-text"}>
            Actual email preview may vary depending on the email client.
          </small>

          <Phone
            selectedEmailCampaign={selectedEmailCampaign}
            values={values}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Subject;
