import React from "react";
import Moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import AtlasCloseButton from "components/Button/close";

import "./previewCobrokeLetterModal.scss";

const PreviewCobrokeLetterModal = ({
  open,
  onClickClose,
  letter_date,
  property_address,
  commission_type_id,
  valid_from,
  valid_to,
  agent_commission,
  co_broker_commission,
  full_name,
  email,
  contact_number,
  agency,
  ren_tag,
  type_id,
  use_display_name,
  user,
  sales_tax_percentage,
  show_professional_fee_percentage,
  professional_fee_percentage,
}) => {
  const type_title = type_id === 1 ? "Sale" : "Rental";
  const coAgentName = `${full_name}${ren_tag ? ` (${ren_tag})` : ""}`;
  const from = `${use_display_name ? user.display_name : user.full_name}${user.board_registration_number ? `( ${user.license_number})` : ""}`;

  const getData = (field, type) => {
    switch (type) {
      case 1:
        if (parseFloat(field) > 0.0) {
          let floatField = field.toFixed(2);
          let formattedField = parseFloat(floatField).toLocaleString(
            undefined,
            { minimumFractionDigits: 2 },
          );
          return formattedField;
        } else {
          return "0.00";
        }
      case 2:
        if (parseFloat(field) > 0.0) {
          let floatField = parseFloat(field).toFixed(1);
          let formattedField = parseFloat(floatField).toLocaleString(
            undefined,
            { minimumFractionDigits: 1 },
          );
          return formattedField;
        } else {
          return "0.0";
        }
      case 3:
        if (parseInt(field) > 0) {
          return field.toFixed(0);
        } else {
          return "0.0";
        }
      case 4:
        if (field !== null) {
          let regex = /^\d{2} [A-Za-z]{3} \d{4}$/;
          if (regex.test(field)) {
            return Moment(field, "DD MMM YYYY").format("DD-MM-YYYY");
          } else {
            return `${field}`;
          }
        } else {
          return `NOT STATED`;
        }
      default:
        return field;
    }
  };

  const extra = () => {
    switch (commission_type_id) {
      case 1:
        return `shared on ${getData(agent_commission, 2)}% : ${getData(co_broker_commission, 2)}% basis based on the full commission received on the ${type_title} price.`;
      case 2:
        return `paid / received RM ${getData(co_broker_commission, 1)} to / from your agency plus ${sales_tax_percentage}% SST on the fee. `;
      default:
        return "";
    }
  };

  const letterHead = [
    {
      title: "Date",
      value: getData(letter_date, 4) || "NOT STATED",
    },
    {
      title: "Name of Co-Agent",
      value: getData(coAgentName, 4) || "NOT STATED",
    },
    {
      title: "Email of Co-Agent",
      value: getData(email, 4) || "NOT STATED",
    },
    {
      title: "Contact of Co-Agent",
      value: getData(contact_number, 4) || "NOT STATED",
    },
    {
      title: "Name of Agency",
      value: getData(agency.name, 4) || "NOT STATED",
    },
    {
      title: "Address",
      value: getData(agency.address, 4) || "NOT STATED",
    },
    {
      title: "From",
      value: getData(from, 4) || "NOT STATED",
    },
    {
      title: "Contact No.",
      value: getData(user.mobile_contact_number, 4) || "NOT STATED",
    },
  ];

  const renderLetterContent = () => {
    return (
      <div
        className="d-flex justify-content-between align-items-start mr-1 p-1"
        onCopy={(e) => e.preventDefault()}
      >
        <div style={{ zIndex: "999" }}>
          <table className="cobroke-letter-table">
            <tbody>
              {letterHead.map((item) => {
                return (
                  <tr>
                    <td className="col-left" style={{ width: "38%" }}>
                      {item.title}
                    </td>
                    <td className="col-left" style={{ width: "2%" }}>
                      :
                    </td>
                    <td style={{ width: "60%" }}>{item.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div style={{ marginTop: 20 }}>Dear Sir/Madam,</div>
          <div style={{ marginTop: 10, fontWeight: "bold" }}>
            {`RE : Co-Agency Arrangement for the  ${type_title} of :`}
          </div>
          <div style={{ marginTop: 10 }}>
            PROPERTY : <u> {`${property_address}`} </u>
          </div>
          <div style={{ marginTop: 15, textAlign: "justify" }}>
            We would like to inform you that we have a client who is interested
            in the above mentioned properties and would like to view it. We
            would appreciate if you could make the necessary arrangements for
            viewing.
          </div>
          <div style={{ marginTop: 10 }}>
            This co-agency arrangement will be subjected to the following terms:
          </div>
          <div style={{ marginTop: 5 }}>
            <table className="cobroke-term-table">
              <tbody>
                <tr>
                  <td className="col-left">I.</td>
                  {commission_type_id === 3 && (
                    <td className="col-right">
                      {" "}
                      {`Upon the successful ${type_title} transaction, the professional fee will be collected directly from the respective client.`}
                    </td>
                  )}
                  {commission_type_id !== 3 && (
                    <td className="col-right">
                      {" "}
                      {`Upon the successful ${type_title} transaction, the professional fee${type_id === 1 && show_professional_fee_percentage ? ` ${getData(professional_fee_percentage, 2)}%` : ""} would be ${extra()}`}
                    </td>
                  )}
                </tr>
                <tr>
                  <td className="col-left">II.</td>
                  <td className="col-right">
                    {`Should the transaction be aborted by ${type_id === 1 ? "Purchaser" : "Tenant"} after the Earnest Deposit has been paid, 50% of the ${commission_type_id === 3 ? "forfeited amount" : "agreed fees"} shall be shared equally between ${type_id === 1 ? "Vendor" : "Landlord"} and Agency;`}
                  </td>
                </tr>
                <tr>
                  <td className="col-left">III.</td>
                  <td className="col-right">
                    <span>
                      {" "}
                      This Co-Agency Agreement shall be valid from{" "}
                      <u>{`${getData(valid_from, 4)}`} </u> to{" "}
                      <u>{`${getData(valid_to, 4)}`}</u>, Either agency shall
                      not contact the respective client directly unless consent
                      is granted whilst the co-agency arrangement is still in
                      force.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="col-left">IV.</td>
                  <td className="col-right">
                    All other terms shall be in accordance with Standard 7.2.4
                    to the Valuers, Appraisers and Estate Agents and Property
                    Managers. In a co-agency arrangement, the earnest deposit
                    paid by the prospective party may be collected by either the
                    Listing Agency or the Co-Agency. However only the party
                    authorized by the Client to be the rightful appointed
                    Stakeholder shall HOLD the Earnest Deposit, unless otherwise
                    agreed in writing by the client. The rights of the Listing
                    Agent shall be respected.
                  </td>
                </tr>
                <tr>
                  <td className="col-left">V.</td>
                  <td className="col-right">
                    <span>
                      Service Tax for Estate Agents are classified under Group G
                      in accordance with the Service Tax (Persons Exempted from
                      payment of Tax) Order 2018.{" "}
                      <b>
                        <u>Amendment of regulation 10</u>
                      </b>{" "}
                    </span>
                    <p>
                      "(1A) Where a registered person provides any taxable
                      service to his customer who is a registered person
                      providing the same taxable service as provided by him, and
                      such customer is entitled for exemption of service tax
                      under section 34 of the Act in relation to the taxable
                      service provided by the registered person, the registered
                      person shall issue an invoice under sub regulation (1)
                      with the following additional particulars: Name and
                      address of the customer, The customer’s service tax
                      registration number, and The customer’s total amount of
                      service tax that are exempted."
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="col-left">VI.</td>
                  <td className="col-right">
                    All signatories to this document hereby acknowledge and agree to comply with the Malaysia Anti-Corruption Commission Act 2009 and Amendment Act - Section 17A of the MACC Act 2009 (MACC 2009), Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 (AML/CFT), 
                    Personal Data Protection Act 2010 and further authorise IQI Realty Sdn Bhd and/or its group companies to disclose their information to third parties as necessary, inter alia, 
                    at the request of regulatory authorities. For reference, parties may refer to our website at <a target="_blank" href="https://www.iqiglobal.com/amla">https://www.iqiglobal.com/amla</a> and <a target="_blank" href="https://www.iqiglobal.com/privacy-policy">https://www.iqiglobal.com/privacy-policy</a>.
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ marginTop: 10 }}>
              If the above is acceptable to you, kindly indicate acceptance by
              returning the copy of this letter to us duly signed and stamped.
            </div>
            <div style={{ marginTop: 30 }}>
              <p>Thank you</p>
              <p>Yours faithfully,</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog maxWidth={false} open={open} fullScreen>
      <DialogTitle>
        <div className="d-flex">
          <AtlasCloseButton
            containerStyle={{ display: "flex", marginLeft: "auto" }}
            onClick={() => onClickClose(false, "showPreviewCobrokeLetterModal")}
          />
        </div>
      </DialogTitle>
      <DialogContent dividers className="watermark p-4">
        {renderLetterContent()}
      </DialogContent>
      <DialogActions>
        <div className="form-action">
          <button
            className="btn-new btn-new--primary"
            onClick={() => onClickClose(false, "showPreviewCobrokeLetterModal")}
          >
            Close
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewCobrokeLetterModal;
