import React, { useEffect } from "react";
import { compose } from "redux";
import { DialogContent } from "@material-ui/core";

import Tabs from "components/Tab";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";
import CustomCard from "components/Card";

import DetailTab from "./tabs/Details";
import PhotosTab from "./tabs/Photo";
import FloorPlanTab from "./tabs/FloorPlan";
import JuwaiSubsTab from "./tabs/JuwaiSubs";
import SettingTab from "./tabs/Settings";
import MediaLink from "./tabs/MediaLink/index";
import RecommendationSettings from "./tabs/RecommendationSettings";
import SubsalesListingPreview from "../components/subsalesListingPreview";

import EditHOC from "../actions/edit";
import DetailsHOC from "../actions/detail";

import "../index.scss";

const sections = [
  "Details",
  "Photos",
  "Floor Plan",
  "Media Links",
  "Juwai Subscription",
  "Recommendation Settings",
];

const Edit = (props) => {
  useEffect(() => {
    !props.showEditModal && props.onChangeEditHOC("Details", "selectedSection");
  }, [props.showEditModal]);

  const renderCardContent = () => {
    if (props.selectedSection === "Details") {
      return <DetailTab dictionary={props.data.dictionaryReducer} {...props} />;
    }
    if (props.selectedSection === "Photos") {
      return <PhotosTab {...props} />;
    }
    if (props.selectedSection === "Setting") {
      return <SettingTab {...props} />;
    }
    if (props.selectedSection === "Floor Plan") {
      return <FloorPlanTab {...props} />;
    }
    if (props.selectedSection === "Juwai Subscription") {
      return (
        <JuwaiSubsTab
          agent_juwai_account={
            props.subsalesEntryCreationProps.agent_juwai_account
          }
        />
      );
    }
    if (props.selectedSection === "Media Links") {
      return (
        <MediaLink
          {...props}
          subsalesEntryCreationProps={props.subsalesEntryCreationProps}
        />
      );
    }

    if (props.selectedSection === "Recommendation Settings") {
      return <RecommendationSettings {...props} />;
    }
  };

  return (
    <>
      <DialogContent>
        <div className="at-card__header">
          <AtlasCloseButton
            onClick={() => props.onChangeSubsalesHOC(false, "showEditModal")}
          />
          <h4 className="at-card__title">Edit Listing</h4>
        </div>
        <Tabs
          sections={sections}
          selectedSection={props.selectedSection}
          onSelectSection={(val) =>
            props.onChangeEditHOC(val, "selectedSection")
          }
        />
        {(props.onLoadSubsales || props.onLoadEditHOC) && <LoadingModal />}
        <div style={{ minHeight: "100%" }} children={renderCardContent()} />
      </DialogContent>
      <SubsalesListingPreview {...props} />
    </>
  );
};

export default compose(EditHOC, DetailsHOC)(Edit);
