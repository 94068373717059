import React, { Component, Fragment } from "react";

import {Select, ListSubheader, MenuItem, MenuList} from '@material-ui/core';

import "./multi.scss";

class CustomSelect extends Component {
  state = {
    collapsed: true,
    currentlySelected: "",
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.currentlySelected &&
      this.props.currentlySelected !== prevProps.currentlySelected
    ) {
      this.setState({ currentlySelected: this.props.currentlySelected?.label || "" });
    }
  };

  componentDidMount = () => {
      this.setState({ currentlySelected: this.props.currentlySelected.label || "" });
  };

  toggleDropdown = () => {
    this.setState({ collapsed: true });
  };

  selectItem = (item) => {
    this.setState((prevState) => ({
      currentlySelected: item?.label || ""
    }));
    this.props.updateSelect(item);
  };

  onClickSelectItems = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  renderErrorMessage(error) {
    if (error) {
      return (
        <div className="at-form-input__label at-form-input__label--error">
          {error}
        </div>
      );
    }
  }

  renderRequired(param) {
    if (param) {
      return <div className="at-form-input__required">required *</div>;
    }
  }

  renderNewSelectItems = () => {
    const { selectItems, selectType } = this.props;
    if (selectType === "section") {
      return selectItems.map( item => (
        [
          <ListSubheader 
            key={item.name} 
            disableSticky
            className="at-select__dropdown-title">{item.name}</ListSubheader>, 
          item.children.map( child => (
            <MenuItem 
              key={child.id} 
              value={child.label}
              className="at-multi_select-dropdown_item"
              onClick={() => this.selectItem(child)}>{child.label}</MenuItem>
          ))
        ]
      )
      )
    } else {
      selectItems.map((item) => {
        return (
          <MenuItem
            key={item.value}
            value={item.label}
            onClick={() => this.selectItem(item)}>
            {item.label}
          </MenuItem>
        )
      })
    }
  }

  render() {
    const { className, removeError, required, disabled, inputError, selectType, selectItems, placeholder = "" } = this.props;
    const checkEmptyObj = (this.state.currentlySelected !== null && typeof this.state.currentlySelected === "object") && (Object.keys(this.state.currentlySelected)?.length === 0 || !this.state.currentlySelected.value);

    return (
      <div
        tabIndex="1"
        className={`at-select ${className} ${disabled ? "at-select--disabled" : ""}`}
        onChange={removeError}>
        <div className='position-relative'>
          <Select 
            defaultValue={""} 
            disabled={disabled}
            value={this.state.currentlySelected || placeholder}
            className={`at-select__selected-item ${(!this.state.currentlySelected || checkEmptyObj) ? "at-select__selected-placeholder" : ""}`}
            classes={{root: "at-multi_select-input_wrapper"}}
            MenuProps={{ 
              classes: {
                paper: "at-multi_select-dropdown"
              }
            }}
            onChange={ e => {
              let tmpSelected = {
                label: "",
                name: "",
                id: "",
                value: ""
              }
              if(selectType === "section") {
                let targetItem = selectItems.find(item => item.children.find(child => child.label === e.target.value));
                if(targetItem && targetItem.children) {
                  tmpSelected = targetItem?.children?.find(child => child?.label === e.target.value) || tmpSelected
                }
              } else {
                tmpSelected = selectItems?.find( item => item?.label === e.target.value) || tmpSelected;
              }
              this.selectItem(tmpSelected)
            }} 
            id="grouped-select">
            <MenuItem 
              value={this.props.placeholder}
              className="at-multi_select-dropdown_item at-select__selected-placeholder"
              onClick={() => this.selectItem({
                label: "",
                name: "",
                id: "",
                value: ""
              })}>
              {this.props.placeholder || ""}
            </MenuItem>
            {this.renderNewSelectItems()}
          </Select>
        </div>
        <div
          className="at-input_footer"
          style={ (inputError || required) ? { marginBottom: "1rem" } : {} }>
          {this.renderRequired(required)}
          {this.renderErrorMessage(inputError)}
        </div>
      </div>
    );
  }
}

export default CustomSelect;