import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Dialog from "@material-ui/core/Dialog";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import AtPaginatedTable from "./components/Table";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtlasSnackbar from "components/Snackbar/v2";

import SubsalesHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import FloorPlanHOC from "./actions/floorplan";
import MediaLinkHOC from "./actions/medaLink";
import permissionsChecker from "utils/permissionsChecker";

import EditModal from "./Edit";
import ViewModal from "./View";

class Subsales extends Component {
  componentDidMount = () => {
    this.props.getCountryDictionary();
    this.props.getSubsalesCurrentUser(1, "");
  };

  componentDidUpdate = (pp) => {
    let temp = _.cloneDeep(this.props.searchParams);
    let countryIndex = _.findIndex(this.props.searchParams, {
      value: "country_id_eq",
    });
    let stateIndex = _.findIndex(this.props.searchParams, {
      value: "state_id_eq",
    });
    let townIndex = _.findIndex(this.props.searchParams, {
      value: "township_cont",
    });

    if (pp.searchParams[countryIndex].param !== temp[countryIndex].param) {
      let selectedCountry = _.find(
        this.props.data.dictionaryReducer.countries,
        { id: this.props.searchParams[countryIndex].param },
      );
      temp[stateIndex].options = selectedCountry?.states ?? [];
      temp[stateIndex].param = "";
      temp[townIndex].param = "";
      temp[townIndex].options = [];
      pp.searchParams[stateIndex].param = "";

      this.props.onChangeSubsalesHOC(temp, "searchParams");
    }

    if (
      pp.searchParams[stateIndex].param !== temp[stateIndex].param &&
      temp[stateIndex].param !== ""
    ) {
      this.props.getSelectedTownShip(temp[stateIndex].param);
    }
  };

  renderCardContent = () => {
    const { can_update } = permissionsChecker(
      "Listing Centre",
      this.props.data,
    );

    return (
      <>
        <AtPaginatedTable
          headerData={this.props.subsalesTableHeader}
          rowData={
            this.props.tmpSubsalesCurrentUser.data
              ? this.props.tmpSubsalesCurrentUser.data
              : []
          }
          meta={
            this.props.tmpSubsalesCurrentUser.meta &&
            this.props.tmpSubsalesCurrentUser.meta
          }
          onClickCard={(val) => {
            Promise.all([
              this.props.getSelectedSubsales(val.id),
              this.props.getFloorPlans(val.id),
            ]).then(() => {
              this.props.onChangeSubsalesHOC(true, "showViewModal");
            });
          }}
          onClickFavourite={(id) => {
            this.props.toggleFavourite(id);
          }}
          onClickEdit={(val) => {
            Promise.all([
              this.props.getSelectedSubsales(val.id),
              this.props.getFloorPlans(val.id),
            ]).then(() => {
              this.props.onChangeSubsalesHOC(true, "showEditModal");
            });
          }}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeSubsalesHOC(val, "searchParams")
          }
          getListAPI={this.props.getSubsalesCurrentUser}
          totalPages={this.props.subsalesPages}
          can_update={can_update}
          sideMenuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
        />
        <Dialog
          open={this.props.showSubsaleInfoLoadingModal}
          onClose={() => {}}
        >
          <CustomCard cardContent={this.renderCardContentModal()} />
        </Dialog>
      </>
    );
  };

  renderCardContentModal = () => {
    return (
      <>
        <h4>Notification</h4>
        <hr />
        <p>
          Please wait awhile while we load the necessarily information required
          from the server for you.
        </p>
      </>
    );
  };

  render = () => {
    const {
      onLoadSubsales,
      onLoadFavourite,
      data,
      showEditModal,
      showViewModal,
    } = this.props;
    return (
      <>
        <ModuleHeader
          title={"Listing Centre"}
          moduleIcon={"icon-listing-centre"}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog
          open={showEditModal}
          className={`${data.sideMenuReducer.sideMenuExpand ? "at-subsales_listing-menu_expand" : ""}`}
          paperClassName={"paper"}
        >
          <EditModal {...this.props} />
        </AtlasDialog>
        <AtlasDialog
          className={`at-subsales_listing__view-dialog ${data.sideMenuReducer.sideMenuExpand ? "at-subsales_listing-menu_expand" : ""}`}
          open={showViewModal}
          paperClassName={"paper"}
        >
          <ViewModal {...this.props} />
        </AtlasDialog>
        <AtlasSnackbar
          open={this.props.openSnackBar}
          handleClose={() =>
            this.props.onChangeSubsalesHOC(false, "openSnackBar")
          }
          message={this.props.snackBarMessage}
        />
        {(onLoadSubsales || onLoadFavourite) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  SubsalesHOC,
  FloorPlanHOC,
  MediaLinkHOC,
  VersionCheckHOC,
)(Subsales);
