import React, { useState, useEffect, useMemo, useCallback } from "react";

import AlertBox from "components/AlertBox";
import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "./ModalDialog";

const EmailModal = ({
  title,
  submitEmail,
  closeSendEmail,
  showSendEmail,
  email,
  loading,
}) => {
  const [recipient, onChangeRecipient] = useState("");
  const [recipientError, onChangeRecipientError] = useState("");

  useEffect(() => {
    onChangeRecipient(email);
    onChangeRecipientError("");
  }, [email]);

  const onChangeField = (val) => {
    onChangeRecipient(val);
    onChangeRecipientError(val === "" ? "Email address cannot be empty" : "");
  };

  useEffect(() => {
    onChangeRecipient("");
    onChangeRecipientError("");
  }, [showSendEmail]);

  let disabledSubmit = useMemo(() => {
    return !recipient || recipientError;
  }, [recipient, recipientError]);

  const renderCardContent = useCallback(() => {
    return (
      <div className="grid-control">
        <section className="grid-full-col">
          <AlertBox
            title={""}
            mode={"info"}
            description={
              <>
                <p>Please include a , between email addresses if multiple emails need to be included as recipients.</p> 
                <p>For e.g. test@gmail.com,john@gmail.com</p>
              </>
            }
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Recipient's Email</h2>
          <CustomFormInput
            type="text"
            value={recipient}
            onChangeValue={(val) => onChangeField(val)}
            required={true}
            inputError={recipientError}
          />
        </section>
      </div>
    );
  }, [recipient, recipientError, email]);

  const renderFooter = useCallback(() => {
    return (
      <div className="d-flex g-3">
        <button
          type={"button"}
          disabled={disabledSubmit}
          className="btn-new btn-new--primary"
          onClick={() => submitEmail(recipient)}
        >
          Send Now
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => closeSendEmail()}
        >
          Cancel
        </button>
      </div>
    );
  }, [recipient, disabledSubmit]);

  return (
    <>
      {showSendEmail && (
        <ModalDialog
          title={title}
          onClose={closeSendEmail}
          children={renderCardContent()}
          footer={renderFooter()}
        />
      )}
      {loading && <LoadingModal />}
    </>
  );
};

export default EmailModal;