import { FaEdit } from "react-icons/fa";
import { BsTrash3Fill } from "react-icons/bs";

import AtlasButton from "components/Button";

import { status } from "../../assets";
import { getColorBadge } from "dictionary/badgeColor";
import PlaceholderImage from "assets/images/no_photo_available.png";

const CustomCard = ({
  data,
  can_update,
  can_destroy,
  onClickEdit,
  onClickDelete,
}) => {
  const { id, name, sort_list, status_id, thumbnail_url } = data;

  const { colorName, label } = status.find((item) => item.value === status_id);

  return (
    <li className={"at-email-templates__card"}>
      <span
        className={"at-status_badge"}
        style={{ ...getColorBadge(colorName) }}
      >
        {label}
      </span>

      <div className={"action-buttons"}>
        {can_update && (
          <AtlasButton
            className={"at-table-icon-button mr-1"}
            tooltip={true}
            tooltipChildren={"Edit"}
            tooltipPosition={"top"}
            tooltipID={"edit-email-template"}
            onClick={() => onClickEdit(id)}
          >
            <FaEdit style={{ width: 20, height: 20, color: "#007BFF" }} />
          </AtlasButton>
        )}

        {can_destroy && (
          <AtlasButton
            className={"at-table-icon-button"}
            tooltip={true}
            tooltipChildren={"Delete"}
            tooltipPosition={"top"}
            tooltipID={"delete-email-template"}
            onClick={() => onClickDelete(id)}
          >
            <BsTrash3Fill style={{ width: 20, height: 20, color: "#F04438" }} />
          </AtlasButton>
        )}
      </div>

      <img
        className={"at-email-templates__card-media"}
        src={thumbnail_url || PlaceholderImage}
        alt={name}
        title={name}
        height={250}
      />

      <hr className={"m-0"} />

      <div className={"at-email-templates__card-content"}>
        <h5 className={"at-email-templates__card-title"}>{name}</h5>
      </div>

      {sort_list.length > 0 && (
        <div className={"at-email-templates__card-footer"}>
          {sort_list.sort().map((category, index) => (
            <span key={index} className={"at-email-templates__category-chip"}>
              {category}
            </span>
          ))}
        </div>
      )}
    </li>
  );
};

export default CustomCard;
