import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import HtmlParser from "react-html-parser";

import LoadingOverlay from "components/LoadingModal/overlay";

import LoginForm from "../../components/Form/login";
import LoginImage1 from "../../assets/images/login_img1.png";
import LoginImage2 from "../../assets/images/login_img2.png";
import IQILogoBlack from "../../assets/images/iqi-logo_black.png";
import IQILogoWhite from "../../assets/images/iqi-logo_white.png";
import SupportButtonGroups from "components/Button/supportGroups";

import { getAllDictionaryData } from "actions/dictionary";
import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { getBranches } from "actions/branch";
import { getAgencies } from "actions/agency";
import { login, mobileSSO } from "actions/login";

import { initGA } from "utils/ga";
import { getItem } from "utils/tokenStore";

import "./index.scss";

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

class Login extends Component {
  state = {
    errorPresence: false,
    errorMessage: "",
    showLoadingOverlay: false,
    ajaxCallProgress: 0,
    ajaxErrorMessage: "",
    from: "",
    error: null,
  };

  componentDidMount = () => {
    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      initGA("/");
    }
    if (getItem("IQI_ATLAS_JWT_TOKEN") !== null) {
      this.props.history.push("/dashboard/statistic");
    }
    if (this.props.location.search) {
      let query = new URLSearchParams(this.props.location.search);
      let token = query.get("token");
      if (token && token.length > 0) {
        localStorage.setItem("IQI_ATLAS_OUTDATED_TOKEN", "no");
        this.setState(
          {
            from: "ON_LOGIN",
          },
          () => this.props.mobileSSO(atob(token)),
        );
      }
    }
    document.title = "IQI Atlas";
  };

  componentDidUpdate = (prevProps) => {
    let { ajaxCallProgress, ajaxSuccess, ajaxError, ajaxErrorMessage } =
      this.props.data.ajaxStatusReducer;
    if (
      ajaxCallProgress !== prevProps.data.ajaxStatusReducer.ajaxCallProgress
    ) {
      if (ajaxCallProgress === 0) {
        if (ajaxError) {
          this.setState({ showLoadingOverlay: false });
          this.ajaxError(HtmlParser(ajaxErrorMessage));
        }
        if (ajaxSuccess) {
          if (this.state.from === "ON_LOGIN") {
            this.setState(
              { showLoadingOverlay: true, from: "LOGIN_SUCCESS" },
              () => {
                window.location.href.includes("/admin-impersonate")
                  ? this.props.getCurrentSignInProfile()
                  : this.props.getCurrentUserProfile();
              },
            );
          }
          if (this.state.from === "LOGIN_SUCCESS") {
            this.setState({ showLoadingOverlay: true }, () => {
              this.props.history.push("/dashboard/statistic");
            });
          }
        }
      } else {
        this.setState({ showLoadingOverlay: true });
      }
    }
  };

  ajaxError = (message) => this.setState({ errorMessage: message });

  onClickForgetPassword = () => this.props.history.push("/forget-password");

  checkErrorPresence = (errorPresence, errorMessage) =>
    this.setState({
      errorPresence: errorPresence,
      errorMessage: errorMessage,
    });

  emailPasswordCheckPassed = (val, credentials) => {
    if (val) {
      localStorage.setItem("IQI_ATLAS_OUTDATED_TOKEN", "no");
      this.setState(
        {
          from: "ON_LOGIN",
        },
        () => this.props.login(credentials),
      );
    }
  };

  render = () => {
    return (
      <>
        <div className="d-flex" style={{ height: "100vh", overflow: "hidden" }}>
          <div className="at-desktop-view__controller at-login__banner">
            <div className="at-login__banner-header">
              <img
                src={IQILogoWhite}
                alt={"IQI Logo White"}
                style={{ width: 70, margin: "20px 0px" }}
              />
              <p>Empowering IQI agents, streamlining real estate process</p>
            </div>
            <div className="at-login__banner-slider">
              <Slider {...SliderSettings}>
                <img src={LoginImage1} alt={"Login Image 1"} />
                <img
                  src={LoginImage2}
                  alt={"Login Image 2"}
                  style={{ maxWidth: 250 }}
                />
              </Slider>
            </div>
          </div>
          <div className="login-container">
            <div className="at-login__header">
              <img
                src={IQILogoBlack}
                alt={"IQI Logo Black"}
                style={{ width: "114px" }}
              />
              <h2>Sign in to IQI ATLAS</h2>
            </div>
            <div className="login-panel">
              <LoginForm
                errorMessage={this.state.errorMessage}
                errorPresence={(errorPresence, errorMessage) =>
                  this.checkErrorPresence(errorPresence, errorMessage)
                }
                emailPasswordCheckPassed={(val, credentials) =>
                  this.emailPasswordCheckPassed(val, credentials)
                }
                onClickForgetPassword={() => this.onClickForgetPassword()}
              />
            </div>
            <div className="at-mobile-view__controller at-login__banner-slider">
              <Slider {...SliderSettings}>
                <img src={LoginImage1} alt={"Login Image 1"} />
                <img src={LoginImage2} alt={"Login Image 2"} />
              </Slider>
            </div>
            <div className="at-login__footer">
              <p className="small">{`IQI Holdings Sdn Bhd (1018842-U)`}</p>
              <p className="small" style={{ whiteSpace: "pre-line" }}>
                {`26th -28th Floor, Tower D, Millerz Square, No. 357, Jalan Kelang Lama, 58000 Kuala Lumpur, Malaysia. \nT: +60374506655`}
              </p>
              <p
                className="small"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.iqiglobal.com/privacy-policy",
                    "__blank",
                  )
                }
              >
                <u className="mx-2">Terms of use</u>•
                <u className="mx-2">Privacy Policy</u>
              </p>
            </div>
          </div>
        </div>
        <LoadingOverlay showLoadingOverlay={this.state.showLoadingOverlay} />
        <SupportButtonGroups />
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default connect(mapStateToProps, {
  login,
  mobileSSO,
  getAllDictionaryData,
  getCurrentUserProfile,
  getCurrentSignInProfile,
  getBranches,
  getAgencies,
})(Login);
