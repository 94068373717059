import { useState } from "react";

import AtlasPrevButton from "components/Button/prev";
import Design from "./Design";
import Setup from "./Setup";

const Update = ({
  categories,
  selectedEmailTemplate,
  onChangeEmailTemplatesHOC,
  updateEmailTemplate,
}) => {
  const [isSetup, setIsSetup] = useState(true);

  const handleClose = () => {
    onChangeEmailTemplatesHOC("emailTemplateDialog", "");
    setIsSetup(true);
  };

  const toggleSetup = () => setIsSetup(!isSetup);

  return (
    <>
      <div className={"at-card__header"}>
        <AtlasPrevButton onClick={handleClose} />
        <h5 className={"at-card__title"}>Edit Email Template</h5>
      </div>

      {isSetup && (
        <Setup
          categories={categories}
          selectedEmailTemplate={selectedEmailTemplate}
          onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
          toggleSetup={toggleSetup}
          updateEmailTemplate={updateEmailTemplate}
        />
      )}
      {!isSetup && (
        <Design
          handleClose={handleClose}
          selectedEmailTemplate={selectedEmailTemplate}
          toggleSetup={toggleSetup}
          updateEmailTemplate={updateEmailTemplate}
        />
      )}
    </>
  );
};

export default Update;
