import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Card, Dialog } from "@material-ui/core";

import JuwaiIQILogo from "assets/images/juwai_iqi_logo.jpg";
import AtlasIcon from "components/Icon/atlasIcon";
import SupportButtonGroups from "components/Button/supportGroups";
import AtlasCloseButton from "components/Button/close";
import SimpleModal from "components/Modal/simple";
import PolicyContent from "./components/policyContent";
import PrivacyContent from "./components/privacyContent";
import SelectionPage from "./components/selection";
import ModalDialog from "components/Modal/ModalDialog";

import AgentRegistrationPaymentHOC from "./action";
import PaymentApprovalHOC from "./action/Payment";

class AgentRegistrationPayment extends Component {
  componentDidMount = () => {
    let { message = {} } = this.props.data.registerReducer.newUser;
    if (message && Object.values(message).length > 0) {
      const { branch_id } = this.props.data.registerReducer.newUser.message;
      this.props.onChangeHOC(branch_id, "branch_id");
      this.props.onChangeHOC(message, "message");
      this.props.getPaymentStatus(message.id);
    } else {
      const url = new URL(window.location.href);
      const hashIndex = url.href.indexOf('#');
      let search = '';
      if (hashIndex !== -1) {
        const fragment = url.href.slice(hashIndex + 1);
        const queryIndex = fragment.indexOf('?');
        if (queryIndex !== -1) {
          search = fragment.slice(queryIndex);
        }
      }
      const searchParams = new URLSearchParams(search);
      const paramsObject = {};
      for (let [key, value] of searchParams.entries()) {
        paramsObject[key] = value;
      }     
      message = {
        id: paramsObject.user_id,
        agent_registration_id: paramsObject.agent_registration_id,
        branch_id: paramsObject.branch_id,
        email: paramsObject.email,
        first_name: paramsObject.first_name,
        last_name: paramsObject.last_name,
        agent_via_recruitment_campaign: paramsObject.agent_via_recruitment_campaign === "true",
        agent_status_id: paramsObject.agent_status_id,
        mobile_contact_number: paramsObject.mobile_contact_number,
      };
      this.props.onChangeHOC(message, "message");
      this.props.onChangeHOC(paramsObject.branch_id, "branch_id");
      this.props.getPaymentStatus(message.id);
    }

    if ( window.location.href.includes("/fail") ) {
      this.props.onChangeHOC("fail", "paymentStatus")
      this.props.onChangeHOC(true, "showPaymentStatus");
    } else if ( window.location.href.includes("/success")) {
      this.props.onChangeHOC("success", "paymentStatus");
      this.props.onChangeHOC(true, "showPaymentStatus");
      this.props.onChangeHOC(false, "showPaymentGateway");
    }
  };

  renderPaymentCardContent = () => {
    const {
      paymentMethod,
      message,
      getPrice,
      uploadedPaymentUrl,
      paymentMethodId,
      paymentStatus,
      tabContentType,
      onChangeHOC,
      payment_proof,
      onChangeFile,
      history,
      from,
      employment_proof,
      employmentUrl,
      branch_id,
      showPaymentStatus,
      showPaymentGateway,
      onClickSubmitPaymentProof,  
    } = this.props;
    return (
      <>
        {
          showPaymentStatus && 
            <ModalDialog
              maxWidth="sm"
              responsiveSize="sm"
              children={
                <div className="d-flex flex-column g-3">
                  <div className={"text-center"}>
                    <div className="at-confirmation_modal-icon">
                      <AtlasIcon
                        svgHref={`${paymentStatus === 'success' 
                          ? 'atlas-tick-circle' 
                          : 'atlas-danger'}`
                        }
                        style={{ 
                          fill: `${paymentStatus === 'success' 
                            ? '#12B76A' 
                            : '#F04438'}`, 
                          backgroundColor: `${paymentStatus === 'success' 
                            ? '#D1FADF' 
                            : '#FEE4E2'}`,
                        }}
                      />
                    </div>
                    <br />
                    <h4 className="fs-4 fw-600">{`Payment ${paymentStatus === 'success' ? 'Successful' : 'Failed'}`}</h4>
                    {paymentStatus === 'fail' && <p className={"fs-2 mt-10 mb-20"}>You will be redirect to the payment page.</p>}
                  </div>
                  <button
                    className="btn-new btn-new--primary w-100"
                    onClick={() => {
                      window.location = `${window.location.origin}/#/register/register-payment`;
                      this.props.onChangeHOC(true, "showPaymentGateway");
                      this.props.onChangeHOC(false, "showPaymentStatus");
                    }}>
                    OK
                  </button>
                </div>
              }
            />
        }
        {!showPaymentStatus && (
          <SelectionPage
            message={message}
            currencyCode={"MYS"}
            user_id={message?.id ?? ""}
            branch_id={branch_id}
            registerReducer={this.props.data.registerReducer}
            paymentMethod={paymentMethod}
            paymentMethodId={paymentMethodId}
            uploadedPaymentUrl={uploadedPaymentUrl}
            tabContentType={tabContentType}
            onChangeHOC={onChangeHOC}
            from={from}
            history={history}
            showPaymentGateway={showPaymentGateway}
            onClickSubmitPaymentProof={onClickSubmitPaymentProof}
            onChangeFile={onChangeFile}
            payment_proof={payment_proof}
            employment_proof={employment_proof}
            employmentUrl={employmentUrl}
            getPrice={getPrice}
            agent_via_recruitment_campaign={
              message.agent_via_recruitment_campaign
            }
            onLoading={
              this.props.onLoadPayment ||
              this.props.onLoadAgentRegister ||
              this.props.data.ajaxStatusReducer.ajaxCallProgress > 0
            }
            userID={message ? message.id : ""}
            agentRegistrationID={message ? message.agent_registration_id : ""}
          />
        )}
      </>
    );
  };

  render = () => {
    return (
      <>
        <Card style={{ padding: "30px", width: "100%", overflow: "scroll" }}>
          <div style={{ textAlign: "center" }}>
            <img src={JuwaiIQILogo} alt={"JuwaiLogo"} style={{ height: 200 }} />
          </div>
          <div style={{ maxWidth: 1200, margin: "auto" }}>
            {this.renderPaymentCardContent()}
          </div>
        </Card>
        <SimpleModal
          isOpen={this.props.showPolicyRefundModal}
          closeModal={() =>
            this.props.onChangeHOC(false, "showPolicyRefundModal")
          }
          modalContent={<PolicyContent />}
        />
        <SimpleModal
          isOpen={this.props.showPrivacyPolicyModal}
          closeModal={() =>
            this.props.onChangeHOC(false, "showPrivacyPolicyModal")
          }
          modalContent={<PrivacyContent />}
        />
        <Dialog
          open={this.props.showPaymentProofModal}
          maxWidth={"md"}
          fullWidth={true}
        >
          <AtlasCloseButton
            onClick={() =>
              this.props.onChangeHOC(false, "showPaymentProofModal")
            }
            containerStyle={{
              position: "absolute",
              right: 15,
              top: 15,
            }}
          />
          <img
            alt="uploaded_payments"
            src={this.props.uploadedPaymentUrl}
            style={{ width: "100%", cursor: "pointer" }}
          />
        </Dialog>
        <Dialog
          open={this.props.showEmployementProofModal}
          maxWidth={"md"}
          fullWidth={true}
        >
          <AtlasCloseButton
            onClick={() =>
              this.props.onChangeHOC(false, "showEmployementProofModal")
            }
            containerStyle={{
              position: "absolute",
              right: 15,
              top: 15,
            }}
          />
          <img
            alt="employment_url"
            src={this.props.employmentUrl}
            style={{ width: "100%", cursor: "pointer" }}
          />
        </Dialog>        
        <SupportButtonGroups />
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default compose(
  AgentRegistrationPaymentHOC,
  PaymentApprovalHOC,
  connect(mapStateToProps)
)(AgentRegistrationPayment);
