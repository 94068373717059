import React, { useState } from "react";
import _ from "lodash";

import { Card } from "@material-ui/core";
import CustomSelect from "components/Select";
import CustomDialog from "components/Dialog";
import CustomRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import AtlasTextarea from "components/Input/textarea";
import AtInput from "components/Input/formInput";
import CustomButton from "components/Button";
import CustomCard from "components/Card";
import CustomUpload from "components/Upload";
import DefaultThumbnail from "assets/images/default_thumbnail.jpg";
import VideoPlayer from "components/VideoPlayer";
import LoadingModal from "components/LoadingModal";
import { ImPlay } from "react-icons/im";

const statusOptions = [
  { value: 1, label: "Draft" },
  { value: 2, label: "Published" },
];

const excludeLogoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const excludeDetailsOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const Update = ({
  data,
  onVideoTemplateLoad,
  permissionsChecker,

  onChangeVideoTemplateHOC,
  selectedTemplate,
  updateVideoTemplate,
}) => {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const { can_update } = permissionsChecker;

  const inputField = [
    {
      title: "Thumbnail",
      type: "file",
      value: "thumbnail_url",
    },
    {
      title: "Title",
      type: "input",
      value: "title",
    },
    {
      title: "Description",
      type: "textarea",
      value: "description",
    },
    {
      title: "Project",
      type: "input",
      value: "project_name",
      disabled: true,
    },
    {
      title: "Template File Name",
      type: "input",
      value: "mg_filename",
      disabled: true,
    },
    {
      title: "Video URL",
      type: "input",
      value: "url",
      disabled: true,
      instruction: (
        <a
          className="at-update__play-button"
          onClick={() => setShowVideoPlayer(true)}
        >
          <ImPlay />
          Play Video
        </a>
      ),
    },
    {
      title: "Country",
      type: "select",
      value: "country_id",
      option: data.dictionaryReducer.countries,
    },
    {
      title: "Exclude Logo",
      type: "radio",
      value: "exclude_logo",
      option: excludeLogoOptions,
    },
    {
      title: "Exclude Property Details",
      type: "radio",
      value: "exclude_property_details",
      option: excludeDetailsOptions,
    },
    {
      title: "Status",
      type: "radio",
      value: "status_id",
      option: statusOptions,
    },
  ];

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(selectedTemplate);
    temp[context] = val;
    onChangeVideoTemplateHOC(temp, "selectedTemplate");
  };

  const renderCardContent = () => (
    <>
      <div className="at-card__header">
        <AtlasCloseButton
          onClick={() => onChangeVideoTemplateHOC(false, "showUpdateModal")}
        />
        <h4 className="at-card__title">Edit Video Template</h4>
      </div>
      <Card style={{ padding: 20 }}>
        {inputField.map((item, index) => {
          return (
            <div
              key={index}
              className="at-video-template-management__update_item"
            >
              <strong className="d-flex">
                {item.title} {item.instruction && item.instruction}
              </strong>
              {item.type === "input" && (
                <AtInput
                  value={selectedTemplate[item.value]}
                  onChangeValue={(value) => onChangeField(value, item.value)}
                  disabled={item.disabled || !can_update}
                />
              )}
              {item.type === "textarea" && (
                <AtlasTextarea
                  value={selectedTemplate[item.value]}
                  disabled={!can_update}
                  onChangeValue={(value) => onChangeField(value, item.value)}
                />
              )}
              {item.type === "file" && (
                <CustomUpload
                  imageUrl={
                    selectedTemplate[item.value]
                      ? selectedTemplate[item.value]
                      : DefaultThumbnail
                  }
                  accept={`image/png,image/jpg,image/jpeg'`}
                  style={{ width: "100%" }}
                  disabled={!can_update}
                  uploadImage={(file, fileName) => {
                    let tmp = _.cloneDeep(selectedTemplate);
                    tmp.change_image = true;
                    tmp.thumbnail_url = file;
                    tmp.thumbnail_name = fileName;

                    onChangeVideoTemplateHOC(tmp, "selectedTemplate");
                  }}
                />
              )}
              {item.type === "radio" && (
                <CustomRadioGroup
                  disabled={!can_update}
                  checkedValue={selectedTemplate[item.value]}
                  options={item.option}
                  selectedRadioValue={(val) => onChangeField(val, item.value)}
                />
              )}
              {item.type === "select" && (
                <CustomSelect
                  className={"mt-3"}
                  currentlySelected={_.find(
                    item.option,
                    (opt) => opt.id === selectedTemplate[item.value],
                  )}
                  valueKey={"id"}
                  selectItems={item.option}
                  updateSelect={(val) => onChangeField(val.id, item.value)}
                />
              )}
            </div>
          );
        })}
        <div className="d-flex">
          {can_update && (
            <CustomButton
              className={"btn-new btn-new--success"}
              children={"Update"}
              onClick={() => updateVideoTemplate(selectedTemplate)}
            />
          )}
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => onChangeVideoTemplateHOC(false, "showUpdateModal")}
          >
            {can_update ? "Cancel" : "Close"}
          </button>
        </div>
      </Card>
    </>
  );

  return (
    <CustomDialog open={true}>
      <CustomCard className={`h-100`} cardContent={renderCardContent()} />
      {showVideoPlayer && (
        <VideoPlayer
          videoURL={selectedTemplate.url}
          onClickClose={() => setShowVideoPlayer(false)}
        />
      )}
      {onVideoTemplateLoad && <LoadingModal />}
    </CustomDialog>
  );
};

export default Update;
