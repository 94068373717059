import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import { getCountryDictionary } from "actions/dictionary";

import { Post, Put, Get, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [
  {
    label: "Title",
    value: "video_template_title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Project",
    value: "project_name_or_project_internal_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Processing" },
      { value: "2", label: "Ready" },
    ],
  },
  {
    label: "Created Date from",
    value: "created_at_dategteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
  {
    label: "Created Date to",
    value: "created_at_datelteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
];

const adminSearchParams = [
  {
    label: "Title",
    value: "video_template_title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Project",
    value: "project_name_or_project_internal_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Request Date from",
    value: "created_at_gteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
  {
    label: "Request Date to",
    value: "created_at_lteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Processing" },
      { value: "2", label: "Ready" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      selectedVideo: {},
      selectedProjectListVideo: {},

      adminView: false,

      searchParams: searchParams,
      adminSearchParams: adminSearchParams,
      videoTemplate: {},
      videoTemplatePage: null,
      personalisedVideo: {},
      personalisedVideoPage: null,
      youtubeLink: null,

      showVideoPlayer: false,
      showCreateModal: false,
      showShareModal: false,
      showDeleteConfirmation: false,
      showUpdateConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeVideoCreatorHOC = (val, context) =>
      this.setState({ [context]: val });

    getAdminPersonalisedVideo = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/video_requests?${search}page=${page}`,
        this.getAdminPersonalisedVideoSuccess,
        this.getAdminPersonalisedVideoError,
        this.load,
      );
    };
    getAdminPersonalisedVideoSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        personalisedVideo: payload,
        personalisedVideoPage: tmpTotalPages,
      });
    };
    getAdminPersonalisedVideoError = (error) => requestError(error);

    deleteAdminPersonalisedVideo = (id) =>
      Delete(
        `/admin/video_requests/${id}`,
        this.deleteAdminPersonalisedVideoSuccess,
        this.deleteAdminPersonalisedVideoError,
        this.load,
      );
    deleteAdminPersonalisedVideoSuccess = (payload) => {
      requestSuccess(payload.message);
      let lastView = this.props.data.lastViewReducer.lastView;
      this.getAdminPersonalisedVideo(
        lastView.currentPage,
        lastView.searchParams,
      );
    };
    deleteAdminPersonalisedVideoError = (error) => requestError(error);

    getPersonalisedVideo = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/video_requests?${search}page=${page}`,
        this.getPersonalisedVideoSuccess,
        this.getPersonalisedVideoError,
        this.load,
      );
    };
    getPersonalisedVideoSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        personalisedVideo: payload,
        personalisedVideoPage: tmpTotalPages,
      });
    };
    getPersonalisedVideoError = (error) => requestError(error);

    deletePersonalisedVideo = (id) =>
      Delete(
        `/video_requests/${id}`,
        this.deletePersonalisedVideoSuccess,
        this.deletePersonalisedVideoError,
        this.load,
      );
    deletePersonalisedVideoSuccess = (payload) => {
      requestSuccess(payload.message);
      let lastView = this.props.data.lastViewReducer.lastView;
      this.getPersonalisedVideo(lastView.currentPage, lastView.searchParams);
    };
    deletePersonalisedVideoError = (error) => requestError(error);

    getVideoTemplate = (page, search) =>
      Get(
        `/video_templates?${search}page=${page}`,
        this.getVideoTemplateSuccess,
        this.getVideoTemplateError,
        this.load,
      );
    getVideoTemplateSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        videoTemplate: payload,
        videoTemplatePage: tmpTotalPages,
      });
    };
    getVideoTemplateError = (error) => requestError(error);

    createPersonalisedVideo = (id) =>
      Post(
        `/video_requests?video_template_id=${id}`,
        {},
        this.createPersonalisedVideoSucces,
        this.createPersonalisedVideoError,
        this.load,
      );
    createPersonalisedVideoSucces = () => {
      let lastView = this.props.data.lastViewReducer.lastView;
      this.getPersonalisedVideo(lastView.currentPage, lastView.searchParams);
      requestSuccess(
        "Video request is successfully created. This process may take several minute to process. Please check the status of the video request periodically.",
      );
    };
    createPersonalisedVideoError = (error) => requestError(error);

    updateDownloadCount = (id) => {
      Put(
        `/video_requests/${id}/download_completed`,
        {},
        (payload) => {},
        (error) => requestError(error),
        this.load,
      );
    };

    getYoutubeLink = (id) =>
      Get(
        `/youtube_sessions/new?video_id=${id}`,
        this.getYoutubeLinkSucces,
        this.getYoutubeLinkError,
        this.load,
      );

    getYoutubeLinkSucces = (response) =>
      this.setState({ youtubeLink: response.redirect_url });
    getYoutubeLinkError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onVideoCreatorLoad={this.state.loading}
            searchParams={this.state.searchParams}
            adminSearchParams={this.state.adminSearchParams}
            videoTemplate={this.state.videoTemplate}
            videoTemplatePage={this.state.videoTemplatePage}
            personalisedVideo={this.state.personalisedVideo}
            personalisedVideoPage={this.state.personalisedVideoPage}
            selectedVideo={this.state.selectedVideo}
            selectedProjectListVideo={this.state.selectedProjectListVideo}
            youtubeLink={this.state.youtubeLink}
            showVideoPlayer={this.state.showVideoPlayer}
            showShareModal={this.state.showShareModal}
            showDeleteConfirmation={this.state.showDeleteConfirmation}
            showCreateModal={this.state.showCreateModal}
            showUpdateConfirmation={this.state.showUpdateConfirmation}
            createPersonalisedVideo={this.createPersonalisedVideo}
            getAdminPersonalisedVideo={this.getAdminPersonalisedVideo}
            getPersonalisedVideo={this.getPersonalisedVideo}
            deletePersonalisedVideo={this.deletePersonalisedVideo}
            deleteAdminPersonalisedVideo={this.deleteAdminPersonalisedVideo}
            getVideoTemplate={this.getVideoTemplate}
            onChangeVideoCreatorHOC={this.onChangeVideoCreatorHOC}
            updateDownloadCount={this.updateDownloadCount}
            getYoutubeLink={this.getYoutubeLink}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary,
  })(WithHOC);
};

export default HOC;
