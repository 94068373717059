import React, { Component } from "react";

import LoadingModal from "components/LoadingModal";
import AtlasIcon from "components/Icon/atlasIcon";
import EmptyState from "components/EmptyState";

class MediaLink extends Component {
  componentDidMount = () => {
    this.props.getMediaLinks(this.props.subsalesEntryCreationProps.id);
  };

  render = () => {
    const { mediaLink = [] } = this.props;
    return (
      <section className="at-form__content">
        <div className="at-subsales-listing__view-title my-2">
          <AtlasIcon
            svgHref={"atlas-video-add"}
            style={{ fill: "#F0631D", marginRight: 10 }}
          />
          Media Links
        </div>
        {mediaLink.length < 1 && (
          <>
            <hr />
            <EmptyState
              title={`No record is available`}
              renderIcon={<AtlasIcon svgHref="atlas-video-play" />}
            />
          </>
        )}
        {mediaLink.length > 0 &&
          mediaLink.map((item) => (
            <div key={item.id} className="at-listing__media-link">
              <div>
                <div className="d-flex">
                  <div
                    className="at-listing__media-badge"
                    style={{
                      backgroundColor:
                        item.type === "Youtube" ? "#D92D20" : "#FF3158",
                    }}
                  >
                    {item.type}
                  </div>
                  <label>{item.title}</label>
                </div>
                <a href={item.url} target="_blank">
                  {item.url}
                </a>
              </div>
            </div>
          ))}
        {this.props.onLoadMediaLink && <LoadingModal />}
      </section>
    );
  };
}

export default MediaLink;
