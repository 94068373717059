import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { HiArrowLeft } from "react-icons/hi";

import "./index.scss";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const AtlasCloseButton = ({
  tooltipContent,
  tooltipPlacment,

  onClick,
  buttonStyle,
  containerStyle,
  containerClass,
  disabled,
}) => {
  return (
    <DarkTooltip
      classes={{ tooltip: "tooltip-arrow left" }}
      placement={tooltipPlacment || "right"}
      enterTouchDelay={0}
      title={tooltipContent || "Back To Previous Page"}
    >
      <button
        style={{ width: "fit-content", ...containerStyle }}
        className={`btn-new btn-new--outline-secondary ${containerClass || ""}`}
        disabled={disabled}
        onClick={onClick}
      >
        <HiArrowLeft style={{ height: 20, width: 20, ...buttonStyle }} />
      </button>
    </DarkTooltip>
  );
};

export default AtlasCloseButton;
