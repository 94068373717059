import React, { Component } from "react";
import { BiRename } from "react-icons/bi";
import { IoMdSync } from "react-icons/io";

import CustomCard from "components/Card";
import VideoPlayer from "components/VideoPlayer";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import PaginatedTable from "components/NewPaginatedTable";
import GenerateFileName from "./generateFileName";
import UpdateTemplate from "./Update";

import VideoTemplateHOC from "./action";

import { columnData } from "./assets";
import permissionsChecker from "utils/permissionsChecker";

import "./index.scss";

class VideoTemplateManagement extends Component {
  componentDidMount = () => {
    this.props.getCountryDictionary();
    this.props.getVideoTemplate(1, "");
  };

  renderCardContent = () => {
    const { can_update } = permissionsChecker("Video Creator", this.props.data);

    return (
      <PaginatedTable
        tableTitle={""}
        rowData={this.props.videoTemplate.data}
        meta={this.props.videoTemplate.meta}
        columns={columnData({
          onChangeVideoTemplateHOC: this.props.onChangeVideoTemplateHOC,
        })}
        showCreateButton={false}
        searchParams={this.props.searchParams}
        onChangeSearchParams={(val) =>
          this.props.onChangeVideoTemplateHOC(val, "searchParams")
        }
        getListAPI={this.props.getVideoTemplate}
        totalPages={this.props.videoTemplatePage}
        actionColumnContent={[
          {
            name: can_update ? "edit" : "view",
            onClick: (rowData) =>
              this.props.getSelectedVideoTemplate(rowData.id),
          },
        ]}
      />
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader
          title={`Video Templates`}
          moduleIcon={"icon-video-creator"}
          actionButton={[
            {
              label: "Generate File Name",
              onShow: true,
              className: "btn-new btn-new--primary",
              icon: <BiRename style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeVideoTemplateHOC(true, "showGenerateName"),
            },
            {
              label: "Sync Videos",
              onShow: true,
              className: "btn-new btn-new--secondary",
              icon: <IoMdSync style={{ height: 18, width: 18 }} />,
              onClick: () => this.props.syncVideoTemplate(),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showUpdateModal && (
          <UpdateTemplate
            data={this.props.data}
            permissionsChecker={permissionsChecker(
              "Video Creator",
              this.props.data,
            )}
            selectedTemplate={this.props.selectedTemplate}
            onVideoTemplateLoad={this.props.onVideoTemplateLoad}
            updateVideoTemplate={this.props.updateVideoTemplate}
            onChangeVideoTemplateHOC={this.props.onChangeVideoTemplateHOC}
          />
        )}
        {this.props.showVideoPlayer && (
          <VideoPlayer
            videoURL={this.props.selectedTemplate.url}
            onClickClose={() =>
              this.props.onChangeVideoTemplateHOC(false, "showVideoPlayer")
            }
          />
        )}
        {this.props.showGenerateName && (
          <GenerateFileName
            projectList={this.props.projectList}
            onVideoTemplateLoad={this.props.onVideoTemplateLoad}
            getProjectListing={this.props.getProjectListing}
            onChangeVideoTemplateHOC={this.props.onChangeVideoTemplateHOC}
            onProjectListLoad={this.props.onProjectListLoad}
            onClickClose={() =>
              this.props.onChangeVideoTemplateHOC(false, "showGenerateName")
            }
          />
        )}
        {this.props.onVideoTemplateLoad && <LoadingModal />}
      </>
    );
  };
}

export default VideoTemplateHOC(VideoTemplateManagement);
