import React from "react";
import { toast } from "react-toastify";
import ReactHtmlParser from "react-html-parser";

export const requestSuccess = (message, toastId) => {
  toast.success(<div>{ReactHtmlParser(message)}</div>, {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: toastId,
  });
};

export const requestError = (message, toastId) => {
  const defaultMessage =
    "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again.";
  toast.error(<div>{ReactHtmlParser(message || defaultMessage)}</div>, {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: toastId,
  });
};
