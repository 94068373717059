import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import CustomTable from "components/NewPaginatedTable";
import ModalDialog from "components/Modal/ModalDialog";
import LoadingModal from "components/LoadingModal";
import CreateModal from "./Details";
import UpdateModal from "./Update";

import BankHOC from "actions/banks";
import BranchManagementHOC from "./actions";
import BranchRegionHOC from "actions/branchRegion";
import { getColorBadge } from "dictionary/badgeColor";

import "./index.scss";

const columnData = [
  {
    header: "Branch Name",
    accessor: "name",
    searchFlag: "name",
  },
  {
    header: "Company Reg. No.",
    accessor: "company_reg_no",
    searchFlag: "company_reg_no",
  },
  {
    header: "Region",
    accessor: "branch_region_name",
    searchFlag: "branch_region_name",
  },
  {
    header: "Status",
    searchFlag: "active",
    renderColumn: (rowData) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(rowData.active ? "Green" : "Grey") }}
      >
        {rowData.active ? "Active" : "Inactive"}
      </div>
    ),
  },
];

class BranchManagement extends Component {
  componentDidMount = () => {
    this.props.getBanks();
    this.props.getBranchRegions();
    this.props.getBranch(1, "");
  };

  renderCardContent = () => (
    <CustomTable
      columns={columnData}
      rowData={this.props.branches.data || []}
      meta={this.props.branches.meta || null}
      enableSort={true}
      searchParams={this.props.searchParams}
      onChangeSearchParams={(val) =>
        this.props.onChangeBranchHOC(val, "searchParams")
      }
      actionColumnContent={[
        {
          name: "edit",
          name: "edit",
          name: "edit",
          onClick: (rowData) => this.props.getSelectedBranch(rowData.id),
        },
      ]}
      totalPages={this.props.branchesPage}
      getListAPI={this.props.getBranch}
    />
  );

  render = () => {
    return (
      <>
        <ModuleHeader
          title={"Branches"}
          atlasIcon={"atlas-buildings-2"}
          actionButton={[
            {
              label: "New Branch",
              onShow: true,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 8 }} />
              ),
              onClick: () =>
                this.props.onChangeBranchHOC(true, "showCreateModal"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showCreateModal && (
          <ModalDialog
            title={"Create Branch"}
            onLoad={this.props.onLoadBranches}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeBranchHOC(false, "showCreateModal")
            }
            children={
              <CreateModal
                mode={"create"}
                banks={this.props.banks}
                branchRegions={this.props.branchRegions}
                countries={this.props.data.dictionaryReducer.countries}
                onClickSubmit={(val) => this.props.createBranch(val)}
                onChangeBranchHOC={this.props.onChangeBranchHOC}
              />
            }
          />
        )}
        {this.props.showUpdateModal && (
          <ModalDialog
            title={"Edit Branch"}
            onLoad={this.props.onLoadBranches}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeBranchHOC(false, "showUpdateModal")
            }
            children={
              <UpdateModal
                banks={this.props.banks}
                branchRegions={this.props.branchRegions}
                selectedBranch={this.props.selectedBranch}
                countries={this.props.data.dictionaryReducer.countries}
                onClickSubmit={(val) => this.props.updateBranch(val)}
                onChangeBranchHOC={this.props.onChangeBranchHOC}
              />
            }
          />
        )}
        {(this.props.onLoadBranches || this.props.onLoadBanks) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  BankHOC,
  BranchRegionHOC,
  BranchManagementHOC,
)(BranchManagement);
