import { useState } from "react";
import { MarvelDevices } from "react-css-devices";
import Frame from "react-frame-component";
import ReactHtmlParser from "react-html-parser";
import { BiDesktop } from "react-icons/bi";
import { FaPaperPlane } from "react-icons/fa";
import { GiSmartphone } from "react-icons/gi";

import AtlasButton from "components/Button";
import AtlasPrevButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";

import withSendTestEmail from "actions/sendTestEmail";

const PreviewAndTest = ({
  handleClose,
  selectedEmailCampaign,
  recipients,
  onChangeSendTestEmailHOC,
  sendTestEmail,
}) => {
  const [isDesktopView, setIsDesktopView] = useState(true);

  return (
    <>
      <div className={"at-card__header"}>
        <AtlasPrevButton onClick={handleClose} />
        <h5 className={"at-card__title"}>Preview & Test</h5>
      </div>

      <div
        className={"at-card"}
        style={{ backgroundColor: "#fff", overflow: "hidden" }}
      >
        <div className={"form-row"}>
          <div className={"col-lg-8 order-last order-lg-1"}>
            <div className={"at-email-campaigns__email-preview"}>
              <div className={"email-preview__header"}>
                <table>
                  <tbody>
                    <tr
                      className={
                        "d-flex flex-column mb-3 d-md-table-row mb-md-0"
                      }
                    >
                      <th style={{ width: 100 }}>From</th>
                      <td>
                        {selectedEmailCampaign.sender_name} &lt;
                        {selectedEmailCampaign.sender_email}&gt;
                      </td>
                    </tr>
                    <tr
                      className={
                        "d-flex flex-column mb-3 d-md-table-row mb-md-0"
                      }
                    >
                      <th>Subject</th>
                      <td>{selectedEmailCampaign.subject}</td>
                    </tr>
                    <tr
                      className={
                        "d-flex flex-column mb-3 d-md-table-row mb-md-0"
                      }
                    >
                      <th>Preview</th>
                      <td>{selectedEmailCampaign.preview_text}</td>
                    </tr>
                  </tbody>
                </table>

                <div className={"btn-group"} role={"group"}>
                  <AtlasButton
                    className={`desktop-btn ${isDesktopView ? "active" : ""}`}
                    onClick={() => setIsDesktopView(true)}
                  >
                    <BiDesktop />
                  </AtlasButton>

                  <AtlasButton
                    className={`mobile-btn ${isDesktopView ? "" : "active"}`}
                    onClick={() => setIsDesktopView(false)}
                  >
                    <GiSmartphone />
                  </AtlasButton>
                </div>
              </div>

              {isDesktopView && (
                <div className={"email-preview__body"}>
                  {selectedEmailCampaign.email_template && (
                    <Frame className={"iframe"}>
                      {ReactHtmlParser(
                        selectedEmailCampaign.email_template.html_code,
                      )}
                    </Frame>
                  )}
                </div>
              )}
              {!isDesktopView && (
                <div className={"email-preview__body"}>
                  <div className={"device-wrapper"}>
                    <MarvelDevices
                      deviceName={"ipad"}
                      orientation={"portrait"}
                      transform={0.5}
                    >
                      <div className={"device-email-body"}>
                        {selectedEmailCampaign.email_template && (
                          <Frame className={"iframe"}>
                            {ReactHtmlParser(
                              selectedEmailCampaign.email_template.html_code,
                            )}
                          </Frame>
                        )}
                      </div>
                    </MarvelDevices>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={"col-lg-4 order-first order-lg-2"}>
            <div className={"at-email-campaigns__send-test-form mb-3"}>
              <div className={"d-flex align-items-center mb-2"}>
                <FaPaperPlane
                  className={"mr-2"}
                  style={{ width: 16, height: 16 }}
                />
                <strong>Send test</strong>
              </div>

              <p className={"mb-3"}>
                Send the current preview as a test email to yourself or your
                colleagues.
              </p>

              <CustomFormInput
                type={"text"}
                value={recipients}
                className={"mb-4"}
                onChangeValue={(val) =>
                  onChangeSendTestEmailHOC("recipients", val)
                }
                required={true}
              />

              <AtlasButton
                type={"button"}
                className={"btn-new btn-new--secondary"}
                onClick={() =>
                  sendTestEmail({
                    recipients,
                    template_id: selectedEmailCampaign.email_template?.id,
                  })
                }
              >
                Send test
              </AtlasButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withSendTestEmail(PreviewAndTest);
