import React, { Component } from "react";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import EmptyState from "components/EmptyState";
import LoadingModal from "components/LoadingModal";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import MediaForm from "./form";

import permissionsChecker from "utils/permissionsChecker";

class MediaLink extends Component {
  componentDidMount = () => {
    this.props.getMediaLinks(this.props.subsalesEntryCreationProps.id);
  };

  render = () => {
    const { mediaLink = [] } = this.props;
    const { can_create, can_destroy } = permissionsChecker(
      "Listing Centre",
      this.props.data,
    );

    return (
      <div className="at-form__content">
        <div className="d-flex align-items-center flex-wrap mb-10">
          <div className="at-subsales-listing__view-title my-2">
            <AtlasIcon
              svgHref={"atlas-video-add"}
              style={{ fill: "#F0631D", marginRight: 10 }}
            />
            Media Links
          </div>
          {can_create && (
            <CustomButton
              className={"btn-new btn-new--primary ml-2"}
              onClick={() =>
                this.props.onChangeMediaLinkHOC(
                  true,
                  "showCreateMediaLinkModal",
                )
              }
              children={
                <>
                  <AtlasIcon
                    svgHref={"atlas-add-linear"}
                    style={{
                      width: 20,
                      height: 20,
                      fill: "white",
                    }}
                  />
                  New Media Link
                </>
              }
            />
          )}
        </div>
        {mediaLink.length < 1 && (
          <>
            <hr />
            <EmptyState
              title={`No record is available`}
              renderIcon={<AtlasIcon svgHref="atlas-video-play" />}
            />
          </>
        )}
        {mediaLink.length > 0 &&
          mediaLink.map((item) => (
            <div key={item.id} className="at-listing__media-link">
              <div>
                <div className="d-flex">
                  <div
                    className="at-listing__media-badge"
                    style={{
                      backgroundColor:
                        item.type === "Youtube" ? "#D92D20" : "#FF3158",
                    }}
                  >
                    {item.type}
                  </div>
                  <label>{item.title}</label>
                </div>
                <a href={item.url} target="_blank">
                  {item.url}
                </a>
              </div>
              <CustomButton
                containerStyle={{ marginLeft: "auto" }}
                className={"btn-new btn-new--outline-secondary"}
                children={
                  <AtlasIcon
                    svgHref={"atlas-trash"}
                    style={{ width: 18, height: 18, fill: "#F04438" }}
                  />
                }
                onClick={() =>
                  Promise.all([
                    this.props.onChangeMediaLinkHOC(
                      item.id,
                      "selectedDeleteLink",
                    ),
                  ]).then(() =>
                    this.props.onChangeMediaLinkHOC(
                      true,
                      "showDeleteConfirmation",
                    ),
                  )
                }
              />
            </div>
          ))}
        {this.props.showCreateMediaLinkModal && (
          <MediaForm
            onLoadMediaLink={this.props.onLoadMediaLink}
            onClose={() =>
              this.props.onChangeMediaLinkHOC(false, "showCreateMediaLinkModal")
            }
            onSubmit={(payload) =>
              this.props.createMediaLink(
                this.props.subsalesEntryCreationProps.id,
                payload,
              )
            }
          />
        )}
        <AtlasConfirmationDialog
          mode={"alert"}
          open={this.props.showDeleteConfirmation}
          loading={this.props.onLoadMediaLink}
          message={"Are you sure to delete the selected media link?"}
          positiveAction={() =>
            this.props.deleteMediaLink(
              this.props.subsalesEntryCreationProps.id,
              this.props.selectedDeleteLink,
            )
          }
          negativeAction={() =>
            this.props.onChangeMediaLinkHOC(false, "showDeleteConfirmation")
          }
        />
        {this.props.onLoadMediaLink && <LoadingModal />}
      </div>
    );
  };
}

export default MediaLink;
