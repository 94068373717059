import CustomCheckbox from "components/Checkbox";

export const columnData = ({
  checkedArray,
  emailListsData,
  onHandleCheckBox,
  onHandleCheckAllBox,
}) => [
  {
    header: () => (
      <span className="at-table-head-checkbox_responsive">
        <CustomCheckbox
          checked={
            checkedArray.length > 0 &&
            checkedArray.length === emailListsData.length
          }
          onChangeCheckboxValue={onHandleCheckAllBox}
        />
      </span>
    ),
    columnStyle: { width: "10%" },
    renderColumn: (rowData) => (
      <CustomCheckbox
        checked={checkedArray.includes(rowData.id)}
        onChangeCheckboxValue={() => onHandleCheckBox(rowData.id)}
      />
    ),
  },
  {
    header: "List",
    accessor: "name",
  },
  {
    header: "Recipients",
    columnStyle: { width: "25%" },
    accessor: "sum_users",
  },
];
