import React from "react";
import _ from "lodash";

import Moment from "moment";
import { ImPlay } from "react-icons/im";
import DefaultThumbnail from "assets/images/default_thumbnail.jpg";
import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: 1, colorName: "Green" },
  { value: 2, colorName: "Blue" },
];

export const columnData = (props) => [
  {
    header: "Thumbnail",
    columnClass: "at-video-creator__thumbnail-column",
    renderColumn: (data) => (
      <>
        <img
          style={{ width: "100%", borderRadius: 8 }}
          src={data.thumbnail_url ? data.thumbnail_url : DefaultThumbnail}
          alt="thumbnail_url"
        />
        {data.url && (
          <a
            className="at-video-creator__play"
            onClick={() => {
              Promise.all([
                props.onChangeVideoCreatorHOC(data, "selectedVideo"),
              ]).then(() => {
                props.onChangeVideoCreatorHOC(true, "showVideoPlayer");
              });
            }}
          >
            <ImPlay className="at-video-creator__thumbnail" />
          </a>
        )}
        {data.is_update_available && (
          <a
            className="at-video-creator__outdate_tag"
            onClick={() => {
              Promise.all([
                props.onChangeVideoCreatorHOC(data, "selectedVideo"),
              ]).then(() => {
                props.onChangeVideoCreatorHOC(true, "showUpdateConfirmation");
              });
            }}
          >
            Updated version available
          </a>
        )}
      </>
    ),
  },
  { header: "Title", accessor: "title" },
  { header: "Description", accessor: "description" },
  { header: "Project", accessor: "project_name" },
  {
    header: "Created At",
    renderColumn: ({ created_at }) => (
      <span>{Moment(created_at).format("DD MMM YYYY HH:mm")}</span>
    ),
    columnStyle: { width: "50%" },
  },
  {
    header: () => (
      <span className=" at-table-head__title text-center">Status</span>
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: ({ status_id }) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: status_id }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {status_id === 1 ? "Processing" : "Ready"}
        </div>
      );
    },
  },
];

export const adminColumnData = (props) => [
  {
    header: "Thumbnail",
    renderColumn: (data) => (
      <>
        <img
          style={{ width: "100%" }}
          src={data.thumbnail_url ? data.thumbnail_url : DefaultThumbnail}
          alt="thumbnail_url"
        />
        {data.url && (
          <a
            className="at-video-creator__play"
            onClick={() => {
              Promise.all([
                props.onChangeVideoCreatorHOC(data, "selectedVideo"),
              ]).then(() => {
                props.onChangeVideoCreatorHOC(true, "showVideoPlayer");
              });
            }}
          >
            <ImPlay className="at-video-creator__thumbnail" />
          </a>
        )}
      </>
    ),
  },
  {
    header: "Title / Project",
    renderColumn: ({ title, project_name }) => (
      <>
        <p>{title || "N/A"}</p>
        <p>{project_name || "N/A"}</p>
      </>
    ),
    columnStyle: { width: "150%" },
  },
  {
    header: "Request At",
    renderColumn: ({ created_at }) =>
      Moment(created_at).format("DD MMM YYYY HH:mm"),
    columnStyle: { width: "50%" },
  },
  {
    header: "Agent",
    accessor: "agent_name",
    columnStyle: { width: "50%" },
  },
  {
    header: () => (
      <span className="at-table-head__title text-center">Status</span>
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: ({ status_id }) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: status_id }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {status_id === 1 ? "Processing" : "Ready"}
        </div>
      );
    },
  },
];
