import React, { Component, Fragment } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Moment from "moment";

import websocketURL from "utils/websocketURL";
import { Get, Post, Put } from "utils/axios";
import { getCountryDictionary } from "actions/dictionary";
import { requestError, requestSuccess } from "utils/requestHandler";
import { getItem } from "utils/tokenStore";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      fetchTime: "",
      storeyPlan: {
        project_name: "",
        location: "",
        available: 0,
        sold: 0,
        reserved: 0,
        pending_approval: 0,
        data: [],
      },
      selectedUnit: {},
      selectedProject: {},
      selectedReservation: {},
      needReconnect: false,
      showReserveModal: false,
      progress: "",
      projectId: "",
    };

    componentDidUpdate = () => {
      if (this.state.needReconnect) {
        this.establishConnection();
        this.setState({ needReconnect: false });
      }
    };

    load = (param) => this.setState({ loading: param });
    onChangeStoreyPlanHOC = (context, value) =>
      this.setState({ [context]: value });
    requestInfo = (message) => {
      toast.info(message, {
        position: "top-right",
      });
    };
    requestWarning = (message) => {
      toast.warning(message, {
        position: "bottom-right",
        pauseOnFocusLoss: false,
      });
    };

    getLiveSales = (id) => {
      this.setState({ projectId: id });
      Get(
        `/projects/${id}/live_sales`,
        this.getLiveSalesSuccess,
        this.getLiveSalesError,
        this.load,
      );
    };
    getLiveSalesSuccess = (payload) =>
      this.setState(
        {
          storeyPlan: {
            ...payload,
            sold: payload.sold + payload.booked,
          },
          fetchTime: Moment(new Date()).format("DD MMM YYYY hh:mm:ss"),
        },
        () => {
          this.establishConnection();
        },
      );
    getLiveSalesError = (error) => requestError(error);

    establishConnection = (payload) => {
      const tokenName = window.location.href.includes("/admin-impersonate")
        ? "IQI_ATLAS_JWT_AGENT_TOKEN"
        : "IQI_ATLAS_JWT_TOKEN";

      let authToken = getItem(tokenName);
      let webSocket = new WebSocket(
        `${websocketURL()}/cable?auth_token=${authToken}`,
      );

      webSocket.onopen = function () {
        webSocket.send(
          JSON.stringify({
            command: "subscribe",
            identifier: '{"channel":"LiveSalesChannel"}',
          }),
        );
        console.log("web socket open");
      };

      webSocket.onclose = function () {
        console.log("web socket close");
        reconnect();
      };

      webSocket.onmessage = function (event) {
        let message = JSON.parse(event.data).message;
        if (typeof message === "object") {
          console.log(message);
          processData(message);
        }
      };

      const reconnect = () => this.setState({ needReconnect: true });

      const processData = (message) => {
        this.load(true);
        let unit = message.unit;
        let projectDetail = message.project_info;
        let data = this.state.storeyPlan.data;
        Object.keys(data).map((block) => {
          let blockObject = data[block];
          Object.keys(blockObject).map((floor) => {
            let floorObject = blockObject[floor];
            let unitIndex = _.findIndex(floorObject, { id: unit.id });
            if (unitIndex > -1 && floorObject[unitIndex]) {
              let temp = {
                ...floorObject[unitIndex],
                price: unit.price,
                status:
                  unit.reservations_attributes.status === "Cancelled"
                    ? "Available"
                    : unit.reservations_attributes.status,
                unit_no: unit.unit_no,
                unit_type: unit.unit_type,
                floor_size: unit.floor_size,
                has_submitted_payment:
                  unit.reservations_attributes.has_submitted_payment,
                reservation_time: unit.reservations_attributes.reservation_time,
                agent_id: unit.reservations_attributes.agent_id,
                onChange: true,
              };
              floorObject[unitIndex] = temp;
              setTimeout(() => (floorObject[unitIndex].onChange = false), 2000);
              this.requestWarning(
                `${unit.title} has been ${unit.reservations_attributes.status.toLowerCase()}.`,
              );
            }
          });
        });
        this.setState(
          {
            storeyPlan: {
              ...this.state.storeyPlan,
              available: projectDetail.available,
              sold: projectDetail.sold + projectDetail.booked,
              reserved: projectDetail.reserved,
              pending_approval: projectDetail.pending_approval,
              data: data,
            },
            fetchTime: Moment(new Date()).format("DD MMM YYYY hh:mm:ss"),
          },
          () => this.load(false),
        );
      };
    };

    getSelectedUnit = (id, nextStage) =>
      Get(
        `/projects/${this.state.projectId}/project_units/${id}`,
        (payload) => this.getSelectedUnitSuccess(payload, nextStage),
        this.getSelectedUnitError,
        this.load,
      );
    getSelectedUnitSuccess = (payload, nextStage) => {
      this.setState({
        selectedUnit: payload,
        showReserveModal: true,
        progress:
          this.state.selectedProject.reservation_time_limit &&
          this.state.selectedProject.reservation_time_limit.settings
            .has_time_limit
            ? nextStage
            : "BuyerInfo",
      });
    };
    getSelectedUnitError = (error) => {
      this.setState({ showReserveModal: false });
      requestError(error);
    };

    putReservation = (selectedUnit) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Post(
        `/projects/${this.state.projectId}/project_units/${selectedUnit.id}/reservations`,
        {
          agent_id: id,
        },
        (payload) => this.putReservationSuccess(payload, selectedUnit),
        this.putReservationError,
        this.load,
      );
    };
    putReservationSuccess = (payload, selectedUnit) => {
      if (payload.status === "Pending Approval") {
        this.setState({
          showReserveModal: false,
        });
        this.requestInfo(
          `Request successful. Wait for admin approval to reserve this unit.`,
        );
      } else {
        this.getSelectedUnit(selectedUnit.id, "PaymentSlip");
        this.requestInfo(`You are given 
          ${this.state.selectedProject.reservation_time_limit.settings.payment_slip.time} 
          ${this.state.selectedProject.reservation_time_limit.settings.payment_slip.unit} 
          to upload payment slip to secure the unit (${selectedUnit.title}).`);
      }
    };
    putReservationError = (error) => {
      this.setState({ showReserveModal: false });
      requestError(error);
    };

    uploadPaymentSlip = (dataToSubmit, selectedUnit) =>
      Put(
        `/projects/${this.state.projectId}/project_units/${selectedUnit.id}/reservations/${selectedUnit.reservations_attributes.id}`,
        dataToSubmit,
        () => this.uploadPaymentSlipSuccess(selectedUnit),
        this.uploadPaymentSlipError,
        this.load,
      );
    uploadPaymentSlipSuccess = (selectedUnit) => {
      this.getSelectedUnit(selectedUnit.id, "BuyerInfo");
      this.requestInfo(`You are given 
        ${this.state.selectedProject.reservation_time_limit.settings.buyer_info.time} 
        ${this.state.selectedProject.reservation_time_limit.settings.buyer_info.unit} 
        to get all the information filled to complete reservation for unit (${selectedUnit.title}).`);
    };
    uploadPaymentSlipError = (error) => {
      this.setState({ showUploadModal: false });
      requestError(error);
    };

    createReservation = (dataToSubmit, selectedUnit) => {
      let payload = {
        ...dataToSubmit,
        ic_front: dataToSubmit.ic_front.snapshot,
        ic_front_file_name: dataToSubmit.ic_front.snapshot_file_name,
        ic_back: dataToSubmit.ic_back.snapshot,
        ic_back_file_name: dataToSubmit.ic_back.snapshot_file_name,
        ...(dataToSubmit.payment_slip &&
          typeof dataToSubmit.payment_slip === "object" && {
            payment_slip: dataToSubmit.payment_slip
              ? dataToSubmit.payment_slip.snapshot
              : "",
            payment_slip_file_name: dataToSubmit.payment_slip
              ? dataToSubmit.payment_slip.snapshot_file_name
              : "",
          }),
      };
      if (
        this.state.selectedProject.reservation_time_limit &&
        this.state.selectedProject.reservation_time_limit.settings
          .has_time_limit
      ) {
        Put(
          `/projects/${this.state.projectId}/project_units/${selectedUnit.id}/reservations/${selectedUnit.reservations_attributes.id}`,
          payload,
          this.createReservationSuccess,
          this.createReservationError,
          this.load,
        );
      } else {
        Post(
          `/projects/${this.state.projectId}/project_units/${selectedUnit.id}/reservations`,
          payload,
          this.createReservationSuccess,
          this.createReservationError,
          this.load,
        );
      }
    };
    createReservationSuccess = () => {
      this.setState({ progress: "Complete" });
      requestSuccess("Buyer information updated.");
    };
    createReservationError = (error) => requestError(error);

    getSelectedProject = (id) =>
      Get(
        `/projects/${id}`,
        this.getSelectedProjectSuccess,
        this.getSelectedProjectError,
        this.load,
      );
    getSelectedProjectSuccess = (payload) =>
      this.setState({ selectedProject: payload });
    getSelectedProjectError = (error) => requestError(error);

    getReservation = (selectedUnit) =>
      Get(
        `/projects/${this.state.projectId}/project_units/${selectedUnit.id}/reservations/${selectedUnit.reservations_attributes.id}`,
        this.getReservationSuccess,
        this.getReservationError,
        this.load,
      );
    getReservationSuccess = (payload) =>
      this.setState({ selectedReservation: payload });
    getReservationError = (error) => requestError(error);

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            onLoadPlan={this.state.loading}
            storeyPlan={this.state.storeyPlan}
            fetchTime={this.state.fetchTime}
            progress={this.state.progress}
            selectedUnit={this.state.selectedUnit}
            selectedProject={this.state.selectedProject}
            selectedReservation={this.state.selectedReservation}
            showReserveModal={this.state.showReserveModal}
            showUploadModal={this.state.showUploadModal}
            getLiveSales={this.getLiveSales}
            establishConnection={this.establishConnection}
            getSelectedUnit={this.getSelectedUnit}
            getSelectedProject={this.getSelectedProject}
            createReservation={this.createReservation}
            getReservation={this.getReservation}
            uploadPaymentSlip={this.uploadPaymentSlip}
            putReservation={this.putReservation}
            onChangeStoreyPlanHOC={this.onChangeStoreyPlanHOC}
          />
        </Fragment>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { getCountryDictionary })(WithHOC);
};

export default HOC;
