import FormInputs from "../formInputs";

import { getTranslation } from "../../assets";

const Upload = ({ tmpProfileReducer, updateNewAgent, onChangeField }) => {
  const language = tmpProfileReducer.country_id === 14 ? "jp" : "en";

  const onChangeFile = (val, id) => {
    switch (id) {
      case "previous_company":
      case "has_been_agent":
        onChangeField(
          {
            ...updateNewAgent.agent_registration_attributes,
            [id]: val,
          },
          "agent_registration_attributes"
        );
        break;
      default:
        onChangeField(
          {
            ...updateNewAgent.agent_registration_attributes,
            [id]: val?.[0]?.source,
            [`${id}_file_name`]: val?.[0]?.name,
          },
          "agent_registration_attributes"
        );
    }
  };

  return (
    <>
      {tmpProfileReducer.country_id !== 14 && (
        <>
          <p>
            If your bank account belongs to a company, please provide a copy of the
            bank account statement (only header) and SSM.
          </p>
          <p className={"mb-4"}>
            To proceed to the next step, kindly upload all required documents.
          </p>
        </>
      )}

      <div className={"container-fluid p-0"}>
        <FormInputs
          payload={updateNewAgent.agent_registration_attributes}
          inputConfig={[
            {
              type: "file",
              label: getTranslation("agent.profile.ic_front", language),
              id: "ic_front",
              className: tmpProfileReducer.country_id === 14 ? "at-content-japanese" : "",
              hideLabelDragDrop: true,
              labelText: getTranslation("agent.profile.ic_front_datatype", language),
              required: true,
              span: 6,
            },
            {
              type: "file",
              label: getTranslation("agent.profile.ic_back", language),
              id: "ic_back",
              className: tmpProfileReducer.country_id === 14 ? "at-content-japanese" : "",
              hideLabelDragDrop: true,
              labelText: getTranslation("agent.profile.ic_front_datatype", language),
              span: 6,
            },
            {
              type: "file",
              label: "Bank Account Statement (Header)",
              accepts: "application/pdf, image/*",
              id: "bank_account_statement",
              span: 6,
              hidden: tmpProfileReducer.country_id === 14,
            },
            {
              type: "file",
              label: "SSM Copy",
              accepts: "application/pdf, image/*",
              id: "ssm_copy",
              span: 6,
              hidden: tmpProfileReducer.country_id === 14,
            },
            {
              type: "radio",
              label: getTranslation("agent.profile.has_been_agent", language),
              id: "has_been_agent",
              options: [
                { value: true, label: getTranslation("yes", language) },
                { value: false, label: getTranslation("no", language) },
              ],
            },
            {
              label: getTranslation("agent.profile.previous_agency", language),
              id: "previous_company",
              hidden: tmpProfileReducer.country_id !== 14 || !updateNewAgent.agent_registration_attributes?.has_been_agent,
            },
            {
              type: "file",
              label: "Resignation letter",
              accepts: "application/*, image/*",
              id: "resignation_letter",
              hidden: tmpProfileReducer.country_id !== 1 || !updateNewAgent.agent_registration_attributes?.has_been_agent,
            },
          ]}
          onChange={onChangeFile}
        />
      </div>
    </>
  );
};

export default Upload;
