import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import "stylesheets/components/select/index.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronIcon from "@material-ui/icons/ChevronLeft";

const CustomSelect = ({
  selectItems = [],
  currentlySelected,
  defaultValue = '',
  footerClass = "",
  customStyle,
  className = "",
  selectClassName,
  removeError,
  required,
  disabled,
  inputError,
  disabledPlaceholder,
  placeholder,
  labelKey,
  valueKey,
  renderOptions,
  updateSelect,
  emptyOption,
}) => {

  useEffect(() => {
    onChangeValue(currentlySelected);
  }, [currentlySelected]);

  const onChangeValue = (value) => {
    if (value !== null && typeof value === "object") {
      return selectValue(currentlySelected[valueKey || "value"] ?? "");
    }
    return selectValue(value);
  };

  const [selected, selectValue] = useState("");
  const [collapsed, setCollapse] = useState(true);

  const toggleDropdown = () => {
    setCollapse(true);
  };

  const selectItem = (val) => {
    onChangeValue(val);

    let temp = _.find(selectItems, (item) => item[valueKey || "value"] === val);
    updateSelect(temp);
  };

  const renderErrorMessage = (error) => {
    if (error) {
      return (
        <div className="at-form-input__label at-form-input__label--error">
          {error}
        </div>
      );
    }
  };

  const renderRequired = (param) => {
    if (param) {
      return <div className="at-form-input__required">required *</div>;
    }
  };

  const checkEmptyObj = useMemo(() => (selected !== null && typeof selected === "object") && (Object.keys(selected)?.length === 0 || [null, undefined, ""].indexOf(selected?.[valueKey || "value"]) > -1), [selected]);

  return (
    <div
      style={{ ...customStyle }}
      className={`at-select${disabled ? " at-select--disabled" : ""} ${className}`}
      onChange={removeError}
    >
      <Select
        value={selected || defaultValue}
        onChange={(e) => selectItem(e.target.value)}
        IconComponent={ChevronIcon}
        classes={{
          root: `at-select__selected-item ${selectClassName || ""}`,
          select: `at-select__selected-item-select ${collapsed ? "" : "expand"} ${((!selected && !defaultValue) || checkEmptyObj) ? "at-select__selected-placeholder" : ""}`,
          icon: `at-select__chevron ${collapsed ? "" : "expand"}`,
        }}
        MenuProps={{
          classes: { paper: `at-select__dropdown-paper` },
          onExit: () => toggleDropdown(),
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          MenuListProps: {
            classes: { root: `at-select__dropdown-paper-list` },
          },
          disableAutoFocus: true,
        }}
        fullWidth
        disableUnderline
        displayEmpty
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem
            classes={{ root: `at-select__dropdown-item at-select__dropdown-placeholder` }}
            value={defaultValue || ""}
            disabled={disabledPlaceholder}
          >
            {placeholder}
          </MenuItem>
        )}
        {emptyOption && (
          <MenuItem
            classes={{ root: `at-select__dropdown-item` }}
            value={defaultValue}
          >
            N/A
          </MenuItem>
        )}
        {selectItems.map((item, index) => (
          <MenuItem
            classes={{ root: `at-select__dropdown-item` }}
            key={item.value || index}
            value={valueKey ? item[valueKey] : item.value}
          >
            {renderOptions
              ? renderOptions(item)
              : labelKey
                ? item[labelKey]
                : item.label}
          </MenuItem>
        ))}
      </Select>
      <div
        className={`at-input_footer ${footerClass}`}
        style={ (required || inputError) ? { marginBottom: '1rem' } : {} }
      >
        {renderRequired(required)}
        {renderErrorMessage(inputError)}
      </div>
    </div>
  );
};

export default CustomSelect;