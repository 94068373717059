import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

import StatusModal from "../components/statusModal";
import ConfirmationModal from "components/Modal/confirmation";

import { Post } from "utils/axios";
import { requestSuccess } from "utils/requestHandler";
import { getTranslation } from "../assets";

const OtpTacHOC = (WrappedComponent) => {
  class OtpTacWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
      showStatusModal: false,
      statusModalType: "",
      onClickStatusModalButton: () => {},
      statusModalMessage: "",
      mobileVerification: false,
      emailVerification: false,
      otpVerificationMessage: "",
      tacVerificationMessage: "",
      verifyCodeButton: "",
      showVerifyCodeEmail: false,
      showVerifyCodeEmailTitle: "",
      showVerifyCodeEmailMode: "",
      showVerifyCodeEmailMessage: "",
    };

    load = (param) => this.setState({ loading: param });

    requestError = (error) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "warning-custom",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: error,
      });
    requestSuccess = (success) =>
      this.setState({
        showStatusModal: true,
        statusModalType: "positive",
        onClickStatusModalButton: () =>
          this.setState({ showStatusModal: false }),
        statusModalMessage: success,
      });

    onChangeOtpTacHOC = (val, context) => this.setState({ [context]: val });

    getOTPCode = (countryMobile, mobile_contact_number) =>
      Post(
        `/registrations/verification_codes/mobile`,
        { mobile_contact_number: countryMobile + mobile_contact_number },
        this.getOTPCodeSuccess,
        this.getOTPCodeError,
        this.load
      );
    getOTPCodeSuccess = () => {
      this.props.resetOtpCountDown();
      this.props.resendOtpCountDownStarts();
    };
    getOTPCodeError = (error) => this.requestError(error);

    getTACCode = (email) =>
      Post(
        `/registrations/verification_codes/email`,
        { email: email },
        this.getTACCodeSuccess,
        this.getTACCodeError,
        this.load
      );
    getTACCodeSuccess = () => {
      this.props.resetTacCountDown();
      this.props.resendTacCountDownStarts();
    };
    getTACCodeError = (error) => {
      this.setState({
        showVerifyCodeEmail: true,
        showVerifyCodeEmailTitle: "Invalid Email",
        showVerifyCodeEmailMode: "alert",
        showVerifyCodeEmailMessage: error,
      });
    };

    onClickSubmitTAC = (email, email_tac, country_id) =>
      Post(
        `/registrations/verification_codes/verify`,
        { 
          recipient: email,
          type_id: 1,
          code: email_tac,
          country_id: country_id,
        },
        this.onClickSubmitTACSuccess,
        error => this.onClickSubmitTACError(error, country_id),
        this.load
      );
    onClickSubmitTACSuccess = (payload) => {
      requestSuccess(payload.message);
      this.setState({
        emailVerification: true,
        tacVerificationMessage: payload.message,
      });
    };
    onClickSubmitTACError = (error, country_id) => {
      this.setState({
        showVerifyCodeEmail: true,
        showVerifyCodeEmailTitle: getTranslation("agent.signup.invalid_code", country_id === 14 ? 'jp' : 'en'),
        showVerifyCodeEmailMode: "alert",
        showVerifyCodeEmailMessage: ReactHtmlParser(error),
        tacVerificationMessage: ReactHtmlParser(error),
        verifyCodeButton: getTranslation("agent.signup.button_ok", country_id === 14 ? 'jp' : 'en'),
      });
    };

    onClickSubmitOTP = (countryMobile, mobile_contact, phone_otp, country_id) =>
      Post(
        `/registrations/verification_codes/verify`,
        {
          recipient: countryMobile + mobile_contact,
          type_id: 2,
          code: phone_otp,
          country_id: country_id,
        },
        this.onClickSubmitOTPSuccess,
        error => this.onClickSubmitOTPError(error, country_id),
        this.load
      );
    onClickSubmitOTPSuccess = (payload) => {
      requestSuccess(payload.message);
      this.setState({
        mobileVerification: true,
        otpVerificationMessage: payload.message,
      });
    };
    onClickSubmitOTPError = (error, country_id) => {
      this.setState({
        showVerifyCodeEmail: true,
        showVerifyCodeEmailTitle: getTranslation("agent.signup.invalid_code", country_id === 14 ? 'jp' : 'en'),
        showVerifyCodeEmailMode: "alert",
        showVerifyCodeEmailMessage: ReactHtmlParser(error),
        otpVerificationMessage: ReactHtmlParser(error),
        verifyCodeButton: getTranslation("agent.signup.button_ok", country_id === 14 ? 'jp' : 'en'),
      });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadOtpTac={this.state.loading}
            mobileVerification={this.state.mobileVerification}
            emailVerification={this.state.emailVerification}
            otpVerificationMessage={this.state.otpVerificationMessage}
            tacVerificationMessage={this.state.tacVerificationMessage}
            onChangeOtpTacHOC={this.onChangeOtpTacHOC}
            onClickSubmitTAC={this.onClickSubmitTAC}
            onClickSubmitOTP={this.onClickSubmitOTP}
            getOTPCode={this.getOTPCode}
            getTACCode={this.getTACCode}
          />
          <StatusModal
            isOpen={this.state.showStatusModal}
            type={this.state.statusModalType}
            positiveText={"Close"}
            message={
              this.state.statusModalMessage
                ? this.state.statusModalMessage
                : "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again."
            }
            onClickClose={this.state.onClickStatusModalButton}
            onClick={this.state.onClickStatusModalButton}
          />
          <ConfirmationModal
            open={this.state.showVerifyCodeEmail}
            title={this.state.showVerifyCodeEmailTitle}
            cardMessageStyle={{ whiteSpace: "pre-wrap" }}
            message={this.state.showVerifyCodeEmailMessage}
            mode={this.state.showVerifyCodeEmailMode}
            positiveText={this.state.verifyCodeButton}
            positiveAction={() => {
              this.setState({
                showVerifyCodeEmail: false,
              });
            }}
            hideNegative
          />
        </>
      );
    };
  }
  return OtpTacWrappedComponent;
};

export default OtpTacHOC;
