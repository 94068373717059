import React, { Component, Fragment } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Axios from "axios";

import AtlasSnackbar from "components/Snackbar";
import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Put, Delete } from "utils/axios";
import { storePhotoWaterMark } from "actions/subsales";
import getDomainURL from "utils/getDomainURL";
import objToFormData from "utils/objToFormData";

const PhotoHOC = (WrappedComponent) => {
  class PhotoWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,

      showPhotoSnackbar: false,
      showPhotoTabModal: false,
      photoSnackbarContent: "",
      publishImages: [],
      privateImages: [],
      deleteImageID: "",
      uploaded_image: [],
      watermark: false,
      watermark_content: "",
      watermark_color: "",
      tempImgSrc: "",
      status_id: "",

      selectedImages: [],
      selectedImageSection: "Published",
      showSubsalesUpload: false,
      showSubsalesDeleteImage: false,
      uploadingSubsalesPhoto: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangePhotoHOC = (val, context) => this.setState({ [context]: val });

    onClickSetPrimary = (id) =>
      this.setPhotoPrimary(this.props.subsalesEntryCreationProps.id, id);

    onClickSetPhotoStatus = (id, status_id) =>
      this.setPhotoStatus(
        this.props.subsalesEntryCreationProps.id,
        id,
        status_id,
      );

    // get uploaded photos for a listing
    getSubsalesPhotos = (id) =>
      Get(
        `/sub_sales/${id}/photos?by_status=true`,
        this.getSubsalesPhotosSuccess,
        this.getSubsalesPhotosError,
        this.load,
      );
    getSubsalesPhotosSuccess = (payload) => {
      let publishImages = this.sortImageByPrimary(payload["published"]);
      let privateImages = this.sortImageByPrimary(payload["private"]);
      this.setState({ publishImages: publishImages });
      this.setState({ privateImages: privateImages });
    };
    sortImageByPrimary = (data) => {
      let images = data.map((item) => {
        return {
          ...item,
          draggableId: item.id.toString(),
        };
      });
      images.sort(function (a, b) {
        return b.is_primary - a.is_primary;
      });
      return images;
    };

    getSubsalesPhotosError = (error) => requestError(error);

    // remove attached images
    onClickRemoveUploadImage = (filename) => {
      var array = _.cloneDeep(this.state.uploaded_image);
      let fileIndex = _.findIndex(array, { name: filename });
      array = array
        .slice(0, fileIndex)
        .concat(array.slice(fileIndex + 1, array.length));
      this.setState({ uploaded_image: array });
    };

    // filter uploaded and upload failed photos
    filterUploadedPhoto = () => {
      let tempFailed = [];
      const { uploaded_image, uploadingSubsalesPhoto } = this.state;

      uploadingSubsalesPhoto.map((item, index) => {
        item.status === "failed" && tempFailed.push(uploaded_image[index]);
      });

      this.setState({ uploaded_image: tempFailed });
    };

    // handle images into array before the upload
    onClickUploadImage = () => {
      if (this.state.uploaded_image.length > 0) {
        let temp = [];
        let tempUploading = [];

        this.state.uploaded_image.map(({ file: imgFile, name: imgName }) => {
          let dataToSubmit = {
            image: imgFile,
            watermark: this.state.watermark,
            watermark_content: this.state.watermark_content,
            image_file_name: imgName,
            watermark_color: this.state.watermark_color,
            status_id: this.state.status_id,
          };
          tempUploading.push({ ...dataToSubmit, status: "loading" });
          temp.push(dataToSubmit);
        });

        this.setState(
          {
            uploadingSubsalesPhoto: tempUploading,
            showSubsalesUpload: true,
            status_id: "",
          },
          () => {
            this.props.storePhotoWaterMark({
              watermark: this.state.watermark,
              watermarkContent: this.state.watermark_content,
              watermarkColor: this.state.watermark_color,
            });
            this.uploadSubsalesPhoto(
              this.props.subsalesEntryCreationProps.id,
              temp,
            );
          },
        );
      } else {
        requestError("Please drop your 1 or more images to upload.");
      }
    };

    uploadSubsalesPhoto = async (id, uploadFiles) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      for (let i = 0; i < uploadFiles.length; i++) {
        let tempRes = null;
        try {
          tempRes = await Axios.post(
            `${getDomainURL()}/sub_sales/${id}/photos`,
            objToFormData(uploadFiles[i]),
          );
        } catch (error) {
          tempRes = { status: 422, message: error.response.data.message };
        }

        let tempLoading = _.cloneDeep(this.state.uploadingSubsalesPhoto);
        tempLoading[i] = {
          ...tempLoading[i],
          status: tempRes.status === 200 ? "success" : "failed",
          ...(tempRes.status === 422 && {
            message: tempRes.message,
          }),
        };
        tempLoading[i].status = tempRes.status === 200 ? "success" : "failed";
        this.setState({ uploadingSubsalesPhoto: tempLoading });
      }

      this.getSubsalesPhotos(this.props.subsalesEntryCreationProps.id);
      this.props.getSubsalesCurrentUser(currentPage, searchParams);
      this.filterUploadedPhoto();
      this.load(false);
    };

    // remove uploaded photo
    onClickRemoveImage = () => {
      if (this.state.selectedImages.length > 0) {
        let temp = [];
        let tempUploading = [];

        this.state.selectedImages.map((item) => {
          let subSaleImages = [
            ...this.state.publishImages,
            ...this.state.privateImages,
          ];
          let dataToSubmit = _.find(subSaleImages, { id: item });
          tempUploading.push({ ...dataToSubmit, status: "loading" });
          temp.push(dataToSubmit);
        });

        this.setState(
          {
            uploadingSubsalesPhoto: tempUploading,
            showSubsalesDeleteImage: true,
          },
          () =>
            this.removeSubsalesPhoto(this.props.subsalesEntryCreationProps.id),
        );
      } else {
        requestError("Must check at least 1 image to delete.");
      }
    };

    removeSubsalesPhoto = async (id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      for (let i = 0; i < this.state.selectedImages.length; i++) {
        let tempRes = null;
        try {
          tempRes = await Axios.delete(
            `${getDomainURL()}/sub_sales/${id}/photos/${this.state.selectedImages[i]}`,
          );
        } catch (error) {
          tempRes = { status: 422, message: error.response.data.message };
        }

        let tempLoading = _.cloneDeep(this.state.uploadingSubsalesPhoto);
        tempLoading[i] = {
          ...tempLoading[i],
          status: tempRes.status === 200 ? "success" : "failed",
          ...(tempRes.status === 422 && {
            message: tempRes.message,
          }),
        };
        tempLoading[i].status = tempRes.status === 200 ? "success" : "failed";
        this.setState({ uploadingSubsalesPhoto: tempLoading });
      }
      this.getSubsalesPhotos(this.props.subsalesEntryCreationProps.id);
      this.props.getSubsalesCurrentUser(currentPage, searchParams);
      this.setState({ selectedImages: [] });
      this.load(false);
    };

    // set subsales photo as primary photo of the listing
    setPhotoPrimary = (id, photo_id) =>
      Put(
        `/sub_sales/${id}/photos/${photo_id}/set_as_primary`,
        {},
        this.setPhotoPrimarySuccess,
        this.setPhotoPrimaryError,
        this.load,
      );
    setPhotoPrimarySuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubsalesPhotos(this.props.subsalesEntryCreationProps.id);
      this.props.getSubsalesCurrentUser(currentPage, searchParams);
    };
    setPhotoPrimaryError = (error) => requestError(error);
    setPhotoStatus = (id, photo_id, status_id) =>
      Put(
        `/sub_sales/${id}/photos/${photo_id}?status_id=${status_id}`,
        {},
        this.setPhotoPrimarySuccess,
        this.setPhotoPrimaryError,
        this.load,
      );

    updateImageSequence = () => {
      let temp = [];
      this.state.publishImages.map((item) => {
        temp.push({ id: item.id });
      });
      Put(
        `/sub_sales/${this.props.subsalesEntryCreationProps.id}/photos/sequence`,
        temp,
        this.updateImageSequenceSuccess,
        this.updateImageSequenceError,
        this.load,
      );
    };
    updateImageSequenceSuccess = () => requestSuccess("Photo sequence updated");
    updateImageSequenceError = (error) => requestError(error);

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            selectedImageSection={this.state.selectedImageSection}
            tempImgSrc={this.state.tempImgSrc}
            onLoadPhoto={this.state.loading}
            photoSnackbarContent={this.state.photoSnackbarContent}
            showPhotoSnackbar={this.state.showPhotoSnackbar}
            showPhotoTabModal={this.state.showPhotoTabModal}
            deleteImageID={this.state.deleteImageID}
            uploaded_image={this.state.uploaded_image}
            f
            publishImages={this.state.publishImages}
            privateImages={this.state.privateImages}
            selectedImages={this.state.selectedImages}
            watermark={this.state.watermark}
            watermark_color={this.state.watermark_color}
            watermark_content={this.state.watermark_content}
            status_id={this.state.status_id}
            showSubsalesUpload={this.state.showSubsalesUpload}
            showSubsalesDeleteImage={this.state.showSubsalesDeleteImage}
            uploadingSubsalesPhoto={this.state.uploadingSubsalesPhoto}
            requestSuccess={requestSuccess}
            onChangePhotoHOC={this.onChangePhotoHOC}
            onClickRemoveUploadImage={this.onClickRemoveUploadImage}
            getSubsalesPhotos={this.getSubsalesPhotos}
            onClickRemoveImage={this.onClickRemoveImage}
            updateImageSequence={this.updateImageSequence}
            onClickSetPrimary={this.onClickSetPrimary}
            onClickSetPhotoStatus={this.onClickSetPhotoStatus}
            onClickUploadImage={this.onClickUploadImage}
          />

          <AtlasSnackbar
            open={this.state.showPhotoSnackbar}
            message={this.state.photoSnackbarContent}
            handleClose={() => this.setState({ showPhotoSnackbar: false })}
          />
        </Fragment>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, { storePhotoWaterMark })(
    PhotoWrappedComponent,
  );
};

export default PhotoHOC;
