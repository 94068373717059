import React, { Component } from "react";

import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";

import UploadDocsImage from "assets/images/file.png";

import "stylesheets/components/upload/index.scss";
import "./index.scss";

class Upload extends Component {
  state = {
    imageUrl: "",
  };

  fileInput = null;

  fileInputRef = (element) => {
    this.fileInput = element;
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageUrl: nextProps.imageUrl,
      });
    }

    return true;
  }

  onUploadImage(event) {
    if (event.target.files[0]) {
      let reader = new FileReader();
      let filename = event.target.files[0].name;
      reader.onload = (e) => {
        this.setState(
          {
            imageUrl: e.target.result,
          },
          () => {
            this.props.uploadImage(e.target.result, filename);
          }
        );
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  render() {
    const {
      containerClass,
      containerStyle,
      className = "",
      uploadType,
      disabled,
      accepts,
      style,
      description,
    } = this.props;

    return (
      <>
        {uploadType !== "docs" && !this.props.imageUrl && (
          <div
            className={`at-image-upload ${containerClass || ""}`}
            style={{
              width: "100%",
              height: 196,
              textAlign: "center",
              ...containerStyle,
            }}
            onClick={() => this.fileInput?.click()}
          >
            <input
              disabled={disabled}
              id={"fileInputID"}
              ref={this.fileInputRef}
              type={"file"}
              onClick={() =>
                (document.getElementById("fileInputID").value = "")
              }
              onChange={(event) => this.onUploadImage(event)}
              style={{ display: "none" }}
              accept={accepts || "application/pdf,image/png,image/jpeg"}
            />
            <EmptyState
              renderIcon={<AtlasIcon svgHref={"atlas-document-upload"} />}
              title={"Click to upload"}
              description={description || "PNG, JPG, JPEG"}
            />
          </div>
        )}
        {(uploadType === "docs" || this.props.imageUrl) && (
          <div class="welcome-header">
            <div
              className={`at-image-upload ${containerClass || ""}`}
              style={{
                width: "100%",
                height: 196,
                textAlign: "center",
                ...containerStyle,
              }}
              onClick={() => this.fileInput?.click()}
            >
              <input
                disabled={disabled}
                id={"fileInputID"}
                ref={this.fileInputRef}
                type={"file"}
                onClick={() =>
                  (document.getElementById("fileInputID").value = "")
                }
                onChange={(event) => this.onUploadImage(event)}
                style={{ display: "none" }}
                accept={accepts || "application/pdf,image/png,image/jpeg"}
              />
              {uploadType === "docs" ? (
                <img
                  className={className}
                  style={{
                    marginRight: 0,
                    ...style,
                  }}
                  alt="Upload Docs"
                  src={UploadDocsImage}
                />
              ) : this.props.imageUrl ? (
                <img
                  className={className}
                  style={{
                    marginRight: 0,
                    objectPosition: "top",
                    objectFit: "contain",
                    height: "100%",
                    ...style,
                  }}
                  alt="Profile Avatar"
                  src={this.props.imageUrl}
                />
              ) : (
                <EmptyState
                  renderIcon={<AtlasIcon svgHref={"atlas-document-upload"} />}
                  title={"Click to upload"}
                  description={description ? description : "PNG, JPG, JPEG"}
                />
              )}
            </div>
            <div
              className="upload-icon-container"
              style={{ position: "absolute" }}
            >
              <AtlasIcon
                svgHref={"atlas-close-circle"}
                className={"upload-atlas-icon"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ imageUrl: "" }, () => this.props.uploadImage("", ""))}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Upload;
