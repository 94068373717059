import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WithComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      rejectReason: "",
      has_resignation_letter_issue: false,
      has_payment_issue: false,
      has_other_issue: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeRejectHOC = (val, context) => this.setState({ [context]: val });

    rejectNewRENS = () => {
      let tmp = {};
      let tmpArray = [];
      this.props.selectedUsers.map((item) => tmpArray.push({ id: item }));
      tmp = {
        approval_ids: tmpArray,
        status_id: 3,
        remark: this.state.rejectReason,
        has_resignation_letter_issue: this.state.has_resignation_letter_issue,
        has_payment_issue: this.state.has_payment_issue,
        has_other_issue: this.state.has_other_issue,
      };

      if (
        tmp.remark === "" ||
        !(
          tmp.has_resignation_letter_issue ||
          tmp.has_payment_issue ||
          tmp.has_other_issue
        )
      ) {
        requestError(
          "Please make sure that at least one of the checkboxes is ticked and the reject reason is filled before rejecting the new REN approval.",
        );
      } else {
        Post(
          `/admin/registrations/reject`,
          tmp,
          this.rejectNewRENSSuccess,
          this.rejectNewRENSError,
          this.load,
        );
      }
    };
    rejectNewRENSSuccess = (payload) => {
      requestSuccess(payload.message);
      this.props.onChangeApprovalHOC([], "selectedUsers");
      this.props.onChangeApprovalHOC(false, "showRejectDialog");

      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.props.getPendingNewREN(currentPage, searchParams);
    };
    rejectNewRENSError = (error) => requestError(error);

    render = () => {
      return (
        <WithComponent
          {...this.props}
          rejectReason={this.state.rejectReason}
          onLoadReject={this.state.loading}
          onChangeRejectHOC={this.onChangeRejectHOC}
          rejectNewRENS={this.rejectNewRENS}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
