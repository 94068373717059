import React, { useState, useRef, useCallback, useEffect } from "react";
import _ from "lodash";
import { PulseLoader } from "react-spinners";
import { FormHelperText } from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";

import SVGIcon from "components/Icon";
import CutsomTabs from "components/Tab";
import AtlasButton from "components/Button";
import TeaserImage from "components/TeaserImage";
import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomTypeahead from "components/Typeahead/new";
import AtlasTable from "components/NewPaginatedTable";
import CustomTable from "components/NewTable";

import AgentSearchHOC from "actions/agentSearch";
import { addAttendeeFieldData } from "../assets";
import DefaultImage from "assets/images/av-male.jpg";
import isEmptyValue from "utils/isEmpty";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const actionColumn = ["transfer", "check-in", "check-out"];
const denyActionColumn = ["reject", "delete"];

const columnData = [
  {
    header: "Transfer Date",
    accessor: "created_at",
    searchFlag: "created_at",
    columnStyle: { width: "100%" },
  },
  {
    header: "From",
    accessor: "from_who",
    searchFlag: "from_who",
    columnStyle: { width: "100%" },
  },
  {
    header: "To",
    accessor: "to_who",
    searchFlag: "to_who",
    columnStyle: { width: "100%" },
  },
  {
    header: "By",
    accessor: "by_who",
    searchFlag: "by_who",
    columnStyle: { width: "100%" },
  },
  {
    header: "Statement Of Approval",
    searchFlag: "statement_of_approval_url",
    hideFromSearch: true,
    renderColumn: (rowData) => {
      return (
        <a target="_blank" href={rowData.statement_of_approval_url}>
          <SVGIcon className="svg-icon-info" svgHref={"icon-doc"} />
        </a>
      );
    },
    columnStyle: { width: "100%" },
  },
];

const processIconType = (param) => {
  let tmp = <></>;
  switch (param) {
    case "check-in":
      tmp = "Clock In";
      break;
    case "check-out":
      tmp = "Clock Out";
      break;
    case "transfer":
      tmp = "Transfer";
      break;
    case "delete":
      tmp = "Delete";
      break;
    case "reject":
      tmp = "Reject";
      break;
    default:
      tmp = "";
      break;
  }
  return tmp;
};

const processActions = (content, onClickAction, type) => {
  let flag = false;
  let colorClass = "btn-new--secondary";

  switch (type) {
    case "check-in":
      flag = content.clock_in_time !== "N/A";
      break;

    case "check-out":
      flag = content.clock_out_time !== "N/A";
      break;

    case "transfer":
      flag =
        content.clock_in_time !== "N/A" || content.clock_out_time !== "N/A";
      break;

    case "delete":
      flag =
        !(
          content.payment_method_id === 0 ||
          (content.payment_method_id === 2 && !content.proof_of_payment_url)
        ) || content.is_approved;
      colorClass = "btn-new--danger";
      break;

    case "reject":
      flag =
        content.is_approved ||
        content.payment_method_id === 1 ||
        isEmptyValue(content.payment_method_id);
      colorClass = "btn-new--danger";
      break;

    default:
      flag = false;
      break;
  }

  return (
    <AtlasButton
      key={type}
      disabled={flag}
      containerStyle={{ marginRight: "5px" }}
      className={`btn-new ${colorClass} fs-2 mt-1`}
      children={processIconType(type)}
      tooltip={true}
      tooltipChildren={
        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
      }
      tooltipPosition={"top"}
      tooltipID={`details-${type}-${content.id}`}
      onClick={() => onClickAction(content)}
    />
  );
};

const AddAttandee = ({
  mode,
  agentList,
  onLoadAgent,
  selectedEvent,
  onLoadAttendee,
  selectedAttendee,
  transferHistories,
  eventTicketPricing,
  onLoadTransferTicket,

  onClose,
  putAttendee,
  updateAttendee,
  getTransferHistories,
  onClickRemove,
  onClickCheckIn,
  onClickCheckout,
  onClickTransfer,
  onClickTransferEvent,
  onClickReject,
  getAgentsBySearch,
  onChangeAgentHOC,
}) => {
  const [formData, setFormData] = useState({
    agent: "",
    payment_method_id: 0,
    is_approved: false,
    status_id: 0,
    ticket_pricing_id: "",
    modifiedImage: false,
  });
  const [bankInSlip, setBankSlip] = useState({});
  const [selectedSection, setSelectedSection] = useState("Details");

  useEffect(() => {
    if (mode === "update") {
      getTransferHistories(selectedAttendee.id);
      if (
        selectedAttendee.proof_of_payment_url &&
        selectedAttendee.payment_method_id === 2
      ) {
        setBankSlip({
          proof_of_payment: selectedAttendee.proof_of_payment_url,
        });
      }
      setFormData(selectedAttendee);
    }
  }, [selectedAttendee]);

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const onChangeFieldData = (val, context) => {
    let temp = _.cloneDeep(formData);
    if (context === "ticket_pricing_id") temp[context] = Number(val);
    else temp[context] = val;
    setFormData(temp);
  };

  const onChangeFile = (fileItems) => {
    let temp = {};

    if (fileItems.length > 0 && fileItems[0].file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          temp.proof_of_payment = e.target.result;
          temp.proof_of_payment_file_name = fileItems[0].file.name;
          onChangeFieldData(true, "modifiedImage");
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    }
    return setBankSlip(temp);
  };

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClose()} />
        <h2 className="at-card__title">
          {mode === "create" && "Add Attendee"}
          {mode === "update" && "Edit Attendee"}
        </h2>
      </div>
      {mode === "update" && (
        <CutsomTabs
          sections={["Details", "Transfer Histories"]}
          selectedSection={selectedSection}
          onSelectSection={(val) => {
            if (val === "Transfer Histories") {
              getTransferHistories(selectedAttendee.id);
            }
            setSelectedSection(val);
          }}
        />
      )}
      {selectedSection === "Details" && (
        <div className={"row"}>
          {addAttendeeFieldData(selectedEvent).map((item, index) => (
            <div key={index} className="col-md-12 mb-20">
              {item.type === "typeahead" && (
                <>
                  {mode === "create" && (
                    <>
                      <div className="d-flex">
                        <h2 className="at-form-input__title mr-10">
                          {item.label}
                        </h2>
                        <PulseLoader
                          sizeunit={"px"}
                          size={10}
                          color={"#16232c"}
                          loading={onLoadAgent}
                        />
                      </div>
                      <CustomTypeahead
                        disabled={onLoadAgent}
                        typeaheadId={"attendee-agent"}
                        options={agentList || []}
                        selectedValue={
                          formData[item.value] ? [formData[item.value]] : []
                        }
                        labelKey={"full_name"}
                        onSearch={(val) => {
                          onChangeFieldData(null, item.value);
                          onChangeTypeaheadSearch(val);
                        }}
                        onSelect={(val) =>
                          val &&
                          val.length > 0 &&
                          onChangeFieldData(val[0], item.value)
                        }
                        filterBy={[
                          "full_name",
                          "email",
                          "mobile_contact_number",
                        ]}
                        helpText={"e.g. Full Name, Email, Mobile Number"}
                        childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                        highlighterData={[
                          (option) => `Team: ${option.team_name}`,
                          <br />,
                          (option) =>
                            `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                        ]}
                      />
                    </>
                  )}
                  {mode === "update" && (
                    <div className="at-attendee__agent-detail">
                      <img
                        className="at-attendee__agent-detail-avatar"
                        src={DefaultImage}
                        alt={"attendee-profile-pic"}
                      />
                      <div className="at-attendee__agent-detail-info">
                        <p>{formData.agent.full_name}</p>
                        <p>{formData.agent.email}</p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {item.type === "radio" && (
                <>
                  <h2 className="at-form-input__title mr-10">{item.label}</h2>
                  <CustomRadioGroup
                    checkedValue={formData[item.value]}
                    containerClassName={"w-100"}
                    horizontal={true}
                    options={item.options}
                    selectedRadioValue={(val) =>
                      onChangeFieldData(val, item.value)
                    }
                  />
                  {item.value === "payment_method_id" &&
                    formData[item.value] === 2 && (
                      <div className="mt-3">
                        <h2 className="at-form-input__title mr-10">
                          Payment Proof
                        </h2>
                        {bankInSlip.proof_of_payment && (
                          <TeaserImage
                            src={bankInSlip.proof_of_payment}
                            can_update={true}
                            containerStyle={{ marginTop: 20 }}
                            onRemoveImage={() => setBankSlip({})}
                          />
                        )}
                        {!bankInSlip.proof_of_payment && (
                          <div className={"mt-20"}>
                            <FilePond
                              allowMultiple={false}
                              fullWidth
                              maxFiles={1}
                              acceptedFileTypes={["image/*"]}
                              onupdatefiles={(fileItems) =>
                                onChangeFile(fileItems)
                              }
                            />
                            <FormHelperText>
                              Accepted file types: jpg, jpeg, png.( Required )
                            </FormHelperText>
                          </div>
                        )}
                      </div>
                    )}
                </>
              )}
              {item.type === "table" && (
                <div className="mt-3">
                  <h2 className="at-form-input__title mr-10">{item.label}</h2>
                  <AtlasTable
                    columns={item.column({
                      mode,
                      formData,
                      onChangeFieldData,
                    })}
                    searchParams={[]}
                    rowData={eventTicketPricing || []}
                    meta={null}
                    totalPages={[]}
                  />
                </div>
              )}
            </div>
          ))}
          <div className="col-md-12 d-flex">
            <AtlasButton
              className={"btn-new btn-new--success"}
              children={<span className="text-capitalize">{mode}</span>}
              onClick={() => {
                if (mode === "create") {
                  putAttendee({
                    event_id: selectedEvent.id,
                    user_id: formData.agent.id,
                    payment_method_id: formData.payment_method_id,
                    ticket_pricing_id: formData.ticket_pricing_id,
                    is_approved: formData.is_approved,
                    status_id: formData.status_id,
                    ...(formData.payment_method_id === 2 &&
                      formData.modifiedImage &&
                      bankInSlip),
                  });
                } else {
                  updateAttendee({
                    user_id: formData.id,
                    payment_method_id: formData.payment_method_id,
                    ticket_pricing_id: formData.ticket_pricing_id,
                    is_approved: formData.is_approved,
                    status_id: formData.status_id,
                    ...(formData.payment_method_id === 2 &&
                      formData.modifiedImage &&
                      bankInSlip),
                  });
                }
              }}
            />
            <AtlasButton
              className={"btn-new btn-new--outline-secondary ml-2"}
              children={
                <>
                  {mode === "create" && "Cancel"}
                  {mode === "update" && "Close"}
                </>
              }
              onClick={() => onClose()}
            />
          </div>
          {mode === "update" && (
            <>
              <div className="col-md-12 d-flex">
                {actionColumn.map((item) => {
                  switch (item) {
                    case "check-in":
                      return processActions(
                        selectedAttendee,
                        onClickCheckIn,
                        item,
                      );
                    case "check-out":
                      return processActions(
                        selectedAttendee,
                        onClickCheckout,
                        item,
                      );
                    case "transfer":
                      return processActions(
                        selectedAttendee,
                        onClickTransfer,
                        item,
                      );
                  }
                })}
              </div>
              <div className="col-md-12 d-flex">
                {denyActionColumn.map((item) => {
                  switch (item) {
                    case "delete":
                      return processActions(
                        selectedAttendee,
                        onClickRemove,
                        item,
                      );
                    case "reject":
                      return processActions(
                        selectedAttendee,
                        onClickReject,
                        item,
                      );
                  }
                })}
              </div>
            </>
          )}
        </div>
      )}
      {selectedSection === "Transfer Histories" && (
        <CustomTable
          className={"mb-100"}
          columns={columnData}
          pagination={true}
          rowData={transferHistories || []}
          actionColumnContent={[]}
        />
      )}
      {onLoadAttendee && onLoadTransferTicket && <LoadingModal />}
    </>
  );
};

export default AgentSearchHOC(AddAttandee);
