import React, { Component } from "react";
import Moment from "moment";
import { Grid, Card } from "@material-ui/core";

import AtlasButton from "components/Button";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import permissionsChecker from "utils/permissionsChecker";

const RadioOptions = [
  { label: "On", value: "On" },
  { label: "Off", value: "Off" },
];

const WaitingRoomOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export default class CreateZoomContent extends Component {
  state = {
    passcode: "",
    waitingRoom: true,
    hostFlag: "Off",
    participantsFlag: "On",
    process_status: null,
  };

  componentDidMount = () => this.props.selectedEventZoom && this.initState();

  componentDidUpdate = (pp) => {
    if (
      pp.selectedEventZoom !== this.props.selectedEventZoom &&
      Object.keys(this.props.selectedEventZoom).length > 0
    ) {
      this.initState();
    }
  };

  initState = () => {
    const {
      password,
      waiting_room,
      host_video,
      participant_video,
      process_status,
    } = this.props.selectedEventZoom;

    this.setState({
      passcode: password,
      waitingRoom: waiting_room,
      hostFlag: host_video,
      participantsFlag: participant_video,
      process_status,
    });
  };

  renderEventDetails = () => {
    const { name, start_date_time, end_date_time, description } =
      this.props.selectedEvent;

    let tempStartDate =
      start_date_time === "N/A"
        ? "N/A"
        : Moment(start_date_time).format("YYYY-MM-DD LT");
    let tempEndDate =
      end_date_time === "N/A"
        ? "N/A"
        : Moment(end_date_time).format("YYYY-MM-DD LT");

    return (
      <div className="grid-control" style={{ maxWidth: 1000 }}>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">{`Topic`}</h2>
          <p>{name}</p>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{`Start Datetime`}</h2>
          <p>{tempStartDate}</p>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{`End Datetime`}</h2>
          <p>{tempEndDate}</p>
        </section>
        <section className="grid-half-col mb-20">
          <h2 className="at-form-input__title">Agenda:</h2>
          <p>{description}</p>
        </section>
        {this.state.process_status && (
          <section className="grid-half-col mb-20">
            <h2 className="at-form-input__title">Attendance Update Status:</h2>
            <p>{this.state.process_status}</p>
          </section>
        )}
      </div>
    );
  };

  renderSettingsContent = () => {
    const { mode } = this.props;
    const { can_update } = permissionsChecker(
      "Company Events",
      this.props.data,
    );
    return (
      <>
        <h4 className="at-card__title mt-20">{"Settings"}</h4>
        <hr />
        <Card style={{ padding: 16, maxWidth: 1000 }}>
          <Grid container spacing={16}>
            <Grid item md={2} xs={12}>
              <h2 style={{ color: "#f18e03" }} className="at-form-input__title">
                Security
              </h2>
            </Grid>
            <Grid item md={5} xs={12}>
              <h2 className="at-form-input__title" required>
                Passcode
              </h2>
              <CustomFormInput
                disabled={mode === "edit" && !can_update}
                type="text"
                containerStyle={{ maxWidth: 1000 }}
                value={this.state.passcode}
                onChangeValue={(val) => this.setState({ passcode: val })}
                required={true}
                placeholder={"Auto-generated if left blank."}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <h2 className="at-form-input__title">Waiting Room</h2>
              <AtlasRadioGroup
                horizontal={true}
                disabled={mode === "edit" && !can_update}
                checkedValue={this.state.waitingRoom}
                containerClassName={"w-100"}
                selectedRadioValue={(val) =>
                  this.setState({ waitingRoom: val })
                }
                options={WaitingRoomOptions}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <h2 style={{ color: "#f18e03" }} className="at-form-input__title">
                Video
              </h2>
            </Grid>
            <Grid item md={5} xs={12}>
              <h2 className="at-form-input__title">Host</h2>
              <AtlasRadioGroup
                horizontal={true}
                disabled={mode === "edit" && !can_update}
                checkedValue={this.state.hostFlag}
                containerClassName={"w-100"}
                selectedRadioValue={(val) => this.setState({ hostFlag: val })}
                options={RadioOptions}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <h2 className="at-form-input__title">Participants</h2>
              <AtlasRadioGroup
                horizontal={true}
                disabled={mode === "edit" && !can_update}
                checkedValue={this.state.participantsFlag}
                containerClassName={"w-100"}
                selectedRadioValue={(val) =>
                  this.setState({ participantsFlag: val })
                }
                options={RadioOptions}
              />
            </Grid>
          </Grid>
        </Card>
      </>
    );
  };

  renderCardContent = () => {
    const {
      mode,
      selectedEvent,
      selectedEventZoom,
      getStartZoomLink,
      updateAttendance,
      refreshProcessingStatus,
    } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Company Events",
      this.props.data,
    );
    return (
      <>
        {this.renderEventDetails()}
        {this.renderSettingsContent()}
        {mode === "create" && (
          <div className="at-modal_dialog-container-footer">
            {((mode === "create" && can_create) ||
              (mode === "edit" && can_update)) && (
              <AtlasButton
                className={"btn-new btn-new--success mr-2"}
                children={mode === "create" ? "Create" : "Save"}
                onClick={() => {
                  let temp = {
                    password: this.state.passcode,
                    waiting_room: this.state.waitingRoom,
                    host_video: this.state.hostFlag === "On",
                    participant_video: this.state.participantsFlag === "On",
                  };
                  mode === "create"
                    ? this.props.createEventZoomSession(selectedEvent.id, temp)
                    : this.props.editEventZoomSession(selectedEvent.id, temp);
                }}
              />
            )}
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => this.props.onClose()}
            >
              Cancel
            </button>
          </div>
        )}
        {mode === "edit" && can_update && (
          <div className={"at-modal_dialog-container-footer"}>
            <button
              className={"btn-new btn-new--secondary mr-10 "}
              style={{ fontWeight: "400", fontSize: "14px" }}
              onClick={() => getStartZoomLink(selectedEvent.id)}
            >
              View Meetings
            </button>
            {can_update && (
              <>
                <button
                  disabled={selectedEventZoom.process_status !== "Pending"}
                  className={"btn-new btn-new--secondary mr-10"}
                  style={{ fontWeight: "400", fontSize: "14px" }}
                  onClick={() => updateAttendance(selectedEventZoom.event_id)}
                >
                  Sync Attendance
                </button>
                <button
                  disabled={selectedEventZoom.process_status === "Processed"}
                  className={"btn-new btn-new--secondary "}
                  style={{ fontWeight: "400", fontSize: "14px" }}
                  onClick={() =>
                    refreshProcessingStatus(selectedEventZoom.event_id)
                  }
                >
                  Refresh status
                </button>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  render = () => {
    return (
      <>
        {this.renderCardContent()}
        {this.props.onLoadZoom && <LoadingModal />}
      </>
    );
  };
}
