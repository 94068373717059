import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";

const PolicyContent = () => {
  return (
    <Card style={{ padding: 15, marginTop: 10 }}>
      <CardHeader title="Refund Policy" />
      <CardContent>
        <p style={{ paddingTop: 20 }}>
          Thank you for joining Team IQI. Our effort, preparations and
          investments are here to provide you a solid ground before you start
          your new career. We would like to guide you to the process from
          beginning to end and aim that you remain involved. Hence we want to
          acquaint you about our terms and conditions related to your training
          and e-tickets.
        </p>
        <p style={{ fontWeight: "bold", paddingTop: 20, paddingBottom: 10 }}>
          Training
        </p>
        <p>
          Once we receive your admission fee for the training, a total package
          will be offered to you which will be your first investment to your
          career at IQI. As we have to pay for outside trainers, once your
          training is confirmed, the fees are non-refundable. In some
          circumstances, it may be possible to postpone the training session.
        </p>
        <p style={{ fontWeight: "bold", paddingTop: 20, paddingBottom: 10 }}>
          Agent Registration Fee & Event Registration Fee
        </p>
        <p>
          At IQI we offer our agents the opportunity to attend extraordinary
          events and special occasions. To ensure a smooth process we offer a
          ticketing service. Based on the service provided, the cost of any
          E-Ticket is non-refundable after successful payment. The event ticket
          and training session will be available upon completion of the
          transaction.{" "}
        </p>
        <p style={{ paddingTop: 20, paddingBottom: 10 }}>
          For any further questions you can always contact us on the following
          contact details below:
        </p>
        <ul>
          <li>T: +(60)3 7453 5155</li>
          <li>
            E: <a href="mailto:info@iqi-group.com">info@iqi-group.com</a>
          </li>
        </ul>
      </CardContent>
    </Card>
  );
};

export default PolicyContent;
