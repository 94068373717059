import React, { Component } from "react";
import { Dialog, Grid } from "@material-ui/core";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasPrevButton from "components/Button/prev";
import AtlasCloseButton from "components/Button/close";
import ModalDialog from "components/Modal/ModalDialog";
import ZoomForm from "./zoomForm";

import ZoomHOC from "../actions/zoom";
import permissionsChecker from "utils/permissionsChecker";
import CustomButton from "components/Button";

class ZoomSessionContent extends Component {
  componentDidMount = () => {
    this.props.getSelectedEvent(this.props.selectedEvent.id);
    this.props.getEventZoomSession(this.props.selectedEvent.id);
  };

  renderCreateContent = () => {
    const {
      data,
      onLoadZoom,
      selectedEvent,
      showCreateModal,
      onChangeZoomHOC,
      createEventZoomSession,
    } = this.props;
    const { can_create } = permissionsChecker("Company Events", data);
    return (
      <>
        <h2 className="at-form-input__title" style={{ fontWeight: "normal" }}>
          {"This event does not have any zoom session created."}
        </h2>
        {can_create && (
          <AtlasButton
            className={"btn-new btn-new--secondary mr-10"}
            style={{ fontWeight: "400", fontSize: "14px" }}
            children={"Create Zoom Session"}
            onClick={() => onChangeZoomHOC(true, "showCreateModal")}
          />
        )}
        {showCreateModal && (
          <ModalDialog
            title={"Create zoom session"}
            onClose={() => onChangeZoomHOC(false, "showCreateModal")}
            children={
              <ZoomForm
                mode={"create"}
                data={data}
                onLoadZoom={onLoadZoom}
                selectedEvent={selectedEvent}
                onClose={() => onChangeZoomHOC(false, "showCreateModal")}
                createEventZoomSession={createEventZoomSession}
              />
            }
          />
        )}
      </>
    );
  };

  renderEditContent = () => {
    const {
      data,
      onLoadZoom,
      selectedEvent,
      selectedEventZoom,
      editEventZoomSession,
      updateAttendance,
      refreshProcessingStatus,
      getStartZoomLink,
    } = this.props;

    return (
      <>
        <ZoomForm
          mode={"edit"}
          data={data}
          onLoadZoom={onLoadZoom}
          selectedEvent={selectedEvent}
          selectedEventZoom={selectedEventZoom}
          onClose={() =>
            this.props.onChangeZoomHOC(false, "showSelectLinkDialog")
          }
          editEventZoomSession={editEventZoomSession}
          getStartZoomLink={getStartZoomLink}
          updateAttendance={updateAttendance}
          refreshProcessingStatus={refreshProcessingStatus}
        />
      </>
    );
  };

  renderSelectLinkContent = () => {
    return (
      <>
        <div className="d-flex mb-20">
          <h4 className={"at-form-input__title"} style={{ fontWeight: "normal" }}>
            Please click on the buttons to start the zoom sessions
          </h4>
          <AtlasCloseButton
            containerStyle={{
              display: "flex",
              marginLeft: "auto",
            }}
            onClick={() =>
              this.props.onChangeZoomHOC(false, "showSelectLinkDialog")
            }
          />
        </div>
        <hr />
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          {this.props.zoomLinks.map((item) => (
            <Grid item md={3} sm={4} xs={12}>
              <CustomButton
                containerClass={"btn-new btn-new--secondary mr-2 mb-2"}
                containerStyle={{ backgroundColor: item.background }}
                onClick={() => window.open(item.start_url, "_blank")}
              >
                <>
                  <strong>{item.name}</strong>
                  <br />
                  <i
                    style={{ fontSize: 13, color: "white" }}
                  >{`id: ${item.zoom_meeting_id}`}</i>
                </>
              </CustomButton>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  render = () => {
    return (
      <>
        {!this.props.selectedEventZoom && this.renderCreateContent()}
        {this.props.selectedEventZoom && this.renderEditContent()}
        <Dialog fullWidth={true} open={this.props.showSelectLinkDialog}>
          <CustomCard cardContent={this.renderSelectLinkContent()} />
        </Dialog>
        {this.props.onLoadZoom && <LoadingModal />}
      </>
    );
  };
}

export default ZoomHOC(ZoomSessionContent);
