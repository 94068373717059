import React, { useEffect, useRef, useState, useCallback } from "react";
import _ from "lodash";
import { AiFillIdcard } from "react-icons/ai";
import { FaUserTie, FaTelegram, FaYoutube } from "react-icons/fa";
import { RiQrCodeLine, RiGroup2Fill } from "react-icons/ri";
import { IoIosPaper } from "react-icons/io";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import PreviewModal from "../components/Preview";
import EmailAppointmentModal from "./emailAppointment";
import UserInfoList from "./userInfoList";

import Instagram from "assets/images/instagram.png";
import defaultAvatar from "assets/images/av-male.jpg";
import permissionsChecker from "utils/permissionsChecker";

const ProfileCard = ({
  data,
  bankDetails,
  selectedUser,
  onLoadAppointmentLetter,
  isAppointmentSigned,
  onChangeState,
  profileRole,
  onClickNameCard,
  onClickEditButton,
  onClickAppointmentLetter,
  onChangeAppointmentLetterHOC,
  sendEmailWithoutData,
  downloadDocWithoutData,
  appointmentPreview,
  showAppointmentEmailModal,
}) => {
  let dropDownRef = useRef(null);

  const [showDropDown, onToggleDropDown] = useState(false);
  const [showPreview, onTogglePreview] = useState(false);
  const { can_update } = permissionsChecker("Users", data);

  useEffect(() => {
    appointmentPreview && onTogglePreview(true);
  }, [appointmentPreview]);

  useEffect(() => {
    !showPreview && onChangeAppointmentLetterHOC(null, "appointmentPreview");
  }, [showPreview]);

  useEffect(() => {
    document.addEventListener("click", (e) => handleOnClose(e), true);
  }, []);

  let handleOnClose = useCallback(
    (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        onToggleDropDown(false);
      }
    },
    [showDropDown]
  );

  let {
    avatar_url,
    full_name,
    display_name,
    role,
    role_id,
    instagram_url,
    telegram_url,
    facebook_url,
    youtube_playlist_url,
    wechat_id,
    show_public_qr,
    team_name,
    job_title_display_name,
    identity_qr_code_image_url,
    board_registration_type_id,
  } = selectedUser;

  return (
    <>
      <div className={"at-admin-profile "}>
        <div className={"at-form__content at-profile__agent-cont"}>
          <img
            className="at-card--profile_card-img"
            alt="avatar"
            src={
              avatar_url && !_.includes(avatar_url, "missing.png")
                ? avatar_url
                : defaultAvatar
            }
          />
          <div className="at-profile__agent-info">
            <h4>{display_name}</h4>
            <p>{full_name}</p>
            <p>
              <FaUserTie />
              {job_title_display_name}
            </p>
            {(role === "Personal Assistant" || role === "Agent") && (
              <p>
                <RiGroup2Fill />
                {team_name} Team
              </p>
            )}
          </div>
          <div className="at-profile__agent-contact">
            {wechat_id && (
              <div className="at-profile__agent-wechat">
                <AtlasIcon
                  svgHref={"atlas-wechat"}
                  style={{ fill: "#12B76A" }}
                />
                <span>{wechat_id}</span>
              </div>
            )}
            {facebook_url && (
              <div onClick={() => window.open(facebook_url, "__blank")}>
                <AtlasIcon
                  svgHref={"atlas-facebook"}
                  style={{ fill: "#3B82F6" }}
                />
                <span>{facebook_url}</span>
              </div>
            )}
            {telegram_url && (
              <div onClick={() => window.open(telegram_url, "__blank")}>
                <FaTelegram style={{ color: "#25A3E0" }} />
                <span>{telegram_url}</span>
              </div>
            )}
            {instagram_url && (
              <div onClick={() => window.open(instagram_url, "__blank")}>
                <img
                  src={Instagram}
                  alt="instagram"
                  style={{ width: 20, height: 20, minWidth: 20 }}
                />
                <span>{instagram_url}</span>
              </div>
            )}
            {youtube_playlist_url && (
              <div onClick={() => window.open(youtube_playlist_url, "__blank")}>
                <FaYoutube style={{ color: "#F04438" }} />
                <span>Play on Youtube</span>
              </div>
            )}
          </div>
        </div>
        {role_id === 5 && (
          <button
            className="btn-new at-profile__agent-action"
            onClick={() => onClickNameCard()}
          >
            <AtlasIcon
              svgHref={"atlas-card"}
              style={{ backgroundColor: "#6875F5" }}
            />
            Name Card
          </button>
        )}
        {(role === "Agent" || role === "Associated Agent") && (
          <button
            className="btn-new at-profile__agent-action"
            onClick={() => onChangeState(true, "showReferalAgent")}
          >
            <AtlasIcon
              svgHref={"atlas-card"}
              style={{ backgroundColor: "#F0631D" }}
            />
            My Referral Link
          </button>
        )}
        {show_public_qr &&
          role === "Agent" &&
          identity_qr_code_image_url !== "" && (
            <button
              className="btn-new at-profile__agent-action position-relative"
              onClick={() => {
                if (board_registration_type_id !== 3) {
                  onClickAppointmentLetter();
                  onToggleDropDown(!showDropDown);
                }
              }}
            >
              <IoIosPaper style={{ backgroundColor: "#06B6D4" }} />
              Appointment Letter
              <div
                ref={dropDownRef}
                className={"at-party__dropbear--modal-root"}
                style={{ display: showDropDown ? "block" : "none" }}
                onBlurCapture={() => onToggleDropDown(false)}
              >
                <div className={`at-party__dropbear--list`}>
                  <button
                    disabled={!isAppointmentSigned}
                    onClick={() => {
                      downloadDocWithoutData(selectedUser.id);
                      onToggleDropDown(false);
                    }}
                  >
                    Preview
                  </button>
                  <button
                    disabled={!isAppointmentSigned}
                    onClick={() => {
                      onChangeAppointmentLetterHOC(
                        true,
                        "showAppointmentEmailModal"
                      );
                      onToggleDropDown(false);
                    }}
                  >
                    Email
                  </button>
                  <button
                    onClick={() =>
                      onChangeAppointmentLetterHOC(
                        true,
                        "showAppointmentLetterModal"
                      )
                    }
                  >
                    Customise
                  </button>
                </div>
              </div>
            </button>
          )}
        <button
          className="btn-new at-profile__agent-action"
          onClick={() => onChangeState(true, "showIQIID")}
        >
          <AiFillIdcard style={{ backgroundColor: "#F79009" }} />
          My IQI ID
        </button>
        {show_public_qr &&
          role === "Agent" &&
          identity_qr_code_image_url !== "" && (
            <button
              className="btn-new at-profile__agent-action"
              onClick={() => onChangeState(true, "showAgentQR")}
            >
              <RiQrCodeLine style={{ backgroundColor: "#3B82F6" }} />
              My Public Profile
            </button>
          )}
        <div className="at-profile__agent-details">
          <UserInfoList
            selectedUser={selectedUser}
            bankDetails={bankDetails}
            editPermission={profileRole === "Super Admin" || can_update}
            onClickEditButton={onClickEditButton}
          />
        </div>
      </div>
      {onLoadAppointmentLetter && <LoadingModal />}
      <EmailAppointmentModal
        open={showAppointmentEmailModal}
        onLoadAppointmentLetter={onLoadAppointmentLetter}
        onCloseModal={() =>
          onChangeAppointmentLetterHOC(false, "showAppointmentEmailModal")
        }
        onClickSubmit={sendEmailWithoutData}
        selectedUserId={selectedUser.id}
      />
      <PreviewModal
        open={showPreview}
        onClickClose={() => onTogglePreview(false)}
        previewFile={appointmentPreview}
      />
    </>
  );
};

export default ProfileCard;
