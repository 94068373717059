import React, { useState, useCallback } from "react";
import { MenuItem } from "@material-ui/core";
import _ from "lodash";

import AtlasButton from "components/Button";
import AtSelectNew from "components/Select/new";
import CustomCheckbox from "components/Checkbox";
import ModalDialog from "components/Modal/ModalDialog";

const SubsalesAdminStatus = [
  { id: 1, name: "On Call" },
  { id: 2, name: "Offline" },
  { id: 3, name: "Disabled" },
];

const SelectFieldData = ({ admins = [], admin_roles = [] }) => [
  { label: "Select an admin", value: "user_id", options: admins },
  { label: "Roles", value: "type_id", options: admin_roles },
  { label: "Status", value: "status_id", options: SubsalesAdminStatus },
];

const Form = ({
  selectedData,
  onClose,
  onClickSubmit,

  branchOptions = [],
  onLoadSubsalesAdmins,

  ...props
}) => {
  const [formData, onChangeForm] = useState({
    user_id: null,
    type_id: null,
    status_id: null,
  });
  const [branchIDs, onChangeBranchID] = useState([]);

  let onChangeField = useCallback(
    (context, val) => {
      onChangeForm((prevState) => ({
        ...prevState,
        [context]: val,
      }));
    },
    [formData],
  );

  return (
    <ModalDialog
      title={"Assign Admin to a Role"}
      onLoad={onLoadSubsalesAdmins}
      responsiveSize="lg"
      onClose={onClose}
      children={
        <div className="row">
          {SelectFieldData(props).map((field) => (
            <section className="col-md-4" key={field.value}>
              <h2 className="at-form-label">{field.label}</h2>
              <AtSelectNew
                value={`${formData[field.value]}`}
                onChange={(e) => onChangeField(field.value, e.target.value)}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {field.options.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </AtSelectNew>
            </section>
          ))}
          <div className="col-md-12">
            <h2 className="at-form-label">Manage Branches Below</h2>
            <div className="row mt-3">
              {branchOptions.map((item) => (
                <CustomCheckbox
                  key={item.id}
                  labelClassname={"col-6 col-md-4 col-lg-3 mx-0"}
                  labelStyle={{ marginBottom: 12 }}
                  content={item.name}
                  checked={branchIDs.indexOf(item.id) > -1}
                  onChangeCheckboxValue={() => {
                    let tmp = _.cloneDeep(branchIDs);
                    if (branchIDs.indexOf(item.id) > -1) {
                      tmp.splice(tmp.indexOf(item.id), 1);
                    } else {
                      tmp.push(item.id);
                    }
                    onChangeBranchID(tmp);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="d-flex align-items-center g-3">
          <AtlasButton
            className={"btn-new btn-new--primary"}
            children={"Assign"}
            onClick={() =>
              onClickSubmit({
                ...formData,
                branch_ids: branchIDs,
              })
            }
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      }
    />
  );
};

export default Form;
