import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { AiFillIdcard } from "react-icons/ai";
import { FaUserTie, FaTelegram, FaYoutube } from "react-icons/fa";
import { RiQrCodeLine, RiGroup2Fill } from "react-icons/ri";
import { IoIosPaper } from "react-icons/io";

import AtlasIcon from "components/Icon/atlasIcon";
import DownloadCP58Content from "./downloadCP58";
import UserInfoList from "./userInfoList";

import defaultAvatar from "assets/images/av-male.jpg";
import Instagram from "assets/images/instagram.png";

const ProfileCard = ({
  data,
  history,
  cp58Year,
  cp58List,
  selectedAgent,
  showDownloadModal,
  onLoadAppointmentLetter,

  getCP58,
  downloadCP58,
  checkDigitalSign,
  onChangeProfileHOC,
}) => {
  const {
    avatar_url,
    full_name,
    display_name,
    role,
    instagram_url,
    telegram_url,
    facebook_url,
    youtube_playlist_url,
    wechat_id,
    country_id,
    show_public_qr,
    team_name,
    job_title_display_name,
  } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;

  return (
    <>
      <div className={"at-profile"}>
        <div className={"at-form__content at-profile__agent-cont"}>
          <img
            className="at-card--profile_card-img"
            alt="avatar"
            src={
              avatar_url && !_.includes(avatar_url, "missing.png")
                ? avatar_url
                : defaultAvatar
            }
          />
          <div className="at-profile__agent-info">
            <h4>{display_name}</h4>
            <p>{full_name}</p>
            <p>
              <FaUserTie />
              {job_title_display_name}
            </p>
            {_.includes(
              ["Personal Assistant", "Agent", "Associated Agent"],
              role
            ) && (
              <p>
                <RiGroup2Fill />
                {team_name} Team
              </p>
            )}
          </div>
          <div className="at-profile__agent-contact">
            {wechat_id && (
              <div className="at-profile__agent-wechat">
                <AtlasIcon
                  svgHref={"atlas-wechat"}
                  style={{ fill: "#12B76A" }}
                />
                <span>{wechat_id}</span>
              </div>
            )}
            {facebook_url && (
              <div onClick={() => window.open(facebook_url, "__blank")}>
                <AtlasIcon
                  svgHref={"atlas-facebook"}
                  style={{ fill: "#3B82F6" }}
                />
                <span>{facebook_url}</span>
              </div>
            )}
            {telegram_url && (
              <div onClick={() => window.open(telegram_url, "__blank")}>
                <FaTelegram style={{ color: "#25A3E0" }} />
                <span>{telegram_url}</span>
              </div>
            )}
            {instagram_url && (
              <div onClick={() => window.open(instagram_url, "__blank")}>
                <img
                  src={Instagram}
                  alt="instagram"
                  style={{ width: 20, height: 20, minWidth: 20 }}
                />
                <span>{instagram_url}</span>
              </div>
            )}
            {youtube_playlist_url && (
              <div onClick={() => window.open(youtube_playlist_url, "__blank")}>
                <FaYoutube style={{ color: "#F04438" }} />
                <span>Play on Youtube</span>
              </div>
            )}
          </div>
        </div>
        {role === "Agent" && (
          <button
            className="btn-new at-profile__agent-action"
            onClick={() => onChangeProfileHOC(true, "showNameCardUpdate")}
          >
            <AtlasIcon
              svgHref={"atlas-card"}
              style={{ backgroundColor: "#6875F5" }}
            />
            Name Card
          </button>
        )}
        {(role === "Agent" || role === "Associated Agent") && (
          <button
            className="btn-new at-profile__agent-action"
            onClick={() => onChangeProfileHOC(true, "showReferalAgent")}
          >
            <AtlasIcon
              svgHref={"atlas-shake-hand"}
              style={{ backgroundColor: "#F0631D" }}
            />
            My Referral Link
          </button>
        )}
        {role === "Agent" && (
          <button
            className="btn-new at-profile__agent-action"
            onClick={() => checkDigitalSign()}
          >
            <IoIosPaper style={{ backgroundColor: "#06B6D4" }} />
            Appointment Letter
          </button>
        )}
        <button
          className="btn-new at-profile__agent-action"
          onClick={() => onChangeProfileHOC(true, "showIQIID")}
        >
          <AiFillIdcard style={{ backgroundColor: "#F79009" }} />
          My IQI ID
        </button>
        {show_public_qr && (
          <button
            className="btn-new at-profile__agent-action"
            onClick={() => onChangeProfileHOC(true, "showAgentQR")}
          >
            <RiQrCodeLine style={{ backgroundColor: "#3B82F6" }} />
            My Public Profile
          </button>
        )}
        {country_id === 1 && (
          <button
            className="btn-new at-profile__agent-action"
            onClick={() => onChangeProfileHOC(true, "showDownloadModal")}
          >
            <AtlasIcon
              svgHref={"atlas-tax"}
              style={{ backgroundColor: "#8B5CF6" }}
            />
            CP58
          </button>
        )}
        <div className="at-profile__agent-details">
          <UserInfoList
            history={history}
            selectedAgent={selectedAgent}
            onChangeProfileHOC={onChangeProfileHOC}
          />
        </div>
      </div>
      {showDownloadModal && (
        <DownloadCP58Content
          cp58Year={cp58Year}
          cp58List={cp58List}
          onLoadAppointmentLetter={onLoadAppointmentLetter}
          getCP58={getCP58}
          downloadCP58={downloadCP58}
          onChangeProfileHOC={onChangeProfileHOC}
          onClose={() => onChangeProfileHOC(false, "showDownloadModal")}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(ProfileCard);
