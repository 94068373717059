import React from "react";
import { MdClose } from "react-icons/md";

import CustomButton from "components/Button";
import CustomCheckbox from "components/Checkbox";

const CustomImage = ({
  tooltipID,
  onClickShowImage,
  onClickRemoveImage,
  imgSrc,
  checked,
  showRemoveButton,
  showCheckBox,
  tooltipChildren,
  onClickSetPrimary,
  onClickSetPrivate,
  onClickSetPublish,
  onChangeChecbox,
  showPrimaryLabel,
  showSetPrimaryButton,
  showSetPublishButton,
  isPrimary,
  isPrivate,
  imgStyle,
}) => {
  return (
    <div
      className="project-detail__edit-image-item mb-10"
      style={{ backgroundColor: "#1F2937", minWidth: 250 }}
    >
      <div className="d-flex flex-wrap align-items-center">
        {showCheckBox && (
          <CustomCheckbox
            labelClassname={"mr-auto"}
            checked={checked}
            onChangeCheckboxValue={onChangeChecbox}
            content={
              isPrimary &&
              showPrimaryLabel && (
                <h5
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: "white",
                    marginRight: 5,
                    padding: "2px 8px",
                    borderRadius: 4,
                    backgroundColor: "#EA580C",
                  }}
                >
                  Primary
                </h5>
              )
            }
          />
        )}
        {!isPrimary && showSetPrimaryButton && (
          <>
            <CustomButton
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "white",
                marginRight: 5,
                padding: "2px 8px",
                borderRadius: 4,
                backgroundColor: "#EA580C",
              }}
              onClick={onClickSetPrimary}
              children={"Set as primary"}
            />
            <CustomButton
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "white",
                marginRight: 5,
                padding: "2px 8px",
                borderRadius: 4,
                backgroundColor: "#FF0063",
              }}
              onClick={onClickSetPrivate}
              children={"Unpublish"}
            />
          </>
        )}
        {isPrivate && showSetPublishButton && (
          <CustomButton
            style={{
              fontSize: 12,
              fontWeight: 500,
              color: "white",
              marginRight: 5,
              padding: "2px 8px",
              borderRadius: 4,
              backgroundColor: "#3B82F6",
            }}
            onClick={onClickSetPublish}
            children={"Publish"}
          />
        )}
      </div>
      <div className="at-subsales-listing__photo">
        {showRemoveButton && (
          <CustomButton
            children={<MdClose />}
            tooltip={true}
            tooltipPosition={"top"}
            tooltipID={tooltipID}
            tooltipChildren={tooltipChildren}
            onClick={onClickRemoveImage}
          />
        )}
        <img
          onClick={() => onClickShowImage(imgSrc)}
          alt={tooltipID}
          src={imgSrc}
          style={imgStyle}
          className={"project-detail-image"}
        />
      </div>
    </div>
  );
};

export default CustomImage;
