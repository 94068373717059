import React, { useEffect, useState } from "react";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";
import { Card, Dialog } from "@material-ui/core";

import Tabs from "components/Tab";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import AttendeeList from "../components/attendeeList";
import ConfirmationModal from "components/Modal/confirmation";
import CreateTicketPricingModal from "./createTicketPricing";
import UpdateTicketPricingModal from "./updateTicketPricing";
import AttachJointTicketModal from "./attachJointTicket";
import CreateTicketTypeModal from "./createTicketType";
import UpdateTicketTypeModal from "./updateTicketType";
import TicketManagement from "./ticketManagement";
import EventDetailsContent from "./eventsDetails";
import ZoomSessionContent from "./zoomSession";
import AttachmentContent from "./attachments";
import EventTrainingForm from "./form";
import TncModal from "./tncModal";
import QRCode from "./qrCode";

import EditHOC from "../actions/editEvent";

const tabOptions = [
  "Update Event",
  "Event Details",
  "Attachments",
  "Zoom Meeting",
  "Attendees",
];

const EditDialogContent = ({
  data,
  eventQr,
  toBeDeletedId,
  selectedTicketPricing,
  availableJointTickets,
  tncText,
  attachments,
  ticketTypeList,
  priceManagementList,
  jointTicketsList,
  showCreateTicketType,
  showUpdateTicketType,
  showCreateTicketPricing,
  showUpdateTicketPricing,
  showAttachTicketPricing,
  showDeleteAttachedTicketConfirmation,
  showDeletePricingTicketConfirmation,
  showDeleteTicketTypeConfirmation,
  showTncPreview,
  showTncEditor,
  selectedTicketTypeDetail,
  onChangeFieldEdit,
  onChangeEditEventHOC,
  onLoadEventTraining,
  onChangeEventHOC,
  branchRegions,
  onChangeFileEdit,
  onLoadExportUnitAdmin,
  selectedEvent,
  updateEvent,
  disapproveClick,
  onLoadEditEvent,
  getEventQrCode,
  getSelectedEvent,
  getAttachments,
  getTicketTypeDetail,
  createTicketType,
  updateTicketType,
  deleteSelectedTicketType,
  createTicketPricing,
  updateTicketPricing,
  deleteTicketPricing,
  getAvailableJointTickets,
  attachJointTicket,
  deletedAttachedJointTicket,
}) => {
  const [tabSections, onChangeTabSections] = useState(tabOptions);
  const [selectedSection, onChangeSection] = useState("Update Event");

  useEffect(() => {
    let temp = _.cloneDeep(tabOptions);
    if (selectedEvent.is_eligible_for_ims) {
      temp.push("QR Code");
    }
    if (selectedEvent.is_payment_needed) {
      temp.splice(1, 0, "Ticket Pricing");
    }
    onChangeTabSections(temp);
  }, [selectedEvent]);

  const onToggleSubmit = () => {
    let temp = _.cloneDeep(selectedEvent);
    temp.cut_off_date = temp.cut_off_date
      ? Moment(temp.cut_off_date).format("YYYY-MM-DD")
      : "";
    updateEvent(temp);
  };

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton
          onClick={() => onChangeEventHOC(false, "showEditEventDialog")}
        />
        <h4 className="at-card__title">{"Edit Event"}</h4>
      </div>
      <Tabs
        sections={tabSections}
        selectedSection={selectedSection}
        onSelectSection={(val) => onChangeSection(val)}
      />
      <Card
        classes={{ root: "mx-n3 mx-sm-0" }}
        style={{ padding: 16, marginBottom: 20 }}
      >
        {selectedSection === "Update Event" && (
          <EventTrainingForm
            mode={"edit"}
            data={data}
            submitEventForm={() => onToggleSubmit()}
            type_id={selectedEvent.type_id}
            branchRegions={branchRegions}
            onChangeFile={onChangeFileEdit}
            onChangeField={onChangeFieldEdit}
            getSelectedEvent={getSelectedEvent}
            onLoadExportUnitAdmin={onLoadExportUnitAdmin}
            onChangeEditEventHOC={onChangeEditEventHOC}
            onClose={() => onChangeEventHOC(false, "showEditEventDialog")}
            eventDetails={selectedEvent}
          />
        )}
        {selectedSection === "Event Details" && (
          <EventDetailsContent
            branchRegions={branchRegions}
            selectedEvent={selectedEvent}
            disapproveClick={disapproveClick}
            getSelectedEvent={getSelectedEvent}
            onChangeEventHOC={onChangeEventHOC}
          />
        )}
        {selectedSection === "Ticket Pricing" && (
          <TicketManagement
            data={data}
            ticketTypeList={ticketTypeList}
            getTicketTypeDetail={getTicketTypeDetail}
            onChangeEditEventHOC={onChangeEditEventHOC}
          />
        )}
        {selectedSection === "Attachments" && (
          <AttachmentContent
            attachments={attachments}
            selectedEventId={selectedEvent.id}
            getAttachments={getAttachments}
          />
        )}
        {selectedSection === "Zoom Meeting" && (
          <ZoomSessionContent
            data={data}
            zoomTabMode={"edit"}
            selectedEvent={selectedEvent}
            getSelectedEvent={getSelectedEvent}
          />
        )}
        {selectedSection === "Attendees" && (
          <AttendeeList
            getSelectedEvent={getSelectedEvent}
            selectedEvent={selectedEvent}
          />
        )}
        {selectedSection === "QR Code" && (
          <QRCode
            eventQr={eventQr}
            selectedEvent={selectedEvent}
            getEventQrCode={getEventQrCode}
          />
        )}
      </Card>
      {showCreateTicketType && (
        <CreateTicketTypeModal
          createTicketType={createTicketType}
          onLoadTicketType={onLoadEventTraining || onLoadEditEvent}
          onClose={() => onChangeEditEventHOC(false, "showCreateTicketType")}
        />
      )}
      {showUpdateTicketType && (
        <UpdateTicketTypeModal
          data={data}
          onLoad={onLoadEventTraining || onLoadEditEvent}
          priceManagementList={priceManagementList}
          jointTicketsList={jointTicketsList}
          selectedTicketTypeDetail={selectedTicketTypeDetail}
          deleteTicketPricing={deleteTicketPricing}
          updateTicketType={updateTicketType}
          getAvailableJointTickets={getAvailableJointTickets}
          deletedAttachedJointTicket={deletedAttachedJointTicket}
          onChangeEditEventHOC={onChangeEditEventHOC}
          onClose={() => onChangeEditEventHOC(false, "showUpdateTicketType")}
        />
      )}
      {showCreateTicketPricing && (
        <CreateTicketPricingModal
          data={data}
          onLoad={onLoadEventTraining || onLoadEditEvent}
          createTicketPricing={createTicketPricing}
          onClose={() => onChangeEditEventHOC(false, "showCreateTicketPricing")}
        />
      )}
      {showUpdateTicketPricing && (
        <UpdateTicketPricingModal
          onLoad={onLoadEventTraining || onLoadEditEvent}
          selectedTicketPricing={selectedTicketPricing}
          updateTicketPricing={updateTicketPricing}
          onChange={onChangeEditEventHOC}
          onClose={() => onChangeEditEventHOC(false, "showUpdateTicketPricing")}
        />
      )}
      <AtlasDialog open={showTncPreview}>
        <CustomCard
          className="h-100"
          cardContent={
            <>
              <TncModal
                mode={"preview"}
                selectedEvent={selectedEvent}
                onChange={() => {}}
                onClose={() => onChangeEditEventHOC(false, "showTncPreview")}
              />
              {(onLoadEventTraining || onLoadEditEvent) && <LoadingModal />}
            </>
          }
        />
      </AtlasDialog>
      <AtlasDialog open={showTncEditor}>
        <CustomCard
          className="h-100"
          cardContent={
            <>
              <TncModal
                mode={"edit"}
                selectedEvent={selectedEvent}
                onChange={onChangeEventHOC}
                onClose={() => onChangeEditEventHOC(false, "showTncEditor")}
                onSave={updateEvent}
                onClickPreview={() =>
                  onChangeEditEventHOC(true, "showTncPreview")
                }
              />
              {(onLoadEventTraining || onLoadEditEvent) && <LoadingModal />}
            </>
          }
        />
      </AtlasDialog>
      <AttachJointTicketModal
        attachJointTicket={attachJointTicket}
        selectedTicketPricing={selectedTicketPricing}
        availableJointTickets={availableJointTickets}
        showAttachTicketPricing={showAttachTicketPricing}
        onLoadEventTraining={onLoadEventTraining}
        onLoadEditEvent={onLoadEditEvent}
        onClose={() => onChangeEditEventHOC(false, "showAttachTicketPricing")}
      />
      <ConfirmationModal
        mode={"alert"}
        open={showDeleteTicketTypeConfirmation}
        title={"Delete"}
        message={"Remove Ticket from the type list?"}
        loading={onLoadEventTraining || onLoadEditEvent}
        positiveText={"Yes"}
        positiveAction={() => deleteSelectedTicketType(toBeDeletedId)}
        negativeText={"No"}
        negativeAction={() =>
          onChangeEditEventHOC(false, "showDeleteTicketTypeConfirmation")
        }
      />
      <ConfirmationModal
        mode={"alert"}
        open={showDeletePricingTicketConfirmation}
        title={"Delete"}
        message={"Remove Ticket from the pricing list?"}
        loading={onLoadEventTraining || onLoadEditEvent}
        positiveText={"Yes"}
        positiveAction={() => deleteTicketPricing(toBeDeletedId)}
        negativeText={"No"}
        negativeAction={() =>
          onChangeEditEventHOC(false, "showDeletePricingTicketConfirmation")
        }
      />
      <ConfirmationModal
        mode={"alert"}
        open={showDeleteAttachedTicketConfirmation}
        title={"Delete"}
        message={"Remove Ticket from the joint list?"}
        loading={onLoadEventTraining || onLoadEditEvent}
        positiveText={"Yes"}
        positiveAction={() => deletedAttachedJointTicket(toBeDeletedId)}
        negativeText={"No"}
        negativeAction={() =>
          onChangeEditEventHOC(false, "showDeleteAttachedTicketConfirmation")
        }
      />
      {(onLoadEventTraining || onLoadEditEvent) && (
        <Dialog
          classes={{ paper: "at-dialog_paper-card" }}
          className="at-dialog"
          style={{ zIndex: 999 }}
          open={true}
        >
          <LoadingModal />
        </Dialog>
      )}
    </>
  );
};

export default compose(EditHOC)(EditDialogContent);
