import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { MdFilterList } from "react-icons/md";
import { withStyles } from "@material-ui/core/styles";

import SVGIcon from "components/Icon";
import SvgIcon from "components/Icon/new";
import AlertBox from "components/AlertBox";
import CustomButton from "components/Button";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasCloseButton from "components/Button/prev";
import TableSearchParams from "./SearchParams";
import TablePagination from "./Pagination";
import TableContent from "./Content";
import TableHeader from "./Header";

import "stylesheets/components/table/index.scss";
import "stylesheets/components/pagination/index.scss";

// const sampleAction = [
//   {
//     name: 'details',
//     disabled: rowData => {},
//     iconStyle: {},
//     iconClass: '',
//     onClick: rowData => {}
//   }
// ]

// const sampleColumn = {
//   header: 'Name',
//   accessor: 'name',
//   columnStyle: {},
//   contentClass: '',
//   renderColumn: rowData => {}
// },

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const PaginatedTable = ({
  meta,
  rowData: initialRowData,
  columns,
  searchParams,
  getListAPI,
  totalPages,
  helpText,
  actionColumnContent,
  enableSort,
  hideHeader,
  headerClass,
  wrapperClassName = "",
  showResetButton,
  customEmptyState,

  tableTitle,
  showPABadge,
  showCreateButton,
  showCloseButton,
  otherActions,
  clearFilterAction,
  createTooltipContent,
  disabledCreateButton,
  renderCheckBoxActions,
  renderExtraContent,

  onClickCreate,
  onClickClose,
  onChangeSearchParams,
}) => {
  const [maxActionLength, onSetMaxActionLength] = useState(0);
  const [showSearchModal, onToggleSearchModal] = useState(false);
  const [rowData, setRowData] = useState(initialRowData);

  const isSearching = searchParams.some((item) => item.param);

  useEffect(() => {
    if(initialRowData && initialRowData.length > 0) {
      setRowData(initialRowData);
      let tmpMaxLength = 0
      initialRowData?.forEach(rowData => {
        let tmpLength = actionColumnContent?.filter(action => (!action?.onShow || (action?.onShow && action?.onShow(rowData))))?.length
  
        if( tmpMaxLength < tmpLength ) {
          tmpMaxLength = tmpLength
        }
      })
      onSetMaxActionLength(tmpMaxLength)
    }
  }, [initialRowData]);
  
  useEffect(() => {
    setRowData(initialRowData)
  }, [initialRowData]);

  const onClickRearrangeData = useCallback(
    (val) => {
      if (val.columnValue && val.mode) {
        let tempOrder = _.orderBy(
          rowData,
          (o) => {
            let columnData = _.get(o, val.columnValue);
            if (columnData !== undefined && columnData !== null) {
              if (columnData !== "Infinity" && !isNaN(columnData)) {
                return Number(columnData);
              } else if (columnData.match(/^(\d{2})-(\d{2})-(\d{4})$/)) {
                let dateString = columnData.split("-");
                return new Date(dateString[2], dateString[1], dateString[0]);
              } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                return new Date(columnData);
              } else {
                return columnData.toLowerCase();
              }
            }
          },
          val.mode
        );
        setRowData(tempOrder);
      } else {
        setRowData(initialRowData);
      }
    },
    [rowData]
  );

  return (
    <>
      <div className={`at-table__options ${wrapperClassName}`}>
        {(tableTitle ||
          showCreateButton ||
          showCloseButton ||
          (otherActions && otherActions.length > 0)) && (
          <>
            <div className="at-card__header">
              {showCloseButton && (
                <AtlasCloseButton
                  containerClass={"btn-table-back-previous-screen"}
                  onClick={onClickClose}
                />
              )}
              <h4 className="at-card__title">{tableTitle}</h4>
              {showPABadge && (
                <DarkTooltip
                  placement={"bottom"}
                  title={`You are acting on behalf of your superior in this section.`}
                >
                  <div>
                    <SVGIcon
                      style={{
                        fill: "#f18e03",
                        width: 26,
                        marginRight: 5,
                        height: 30,
                      }}
                      svgHref={"icon-superior"}
                    />
                  </div>
                </DarkTooltip>
              )}
              {(showCreateButton || otherActions) && (
                <div className="d-flex">
                  {showCreateButton && (
                    <CustomButton
                      containerStyle={{ marginLeft: "auto" }}
                      className={"btn-new btn-new--primary"}
                      onClick={onClickCreate}
                      disabled={disabledCreateButton}
                      children={
                        <>
                          <SvgIcon
                            svgHref="confirmation-form_add"
                            style={{ width: 16, height: 16, marginRight: 6 }}
                          />
                          {createTooltipContent}
                        </>
                      }
                      tooltip={true}
                      tooltipPosition={"bottom"}
                      tooltipID={"create"}
                      tooltipChildren={createTooltipContent}
                    />
                  )}
                  {otherActions &&
                    otherActions.map((button, index) => (
                      <CustomButton
                        key={index}
                        style={{ ...button.style }}
                        containerStyle={{
                          display: "inline",
                          ...(button?.containerStyle || {}),
                        }}
                        onClick={button.onClick}
                        isIconButton={button.isIconButton}
                        className={`${button.className || ""} d-flex`}
                        children={button.content}
                        tooltip={button.showTooltip}
                        tooltipPosition={"bottom"}
                        tooltipID={`other-action-${index}`}
                        tooltipChildren={button.tooltipContent}
                        additionalContent={button.additionalContent}
                      />
                    ))}
                </div>
              )}
              {renderExtraContent && renderExtraContent()}
            </div>
            {helpText && (
              <div className="mt-2">
                <p className={"at-card__description"}>{helpText}</p>
              </div>
            )}
          </>
        )}
        {tableTitle === "Leads" && (
          <AlertBox
            mode={"info"}
            description={
              <>
                When you have a NEW Recruitment Lead, please follow up with the
                lead as soon as possible.
                <br />
                After the follow-up, please change the Lead's STATUS
                accordingly.
              </>
            }
          />
        )}
        {searchParams.length > 0 && (
          <div className="mb-3">
            <button
              className={
                "btn-new btn-new--outline-secondary at-mobile-view__controller"
              }
              style={{ width: "100%" }}
              onClick={() => onToggleSearchModal(!showSearchModal)}
            >
              <MdFilterList style={{ width: 20 }} />
              <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
                Filter & Search
              </h5>
            </button>
            <div className="at-table__search-cont at-desktop-view__controller">
              <TableSearchParams
                mode={"desktop"}
                showResetButton={showResetButton}
                searchParams={searchParams}
                onChangeSearchParams={onChangeSearchParams}
                getListAPI={getListAPI}
              />
            </div>
          </div>
        )}
        {renderExtraContent && renderExtraContent() }
        <div className="at-table at-table--set">
          {renderCheckBoxActions && (
            <>
              <div className="at-table-head">{renderCheckBoxActions()}</div>
              <div className="at-mobile-view__controller">
                {renderCheckBoxActions()}
              </div>
            </>
          )}
          {/* content header */}
          {!hideHeader && (
            <TableHeader
              columns={columns}
              rowData={rowData}
              maxActionLength={maxActionLength}
              initialRowData={initialRowData}
              enableSort={enableSort}
              headerClass={headerClass}
              actionColumnContent={actionColumnContent}
              onClickRearrangeData={onClickRearrangeData}
            />
          )}
          {/* content section */}
          {(rowData && rowData?.length > 0) && rowData?.map((item, index) => (
            <TableContent
              key={index}
              maxActionLength={maxActionLength}
              columns={columns}
              index={index}
              actionColumnContent={actionColumnContent}
              rowData={item}
            />
          ))}
          {!(rowData && rowData?.length > 0) && isSearching && (
            <div className="at-empty-state">
              <ul className="at-table-row grid-controller">
                <li className="at-table-row__item at-table-row__item-empty">
                  <p className="mx-auto">No result found</p>
                  <span>
                    Try to adjust your search or filter options to find
                    something you're looking for
                  </span>
                  <br />
                  {clearFilterAction && (
                    <button onClick={() => clearFilterAction()}>
                      Clear Filters
                    </button>
                  )}
                </li>
              </ul>
            </div>
          )}
          {!(rowData && rowData?.length > 0) && !isSearching && (
            <div className={"at-table__empty-state"}>
              <EmptyState
                renderIcon={
                  customEmptyState?.icon || (
                    <AtlasIcon
                      svgHref={`atlas-${customEmptyState?.icon || "document-text"}`}
                    />
                  )
                }
                title={customEmptyState?.title || "You don't have any items"}
                description={customEmptyState?.description || ""}
              />
            </div>
          )}
        </div>
        {meta && meta?.count > 0 && (
          <div className="at-pagination__cont">
            <TablePagination
              meta={meta}
              searchParams={searchParams}
              getListAPI={getListAPI}
              totalPages={totalPages}
            />
            <p className={`at-table__pagination-info`}>
              Showing
              <span> {meta && meta.from} </span>-
              <span> {meta && meta.to} </span>
              of
              <span> {meta && meta.count} </span>
              results
            </p>
          </div>
        )}
      </div>
      {showSearchModal && (
        <TableSearchParams
          mode={"mobile"}
          searchParams={searchParams}
          showResetButton={showResetButton}
          onToggleSearchModal={onToggleSearchModal}
          onChangeSearchParams={onChangeSearchParams}
          getListAPI={getListAPI}
        />
      )}
    </>
  );
};

export default PaginatedTable;