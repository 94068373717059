import Axios from "axios";

import { CURRENT_USER_PROFILE, UPDATE_CURRENT_USER_PROFILE } from "./type";
import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./ajax";
import getDomainURL from "utils/getDomainURL";
import { setLanguage } from "../reducers/language";

export function getCurrentUserProfile() {
  return (dispatch) => {
    dispatch(beginAjaxCall(true));
    Axios.get(`${getDomainURL()}/profiles/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${window.localStorage.getItem("IQI_ATLAS_JWT_TOKEN")}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        dispatch(storeLanguage(response.data.country_id));
        dispatch(getCurrentUserProfileSuccess(response.data));
        dispatch(ajaxCallSuccess());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error, () => dispatch(getCurrentUserProfile())));
      });
  };
}

function storeLanguage (country_id) {
  switch (country_id) {
    case 14:
      return setLanguage('jp');
    default:
      return setLanguage('en');
  }
}

function getCurrentUserProfileSuccess(payload) {
  return {
    type: CURRENT_USER_PROFILE,
    payload,
  };
}

export function updateCurrentUserProfile(params) {
  let user = new FormData();
  if (params) {
    Object.keys(params).map((key) => {
      if (typeof params[key] === "object") {
        Object.keys(params[key]).map((childObj) => {
          user.append(`${key}[${childObj}]`, params[key][childObj]);
        });
      } else {
        user.append(`${key}`, params[key]);
      }
    });
  }
  return (dispatch) => {
    dispatch(beginAjaxCall(true, true));
    Axios.put(`${getDomainURL()}/profiles/me`, user)
      .then((response) => {
        dispatch(storeLanguage(response.data.country_id));
        dispatch(updateCurrentProfileSuccess(response.data));
        dispatch(ajaxCallSuccess());
      })
      .catch((error) => {
        dispatch(
          ajaxCallError(error, () =>
            dispatch(updateCurrentUserProfile(params)),
          ),
        );
      });
  };
}

function updateCurrentProfileSuccess(payload) {
  return {
    type: UPDATE_CURRENT_USER_PROFILE,
    payload,
  };
}
