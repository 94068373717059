import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";

import AtlasButton from "components/Button";
import Spinner from "components/LoadingModal";
import DeleteConfirmationDialog from "components/Modal/delete";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateEmailList from "./Create";
import UpdateEmailList from "./Update";

import withEmailLists from "containers/Dashboard/EmailCampaigns/actions/lists";
import { requestError } from "utils/requestHandler";
import { columnData } from "./assets";

const To = ({
  isActive,
  handleAccordionChange,
  handleAccordionClose,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  onLoadEmailLists,
  emailLists,
  emailListsPages,
  searchParams,
  selectedEmailList,
  teamsList,
  countriesList,
  branchesList,
  emailListDialog,
  onChangeEmailListsHOC,
  getEmailLists,
  getSelectedEmailList,
  getTeamsList,
  getCountriesList,
  getBranchesList,
  checkEmailList,
  createEmailList,
  updateEmailList,
  deleteEmailList,
}) => {
  const [checkedArray, setCheckedArray] = useState([]);

  useEffect(() => {
    getEmailLists();
  }, []);

  useEffect(() => {
    if (
      selectedEmailCampaign.email_list_ids &&
      selectedEmailCampaign.email_list_ids.length > 0 &&
      emailLists.data &&
      emailLists.data.length > 0
    ) {
      setCheckedArray(
        emailLists.data
          .filter((list) =>
            selectedEmailCampaign.email_list_ids.includes(list.id),
          )
          .map((list) => list.id),
      );
    } else {
      setCheckedArray([]);
    }
  }, [selectedEmailCampaign, emailLists]);

  const isChecked = selectedEmailCampaign.email_list_ids;

  const handleClose = () => {
    if (
      selectedEmailCampaign.email_list_ids &&
      selectedEmailCampaign.email_list_ids.length > 0 &&
      emailLists.data &&
      emailLists.data.length > 0
    ) {
      setCheckedArray(
        emailLists.data
          .filter((list) =>
            selectedEmailCampaign.email_list_ids.includes(list.id),
          )
          .map((list) => list.id),
      );
    } else {
      setCheckedArray([]);
    }

    handleAccordionClose();
  };

  const onHandleCheckAllBox = () => {
    if (checkedArray.length < emailLists.data.length) {
      setCheckedArray(emailLists.data.map((list) => list.id));
    } else {
      setCheckedArray([]);
    }
  };

  const onHandleCheckBox = (id) => {
    if (checkedArray.includes(id)) {
      setCheckedArray(checkedArray.filter((item) => item !== id));
    } else {
      setCheckedArray([...checkedArray, id]);
    }
  };

  const handleSave = () => {
    if (checkedArray.length === 0) {
      requestError("Select at least one email list");
    } else {
      const newEmailCampaign = {
        ...selectedEmailCampaign,
        email_list_ids: checkedArray,
        status: 1,
      };

      onChangeEmailCampaignsHOC("selectedEmailCampaign", newEmailCampaign);
      updateEmailCampaign(newEmailCampaign);
      handleAccordionClose();
    }
  };

  const renderModuleContent = () => (
    <AtPaginatedTable
      columns={columnData({
        checkedArray,
        emailListsData: emailLists.data || [],
        onHandleCheckBox,
        onHandleCheckAllBox,
      })}
      getListAPI={getEmailLists}
      rowData={emailLists.data}
      meta={emailLists.meta}
      totalPages={emailListsPages}
      searchParams={searchParams}
      onChangeSearchParams={(val) => onChangeEmailListsHOC("searchParams", val)}
      actionColumnContent={[
        {
          name: "edit",
          onClick: (rowData) => {
            getSelectedEmailList(rowData.id);
            onChangeEmailListsHOC("emailListDialog", "edit");
          },
        },
        {
          color: "#F04438",
          onClick: (rowData) => {
            onChangeEmailListsHOC("selectedId", rowData.id);
            onChangeEmailListsHOC("emailListDialog", "delete");
          },
        },
      ]}
    />
  );

  const renderCreateDialog = () => (
    <CreateEmailList
      onChangeEmailListsHOC={onChangeEmailListsHOC}
      teamsList={teamsList}
      countriesList={countriesList}
      branchesList={branchesList}
      getTeamsList={getTeamsList}
      getCountriesList={getCountriesList}
      getBranchesList={getBranchesList}
      checkEmailList={checkEmailList}
      createEmailList={createEmailList}
    />
  );

  const renderUpdateDialog = () => (
    <UpdateEmailList
      onChangeEmailListsHOC={onChangeEmailListsHOC}
      selectedEmailList={selectedEmailList}
      teamsList={teamsList}
      countriesList={countriesList}
      branchesList={branchesList}
      getTeamsList={getTeamsList}
      getCountriesList={getCountriesList}
      getBranchesList={getBranchesList}
      checkEmailList={checkEmailList}
      updateEmailList={updateEmailList}
    />
  );

  const renderDeleteDialog = () => (
    <DeleteConfirmationDialog
      open={emailListDialog === "delete"}
      title={"Delete email list"}
      message={"Are you sure you want to delete this list?"}
      negativeAction={() => onChangeEmailListsHOC("emailListDialog", "")}
      positiveText={"Yes"}
      positiveAction={deleteEmailList}
    />
  );

  return (
    <ExpansionPanel
      square={true}
      expanded={isActive}
      className={"expansion-panel"}
    >
      <ExpansionPanelSummary
        classes={{
          root: "expansion-panel-summary",
          content: "expansion-panel-summary-content",
        }}
      >
        <div className={"expansion-panel-summary-wrapper"}>
          <FaCheckCircle
            className={`check-icon${isChecked ? " is-checked" : ""}`}
          />

          <div>
            <h4 className={"title mb-3"}>To</h4>

            {isChecked && (
              <p>
                <strong>
                  {selectedEmailCampaign.email_list_ids &&
                    selectedEmailCampaign.email_list_ids.length}
                </strong>{" "}
                <span>
                  list
                  {selectedEmailCampaign.email_list_ids &&
                  selectedEmailCampaign.email_list_ids.length === 1
                    ? ""
                    : "s"}
                </span>
              </p>
            )}
            {!isChecked && (
              <small className={"text-muted"}>
                Choose your contact list(s)
              </small>
            )}
          </div>

          {isActive && (
            <AtlasButton
              type={"button"}
              className={"close"}
              onClick={handleClose}
            >
              &times;
            </AtlasButton>
          )}
          {!isActive && (
            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={handleAccordionChange("to")}
              tooltip={true}
              tooltipID={`${isChecked ? "Edit" : "Add"} lists`}
              tooltipPosition={"top"}
              tooltipChildren={`${isChecked ? "Edit" : "Add"} lists`}
            >
              <span className={"button-text"}>
                {isChecked ? "Edit" : "Add"} lists
              </span>

              <BsPencilSquare className={"button-icon"} />
            </AtlasButton>
          )}
        </div>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={"expansion-panel-details__to"}>
        <div>
          <div className={"mb-3"}>
            <div className={"d-flex align-items-center mb-2"}>
              <h2 className={"at-form-input__title text-capitalize mr-4"}>
                Send to
              </h2>

              <AtlasButton
                type={"button"}
                className={"btn-new btn-new--primary"}
                onClick={() =>
                  onChangeEmailListsHOC("emailListDialog", "create")
                }
              >
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
                New Email List
              </AtlasButton>
            </div>

            {renderModuleContent()}
          </div>

          <h4 className={"title mb-4"}>
            {checkedArray.length} list{checkedArray.length === 1 ? "" : "s"}
          </h4>

          <div className={"d-flex g-3"}>
            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--success"}
              onClick={handleSave}
              disabled={checkedArray.length === 0}
            >
              Save
            </AtlasButton>

            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--outline-secondary"}
              onClick={handleClose}
            >
              Cancel
            </AtlasButton>
          </div>
        </div>
      </ExpansionPanelDetails>

      {emailListDialog === "create" && renderCreateDialog()}
      {emailListDialog === "edit" && renderUpdateDialog()}
      {renderDeleteDialog()}
      {onLoadEmailLists && <Spinner />}
    </ExpansionPanel>
  );
};

export default withEmailLists(To);
