import React, { Component, useState } from "react";
import FileSaver from "file-saver";
import { HiShare } from "react-icons/hi";

import ShareModal from "components/Share";
import CustomButton from "components/Button";
import ConfirmDeleteModal from "components/Modal/delete";
import LoadingModal from "components/LoadingModal";
import AtlasTable from "components/NewTable";
import ModalDialog from "components/Modal/ModalDialog";
import AttachmentCreateUpdateModal from "./attachmentCreateUpdate";

import permissionsChecker from "utils/permissionsChecker";
import AttachmentHOC from "../actions/attachments";
import "stylesheets/components/calendar/index.scss";

const Attachments = ({
  data,
  attachments,
  selectedAttachment,
  showConfirmDeleteDialog,
  showViewImage,
  showShareModal,
  showCreateModal,
  showEditModal,
  onLoadAttachment,
  deleteAttachment,
  createAttachment,
  updateAttachment,
  regenerateQrCode,
  onChangeAttachmentHOC,
}) => {
  const [toRemoveItemID, setToRemoveItemID] = useState("");

  const { can_create, can_update, can_destroy } = permissionsChecker(
    "Company Events",
    data,
  );

  return (
    <>
      {can_create && (
        <CustomButton
          className="btn-new btn-new--primary mb-3"
          children={"New Attachment"}
          onClick={() => onChangeAttachmentHOC(true, "showCreateModal")}
        />
      )}
      <AtlasTable
        hideSearch={true}
        className={"mb-100"}
        columns={[
          {
            header: "Title",
            accessor: "title",
            searchFlag: "title",
            columnStyle: { width: "70%" },
          },
        ]}
        actionColumnContent={[
          {
            name: "view",
            onClick: (rowData) => window.open(rowData.image_url, "_blank"),
          },
          {
            name: "preview",
            tooltipContent: "QR Code",
            onClick: (rowData) => {
              Promise.all([
                onChangeAttachmentHOC(rowData, "selectedAttachment"),
              ]).then(() => {
                onChangeAttachmentHOC(true, "showViewImage");
              });
            },
          },
          {
            name: "edit",
            onShow: () => false,
            onClick: (rowData) => onChangeAttachmentHOC(true, "showEditModal"),
          },
          {
            name: "delete",
            color: "#F04438",
            onShow: () => can_destroy,
            onClick: (rowData) => {
              onChangeAttachmentHOC(true, "showConfirmDeleteDialog");
              setToRemoveItemID(rowData.id);
            },
          },
        ]}
        rowData={attachments.data || []}
        pagination={true}
      />
      {showViewImage && (
        <ModalDialog
          title={"QR Code"}
          onClose={() => onChangeAttachmentHOC(false, "showViewImage")}
          children={
            <div className="d-flex flex-column align-items-center">
              <div className="position-relative">
                <CustomButton
                  tooltipPopperClass="at-event-attachment-share_btn-tooltip_popper"
                  tooltipClass="at-event-attachment-share_btn-tooltip"
                  tooltip={true}
                  tooltipChildren="Share"
                  isIconButton={true}
                  className="at-event-attachment-share_btn mb-4"
                  onClick={() => onChangeAttachmentHOC(true, "showShareModal")}
                  children={
                    <HiShare
                      style={{
                        width: 35,
                        height: 35,
                        color: "#007bff",
                        marginRight: "0.2rem",
                      }}
                    />
                  }
                />
                <img
                  className="mb-3"
                  src={selectedAttachment.qr_code_image_url}
                />
              </div>
              {can_update && (
                <CustomButton
                  className="btn-new btn-new--secondary mb-4"
                  children={"Re-generate QR Code"}
                  onClick={() => regenerateQrCode(selectedAttachment.id)}
                />
              )}
              {onLoadAttachment && <LoadingModal />}
            </div>
          }
        />
      )}
      {showCreateModal && (
        <AttachmentCreateUpdateModal
          mode={"create"}
          createAttachment={createAttachment}
          onChangeAttachmentHOC={onChangeAttachmentHOC}
          onClose={() => onChangeAttachmentHOC(false, "showCreateModal")}
          onLoad={onLoadAttachment}
        />
      )}
      {showEditModal && (
        <AttachmentCreateUpdateModal
          mode={"edit"}
          selectedAttachment={selectedAttachment}
          updateAttachment={updateAttachment}
          onChangeAttachmentHOC={onChangeAttachmentHOC}
          onClose={() => onChangeAttachmentHOC(false, "showEditModal")}
          onLoad={onLoadAttachment}
        />
      )}
      {showShareModal && (
        <ShareModal
          title={"Share"}
          mediaURL={selectedAttachment.qr_code_image_url}
          shareLink={selectedAttachment.qr_code_image_url}
          downloadTooltip={"Download Image"}
          showElement={[
            "Whatsapp",
            "Telegram",
            "Download",
            "Copy Link",
            "Open in New Tab",
          ]}
          downloadTarget={"_self"}
          onClickDownload={() =>
            FileSaver.saveAs(
              selectedAttachment.qr_code_image_url,
              selectedAttachment.title,
            )
          }
          onClickClose={() => onChangeAttachmentHOC(false, "showShareModal")}
        />
      )}
      <ConfirmDeleteModal
        open={showConfirmDeleteDialog}
        loading={onLoadAttachment}
        message={"Are you sure to remove the selected attachment?"}
        positiveAction={() => deleteAttachment(toRemoveItemID)}
        negativeAction={() =>
          onChangeAttachmentHOC(false, "showConfirmDeleteDialog")
        }
      />
      {onLoadAttachment && <LoadingModal />}
    </>
  );
};

export default AttachmentHOC(Attachments);
