import React, { Component, Fragment } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import websocketURL from "utils/websocketURL";
import { Get, Post, Put, GetFile } from "utils/axios";
import { getItem } from "utils/tokenStore";
import { requestError, requestSuccess } from "utils/requestHandler";
import { storeLastView } from "actions/lastView";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      units: [],
      unitsPage: [],
      total_unit: 0,
      available_unit: 0,
      reserved_unit: 0,
      sold: 0,
      my_units: 0,
      searchParams: [
        {
          label: "Unit No",
          value: "unit_no_cont",
          type: "input",
          param: "",
        },
        {
          label: "Floor No",
          value: "floor_no_cont",
          type: "input",
          param: "",
        },
        {
          label: "Price Greater Than",
          value: "price_gt",
          type: "input",
          param: "",
        },
        {
          label: "Price Less Than",
          value: "price_lt",
          type: "input",
          param: "",
        },
        {
          label: "Floor Size Greater Than",
          value: "floor_size_gt",
          type: "input",
          param: "",
        },
        {
          label: "Floor Size Less Than",
          value: "floor_size_lt",
          type: "input",
          param: "",
        },
      ],
      searchString: "",

      showReserveModal: false,
      showUnitDetailModal: false,
      selectedTab: "All",
      selectedReservation: {},
      selectedUnit: {},
      progress: "",
      needReconnect: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeUnitHOC = (context, val) => this.setState({ [context]: val });
    requestInfo = (message) => {
      toast.info(message, {
        position: "top-right",
      });
    };
    requestWarning = (message) => {
      toast.warning(message, {
        position: "bottom-right",
        pauseOnFocusLoss: false,
      });
    };

    getProjectUnits = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      if (!search) {
        this.onChangeUnitHOC("selectedTab", "All");
      }

      Get(
        `/projects/${this.props.selectedProject.id}/project_units?${search}page=${page}`,
        this.getProjectUnitsSuccess,
        this.getProjectUnitsError,
        this.load,
      );
    };
    getProjectUnitsSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        units: payload,
        unitsPage: tmpTotalPages,
      });
    };
    getProjectUnitsError = (error) => requestError(error);

    getReservationForm = (unit_id, form_id, unit_no) =>
      GetFile(
        `/projects/${this.props.selectedProject.id}/project_units/${unit_id}/reservations/${form_id}/download_form`,
        `ProjectReservation_${this.props.selectedProject.internal_name}_${unit_no}.pdf`,
        this.getReservationFormSuccess,
        this.getReservationFormError,
        this.load,
      );
    getReservationFormSuccess = (payload) => {
      let fileURL = URL.createObjectURL(payload.blob);
      let a = document.createElement("a");
      a.href = fileURL;
      a.target = "_blank";
      a.click();
    };
    getReservationFormError = (error) => requestError(error);

    putReservation = (selectedUnit) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      Post(
        `/projects/${this.props.selectedProject.id}/project_units/${selectedUnit.id}/reservations`,
        {
          agent_id: id,
        },
        this.putReservationSuccess,
        this.putReservationError,
        this.load,
      );
    };
    putReservationSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getProjectUnits(currentPage, searchParams);
      if (payload.status === "Pending Approval") {
        this.setState({
          showReserveModal: false,
        });
        this.requestInfo(
          `Request successful. Wait for admin approval to reserve this unit.`,
        );
      } else {
        this.getSelectedUnit(this.state.selectedUnit.id, "reservation");
        this.setState({ progress: "PaymentSlip" });
        this.requestInfo(`You are given 
          ${this.props.selectedProject.reservation_time_limit.settings.payment_slip.time} 
          ${this.props.selectedProject.reservation_time_limit.settings.payment_slip.unit} 
          to upload payment slip to secure the unit (${this.state.selectedUnit.title}).`);
      }
    };
    putReservationError = (error) => {
      this.setState({ showReserveModal: false });
      requestError(error);
    };

    uploadPaymentSlip = (dataToSubmit, selectedUnit) =>
      Put(
        `/projects/${this.props.selectedProject.id}/project_units/${selectedUnit.id}/reservations/${selectedUnit.reservations_attributes.id}`,
        dataToSubmit,
        (payload) => this.uploadPaymentSlipSuccess(payload, selectedUnit),
        this.uploadPaymentSlipError,
        this.load,
      );
    uploadPaymentSlipSuccess = (payload, selectedUnit) => {
      this.setState(
        {
          selectedUnit: {
            ...selectedUnit,
            reservations_attributes: {
              ...selectedUnit.reservations_attributes,
              reservation_time: payload.reservation_date,
            },
          },
        },
        () => {
          this.getSelectedUnit(selectedUnit.id, "reservation");
          this.setState({ progress: "BuyerInfo" });
          this.requestInfo(`You are given 
          ${this.props.selectedProject.reservation_time_limit.settings.buyer_info.time} 
          ${this.props.selectedProject.reservation_time_limit.settings.buyer_info.unit} 
          to get all the information filled to complete reservation for unit (${selectedUnit.title}). Click to fill in reservation form.`);
        },
      );
    };
    uploadPaymentSlipError = (error) => {
      this.setState({ showReserveModal: false });
      requestError(error);
    };

    createReservation = (dataToSubmit, selectedUnit) => {
      let payload = {
        ...dataToSubmit,
        ic_front: dataToSubmit.ic_front.snapshot,
        ic_front_file_name: dataToSubmit.ic_front.snapshot_file_name,
        ic_back: dataToSubmit.ic_back.snapshot,
        ic_back_file_name: dataToSubmit.ic_back.snapshot_file_name,
        ...(dataToSubmit.payment_slip &&
          typeof dataToSubmit.payment_slip === "object" && {
            payment_slip: dataToSubmit.payment_slip
              ? dataToSubmit.payment_slip.snapshot
              : "",
            payment_slip_file_name: dataToSubmit.payment_slip
              ? dataToSubmit.payment_slip.snapshot_file_name
              : "",
          }),
      };
      if (
        this.props.selectedProject.reservation_time_limit &&
        this.props.selectedProject.reservation_time_limit.settings
          .has_time_limit
      ) {
        Put(
          `/projects/${this.props.selectedProject.id}/project_units/${selectedUnit.id}/reservations/${selectedUnit.reservations_attributes.id}`,
          payload,
          this.createReservationSuccess,
          this.createReservationError,
          this.load,
        );
      } else {
        Post(
          `/projects/${this.props.selectedProject.id}/project_units/${selectedUnit.id}/reservations`,
          payload,
          this.createReservationSuccess,
          this.createReservationError,
          this.load,
        );
      }
    };
    createReservationSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getProjectUnits(currentPage, searchParams);
      this.setState({ progress: "Complete" });
      requestSuccess("Buyer information updated.");
    };
    createReservationError = (error) => requestError(error);

    getSelectedUnit = (id, mode) =>
      Get(
        `/projects/${this.props.selectedProject.id}/project_units/${id}`,
        (payload) => this.getSelectedUnitSuccess(payload, mode),
        this.getSelectedUnitError,
        this.load,
      );
    getSelectedUnitSuccess = (payload, mode) => {
      this.setState({ selectedUnit: payload });
      if (mode === "reservation") {
        if (
          this.props.selectedProject.reservation_time_limit &&
          this.props.selectedProject.reservation_time_limit.settings
            .has_time_limit
        ) {
          if (payload.reservations_attributes.status === "Available")
            this.setState({
              showReserveModal: true,
              progress: "Confirmation",
            });
          else if (payload.reservations_attributes.status === "Reserved") {
            if (payload.reservations_attributes.has_submitted_payment)
              this.setState({
                showReserveModal: true,
                progress: "BuyerInfo",
              });
            else
              this.setState({
                showReserveModal: true,
                progress: "PaymentSlip",
              });
          }
        } else {
          this.setState({
            showReserveModal: true,
            progress: "BuyerInfo",
          });
        }
      } else {
        this.setState({ showUnitDetailModal: true });
      }
    };
    getSelectedUnitError = (error) => requestError(error);

    getLiveSales = () =>
      Get(
        `/projects/${this.props.selectedProject.id}/live_sales`,
        this.getLiveSalesSuccess,
        this.getLiveSalesError,
        this.load,
      );
    getLiveSalesSuccess = (payload) => {
      this.setState({
        total_unit: payload.total_units,
        available_unit: payload.available,
        reserved_unit: payload.reserved,
        sold: payload.sold + payload.booked,
        my_units: payload.my_units,
      });
    };
    getLiveSalesError = (error) => requestError(error);

    getReservation = (selectedUnit) =>
      Get(
        `/projects/${this.props.selectedProject.id}/project_units/${selectedUnit.id}/reservations/${selectedUnit.reservations_attributes.id}`,
        this.getReservationSuccess,
        this.getReservationError,
        this.load,
      );
    getReservationSuccess = (payload) =>
      this.setState({ selectedReservation: payload });
    getReservationError = (error) => requestError(error);

    establishConnection = () => {
      let authToken =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
          : getItem("IQI_ATLAS_JWT_TOKEN");
      let webSocket = new WebSocket(
        `${websocketURL()}/cable?auth_token=${authToken}`,
      );

      webSocket.onopen = function () {
        webSocket.send(
          JSON.stringify({
            command: "subscribe",
            identifier: '{"channel":"LiveSalesChannel"}',
          }),
        );
        console.log("web socket open");
      };

      webSocket.onclose = function () {
        console.log("web socket close");
        reconnect();
      };

      webSocket.onmessage = function (event) {
        let message = JSON.parse(event.data).message;
        if (typeof message === "object") {
          processData(message);
        }
      };

      const reconnect = () => this.setState({ needReconnect: true });

      const processData = (message) => {
        let unit = message.unit;
        let projectDetail = message.project_info;
        if (unit.project_id === this.props.selectedProject.id) {
          let unitIndex = _.findIndex(
            this.state.units.data,
            (item) => item.id === unit.id,
          );
          let temp = _.cloneDeep(this.state.units.data);
          if (unitIndex > -1 && temp[unitIndex]) {
            temp[unitIndex] = unit;
            this.setState({
              units: {
                data: temp,
                meta: this.state.units.meta,
              },
            });
          }
          this.requestWarning(
            `${unit.title} has been ${unit.reservations_attributes.status.toLowerCase()}.`,
          );
          this.setState({
            available_unit: projectDetail.available,
            sold: projectDetail.sold + projectDetail.booked,
            reserved_unit: projectDetail.reserved,
          });
        }
      };
    };

    render = () => {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            onLoadUnit={this.state.loading}
            units={this.state.units}
            unitsPage={this.state.unitsPage}
            showReserveModal={this.state.showReserveModal}
            showUnitDetailModal={this.state.showUnitDetailModal}
            selectedUnit={this.state.selectedUnit}
            selectedTab={this.state.selectedTab}
            available_unit={this.state.available_unit}
            reserved_unit={this.state.reserved_unit}
            total_unit={this.state.total_unit}
            sold={this.state.sold}
            my_units={this.state.my_units}
            progress={this.state.progress}
            selectedReservation={this.state.selectedReservation}
            searchParams={this.state.searchParams}
            searchString={this.state.searchString}
            createReservation={this.createReservation}
            putReservation={this.putReservation}
            uploadPaymentSlip={this.uploadPaymentSlip}
            getSelectedUnit={this.getSelectedUnit}
            getReservationForm={this.getReservationForm}
            getReservation={this.getReservation}
            getLiveSales={this.getLiveSales}
            establishConnection={this.establishConnection}
            getProjectUnits={this.getProjectUnits}
            onChangeUnitHOC={this.onChangeUnitHOC}
          />
        </Fragment>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
