import React from "react";

import AtlasButton from "components/Button";
import CustomFormInput from "./formInput";

import { getTranslation } from "../assets";

const VerifyInput = ({
  id = "",
  label,
  labelValue,
  inputLabel,
  value,
  onChange,
  language,
  disabled = false,
  verifyMessage,
  verifyConfig = { onVerify: () => {}, timeoutCounter: 0, onResend: () => {} },
}) => {
  return (
    <>
      <div>
        <label className={"at-profile__title mb-3"} style={{ fontSize: 16 }}>
          <span style={{ fontWeight: 400 }}>
            {label.charAt(0).toUpperCase() + label.slice(1)}:{" "}
          </span>
          {labelValue}
        </label>
      </div>
      <div>
        <p className={"mb-3"} style={{ fontSize: 14 }}>
          {id === "email_tac"
            ? getTranslation("agent.signup.verify_email", language)
            : getTranslation("agent.signup.verify_mobile", language)}
        </p>
      </div>
      <label className={"at-form-input__title mb-3"} style={{ fontSize: 16 }} required>{inputLabel}</label>
      <CustomFormInput
        type={"number"}
        value={value}
        onChange={(val) => onChange(val, id)}
        disabled={disabled}
        successMessage={disabled && verifyMessage}
        errorMessage={!disabled && verifyMessage}
      />
      {!disabled && (
        <div className="d-flex justify-content-between mt-1">
          <AtlasButton
            className={"btn-new btn-new--secondary d-inline-block"}
            onClick={verifyConfig.onVerify}
          >
            {getTranslation("agent.signup.verify_code", language)}
          </AtlasButton>
          <p style={{ color: "#808080", fontSize: 14 }}>
            {verifyConfig.timeoutCounter !== 0 &&
              `${getTranslation('agent.signup.resend_code', language)} ${verifyConfig.timeoutCounter} ${getTranslation('agent.signup.seconds', language)}.`}
            {verifyConfig.timeoutCounter === 0 && (
              <>
                {getTranslation("agent.signup.didnt_get_code", language)}{" "}
                <br />
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={verifyConfig.onResend}
                >
                  {id === "email_tac"
                    ? getTranslation("agent.signup.resend_email", language)
                    : getTranslation("agent.signup.resend_mobile", language)}
                </span>
              </>
            )}
          </p>
        </div>
      )}
    </>
  );
};

export default VerifyInput;
