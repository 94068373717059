import React, { Component } from "react";
import Transition from "react-transition-group/Transition";
import _ from "lodash";
import { FaList } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { BsChatSquareQuoteFill } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";

import SVGIcon from "components/Icon";
import SVGIconNew from "components/Icon/new";
import CustomButton from "components/Button";
import ModalNotification from "components/Modal/notification";
import ConfirmationModal from "components/Modal/confirmation";
import TopBarModal from "components/Modal/topMenu";
import SupportModal from "components/Modal/support";

import "stylesheets/components/topbar/index.scss";
import "stylesheets/components/transition/index.scss";
import DefaultImage from "assets/images/av-male.jpg";
import { getItem } from "utils/tokenStore";

class Topbar extends Component {
  state = {
    notificationShow: false,
    showSupportModal: false,
    showCallConfirmation: false,
    showSubMenu: false,
  };

  toggleOldVersion = () => window.open("https://atlas.iqiglobal.com", "_blank");

  onClickSignOut = () => {
    if (window.location.href.includes("/admin-impersonate")) {
      this.props.adminLogOut();
      setTimeout(() => {
        window.close();
      }, 500);
    } else {
      this.props.sendLogoutRequest();
    }
  };

  render = () => {
    const { avatar_url, display_name } = this.props.profileData;
    return (
      <>
        <div className={"at-navbar"}>
          <div>
            <button
              className="at-topbar-opt__btn at-navbar__sidebar-active"
              onClick={() => this.props.toggleSidebarExpand()}
            >
              <SVGIcon
                className="at-topbar-opt__icon svg-icon-list"
                svgHref={`icon-list`}
              />
            </button>
            <button
              className="at-topbar-opt__btn at-navbar__hide-sidebar"
              onClick={() => this.props.toggleSidebarExpand()}
            >
              <FaList className="at-topbar-opt__icon" />
            </button>
          </div>
          <div
            className={"d-flex"}
            style={{
              alignItems: "center",
              marginLeft: "1rem",
              gridGap: "0.5rem",
            }}
          >
            {window.location.href.includes("/admin-impersonate") &&
              getItem("IQI_ATLAS_JWT_AGENT_TOKEN") !== null && (
                <CustomButton
                  className="at-topbar-opt__btn mx-2"
                  children={
                    <SVGIconNew
                      style={{ width: 30, height: 30, color: "#CD5050" }}
                      svgHref={"icon-offmask"}
                    />
                  }
                  onClick={() => {
                    this.props.adminLogOut();
                    setTimeout(() => {
                      window.close();
                    }, 500);
                  }}
                  tooltip={true}
                  tooltipChildren={<span> Stop Impersonating </span>}
                  tooltipPosition={"bottom"}
                  tooltipID={"Admin Log Out"}
                />
              )}
            <CustomButton
              className="at-topbar-opt__btn mx-2"
              children={<BsChatSquareQuoteFill />}
              onClick={() => this.props.getMOTDTrigger()}
              tooltip={true}
              tooltipChildren={<span>MOTD</span>}
              tooltipPosition={"bottom"}
              tooltipID={"TopbarMOTD"}
            />
            <CustomButton
              className="at-topbar-opt__btn"
              children={<BiSupport />}
              onClick={() =>
                this.setState((prevState) => ({
                  showSupportModal: !prevState.showSupportModal,
                  notificationShow: false,
                  showSubMenu: false,
                }))
              }
              tooltip={true}
              tooltipChildren={<span>Help & Support</span>}
              tooltipPosition={"bottom"}
              tooltipID={"TopbarSupport"}
            />
            <div style={{ position: "relative", padding: 10 }}>
              <CustomButton
                className="at-topbar-opt__btn"
                children={
                  <SVGIcon
                    className="at-topbar-opt__icon at-topbar-opt__icon-notification"
                    svgHref={`icon-bell`}
                  />
                }
                onClick={() =>
                  this.setState((prevState) => ({
                    notificationShow: !prevState.notificationShow,
                    showSupportModal: false,
                    showSubMenu: false,
                  }))
                }
                tooltip={true}
                tooltipChildren={<span>Notifications</span>}
                tooltipPosition={"bottom"}
                tooltipID={"TopbarNotification"}
              />
              {this.props.unreadNotification.unread_count > 0 && (
                <span className="at-topbar-opt__noti-num">
                  {this.props.unreadNotification.unread_count}
                </span>
              )}
            </div>
            <h5 className="at-topbar-display_name">{display_name || ""}</h5>
            <CustomButton
              children={
                <img
                  className={"at-topbar-opt__btn-profile-img"}
                  src={avatar_url || DefaultImage}
                  onError={(e) => {
                    if (e.target) {
                      e.target.src = DefaultImage;
                    }
                  }}
                />
              }
              onClick={() =>
                this.setState({
                  showSubMenu: true,
                  showSupportModal: false,
                  notificationShow: false,
                })
              }
            />
          </div>
        </div>
        <Transition in={this.state.notificationShow} timeout={0} unmountOnExit>
          {(state) => {
            const renderModalClass = (param) => {
              if (param === "entering") {
                return "fade-enter-active";
              }
            };
            return (
              <ModalNotification
                className={`${renderModalClass(state)}`}
                onClickCloseNotification={() =>
                  this.setState({ notificationShow: false })
                }
                markNotificationAsRead={(id) =>
                  this.props.markNotificationAsRead(id)
                }
                markAllNotificationAsRead={this.props.markAllNotificationAsRead}
                onLoadModalNotification={this.props.onLoadModalNotification}
                getListAPI={this.props.getUnreadNotifications}
                panelData={
                  this.props.unreadNotification.data
                    ? this.props.unreadNotification.data
                    : []
                }
                prefix_path={this.props.adminSignInReducer.prefix_path}
                meta={
                  this.props.unreadNotification.meta &&
                  this.props.unreadNotification.meta
                }
                totalPages={this.props.unreadNotificationPage}
                history={this.props.history}
              />
            );
          }}
        </Transition>
        <SupportModal
          open={this.state.showSupportModal}
          onClose={() => this.setState({ showSupportModal: false })}
          setShowCallConfirmation={() =>
            this.setState({ showCallConfirmation: true })
          }
        />
        <TopBarModal
          open={this.state.showSubMenu}
          history={this.props.history}
          appSetting={this.props.appSettingReducer?.appSetting ?? {}}
          prefix_path={this.props.adminSignInReducer.prefix_path}
          onClose={() => this.setState({ showSubMenu: false })}
          onClickSignOut={() => this.onClickSignOut()}
          updateAppSetting={this.props.updateAppSetting}
        />
        <ConfirmationModal
          mode={"alert"}
          open={this.state.showCallConfirmation}
          title={"Helpdesk"}
          message={
            "An exclusive and premier support line for IQI warriors in all your real estate queries. Click the call button below to talk to one of our Exclusive Care Service team. This service is available between 10am until 10pm everyday including public holidays."
          }
          loading={false}
          positiveText={
            <p>
              <FaPhoneAlt style={{ width: 14, height: 14, marginRight: 8 }} />
              Call
            </p>
          }
          positiveAction={() => {
            Promise.all([(window.location.href = "tel:+1800222155")]).then(
              () => {
                this.setState({ showCallConfirmation: false });
              },
            );
          }}
          negativeText={"Cancel"}
          negativeAction={() => this.setState({ showCallConfirmation: false })}
        />
      </>
    );
  };
}

export default Topbar;
