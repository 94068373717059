import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";

import withEmailTemplates from "containers/Dashboard/Administrator/EmailTemplates/actions";

import AtlasButton from "components/Button";
import SelectEmailTemplate from "./SelectEmailTemplate";

const Content = ({
  isActive,
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
  handleAccordionChange,
  handleAccordionClose,
  emailTemplates,
  metadata,
  selectedEmailTemplate,
  getEmailTemplates,
  getSelectedEmailTemplate,
  categories,
  getCategories,
}) => {
  const [showEmailTemplateDialog, setShowEmailTemplateDialog] = useState(false);

  useEffect(() => {
    if (isChecked) {
      getSelectedEmailTemplate(selectedEmailCampaign.email_template_id);
    }
  }, [selectedEmailCampaign.email_template_id]);

  const isChecked = selectedEmailCampaign.email_template_id;

  const toggleEmailTemplateDialog = () =>
    setShowEmailTemplateDialog(!showEmailTemplateDialog);

  const renderSelectEmailTemplateDialog = () => (
    <SelectEmailTemplate
      handleClose={toggleEmailTemplateDialog}
      selectedEmailCampaign={selectedEmailCampaign}
      onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
      updateEmailCampaign={updateEmailCampaign}
      emailTemplates={emailTemplates}
      metadata={metadata}
      getEmailTemplates={getEmailTemplates}
      categories={categories}
      getCategories={getCategories}
    />
  );

  return (
    <>
      <ExpansionPanel
        square={true}
        expanded={isActive}
        className={"expansion-panel"}
      >
        <ExpansionPanelSummary
          classes={{
            root: "expansion-panel-summary",
            content: "expansion-panel-summary-content",
          }}
        >
          <div className={"expansion-panel-summary-wrapper"}>
            <FaCheckCircle
              className={`check-icon${isChecked ? " is-checked" : ""}`}
            />

            <div>
              <h4 className={"title mb-3"}>Design</h4>

              <small className={"text-muted"}>Create your email content.</small>
            </div>

            <div className={"d-flex align-items-center"}>
              {isActive && (
                <AtlasButton
                  type={"button"}
                  className={"close"}
                  onClick={handleAccordionClose}
                >
                  &times;
                </AtlasButton>
              )}
              {!isActive && (
                <AtlasButton
                  type={"button"}
                  className={"btn-new btn-new--outline-secondary"}
                  onClick={handleAccordionChange("content")}
                  tooltip={true}
                  tooltipID={"Edit content"}
                  tooltipPosition={"top"}
                  tooltipChildren={"Edit content"}
                >
                  <span className={"button-text"}>Edit content</span>

                  <BsPencilSquare className={"button-icon"} />
                </AtlasButton>
              )}
            </div>
          </div>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails
          className={"expansion-panel-details justify-content-center"}
        >
          <div className={"text-center"}>
            {isChecked && (
              <>
                <img
                  alt={selectedEmailTemplate.name}
                  src={selectedEmailTemplate.thumbnail_url}
                  title={selectedEmailTemplate.name}
                  width={200}
                  height={300}
                  className={
                    "border p-1 at-email-campaigns__template-thumbnail"
                  }
                />

                <p className={"mb-4"}>
                  <strong>{selectedEmailTemplate.name}</strong>
                </p>
              </>
            )}

            <AtlasButton
              type={"button"}
              className={"btn-new btn-new--secondary mx-auto"}
              onClick={toggleEmailTemplateDialog}
            >
              Select Template
            </AtlasButton>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {showEmailTemplateDialog && renderSelectEmailTemplateDialog()}
    </>
  );
};

export default withEmailTemplates(Content);
