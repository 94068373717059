import Axios from "axios";
import Cookies from "js-cookie";
import FileSaver from "file-saver";

import getDomainURL from "utils/getDomainURL";
import { getItem, storeItem, clearItem } from "utils/tokenStore";

import { requestError } from "./requestHandler";

export const Get = (url, response, error, load) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  let token = getItem(
    isImpersonating ? "IQI_ATLAS_JWT_AGENT_TOKEN" : "IQI_ATLAS_JWT_TOKEN"
  );
  Axios.defaults.headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `JWT ${token}`,
    ...(isImpersonating && getItem("IQI_ATLAS_IMPERSONATOR_EMAIL") && {
        "Impersonator-Email": getItem("IQI_ATLAS_IMPERSONATOR_EMAIL"),
      }),
  };
  return Axios.get(`${getDomainURL()}${url}`)
    .then((res) => {
      response(res.data);
      load(false);
    })
    .catch((err) => {
      load(false);
      if (err && err.response && err.response.status) {
        if (err.response.status === 401) {
          if (err.response.data.internal_code === 460) {
            // OutdatedToken( () => load( true ), () => Get( url, response, error, load ) )
            error(err.response.data.message);
          }
        } else if (err.response.status === 422) {
          if (err.response.data.internal_code === 461) {
            const isImpersonating = window.location.href.includes("/admin-impersonate");

            window.location.href = isImpersonating
              ? "/#/admin-impersonate/6395/dashboard/profile?signNDA"
              : "/#/dashboard/profile?signNDA";
            requestError(err.response.data.message, "signNDA");
          } else {
            error(err.response.data.message || err.response.data);
          }
        } else {
          error(err.response.data.message);
        }
      } else if (err && err.message) {
        error(err.message);
      } else {
        error(
          "You are disconnnected from the internet, please reconnect to use Atlas. If problem persists, please contact the system admin."
        );
      }
    });
};

export const GetFile = async (url, savedFilename, response, error, load) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";
  const refreshTokenName = isImpersonating
    ? "IQI_ATLAS_REFRESH_AGENT_TOKEN"
    : "IQI_ATLAS_REFRESH_TOKEN";
  let headers = new Headers();
  headers.append("Authorization", `JWT ${getItem(tokenName)}`);
  const result = await fetch(`${getDomainURL()}${url}`, { headers });
  load(false);
  if (result.ok) {
    const resultBlob = await result.blob();
    FileSaver.saveAs(resultBlob, savedFilename);
    response({ blob: resultBlob, filename: savedFilename });
  } else {
    const resultJSON = await result.json();
    if (resultJSON.message === "Token expired") {
      const RefreshToken = await Axios.post(
        `${getDomainURL()}/tokens/refresh`,
        { refresh_token: getItem(refreshTokenName) },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: getItem(refreshTokenName),
          },
        }
      );
      if (RefreshToken.status === 200) {
        Cookies.set(
          "IQI_ATLAS_TOKEN_EXPIRY_TIME",
          RefreshToken.data.expire_time
        );
        storeItem(tokenName, RefreshToken.data.access_token);
        storeItem(refreshTokenName, RefreshToken.data.refresh_token);
        GetFile(url, savedFilename, response, error, load);
      } else {
        let alerted = getItem("IQI_ATLAS_OUTDATED_TOKEN") || "";
        if (
          RefreshToken.response.data.message === "Token not found" ||
          RefreshToken.response.data.message === "Token expired"
        ) {
          clearItem(tokenName);
          clearItem(refreshTokenName);
          if (alerted !== "yes") {
            alert(
              "Due to inactivity, your session has expired, please login again."
            );
            storeItem("IQI_ATLAS_OUTDATED_TOKEN", "yes");
          }
          window.location.reload();
        }
      }
    } else {
      error(resultJSON.message);
    }
  }
};

export const GetFileURL = (url, savedFilename, error, load) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";

  let token = getItem(tokenName);
  let headers = new Headers();
  headers.append("Authorization", `JWT ${token}`);
  return fetch(`${url}`, { headers })
    .then((res) => res.blob())
    .then((blobby) => {
      load(false);
      return FileSaver.saveAs(blobby, savedFilename);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        if (err.response.data.internal_code === 460) {
          // OutdatedToken( () => load( true ), () => GetFileURL( url, savedFilename, error, load ) )
        }
      } else {
        error(err);
      }
      load(false);
    });
};

export const OpenFile = (url, savedFilename, error, load, format) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";

  let token = getItem(tokenName);
  let headers = new Headers();
  headers.append("Authorization", `JWT ${token}`);
  return fetch(`${getDomainURL()}${url}${format ? format : ""}`, { headers })
    .then((res) => res.blob())
    .then((blobby) => {
      load(false);
      let url = window.URL.createObjectURL(blobby);
      let link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
    })
    .catch((err) => {
      if (err.response.status === 401) {
        if (err.response.data.internal_code === 460) {
          // OutdatedToken( () => load( true ), () => OpenFile( url, savedFilename, error, load, format ) )
        }
      } else {
        error(err.message);
      }
      load(false);
    });
};

export const OutdatedTokenPost = (url, data, response, error, load) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";

  let token = getItem(tokenName);
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `JWT ${token}`,
  };
  return Axios.post(`${getDomainURL()}${url}`, data)
    .then((res) => {
      response(res);
      load(false);
    })
    .catch((err) => {
      load(false);
      if (err && err.response && err.response.status) {
        error(err.response.data.message);
      } else if (err && err.message) {
        error(err.message);
      } else {
        error(
          "You are disconnnected from the internet, please reconnect to use Atlas. If problem persists, please contact the system admin."
        );
      }
    });
};

export const Post = (url, data, response, error, load, config) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";

  let token = getItem(tokenName);
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `JWT ${token}`,
  };
  return Axios.post(`${getDomainURL()}${url}`, data, config)
    .then((res) => {
      response(res.data);
      load(false);
    })
    .catch(async (err) => {
      load(false);
      if (err && err.response && err.response.status) {
        if (err.response.status === 401) {
          if (err.response.data.internal_code === 460) {
            // OutdatedToken( () => load( true ), () => Post( url, data, response, error, load ) )
          }
        } else {
          if (err.response.request.responseType === "blob") {
            const blob = await new Blob([err.response.data], {
              type: "application/json",
            });
            const text = await blob.text();
            error(JSON.parse(text).message, err.response);
          } else error(err.response.data.message, err.response);
        }
      } else if (err && err.message) {
        error(err.message, err.response);
      } else {
        error(
          "You are disconnnected from the internet, please reconnect to use Atlas. If problem persists, please contact the system admin."
        );
      }
    });
};

export const Put = (url, data, response, error, load) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";

  let token = getItem(tokenName);
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `JWT ${token}`,
  };
  return Axios.put(`${getDomainURL()}${url}`, data)
    .then((res) => {
      response(res.data);
      load(false);
    })
    .catch((err) => {
      load(false);
      if (err && err.response && err.response.status) {
        if (err.response.status === 401) {
          if (err.response.data.internal_code === 460) {
            // OutdatedToken( () => load( true ), () => Put( url, data, response, error, load ) )
          }
        } else {
          error(err.response.data.message);
        }
      } else if (err) {
        error(err.message);
      } else {
        error(
          "You are disconnnected from the internet, please reconnect to use Atlas. If problem persists, please contact the system admin."
        );
      }
    });
};

export const Delete = (url, response, error, load) => {
  load(true);
  const isImpersonating = window.location.href.includes("/admin-impersonate");
  const tokenName = isImpersonating
    ? "IQI_ATLAS_JWT_AGENT_TOKEN"
    : "IQI_ATLAS_JWT_TOKEN";

  let token = getItem(tokenName);
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `JWT ${token}`,
  };
  return Axios.delete(`${getDomainURL()}${url}`)
    .then((res) => {
      response(res.data);
      load(false);
    })
    .catch((err) => {
      if (err.response && err.response.status) {
        if (err.response.status === 401) {
          if (err.response.data.internal_code === 460) {
            // OutdatedToken( () => load( true ), () => Delete( url, response, error, load ) )
          }
        } else {
          if (err.response.data && err.response.data.message) {
            error(err.response.data.message);
          } else if (err.message) {
            error(err.message);
          }
        }
      } else if (err && err.message) {
        error(err.message);
      } else {
        error(
          "You are disconnnected from the internet, please reconnect to use Atlas. If problem persists, please contact the system admin."
        );
      }
      load(false);
    });
};
