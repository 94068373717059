import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import { getCountryDictionary } from "actions/dictionary";

import { requestError } from "utils/requestHandler";
import { Get } from "utils/axios";
import { numberWithCommas } from "utils/thousandSeparator";

const searchParams = [
  {
    label: "Project Name",
    value: "project_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Unit Number ",
    value: "unit_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Full Name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Email",
    value: "user_email_eq",
    type: "input",
    param: "",
  },
  {
    label: "Agent Mobile Number",
    value: "user_mobile_contact_number_eq",
    type: "input",
    param: "",
  },
  {
    label: "Buyer",
    value: "buyer_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Booking Date From",
    value: "booking_date_gteq",
    type: "date",
    param: "",
  },
  {
    label: "Booking Date To",
    value: "booking_date_lteq",
    type: "date",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "1", label: "Draft" },
      { value: "2", label: "Submitted" },
      { value: "3", label: "Approved" },
      { value: "4", label: "Rejected" },
      { value: "5", label: "Cancelled" },
      { value: "6", label: "Ready to Invoice" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      showDetailDialog: false,
      showApproveConfirmation: false,
      showRejectConfirmation: false,
      showDeleteConfirmation: false,

      reportSales: {
        data: {},
        meta: {},
      },
      searchParams: searchParams,
      salesPages: [],

      reportPage: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeSalesHOC = (val, context) => {
      this.setState({ [context]: val });
      if (context === "showDetailDialog" && val === false) {
        this.getReportSales(1, "");
      }
    };

    getReportSales = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/project_sales?${search}page=${page}`,
        this.getReportSalesSuccess,
        this.getReportSalesError,
        this.load,
      );
    };
    getReportSalesSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        booking_date: Moment(item.booking_date).format("DD MMM YYYY"),
      }));
      this.setState({
        reportSales: {
          data: tempData,
          meta: payload.meta,
        },
        reportPage: tmpTotalPages,
      });
    };
    getReportSalesError = (error) => requestError(error);

    getSelectedSales = (id) =>
      Get(
        `/admin/project_sales/${id}`,
        this.getSelectedSalesSuccess,
        this.getSelectedSalesError,
        this.load,
      );
    getSelectedSalesSuccess = (payload) => {
      let temp = {
        ...payload,
        booking_date: Moment(payload.booking_date).format("DD MMM YYYY"),
        type_id: `${payload.type_id}`,
        status_id: `${payload.status_id}`,
        purchase_price: numberWithCommas(`${payload.purchase_price}`),
        nett_price: numberWithCommas(`${payload.nett_price}`),
        project_sale_agents_attributes: payload.sale_agents,
        admin_remark: payload.admin_remark,
        agent: {
          ...payload.agent,
          agent_name: payload.agent.full_name,
          agent_email: payload.agent.email,
          agent_mobile_number: payload.agent.mobile_number,
          agent_team: payload.agent.team,
        },
      };
      delete temp.sale_agents;
      delete temp.booking_form_url;

      this.setState({
        selectedSale: temp,
        showDetailDialog: true,
      });
    };
    getSelectedSalesError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          reportPage={this.state.reportPage}
          showApproveConfirmation={this.state.showApproveConfirmation}
          showRejectConfirmation={this.state.showRejectConfirmation}
          showDeleteConfirmation={this.state.showDeleteConfirmation}
          showDetailDialog={this.state.showDetailDialog}
          salesPages={this.state.salesPages}
          searchParams={this.state.searchParams}
          onLoadSales={this.state.loading}
          reportSales={this.state.reportSales}
          selectedSale={this.state.selectedSale}
          onChangeSalesHOC={this.onChangeSalesHOC}
          getReportSales={this.getReportSales}
          getSelectedSales={this.getSelectedSales}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary,
  })(WithHOC);
};

export default HOC;
