import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Typeahead } from "react-bootstrap-typeahead";
import { FaInfoCircle } from "react-icons/fa";
import { BsTrash3Fill } from "react-icons/bs";

import AtlasButton from "components/Button";
import SVGIcon from "components/Icon";
import CustomFileInput from "components/Input/fileInput";
import CustomFormInput from "components/Input/formInput";
import AtlasRadioGroup from "components/RadioGroup";

import isEmptyValue from "utils/isEmpty";
import { acceptedFileTypes, status } from "../../assets";

const FieldData = (context, categories) => {
  const data = [
    {
      label: "Template Name",
      value: "name",
      type: "text",
      placeholder: "Default template - Unsubscription Follow Up",
    },
    {
      label: "Subject Line",
      value: "subject",
      type: "text",
      placeholder: "You have been unsubscribed successfully",
    },
    {
      label: "Categories",
      value: "categories",
      type: "typeahead",
      options: categories,
    },
    {
      label: "Add an attachment",
      value: "attachment",
      type: "file",
      info: "Choose a file to attach to your email (4MB maximum). Accepted file types: .csv, .doc, .docx, .eps, .gif, .ics, .jpeg, .jpg, .mobi, .msg, .ods, .pdf, .png, .ppt, .pptx, .pub, .rtf, .tif, .tiff, .txt, .xls, .xlsx",
    },
  ];

  if (context === "edit") {
    data.push({
      label: "Status",
      value: "status_id",
      type: "radio",
      options: status,
    });
  }

  return data;
};

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const Form = ({ values, categories, attachment, context, handleChange }) => {
  return (
    <div className={"grid-control"}>
      {FieldData(context, categories).map((item) => (
        <section
          key={item.value}
          className={`mb-3 mb-sm-0 ${item.type === "file" ? "grid-half-col" : "grid-full-col"}`}
        >
          <div className={"d-flex align-items-center"}>
            <h2
              className={"at-form-input__title text-capitalize"}
              required={item.type === "text"}
            >
              {item.label}
            </h2>

            {item.info && (
              <DarkTooltip
                classes={{ tooltip: "tooltip-arrow top" }}
                placement={"top"}
                enterTouchDelay={50}
                title={item.info}
              >
                <FaInfoCircle
                  className={"ml-1"}
                  style={{ width: 21, height: 21, color: "#447DF7" }}
                />
              </DarkTooltip>
            )}
          </div>

          {item.type === "text" && (
            <CustomFormInput
              type={"text"}
              value={values[item.value]}
              required={true}
              onChangeValue={(val) => handleChange(item.value, val)}
              placeholder={item.placeholder}
            />
          )}

          {item.type === "typeahead" && (
            <Typeahead
              id={`${context}-email-template__categories`}
              className={"at-email-templates__typeahead"}
              multiple={true}
              placeholder={"Choose several categories"}
              options={item.options}
              selected={values[item.value] || []}
              onChange={(val) =>
                handleChange(item.value, val && val.length > 0 ? val : "")
              }
              renderMenuItemChildren={(option) => <p>{option}</p>}
              allowNew={true}
              newSelectionPrefix={"Add a new category: "}
            />
          )}

          {item.type === "file" && (
            <>
              {attachment && (attachment.file_name || attachment.name) && (
                <div className={"d-flex align-items-center mb-2 g-2"}>
                  <CustomFormInput
                    type={"text"}
                    containerClass={"mb-0"}
                    containerStyle={{ flex: 1 }}
                    value={attachment.file_name || attachment.name}
                    disabled={true}
                  />

                  {attachment && attachment.url && (
                    <AtlasButton
                      type={"button"}
                      className={"at-table-icon-button"}
                      tooltip={true}
                      tooltipID={"view-file"}
                      tooltipPosition={"top"}
                      tooltipChildren={"View file"}
                      onClick={() => {
                        const a = document.createElement("a");
                        a.href = attachment.url;
                        a.target = "_blank";
                        a.click();
                      }}
                    >
                      <SVGIcon
                        className={"svg-icon-info"}
                        svgHref={"icon-info"}
                      />
                    </AtlasButton>
                  )}

                  <AtlasButton
                    type={"button"}
                    className={"at-table-icon-button"}
                    tooltip={true}
                    tooltipID={"remove-file"}
                    tooltipPosition={"top"}
                    tooltipChildren={"Remove file"}
                    onClick={() => handleChange(item.value, null)}
                  >
                    <BsTrash3Fill
                      style={{ width: 20, height: 20, color: "#F04438" }}
                    />
                  </AtlasButton>
                </div>
              )}
              {isEmptyValue(attachment) && (
                <CustomFileInput
                  acceptedFileTypes={acceptedFileTypes}
                  maxFiles={1}
                  maxFileSize={4000000}
                  onChangeFile={(file) => handleChange(item.value, file)}
                />
              )}
            </>
          )}

          {item.type === "radio" && (
            <AtlasRadioGroup
              valueKey={"value"}
              horizontal={true}
              checkedValue={values[item.value]}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => handleChange(item.value, val)}
              options={item.options}
            />
          )}
        </section>
      ))}
    </div>
  );
};

export default Form;
