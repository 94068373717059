import React, { useState, useCallback, useEffect } from "react";

const ArrowContent = ({
  onClickRearrangeData,
  columnContent,
  initialRowData,
  rowData,
}) => {
  const [sortStatus, setSortStatus] = useState("");

  useEffect(() => {
    setSortStatus("");
  }, [initialRowData]);

  const onToggleSortMode = useCallback(
    (param, value) => {
      setSortStatus(param);
      onClickRearrangeData({
        mode: param,
        columnValue: value,
      });
    },
    [columnContent, sortStatus, rowData],
  );

  if (sortStatus === "asc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("desc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--down arrow--active"></div>
      </div>
    );
  } else if (sortStatus === "desc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up arrow--active"></div>
      </div>
    );
  } else {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("asc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up"></div>
        <div className="at-table-head__arrow at-table-head__arrow--down"></div>
      </div>
    );
  }
};

const TableHeader = ({
  maxActionLength,
  columns,
  rowData,
  enableSort,
  headerClass,
  initialRowData,
  actionColumnContent,
  onClickRearrangeData,
}) => {
  return (
    <ul className={`at-table-row at-table-head  ${headerClass || ""}`}>
      {columns.map(
        ({ searchFlag, header, columnStyle, contentClass }, index) => (
          <li
            style={{
              width: "100%",
              ...columnStyle,
            }}
            key={header}
            className={`at-table-row__item at-table__${index} ${contentClass || ""}`}
          >
            {typeof header === "string" && (
              <span className="at-table-head__title">
                {header}
                {(enableSort && searchFlag) && (
                  <div style={{ maxWidth: 0, maxHeight: 0, position: "relative" }}>
                    <ArrowContent
                      columnContent={searchFlag}
                      rowData={rowData}
                      initialRowData={initialRowData}
                      onClickRearrangeData={onClickRearrangeData}
                    />
                  </div>
                )}
              </span>
            )}
            {typeof header === "function" && header()}
          </li>
        ),
      )}
      {actionColumnContent && actionColumnContent.length > 0 && (
        <li
          style={{
            width: "100%",
            minWidth: (maxActionLength * 60) < 100
              ? 100 
              : (maxActionLength * 60),
            maxWidth: (maxActionLength * 70),
          }}
          className="at-table-row__item at-table__actions"
        >
          <span className="at-table-head__title">Actions</span>
        </li>
      )}
    </ul>
  );
};

export default TableHeader;
