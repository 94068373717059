import React from "react";
import _ from "lodash";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import Moment from "moment";

import FormDialog from "../../../components/formDialog";

import { Tenure } from "../assets";
import subsaleForms from "../assets/subsaleForms";

const TempSubsaleAuthorisationForm = ({
  data,
  switchMode,

  onChange,
  closedEditing,
}) => {
  const {
    commencement_date,
    expiring_date,
    appoint_day,
    professional_fee,
    property_type,
    property_address,
    government_tax,
    payment_amount,
    others,
    vacant_possession,
    percentage_of_purchase_price,
    tenure_type_id,
    transaction_number,
  } = data;

  const renderContractLayout = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <h4 style={{ fontWeight: "bold" }}>
            {" "}
            Authorisation To Secure A Property For Acquisition{" "}
          </h4>
          <h4 style={{ fontWeight: "bold" }}>
            {transaction_number ? transaction_number : ""}
          </h4>
        </div>
        <hr />
        <div style={{ marginBottom: 10 }}>
          Purchaser(s):
          <span className={"text-danger"}>
            {` (Info will be displayed according to the contract participants section)`}
          </span>
        </div>
        <div style={{ marginBottom: 10 }}>
          NRIC/Passport No :&nbsp;
          <span className={"text-danger"}>
            {` (Info will be displayed according to the contract participants section)`}
          </span>
        </div>
        <div style={{ marginBottom: 10 }}>
          Purchaser Address :
          <span className={"text-danger"}>
            {` (Info will be displayed according to the contract participants section)`}
          </span>
        </div>
        <div className="mb-10 mt-10">
          Do hereby appoint you
          <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
          as my/our AGENT for a period of
          <input
            type={"text"}
            disabled={closedEditing}
            className="subsales-letter-input"
            value={appoint_day}
            onChange={(e) => onChange("appoint_day", e.target.value)}
          />
          days/ calendar months* commencing from the
          {/* Date format submit follows BE returns' format YYYY-MM-DD */}
          {/* Make sure date format not affect date input form field view */}
          <DatePicker
            wrapperClassName="subsales-letter-input_wrapper"
            className="subsales-letter-input"
            value={
              Moment(commencement_date).isValid()
                ? Moment(commencement_date).format("DD MMM YYYY")
                : commencement_date
            }
            onChange={(val) =>
              onChange("commencement_date", Moment(val).format("YYYY-MM-DD"))
            }
            placeholderText={"DD MMM YYYY"}
            disabled={closedEditing}
            popperPlacement={"bottom-end"}
          />
          and expiring on the
          <DatePicker
            wrapperClassName="subsales-letter-input_wrapper"
            className="subsales-letter-input"
            value={
              Moment(expiring_date).isValid()
                ? Moment(expiring_date).format("DD MMM YYYY")
                : expiring_date
            }
            onChange={(val) =>
              onChange("expiring_date", Moment(val).format("YYYY-MM-DD"))
            }
            placeholderText={"DD MMM YYYY"}
            disabled={closedEditing}
            popperPlacement={"bottom-end"}
          />
          ( "Agency Period ") to secure a property for purchase ( "said
          Property" ) at a total consideration for the said Property which shall
          not exceed RM
          <CurrencyInput
            className="subsales-letter-input"
            style={{ paddingLeft: "0.2rem" }}
            name={"payment_amount"}
            defaultValue={1000}
            disabled={closedEditing}
            value={payment_amount}
            onValueChange={(value, name) => onChange("payment_amount", value)}
          />
          or other such sum as may be subsequently agreed by the Prospective
          Purchaser ( "Sale Price ") subject to the following terms and
          conditions.
        </div>

        <ol type="1" className="mt-20">
          <li>
            The Prospective Purchaser hereby authorizes
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            to utilize any form of advertisement to promote the acquisition of
            the said Property, including but not limited to sending out the
            flyers, or advertising in any form of media.
          </li>
          <li>
            A brief description of the Prospective Purchaser's requirement is as
            follows:
            <ol type="a">
              <li>
                Type of Property:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={property_type}
                  onChange={(e) => onChange("property_type", e.target.value)}
                />
              </li>
              <li>
                Location of Property:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={property_address}
                  onChange={(e) => onChange("property_address", e.target.value)}
                />
              </li>
              <li>
                Tenure ( Freehold/ Leasehold ):
                <select
                  className="subsales-letter-input ml-0 mb-0"
                  disabled={closedEditing}
                  value={tenure_type_id}
                  style={{ width: "100%", maxWidth: "100%" }}
                  onChange={(e) =>
                    onChange("tenure_type_id", parseInt(e.target.value))
                  }
                >
                  {Tenure.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </li>
              <li>
                With or without vacant posession:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={vacant_possession}
                  onChange={(e) =>
                    onChange("vacant_possession", e.target.value)
                  }
                />
              </li>
              <li>
                Others:
                <input
                  type={"text"}
                  disabled={closedEditing}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="subsales-letter-input ml-0 mb-0"
                  value={others}
                  onChange={(e) => onChange("others", e.target.value)}
                />
              </li>
            </ol>
          </li>
          <li>
            This instruction contained herein is given to
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            in my/our capacity as the Prospective Purchaser. This authorisation
            to secure/ acquire a property for purchase shall remain valid during
            the said Agency Period.
          </li>
          <li>
            The Prospective Purchaser hereby agrees to pay
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            a professional fee a RM
            <CurrencyInput
              className="subsales-letter-input"
              style={{ paddingLeft: "0.2rem" }}
              name={"professional_fee"}
              defaultValue={1000}
              disabled={closedEditing}
              value={professional_fee}
              onValueChange={(value, name) =>
                onChange("professional_fee", value)
              }
            />
            or a professional fee equivalent to
            <input
              type={"number"}
              disabled={closedEditing}
              className="subsales-letter-input"
              value={percentage_of_purchase_price}
              onChange={(e) =>
                onChange(
                  "percentage_of_purchase_price",
                  parseFloat(e.target.value),
                )
              }
            />
            % of the Purchase Price, plus {government_tax}% Sales and Service
            Tax ( "SST" ) on the professional fees upon the acquisition of the
            said Property ( even if it differs from Clause 2 ) from any
            Vendor(s) procured and/or introduced by
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            during the Agency Period on the terms specified herein and upon the
            execution of the formal Sale and Purchase Agreement ("SPA")
          </li>
          <li>
            In the event the Earnest Deposit has been paid and acquisition
            transaction is aborted by the Prospective Purchaser or by the
            Vendor(s) before the execution of the SPA the Prospective Purchaser
            agrees to pay IQI REALTY SDN BHD a professional fee equivalent to
            50% of the Earnest Deposit or forfeitable deposit or 50% of the
            agreed full fees, whichever is lesser plus the relevant applicable
            SST.
          </li>
          <li>
            The Prospective Purchaser agrees to indemnify
            <span style={{ fontWeight: "bold" }}> IQI REALTY SDN BHD </span>
            against all losses/ damages or claims by the Vendor(s) if the
            Prospective Purchaser for any reasons whatsoever fail to execute, or
            does not proceed with the execution of, the SPA after the Earnest
            Deposit has been paid or collected and the Prospective Purchaser's
            offer to purchase has been accepted by the Vendor(s).
          </li>
          <li>
            All signatories to this document hereby acknowledge and agree to comply with the Malaysia Anti-Corruption Commission Act 2009 and Amendment Act - Section 17A of the MACC Act 2009 (MACC 2009), Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 (AML/CFT), 
            Personal Data Protection Act 2010 and further authorise IQI Realty Sdn Bhd and/or its group companies to disclose their information to third parties as necessary, inter alia, 
            at the request of regulatory authorities. For reference, parties may refer to our website at <a target="_blank" href="https://www.iqiglobal.com/amla">https://www.iqiglobal.com/amla</a> and <a target="_blank" href="https://www.iqiglobal.com/privacy-policy">https://www.iqiglobal.com/privacy-policy</a>.
          </li>
        </ol>
        <hr />
        <div>
          *Each party further represent and agree that each has duly reviewed
          all aspects of this Letter and fully understands the terms and
          conditions thereof, has executed this Letter based upon such party’s
          own judgment, and knowingly, voluntarily, andwithout duress, agrees to
          all of the terms set forth in this Letter.
        </div>
      </>
    );
  };

  const renderFormListLayout = () => (
    <FormDialog
      formData={subsaleForms}
      data={data}
      closedEditing={closedEditing}
      onChange={onChange}
    />
  );

  return (
    <>
      {switchMode === "Contract View" && renderContractLayout()}
      {switchMode === "Form View" && renderFormListLayout()}
    </>
  );
};

export default TempSubsaleAuthorisationForm;
