import React, { Component } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Put, Post } from "utils/axios";
import { refreshToken } from "actions/login";
import { getBranches } from "actions/branch";
import { storeLastView } from "actions/lastView";
import { getAllDictionaryData } from "actions/dictionary";
import { storeSubsalesAddress } from "actions/subsales/address";
import { types as SubsalesType } from "dictionary/subsales";

const ClaimTypeOptions = [
  { value: "1", label: "Claim Commission" },
  { value: "2", label: "Refund" },
  { value: "3", label: "Forfeit" },
  { value: "4", label: "Renewal" },
  { value: "5", label: "Release" },
  { value: "6", label: "Cancel Form" },
  { value: "7", label: "Request Invoice" },
  { value: "8", label: "Request Official Receipt" },
  { value: "9", label: "Request TA Stamping" },
];

const radioFilters = [
  {
    label: "All",
    type: "radio",
    value: "",
  },
  {
    label: "Submitted",
    type: "radio",
    value: "1",
  },
  {
    label: "Reviewing",
    type: "radio",
    value: "7",
  },
  {
    label: "Processing",
    type: "radio",
    value: "2",
  },
  {
    label: "Cancelled",
    type: "radio",
    value: "3",
  },
  {
    label: "Paid",
    type: "radio",
    value: "4",
  },
  {
    label: "Completed",
    type: "radio",
    value: "5",
  },
  {
    label: "Rejected",
    type: "radio",
    value: "6",
  },
];

const searchParamsAdmin = (id) => [
  {
    label: "Confirmation Form Number",
    value: `confirmation_form_number_cont`,
    type: "input",
    param: "",
  },
  {
    label: "Property Address",
    value: "property_address_cont",
    type: "input",
    param: "",
  },
  {
    label: "Markup Confirmation Form Number",
    value:
      "markup_confirmation_form_of_ConfirmationForm_type_transaction_number_or_markup_confirmation_form_of_SubSalesForm_type_tracking_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Name / Email ",
    value: "user_email_or_user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Admin Name / Email",
    value:
      "sub_sale_claim_submissions_assignee_full_name_or_sub_sale_claim_submissions_assignee_email_or_sub_sale_claim_submissions_senior_assignee_full_name_or_sub_sale_claim_submissions_senior_assignee_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Unit ID",
    value: "unit_id_eq",
    type: "input",
    param: "",
  },
  {
    label: "Submission Type",
    value: "sub_sale_claim_submissions_type_id_eq",
    param: "",
    type: "select",
    options: ClaimTypeOptions,
  },
  {
    label: "Submission Date",
    value: "sub_sale_claim_submissions_submission_date_on_eq",
    param: "",
    type: "date",
  },
  {
    label: "Approval Date",
    value: "sub_sale_claim_submissions_approved_date_on_eq",
    param: "",
    type: "date",
  },
  {
    label: "Status",
    value: "case_closed_true",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "0", label: "Open" },
      { value: "1", label: "Closed" },
    ],
  },
  {
    label: "Is urgent",
    value: "sub_sale_claim_submissions_is_urgent_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
  {
    label: "Assigned/Reviewing Admin",
    value: "assign_option_id",
    type: "radio",
    param: "1",
    options: [
      { label: "All", value: "" },
      { label: "Assigned to me", value: "1" },
      { label: "Review by me", value: "2" },
    ],
  },
  {
    label: "Submission Status",
    value: "sub_sale_claim_submissions_status_id_eq",
    type: "radio",
    col: 12,
    param: "",
    options: radioFilters,
  },
  {
    label: "Representative",
    value: "representative_id_eq",
    type: "radio",
    col: 12,
    param: "",
    options: [
      {
        id: "",
        label: "All",
        value: "",
      },
      {
        id: 1,
        label: "Represent Landlord/Vendor",
        value: 1,
      },
      {
        id: 2,
        label: "Represent Tenant/Purchaser",
        value: 2,
      },
      {
        id: 3,
        label: "Represent Both",
        value: 3,
      },
      {
        id: 4,
        label: "None",
        value: true,
      },
    ],
  },
];

const searchParamsSuperAdmin = (id) => [
  {
    label: "Confirmation Form Number",
    value: `confirmation_form_number_cont`,
    type: "input",
    param: "",
  },
  {
    label: "Property Address",
    value: "property_address_cont",
    type: "input",
    param: "",
  },
  {
    label: "Markup Confirmation Form Number",
    value:
      "markup_confirmation_form_of_ConfirmationForm_type_transaction_number_or_markup_confirmation_form_of_SubSalesForm_type_tracking_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Agent Name / Email ",
    value: "user_email_or_user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Admin Name / Email",
    value:
      "sub_sale_claim_submissions_assignee_full_name_or_sub_sale_claim_submissions_assignee_email_or_sub_sale_claim_submissions_senior_assignee_full_name_or_sub_sale_claim_submissions_senior_assignee_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Unit ID",
    value: "unit_id_eq",
    type: "input",
    param: "",
  },
  {
    label: "Submission Type",
    value: "sub_sale_claim_submissions_type_id_eq",
    param: "",
    type: "select",
    options: ClaimTypeOptions,
  },
  {
    label: "Submission Date",
    value: "sub_sale_claim_submissions_submission_date_on_eq",
    param: "",
    type: "date",
  },
  {
    label: "Approval Date",
    value: "sub_sale_claim_submissions_approved_date_on_eq",
    param: "",
    type: "date",
  },
  {
    label: "Status",
    value: "case_closed_true",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "0", label: "Open" },
      { value: "1", label: "Closed" },
    ],
  },
  {
    label: "Is urgent",
    value: "sub_sale_claim_submissions_is_urgent_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
  {
    label: "Assigned/Reviewing Admin",
    value: "assign_option_id",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Assigned to me", value: "1" },
      { label: "Review by me", value: "2" },
    ],
  },
  {
    label: "Submission Status",
    value: "sub_sale_claim_submissions_status_id_eq",
    type: "radio",
    col: 12,
    param: "",
    options: radioFilters,
  },
  {
    label: "Representative",
    value: "representative_id_eq",
    type: "radio",
    col: 12,
    param: "",
    options: [
      {
        id: "",
        label: "All",
        value: "",
      },
      {
        id: 1,
        label: "Represent Landlord/Vendor",
        value: 1,
      },
      {
        id: 2,
        label: "Represent Tenant/Purchaser",
        value: 2,
      },
      {
        id: 3,
        label: "Represent Both",
        value: 3,
      },
      {
        id: 4,
        label: "None",
        value: true,
      },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      claims: {
        data: [],
        meta: {},
      },
      selected_claim: {},
      claim_activities: [],
      loading: false,
      reassignLoading: false,

      showTransferModal: false,

      showCreateClaim: false,
      showExternalAgent: false,
      showUpdateClaimFormModal: false,
      showReassignModal: false,
      townshipsList: [],

      totalPages: [],
      selectedSection: "Claim Form",

      searchParams: [],
      pendingRessignCount: 0,
      showAssignContactModal: false,

      disableCreateButton: false,
      showSubmitConfirmation: false
    };

    componentDidMount = () => {
      const tmpProfileReducer = window.location.href.includes(
        "/admin-impersonate",
      )
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      this.setState({
        searchParams:
          tmpProfileReducer.role === "Admin"
            ? _.cloneDeep(searchParamsAdmin(tmpProfileReducer.id))
            : _.cloneDeep(searchParamsSuperAdmin(tmpProfileReducer.id)),
      });
    };

    load = (param) => this.setState({ loading: param });

    onChangeClaimsHOC = (context, val) => {
      this.setState({ [context]: val });
    };

    onChangeUpdateSelectedClaim = (key, val) => {
      let tmp = _.cloneDeep(this.state.selected_claim);
      tmp[key] = val;
      if (key === "admin_fee_amount") {
        this.calculateResult(key, val);
      }
      return this.setState({ selected_claim: tmp });
    };

    calculateResult = (context, val) => {
      let res = 0;
      if (context === "admin_fee_amount") {
        res = parseFloat(val) * 0.06;
        this.onChangeUpdateSelectedClaim("sales_tax_amount", res.toFixed(2));
      }
    };

    getSubmittedSubsaleClaims = (page, search = '') => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      let tmp = _.cloneDeep(search);
      if (tmp.includes("q[assign_option_id]")) {
        let optionIndex = _.findIndex(this.state.searchParams, {
          value: "assign_option_id",
        });
        let option = this.state.searchParams[optionIndex].param;
        if (option === "1") {
          tmp = _.replace(
            tmp,
            `q[assign_option_id]=${option}`,
            `q[sub_sale_claim_submissions_assignee_id_eq]=${id}`,
          );
        } else if (option === "2") {
          tmp = _.replace(
            tmp,
            `q[assign_option_id]=${option}`,
            `q[sub_sale_claim_submissions_senior_assignee_id_eq]=${id}`,
          );
        }
      }
      if(tmp.includes("q[representative_id_eq]=true")) {
        tmp = _.replace(
          tmp,
          `q[representative_id_eq]=true`,
          `q[representative_id_null]=true`,
        );
      }
      Get(
        `/admin/sub_sale_claims?${tmp}page=${page}`,
        (payload) =>
          this.getSubmittedSubsaleClaimsSuccess(payload, page, search),
        this.getSubmittedSubsaleClaimsError,
        this.load,
      );
    };
    getSubmittedSubsaleClaimsSuccess = (payload, page, search) => {
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => {
        let tempSubmission = [];
        _.map(item.sub_sale_claim_submissions, (sub) => {
          let submissionFilterIndex = _.findIndex(this.state.searchParams, {
            value: "sub_sale_claim_submissions_status_id_eq",
          });
          let submissionFilter = this.state.searchParams[submissionFilterIndex]
            .param
            ? sub.status ===
              _.find(radioFilters, {
                value: this.state.searchParams[submissionFilterIndex].param,
              }).label
            : true;
          let assignOptionIndex = _.findIndex(this.state.searchParams, {
            value: "assign_option_id",
          });
          let assignOption = this.state.searchParams[assignOptionIndex].param
            ? sub.assignee_id === id || sub.senior_assignee_id === id
            : true;
          if (assignOption && submissionFilter) {
            tempSubmission.push({
              ...sub,
              submitted_date: Moment(
                sub.submitted_date,
                "DD-MM-YYYY HH:mm",
              ).isValid()
                ? Moment(sub.submitted_date, "DD-MM-YYYY HH:mm").format(
                    "DD MMM YYYY HH:mm",
                  )
                : "N/A",
            });
          }
        });

        return {
          ...item,
          booking_date: item.booking_date
            ? Moment(item.booking_date, "YYYY-MM-DD").format("DD MMM YYYY")
            : "N/A",
          sub_sale_claim_submissions: tempSubmission,
        };
      });

      this.setState(
        {
          claims: {
            ...payload,
            data: tempData,
          },
          totalPages: tmpTotalPages,
        },
        () => {
          let temp = {
            currentPage: page,
            searchParams: search,
          };
          this.props.storeLastView(temp);
        },
      );
    };
    getSubmittedSubsaleClaimsError = (error) => requestError(error);

    getActivity = (id) =>
      Get(
        `/admin/sub_sale_claims/${id}/activities`,
        this.getActivitySuccess,
        this.getActivityError,
        this.load,
      );
    getActivitySuccess = (payload) =>
      this.setState({ claim_activities: payload });
    getActivityError = (error) => requestError(error);

    approveSubsaleClaim = (id) => {
      Put(
        `/admin/sub_sale_claims/${id}/approve`,
        {},
        this.approveSubsaleClaimSuccess,
        this.approveSubsaleClaimError,
        this.load,
      );
    };
    approveSubsaleClaimSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubmittedSubsaleClaims(currentPage, searchParams);
      this.getSelectedClaim(payload.sub_sale_claim.id);
      requestSuccess(payload.meta.message);
    };
    approveSubsaleClaimError = (error) => requestError(error);

    rejectSubsaleClaim = (id) => {
      Put(
        `/admin/sub_sale_claims/${id}/reject`,
        {},
        this.rejectSubsaleClaimSuccess,
        this.rejectSubsaleClaimError,
        this.load,
      );
    };
    rejectSubsaleClaimSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubmittedSubsaleClaims(currentPage, searchParams);
      this.getSelectedClaim(payload.sub_sale_claim.id);
      requestSuccess(payload.meta.message);
    };
    rejectSubsaleClaimError = (error) => requestError(error);

    // routes that needs to add /admin
    // approve

    updateCreateSubsales = (context, val) => {
      let tmp = this.state.createSubsale;
      tmp[context] = val;
      return this.setState({ createSubsale: tmp });
    };
    onSubmitCreateClaim = () => {
      let tmpTypes = _.find(SubsalesType, {
        name: this.state.createSubsale.type,
      });
      let data = {
        sub_sale_type_id: tmpTypes.id,
        user_id: this.state.createSubsale.selectedAgentID,
      };
      return this.createSubsaleClaim(data);
    };

    transferConfirmation = (dataToSubmit, id) =>
      Put(
        `/admin/sub_sale_claims/${id}/replace_form`,
        dataToSubmit,
        () => this.transferConfirmationSuccess(id),
        this.transferConfirmationError,
        this.load,
      );
    transferConfirmationSuccess = (id) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getSubmittedSubsaleClaims(currentPage, searchParams);
      this.getSelectedClaim(id);
      requestSuccess("Confirmation transferred successfully.");
      this.setState({
        showTransferModal: false,
      });
    };
    transferConfirmationError = (error) => requestError(error);

    getSelectedClaim = (id) =>
      Get(
        `/admin/sub_sale_claims/${id}`,
        this.getSelectedClaimSuccess,
        this.getSelectedClaimError,
        this.load,
      );
    getSelectedClaimSuccess = (payload) => {
      let tmp = {
        ...payload,
        claim_status: "Draft",
      };
      tmp.property_location_attributes = { ...tmp.property_location };
      delete tmp.property_location;
      const {
        property_name = "",
        postcode = "",
        built_up = 0,
        land_area = 0,
        category_id = "",
        property_type_id = "",
        tenure_type_id = "",
        unit_type_id = "",
      } = tmp.property_location_attributes;
      return this.setState({
        selected_claim: {
          ...tmp,
          external_agency_bank_id: [0, null].indexOf(tmp.external_agency_bank_id) === -1 
            ? tmp.external_agency_bank_id 
            : "",
          property_type_id: category_id,
          property_location_attributes: {
            ...tmp.property_location_attributes,
            property_name: property_name || "",
            postcode: postcode || "",
            built_up: built_up || 0,
            land_area: land_area || 0,
            category_id: category_id || "",
            property_type_id: property_type_id || "",
            tenure_type_id: tenure_type_id || "",
            unit_type_id: unit_type_id || "",
          },
        },
        showUpdateClaimFormModal: true,
      });
    };
    getSelectedClaimError = (error) => requestError(error);

    getSelectedTownShip = (id) =>
      Get(
        `/townships?state_id=${id}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) =>
      this.setState({
        townshipsList: _.map(payload, (town) => ({
          ...town,
          value: town.name,
        })),
      });
    getSelectedTownShipError = (error) => requestError(error);

    changeClaimsState = (id) =>
      Put(
        `/admin/sub_sale_claims/${id}/toggle_status`,
        {},
        this.changeClaimsStateSuccess,
        this.changeClaimsStateError,
        this.load,
      );

    changeClaimsStateSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      requestSuccess(payload.message);
      this.getSubmittedSubsaleClaims(currentPage, searchParams);
    };

    changeClaimsStateError = (error) => requestError(error);

    updateSelectedClaim = (id, dataToSubmit, enableExternalAgentBankValidation) => {
      const { property_address = "" } = dataToSubmit;
      this.setState({ enableValidateBank: false })

      if (property_address !== "") {
        this.props.storeSubsalesAddress(dataToSubmit.property_address);
      }
      // All forms which can add External Agency need to validate external agent bank info under condition:
      // Bank fields of External Agent cannot be empty if external agent is selected, stakeholder type is IQI and commission fee cant be empty
      let validateIqiStakeholder = (dataToSubmit.external_agency_id > 0 && dataToSubmit.stakeholder_type_id === 1) && dataToSubmit.revised_co_agency_fee_amount > 0
      let checkExternalAgentBankInfoError = (!(dataToSubmit.external_agency_bank_id > 0) || dataToSubmit.external_agency_bank_account_number === "")

      if(enableExternalAgentBankValidation) {
        if(validateIqiStakeholder && checkExternalAgentBankInfoError) {
          this.setState({ showExternalAgent: true, enableValidateBank: true })
        } else {
          Put(
            `/admin/sub_sale_claims/${id}`,
            {
              ...dataToSubmit,
              revised_co_agency_fee_amount: dataToSubmit?.revised_co_agency_fee_amount > 0 
                ? dataToSubmit?.revised_co_agency_fee_amount
                : 0
            },
            this.updateSelectedClaimSuccess,
            this.updateSelectedClaimError,
            this.load,
          );
        }
      } else {
        Put(
          `/admin/sub_sale_claims/${id}`,
          {
            ...dataToSubmit,
            revised_co_agency_fee_amount: dataToSubmit?.revised_co_agency_fee_amount > 0 
              ? dataToSubmit?.revised_co_agency_fee_amount
              : 0
          },
          this.updateSelectedClaimSuccess,
          this.updateSelectedClaimError,
          this.load,
        );
      }
    };
    updateSelectedClaimSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.setState({ showExternalAgent: false })
      this.getSubmittedSubsaleClaims(currentPage, searchParams);
      requestSuccess("Claim updated successfully.");
      this.getSelectedClaim(payload.id);
    };
    updateSelectedClaimError = (error) => requestError(error);

    getPendingReassign = () =>
      Get(
        `/admin/reassign_submission_requests/pending_count`,
        this.getPendingReassignSuccess,
        this.getPendingReassignError,
        (param) => this.setState({ reassignLoading: param }),
      );
    getPendingReassignSuccess = (payload) =>
      this.setState({ pendingRessignCount: payload.pending_count });
    getPendingReassignError = (error) => requestError(error);

    postClaim = (dataToSubmit) => {
      this.setState({ disableCreateButton: true });
      let temp = _.cloneDeep(dataToSubmit);

      if (["1", "2"].indexOf(temp.form_type_id) > -1) {
        delete temp.form_method_id;
        delete temp.agency_id;
        delete temp.sub_sale_type_id;
      }
      if (temp.form_method_id === "1" || temp.form_type_id === "4") {
        delete temp.agency_id;
        delete temp.sub_sale_type_id;
      }
      if (temp.form_method_id === "2") {
        delete temp.confirmation_form_id;
        delete temp.confirmation_form_number;
      }
      Post(
        `/admin/sub_sale_claims`,
        temp,
        this.postClaimSuccess,
        this.postClaimError,
        this.load,
      );
    };
    postClaimSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      requestSuccess("Subsale Claim is created successfully.");
      this.getSubmittedSubsaleClaims(currentPage, searchParams);
      this.getSelectedClaim(payload.id);
      this.setState({
        showCreateClaim: false,
        disableCreateButton: false,
        showSubmitConfirmation: false
      });
    };
    postClaimError = (error) => {
      this.setState({ disableCreateButton: false });
      requestError(error);
    };

    updateRepresentativeType = (claim_id, representative_id) => {
      Put(
        `/admin/sub_sale_claims/${claim_id}/change_representative_id`,
        {representative_id: representative_id},
        payload => this.updateRepresentativeTypeSuccess(payload, claim_id),
        this.updateRepresentativeTypeError,
        this.load
      );
    };
    updateRepresentativeTypeSuccess = (payload, claim_id) => {
      this.getSelectedClaim(claim_id)
      requestSuccess(payload.message);
    };
    updateRepresentativeTypeError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showExternalAgent={this.state.showExternalAgent}
            enableValidateBank={this.state.enableValidateBank}
            pendingRessignCount={this.state.pendingRessignCount}
            selectedSection={this.state.selectedSection}
            searchParams={this.state.searchParams}
            showCreateClaim={this.state.showCreateClaim}
            claims={this.state.claims}
            selected_claim={this.state.selected_claim}
            claim_activities={this.state.claim_activities}
            onLoadSubsaleClaims={this.state.loading}
            onLoadReassign={this.state.reassignLoading}
            totalPages={this.state.totalPages}
            showReassignModal={this.state.showReassignModal}
            showTransferModal={this.state.showTransferModal}
            showUpdateClaimFormModal={this.state.showUpdateClaimFormModal}
            showAssignContactModal={this.state.showAssignContactModal}
            disableCreateButton={this.state.disableCreateButton}
            townshipsList={this.state.townshipsList}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            getSelectedTownShip={this.getSelectedTownShip}
            postClaim={this.postClaim}
            getPendingReassign={this.getPendingReassign}
            getSelectedClaim={this.getSelectedClaim}
            getActivity={this.getActivity}
            updateSelectedClaim={this.updateSelectedClaim}
            transferConfirmation={this.transferConfirmation}
            onChangeClaimsHOC={this.onChangeClaimsHOC}
            assignContact={this.assignContact}
            getSubmittedSubsaleClaims={this.getSubmittedSubsaleClaims}
            approveSubsaleClaim={this.approveSubsaleClaim}
            rejectSubsaleClaim={this.rejectSubsaleClaim}
            updateCreateSubsales={this.updateCreateSubsales}
            updateRepresentativeType={this.updateRepresentativeType}
            onChangeUpdateSelectedClaim={this.onChangeUpdateSelectedClaim}
            changeClaimsState={this.changeClaimsState}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getBranches,
    storeLastView,
    refreshToken,
    getAllDictionaryData,
    storeSubsalesAddress,
  })(WithHOC);
};

export default HOC;
