import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import CustomInput from "components/Input/formInput";
import CustomSelect from "components/Select";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 20,
  },
}))(Tooltip);

const ClaimTypeOptions = [
  { id: 1, name: "Claim Commission", value: 1, label: "Claim Commission" },
  { id: 2, name: "Refund", value: 2, label: "Refund" },
  { id: 3, name: "Forfeit", value: 3, label: "Forfeit" },
  { id: 4, name: "Renewal", value: 4, label: "Renewal" },
  { id: 5, name: "Release", value: 5, label: "Release" },
  { id: 6, name: "Cancel Form", value: 6, label: "Cancel Form" },
  { id: 7, name: "Request Invoice", value: 7, label: "Request Invoice" },
  {
    id: 8,
    name: "Request Official Receipt",
    value: 8,
    label: "Request Official Receipt",
  },
  {
    id: 9,
    name: "Request TA Stamping",
    value: 9,
    label: "Request TA Stamping",
  },
];

const getRecipients = (representative_id, sub_sale_type_id, external_agency_id) => {
  let tmp = [];
  if ([1, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_owner", label: sub_sale_type_id === 1 ? "Vendor" : "Landlord" });
  }
  if ([2, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_client", label: sub_sale_type_id === 1 ? "Purchaser" : "Tenant" });
  }

  if(external_agency_id && external_agency_id !== null) {
    tmp.push({ value: "issue_to_agency", label: "Co-Agency" });
  }

  return tmp;
};

const ClaimParty = (sub_sale_type_id) => [
  {
    id: 1,
    value: 1,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
  },
  {
    id: 2,
    value: 2,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
  }
]


const AssignTo = [
  { label: "Auto Assign", value: 1 },
  { label: "Assign to me", value: 0 },
];

const Create = ({
  admins,
  showConfirmCreateSubmission,
  showCloseConfirmation,
  isCreateSubmissionError,
  createSubmission,
  selected_claim,
  onLoadAdmins,
  onLoadSubmissions,
  role,
  refundParticipants,

  onChangeSubmissionHOC,
  onCloseCopyRemarkConfirmation,
  getRefundParticipants,
  getAdmins,
  onClose,
}) => {
  const [showTooltip, onShowToolTip] = useState(false);
  const [issueToArray, onChangeIssueToArray] = useState([]);
  const [newSubmission, onChangeNewSubmission] = useState({
    type_id: "",
    description: "",
    claim_party_id: 0,
    assignee_id: null,
    assign_option_id: 1,
    issue_to_owner: false,
    issue_to_client: false,
    issue_to_agency: false,
  });

  const onChangeCreateField = (val, context) => {
    let temp = _.cloneDeep(newSubmission);
    temp[context] = val;

    onChangeNewSubmission(temp);
  };

  const onChangeType = (val) => {
    let tmp = _.cloneDeep(newSubmission);
    tmp.type_id = val;
    tmp.issue_to_owner = false;
    tmp.issue_to_client = false;
    tmp.issue_to_agency = false;

    if (val !== 2 && tmp.refund_details_attributes) {
      delete tmp.refund_details_attributes;
    }

    const { 
      representative_id,
      external_agency_id,
      sub_sale_type_id
    } = selected_claim

    let tempIssueToArray = [];
    switch (val) {
      case 7:
      case 8:
        tempIssueToArray = getRecipients(representative_id, sub_sale_type_id, external_agency_id);
        break;

      default:
        break;
    }
    if( tempIssueToArray.length === 1 ) {
      tmp[tempIssueToArray[0].value] = true
    }

    getAdmins(val);
    onChangeIssueToArray(tempIssueToArray);
    onChangeNewSubmission(tmp);
  };

  const onCloseModalDialog = () => {
    if (isCreateSubmissionError) {
      onChangeSubmissionHOC("showCloseConfirmation", true)
    } else {
      onClose();
    }
  }

  const onCopyRemarks = () => {
    Promise.all([
      navigator.clipboard.writeText(newSubmission.description)
    ]).then(() => {
      onShowToolTip(true);
    })
  }

  const renderRefundSubmission = () => {
    if(refundParticipants?.some(item => item.participants?.length > 0) ) {
      return (
        <section className="grid-full-col at-claim_submission-participants_cont">
          {refundParticipants.map((item, index) => {
            const { refund_details_attributes = [] } = newSubmission;
            const tmpAttributes = _.cloneDeep(refund_details_attributes);
            return (
              item.participants.length > 0 && (
                <>
                  <p className="at-form-input__title">{item.type}</p>
                  <div
                    key={`participant-type-${index}`}
                    className="at-claim_submission-participant_item_cont"
                  >
                    <div className="d-flex flex-column grid_gap-2">
                      {item.participants.map((participant, nestedIndex) => {
                        let tmpItem = refund_details_attributes.find(
                          (attribute) =>
                            attribute.participant_id === participant.id,
                        );
                        let tmpIndex = tmpAttributes.findIndex(
                          (attribute) =>
                            attribute.participant_id === participant.id,
                        );
  
                        return (
                          <div
                            key={`participant-${nestedIndex}`}
                            className="at-claim_submission-participant d-flex flex-wrap grid_gap-1"
                          >
                            <CustomCheckbox
                              labelClassname="m-0"
                              content={participant.name}
                              checked={tmpItem}
                              contentStyle={{ fontWeight: 500 }}
                              onChangeCheckboxValue={(event) => {
                                const tmpOriData = _.cloneDeep(tmpItem);
                                if (!tmpOriData) {
                                  let tmp = {
                                    amount: 0,
                                    remark: "",
                                    ...tmpOriData,
                                    type_id: item.type_id,
                                    participant_type: item.participant_type,
                                    participant_id: participant.id,
                                  };
                                  tmpAttributes.push(tmp);
                                } else {
                                  tmpAttributes.splice(tmpIndex, 1);
                                }
                                onChangeCreateField(
                                  tmpAttributes,
                                  "refund_details_attributes",
                                );
                              }}
                            />
                            <CustomInput
                              disabled={!tmpItem}
                              type={"number"}
                              containerStyle={{ width: 200 }}
                              placeholder="Refund amount"
                              value={tmpAttributes[tmpIndex]?.amount || ""}
                              onChangeValue={(val) => {
                                let tmpRefundAttributes =
                                  _.cloneDeep(tmpAttributes);
                                tmpRefundAttributes[tmpIndex].amount = val;
                                onChangeCreateField(
                                  tmpRefundAttributes,
                                  "refund_details_attributes",
                                );
                              }}
                            />
                            <AtlasTextarea
                              rows={1}
                              placeholder={"Remark"}
                              containerStyle={{
                                width: "calc(100% - 482px - 2rem)",
                                minWidth: 200,
                              }}
                              style={{ minHeight: 41 }}
                              disabled={!tmpItem}
                              value={tmpAttributes[tmpIndex]?.remark || ""}
                              onChangeValue={(val) => {
                                let tmpRefundAttributes =
                                  _.cloneDeep(tmpAttributes);
                                tmpRefundAttributes[tmpIndex].remark = val;
                                onChangeCreateField(
                                  tmpRefundAttributes,
                                  "refund_details_attributes",
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )
            );
          })}
        </section>
      );
    }
    return (
      <div className="grid-full-col at-empty-state" style={{borderRadius: 8}}>
        <div className={`at-empty-state__icon`}>
          <AtlasIcon svgHref="atlas-document-text"/>
        </div>
        <h4 className="title">{`There is no participant available for the submission.`}</h4>
      </div>
    )
  };

  return (
    <>
      <ModalDialog
        title={"Submit My Claims"}
        onClose={onCloseModalDialog}
        children={
          <form
            className="at-profile__container grid-control grid_gap-x-2"
            onSubmit={(e) => e.preventDefault()}>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">{"Submission Type"}</h2>
              <Grid container spacing={0} style={{ marginTop: 0 }}>
                {ClaimTypeOptions.map((item) => {
                  return (
                    <Grid
                      key={item.id}
                      item
                      md={4}
                      xs={12}
                      style={{ padding: "0px 8px" }}>
                      <input
                        className="at-radio__input"
                        type="radio"
                        id={item.value}
                        value={item.value}
                        checked={item.value === newSubmission.type_id}
                        readOnly={true}/>
                      <label
                        className="at-radio__label m-0"
                        onClick={() => {
                          onChangeType(item.value);
                          if ([2, 3, 5].indexOf(item.value) > -1) {
                            getRefundParticipants(selected_claim.id, item.value);
                          }
                        }}>
                        <div className="at-radio__checker"></div>
                        <p className="at-radio__content">{item.label}</p>
                      </label>
                    </Grid>
                  );
                })}
              </Grid>
            </section>
            {[7, 8].indexOf(newSubmission.type_id) !== -1 && (
              <section className="grid-full-col">
                <h2 className="at-form-input__title">Issue To</h2>
                {issueToArray.map((item) => (
                  <CustomCheckbox
                    content={item.label}
                    checked={newSubmission[item.value]}
                    onChangeCheckboxValue={(event) =>
                      onChangeCreateField(event.target.checked, item.value)
                    }
                  />
                ))}
              </section>
            )}
            {newSubmission.type_id !== "" && newSubmission.type_id === 1 && (
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Claim Party"}</h2>
                <AtlasRadioGroup
                  mode="old"
                  options={ClaimParty(selected_claim.sub_sale_type_id)}
                  checkedValue={newSubmission.claim_party_id}
                  selectedRadioValue={(val) =>
                    onChangeCreateField(val, "claim_party_id")
                  }
                />
              </section>
            )}
            {role !== "Admin" && (
              <section className="grid-full-col">
                <h4 className={"at-form-input__title"}>{"Assign Admin"}</h4>
                <CustomSelect
                  className="w-100"
                  selectItems={admins}
                  labelKey={"name"}
                  valueKey={"id"}
                  currentlySelected={admins.find(item => item.value === newSubmission.assignee_id)
                    ? admins.find( item => item.id === newSubmission.assignee_id)
                    : ''
                  }
                  updateSelect={(selectedItem) =>{
                    if( selectedItem ) {
                      onChangeCreateField(selectedItem.id, "assignee_id")
                    }
                  }}
                  renderOptions={(menuItem) => {
                    return (
                      <div className="d-flex align-items-center">
                        <span
                          className="at-submission__online-status"
                          style={{
                            backgroundColor:
                              menuItem.online_status === 1 ? "green" : "red",
                          }}
                        ></span>
                        {menuItem.name}
                      </div>
                    );
                  }}
                />
              </section>
            )}
            {[2, 3, 5].indexOf(newSubmission.type_id) > -1 && renderRefundSubmission()}
            {!([2, 3, 5].indexOf(newSubmission.type_id) > -1) && (
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Remark"}</h2>
                <AtlasTextarea
                  rows={5}
                  style={{ marginBottom: 0 }}
                  value={newSubmission.description}
                  onChangeValue={(val) =>
                    onChangeCreateField(val, "description")
                  }
                />
              </section>
            )}
            {role === "Admin" && ![7, 8].includes(newSubmission.type_id) && (
              <section className="grid-full-col">
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={newSubmission.assign_option_id}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) =>
                    onChangeCreateField(Number(val), "assign_option_id")
                  }
                  options={AssignTo}
                />
              </section>
            )}
            <section className="grid-full-col">
              <AlertBox
                mode={"info"}
                containerClassName="mb-3"
                description={
                  <>
                    <p>
                      1. Before you clicked the button to submit, please check
                      all the supported documents had been uploaded for
                      approval.
                    </p>
                    <p>
                      2. Please state in the description as much details as
                      possible, for example Amount for Requested Invoice
                      (include/exclude SST), requested invoice for co-agency /
                      owner or other party, percentage of the claim commission
                      and etc.
                    </p>
                  </>
                }
              />
            </section>
          </form>
        }
        footer={
          <div className="d-flex flex-wrap grid_gap-2">
            <button
              disabled={onLoadSubmissions}
              className="btn-new btn-new--primary"
              onClick={() => onChangeSubmissionHOC("showConfirmCreateSubmission", true)}
            >
              Submit to Admin Now
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={onCloseModalDialog}
            >
              Cancel
            </button>
          </div>
        }
      />
      {/* Pop up confirmtion dialog to copy remark if submission failed */}
      {
        showCloseConfirmation && (
          <ModalDialog
            title={"Copy Remarks"}
            responsiveSize={"sm"}
            onLoad={onLoadSubmissions}
            children={
              <>
                <p className="fs-2">We noticed you entered remarks. To keep them for future reference, copy them to your clipboard before closing this window.</p>
                <div className="mt-3">
                  <h2 className="at-form-input__title">Remarks</h2>
                  <AtlasTextarea
                    rows={5}
                    className={"mb-0"}
                    containerClassName={"w-100"}
                    value={newSubmission.description}
                    onChangeValue={(val) => onChangeCreateField(val, "description")}
                  />
                </div>
              </>
            }
            onClose={() => onCloseCopyRemarkConfirmation()}
            footer={
              <div className="d-flex g-3">
                <DarkTooltip
                  open={showTooltip}
                  classes={{
                    tooltip: `tooltip-arrow top`,
                  }}
                  onClose={() => onShowToolTip(false)}
                  placement={"top"}
                  enterTouchDelay={50}
                  title={"Copied!"}>
                  <span
                    data-tip={"Copied!"}
                    data-for={"copy_remark"}>
                    <button
                      type={"button"}
                      className="btn-new btn-new--primary"
                      onClick={ () => onCopyRemarks() }>
                      Copy
                    </button>
                  </span>
                </DarkTooltip>
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary"
                  onClick={onCloseCopyRemarkConfirmation}>
                  Cancel
                </button>
              </div>
            }
          />
        )
      }
      <ConfirmationModal
        open={showConfirmCreateSubmission}
        loading={onLoadSubmissions || onLoadAdmins}
        message={
          <>
            {newSubmission.type_id !== 6 && (
              <span className="text-danger">
                Please note that if this submission is made during the weekend,
                public holidays, or after working hours, the request will be
                processed by the admin on the next business day.
                <br />
                <br />
              </span>
            )}
            Would you like to proceed with submitting your request to the admin?
          </>
        }
        positiveAction={() =>
          createSubmission(selected_claim.id, newSubmission)
        }
        negativeAction={() => onChangeSubmissionHOC("showConfirmCreateSubmission", false)}
      />
      {onLoadSubmissions && <LoadingModal />}
    </>
  );
};

export default Create;
