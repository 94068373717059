import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import UnderlineTab from "components/Tab/new";
import DuoTab from "components/Tab/duo";
import AlertBox from "components/AlertBox";
import AtlasBackButton from "components/Button/prev";
import AtlasCloseButton from "components/Button/close";
import ConfirmationModal from "components/Modal/confirmation";
import DownloadContent from "./downloadContent";
import EProcessContent from "./eProcessContent";
import LoadingModal from "components/LoadingModal";
import UnlockContent from "./unlockFormRequestContent";
import PartyContent from "../../components/partyContent";
import PropertyContent from "../../components/propertyTabContent";
import SubrentConfirmationContent from "./tempSubrentConfirmationContent";
import SubsaleConfirmationContent from "./tempSubsaleConfirmationContent";
import TenancyAgreement from "./tenancyAgreement";
import InventoryList from "./inventoryList";
import FormChart from "components/Chart/form";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasRadioGroup from "components/RadioGroup";

import ContactHoc from "actions/contact";
import BankHOC from "actions/banks";
import EditConfirmationHOC from "../actions/edit";
import PartyHOC from "../actions/party";
import RefundHOC from "../actions/refund";
import DraftHOC from "../actions/draftEmail";
import AgreementHOC from "../actions/agreement";
import InventoryHOC from "../actions/inventory";
import PropertyHOC from "../actions/listing";
import { checkIsNumber } from "utils/checkNull";
import isEmptyValue from "utils/isEmpty";
import permissionsChecker from "utils/permissionsChecker";

import { NavTabs, tenancyArray, partyDictionary } from "../assets/index";

const FormTabs = ["Contract View", "Form View"];
const SigningTabs = ["e-Signing", "Manual Signing"];

const RepresentativeInfo = (representative_ids) => {
  let tmpRepresentative_ids = representative_ids?.sort((a,b) => b-a)
  let tmp = []
  if(tmpRepresentative_ids && tmpRepresentative_ids !== null) {
    tmpRepresentative_ids.map((id) => {
      switch(id) {
        case 1:
        case 2:
          tmp.push({
            id: id,
            label: `Separate Claim`,
            value: id,
          })
          break;
        case 3:
          tmp.push({
            id: 3,
            label: `Joint Claim`,
            value: 3,
          })
          break;
        default: break
      }
    })
  }
  
  return tmp
};

const getRepresentativePartyName = (representative_id, type_id) => {
  switch(representative_id) {
    case 1:
      return type_id === 1 ? "Vendor" : "Landlord";
    case 2:
      return type_id === 1 ? "Purchaser" : "Tenant";
  }
}

const RadioOptions = ({ can_buyer_submit, can_owner_submit }) => {
  let tmpRadioOptons = [];
  if (can_buyer_submit) {
    tmpRadioOptons.push({ label: "Tenant/Purchaser", value: "2" });
  }
  if (can_owner_submit) {
    tmpRadioOptons.push({ label: "Landlord/Vendor", value: "1" });
  }
  return tmpRadioOptons;
};

const getStampingFee = (
  monthly_rental_amount = 0,
  intended_tenure_years = 0,
) => {
  let feeByStandard = +monthly_rental_amount * 12 - 2400;
  let kiloValue = Math.trunc(feeByStandard / 1000);
  let beforeRoundedUpValue = feeByStandard - Math.trunc(kiloValue) * 1000;
  let afterRoundedUpValue = 0;
  let roundUpFactors = [0, 250, 500, 750, 1000];

  afterRoundedUpValue = roundUpFactors.find(
    (factor) => beforeRoundedUpValue <= factor,
  );

  let roundedUpFee = kiloValue.toFixed(0) * 1000 + afterRoundedUpValue;

  if (intended_tenure_years > 0 && intended_tenure_years <= 1) {
    return roundedUpFee / 250 + 10;
  } else if (intended_tenure_years > 1 && intended_tenure_years <= 3) {
    return (roundedUpFee / 250) * 2 + 10;
  } else if (intended_tenure_years > 3) {
    return (roundedUpFee / 250) * 4 + 10;
  }
};

class EditModal extends Component {
  state = {
    autoCalculatedList: [],
    onAutoCalculateDownPaymentPercentage: false,
    showConfirmationModal: false,
    adminView: true,
    toSubmitData: {},
  };

  componentDidMount = () => {
    const { tempEProcess, selectedConfirmationForm } = this.props;

    tempEProcess && this.props.getSigningStatus(selectedConfirmationForm.id);
  };

  componentDidUpdate = (pp) => {
    const {
      refundStatusId,
      selectedSection,
      selectedConfirmationForm,
    } = this.props;

    if (pp.refundStatusId !== refundStatusId && refundStatusId !== null) {
      this.props.getRefundForm(selectedConfirmationForm.id);
    }

    if (pp.selectedSection !== selectedSection) {
      const { selectedConfirmationForm } = this.props;

      if(this.props.showEditModal) {
        this.props.getConfirmationForm(selectedConfirmationForm.id);
      }
      if([ "Participants", "Contract Signing", "Temp. Receipt", "Tenancy Agreement" ].indexOf(selectedSection) > -1) {
        this.props.getParty(selectedConfirmationForm.id);

        if(selectedSection === 'Participants') {
          this.props.getCurrentFormContactList(selectedConfirmationForm.id);
        }
      }
      if(selectedSection === 'Inventory Checklist') {
        this.props.getInventoryItems();
      }
      if([ "Contract Signing", "Tenancy Agreement"].indexOf(selectedSection) > -1) {
        this.props.getBanks();
      }
    }
  };

  onChangeEditConfirmationForm = (context, value) => {
    this.setState({
      [context]: value,
    });
  };

  onChangeEditSubsale = (context, value = "") => {
    const { selectedConfirmationForm, onChangeConfirmationFormHOC } =
      this.props;

    let tmpAutoCalculatedList = [];
    let tmp = _.cloneDeep(selectedConfirmationForm);

    if (context === "TriggerIcon") {
      this.setState({ onAutoCalculateDownPaymentPercentage: value });
    } else {
      tmp[context] = value;
      const { purchase_price = 0 } = tmp;

      if (context === "purchase_price" && checkIsNumber(purchase_price) && this.state.onAutoCalculateDownPaymentPercentage) {
        tmp.down_payment_amount = (
          (purchase_price * tmp.down_payment_percentage) /
          100
        ).toFixed(2);
        tmp.balance_deposit_amount = (
          (purchase_price * tmp.balance_deposit_percentage) /
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push(
          "down_payment_amount",
          "balance_deposit_amount",
        );
      }

      if (
        context === "down_payment_percentage" &&
        checkIsNumber(tmp.down_payment_percentage) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        if (tmp.down_payment_percentage <= 10) {
          tmp.balance_deposit_percentage = (
            10 - tmp.down_payment_percentage
          ).toFixed(2);
          tmp.balance_deposit_amount = (
            (purchase_price * tmp.balance_deposit_percentage) /
            100
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_deposit_percentage",
            "balance_deposit_amount",
          );
        }
      }
      if (
        context === "down_payment_percentage" &&
        checkIsNumber(tmp.down_payment_percentage) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.down_payment_amount = (
          (purchase_price * tmp.down_payment_percentage) /
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("down_payment_amount");

        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_deposit_percentage" &&
        checkIsNumber(tmp.balance_deposit_percentage) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.balance_deposit_amount = (
          (purchase_price * tmp.balance_deposit_percentage) /
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("balance_deposit_amount");
        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_deposit_percentage" &&
        checkIsNumber(tmp.balance_deposit_percentage) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        if (tmp.balance_deposit_percentage <= 10) {
          tmp.down_payment_percentage = (
            10 - tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.down_payment_amount = (
            (purchase_price * tmp.down_payment_percentage) /
            100
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "down_payment_percentage",
            "down_payment_amount",
          );
        }
      }

      if (
        context === "down_payment_amount" &&
        checkIsNumber(tmp.down_payment_amount) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.down_payment_percentage = (
          (tmp.down_payment_amount / purchase_price) *
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("down_payment_percentage");
        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_deposit_amount" &&
        checkIsNumber(tmp.balance_deposit_amount) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.balance_deposit_percentage = (
          (tmp.balance_deposit_amount / purchase_price) *
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("balance_deposit_percentage");

        if (
          +tmp.down_payment_percentage + +tmp.balance_deposit_percentage <
          100
        ) {
          tmp.balance_purchase_price_percentage = (
            100 -
            tmp.down_payment_percentage -
            tmp.balance_deposit_percentage
          ).toFixed(2);
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push(
            "balance_purchase_price_percentage",
            "balance_purchase_price",
          );
        }
      }
      if (
        context === "balance_purchase_price" &&
        checkIsNumber(tmp.balance_purchase_price) &&
        this.state.onAutoCalculateDownPaymentPercentage
      ) {
        tmp.balance_purchase_price_percentage = (
          (tmp.balance_purchase_price / purchase_price) *
          100
        ).toFixed(2);
        tmpAutoCalculatedList.push("balance_purchase_price_percentage");
      }
      if (
        (context === "purchase_price" || context === "balance_purchase_price_percentage") && this.state.onAutoCalculateDownPaymentPercentage
      ) {
        if (
          checkIsNumber(purchase_price) &&
          checkIsNumber(tmp.balance_purchase_price_percentage)
        ) {
          tmp.balance_purchase_price = (
            purchase_price -
            tmp.balance_deposit_amount -
            tmp.down_payment_amount
          ).toFixed(2);
          tmpAutoCalculatedList.push("balance_purchase_price");
        }
      }

      this.setState({ autoCalculatedList: tmpAutoCalculatedList });
      return onChangeConfirmationFormHOC("selectedConfirmationForm", tmp);
    }
  };

  onChangeEditSubrent = (context, value = "") => {
    const { selectedConfirmationForm, onChangeConfirmationFormHOC } =
      this.props;

    let tmpAutoCalculatedList = [];
    let tmp = _.cloneDeep(selectedConfirmationForm);

    tmp[context] = value;

    const {
      monthly_rental_amount = 0,
      security_deposit_months = 0,
      utility_deposit_months = 0,
      intended_tenure_years = 0,
      advanced_rental_months = 0
    } = tmp;

    if(context === "monthly_rental_amount") {
      tmp["one_month_earnest_deposit"] = (+monthly_rental_amount)?.toFixed(2)
      tmpAutoCalculatedList.push("one_month_earnest_deposit")
    }

    if(tmp.include_advanced_rental) {
      if(context === "monthly_rental_amount" || context === "advanced_rental_months") {
        if(checkIsNumber(monthly_rental_amount) && checkIsNumber(advanced_rental_months)) {
          tmp["advanced_rental_amount"] = (advanced_rental_months * monthly_rental_amount)?.toFixed(2)
          tmpAutoCalculatedList.push("advanced_rental_amount");
        }
      }
    }
    if (
      context === "monthly_rental_amount" ||
      context === "security_deposit_months"
    ) {
      if (
        checkIsNumber(monthly_rental_amount) &&
        checkIsNumber(security_deposit_months)
      ) {
        tmp.security_deposit_amount = (monthly_rental_amount * security_deposit_months).toFixed(2);
        tmpAutoCalculatedList.push("security_deposit_amount");
      }
    }
    if (
      context === "monthly_rental_amount" ||
      context === "utility_deposit_months"
    ) {
      if (checkIsNumber(monthly_rental_amount && checkIsNumber(utility_deposit_months))) {
        tmp.utility_deposit_amount = (monthly_rental_amount * utility_deposit_months).toFixed(2);
        tmpAutoCalculatedList.push("utility_deposit_amount");
      }
    }
    if (
      context === "monthly_rental_amount" ||
      context === "intended_tenure_years"
    ) {
      if (checkIsNumber(monthly_rental_amount) && intended_tenure_years > 0) {
        tmp.stamping_fee = getStampingFee(monthly_rental_amount,intended_tenure_years).toFixed(2);
        tmpAutoCalculatedList.push("stamping_fee");
      }
    }
    this.setState({ autoCalculatedList: tmpAutoCalculatedList });
    onChangeConfirmationFormHOC("selectedConfirmationForm", tmp);
  };

  onChangeBlur = (context) => {
    let tmp = _.cloneDeep(this.props.selectedConfirmationForm);
    if (!tmp[context] || tmp[context] == "") {
      tmp[context] = 0;
    }
    this.props.onChangeConfirmationFormHOC("selectedConfirmationForm", tmp);
  };

  onChangeFocus = (context) => {
    let tmp = _.cloneDeep(this.props.selectedConfirmationForm);
    if (!tmp[context] || tmp[context] == 0) {
      tmp[context] = "";
    }
    this.props.onChangeConfirmationFormHOC("selectedConfirmationForm", tmp);
  };

  renderNavButton = (selectedTab) => {
    const {
      selectedConfirmationForm,
      onChangePartyHOC,
      onChangeConfirmationFormHOC,
    } = this.props;
    let tmpTabs = NavTabs(selectedConfirmationForm.type_id);
    let tmpIndex = tmpTabs.findIndex((item) => item.label === selectedTab);
    let lastIndex =
      tmpTabs.findIndex((item) => item.label === "Contract Signing") + 1;

    return (
      <>
        {tmpIndex - 1 >= 0 && (
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() =>
              onChangePartyHOC(tmpTabs[tmpIndex - 1].label, "selectedSection")
            }
          >
            <p>{tmpTabs[tmpIndex - 1].label}</p>
          </button>
        )}
        {tmpIndex + 1 < lastIndex && (
          <button
            className="btn-new btn-new--secondary at-btn_next"
            onClick={() =>
              onChangePartyHOC(tmpTabs[tmpIndex + 1].label, "selectedSection")
            }
          >
            <p>{tmpTabs[tmpIndex + 1].label}</p>
          </button>
        )}
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() => onChangeConfirmationFormHOC("showEditModal", false)}
        >
          Close
        </button>
      </>
    );
  };

  renderTenancyAgreementContent = () => {
    const {
      onLoadTenancyAgreement,
      selectedConfirmationForm,
      onChangeConfirmationFormHOC,
      getTenancyAgreement,
    } = this.props;

    const { id, transaction_number } = selectedConfirmationForm;

    return (
      <>
        <div className={"d-flex mb-20"}>
          <h2 className={"at-card__title"}>Download Tenancy Agreement</h2>
          <AtlasCloseButton
            onClick={() =>
              onChangeConfirmationFormHOC("showTenancyAgreement", false)
            }
            containerStyle={{
              display: "flex",
              marginLeft: "auto",
            }}
          />
        </div>
        {tenancyArray.map((item, index) => (
          <button
            key={index}
            className={"btn-new btn-new--secondary mt-20 w-100"}
            onClick={() => {
              getTenancyAgreement(
                id,
                item.value,
                `${transaction_number}_${item.label}`,
              );
            }}
          >
            {item.label}
          </button>
        ))}
        {onLoadTenancyAgreement && <LoadingModal />}
      </>
    );
  };

  renderContractParicipants = () => {
    const { selectedConfirmationForm, closedEditing } = this.props;
    return (
      <>
        <h2 className="at-content-title mb-10">Participants</h2>
        <PartyContent
          {...this.props}
          closedEditing={ closedEditing }
          mode={"new"}
          form_type={"confirmation"}
          partyDictionary={partyDictionary}
          selected_form={selectedConfirmationForm}
        />
      </>
    );
  };
 
  renderPropertyTabContent = () => {
    const { 
      onLoadEdit, 
      onLoadConfirmationForms, 
      selectedSection, 
      closedEditing,
      selectedConfirmationForm,
    } = this.props;
    const {
      is_claimed,
      is_buyer_claimed,
      is_owner_claimed,
    } = selectedConfirmationForm
    const { can_update } = permissionsChecker("Forms", this.props.data);

    let tmpIsClaimed = (is_claimed || is_buyer_claimed || is_owner_claimed)
  
    return (
      <PropertyContent
        formType={"ConfirmationForm"}
        data={this.props.data}
        townshipsList={this.props.townshipsList}
        closedEditing={!can_update || tmpIsClaimed}
        loading={onLoadEdit || onLoadConfirmationForms}
        onChange={(value, context) =>
          this.props.onChangeConfirmationFormHOC(context, value)
        }
        formData={this.props.selectedPropertyData}
        autoSuggestOptions={this.props.autoSuggestOptions}
        updateForm={this.props.updatePropertyListing}
        edgeAutoSuggest={this.props.edgeAutoSuggest}
        autoSuggestLoading={this.props.autoSuggestLoading}
        getSelectedTownShip={this.props.getSelectedTownShip}
        onClickCloseForm={() =>
          this.props.onChangeConfirmationFormHOC("showEditModal", false)
        }
        renderNavButton={() => this.renderNavButton(selectedSection)}
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
      />
    );
  };

  renderContractParticipants = () => {
    const { selectedConfirmationForm, selectedSection } = this.props;
    return (
      <PartyContent
        {...this.props}
        mode={"new"}
        form_type={"confirmation"}
        partyDictionary={partyDictionary}
        selected_form={selectedConfirmationForm}
        renderNavButton={() => this.renderNavButton(selectedSection)}
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
      />
    );
  };

  renderConfirmationForm = () => {
    const {
      data,
      saveTnC,
      selectedSection,
      closedEditing,
      selectedConfirmationForm,
      onChangeConfirmationFormHOC,
      onChangeEditConfirmationForm,
    } = this.props;

    return (
      <>
        {selectedConfirmationForm.type_id === 1 && (
          <SubsaleConfirmationContent
            closedEditing={closedEditing}
            autoCalculatedList={this.state.autoCalculatedList}
            onAutoCalculateDownPaymentPercentage={
              this.state.onAutoCalculateDownPaymentPercentage
            }
            onChangeEditConfirmationForm={onChangeEditConfirmationForm}
            saveTnC={saveTnC}
            FormTabs={FormTabs}
            addressReducer={data.addressReducer}
            onChangeBlur={this.onChangeBlur}
            onChangeFocus={this.onChangeFocus}
            onChange={(context, value) => this.onChangeEditSubsale(context, value)}
            selectedConfirmationForm={selectedConfirmationForm}
            onChangeConfirmationFormHOC={onChangeConfirmationFormHOC}
            onTriggerSpecialConditionModal={() =>
              onChangeConfirmationFormHOC("showSpecialConditionRTE", true)
            }
            renderNavButton={() => this.renderNavButton(selectedSection)}
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          />
        )}
        {selectedConfirmationForm.type_id === 2 && (
          <SubrentConfirmationContent
            closedEditing={closedEditing}
            autoCalculatedList={this.state.autoCalculatedList}
            saveTnC={saveTnC}
            FormTabs={FormTabs}
            addressReducer={data.addressReducer}
            onChangeBlur={this.onChangeBlur}
            onChangeFocus={this.onChangeFocus}
            onChange={(context, value) =>
              this.onChangeEditSubrent(context, value)
            }
            selectedConfirmationForm={selectedConfirmationForm}
            onChangeConfirmationFormHOC={() => onChangeConfirmationFormHOC}
            onTriggerSpecialConditionModal={() =>
              onChangeConfirmationFormHOC("showSpecialConditionRTE", true)
            }
            renderNavButton={() => this.renderNavButton(selectedSection)}
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          />
        )}
      </>
    );
  };

  renderInventoryChecklist = () => {
    const {
      inventoryItems,
      selectedConfirmationForm,
      selectedSection,
      closedEditing,
      updateInventoryList,
    } = this.props;

    return (
      <InventoryList
        inventoryItems={inventoryItems}
        updateInventoryList={updateInventoryList}
        selectedConfirmationForm={selectedConfirmationForm}
        closedEditing={closedEditing}
        onChange={(context, value) => this.onChangeEditSubrent(context, value)}
        renderNavButton={() => this.renderNavButton(selectedSection)}
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
      />
    );
  };

  renderDraftVersion = () => {
    return <></>;
  };

  renderSigningContent = () => {
    const { selectedSigningTab, onChangePartyHOC, selectedSection } =
      this.props;
    return (
      <>
        <div className="at-form__content at-new_container-20">
          <h5 className="at-card_content-title">{"Contract Signing"}</h5>
          <p className="fs-2">
            How do you want to sign this contract? Choose only one method.
          </p>
        </div>
        <DuoTab
          sections={SigningTabs}
          selectedSection={selectedSigningTab}
          onSelectSection={(val) => onChangePartyHOC(val, "selectedSigningTab")}/>
        {selectedSigningTab === "Manual Signing" &&
          this.renderManualDownloadContent()}
        {selectedSigningTab === "e-Signing" && (
          <EProcessContent
            {...this.props}
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
            renderNavButton={() => this.renderNavButton(selectedSection)}
          />
        )}
      </>
    );
  };

  renderManualDownloadContent = () => {
    const {
      data,
      selectedSection,
      signingStatus,
      downloadAckReceipt,
      selectedConfirmationForm,
      onChangeConfirmationFormHOC,
      downloadConfirmationForm,
    } = this.props;

    const { id, transaction_number } = selectedConfirmationForm;

    const { role } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    const { sideMenuExpand } = this.props.data.sideMenuReducer;

    return (
      <>
        <AlertBox
          title={""}
          containerClassName="mb-3"
          containerStyle={{
            backgroundColor: "#FFFAEA",
            borderColor: "#FCB122",
            marginBottom: "1rem",
          }}
          iconStyle={{ color: "#F79008" }}
          description={`Please make sure that you have saved the data by clicking the "Save Terms & Conditions" button in T&C before proceeding any actions below.`}
        />
        <div className="at-form__content">
          <h5 className="at-card_content-title mb-3">{"Manual Signing"}</h5>
          <p className="fs-2">
            You can offer your client two options when signing the confirmation
            form:
          </p>
          <p className="fs-2">
            1. You can print out the confirmation form and cross out any
            inapplicable phrases or mistakes before your client signs the
            printed form.
          </p>
          <p className="fs-2">
            2. Should your client wish to use a digital signature for the
            confirmation form, you will need to attach a screenshot of the
            client sending you the confirmation form (via E-mail, WhatsApp,
            WeChat or any other platform) as proof of submission for claims.
          </p>
          <br />
          <p className="fs-2">
            Download Adobe Fill & Sign mobile app to sign PDF documents
            digitally.
          </p>
          <div className="d-flex flex-wrap grid_gap-1 mt-2">
            <button
              disabled={signingStatus === "Completed"}
              className="btn-new btn-new--secondary"
              onClick={() =>
                downloadConfirmationForm(id, `${transaction_number}`)
              }
            >
              {`Download Confirmation Form`}
            </button>
            {role === "Super Admin" && (
              <button
                className="btn-new btn-new--secondary"
                onClick={() =>
                  onChangeConfirmationFormHOC("showResetConfirmation", true)
                }
              >
                {`Reset Download Count`}
              </button>
            )}
          </div>
        </div>
        <div
          className={`at-floating_panel${sideMenuExpand ? " menu-expand" : ""}`}
        >
          {this.renderNavButton(selectedSection)}
        </div>
      </>
    );
  };

  renderTenancyAgreement = () => (
    <TenancyAgreement
      data={this.props.data}
      banks={this.props.banks}
      partyData={this.props.partyData}
      eProcessPartyData={this.props.eProcessPartyData}
      selectedPropertyData={this.props.selectedPropertyData}
      selectedConfirmationForm={this.props.selectedConfirmationForm}
      onLoadTenancyAgreement={this.props.onLoadTenancyAgreement}

      onClose={() => this.props.onChangeConfirmationFormHOC("showEditModal", false)}
      getTenancyAgreement={this.props.getTenancyAgreement}
      getConfirmationForm={this.props.getConfirmationForm}
      onChangeConfirmationFormHOC={this.props.onChangeConfirmationFormHOC}
      changeTabToPaticipant={() => this.props.onChangePartyHOC('Participants', "selectedSection")} />
  );

  renderFormsChart = () => {
    const {
      forms_chart,
      invoicing_branch_id,
      type_id,
      id,
      user_id,
    } = this.props.selectedConfirmationForm;

    return (
      <>
        {forms_chart !== null && (
          <FormChart
            infoData={{
              id: id,
              invoicing_branch_id: invoicing_branch_id,
              type_id: type_id,
            }}
            history={this.props.history}
            currentFormType={"Confirmation Form"}
            forms_chart={forms_chart}
            onClickButton={(data) => {
              this.props.onChangeConfirmationFormHOC(
                "showSubmitClaimForm",
                true,
              );
              this.setState({
                toSubmitData: {
                  ...data,
                  user_id: user_id,
                  representative_id: data.representativeIds.length === 1
                    ? data.representativeIds[0]
                    : ''
                },
              });
            }}
            onClickDetach={(data) => {
              this.setState({
                toSubmitData: data
              });

              this.props.onChangeConfirmationFormHOC(
                "showDetachConfirmation",
                true,
              );
            }}
          />
        )}
      </>
    );
  };

  renderClosingFormModal = () => {
    const tmpRepresentativeOptions = RepresentativeInfo(this.state.toSubmitData.representativeIds, this.props.selectedConfirmationForm.type_id)
    if (this.props.showSubmitClaimForm) {
      return (
        <ModalDialog
          title={"Submit Confirmation Form"}
          fullHeight={false}
          onClose={() =>
            this.props.onChangeConfirmationFormHOC("showSubmitClaimForm", false)
          }
          children={
            <div className="at-apt_letter-closing_form-cont">
              <p className="mb-3 fs-2">
                Please choose a claim type to submit the confirmation form.
              </p>
              {
                tmpRepresentativeOptions.length > 0 && (
                  <AtlasRadioGroup
                    horizontal={false}
                    mode="old"
                    checkedValue={this.state.toSubmitData.representative_id}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => {
                      this.setState({
                        toSubmitData: {
                          ...this.state.toSubmitData,
                          representative_id: val,
                        },
                      });
                    }}
                    options={tmpRepresentativeOptions}
                  />
                )
              }
              {
                tmpRepresentativeOptions.length === 0 && 'N/A'
              }
              <div className="at-modal_dialog-container-footer d-flex grid_gap-1">
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() => {

                    this.props.onChangeConfirmationFormHOC("showSubmitClaimForm",false)
                  }}>
                  Cancel
                </button>
                <button
                  disabled={!this.state.toSubmitData.representative_id}
                  className="btn-new btn-new--secondary"
                  onClick={() => {
                    this.props.onChangeConfirmationFormHOC(
                      "showSubmitClaimForm",
                      false,
                    )
                    this.props.onChangeConfirmationFormHOC(
                      "showSubmitConfirmationClaimForm",
                      true,
                    )
                  }}>
                  Next
                </button>
                
              </div>
            </div>
          }
        />
      );
    }
  };

  render = () => {
    const {
      onLoadEdit,
      onLoadConfirmationForms,
      onLoadForms,
      selectedSection,
      purchaserNTenant,
      selectedConfirmationForm,
      downloadAckReceipt,

      getConfirmationForm,
      claimedConfirmationForm,
      onChangeConfirmationFormHOC,
      onChangePartyHOC,
    } = this.props;
    const {
      transaction_number
    } = selectedConfirmationForm;

    const tmpTransactionNumber = `${!isEmptyValue(transaction_number) ? `/ ${transaction_number}` : ""}`;
    const { is_buyer_claimed, is_claimed, is_owner_claimed } = this.props.selectedConfirmationForm;

    let tmpDisabled = is_buyer_claimed || is_claimed || is_owner_claimed;
    return (
      <>
        <div className="at-card__header">
          <AtlasBackButton
            mode="new"
            onClick={() => onChangeConfirmationFormHOC("showEditModal", false)}
          />
          <h4 className="at-card__title">{`Edit Form ${tmpTransactionNumber}`}</h4>
        </div>
        {this.renderFormsChart()}
        <UnderlineTab
          sections={NavTabs(selectedConfirmationForm.type_id)}
          selectedSection={selectedSection}
          onSelectSection={(val) => onChangePartyHOC(val, "selectedSection")}
        />
        <div>
          {selectedSection === "Downloads" && (
            <DownloadContent
              selectedConfirmationForm={selectedConfirmationForm}
            />
          )}
          {selectedSection === "Property" && this.renderPropertyTabContent()}
          {selectedSection === "Participants" &&
            this.renderContractParticipants()}
          {selectedSection === "T&C" && this.renderConfirmationForm()}
          {selectedSection === "Inventory Checklist" &&
            this.renderInventoryChecklist()}
          {selectedSection === "Review" && this.renderDraftVersion()}
          {selectedSection === "Contract Signing" &&
            this.renderSigningContent()}
          {selectedSection === "Temp. Receipt" && (
            <UnlockContent
              purchaserNTenant={purchaserNTenant}
              downloadAckReceipt={downloadAckReceipt}
              getConfirmationForm={getConfirmationForm}
              selectedConfirmationForm={selectedConfirmationForm}
              onChangeConfirmationFormHOC={onChangeConfirmationFormHOC}
            />
          )}
          {selectedSection === "Tenancy Agreement" &&
            this.renderTenancyAgreement()}
        </div>
        {this.renderClosingFormModal()}
        <ConfirmationModal
          mode={"info"}
          open={this.props.showSubmitConfirmationClaimForm}
          title={"Closing Deal"}
          message={
            [1,2].indexOf(this.state.toSubmitData.representative_id) > -1 
              ? (
                <div>
                  <p className="text-left fs-2">
                    You’ve chosen Separate Claim. <b>This means you’ll be managing the claim for your { getRepresentativePartyName(this.state.toSubmitData.representative_id, this.props.selectedConfirmationForm.type_id) }.</b> By choosing this:
                  </p>
                  <ul style={{listStyleType: "disc", textAlign: "left", margin: "0.5rem 1rem", fontSize: 14}}>
                    <li>You can only submit and manage claims for <b>your own representing client.</b></li>
                    <li>You won’t be able to request anything on behalf of the other party involved.(Request Invoice/Official Receipt, etc.)</li>
                  </ul>
                  <p className="text-left fs-2 mb-2">Not sure? Click Back and choose “Joint Claim” if you want to manage claims for both parties.</p>
                  <p className="fs-2">Are you sure you want to proceed with Separate Claim?</p>
                </div>
              )
              : (
                <div>
                  <p className="text-left fs-2">You’ve chosen Joint Claim. This means you’ll manage the submissions for both parties, which can involve:</p>
                  <ul style={{listStyleType: "disc", textAlign: "left", margin: "0.5rem 1rem", fontSize: 14}}>
                    <li><b>You represent both clients.</b></li>
                    <li><b>Engaging in a Co-Agent Deal:{" "}</b>partnering with another agent (internal or external) and are responsible for submitting and managing the claim on behalf of both parties.</li>
                    <li>In this option, you are able to request documents like invoices or receipts on behalf of both parties.</li>
                  </ul>
                  <p className="fs-2">Are you sure you want to proceed?</p>
                </div>
              )
          }
          loading={onLoadEdit || onLoadConfirmationForms || onLoadForms}
          positiveText={"Submit"}
          icon={<AtlasIcon svgHref={"svg-document-tick"} />}
          positiveAction={() =>
            this.props.submitForm(
              this.state.toSubmitData.id,
              "ConfirmationForm",
              this.state.toSubmitData,
            )
          }
          negativeText={"Back"}
          negativeAction={() => {
            this.props.onChangeConfirmationFormHOC(
              "showSubmitConfirmationClaimForm",
              false,
            )
            this.props.onChangeConfirmationFormHOC(
              "showSubmitClaimForm",
              true,
            )
          }}
        />
        <ConfirmationModal
          mode={"alert"}
          open={this.state.showConfirmationModal}
          loading={onLoadEdit || onLoadConfirmationForms}
          message={
            tmpDisabled
              ? "Are you sure you want to mark this form as unsubmitted claim?"
              : "Are you sure you want to mark this form as submitted to claim?"
          }
          positiveAction={() => {
            claimedConfirmationForm();
            this.setState({ showConfirmationModal: false });
          }}
          negativeAction={() => {
            let tmp = _.cloneDeep(selectedConfirmationForm);
            tmp.tmp_claimed = tmp.is_claimed;
            onChangeConfirmationFormHOC("selectedConfirmationForm", tmp);
            this.setState({ showConfirmationModal: false });
          }}
        />
        {(onLoadEdit || onLoadConfirmationForms || onLoadForms) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  BankHOC,
  EditConfirmationHOC,
  ContactHoc,
  PartyHOC,
  DraftHOC,
  RefundHOC,
  AgreementHOC,
  InventoryHOC,
  PropertyHOC,
)(EditModal);
