import { useEffect } from "react";
import { IoMdAdd } from "react-icons/io";

import withEmailCampaigns from "./actions";
import { columnData } from "./assets";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import EmptyState from "components/EmptyState";
import SvgIcon from "components/Icon/new";
import Spinner from "components/LoadingModal";
import DeleteConfirmationDialog from "components/Modal/delete";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import CreateEmailCampaign from "./Create";
import PreviewEmailCampaign from "./Preview";
import UpdateEmailCampaign from "./Update";

import permissionsChecker from "utils/permissionsChecker";

import "./index.scss";

const EmailCampaigns = ({
  onLoadEmailCampaigns,
  emailCampaigns,
  emailCampaignsPages,
  emailCampaignDialog,
  searchParams,
  newEmailCampaign,
  selectedEmailCampaign,
  state,

  onChangeEmailCampaignsHOC,
  getEmailCampaigns,
  getSelectedEmailCampaign,
  createEmailCampaign,
  updateEmailCampaign,
  deleteEmailCampaign,
  suspendEmailCampaign,
}) => {
  const { can_create, can_read, can_update, can_destroy } = permissionsChecker(
    "Email Campaigns",
    state,
  );

  useEffect(() => {
    getEmailCampaigns();
  }, []);

  const renderModuleHeader = () => (
    <ModuleHeader
      moduleIcon={"icon-email-campaigns"}
      title={"Email Campaigns"}
      actionButton={[
        {
          label: "New Email Campaign",
          onShow: can_create,
          className: "btn-new btn-new--primary",
          icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
          onClick: () =>
            onChangeEmailCampaignsHOC("emailCampaignDialog", "create"),
        },
      ]}
    />
  );

  const renderModuleContent = () => (
    <CustomCard
      cardContent={
        <AtPaginatedTable
          columns={columnData({
            can_update,
            can_destroy,
            getSelectedEmailCampaign,
            onChangeEmailCampaignsHOC,
          })}
          getListAPI={getEmailCampaigns}
          rowData={emailCampaigns.data}
          meta={emailCampaigns.meta}
          totalPages={emailCampaignsPages}
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            onChangeEmailCampaignsHOC("searchParams", val)
          }
        />
      }
    />
  );

  const renderCreateDialog = () => (
    <CreateEmailCampaign
      newEmailCampaign={newEmailCampaign}
      onLoadEmailCampaigns={onLoadEmailCampaigns}
      onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
      createEmailCampaign={createEmailCampaign}
    />
  );

  const renderUpdateDialog = () => (
    <AtlasDialog open={emailCampaignDialog === "update"}>
      <CustomCard
        className="h-100"
        cardContent={
          <UpdateEmailCampaign
            selectedEmailCampaign={selectedEmailCampaign}
            onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
            updateEmailCampaign={updateEmailCampaign}
          />
        }
      />
    </AtlasDialog>
  );

  const renderPreviewDialog = () => (
    <AtlasDialog open={emailCampaignDialog === "preview"}>
      <CustomCard
        className="h-100"
        cardContent={
          <PreviewEmailCampaign
            selectedEmailCampaign={selectedEmailCampaign}
            onChangeEmailCampaignsHOC={onChangeEmailCampaignsHOC}
          />
        }
      />
    </AtlasDialog>
  );

  const renderDeleteDialog = () => (
    <DeleteConfirmationDialog
      open={emailCampaignDialog === "delete"}
      title={"Delete email campaign"}
      message={"Are you sure you want to delete this campaign?"}
      negativeAction={() =>
        onChangeEmailCampaignsHOC("emailCampaignDialog", "")
      }
      positiveText={"Yes"}
      positiveAction={deleteEmailCampaign}
    />
  );

  const renderSuspendDialog = () => (
    <DeleteConfirmationDialog
      open={emailCampaignDialog === "suspend"}
      title={"Suspend email campaign"}
      message={"Are you sure you want to suspend this campaign?"}
      negativeAction={() =>
        onChangeEmailCampaignsHOC("emailCampaignDialog", "")
      }
      positiveText={"Yes"}
      positiveAction={suspendEmailCampaign}
    />
  );

  const renderNoPermission = () => (
    <CustomCard
      cardContent={
        <EmptyState
          title={"You do not have permission to view this content"}
          renderIcon={<SvgIcon svgHref={"icon-permission"} />}
        />
      }
    />
  );

  return (
    <>
      {renderModuleHeader()}
      {can_read ? renderModuleContent() : renderNoPermission()}
      {can_create && emailCampaignDialog === "create" && renderCreateDialog()}
      {can_update && renderUpdateDialog()}
      {can_read && renderPreviewDialog()}
      {can_destroy && renderDeleteDialog()}
      {can_update && renderSuspendDialog()}
      {onLoadEmailCampaigns && <Spinner />}
    </>
  );
};

export default withEmailCampaigns(EmailCampaigns);
