import React, { Component } from "react";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      createNewEvent: {
        name: "",
        start_date_time: "",
        end_date_time: "",
        cut_off_date: "",
        location: "",
        location_url: "",
        latitude: "",
        longitude: "",
        price: "",
        speaker: "",
        audience_id: 1,
        status_id: 1,
        type_id: 1,
        description: "",
        entry_pass: "",
        photo: "",
        telegram_link: "",
        photo_file_name: "",
        registration_count: 0,
        maximum_seat: 0,
        need_approval: false,
        is_limited_seat: false,
        is_exam_link_published: false,
        exam_link: "",
        is_payment_needed: false,
        enable_bank_in: false,
        enable_payment_gateway: false,
        event_attendee: 0,
        training_type_id: null,
        clock_in_required: true,
        branch_region_id: 0,
        is_eligible_for_ims: false,
        ims_points: 0,
        minimum_perc_of_attendance: 0,
        status_id: 0,
        minimum_passing_minute: 0,
        open_for_registration: false,
      },
    };

    load = (param) => this.setState({ loading: param });

    createEvent = (dataToSubmit) =>
      Post(
        `/admin/events`,
        dataToSubmit,
        this.createEventSuccess,
        this.createEventError,
        this.load,
      );
    createEventSuccess = (payload) => {
      this.props.getEventLists(1, "");
      this.props.onChangeEventHOC(false, "showCreateEventDialog");
      this.props.getSelectedEvent(payload.id, "showEditEventDialog");
      requestSuccess("Event is created successfully.");
    };
    createEventError = (error) => requestError(error);

    onChangeFileCreate = (fileItems, stateName) => {
      if (fileItems.length > 0 && fileItems[0].file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let tmp = this.state.createNewEvent;
          tmp[stateName] = e.target.result;
          tmp["photo_file_name"] = fileItems[0].file.name;
          this.setState({ createNewEvent: tmp });
        };
        reader.readAsDataURL(fileItems[0].file);
      }
    };

    onChangeFieldCreate = (val, stateName) => {
      let tmp = this.state.createNewEvent;
      tmp[stateName] = val;
      return this.setState({ createNewEvent: tmp });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            createNewEvent={this.state.createNewEvent}
            onLoadCreateEvent={this.state.loading}
            createEvent={this.createEvent}
            onChangeFileCreate={this.onChangeFileCreate}
            onChangeFieldCreate={this.onChangeFieldCreate}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
