import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";

import CustomButton from "components/Button";
import CutsomTable from "components/NewTable";
import CutsomRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";

const columnData = ({ checkedJointTicket, onChangeCheckedTicket }) => [
  {
    header: "",
    hideSort: true,
    searchFlag: "",
    columnStyle: { width: "20%" },
    renderColumn: (rowData) => (
      <CutsomRadioGroup
        mode={"old"}
        checkedValue={
          checkedJointTicket || (rowData["is_attached?"] ? rowData.id : "")
        }
        selectedRadioValue={onChangeCheckedTicket}
        options={[{ label: "", value: rowData.id }]}
      />
    ),
  },
  {
    header: "Event Name",
    searchFlag: "event",
    renderColumn: (rowData) => rowData?.event?.name,
  },
  { header: "Pricing Title", accessor: "title", searchFlag: "title" },
  {
    header: "Price",
    searchFlag: "price",
    columnStyle: { width: "50%" },
    renderColumn: (rowData) => `RM ${rowData.price}`,
  },
];

const CreateTicketPricing = ({
  attachJointTicket,
  selectedTicketPricing,
  availableJointTickets,
  showAttachTicketPricing,
  onLoadEventTraining,
  onLoadEditEvent,

  onClose,
}) => {
  const [checkedJointTicket, onChangeCheckedTicket] = useState("");

  const onToggleClose = () => {
    onChangeCheckedTicket("");
    onClose();
  };

  return (
    <>
      {showAttachTicketPricing && (
        <ModalDialog
          title={"Select secondary ticket to joint ticket"}
          onClose={onToggleClose}
          onLoad={onLoadEventTraining || onLoadEditEvent}
          children={
            <CutsomTable
              columns={columnData({
                checkedJointTicket,
                onChangeCheckedTicket,
              })}
              rowData={availableJointTickets}
              hideSearch={true}
              hidePagination={true}
            />
          }
          footer={
            <div className="d-flex g-3">
              <CustomButton
                containerStyle={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                onClick={() =>
                  attachJointTicket({
                    attached_ticket_pricing_id: checkedJointTicket,
                  })
                }
                isIconButton={false}
                disabled={!checkedJointTicket}
                className={"d-flex btn-new btn-new--success"}
                children={"Add Now"}
                tooltip={false}
                tooltipChildren={"Submit"}
              />
              <CustomButton
                containerStyle={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                onClick={onToggleClose}
                isIconButton={false}
                className={"d-flex btn-new btn-new--outline-secondary ml-2"}
                children={"Cancel"}
                tooltip={false}
                tooltipChildren={"Cancel"}
              />
            </div>
          }
        />
      )}
    </>
  );
};

export default CreateTicketPricing;
