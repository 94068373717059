import React, { Component } from "react";
import { connect } from "react-redux";

import { Post } from "utils/axios";
import _ from "lodash";
import { requestError, requestSuccess } from "utils/requestHandler";

const AppointmentLetterlHOC = (WrappedComponent) => {
  class AppointmentLetterWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
      is_signed_appointment_letter: false,

      signature: null,
      showJapanAgreement: false,
      is_signed_japan_appointment_letter: false,

      currentDate: "",
      showDrawingBoard: false,
    };

    componentDidMount = () => {
      let newDate = new Date();
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let temp = date + "-" + month + "-" + year;
      return this.setState({ currentDate: temp });
    };

    load = (param) => this.setState({ loading: param });

    onChangeHOC = (val, context) => this.setState({ [context]: val });

    onSubmitAppointmentLetter = () => {
      const { country_id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      const temp = {
        is_signed_appointment_letter: this.state.is_signed_appointment_letter,
        signed_appointment_letter_date: this.state.currentDate,
        ...country_id === 14 ? { signature_image: this.state.signature } : {},
      };

      Post(
        `/registrations/sign_document`,
        temp,
        this.onSubmitAppointmentLetterSuccess,
        this.onSubmitAppointmentLetterError,
        this.load,
      );
    };
    onSubmitAppointmentLetterSuccess = (payload) => {
      const tmpPath = window.location.href.includes("/admin-impersonate")
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

      requestSuccess(payload.message);
      if (tmpPath) {
        window.location = `#${tmpPath}/dashboard/statistic`;
      }
      window.location.reload();
    };
    onSubmitAppointmentLetterError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadAgreement={this.state.loading}

          onChangeHOC={this.onChangeHOC}
          onSubmitAppointmentLetter={this.onSubmitAppointmentLetter}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(AppointmentLetterWrappedComponent);
};

export default AppointmentLetterlHOC;
