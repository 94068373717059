import { useEffect } from "react";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import EmptyState from "components/EmptyState";
import SvgIcon from "components/Icon/new";
import Spinner from "components/LoadingModal";
import DeleteConfirmationDialog from "components/Modal/delete";
import ModuleHeader from "components/ModuleHeader";
import CreateEmailTemplate from "./Create";
import UpdateEmailTemplate from "./Update";
import AtPaginatedTable from "./components/Table";

import withEmailTemplates from "./actions";
import permissionsChecker from "utils/permissionsChecker";

import "./index.scss";
import _ from "lodash";

const EmailTemplates = ({
  createEmailTemplate,
  deleteEmailTemplate,
  emailTemplateDialog,
  emailTemplates,
  emailTemplatesPages,
  getEmailTemplates,
  categories,
  getCategories,
  getSelectedEmailTemplate,
  onChangeEmailTemplatesHOC,
  onLoadEmailTemplates,
  selectedEmailTemplate,
  searchParams,
  selectedId,
  state,
  updateEmailTemplate,
}) => {
  const { can_create, can_read, can_update, can_destroy } = permissionsChecker(
    "Email Templates",
    state,
  );

  useEffect(() => {
    getCategories();
    getEmailTemplates();
  }, []);

  const renderModuleHeader = () => (
    <ModuleHeader
      moduleIcon={"icon-email-campaigns"}
      title={"Email Templates"}
      actionButton={[
        {
          label: "New Email Template",
          onShow: can_create,
          className: "btn-new btn-new--primary",
          icon: <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />,
          onClick: () =>
            onChangeEmailTemplatesHOC("emailTemplateDialog", "create"),
        },
      ]}
    />
  );

  const renderModuleContent = () => (
    <CustomCard
      cardContent={
        <AtPaginatedTable
          rowData={emailTemplates.data}
          meta={emailTemplates.meta}
          searchParams={searchParams}
          totalPages={emailTemplatesPages}
          can_update={can_update}
          can_destroy={can_destroy}
          getListAPI={getEmailTemplates}
          onClickEdit={(id) => getSelectedEmailTemplate(id)}
          onClickDelete={(id) => {
            onChangeEmailTemplatesHOC("selectedId", id);
            onChangeEmailTemplatesHOC("emailTemplateDialog", "delete");
          }}
          onChangeSearchParams={(val) =>
            onChangeEmailTemplatesHOC("searchParams", val)
          }
        />
      }
    />
  );

  const renderCreateDialog = () => (
    <CreateEmailTemplate
      categories={categories}
      onLoadEmailTemplates={onLoadEmailTemplates}
      onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
      createEmailTemplate={createEmailTemplate}
    />
  );

  const renderUpdateDialog = () => (
    <AtlasDialog open={emailTemplateDialog === "update"}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <UpdateEmailTemplate
            categories={categories}
            selectedEmailTemplate={selectedEmailTemplate}
            onChangeEmailTemplatesHOC={onChangeEmailTemplatesHOC}
            updateEmailTemplate={updateEmailTemplate}
          />
        }
      />
    </AtlasDialog>
  );

  const renderDeleteDialog = () => (
    <DeleteConfirmationDialog
      open={emailTemplateDialog === "delete"}
      title={"Delete email template"}
      message={"Are you sure you want to delete this template?"}
      negativeAction={() =>
        onChangeEmailTemplatesHOC("emailTemplateDialog", "")
      }
      positiveText={"Yes"}
      positiveAction={() => deleteEmailTemplate(selectedId)}
    />
  );

  const renderNoPermission = () => (
    <CustomCard
      cardContent={
        <EmptyState
          title={"You do not have permission to view this content"}
          renderIcon={<SvgIcon svgHref={"icon-permission"} />}
        />
      }
    />
  );

  return (
    <>
      {renderModuleHeader()}
      {can_read ? renderModuleContent() : renderNoPermission()}
      {can_create && emailTemplateDialog === "create" && renderCreateDialog()}
      {can_update && renderUpdateDialog()}
      {can_destroy && renderDeleteDialog()}
      {onLoadEmailTemplates && <Spinner />}
    </>
  );
};

export default withEmailTemplates(EmailTemplates);
