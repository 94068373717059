import { Component } from "react";
import { connect } from "react-redux";

import { storeLastView } from "actions/lastView";

import { Delete, Get, Post, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

import { status } from "../assets";

const searchParams = (categories) => [
  {
    label: "Name",
    value: "name",
    type: "input",
    param: "",
  },
  {
    label: "Category",
    value: "sort",
    type: "select",
    param: "",
    options: categories || [],
  },
  {
    label: "Status",
    value: "status",
    type: "radio",
    param: "",
    options: [{ value: "", label: "All" }, ...status],
  },
];

const withEmailTemplates = (WrappedComponent) => {
  class EmailTemplatesHOC extends Component {
    state = {
      onLoadEmailTemplates: false,
      emailTemplates: {},
      emailTemplatePages: [],
      selectedEmailTemplate: {},
      selectedId: 0,
      emailTemplateDialog: "",
      searchParams: searchParams(),
      categories: [],
    };

    load = (param) => this.setState({ onLoadEmailTemplates: param });

    onChangeEmailTemplatesHOC = (key, val) => this.setState({ [key]: val });

    getEmailTemplates = (page = 1, query = "") => {
      if (query !== "") {
        query = query
          .replace("[name]", "")
          .replace("q[sort]", "sort")
          .replace("q[status]", "status");
      }

      this.props.storeLastView({ page, query });

      Get(
        `/admin/email_templates?${query}page=${page}`,
        this.getEmailTemplatesSuccess,
        this.getEmailTemplatesError,
        this.load,
      );
    };
    getEmailTemplatesSuccess = (data) => {
      const pages = [];

      for (let i = 0; i < data.meta.pages; i++) {
        pages.push(i);
      }

      this.setState({
        emailTemplates: data,
        emailTemplatesPages: pages,
      });
    };
    getEmailTemplatesError = (error) => requestError(error);

    getCategories = () =>
      Get(
        `/tags?type=sorts`,
        this.getCategoriesSuccess,
        this.getCategoriesError,
        this.load,
      );
    getCategoriesSuccess = (payload) => {
      this.setState({
        categories: payload.map((item) => item.name),
        searchParams: searchParams(
          payload.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          })),
        ),
      });
    };
    getCategoriesError = (error) => requestError(error);

    getSelectedEmailTemplate = (id) =>
      Get(
        `/admin/email_templates/${id}`,
        this.getSelectedEmailTemplateSuccess,
        this.getSelectedEmailTemplateError,
        this.load,
      );
    getSelectedEmailTemplateSuccess = (data) => {
      this.setState({
        selectedEmailTemplate: {
          id: data.id,
          name: data.name,
          subject: data.extra.subject,
          categories: data.sort_list.sort(),
          attachment: data.attachment,
          status_id: data.status_id,
          html_code: data.html_code,
          content: JSON.parse(data.content.body),
          thumbnail_url: data.thumbnail_url,
        },
        emailTemplateDialog: "update",
      });
    };
    getSelectedEmailTemplateError = (error) => requestError(error);

    createEmailTemplate = (data) =>
      Post(
        `/admin/email_templates`,
        data,
        this.createEmailTemplateSuccess,
        this.createEmailTemplateError,
        this.load,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
    createEmailTemplateSuccess = (data) => {
      requestSuccess("Email template was created successfully!");

      this.setState({ emailTemplateDialog: "" });
      this.getEmailTemplates();
      this.getCategories();
      this.getSelectedEmailTemplate(data.id);
    };
    createEmailTemplateError = (error) => requestError(error);

    updateEmailTemplate = (data, from) =>
      Put(
        `/admin/email_templates/${this.state.selectedEmailTemplate.id}`,
        data,
        (data) => this.updateEmailTemplateSuccess(data, from),
        this.updateEmailTemplateError,
        this.load,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
    updateEmailTemplateSuccess = (data, from) => {
      requestSuccess("Email template was updated successfully!");

      if (from === "setup") {
        this.setState({
          emailTemplateDialog: "",
          selectedEmailTemplate: {},
        });
      } else if (from === "editor") {
        this.setState({
          selectedEmailTemplate: {
            id: data.id,
            name: data.name,
            subject: data.extra.subject,
            categories: data.sort_list.sort(),
            attachment: data.attachment,
            status_id: data.status_id,
            html_code: data.html_code,
            content: JSON.parse(data.content.body),
            thumbnail_url: data.thumbnail_url,
          },
        });
      }

      this.getEmailTemplates();
      this.getCategories();
    };
    updateEmailTemplateError = (error) => requestError(error);

    deleteEmailTemplate = (id) =>
      Delete(
        `/admin/email_templates/${id}`,
        this.deleteEmailTemplateSuccess,
        this.deleteEmailTemplateError,
        this.load,
      );
    deleteEmailTemplateSuccess = () => {
      requestSuccess("Email template was deleted successfully!");

      this.setState({ emailTemplateDialog: "" });
      this.getEmailTemplates();
      this.getCategories();
    };
    deleteEmailTemplateError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeEmailTemplatesHOC={this.onChangeEmailTemplatesHOC}
          getEmailTemplates={this.getEmailTemplates}
          getCategories={this.getCategories}
          getSelectedEmailTemplate={this.getSelectedEmailTemplate}
          createEmailTemplate={this.createEmailTemplate}
          updateEmailTemplate={this.updateEmailTemplate}
          deleteEmailTemplate={this.deleteEmailTemplate}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({ state });

  return connect(mapStateToProps, { storeLastView })(EmailTemplatesHOC);
};

export default withEmailTemplates;
