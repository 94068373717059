import React, { Component } from "react";

import IconSprite from "assets/stylesheets/atlasIcon.svg";

class AtlasIcon extends Component {
  render() {
    const { id, className, svgHref, style, onClick } = this.props;
    return (
      <svg id={id} className={className} style={style} onClick={onClick}>
        <use
          id={id}
          href={`${IconSprite}#${svgHref}`}
          xlinkHref={`${IconSprite}#${svgHref}`}
        />
      </svg>
    );
  }
}

export default AtlasIcon;
