import React, { useState, useEffect } from "react";
import { compose } from "redux";

import CustomTabs from "components/Tab/new";
import AssignContactContent from "components/AssignContactContent";
import SectionOwnerLawyer from "./ClaimForm/ClaimFormSections/ownerLawyer";
import SectionBuyerTenant from "./ClaimForm/ClaimFormSections/buyerTenant";
import SectionInternalAgent from "./ClaimForm/ClaimFormSections/internalAgent";
import SectionReferral from "./ClaimForm/ClaimFormSections/referral";
import SectionExternalAgent from "./ClaimForm/ClaimFormSections/externalAgent";
import LoadingModal from "components/LoadingModal";

import AgenciesHOC from "../actions/agency";
import NationalityHOC from "actions/nationality";
import ContactTypeHOC from "containers/Dashboard/SubsalesClaims/actions/contactType";
import AgentHOC from "containers/Dashboard/SubsalesClaims/actions/agent";

import {
  personalForms,
  companyForms,
  referralPersonalForms,
  referralCompanyForms,
} from "./ClaimForm/assets/form";

const formTypes = [
  {
    label: "Personal",
    value: false,
  },
  {
    label: "Company",
    value: true,
  },
];

const referralFormTypes = (props) => [
  {
    label: "Personal",
    value: "1",
  },
  {
    label: "Company",
    value: "2",
  },
  ...props,
];

const Participants = ({
  banks,
  data,
  menuExpand,
  selected_claim,
  showAssignContactModal,
  agencies,
  nationalities,
  contacts,
  onLoadAgency,
  onLoadNationalities,
  getSelectedClaim,
  onClickNav,
  getAgencies,
  getNationalities,
  getCurrentUserContactList,
  onCloseForm,
  assignContact,
  showContactLoadingModal,
  onChangeUpdateSelectedClaim,
  onChangeSubsaleClaimsHOC,
  getBanks,
  updateSelectedClaim,
  ...props
}) => {
  const { sub_sale_type_id } = selected_claim
  const [internalAgentCount, updateInternalAgentCount] = useState(0);
  const [selectedContactTab, updateSelectedContactTab] = useState("Internal Agents");
  const [selectedParticipantTypeId, updateSelectedParticipantTypeId] = useState("");
  const [selectedTitle, updateSelectedTitle] = useState("");

  useEffect(() => {
    const { country_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;

    getAgencies();
    getBanks(country_id);
    getSelectedClaim(selected_claim.id);
    getNationalities();
    getCurrentUserContactList();
  }, []);

  const tmpFirstParty = sub_sale_type_id  === 2 ? "Landlords" : "Vendors"
  const tmpSecondParty = sub_sale_type_id  === 2 ? "Tenants" : "Purchasers"
  // Separate Claim: Don't show external agency if don't have external_agency_id
  const tmpExternalAgentTab = [null, 3].indexOf(selected_claim.representative_id) > -1 || ([1, 2].indexOf(selected_claim.representative_id) > -1 && [0, null].indexOf(selected_claim.external_agency_id) === -1)
    ? ["Co-Agency"]
    : []
  useEffect(() => {
    if([tmpFirstParty, tmpSecondParty].indexOf(selectedContactTab) > -1){
      getSelectedClaim(selected_claim.id)
    }

  },[selectedContactTab])

  const renderAssignContactContent = () => (
    <AssignContactContent
      formType="Claim Form"
      contacts={contacts}
      assignContact={assignContact}
      onLoadParty={showContactLoadingModal}
      selected_form={selected_claim}
      onChangePartyHOC={onChangeSubsaleClaimsHOC}
      selectedParticipantTypeId={selectedParticipantTypeId}
      selectedTitle={selectedTitle}
    />
  );
  
  return (
    <>
      <div>
        <section>
          <CustomTabs
            sections={[
              "Internal Agents",
              ...tmpExternalAgentTab,
              tmpFirstParty,
              tmpSecondParty,
              "Referrals",
            ]}
            selectedSection={selectedContactTab}
            onSelectSection={(val) => updateSelectedContactTab(val)}
          />
          {selectedContactTab === tmpFirstParty && (
            <SectionOwnerLawyer
              {...props}
              data={data}
              banks={banks}
              formTypes={formTypes}
              personalForms={personalForms}
              companyForms={companyForms}
              nationalities={nationalities}
              selected_claim={selected_claim}
              getSelectedClaim={getSelectedClaim}
              updateSelectedParticipantTypeId={updateSelectedParticipantTypeId}
              updateSelectedTitle={updateSelectedTitle}
              onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
            />
          )}
          {selectedContactTab === "Co-Agency" && (
            <SectionExternalAgent
              agencies={agencies}
              banks={banks}
              selected_claim={selected_claim}
              onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
              onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
              updateSelectedClaim={updateSelectedClaim}
            />
          )}
          {selectedContactTab === tmpSecondParty && (
            <SectionBuyerTenant
              {...props}
              data={data}
              banks={banks}
              formTypes={formTypes}
              personalForms={personalForms}
              companyForms={companyForms}
              nationalities={nationalities}
              selected_claim={selected_claim}
              getSelectedClaim={getSelectedClaim}
              updateSelectedParticipantTypeId={updateSelectedParticipantTypeId}
              updateSelectedTitle={updateSelectedTitle}
              onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
            />
          )}

          {selectedContactTab === "Internal Agents" && (
            <SectionInternalAgent
              selected_claim={selected_claim}
              agencies={agencies}
              updateInternalAgentCount={(param) =>
                updateInternalAgentCount(param)
              }
            />
          )}

          {selectedContactTab === "Referrals" && (
            <SectionReferral
              {...props}
              data={data}
              banks={banks}
              claim_status={selected_claim.claim_status}
              formTypes={referralFormTypes}
              personalForms={referralPersonalForms}
              companyForms={referralCompanyForms}
              nationalities={nationalities}
              selected_claim={selected_claim}
              internalAgentCount={internalAgentCount}
              updateSelectedParticipantTypeId={updateSelectedParticipantTypeId}
              updateSelectedTitle={updateSelectedTitle}
              onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
            />
          )}
        </section>
        <section
          className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
          <button
            className="btn-new btn-new--secondary at-btn_prev subsaleclaim-claimform-navuploaddocs"
            onClick={() => onClickNav("Claim Form")}>
            <p>Claim Form</p>
          </button>
          <button
            className="btn-new btn-new--secondary at-btn_next subsaleclaim-claimform-navuploaddocs"
            onClick={() => onClickNav("Documents")}>
            <p>Documents</p>
          </button>
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => onCloseForm()}>
            Close
          </button>
        </section>
      </div>
      {showAssignContactModal && renderAssignContactContent()}
      {(onLoadAgency || onLoadNationalities) && <LoadingModal />}
    </>
  );
};

export default compose(AgenciesHOC, AgentHOC, ContactTypeHOC, NationalityHOC)(Participants);
