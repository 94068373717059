import React from "react";
import { Card, Grid, FormControl } from "@material-ui/core";
import _ from "lodash";
import { IoMdAdd } from "react-icons/io";

import CustomButton from "components/Button";
import AtlasTable from "components/NewTable";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import isEmptyValue from "utils/isEmpty";
import permissionsChecker from "utils/permissionsChecker";
import { TicketPricingColumnData, JointTicketColumnData } from "../assets";

const editDataCheckingLib = [
  {
    value: "maximum_seat",
    onDisabled: (data) => {
      if (data.is_seat_limited) {
        return isEmptyValue(data.maximum_seat);
      }
      return false;
    },
  },
  {
    value: "is_seat_limited",
    onDisabled: (data) => isEmptyValue(data.is_seat_limited),
  },
  {
    value: "is_joint_ticket",
    onDisabled: (data) => isEmptyValue(data.is_joint_ticket),
  },
];

const BooleanRadio = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const UpdateTicket = ({
  data,
  onLoad,
  jointTicketsList,
  priceManagementList,
  selectedTicketTypeDetail,

  onClose,
  updateTicketType,
  onChangeEditEventHOC,
  getAvailableJointTickets,
}) => {
  const { can_create, can_destroy } = permissionsChecker(
    "Company Events",
    data,
  );

  const onChangeUpdateTicketType = (value, context) => {
    let tmpTicketData = _.cloneDeep(selectedTicketTypeDetail);
    tmpTicketData[context] = value;
    onChangeEditEventHOC(tmpTicketData, "selectedTicketTypeDetail");
  };

  return (
    <ModalDialog
      title={"Edit Ticket Type"}
      onLoad={onLoad}
      onClose={onClose}
      children={
        <>
          <Grid container spacing={16} className={"mb-3"}>
            <Grid item md={7} xs={12}>
              <h4 className="at-form-input__title">Title</h4>
              <CustomFormInput
                type="text"
                required={true}
                value={selectedTicketTypeDetail["title"]}
                onChangeValue={(val) => onChangeUpdateTicketType(val, "title")}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <h2 className="at-form-input__title">Type</h2>
              <p className="mt-2">{selectedTicketTypeDetail["type"]}</p>
            </Grid>
            <Grid item md={7} xs={12}>
              <FormControl>
                <h2 className="at-form-input__title">Is Seat Limited?</h2>
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={selectedTicketTypeDetail.is_seat_limited}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) =>
                    onChangeUpdateTicketType(val, "is_seat_limited")
                  }
                  options={BooleanRadio}
                />
              </FormControl>
            </Grid>
            {selectedTicketTypeDetail.is_seat_limited && (
              <Grid item md={7} xs={12}>
                <h4 className="at-form-input__title">Maximum Seat</h4>
                <CustomFormInput
                  type="text"
                  required={true}
                  value={selectedTicketTypeDetail["maximum_seat"]}
                  onChangeValue={(val) =>
                    onChangeUpdateTicketType(val, "maximum_seat")
                  }
                />
              </Grid>
            )}
            <Grid item md={7} xs={12}>
              <FormControl>
                <h2 className="at-form-input__title">Is Joint Ticket</h2>
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={selectedTicketTypeDetail.is_joint_ticket}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) =>
                    onChangeUpdateTicketType(val, "is_joint_ticket")
                  }
                  options={BooleanRadio}
                />
              </FormControl>
            </Grid>
            <Grid item md={7} xs={12}>
              <h4 className="at-form-input__title">Remark</h4>
              <CustomFormInput
                type="textarea"
                value={selectedTicketTypeDetail["remarks"]}
                onChangeValue={(val) =>
                  onChangeUpdateTicketType(val, "remarks")
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomButton
                containerStyle={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                onClick={updateTicketType}
                isIconButton={false}
                disabled={editDataCheckingLib.some((item) =>
                  item.onDisabled(selectedTicketTypeDetail),
                )}
                className={"d-flex btn-new btn-new--success"}
                children={"Update"}
                tooltip={false}
                tooltipChildren={"Update"}
              />
              <CustomButton
                containerStyle={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                onClick={onClose}
                isIconButton={false}
                className={"d-flex btn-new btn-new--outline-secondary ml-2"}
                children={" Close"}
                tooltip={false}
                tooltipChildren={"Close"}
              />
            </Grid>
          </Grid>
          <Grid>
            <Card
              classes={{ root: "mx-n3 mx-sm-0" }}
              style={{ padding: 16, marginBottom: 20 }}
            >
              <div className="at-card__header">
                <h4 className="at-card__title">{"Pricing Management"}</h4>
                <CustomButton
                  className={"btn-new btn-new--primary"}
                  disabled={!can_create}
                  children={
                    <>
                      <IoMdAdd
                        style={{ height: 18, width: 18, marginRight: 10 }}
                      />
                      Create Pricing
                    </>
                  }
                  onClick={() =>
                    onChangeEditEventHOC(true, "showCreateTicketPricing")
                  }
                />
              </div>
              <AtlasTable
                hideSearch={false}
                className={"mb-100"}
                columns={TicketPricingColumnData}
                pagination={true}
                rowData={priceManagementList || []}
                actionColumnContent={[
                  {
                    name: "edit",
                    onClick: (rowData) => {
                      onChangeEditEventHOC(rowData, "selectedTicketPricing");
                      onChangeEditEventHOC(true, "showUpdateTicketPricing");
                    },
                  },
                  {
                    name: "attach",
                    onShow: () => can_create,
                    onClick: (rowData) => {
                      onChangeEditEventHOC(rowData, "selectedTicketPricing");
                      getAvailableJointTickets(rowData.id);
                      onChangeEditEventHOC(true, "showAttachTicketPricing");
                    },
                  },
                  {
                    name: "delete",
                    color: "#F04438",
                    onShow: () => can_destroy,
                    onClick: (rowData) => {
                      onChangeEditEventHOC(rowData.id, "toBeDeletedId");
                      onChangeEditEventHOC(
                        true,
                        "showDeletePricingTicketConfirmation",
                      );
                    },
                  },
                ]}
              />
            </Card>
            <Card
              classes={{ root: "mx-n3 mx-sm-0" }}
              style={{ padding: 16, marginTop: 20 }}
            >
              <h4 className="at-card__title d-inline-block">
                {"Joint Ticket Management"}
              </h4>
              <hr />
              <AtlasTable
                hideSearch={false}
                className={"mb-100"}
                columns={JointTicketColumnData}
                pagination={true}
                rowData={jointTicketsList || []}
                actionColumnContent={[
                  {
                    name: "delete",
                    color: "#F04438",
                    onShow: () => can_destroy,
                    onClick: (rowData) => {
                      onChangeEditEventHOC(rowData.id, "toBeDeletedId");
                      onChangeEditEventHOC(
                        true,
                        "showDeleteAttachedTicketConfirmation",
                      );
                    },
                  },
                ]}
              />
            </Card>
          </Grid>
        </>
      }
    />
  );
};

export default UpdateTicket;
