import React, { Component } from "react";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import _ from "lodash";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put, GetFile } from "utils/axios";
import { storeSubsalesAddress } from "actions/subsales/address";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Agency",
    value: "agency_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Address",
    value: "property_address_cont",
    type: "input",
    param: "",
  },
];

const defaultCreateObj = {
  selected_agency: [],
  type_id: undefined,
  invoicing_branch_id: null,
  agency_id: undefined,
  letter_date: new Date(),
  property_address: "",
  commission_type_id: undefined,
  valid_from: "",
  valid_to: "",
  agent_commission: 0,
  co_broker_commission: 0,
  full_name: "",
  email: "",
  contact_number: "",
  ren_tag: "",
  sales_tax_percentage: 8,
  show_professional_fee_percentage: false,
  use_display_name: false,
  professional_fee_percentage: false,
};

const CobrokeLetterHOC = (WrappedComponent) => {
  class CobrokeLetterWrappedComponent extends Component {
    state = {
      cobrokeLetters: [],
      cobrokeLettersPage: null,
      disableSubmitButton: false,
      selectedCobrokeLetter: {
        full_name: "",
        email: "",
        contact_number: "",
        ren_tag: "",
      },
      initialSelectedCobrokeLetter: {
        full_name: "",
        email: "",
        contact_number: "",
        ren_tag: "",
      },
      showConfirmationModal: false,
      showSendEmail: false,
      showCreateCobrokeLetterDialog: false,
      showEditCobrokeLetterDialog: false,
      showPreviewCobrokeLetterModal: false,
      loading: false,

      searchParams: searchParams,
      createCobrokeObj: defaultCreateObj,
    };

    load = (param) => this.setState({ loading: param });

    onChangeCobrokeHOC = (val, context) => this.setState({ [context]: val });

    onChangeEditCobrokeLetterVal = (context, val) => {
      let tmp = _.cloneDeep(this.state.selectedCobrokeLetter);
      if (context === "selected_agency" && val.length > 0) {
        tmp.agency_id = val[0].id;
      }
      tmp[context] = val;
      return this.setState({ selectedCobrokeLetter: tmp });
    };

    getCobrokeLetters = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/forms/co_broke_letters?${search}page=${page}`,
        this.getCobrokeLettersSuccess,
        this.getCobrokeLettersError,
        this.load,
      );
    };
    getCobrokeLettersSuccess = (payload) => {
      let tmpData = [];
      let tmpTotalPages = [];

      for (let index = 0; index < payload.meta.pages; index++) {
        tmpTotalPages.push(index);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let data = {
            ...item,
            letter_date: item.letter_date
              ? Moment(item.letter_date).format("DD MMM YYYY")
              : "N/A",
            agency_name: item.agency_name,
            invoicing_branch_name: item.branch_name,
          };
          tmpData.push(data);
        });

      this.setState({
        cobrokeLetters: {
          data: tmpData,
          meta: payload.meta,
        },
        cobrokeLettersPage: tmpTotalPages,
      });
    };
    getCobrokeLettersError = (error) => requestError(error);

    getCobrokeLetter = (id) =>
      Get(
        `/forms/co_broke_letters/${id}`,
        this.getCobrokeLetterSuccess,
        this.getCobrokeLetterError,
        this.load,
      );
    getCobrokeLetterSuccess = (payload) => {
      let tmpSelectedAgency = [];
      tmpSelectedAgency.push({
        ...payload.agency,
        label: `${payload.agency.name} - ${payload.agency.address}`,
        value: payload.agency.id,
      });

      let temp = {
        ...payload,
        letter_date: payload.letter_date
          ? Moment(payload.letter_date).format("DD MMM YYYY")
          : null,
        valid_from: payload.valid_from
          ? Moment(payload.valid_from).format("DD MMM YYYY")
          : "N/A",
        valid_to: payload.valid_to
          ? Moment(payload.valid_to).format("DD MMM YYYY")
          : "N/A",
        selected_agency: tmpSelectedAgency,
      };
      this.setState({
        selectedCobrokeLetter: temp,
        initialSelectedCobrokeLetter: temp,
        showEditCobrokeLetterDialog: true,
      });
    };
    getCobrokeLetterError = (error) => requestError(error);

    createCobrokeLetter = (data) => {
      if (data.property_address !== "") {
        this.props.storeSubsalesAddress(data.property_address);
      }
      Post(
        `/forms/co_broke_letters`,
        data,
        this.createCobrokeLetterSuccess,
        this.createCobrokeLetterError,
        this.load,
      );
    };
    createCobrokeLetterSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getCobrokeLetters(currentPage, searchParams);
      this.getCobrokeLetter(payload.id);
      this.setState({
        disableSubmitButton: false,
        showCreateCobrokeLetterDialog: false,
      });
      requestSuccess("Cobroke Letter is created successfully.");
    };
    createCobrokeLetterError = (error) => {
      requestError(error);
      this.setState({ disableSubmitButton: false });
    };

    updateCobrokeLetter = (data) => {
      if (data.property_address !== "") {
        this.props.storeSubsalesAddress(data.property_address);
      }
      Put(
        `/forms/co_broke_letters/${data.id}`,
        data,
        this.updateCobrokeLetterSuccess,
        this.updateCobrokeLetterError,
        this.load,
      );
    };
    updateCobrokeLetterSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getCobrokeLetters(currentPage, searchParams);
      requestSuccess("Update cobroke letter success!");
    };
    updateCobrokeLetterError = (error) => requestError(error);

    submitCobrokeLetter = (id) =>
      Put(
        `/forms/co_broke_letters/${id}/closing`,
        {},
        this.submitCobrokeLetterSuccess,
        this.submitCobrokeLetterError,
        this.load,
      );
    submitCobrokeLetterSuccess = (payload) => {
      this.setState({ showConfirmationModal: false });
      requestSuccess("Data submitted successfully.");
      this.getCobrokeLetter(payload.id);
    };
    submitCobrokeLetterError = (error) => requestError(error);

    downloadCobrokeLetter = (id) =>
      GetFile(
        `/forms/co_broke_letters/${id}/download`,
        `CobrokeLetter-${id}.pdf`,
        () => {},
        this.downloadCobrokeLetterError,
        this.load,
      );
    downloadCobrokeLetterError = (error) => requestError(error);

    sendCobrokeLetter = (id, recipient) =>
      Post(
        `/forms/co_broke_letters/${id}/deliver`,
        recipient,
        this.sendCobrokeLetterSuccess,
        this.sendCobrokeLetterError,
        this.load,
      );
    sendCobrokeLetterSuccess = () => {
      requestSuccess("Cobroke Letter sent successfully.");
      this.setState({ showSendEmail: false });
    };
    sendCobrokeLetterError = (error) => requestError(error);

    resetCreateCobroke = (invoicing_branch_id) =>
      this.setState({
        createCobrokeObj: {
          ...defaultCreateObj,
          invoicing_branch_id: invoicing_branch_id || null,
        },
      });

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showConfirmationModal={this.state.showConfirmationModal}
            showPreviewCobrokeLetterModal={
              this.state.showPreviewCobrokeLetterModal
            }
            showSendEmail={this.state.showSendEmail}
            createCobrokeObj={this.state.createCobrokeObj}
            searchParams={this.state.searchParams}
            cobrokeLettersPage={this.state.cobrokeLettersPage}
            cobrokeLetters={this.state.cobrokeLetters}
            selectedCobrokeLetter={this.state.selectedCobrokeLetter}
            initialSelectedCobrokeLetter={
              this.state.initialSelectedCobrokeLetter
            }
            onLoadCobrokeLetter={this.state.loading}
            showCreateCobrokeLetterDialog={
              this.state.showCreateCobrokeLetterDialog
            }
            showEditCobrokeLetterDialog={this.state.showEditCobrokeLetterDialog}
            disableSubmitButton={this.state.disableSubmitButton}
            resetCreateCobroke={this.resetCreateCobroke}
            onChangeCobrokeHOC={this.onChangeCobrokeHOC}
            getCobrokeLetters={this.getCobrokeLetters}
            getCobrokeLetter={this.getCobrokeLetter}
            createCobrokeLetter={this.createCobrokeLetter}
            updateCobrokeLetter={this.updateCobrokeLetter}
            submitCobrokeLetter={this.submitCobrokeLetter}
            sendCobrokeLetter={this.sendCobrokeLetter}
            downloadCobrokeLetter={this.downloadCobrokeLetter}
            onChangeEditCobrokeLetterVal={this.onChangeEditCobrokeLetterVal}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeSubsalesAddress,
    refreshToken,
    storeLastView,
  })(CobrokeLetterWrappedComponent);
};

export default CobrokeLetterHOC;
