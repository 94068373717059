import React, { useState, Fragment } from "react";
import _ from "lodash";
import { FiEdit } from "react-icons/fi";
import Tooltip from "@material-ui/core/Tooltip";

import SVGIcon from "./icon";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import PhotoSlider from "./photoSlider";

import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";
import defaultAvatar from "assets/images/av-male.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

import { getColorBadge } from "dictionary/badgeColor";
import { numberWithCommas } from "utils/thousandSeparator";

const agentInfoProps = [
  "full_name",
  "email",
  "mobile_contact_number",
  "team_name",
];

const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Draft", colorName: "Yellow" },
  { value: "Inactive/Expired", colorName: "Red" },
];

const publishPlatformLib = [
  {
    label: "IQI Global",
    tooltipTitle: "Click to view this listing on www.iqiglobal.com.",
    linkSrc: "exported_iqi_link",
    published: "published_to_iqi",
    exported: "",
    style: {
      padding: "0.1rem",
    },
    logoSrc: iqiLogo,
  },
  {
    label: "Juwai",
    tooltipTitle: "Click to view this listing on www.juwai.com.",
    linkSrc: "juwai_link",
    published: "published_to_juwai",
    exported: "exported_to_juwai",
    style: {
      padding: "0.1rem",
    },
    logoSrc: juwaiLogo,
  },
  {
    label: "Juwai Asia",
    tooltipTitle: "Click to view this listing on www.juwai.asia.",
    linkSrc: "exported_juwai_asia_link",
    published: "published_to_juwai",
    exported: "exported_to_juwai",
    style: {},
    logoSrc: juwaiAsiaLogo,
  },
  {
    label: "EdgeProp",
    tooltipTitle: "Click to view this listing on www.edgeprop.my.",
    linkSrc: "edge_prop_url",
    published: "published_to_edge_prop",
    exported: "exported_to_edgeprop",
    style: { borderRadius: "50%", overflow: "hidden" },
    logoSrc: edgePropLogo,
  },
];

const CustomCard = ({
  data,

  onClickEdit,
  onClickCard,
}) => {
  const {
    id,
    agent_info,
    bedrooms_label,
    bathrooms_label,
    measurement,
    carparks_label,
    land_area,
    built_up,

    status,
    status_id,
    featureArray,

    code,
    address,
    property,
    category,
    images,
    type,
    asking_price_currency,
    asking_price_cents,
    property_name,
    updated_on,

    published_to_iqi,
    exported_iqi_link,
    published_to_juwai,
    exported_to_juwai,
    exported_to_edgeprop,
    published_to_edge_prop,
    juwai_link,
    exported_juwai_asia_link,
  } = data;

  const [showPublishingPlatforms, setShowPublishingPlatforms] = useState(false);

  const renderPublishingPlatforms = () => {
    const LogoPlatform = ({ tooltipTitle, linkSrc, logoSrc, style, label }) => {
      return (
        <Tooltip
          enterTouchDelay={0}
          classes={{
            popper: "popper",
            tooltip: "tooltip-arrow",
            tooltipPlacementTop: "top",
            tooltipPlacementBottom: "bottom",
          }}
          placement={"top"}
          title={tooltipTitle}
        >
          <div
            style={{
              ...style,
              padding: 12,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => window.open(linkSrc)}
          >
            <img
              src={logoSrc}
              style={{ width: 16, height: 16, marginRight: 10 }}
            />
            <p className="fs-2">{label}</p>
          </div>
        </Tooltip>
      );
    };
    return (
      ((published_to_iqi && exported_iqi_link) ||
        (published_to_juwai &&
          exported_to_juwai &&
          (juwai_link || exported_juwai_asia_link)) ||
        (exported_to_edgeprop && published_to_edge_prop)) && (
        <div className="at-subsales-published_platforms">
          {publishPlatformLib.map((item, index) => {
            let tmpCheckingLink =
              data[item.linkSrc] &&
              data[item.linkSrc].length > 0 &&
              data[item.published] &&
              (item.exported ? data[item.exported] : true);
            return (
              tmpCheckingLink && (
                <LogoPlatform
                  {...item}
                  key={index}
                  linkSrc={data[item.linkSrc]}
                />
              )
            );
          })}
        </div>
      )
    );
  };

  const renderSpecs = () => {
    return (
      <div className="at-subsales__specs">
        <div className="at-subsales__props-features">
          {
            <SVGIcon
              itemID={`${id}-${bedrooms_label}-bedrooms`}
              containerClassName={`d-flex at-subsales__icon`}
              tooltip={true}
              iconClassName={`svg-icon-bed mr-2`}
              iconName={`icon-bed`}
              tooltipLabel={
                <>
                  <span className="at-subsales_tooltip-title">Bedrooms</span>
                </>
              }
              children={
                <span className="fw-400">{bedrooms_label || "N/A"}</span>
              }
            />
          }
          {
            <SVGIcon
              itemID={`${id}-${bathrooms_label}-bathrooms`}
              containerClassName={`d-flex at-subsales__icon`}
              tooltip={true}
              iconClassName={`svg-icon-bathroom mr-2`}
              iconName={`icon-bathroom`}
              tooltipLabel={
                <>
                  <span className="at-subsales_tooltip-title">Bathrooms</span>
                </>
              }
              children={
                <span className="fw-400">{bathrooms_label || "N/A"}</span>
              }
            />
          }
          {
            <SVGIcon
              itemID={`${id}-${carparks_label}-carparks`}
              containerClassName={`d-flex at-subsales__icon`}
              tooltip={true}
              iconClassName={`svg-icon-carpark mr-2`}
              iconName={`icon-carpark`}
              tooltipLabel={
                <>
                  <span className="at-subsales_tooltip-title">Car Parks</span>
                </>
              }
              children={
                <span className="fw-400">{carparks_label || "N/A"}</span>
              }
            />
          }
        </div>
        <div className={`at-subsales__props-built_up__land_area`}>
          <SVGIcon
            itemID={`${id}-${measurement}-builtUp`}
            containerClassName={`d-flex at-subsales__icon`}
            tooltip={true}
            iconClassName={`svg-icon-squarefeet mr-2`}
            iconName={`icon-squarefeet`}
            tooltipLabel={
              <>
                <span className="at-subsales_tooltip-title">
                  Built Up / Land Area
                </span>
              </>
            }
            children={
              <>
                <span className="fw-400">
                  {built_up ? numberWithCommas(built_up) + " " : "N/A"}
                  {land_area
                    ? " / " + numberWithCommas(land_area) + " " + measurement
                    : "/ N/A"}
                </span>
              </>
            }
          />
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    const { colorName } = _.find(StatusColor, { value: status }) || {};

    return (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(colorName || "Grey") }}
      >
        {status}
      </div>
    );
  };

  const renderFeatures = () => {
    return (
      featureArray &&
      featureArray.length > 0 &&
      featureArray.map(
        (item, index) =>
          item.value && (
            <Fragment key={"feature-" + index + "-" + id}>
              <Tooltip
                enterTouchDelay={0}
                classes={{
                  tooltip: "tooltip-arrow",
                  tooltipPlacementTop: "top",
                  tooltipPlacementBottom: "bottom",
                }}
                aria-label={item.value}
                placement={"top"}
                title={item.label}
              >
                <span>{item.value}</span>
              </Tooltip>
            </Fragment>
          ),
      )
    );
  };

  return (
    <li className={`at-subsales__item`}>
      <Tooltip
        enterTouchDelay={0}
        key={"agent_info"}
        interactive={true}
        classes={{
          tooltip: "tooltip-arrow",
          tooltipPlacementTop: "top",
          tooltipPlacementBottom: "bottom",
        }}
        aria-label={"agent_info"}
        placement={"top"}
        title={
          <>
            {agentInfoProps.map(
              (item) =>
                agent_info &&
                agent_info[item] && (
                  <p key={agent_info[item]}>{agent_info[item]}</p>
                ),
            )}
          </>
        }
      >
        <img
          className="at-subsales-agent_info-img"
          src={
            agent_info.avatar_url &&
            !_.includes(agent_info.avatar_url, "missing.png")
              ? agent_info.avatar_url
              : defaultAvatar
          }
          onError={(e) => {
            if (e.target) {
              e.target.src = defaultAvatar;
            }
          }}
        />
      </Tooltip>
      <PhotoSlider id={id} images={images} />
      <div className="at-subsales__details-upper_cont color-black">
        <div className="at-subsales__details-upper_cont-cont">
          <h6 className="at-subsales__code">{code}</h6>
        </div>
      </div>
      <div className="at-subsales__details-lower_cont">
        <div className="at-subsales__details-information">
          <Tooltip
            enterTouchDelay={0}
            key={"property_name"}
            classes={{
              tooltip: "tooltip-arrow ",
              tooltipPlacementTop: "top",
              tooltipPlacementBottom: "bottom",
            }}
            aria-label={"property_name"}
            placement={"top-start"}
            title={property_name}
          >
            <h5 className="fw-600 at-subsales__title">{`${property_name}`}</h5>
          </Tooltip>
          <Tooltip
            enterTouchDelay={0}
            key={"address_name"}
            classes={{
              tooltip: "tooltip-arrow",
              tooltipPlacementTop: "top",
              tooltipPlacementBottom: "bottom",
            }}
            aria-label={"address_name"}
            placement={"top-start"}
            title={address}
          >
            <h6 className="at-subsales__address">{`${address}`}</h6>
          </Tooltip>
          <span className="fw-600 at-subsales__price">
            {`${asking_price_currency} ${asking_price_cents && asking_price_cents > 0 ? numberWithCommas(asking_price_cents) : "N/A"}`}
          </span>
          <div className="at-subsales-props__cont">
            {renderSpecs()}
            <div className="at-subsales__info">
              {type !== "N/A" && (
                <Tooltip
                  enterTouchDelay={0}
                  key={"Subsales_Type-" + id}
                  classes={{
                    tooltip: "tooltip-arrow",
                    tooltipPlacementTop: "top",
                    tooltipPlacementBottom: "bottom",
                  }}
                  aria-label={"subsales_type"}
                  placement={"top"}
                  title={"Type"}
                >
                  <span>{type}</span>
                </Tooltip>
              )}
              {renderFeatures()}
              {category &&
                category !== "N/A" &&
                property &&
                property !== "N/A" && (
                  <Tooltip
                    enterTouchDelay={0}
                    key={"property-1-" + id}
                    classes={{
                      tooltip: "tooltip-arrow",
                      tooltipPlacementTop: "top",
                      tooltipPlacementBottom: "bottom",
                    }}
                    aria-label={"Property Type"}
                    placement={"top"}
                    title={"Property Type"}
                  >
                    <span>
                      {category} - {property}
                    </span>
                  </Tooltip>
                )}
            </div>
          </div>
        </div>
        <div className="at-subsales__footer">
          <div className="at-subsales__status">
            {renderStatus()}
            <div className="at-subsales-available_date mr-2">
              {updated_on && <span>Updated {updated_on}</span>}
            </div>
            {status_id === 1 &&
              ((published_to_iqi && exported_iqi_link) ||
                (published_to_juwai &&
                  exported_to_juwai &&
                  (juwai_link || exported_juwai_asia_link)) ||
                (exported_to_edgeprop && published_to_edge_prop)) && (
                <CustomButton
                  className="at-subsales__actions-view"
                  containerClass="ml-auto"
                  children={
                    <>
                      View Listing
                      <AtlasIcon svgHref={"atlas-export-2-linear"} />
                    </>
                  }
                  onClick={() =>
                    setShowPublishingPlatforms(!showPublishingPlatforms)
                  }
                />
              )}
          </div>
          <div className="at-subsales__actions">
            <CustomButton
              className="btn-new btn-new--secondary w-100"
              containerStyle={{ width: "48%" }}
              onClick={() => onClickEdit(data)}
              children={
                <>
                  <FiEdit style={{ marginRight: 5 }} />
                  Edit
                </>
              }
            />
            <CustomButton
              className="btn-new btn-new--outline-secondary w-100"
              containerStyle={{ width: "48%" }}
              onClick={() => onClickCard(data)}
              children={
                <>
                  <AtlasIcon
                    svgHref={"atlas-info-circle"}
                    style={{ marginRight: 5, fill: "#3B82F6" }}
                  />
                  Details
                </>
              }
            />
          </div>
          {showPublishingPlatforms && (
            <div className={"at-subsales__dropbear-modal-root"}>
              {renderPublishingPlatforms()}
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default CustomCard;
