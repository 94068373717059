import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { storeLastView } from "actions/lastView";
import { getCountryDictionary } from "actions/dictionary";

import { numberWithCommas } from "utils/thousandSeparator";
import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Post, Put } from "utils/axios";

const searchParams = [
  {
    label: "Project Name",
    value: "project_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Unit Number ",
    value: "unit_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Booking Date From",
    value: "booking_date_dategteq",
    type: "date",
    param: "",
  },
  {
    label: "Booking Date To",
    value: "booking_date_datelteq",
    type: "date",
    param: "",
  },
  {
    label: "Buyer",
    value: "buyer_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "1", label: "Draft" },
      { value: "2", label: "Submitted" },
      { value: "3", label: "Approved" },
      { value: "4", label: "Rejected" },
      { value: "5", label: "Cancelled" },
      { value: "6", label: "Ready to Invoice" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      showEditDialog: false,
      showCreateDialog: false,

      reportSales: {
        data: {},
        meta: {},
      },
      searchParams: searchParams,
      tabsContent: "My Project Sale",
      salesPages: [],

      reportPage: [],
      unsavedChanges: false,
      formFileChanges: false,
      showSubmitConfirmation: false,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSalesHOC = (val, context) => this.setState({ [context]: val });

    getReportSales = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/project_sales?${search}page=${page}`,
        this.getReportSalesSuccess,
        this.getReportSalesError,
        this.load,
      );
    };
    getReportSalesSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => ({
        ...item,
        booking_date: item.booking_date
          ? Moment(item.booking_date).format("DD MMM YYYY")
          : null,
      }));
      this.setState({
        reportSales: {
          data: tempData,
          meta: payload.meta,
        },
        reportPage: tmpTotalPages,
      });
    };
    getReportSalesError = (error) => requestError(error);

    getSelectedSales = (id) =>
      Get(
        `/project_sales/${id}`,
        this.getSelectedSalesSuccess,
        this.getSelectedSalesError,
        this.load,
      );
    getSelectedSalesSuccess = (payload) => {
      let temp = {
        ...payload,
        booking_date: payload.booking_date
          ? Moment(payload.booking_date).format("DD MMM YYYY")
          : null,
        purchase_price: numberWithCommas(`${payload.purchase_price}`),
        nett_price: numberWithCommas(`${payload.nett_price}`),
        status_id: `${payload.status_id}`,
        type_id: `${payload.type_id}`,
        booking_form: payload.booking_form_url,
        project_sale_agents_attributes: payload.sale_agents,
      };
      delete temp.sale_agents;
      delete temp.booking_form_url;

      this.setState({
        selectedSale: temp,
        showEditDialog: true,
      });
    };
    getSelectedSalesError = (error) => requestError(error);

    createReportSale = (dataToSubmit, projectListings) => {
      let temp = [];
      let tempReference = _.find(projectListings, {
        value: dataToSubmit.project_name,
      });
      let tempData = _.cloneDeep(dataToSubmit);
      delete tempData.country_id;

      dataToSubmit.project_sale_agents_attributes.map((item) => {
        temp.push({
          user_id: item.user_id,
          percentage: item.percentage,
        });
      });
      if (!dataToSubmit.booking_form) {
        delete tempData.booking_form;
        delete tempData.booking_form_file_name;
      }

      Post(
        `/project_sales`,
        {
          ...tempData,
          purchase_price: tempData.purchase_price.replace(/[^$0-9.]/g, ""),
          nett_price: tempData.nett_price.replace(/[^$0-9.]/g, ""),
          project_sale_agents_attributes: temp,
          project_reference_id: tempReference ? tempReference.id : "0",
        },
        this.createReportSaleSuccess,
        this.createReportSaleError,
        this.load,
      );
    };
    createReportSaleSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getReportSales(currentPage, searchParams);
      this.setState({ showCreateDialog: false });
      this.getSelectedSales(payload.id);
      requestSuccess("Project Sale has been saved as draft successfully.");
    };
    createReportSaleError = (error) => requestError(error);

    updateProjectSales = (dataToSubmit, projectListings) => {
      let tempReference = _.find(projectListings, {
        value: dataToSubmit.project_name,
      });
      let temp = _.cloneDeep(dataToSubmit);
      delete temp.status_id;
      delete temp.country_id;
      if (
        !this.state.formFileChanges ||
        temp.booking_form.indexOf("https://") > -1
      ) {
        delete temp.booking_form;
      }

      Put(
        `/project_sales/${dataToSubmit.id}`,
        {
          ...temp,
          purchase_price: temp.purchase_price.replace(/[^$0-9.]/g, ""),
          nett_price: temp.nett_price.replace(/[^$0-9.]/g, ""),
          project_reference_id: tempReference ? tempReference.id : "0",
        },
        this.updateProjectSalesSuccess,
        this.updateProjectSalesError,
        this.load,
      );
    };
    updateProjectSalesSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getReportSales(currentPage, searchParams);
      this.getSelectedSales(payload.id);
      requestSuccess("The project sale has been saved successfully.");
      this.setState({
        unsavedChanges: false,
        formFileChanges: false,
      });
    };
    updateProjectSalesError = (error) => requestError(error);

    submitProjectSales = (dataToSubmit, projectListings) => {
      let temp = _.cloneDeep(dataToSubmit);
      let tempReference = _.find(projectListings, {
        value: dataToSubmit.project_name,
      });

      delete temp.status_id;
      delete temp.country_id;
      if (
        !this.state.formFileChanges ||
        temp.booking_form.indexOf("https://") > -1
      ) {
        delete temp.booking_form;
      }

      Put(
        `/project_sales/${dataToSubmit.id}/submit`,
        {
          ...temp,
          project_reference_id: tempReference ? tempReference.id : "0",
        },
        this.submitProjectSalesSuccess,
        this.submitProjectSalesError,
        this.load,
      );
    };
    submitProjectSalesSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.setState({
        showCreateDialog: false,
        submitProjectSales: false,
        showSubmitConfirmation: false,
      });
      this.getReportSales(currentPage, searchParams);
      this.getSelectedSales(payload.id);
      requestSuccess(
        "The project sale has been submitted successfully. The admin team will now review this and process it as necessary.",
      );
    };
    submitProjectSalesError = (error) => requestError(error);

    resubmitProjectSales = (dataToSubmit, projectListings) => {
      let temp = _.cloneDeep(dataToSubmit);
      let tempReference = _.find(projectListings, {
        value: dataToSubmit.project_name,
      });

      delete temp.status_id;
      delete temp.country_id;
      delete temp.unit_number;
      delete temp.project_name;
      delete temp.type_id;

      if (
        !this.state.formFileChanges ||
        temp.booking_form.indexOf("https://") > -1
      ) {
        delete temp.booking_form;
      }

      Put(
        `/project_sales/${dataToSubmit.id}/resubmit`,
        {
          ...temp,
          project_reference_id: tempReference ? tempReference.id : "0",
        },
        this.resubmitProjectSalesSuccess,
        this.resubmitProjectSalesError,
        this.load,
      );
    };
    resubmitProjectSalesSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.setState({
        showCreateDialog: false,
        showSubmitConfirmation: false,
      });
      this.getReportSales(currentPage, searchParams);
      this.getSelectedSales(payload.id);
      requestSuccess(
        "The project sale has been resubmitted successfully. The admin team will now review this and process it as necessary",
      );
    };
    resubmitProjectSalesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            reportPage={this.state.reportPage}
            showEditDialog={this.state.showEditDialog}
            showCreateDialog={this.state.showCreateDialog}
            salesPages={this.state.salesPages}
            searchParams={this.state.searchParams}
            onLoadSales={this.state.loading}
            reportSales={this.state.reportSales}
            selectedSale={this.state.selectedSale}
            formFileChanges={this.state.formFileChanges}
            unsavedChanges={this.state.unsavedChanges}
            tabsContent={this.state.tabsContent}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            onChangeSalesHOC={this.onChangeSalesHOC}
            resubmitProjectSales={this.resubmitProjectSales}
            updateProjectSales={this.updateProjectSales}
            submitProjectSales={this.submitProjectSales}
            getReportSales={this.getReportSales}
            getSelectedSales={this.getSelectedSales}
            createReportSale={this.createReportSale}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary,
  })(WithHOC);
};

export default HOC;
