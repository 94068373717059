import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import { Card, Tooltip, Button, Dialog } from "@material-ui/core";
import { FaSignOutAlt } from "react-icons/fa";

import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/close";
import SimpleModal from "components/Modal/simple";
import PrivacyContent from "./components/privacyContent";
import PolicyContent from "./components/policyContent";
import SelectionPage from "./components/selection";
import SupportButtonGroups from "components/Button/supportGroups";

import AgentRegistrationPaymentHOC from "./action";
import VersionCheckHOC from "actions/versionCheck";
import PaymentApprovalHOC from "./action/paymentApproval";
import WithLogout from "actions/logout";

import IQILogo from "assets/images/iqi_logo.png";

class AgentRegistrationPayment extends Component {
  componentDidMount = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    if (
      !tmpProfileReducer ||
      tmpProfileReducer.status_id !== 6 ||
      tmpProfileReducer.agent_status_id !== 0
    ) {
      this.props.history.push(`${tmpPath}/dashboard/profile`);
    } else {
      this.props.getPaymentStatus(tmpProfileReducer.id);
    }
  };

  componentDidUpdate = (pp) => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    let { ajaxCallProgress } = this.props.data.ajaxStatusReducer;
    if (ajaxCallProgress !== pp.data.ajaxStatusReducer.ajaxCallProgress) {
      if (ajaxCallProgress === 0) {
        if (
          tmpProfileReducer.status === "Waiting Approval" &&
          tmpProfileReducer.payment_status_id === 2
        ) {
          this.props.history.push({
            pathname: `${tmpPath}/dashboard/profile/`,
          });
        }
      }
    }
  };

  onClickSignOut = () => this.props.sendLogoutRequest();

  renderPaymentCardContent = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const {
      tabContentType,
      paymentMethod,
      paymentMethodId,
      onChangeHOC,
      payment_proof,
      onChangeFile,
      history,
      uploadedPaymentUrl,
      employment_proof,
      employmentUrl,
      showPaymentGateway,
      onClickSubmitPaymentProof,
    } = this.props;
    return (
      <SelectionPage
        history={history}
        prefix_path={this.props.data.adminSignInReducer.prefix_path}
        paymentMethod={paymentMethod}
        paymentMethodId={paymentMethodId}
        tabContentType={tabContentType}
        profileReducer={tmpProfileReducer}
        uploadedPaymentUrl={uploadedPaymentUrl}
        onChangeFile={onChangeFile}
        onChangeHOC={onChangeHOC}
        payment_proof={payment_proof}
        userID={tmpProfileReducer.id}
        employment_proof={employment_proof}
        employmentUrl={employmentUrl}
        showPaymentGateway={showPaymentGateway}
        agent_via_recruitment_campaign={
          tmpProfileReducer.agent_via_recruitment_campaign
        }
        onClickSubmitPaymentProof={onClickSubmitPaymentProof}
        agentRegistrationID={tmpProfileReducer.agent_registration_id}
      />
    );
  };

  render = () => {
    return (
      <>
        <Card
          style={{
            padding: 30,
            marginTop: 30,
            width: "100%",
            overflow: "auto",
          }}
        >
          <div className="col-md-12" style={{ textAlign: "right" }}>
            <Tooltip title="Log out" placement="top">
              <Button
                style={{ padding: "10px" }}
                onClick={() => this.onClickSignOut()}
              >
                <FaSignOutAlt style={{ width: "24px" }} />
              </Button>
            </Tooltip>
          </div>
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img src={IQILogo} alt={"IqiLogo"} style={{ width: "114px" }} />
          </div>
          {this.renderPaymentCardContent()}
        </Card>
        <SimpleModal
          isOpen={this.props.showPolicyRefundModal}
          closeModal={() =>
            this.props.onChangeHOC(false, "showPolicyRefundModal")
          }
          modalContent={<PolicyContent />}
        />
        <SimpleModal
          isOpen={this.props.showPrivacyPolicyModal}
          closeModal={() =>
            this.props.onChangeHOC(false, "showPrivacyPolicyModal")
          }
          modalContent={<PrivacyContent />}
        />
        <Dialog open={this.props.showPaymentProofModal} maxWidth={"md"}>
          <AtlasCloseButton
            onClick={() =>
              this.props.onChangeHOC(false, "showPaymentProofModal")
            }
            containerStyle={{ position: "absolute", right: 15, top: 15 }}
          />
          <img
            src={this.props.uploadedPaymentUrl}
            style={{ width: "100%", cursor: "pointer" }}
          />
        </Dialog>
        <Dialog open={this.props.showEmployementProofModal} maxWidth={"md"}>
          <AtlasCloseButton
            onClick={() =>
              this.props.onChangeHOC(false, "showEmployementProofModal")
            }
            containerStyle={{ position: "absolute", right: 15, top: 15 }}
          />
          <img
            src={this.props.employmentUrl}
            style={{ width: "100%", cursor: "pointer" }}
          />
        </Dialog>
        {(this.props.onLoadPayment ||
          this.props.onLoadAgentRegister ||
          this.props.data.ajaxStatusReducer.ajaxCallProgress > 0) && (
          <LoadingModal />
        )}
        <SupportButtonGroups />
      </>
    );
  };
}

export default compose(
  VersionCheckHOC,
  AgentRegistrationPaymentHOC,
  PaymentApprovalHOC,
  WithLogout,
)(AgentRegistrationPayment);
