// todo refactor this into react redux cycle
import React from "react";
import firebase from "firebase/app";
import "firebase/messaging";

import { toast } from "react-toastify";

export const initializeFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: "AIzaSyCf-pxw1G_Dcm7dHoDlupsCe1lFviaDrIA",
      authDomain: "iqiatlas.firebaseapp.com",
      databaseURL: "https://iqiatlas.firebaseio.com",
      projectId: "iqiatlas",
      storageBucket: "iqiatlas.appspot.com",
      messagingSenderId: "775423260077",
      appId: "1:775423260077:web:2de61c3244821b96",
      measurementId: "G-KW0795RZWH",
    });
  }
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    await Notification.requestPermission();
    const token = await firebase.messaging().getToken();
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const getFirebaseToken = async () => {
  const token = await firebase.messaging().getToken();
  return token;
};

export const getFirebaseMessage = () => {
  firebase.messaging().onMessage((payload) => {
    toast.warn(
      <>
        {payload.notification.body}
        <a
          href={payload.notification.click_action}
          style={{
            color: "white",
            textDecoration: "underline",
            marginLeft: 10,
          }}
        >
          View
        </a>
      </>,
    );
  });
};

export const REDIRECT_URL = "https://atlas.iqiglobal.com";
export const REDIRECT_LOCAL_URL = "http://localhost:3005";
