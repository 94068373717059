import React, { Component } from "react";
import _ from "lodash"
import { Select, MenuItem } from "@material-ui/core";
import ChevronIcon from "@material-ui/icons/ChevronLeft";

import { getTranslation } from "../Input/assets";
import "stylesheets/components/select/index.scss";

class CustomSelect extends Component {
  state = {
    collapsed: true,
    currentlySelected: "",
  };

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.currentlySelected) {
      if (nextProps.currentlySelected.label !== this.state.currentlySelected) {
        this.setState({ currentlySelected: nextProps.currentlySelected.label });
      }
    }
    return true;
  };

  toggleDropdown = () => {
    this.setState({ collapsed: true });
  };

  onClickSelectItems = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  renderPlaceholder = (placeholder) => {
    if (placeholder) {
      return <div className="at-form-input__instruction">{placeholder}</div>;
    }
  };

  renderErrorMessage = (error) => {
    if (error) {
      return (
        <div className="at-form-input__label at-form-input__label--error">
          {error}
        </div>
      );
    }
  };

  renderRequired = (param, language) => {
    if (param) {
      return <div className="at-form-input__required">{getTranslation('required', language)}</div>;
    }
  };

  renderConditionClass = () => {
    const { placeholder, inputError, required } = this.props;
    let tmpClass = "";
    if (placeholder || inputError || required) {
      return " mb-3";
    }
    return "";
  };

  render = () => {
    const {
      className,
      rootClass,
      style,
      removeError,
      required,
      disabled,
      inputError,
      multiple,
      children,
      language,
      value = "",
      onChange,
      placeholder,
    } = this.props;

    return (
      <div
        className={`at-select ${disabled ? "at-select--disabled" : ""} ${className || ""}`}
        style={{ ...style }}
        onChange={removeError}>
        <Select
          multiple={multiple}
          value={value}
          onChange={(e) => onChange(e)}
          IconComponent={ChevronIcon}
          classes={{
            root: `at-select__selected-item${disabled ? " at-select__selected-item-disabled" : ""} ${rootClass || ""}`,
            select: `at-select__selected-item-select ${this.state.collapsed ? "" : "expand"} ${!value ? "at-select__selected-placeholder" : ""}`,
            icon: `at-select__chevron ${this.state.collapsed ? "" : "expand"}`,
          }}
          MenuProps={{
            classes: { paper: `at-select__dropdown-paper` },
            onEnter: disabled ? () => {} : () => this.onClickSelectItems(),
            onExit: () => this.toggleDropdown(),
            getContentAnchorEl: null,
            anchorOrigin: { horizontal: "left", vertical: "bottom" },
            MenuListProps: {
              classes: { root: `at-select__dropdown-paper-list` },
            },
            disableAutoFocus: true,
          }}
          fullWidth
          disableUnderline
          displayEmpty
          disabled={disabled}
        >
          {
            placeholder && (
              <MenuItem
                classes={{ root: `at-select__dropdown-item at-select__dropdown-placeholder` }}
                value={""}>
                {placeholder}
              </MenuItem>
            )
          }
          {children}
        </Select>
        <div className={`at-input_footer${this.renderConditionClass()}`}>
          {this.renderRequired(required, language)}
          {this.renderErrorMessage(inputError)}
        </div>
      </div>
    );
  };
}

export default CustomSelect;