import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import ProjectTypeTypahead from "components/Typeahead/projectType";
import { requestError, requestSuccess } from "utils/requestHandler";

import { Post, Get } from "utils/axios";
import { bedroom_options, currency } from "dictionary/subsales";
import {
  categories as CategoriesDictionary,
  types,
  statuses,
  measurements,
  directions,
  occupancies,
  furnishing_statuses,
  tenure_types,
  title_types,
  unit_types,
} from "dictionary/subsales";

const SearchParams = (userId, countries, groupTypes) => [
  {
    label: "Show my own listings only",
    value: "user_id_eq",
    type: "radio",
    param: "",
    options: [
      { value: `${userId}`, label: "Yes" },
      { value: "", label: "No" },
    ],
    col: 12,
  },
  {
    label: "Property Name",
    value: "property_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Code",
    value: "code_cont",
    type: "input",
    param: "",
  },
  {
    label: "Property Type",
    value: "property_type_name_cont",
    param: "",
    type: "project-type",
    options: groupTypes,
    renderField: ({ item, index, searchParams, onChangeSearchParams }) => (
      <ProjectTypeTypahead
        selectedValue={item.param ? [item.param] : []}
        options={item.options || []}
        multiple={false}
        onSelect={(val) => {
          if (val && val.length > 0) {
            let tmp = _.cloneDeep(searchParams);
            tmp[index].param = val[0];
            return onChangeSearchParams(tmp);
          } 
        }}
        onSearch={(val) => { 
          let tmp = _.cloneDeep(searchParams);
          tmp[index].param = val;
          return onChangeSearchParams(tmp);
        }}
        filterBy={["name"]}
      />
    ),
  },
  {
    label: "Country",
    value: "country_id_eq",
    type: "select",
    param: "",
    options: countries,
  },
  {
    label: "State",
    value: "state_id_eq",
    type: "select",
    param: "",
    options: [],
    placeholder: "Please choose a country first.",
  },
  {
    label: "Currency",
    value: "currency_id",
    withoutPrefix: true,
    type: "select",
    param: "",
    options: currency,
  },
  {
    label: "Listing Price Greater Than",
    value: "min_price",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Listing Price Less Than",
    value: "max_price",
    withoutPrefix: true,
    type: "input",
    param: "",
  },
  {
    label: "Bedroom",
    value: "bedrooms_eq",
    type: "select",
    param: "",
    options: bedroom_options,
  },
  {
    label: "Measurement",
    value: "measurement_id",
    type: "select",
    param: "",
    options: [
      { value: 1, label: "Squarefeet" },
      { value: 2, label: "Squaremeter" },
      { value: 3, label: "Acres" },
      { value: 4, label: "Hectare" },
    ],
  },
  {
    label: "Built up size Greater than",
    value: "built_up_gteq",
    type: "input",
    param: "",
  },
  {
    label: "Built up size Less than",
    value: "built_up_lteq",
    type: "input",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      estatetListingSearchParams: [],
      estateListings: {
        data: [],
        meta: {
          pages: 1,
          from: 1,
          to: 1,
          count: 1,
          series: [1],
        },
      },
      estateListingPages: [],

      estateListingNavData: {
        currentPage: 1,
        searchParams: "",
      },
      showRemoveConfirmation: false,
    };

    componentDidMount = () => {
      const { countries } = this.props.data.dictionaryReducer;
      let tempGroup = [];

      CategoriesDictionary.map(({ group_types }) => {
        group_types.map((item) => {
          tempGroup.push(...item.children);
        });
      });
      const { id } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;
      this.setState({
        estatetListingSearchParams: SearchParams(id, countries, tempGroup),
      });
    };

    load = (param) => this.setState({ loading: param });

    onChangeEstateListingHOC = (val, context) =>
      this.setState({ [context]: val });

    getEstateListing = (presentationId, page, search) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      this.setState(
        {
          estateListingNavData: {
            currentPage: page,
            searchParams: search,
          },
        },
        () => {
          Get(
            `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates/get_listings?${search}page=${page}`,
            this.getEstateListingSuccess,
            this.getEstateListingError,
            this.load,
          );
        },
      );
    };
    getEstateListingSuccess = (payload) => {
      let tmpTotalPages = [];
      let tempData = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tmpType = _.find(types, { id: item.type_id });
          let tmpCategory = _.find(CategoriesDictionary, {
            id: item.category_id,
          });
          let tempStatus = _.find(statuses, { id: item.status_id });
          let tmpCatGroupPropertyType = {};
          if (tmpCategory) {
            tmpCategory.group_types.map((child) => {
              return child.children.map((childItem) => {
                if (childItem.id === item.property_type_id) {
                  tmpCatGroupPropertyType = childItem;
                }
              });
            });
          }
          let tempTitleType = _.find(title_types, { id: item.title_type_id });
          let tempDirection = _.find(directions, { id: item.direction_id });
          let tempFurnishing = _.find(furnishing_statuses, {
            id: item.furnishing_status_id,
          });
          let tempMeasurement = _.find(measurements, {
            id: item.measurement_id,
          });
          let tempOccupancy = _.find(occupancies, { id: item.occupancy_id });
          let tempTenure = _.find(tenure_types, { id: item.tenure_id });
          let tempUnitType = _.find(unit_types, { id: item.unit_type_id });

          let featureArray = [
            {
              label: "Direction",
              value:
                tempDirection && tempDirection.name.toLowerCase() !== "unknown"
                  ? tempDirection.name
                  : "N/A",
            },
            {
              label: "Occupancy",
              value:
                tempOccupancy && tempOccupancy.name.toLowerCase() !== "unknown"
                  ? tempOccupancy.name
                  : "N/A",
            },
            {
              label: "Furnishing",
              value:
                tempFurnishing &&
                tempFurnishing.name.toLowerCase() !== "unknown"
                  ? tempFurnishing.name
                  : "N/A",
            },
            {
              label: "Tenure",
              value:
                tempTenure && tempTenure.name.toLowerCase() !== "unknown"
                  ? tempTenure.name
                  : "N/A",
            },
            {
              label: "Unit Type",
              value:
                tempUnitType && tempUnitType.name.toLowerCase() !== "unknown"
                  ? tempUnitType.name
                  : "N/A",
            },
            {
              label: "Title Type",
              value:
                tempTitleType && tempTitleType.name.toLowerCase() !== "unknown"
                  ? tempTitleType.name
                  : "N/A",
            },
          ];

          let data = {
            ...item,
            featureArray: featureArray,
            type: tmpType && tmpType.name ? tmpType.name : "N/A",
            property:
              tmpCatGroupPropertyType && tmpCatGroupPropertyType.name
                ? tmpCatGroupPropertyType.name
                : "N/A",
            measurement:
              tempMeasurement && tempMeasurement.name
                ? tempMeasurement.name
                : "N/A",
            status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
            category:
              tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
          };
          return tempData.push(data);
        });
      this.setState({
        estateListings: {
          data: tempData,
          meta: payload.meta,
        },
        estateListingPages: tmpTotalPages,
      });
    };
    getEstateListingError = (error) => requestError(error);

    createListing = (presentationId, listing_id) => {
      const { role } =
        window.location.href.indexOf("/admin-impersonate") > -1
          ? this.props.data.currentSignInProfileReducer
          : this.props.data.profileReducer;

      return Post(
        `${["Super Admin", "Admin"].indexOf(role) > -1 ? "/admin" : ""}/presentations/${presentationId}/estates`,
        {
          type_id: 1,
          listing_id,
        },
        () => this.createListingSuccess(presentationId),
        this.createListingError,
        this.load,
      );
    };
    createListingSuccess = (presentationId) => {
      const { currentPage: estatePage, searchParams: estateSearchParams } =
        this.state.estateListingNavData;
      const {
        currentPage: presentationPage,
        searchParams: presentationSearch,
      } = this.props.data.lastViewReducer.lastView;

      this.props.getListings();
      requestSuccess("Listing added successfully.");
      this.props.getSelectedPresentation(presentationId);
      this.props.getPresentation(presentationPage, presentationSearch);
      this.getEstateListing(presentationId, estatePage, estateSearchParams);
    };
    createListingError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            estateListings={this.state.estateListings}
            showRemoveConfirmation={this.state.showRemoveConfirmation}
            estateListingPages={this.state.estateListingPages}
            estatetListingSearchParams={this.state.estatetListingSearchParams}
            onLoadEstate={this.state.loading}
            getEstateListing={this.getEstateListing}
            createListing={this.createListing}
            onChangeEstateListingHOC={this.onChangeEstateListingHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
