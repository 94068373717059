import React from "react";
import _ from "lodash";
import Moment from "moment";
import { ImPlay } from "react-icons/im";
import { getColorBadge } from "dictionary/badgeColor";
import DefaultThumbnail from "assets/images/default_thumbnail.jpg";

const StatusColor = [
  { value: 1, colorName: "Grey" },
  { value: 2, colorName: "Green" },
];

export const columnData = ({ onChangeVideoTemplateHOC }) => [
  {
    header: "Thumbnail",
    renderColumn: (data) => (
      <>
        <img
          style={{ aspectRatio: "4/3" }}
          src={data.thumbnail_url ? data.thumbnail_url : DefaultThumbnail}
          alt="thumbnail_url"
        />
        {data.url && (
          <a
            className="at-video-creator__play"
            onClick={() => {
              Promise.all([
                onChangeVideoTemplateHOC(data, "selectedTemplate"),
              ]).then(() => {
                onChangeVideoTemplateHOC(true, "showVideoPlayer");
              });
            }}
          >
            <ImPlay className="at-video-creator__thumbnail" />
          </a>
        )}
      </>
    ),
  },
  {
    header: "Title / Project",
    renderColumn: ({ title, project_name }) => (
      <>
        <p>{title || "N/A"}</p>
        <p>{project_name || "N/A"}</p>
      </>
    ),
  },
  {
    header: "Download / Created Count",
    columnStyle: { width: "80%" },
    renderColumn: (rowData) => (
      <p>{`${rowData.download_count || 0}/${rowData.create_count || 0}`}</p>
    ),
  },
  {
    header: "Created At",
    renderColumn: ({ created_at }) => Moment(created_at).format("DD MMM YYYY"),
    columnStyle: { width: "50%" },
  },
  {
    header: "Updated On",
    renderColumn: ({ mg_updated_at }) =>
      Moment(mg_updated_at).format("DD MMM YYYY"),
    columnStyle: { width: "50%" },
  },
  {
    header: () => (
      <span className="at-table-head__title text-center">Status</span>
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: ({ status_id }) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: status_id }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {status_id === 1 ? "Draft" : "Published"}
        </div>
      );
    },
    columnStyle: { width: "70%" },
  },
];
