import React from "react";
import { IoMdAdd } from "react-icons/io";

import CustomButton from "components/Button";
import AtlasTable from "components/NewTable";

import { ticketTypeColumnData } from "../assets";
import permissionsChecker from "utils/permissionsChecker";

const TicketManagement = ({
  data,
  ticketTypeList,

  getTicketTypeDetail,
  onChangeEditEventHOC,
}) => {
  const { can_create, can_update, can_destroy } = permissionsChecker(
    "Company Events",
    data,
  );

  return (
    <>
      <div className="at-card__header">
        <h4 className="at-card__title">{"Ticket Management"}</h4>
        <CustomButton
          className={"btn-new btn-new--primary"}
          disabled={!can_create}
          children={
            <>
              <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              Create Ticket
            </>
          }
          onClick={() => onChangeEditEventHOC(true, "showCreateTicketType")}
        />
      </div>
      <AtlasTable
        hideSearch={false}
        className={"mb-100"}
        columns={ticketTypeColumnData}
        pagination={true}
        rowData={ticketTypeList || []}
        actionColumnContent={[
          {
            name: "edit",
            onShow: () => can_update,
            onClick: (rowData) => getTicketTypeDetail(rowData.id),
          },
          {
            name: "delete",
            color: "#F04438",
            onShow: () => can_destroy,
            onClick: (rowData) => {
              onChangeEditEventHOC(true, "showDeleteTicketTypeConfirmation");
              onChangeEditEventHOC(rowData.id, "toBeDeletedId");
            },
          },
        ]}
      />
    </>
  );
};

export default TicketManagement;
