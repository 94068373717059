import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { Post, Get } from "utils/axios";
import { connect } from "react-redux";
import { requestError, requestSuccess } from "utils/requestHandler";
import { refreshToken } from "actions/login";
import { getAllDictionaryData } from "actions/dictionary";

const agentStatus = [
  { id: 1, name: "Pending" },
  { id: 2, name: "Approved" },
  { id: 3, name: "Rejected" },
];

const PendingApprovalHOC = (WrappedComponent) => {
  class PendingApprovalWrappedComponent extends Component {
    state = {
      loading: false,
      can_update: true,

      jobTitleContent: [],
      checkedArray: [],
      pendingApprovals: [],
      currentPageData: [],
      searchedResult: "",
      selectedStatus: 1,
      pendingCount: 0,
      approvedCount: 0,
      rejectedCount: 0,
      showApprovalConfirmationModal: false,
      showReassignDirectConfirmationModal: false,
      assignLeaderType: 0,
    };

    load = (param) => this.setState({ loading: param });

    onChangePendingApprovalHOC = (val, context) =>
      this.setState({ [context]: val });

    getAllDictionaryData = () => this.props.getAllDictionaryData();

    getPendingNewREN = () =>
      Get(
        `/registrations/pending_approvals`,
        this.getPendingNewRENSuccess,
        this.getPendingNewRENFailed,
        this.load,
      );
    getPendingNewRENSuccess = (payload) => {
      const tmp = [];
      let pendingCount = 0;
      let approvedCount = 0;
      let rejectedCount = 0;

      payload.map((item) => {
        if (item.status_id === this.state.selectedStatus) {
          let tmpData = {
            ...item,
            agent:
              (item.full_name ? item.full_name : "N/A") +
              "\n" +
              (item.mobile_contact_number
                ? item.mobile_contact_number
                : "N/A") +
              "\n" +
              (item.email ? item.email : "N/A"),
            status: _.find(agentStatus, { id: item.status_id }).name,
            created_at: item.created_at
              ? Moment(item.created_at, "DD/MM/YYYY").format("DD MMM YYYY")
              : "N/A",
            assigned_admin:
              item.assignee_full_name &&
              item.assignee_full_name +
                "\n" +
                " ( " +
                item.assignee_side_status +
                " )",
          };
          tmp.push(tmpData);
        }
        if (item.status_id === 1) {
          pendingCount++;
        } else if (item.status_id === 2) {
          approvedCount++;
        } else if (item.status_id === 3) {
          rejectedCount++;
        }
      });

      return this.setState({
        checkedArray: [],
        pendingApprovals: tmp,
        pendingCount: pendingCount,
        approvedCount: approvedCount,
        rejectedCount: rejectedCount,
      });
    };
    getPendingNewRENFailed = (error) => requestError(error);

    approveNewRENS = () => {
      let tmp = {};
      let tempArray = [];
      this.state.checkedArray.map((item) => {
        let tempItem = _.find(this.state.pendingApprovals, { id: item });
        let tempObj = { id: 0 };
        tempObj.id = tempItem && tempItem.id;
        tempArray.push(tempObj);
      });
      tmp.approval_ids = tempArray;
      tmp.status_id = 2;
      Post(
        `/registrations/approve`,
        tmp,
        this.approveNewRENSSuccess,
        this.approveNewRENSFailed,
        this.load,
      );
    };
    approveNewRENSSuccess = (payload) => {
      this.setState({
        showApprovalConfirmationModal: false,
        showRejectConfirmationModal: false,
        showReassignMatricConfirmationModal: false,
        showReassignDirectConfirmationModal: false,
      });
      this.getPendingNewREN();
      requestSuccess(payload.message);
    };
    approveNewRENSFailed = (error) => requestError(error);

    reassignDirectLeader = (param) => {
      let tmp = {};
      let tmpArray = [];
      tmp.status_id = 4;
      tmp.direct_leader_id = param;
      this.state.checkedArray.map((item) => {
        let tempObj = { id: 0 };
        tempObj.id = item;
        tmpArray.push(tempObj);
      });
      tmp.approval_ids = tmpArray;
      Post(
        `/registrations/transfer`,
        tmp,
        () => {
          this.setState({
            showReassignDirectConfirmationModal: false,
          });
          this.getPendingNewREN();
          requestSuccess("Reassign referrer completed successfully.");
        },
        this.approveNewRENSFailed,
        this.load,
      );
    };

    onClickTabApproval = () =>
      this.setState({ showApprovalConfirmationModal: true });

    getLeaderOptions = () =>
      Get(
        `/agents`,
        this.getLeaderOptionsSuccess,
        this.getLeaderOptionsFailed,
        this.load,
      );

    getLeaderOptionsSuccess = (payload) => requestSuccess(payload);

    getLeaderOptionsFailed = (error) => requestError(error);

    closeModal = () =>
      this.setState({
        showApprovalConfirmationModal: false,
        showRejectConfirmationModal: false,
        showReassignMatricConfirmationModal: false,
        showReassignDirectConfirmationModal: false,
      });

    onClickTabAssignDirectLeader = () =>
      this.setState({ showReassignDirectConfirmationModal: true });

    onChangeDirectLeaderValue = (param) =>
      this.setState({ searchDirectLeaderValue: param });

    reassignDirectNewRENS = () => {
      this.setState({ showReassignDirectConfirmationModal: false });
    };

    onHandleCheckBox = (param) => {
      let temp = _.cloneDeep(this.state.checkedArray);

      if (_.includes(temp, param)) {
        const tmpArray = [];
        tmpArray.push(param);
        temp = _.difference(temp, tmpArray);
      } else {
        temp.push(param);
      }
      this.setState({ checkedArray: temp });
    };

    onClickSelectAll = (checkableData) => {
      if (this.state.checkedArray.length !== checkableData.length) {
        let checkedItem = _.reduce(
          checkableData,
          function (sum, n) {
            n.status !== "Approved" && sum.push(n.id);
            return sum;
          },
          [],
        );
        this.setState({ checkedArray: checkedItem });
      } else {
        this.setState({ checkedArray: [] });
      }
    };

    onClickDeselectAll = () => {
      this.setState({ checkedArray: [] });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            can_update={this.state.can_update}
            jobTitleContent={this.state.jobTitleContent}
            pendingApprovals={this.state.pendingApprovals}
            currentPageData={this.state.currentPageData}
            checkedArray={this.state.checkedArray}
            selectedStatus={this.state.selectedStatus}
            pendingCount={this.state.pendingCount}
            approvedCount={this.state.approvedCount}
            rejectedCount={this.state.rejectedCount}
            searchDirectLeaderValue={this.state.searchDirectLeaderValue}
            searchedResult={this.state.searchedResult}
            assignLeaderType={this.state.assignLeaderType}
            showApprovalConfirmationModal={
              this.state.showApprovalConfirmationModal
            }
            showReassignDirectConfirmationModal={
              this.state.showReassignDirectConfirmationModal
            }
            onChangePendingApprovalHOC={this.onChangePendingApprovalHOC}
            getLeaderOptions={this.getLeaderOptions}
            getPendingNewREN={this.getPendingNewREN}
            onClickTabApproval={this.onClickTabApproval}
            onClickTabAssignDirectLeader={this.onClickTabAssignDirectLeader}
            onHandleCheckBox={this.onHandleCheckBox}
            closeModal={this.closeModal}
            approveNewRENS={this.approveNewRENS}
            reassignDirectLeader={this.reassignDirectLeader}
            onClickSelectAll={this.onClickSelectAll}
            onChangeDirectLeaderValue={this.onChangeDirectLeaderValue}
            onClickDeselectAll={this.onClickDeselectAll}
            getAllDictionaryData={this.getAllDictionaryData}
            onLoadPendingApproval={this.state.loading}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    refreshToken,
    getAllDictionaryData,
  })(PendingApprovalWrappedComponent);
};

export default PendingApprovalHOC;
