import React, { Component } from "react";
import { compose } from "redux";
import { Dialog, Tooltip } from "@material-ui/core";
import { RiTimeFill, RiTimeLine } from "react-icons/ri";
import _ from "lodash";
import { IoMdAlert } from "react-icons/io";
import ReactHTMLParser from "react-html-parser";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import AtPaginatedTable from "components/NewPaginatedTable";
import AtlasDialog from "components/Dialog";
import AtlasCard from "components/Card";
import LoadingModal from "components/LoadingModal";
import TransferTicketForm from "./transferTicker";
import PaymentProofPreview from "./proofPreview";
import AttendeeContent from "./attendeeDetails";
import PaymentDetail from "./paymentDetail";
import AtlasButton from "components/Button";
import AttendeeSummary from "./attendeeSummary";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import RejectConfirmationModal from "./rejectModal";

import EventAttendeeHOC from "../actions/attendee";
import TransferHOC from "../actions/transfer";
import permissionsChecker from "utils/permissionsChecker";
import isEmptyValue from "utils/isEmpty";
import { getColorBadge } from "dictionary/badgeColor";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
);

export const StatusColor = [
  { value: "Present", colorName: "Green" },
  { value: "Pending", colorName: "Yellow" },
  { value: "Reset", colorName: "Red" },
  { value: "Absent", colorName: "Red" },
];

const headerData = ({ onClickUpload, onClickShowPayment }) => [
  {
    header: "User",
    columnStyle: { width: "120%" },
    renderColumn: (rowData) => (
      <>
        <p>{rowData.user_name || "N/A"}</p>
        <p>{rowData.email || "N/A"}</p>
        <p>{rowData.mobile_contact_number || "N/A"}</p>
        <p>{rowData.team || "N/A"}</p>
      </>
    ),
  },
  {
    header: "Clock in/out time",
    columnStyle: { width: "70%" },
    renderColumn: (rowData) => (
      <>
        <p>
          <RiTimeLine style={{ width: 12, marginRight: 10 }} />
          {rowData.clock_in_time}
        </p>
        <p>
          <RiTimeFill style={{ width: 12, marginRight: 10 }} />
          {rowData.clock_out_time}
        </p>
      </>
    ),
  },
  {
    header: "Join Duration",
    accessor: "join_duration_minute",
    columnStyle: { width: "50%" },
  },
  {
    header: "Payment Method",
    columnStyle: { width: "90%" },
    renderColumn: (rowData) => {
      const { payment_method, payment_method_id, proof_of_payment_url } =
        rowData;
      return (
        <>
          <p>{payment_method}</p>
          {!(payment_method_id === 2 && !proof_of_payment_url) && (
            <AtlasButton
              disabled={![1, 2].includes(payment_method_id)}
              containerStyle={{ marginRight: "5px" }}
              className={`btn-new btn-new--secondary mt-1`}
              children={"View Payment"}
              tooltip={true}
              tooltipChildren={<span>Payment</span>}
              tooltipPosition={"top"}
              tooltipID={`details-payment`}
              onClick={() => onClickShowPayment(rowData)}
            />
          )}
          {payment_method_id === 2 && !proof_of_payment_url && (
            <AtlasButton
              className={`btn-new btn-new--secondary mt-1`}
              children={"Upload Payment Slip"}
              tooltip={true}
              tooltipChildren={<span>Upload</span>}
              tooltipPosition={"top"}
              tooltipID={`details-upload`}
              onClick={() => onClickUpload(rowData)}
            />
          )}
        </>
      );
    },
  },
  {
    header: "Is Approved",
    columnStyle: { width: "70%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { reject_reason = "" } = !isEmptyValue(rowData.remark)
        ? rowData.remark
        : { reject_reason: "" };
      return (
        <>
          <p>{rowData.is_approved}</p>
          {rowData.is_approved === "No" && reject_reason && (
            <Tooltip
              classes={{ tooltip: "tooltip-arrow top" }}
              enterTouchDelay={50}
              placement="top"
              title={<p>{ReactHTMLParser(reject_reason)}</p>}
            >
              <IoMdAlert
                className={"svg-icon-info"}
                style={{ fill: "#F8E634" }}
              />
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    header: "Attendance Status",
    columnStyle: { width: "70%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.attendance_status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.attendance_status}
        </div>
      );
    },
  },
];

class AttendeeList extends Component {
  filepond = null;

  componentDidMount = () => {
    this.props.getSelectedEvent(this.props.selectedEvent.id);
    this.props.getAttendeeList(this.props.selectedEvent.id, 1, "");
    this.props.getAttendeeSummary(this.props.selectedEvent.id);
    this.props.getEventTicketPricing(this.props.selectedEvent.id);
  };

  renderUploadPaymentProof = () => {
    const onChangePaymentProof = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        let files = e.target.files;
        Object.keys(e.target.files).map((key) => {
          let reader = new FileReader();
          reader.onload = (e) => {
            let tmp = {
              proof_of_payment: e.target.result,
              proof_of_payment_file_name: files[key].name,
            };
            this.props.onChangeAttendeeHOC(tmp, "paymentProof");
            this.props.onChangeAttendeeHOC(true, "showPaymentProofPreview");
          };
          reader.readAsDataURL(e.target.files[key]);
        });
      }
    };

    return (
      <input
        style={{ display: "none" }}
        type="file"
        ref={(ref) => (this.filePond = ref)}
        accept="application/pdf, image/*"
        onChange={(e) => onChangePaymentProof(e)}
      />
    );
  };

  renderConfirmationModal = () => {
    const {
      onLoadAttendee,
      selectedAttendee,
      selectedEvent,
      showRemoveConfirmation,
      showCheckInConfirmation,
      showCheckOutConfirmation,

      removeAttendee,
      checkIn,
      checkOut,
      onChangeAttendeeHOC,
    } = this.props;

    const { full_name } = !_.isEmpty(this.props.selectedAttendee.agent)
      ? this.props.selectedAttendee.agent
      : { full_name: "" };

    return (
      <>
        <ConfirmationModal
          maxWidth={"xl"}
          mode={"alert"}
          open={showRemoveConfirmation}
          title={"Remove Attendee"}
          message={"Are you sure you want to delete the attendee?"}
          loading={onLoadAttendee}
          positiveAction={() =>
            Promise.all([
              onChangeAttendeeHOC(false, "showRemoveConfirmation"),
            ]).then(() => removeAttendee(selectedAttendee.id, selectedEvent.id))
          }
          negativeAction={() =>
            onChangeAttendeeHOC(false, "showRemoveConfirmation")
          }
        />
        <ConfirmationModal
          maxWidth={"xl"}
          mode={"alert"}
          open={showCheckInConfirmation}
          title={"Check In"}
          message={
            <p>
              Are you sure you want check in for <i>{full_name}</i>?
            </p>
          }
          loading={onLoadAttendee}
          positiveAction={() =>
            Promise.all([
              onChangeAttendeeHOC(false, "showCheckInConfirmation"),
            ]).then(() =>
              checkIn(
                selectedAttendee.id,
                selectedAttendee.agent.referrer_code,
              ),
            )
          }
          negativeAction={() =>
            onChangeAttendeeHOC(false, "showCheckInConfirmation")
          }
        />
        <ConfirmationModal
          maxWidth={"xl"}
          mode={"alert"}
          open={showCheckOutConfirmation}
          title={"Check Out"}
          message={
            <p>
              Are you sure you want to check out for <i>{full_name}</i>?
            </p>
          }
          showLoading={onLoadAttendee}
          positiveAction={() =>
            Promise.all([
              onChangeAttendeeHOC(false, "showCheckOutConfirmation"),
            ]).then(() =>
              checkOut(
                selectedAttendee.id,
                selectedAttendee.agent.referrer_code,
              ),
            )
          }
          negativeAction={() =>
            onChangeAttendeeHOC(false, "showCheckOutConfirmation")
          }
        />
      </>
    );
  };

  renderAttendeeDialog = () => {
    const {
      uploadAttendeeList,
      uploadedAttendeeListFile,
      onChangeAttendeeHOC,
      onLoadAttendee,
    } = this.props;

    return (
      <>
        <FilePond
          acceptedFileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          allowMultiple={false}
          fullWidth
          maxFiles={1}
          onupdatefiles={(fileItems) =>
            onChangeAttendeeHOC(fileItems, "uploadedAttendeeListFile")
          }
        />
        <div className="d-flex">
          <button
            className={"btn-new btn-new--success"}
            disabled={!uploadedAttendeeListFile}
            onClick={() => uploadAttendeeList(this.props.selectedEvent.id)}
          >
            Upload Attendee
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() =>
              onChangeAttendeeHOC(false, "showUploadAttendeeDialog")
            }
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  render = () => {
    const {
      eventList,
      attendeeList,
      selectedAttendee,
      attendeePages,
      searchParams,
      summaryLoading,
      putAttendee,
      updateAttendee,
      eventTicketPricing,
      getAttendeeSummary,
      getAttendeeList,
      getEventBySearch,
      attendeeSummary,
      attendeePaymentDetail,
      showTransferTicket,
      transferType,
      showAttendeePaymentDialog,
      onChangeTransferTicketHOC,
      transferTicket,
      transferHistories,
      transferTicketRequest,
      transferTicketEventRequest,
      selectedEvent,
      onChangeAttendeeHOC,
      showRejectModal,
      getAttendeePayment,
      getSelectedAttendee,
      showPaymentProofPreview,
      showAddAttendeeDialog,
      showEditAttendeeDialog,
      showUploadAttendeeDialog,
      exportAttendee,
      paymentProof,
      uploadPaymentProof,
      getTransferHistories,
      regradeAttendance,
      rejectRegistration,
      onLoadAttendee,
      onLoadTransferTicket,
    } = this.props;
    const { can_create, can_update } = permissionsChecker(
      "Company Events",
      this.props.data,
    );

    return (
      <>
        <AttendeeSummary
          selectedEvent={selectedEvent}
          summaryLoading={summaryLoading}
          attendeeSummary={attendeeSummary}
          getAttendeeSummary={getAttendeeSummary}
        />
        <div className={"d-flex flex-wrap mt-10 grid_gap-1"}>
          {can_create && (
            <AtlasButton
              className={"btn-new btn-new--secondary mr-10 at-btn--wd"}
              children={"Add Attendee"}
              onClick={() => onChangeAttendeeHOC(true, "showAddAttendeeDialog")}
            />
          )}
          <AtlasButton
            className={"btn-new btn-new--secondary mr-10 at-btn--wd"}
            children={"Export Attendee"}
            onClick={() => exportAttendee(selectedEvent)}
          />
          {can_create && (
            <AtlasButton
              className={"btn-new btn-new--secondary mr-10 at-btn--wd"}
              children={"Upload Attendee List"}
              onClick={() =>
                onChangeAttendeeHOC(true, "showUploadAttendeeDialog")
              }
            />
          )}
          {can_update && (
            <AtlasButton
              className={"btn-new btn-new--secondary mr-10 at-btn--wd"}
              children={"Regrade Attendance"}
              onClick={() => regradeAttendance(selectedEvent.id)}
            />
          )}
        </div>
        <hr />
        <AtPaginatedTable
          columns={headerData({
            onClickUpload: (val) =>
              Promise.all([getSelectedAttendee(val.id)]).then(() =>
                this.filePond.click(),
              ),
            onClickShowPayment: (val) =>
              val.payment_method_id === 1
                ? getAttendeePayment(val.id)
                : window.open(val.proof_of_payment_url, "_blank"),
          })}
          rowData={attendeeList.data || []}
          meta={attendeeList.meta || {}}
          searchParams={searchParams}
          onChangeSearchParams={(val) =>
            onChangeAttendeeHOC(val, "searchParams")
          }
          getListAPI={(page, search) =>
            getAttendeeList(selectedEvent.id, page, search)
          }
          totalPages={attendeePages}
          actionColumnContent={
            can_update
              ? [
                  {
                    name: "edit",
                    onClick: (rowData) =>
                      Promise.all([getSelectedAttendee(rowData.id)]).then(() =>
                        onChangeAttendeeHOC(true, "showEditAttendeeDialog"),
                      ),
                  },
                  {
                    name: "delete",
                    color: "#F04438",
                    disabled: (rowData) =>
                      !(
                        rowData.payment_method_id === 0 ||
                        (rowData.payment_method_id === 2 &&
                          !rowData.proof_of_payment_url)
                      ) || rowData.is_approved === "Yes",
                    onClick: (rowData) =>
                      Promise.all([getSelectedAttendee(rowData.id)]).then(() =>
                        onChangeAttendeeHOC(true, "showRemoveConfirmation"),
                      ),
                  },
                ]
              : []
          }
        />
        {this.renderUploadPaymentProof()}

        <AtlasDialog open={showAddAttendeeDialog}>
          <AtlasCard
            className="h-100"
            cardContent={
              <AttendeeContent
                mode={"create"}
                selectedEvent={selectedEvent}
                onLoadAttendee={onLoadAttendee}
                eventTicketPricing={eventTicketPricing}
                putAttendee={putAttendee}
                onChangeAttendeeHOC={onChangeAttendeeHOC}
                onClose={() =>
                  onChangeAttendeeHOC(false, "showAddAttendeeDialog")
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={showEditAttendeeDialog}>
          <AtlasCard
            className="h-100"
            cardContent={
              <AttendeeContent
                mode={"update"}
                selectedEvent={selectedEvent}
                onLoadAttendee={onLoadAttendee}
                selectedAttendee={selectedAttendee}
                transferHistories={transferHistories}
                eventTicketPricing={eventTicketPricing}
                onLoadTransferTicket={onLoadTransferTicket}
                updateAttendee={updateAttendee}
                getTransferHistories={getTransferHistories}
                onChangeAttendeeHOC={onChangeAttendeeHOC}
                onClose={() =>
                  onChangeAttendeeHOC(false, "showEditAttendeeDialog")
                }
                onClickRemove={() =>
                  onChangeAttendeeHOC(true, "showRemoveConfirmation")
                }
                onClickCheckIn={() =>
                  onChangeAttendeeHOC(true, "showCheckInConfirmation")
                }
                onClickCheckout={() =>
                  onChangeAttendeeHOC(true, "showCheckOutConfirmation")
                }
                onClickReject={() =>
                  onChangeAttendeeHOC(true, "showRejectModal")
                }
                onClickTransfer={() =>
                  onChangeTransferTicketHOC("showTransferTicket", true)
                }
              />
            }
          />
        </AtlasDialog>
        {showUploadAttendeeDialog && (
          <ModalDialog
            title={"Upload Attendee"}
            onClose={() =>
              onChangeAttendeeHOC(false, "showUploadAttendeeDialog")
            }
            children={this.renderAttendeeDialog()}
          />
        )}
        <AtlasDialog open={showPaymentProofPreview}>
          <AtlasCard
            className="h-100"
            cardContent={
              <PaymentProofPreview
                paymentProof={paymentProof}
                onClickUpload={() =>
                  Promise.all([
                    onChangeAttendeeHOC(false, "showPaymentProofPreview"),
                  ]).then(() =>
                    uploadPaymentProof(selectedAttendee.id, selectedEvent.id),
                  )
                }
                onClose={() =>
                  onChangeAttendeeHOC(false, "showPaymentProofPreview")
                }
              />
            }
          />
        </AtlasDialog>
        <AtlasDialog open={showTransferTicket}>
          <AtlasCard
            className={"h-100"}
            cardContent={
              <TransferTicketForm
                transferType={transferType}
                eventList={eventList}
                onChangeTransferTicketHOC={onChangeTransferTicketHOC}
                transferTicket={transferTicket}
                selectedAttendee={selectedAttendee}
                transferTicketRequest={transferTicketRequest}
                transferTicketEventRequest={transferTicketEventRequest}
                getEventBySearch={getEventBySearch}
                getAttendeeSummary={getAttendeeSummary}
                onChangeAttendeeHOC={onChangeAttendeeHOC}
                onLoadTransferTicket={onLoadTransferTicket}
              />
            }
          />
        </AtlasDialog>
        <Dialog
          classes={{ paper: "at-confirmation_modal-responsive" }}
          fullWidth={true}
          open={showRejectModal}
        >
          <AtlasCard
            className="at-attendee-reject"
            containerStyle={{ overflow: "visible" }}
            cardContent={
              <RejectConfirmationModal
                confirmReject={(reason) =>
                  rejectRegistration(
                    selectedAttendee.id,
                    reason,
                    selectedAttendee.event_id,
                  )
                }
                onClose={() => onChangeAttendeeHOC(false, "showRejectModal")}
              />
            }
          />
        </Dialog>
        {this.renderConfirmationModal()}
        <PaymentDetail
          open={showAttendeePaymentDialog}
          attendeePaymentDetail={attendeePaymentDetail}
          onClickClose={() =>
            onChangeAttendeeHOC(false, "showAttendeePaymentDialog")
          }
        />
        {(onLoadAttendee || onLoadTransferTicket) && <LoadingModal />}
      </>
    );
  };
}

export default compose(EventAttendeeHOC, TransferHOC)(AttendeeList);
