import React, { Component } from "react";
import _ from "lodash";
import {
  BsChevronLeft,
  BsChevronDoubleLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from "react-icons/bs";

import "stylesheets/components/pagination/index.scss";

class Pagination extends Component {
  state = {
    listNumber: 0,
    totalSplitPages: [1],
    splitPages: [1],
    interval: 10,
    disableFirstPageButton: true,
    disablePreviousButton: false,
    disableNextList: false,
    disablePrevList: false,
    disableNextButton: false,
    disableLastPageButton: false,
  };

  componentDidMount = () => {
    this.handleResize();
    this.setState(
      {
        disablePrevList: true,
        disablePrevList: true,
        disableNextList: this.state.totalSplitPages.length < 2,
        disableFirstPageButton: true,
        disablePreviousButton: true,
        disableNextButton: this.props.totalPages.length <= 1,
        disableLastPageButton: this.state.totalSplitPages.length <= 1,
      },
      () => this.processPagination(),
    );

    window.addEventListener("resize", this.handleResize);
  };

  componentDidUpdate = (previousProps) => {
    if (this.props.totalPages !== previousProps.totalPages) {
      this.processPagination();
    }
  };

  componentWillUnmount = () => {
    return () => window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ interval: 3 });
    } else {
      this.setState({ interval: 10 });
    }
    this.processPagination();
  };

  processPagination = () => {
    let arrays = [];
    const totalPagesArray = _.cloneDeep(this.props.totalPages);
    while (totalPagesArray.length > 0) {
      arrays.push(totalPagesArray.splice(0, this.state.interval));
    }

    const tempArray = _.cloneDeep(arrays[0]);
    this.props.onClickPageNumber(1);
    this.setState({
      listNumber: 0,
      totalSplitPages: arrays,
      splitPages: tempArray,
      disablePreviousButton: true,
      disableFirstPageButton: true,
      disableNextList: arrays.length < 2,
      disableNextButton: this.props.totalPages.length <= 1,
      disableLastPageButton: this.props.totalPages.length <= 1,
    });
  };

  nextList = () => {
    this.setState((prevState) => ({
      listNumber: prevState.listNumber + 1,
      splitPages: this.state.totalSplitPages[prevState.listNumber + 1],
      disablePrevList: false,
      disableNextList:
        prevState.listNumber + 1 === prevState.totalSplitPages.length - 1,
    }));
  };

  prevList = () => {
    this.setState((prevState) => ({
      listNumber: prevState.listNumber - 1,
      splitPages: this.state.totalSplitPages[prevState.listNumber - 1],
      disableNextList: false,
      disablePrevList: prevState.listNumber - 1 === 0,
    }));
  };

  goToFirstPage = () => {
    this.setState(
      {
        listNumber: 0,
        splitPages: this.state.totalSplitPages[0],
        disableFirstPageButton: true,
        disablePreviousButton: true,
        disableLastPageButton: false,
        disableNextButton: false,
        disablePrevList: true,
        disableNextList: this.state.totalSplitPages.length < 2 ? true : false,
      },
      () => {
        this.props.onClickPageNumber(1);
      },
    );
  };

  updateCurrentPage = (type) => {
    if (type === "previous") {
      this.setState(
        {
          disableFirstPageButton: this.props.currentPageNumber - 1 === 1,
          disablePreviousButton: this.props.currentPageNumber - 1 === 1,
          disableLastPageButton: false,
          disableNextButton: false,
        },
        () => {
          this.props.onClickPageNumber(this.props.currentPageNumber - 1);
        },
      );
    } else if (type === "next") {
      this.setState(
        {
          disableFirstPageButton: false,
          disablePreviousButton: false,
          disableLastPageButton:
            this.props.currentPageNumber + 1 === this.props.totalPages.length,
          disableNextButton:
            this.props.currentPageNumber + 1 === this.props.totalPages.length,
        },
        () => {
          this.props.onClickPageNumber(this.props.currentPageNumber + 1);
        },
      );
    }
  };

  goToLastPage = () => {
    this.setState(
      {
        listNumber: this.state.totalSplitPages.length - 1,
        splitPages:
          this.state.totalSplitPages[this.state.totalSplitPages.length - 1],
        disableNextButton: true,
        disableLastPageButton: true,
        disableFirstPageButton: false,
        disablePreviousButton: false,
        disablePrevList: this.state.totalSplitPages.length < 2,
        disableNextList: true,
      },
      () => {
        this.props.onClickPageNumber(this.props.totalPages.length);
      },
    );
  };

  onClickPageNumber = (item) => {
    this.setState(
      {
        disableFirstPageButton: item === 1,
        disablePreviousButton: item === 1,
        disableLastPageButton: item === this.props.totalPages.length,
        disableNextButton: item === this.props.totalPages.length,
      },
      () => {
        this.props.onClickPageNumber(item);
      },
    );
  };

  render = () => {
    const highlightPageButton = (param) => {
      if (param === this.props.currentPageNumber) {
        return "active";
      }
    };

    return (
      <div className={`at-pagination ${this.props.className || ""}`}>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.disableFirstPageButton ? "disabled" : ""}`}
          onClick={() => this.goToFirstPage()}
        >
          <BsChevronDoubleLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`
            at-pagination__button
            at-pagination__button-arrow
            ${this.state.disablePreviousButton ? "disabled" : ""}
          `}
          onClick={() => this.updateCurrentPage("previous")}
        >
          <BsChevronLeft className="at-pagination__arrow" />
        </button>
        <button
          className={`at-pagination__button
            at-pagination__button-arrow
            ${this.state.disablePrevList ? "disabled" : ""}`}
          onClick={() => this.prevList()}
        >
          ...
        </button>
        {this.state &&
          this.state.splitPages &&
          this.state.splitPages.map((item) => {
            return (
              <button
                key={item}
                className={`at-pagination__button ${highlightPageButton(item)}`}
                onClick={() => this.onClickPageNumber(item)}
              >
                {item}
              </button>
            );
          })}
        <button
          className={`
            at-pagination__button
            at-pagination__button-arrow
            ${this.state.disableNextList ? "disabled" : ""}
          `}
          onClick={() => this.nextList()}
        >
          ...
        </button>
        <button
          className={`
            at-pagination__button
            ${this.state.disableNextButton ? "disabled" : ""}
            at-pagination__button-arrow
          `}
          onClick={() => this.updateCurrentPage("next")}
        >
          <BsChevronRight className="at-pagination__arrow" />
        </button>
        <button
          className={`
            at-pagination__button
            at-pagination__button-arrow
            ${this.state.disableLastPageButton ? "disabled" : ""}
          `}
          onClick={() => this.goToLastPage()}
        >
          <BsChevronDoubleRight className="at-pagination__arrow" />
        </button>
      </div>
    );
  };
}

export default Pagination;
