import React, { Component } from "react";

import CustomFormInput from "components/Input/formInput";

class ForgetPassword extends Component {
  render = () => {
    const { cooldownTiming, email } = this.props;
    return (
      <div className="login-form-container">
        <h4 className="at-form-input__title">Email</h4>
        <CustomFormInput
          type="text"
          value={email}
          onChangeValue={(val) => this.props.onChangeEmail(val)}
          placeholder={"Email"}
        />
        {!cooldownTiming && (
          <div className="mt-4">
            <button
              className="btn-new btn-new--primary text-uppercase w-100"
              onClick={() => this.props.onClickSubmitEmail()}
            >
              Submit
            </button>
          </div>
        )}
        {cooldownTiming > 0 && (
          <p className="title text-right">
            {`${cooldownTiming} seconds away from the next submit.`}
          </p>
        )}
      </div>
    );
  };
}

export default ForgetPassword;
