import { MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FaMinusCircle } from "react-icons/fa";

import AtlasButton from "components/Button";
import CustomFormInput from "components/Input/formInput";
import AtlasSelect from "components/Select/new";
import CustomTypeahead from "components/Typeahead/new";

import { comparisonOptions, fieldOptions } from "../../assets";

import { user } from "dictionary";

const Condition = ({
  condition,
  conditions,
  setConditions,
  teamsList,
  countriesList,
  branchesList,
  removeCondition,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let options;

    switch (condition.field) {
      case "team_id":
        options = teamsList;
        break;
      case "country_id":
        options = countriesList;
        break;
      case "status_id":
        options = user.statuses.map((user) => ({
          id: user.value,
          name: user.label,
        }));
        break;
      case "branch_id":
        options = branchesList;
        break;
      default:
        options = [];
        break;
    }

    setOptions(options);
  }, [condition.field]);

  const showMultiSelect = condition.field && condition.field.includes("_id");
  const hideInput =
    condition.comparison && condition.comparison.includes("NULL");
  const isMultipleSelection =
    condition.comparison && !condition.comparison.includes("=");

  const handleChange = (field) => (e) => {
    if (field === "field" || field === "comparison") {
      setConditions(
        conditions.map((item) =>
          item.id === condition.id
            ? {
                ...item,
                [field]: e.target.value,
                value: "",
                selected: [],
              }
            : item,
        ),
      );
    } else {
      setConditions(
        conditions.map((item) =>
          item.id === condition.id
            ? {
                ...item,
                value: e,
                selected: [],
              }
            : item,
        ),
      );
    }
  };

  const handleSelect = (value) => {
    setConditions(
      conditions.map((item) =>
        item.id === condition.id
          ? {
              ...item,
              selected: value,
            }
          : item,
      ),
    );
  };

  const renderInstruction = () => {
    const { field, comparison } = condition;
    const label = fieldOptions.find((option) => option.value === field)?.label;

    return !field?.includes("_id") && comparison?.includes("IN")
      ? `Please separate different ${label}s with a comma`
      : "";
  };

  return (
    <div className={"form-row mb-3"}>
      <div className={"col-md-3"}>
        <AtlasSelect value={condition.field} onChange={handleChange("field")}>
          {fieldOptions.map((option) => (
            <MenuItem
              classes={{ root: `at-select__dropdown-item` }}
              key={option.value}
              value={option.value}
              style={{ fontSize: 14 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </AtlasSelect>
      </div>

      <div className={"col-md-3"}>
        <AtlasSelect
          value={condition.comparison}
          onChange={handleChange("comparison")}
        >
          {comparisonOptions.map((option) => (
            <MenuItem
              classes={{ root: `at-select__dropdown-item` }}
              key={option.value}
              value={option.value}
              style={{ fontSize: 14 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </AtlasSelect>
      </div>

      <div className={"col-md-5"}>
        {!hideInput && (
          <>
            {showMultiSelect && (
              <div style={{ marginTop: "-0.5rem", marginBottom: -10 }}>
                <CustomTypeahead
                  containerStyle={{ width: "100%", padding: "2px 10px" }}
                  typeaheadId={condition.field}
                  className={"at-email-campaigns__rbt"}
                  multiple={isMultipleSelection}
                  selectedValue={condition.selected}
                  options={options}
                  labelKey={"name"}
                  placeholder={
                    isMultipleSelection ? "Select multiple" : "Select one"
                  }
                  onSelect={handleSelect}
                  childrenHead={(rowItem) => <p>{rowItem.name}</p>}
                />
              </div>
            )}
            {!showMultiSelect && (
              <CustomFormInput
                type={"text"}
                containerStyle={{ width: "100%", padding: "2px 10px" }}
                className={"mb-0"}
                value={condition.value}
                instruction={renderInstruction()}
                onChangeValue={handleChange("value")}
              />
            )}
          </>
        )}
      </div>

      <div className={"col-md-1 d-flex align-items-center"}>
        <AtlasButton
          type={"button"}
          className={"at-table-icon-button p-2"}
          tooltip={true}
          tooltipChildren={"Remove Condition"}
          tooltipPosition={"top"}
          tooltipID={"remove-condition"}
          onClick={removeCondition(condition.id)}
        >
          <FaMinusCircle />
        </AtlasButton>
      </div>
    </div>
  );
};

export default Condition;
