import React from "react";

import { BiRefresh } from "react-icons/bi";
import { MoonLoader } from "react-spinners";
import { Typography, Grid } from "@material-ui/core";

import AlertBox from "components/AlertBox";

const attendeeData = [
  { label: "Total Registered", value: "total_registered" },
  { label: "Total Present", value: "total_present" },
  { label: "Total Pending", value: "total_pending" },
  { label: "Total Absent", value: "total_absent" },
  { label: "Total Resit", value: "total_resit" },
];

const AttendeeSummary = ({
  selectedEvent,
  summaryLoading,
  attendeeSummary,
  getAttendeeSummary,
}) => {
  return (
    <AlertBox
      mode={"info"}
      iconStyle={{ display: "none" }}
      contentStyle={{ color: "black" }}
      className={"w-100"}
      title={
        <div style={{ display: "flex" }}>
          <Typography
            gutterBottom
            variant={"h6"}
            classes={{ root: "attendee-summary-text" }}
          >
            Summary
          </Typography>
          {!summaryLoading && (
            <button onClick={() => getAttendeeSummary(selectedEvent.id)}>
              <BiRefresh style={{ width: 27, height: 27 }} />
            </button>
          )}
          {summaryLoading && <MoonLoader size={22} />}
        </div>
      }
      description={
        <Grid container spacing={16}>
          {attendeeData.map((item) => {
            return (
              <Grid item lg={2} md={4} xs={6}>
                <Typography
                  classes={{ root: "attendee-summary-text" }}
                  variant={"subtitle2"}
                >
                  <span className="fw-600">{`${item.label}`}</span>
                  <br />
                  {attendeeSummary[item.value]}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      }
    />
  );
};

export default AttendeeSummary;
