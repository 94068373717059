import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";

import { FaPhoneAlt } from "react-icons/fa";
import ConfirmationModal from "components/Modal/confirmation";
import CallImage from "assets/images/call_support.png";

import "./index.scss";

const CallButton = () => {
  const [showCallConfirmation, setShowCallConfirmation] = useState(false);

  return (
    <>
      <Tooltip placement={"left"} title={"Call to Helpdesk"}>
        <button
          className="at-support-group-btn telegram"
          onMouseDown={() => setShowCallConfirmation(true)}
          onTouchEnd={(event) => event.preventDefault()}
          onTouchStart={() => setShowCallConfirmation(true)}
        >
          <img src={CallImage} style={{ width: "40px", height: "40px" }} />
        </button>
      </Tooltip>
      <ConfirmationModal
        mode={"alert"}
        open={showCallConfirmation}
        title={"Helpdesk"}
        message={
          "An exclusive and premier support line for IQI warriors in all your real estate queries. Click the call button below to talk to one of our Exclusive Care Service team. This service is available between 10am until 10pm everyday including public holidays."
        }
        loading={false}
        positiveText={
          <p>
            <FaPhoneAlt style={{ width: 14, height: 14, marginRight: 10 }} />
            Call
          </p>
        }
        positiveAction={() => {
          Promise.all([(window.location.href = "tel:+1800222155")]).then(() => {
            setShowCallConfirmation(false);
          });
        }}
        negativeText={"Cancel"}
        negativeAction={() => setShowCallConfirmation(false)}
      />
    </>
  );
};

export default CallButton;
