import React, { useState, useEffect } from "react";
import ReactHTMLParser from "react-html-parser";
import { IconButton } from "@material-ui/core";
import { HiOutlineExternalLink } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasCloseButton from "components/Button/prev";
import RecommendSection from "./Recommend";
import PhotoSlider from "./PhotoSlider";

import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";
import { numberWithCommas } from "utils/thousandSeparator";
import {
  featureProperty,
  detailsSection,
  listingAgent,
  publishPlatformLib,
} from "./assets";

import defaultAvatar from "assets/images/av-male.jpg";
import defaultImage from "assets/images/iqi_logo_only.png";

import "./index.scss";
import "./recommendations.scss";

const cobrokeSetting = [
  {
    label: "Commission",
    value: "total_portion",
    icon: "atlas-percentage-circle",
    colorName: "Teal",
  },
  {
    label: "My Portion ",
    value: "my_portion",
    icon: "atlas-profile-circle",
    colorName: "Blue",
  },
  {
    label: "Co-broke Agent Portion",
    value: "balance",
    icon: "atlas-shake-hand",
    colorName: "Fuchsia",
  },
];

const StatusColor = [
  { value: "Active", colorName: "Green" },
  { value: "Draft", colorName: "Yellow" },
  { value: "Inactive/Expired", colorName: "Red" },
];

const View = ({
  data,
  initialRecommendation,
  fullRecommendationList,
  mediaLink,
  subsalesEntryCreationProps,
  floorPlanListing,
  descriptionTab,
  onLoadFavourite,
  onLoadSubsales,
  onLoadView,
  onLoadMediaLink,
  showViewModal,

  onChangeSubsalesHOC,
  toggleFavourite,
  getMediaLinks,
}) => {
  const {
    code,
    asking_price_cents,
    asking_price_currency,
    id,
    address,
    property_name,
    images,
    description,
    zh_description,
    facilities = [],
    other_facilities,
    status_id,

    built_up,
    land_area,
    measurement,

    featureArray = [],

    agent_profile_photo_url,
    agent_full_name,

    published_to_iqi,
    published_to_juwai,
    exported_juwai_asia_link,
    exported_iqi_link,
    exported_to_juwai,
    exported_to_edgeprop,
    published_to_edge_prop,
    juwai_link,
    open_for_internal_co_broke,
    co_broke_settings,
  } = subsalesEntryCreationProps;

  const [customStyle, setCustomStyle] = useState("");
  const { can_update } = permissionsChecker("Listing Centre", data);

  useEffect(() => {
    let tmpStyle = `
      "header"
      "gallery"
      "recommendation"
      "subsalesDetails"
      "subsalesPublishedPlatforms"
      "subsalesFeatures"
      "facilities"
      "listingAgent"
      "descriptionContainer"
    `;
    if (!published_to_iqi || !exported_iqi_link) {
      if (
        !published_to_juwai ||
        (!exported_to_juwai && !juwai_link && !exported_juwai_asia_link)
      ) {
        tmpStyle = tmpStyle.replace(`"subsalesPublishedPlatforms"`, "");
      }
    }

    if (!_.some(facilities, { value: true })) {
      tmpStyle = tmpStyle.replace(`"facilities"`, "");
    }

    if (!_.some(featureArray, { value: true })) {
      tmpStyle = tmpStyle.replace(`"subsalesFeatures"`, "");
    }

    setCustomStyle(`grid-template-areas: ${tmpStyle}`);
  }, [subsalesEntryCreationProps]);

  useEffect(() => {
    if (showViewModal) {
      getMediaLinks(subsalesEntryCreationProps.id);
    }
  }, [showViewModal]);

  const renderPublishingPlatforms = () => {
    const LogoPlatform = ({ title, linkSrc, logoSrc }) => {
      return (
        <a
          href={linkSrc}
          target="_blank"
          className="at-subsales_listing_view-published_platforms-item"
        >
          <div className="d-flex align-items-center">
            <img src={logoSrc} />
            <span>{title}</span>
          </div>
          <AtlasIcon svgHref={"atlas-link-2-linear"} />
        </a>
      );
    };
    return (
      ((published_to_iqi && exported_iqi_link) ||
        (published_to_juwai &&
          exported_to_juwai &&
          (juwai_link || exported_juwai_asia_link)) ||
        (exported_to_edgeprop && published_to_edge_prop)) && (
        <div className="at-subsales_listing__view-published_platforms">
          <div className="at-subsales-listing__view-title">
            <AtlasIcon
              svgHref={"atlas-global"}
              style={{ fill: "#F0631D", marginRight: 10 }}
            />
            Published Platforms
          </div>
          {publishPlatformLib.map((item, index) => {
            let tmpCheckingLink =
              subsalesEntryCreationProps[item.linkSrc] &&
              subsalesEntryCreationProps[item.linkSrc].length > 0 &&
              subsalesEntryCreationProps[item.published] &&
              (item.exported
                ? subsalesEntryCreationProps[item.exported]
                : true);

            return (
              tmpCheckingLink && (
                <LogoPlatform
                  {...item}
                  key={index}
                  linkSrc={subsalesEntryCreationProps[item.linkSrc]}
                />
              )
            );
          })}
        </div>
      )
    );
  };

  const renderStyling = () => {
    return (
      <style>
        {`.at-subsales_listing__view {
              ${customStyle}
            }

            @media (min-width: 992px) {
              .at-subsales_listing__view {
                grid-template-areas:
                  "header header"
                  ${
                    open_for_internal_co_broke &&
                    fullRecommendationList.length > 0 &&
                    status_id === 1
                      ? '"recommendation recommendation"'
                      : ""
                  }
                  "left right" !important;
              }
            }
          
            @media (min-width: 1200px) {
              .at-subsales_listing__view {
                grid-template-areas:
                  "header header"
                  ${
                    open_for_internal_co_broke &&
                    fullRecommendationList.length > 0 &&
                    status_id === 1
                      ? '"recommendation recommendation"'
                      : ""
                  }
                  "left left right"
                  "listingTeam listingTeam" !important;
              }
            }
          `}
      </style>
    );
  };

  return (
    <>
      <div
        className="at-card__header"
        style={{ padding: "20px 20px 0px 20px" }}
      >
        <AtlasCloseButton
          onClick={() => onChangeSubsalesHOC(false, "showViewModal")}
        />
        <h4 className="at-card__title">Listing Details</h4>
      </div>
      <div className="at-subsales_listing__view">
        <div className="at-subsales_listing__view-header">
          <div className="at-subsales_listing__view-header-topleft">
            <div className="at-subsales_listing__view-header-topleft_content">
              <h6 className="at-subsales__code mb-3">{code}</h6>
              <p className="at-subsales_listing__view-address_title ml-2">
                {property_name}
              </p>
              {address && (
                <div className="d-flex align-items-center ml-2">
                  <AtlasIcon
                    svgHref={"atlas-location"}
                    style={{
                      minWidth: 14,
                      width: 14,
                      height: 14,
                      fill: "#F0631D",
                      marginRight: 10,
                    }}
                  />
                  <h6 className="at-subsales_listing__view-address_subtitle">{`${address}`}</h6>
                </div>
              )}
              <p className="at-subsales__price ml-2">
                <span className="mr-2" style={{ color: "#111827" }}>
                  {asking_price_currency}
                </span>
                {asking_price_cents && asking_price_cents > 0
                  ? numberWithCommas(asking_price_cents)
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="at-subsales_listing__view-header-right ml-2">
            <div className="at-subsales_listing__feature_property">
              {featureProperty.map((item, index) => (
                <div key={index} className="at-subsales_listing__feature-item">
                  <AtlasIcon svgHref={item.icon} style={{ fill: "#F0631D" }} />
                  <div>
                    <div className="at-subsales_listing_title">
                      {item.label}
                    </div>
                    <div className="at-subsales_listing_content">
                      {subsalesEntryCreationProps[item.value]}
                    </div>
                  </div>
                </div>
              ))}
              <div className="at-subsales_listing__feature-item">
                <AtlasIcon
                  svgHref={"atlas-layer"}
                  style={{ fill: "#F0631D" }}
                />
                <div>
                  <div className="at-subsales_listing_title">
                    Built Up / Land Area
                  </div>
                  <div className="at-subsales_listing_content">{`${numberWithCommas(built_up)} ${measurement}`}</div>
                </div>
              </div>
              <div className="at-subsales_listing__feature-item">
                <AtlasIcon
                  svgHref={"atlas-miximize-3-linear"}
                  style={{ fill: "#F0631D" }}
                />
                <div>
                  <div className="at-subsales_listing_title">Land Area</div>
                  <div className="at-subsales_listing_content">{`${numberWithCommas(land_area)} ${measurement}`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="at-subsales_listing__view-header-button_cont">
            {can_update && (
              <button
                className="btn-new btn-new--secondary"
                onClick={() => onChangeSubsalesHOC(true, "showEditModal")}
              >
                <FiEdit style={{ color: "#FFFFFF" }} />
                Edit
              </button>
            )}
          </div>
        </div>
        {open_for_internal_co_broke &&
          fullRecommendationList.length > 0 &&
          status_id === 1 && (
            <RecommendSection
              data={data}
              initialRecommendation={initialRecommendation}
              fullRecommendationList={fullRecommendationList}
            />
          )}
        {open_for_internal_co_broke &&
          fullRecommendationList.length > 0 &&
          status_id !== 1 && (
            <div className="at-subsales_listing__view-recommendation at-form__content">
              <p className="at-subsales_listing__view-recommendation_title">
                Co-broke Recommendations
              </p>
              <p className="at-card__description">
                The listing is not active. To use the recommendation engine you
                have to first make the listing active. Click on the{" "}
                <strong>{` Edit `}</strong> button above and change the status.
              </p>
            </div>
          )}
        <div className="at-subsales_listing__view-left_column">
          <div className="at-subsales_listing__view-photo_slider_cont">
            <PhotoSlider
              id={id}
              images={images && images.length > 0 ? images : defaultImage}
              floorPlanListing={floorPlanListing}
            />
          </div>
          <div className="at-subsales_listing__view-description">
            <div className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-send-2"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              Description
            </div>
            <div className="at-subsales_listing__view-description_tab">
              <button
                className={
                  descriptionTab === "Eng"
                    ? "at-subsales_listing_view-selected_description"
                    : ""
                }
                onClick={() => onChangeSubsalesHOC("Eng", "descriptionTab")}
              >
                English
              </button>
              <button
                className={
                  descriptionTab === "Zh"
                    ? "at-subsales_listing_view-selected_description"
                    : ""
                }
                onClick={() => onChangeSubsalesHOC("Zh", "descriptionTab")}
              >
                Chinese
              </button>
            </div>
            <div className="at-subsales_listing__view-description-content">
              {descriptionTab === "Eng" && (
                <div
                  className={`at-subsales_listing__view-description_eng at-subsales_listing_view-selected_description`}
                >
                  {description ? ReactHTMLParser(description) : "N/A"}
                </div>
              )}
              {descriptionTab === "Zh" && (
                <div
                  className={`at-subsales_listing__view-description_zh at-subsales_listing_view-selected_description`}
                >
                  {zh_description ? ReactHTMLParser(zh_description) : "N/A"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="at-subsales_listing__view-right_column">
          <div className="at-subsales_listing__view-details">
            <div className="at-subsales-listing__view-title">
              <AtlasIcon
                svgHref={"atlas-note-text"}
                style={{ fill: "#F0631D", marginRight: 10 }}
              />
              Details
            </div>
            {detailsSection.map((item, index) => {
              const { colorName = "" } =
                _.find(StatusColor, {
                  value: subsalesEntryCreationProps.status,
                }) || {};

              return (
                <div
                  key={index}
                  className="at-subsales-listing__view-details-item"
                >
                  <label>
                    <AtlasIcon svgHref={item.icon} />
                    {item.label}
                  </label>
                  <p>
                    {item.value === "status" && (
                      <div
                        className="at-status_badge mt-1"
                        style={{ ...getColorBadge(colorName) }}
                      >
                        {subsalesEntryCreationProps.status}
                      </div>
                    )}
                    {item.value !== "status" &&
                      (subsalesEntryCreationProps[item.value] || "N/A")}
                  </p>
                </div>
              );
            })}
          </div>
          {featureArray && _.some(featureArray, { value: true }) && (
            <div className="at-subsales_listing__view-features">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-building-3"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Features
              </div>
              {featureArray.map((item, index) => (
                <div
                  key={index}
                  className="at-subsales-listing__view-details-item"
                >
                  <label>
                    <AtlasIcon svgHref={item.icon} />
                    {item.label}
                  </label>
                  {item.value}
                </div>
              ))}
            </div>
          )}
          {facilities && _.some(facilities, { value: true }) && (
            <div className="at-subsales_listing__view-facilities">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-building-4"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Facilities
              </div>
              <ul className="mb-10">
                {facilities.map(
                  (item, index) =>
                    item.value && <li key={index}>{item.label}</li>,
                )}
              </ul>
              {other_facilities && (
                <div className="at-subsales_listing__view-">
                  <div className="at-subsales-listing__view-title">
                    <AtlasIcon
                      svgHref={"atlas-note-text"}
                      style={{ fill: "#F0631D", marginRight: 10 }}
                    />
                    Other Facilities
                  </div>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    {other_facilities}
                  </p>
                </div>
              )}
            </div>
          )}
          {mediaLink.length > 0 && (
            <div className="at-subsales_listing__view-media-link">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-video-play"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Media Link
              </div>
              <ul>
                {mediaLink.map((item) => (
                  <li>
                    <span className="mr-1">{item.title} </span>
                    <IconButton
                      className={"p-0"}
                      onClick={() => {
                        window.open(item.url);
                      }}
                    >
                      <HiOutlineExternalLink style={{ width: 20 }} />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {status_id === 1 && renderPublishingPlatforms()}
          {co_broke_settings && (
            <div className="at-subsales-listing__view-cobroke-settings">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-shake-hand"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Cobroke Settings
              </div>
              <div
                className="d-flex align-items-center justify-content-between mt-2"
                style={{ height: 180 }}
              >
                {cobrokeSetting.map((item, index) => (
                  <div
                    key={index}
                    className="at-subsales-listing__view-cobroke-settings-item"
                  >
                    <AtlasIcon
                      svgHref={item.icon}
                      style={{ ...getColorBadge(item.colorName) }}
                    />
                    <label>{item.label}</label>
                    {co_broke_settings && co_broke_settings[item.value]
                      ? `${co_broke_settings[item.value]} ${co_broke_settings.co_broke_portion_by === "amount" ? asking_price_currency : "%"}`
                      : "N/A"}
                  </div>
                ))}
              </div>
            </div>
          )}
          {(agent_profile_photo_url ||
            subsalesEntryCreationProps["agent_full_name"] ||
            subsalesEntryCreationProps["agent_email"]) && (
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title">
                <AtlasIcon
                  svgHref={"atlas-profile-circle"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                Listing Agent
              </div>
              <div className="d-flex align-items-center">
                <img
                  src={agent_profile_photo_url}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
                <div className="ml-3">
                  <div className="at-form-input__title">
                    {subsalesEntryCreationProps.agent_full_name}
                  </div>
                  <div className="fs-2">
                    <AtlasIcon
                      svgHref={"atlas-profile2user"}
                      style={{
                        width: 16,
                        height: 16,
                        fill: "#F0631D",
                        marginRight: 10,
                      }}
                    />
                    {subsalesEntryCreationProps.agent_team}
                  </div>
                  <div className="fs-2">
                    <AtlasIcon
                      svgHref={"atlas-sms"}
                      style={{
                        width: 16,
                        height: 16,
                        fill: "#F0631D",
                        marginRight: 10,
                      }}
                    />
                    {subsalesEntryCreationProps.agent_email}
                  </div>
                  <div className="fs-2">
                    <AtlasIcon
                      svgHref={"atlas-call"}
                      style={{
                        width: 16,
                        height: 16,
                        fill: "#F0631D",
                        marginRight: 10,
                      }}
                    />
                    {subsalesEntryCreationProps.agent_mobile_number}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <CustomButton
                  className={
                    "btn-new btn-new--outline-secondary at-subsales-listing__view-agent_btn"
                  }
                  containerStyle={{ width: "32%" }}
                  onClick={() =>
                    window.open(
                      `mailto:${subsalesEntryCreationProps.agent_email}`,
                    )
                  }
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-sms-edit"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "#3B82F6",
                        }}
                      />
                      Email
                    </>
                  }
                />
                <CustomButton
                  className={
                    "btn-new btn-new--outline-secondary at-subsales-listing__view-agent_btn"
                  }
                  containerStyle={{ width: "32%" }}
                  onClick={() =>
                    window.open(
                      `https://wa.me/${subsalesEntryCreationProps.agent_mobile_number}`,
                      "_blank",
                    )
                  }
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-sms"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "#12B76A",
                        }}
                      />
                      Message
                    </>
                  }
                />
                <CustomButton
                  className={
                    "btn-new btn-new--outline-secondary at-subsales-listing__view-agent_btn"
                  }
                  containerStyle={{ width: "32%" }}
                  onClick={() =>
                    window.open(
                      `tel:${subsalesEntryCreationProps.agent_mobile_number}`,
                    )
                  }
                  children={
                    <>
                      <AtlasIcon
                        svgHref={"atlas-call-calling"}
                        style={{
                          width: 16,
                          height: 16,
                          fill: "#3B82F6",
                        }}
                      />
                      Call
                    </>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {(onLoadFavourite || onLoadSubsales || onLoadView || onLoadMediaLink) && (
        <LoadingModal />
      )}
      {renderStyling()}
    </>
  );
};

export default View;
