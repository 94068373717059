import { useEffect, useState } from "react";

import AtlasButton from "components/Button";
import CustomFormInput from "components/Input/formInput";
import ModalDialog from "components/Modal/ModalDialog";
import Condition from "../components/Condition";

import { user } from "dictionary";
import convertConditions from "utils/convertConditions";

const Update = ({
  onChangeEmailListsHOC,
  selectedEmailList,
  teamsList,
  countriesList,
  branchesList,
  getTeamsList,
  getCountriesList,
  getBranchesList,
  checkEmailList,
  updateEmailList,
}) => {
  const [name, setName] = useState("");
  const [conditions, setConditions] = useState([
    {
      id: 1,
      field: "",
      comparison: "",
      value: "",
      selected: [],
    },
  ]);

  useEffect(() => {
    getTeamsList();
    getCountriesList();
    getBranchesList();
  }, []);

  useEffect(() => {
    setName("");
    setConditions([
      {
        id: 1,
        field: "",
        comparison: "",
        value: "",
        selected: [],
      },
    ]);

    if (Object.keys(selectedEmailList).length > 0) {
      setName(selectedEmailList.name);
      setConditions(selectedEmailList.sql_conditions.map(revertConditions));
    }
  }, [selectedEmailList]);

  const handleClose = () => {
    onChangeEmailListsHOC("emailListDialog", "");
    setName("");
    setConditions([
      {
        id: 1,
        field: "",
        comparison: "",
        value: "",
        selected: [],
      },
    ]);
  };

  const addCondition = () => {
    setConditions([
      ...conditions,
      {
        id: conditions.length + 1,
        field: "",
        comparison: "",
        value: "",
        selected: [],
      },
    ]);
  };

  const removeCondition = (id) => () => {
    setConditions(conditions.filter((condition) => condition.id !== id));
  };

  const populateTypeahead = (arr, obj, val) => {
    if (val.includes(",")) {
      obj.selected = arr.filter((item) =>
        val.slice(1, -1).split(",").map(parseInt).includes(item.id),
      );
    } else {
      obj.selected = arr.filter((item) => parseInt(val) === item.id);
    }

    return obj;
  };

  const revertConditions = (condition, index) => {
    const field = condition.split(" ")[0];
    const comparison = condition.match(
      /(?:=|IS NOT NULL|IN|!=|IS NULL|NOT IN)/,
    )[0];
    const value = condition.slice(
      condition.indexOf(comparison) + comparison.length + 1,
    );

    const values = {
      id: index + 1,
      field,
      comparison,
      value: "",
      selected: [],
    };

    if (comparison.includes("NULL")) {
      return values;
    }

    if (field === "team_id") {
      return populateTypeahead(teamsList, values, value);
    } else if (field === "status_id") {
      const statusList = user.statuses.map((user) => ({
        id: user.value,
        name: user.label,
      }));
      return populateTypeahead(statusList, values, value);
    } else if (field === "country_id") {
      return populateTypeahead(countriesList, values, value);
    } else if (field === "branch_id") {
      return populateTypeahead(branchesList, values, value);
    } else {
      const trimEnds = (string) => string.slice(1, -1);

      const trimmedString = trimEnds(value);

      if (comparison.includes("IN") && value.includes(",")) {
        values.value = trimmedString.split(",").map(trimEnds).join(",");
      } else {
        values.value = trimmedString;
      }

      return values;
    }
  };

  const handleCheck = async () => {
    checkEmailList({
      task: "check",
      sql_conditions: await convertConditions(conditions),
    });
  };

  const handleSave = async () => {
    updateEmailList(selectedEmailList.id, {
      task: "save",
      name,
      sql_conditions: await convertConditions(conditions),
    });
  };

  return (
    <ModalDialog
      title={"Update Email List"}
      onClose={handleClose}
      footer={
        <div className={"d-flex g-2"}>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--primary"}
            onClick={handleCheck}
          >
            Check
          </AtlasButton>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--secondary"}
            onClick={handleSave}
            disabled={name === ""}
          >
            Save
          </AtlasButton>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--outline-secondary"}
            onClick={handleClose}
          >
            Cancel
          </AtlasButton>
        </div>
      }
    >
      <div className={"mb-3"}>
        <h2 className={"at-form-input__title text-capitalize"} required>
          List Name
        </h2>

        <CustomFormInput
          type={"text"}
          value={name}
          onChangeValue={setName}
          maxLength={120}
          required={true}
        />
      </div>

      <div className={"mb-3"}>
        <h2 className={"at-form-input__title text-capitalize"}>Conditions</h2>

        <p className={"mb-1"} style={{ fontSize: 14 }}>
          Add conditions to filter users for the list
        </p>

        {conditions &&
          conditions.map((condition) => (
            <Condition
              key={condition.id}
              condition={condition}
              conditions={conditions}
              setConditions={setConditions}
              teamsList={teamsList}
              countriesList={countriesList}
              branchesList={branchesList}
              removeCondition={removeCondition}
            />
          ))}
      </div>

      <AtlasButton
        type={"button"}
        className={"btn btn-link p-0"}
        onClick={addCondition}
      >
        &#43; Add more conditions to this group
      </AtlasButton>
    </ModalDialog>
  );
};

export default Update;
