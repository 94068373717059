import React from "react";
import Dialog from "@material-ui/core/Dialog";

import AtlasCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";

const ConfirmationModal = ({
  open,
  loading,
  message,
  title,
  positiveAction,
  negativeAction,
  positiveText,
  negativeText,
}) => {
  const renderCardContent = () => {
    return (
      <div className={"text-center"}>
        <div className="at-confirmation_modal-icon">
          <AtlasIcon
            svgHref={"atlas-danger"}
            style={{ fill: "#F04438", backgroundColor: "#FEE4E2" }}
          />
        </div>
        <br />
        <h4 className="fs-4 fw-600">{title ? title : "Are you sure?"}</h4>
        <p className={"fs-2 mt-10 mb-20"}>{message}</p>
      </div>
    );
  };
  const renderCardFooter = () => {
    return (
      <div className={`d-flex justify-content-center pt-4 g-3`}>
        <button
          className="btn-new btn-new--outline-secondary w-100"
          onClick={() => negativeAction()}
        >
          {`${negativeText ? negativeText : "Cancel"}`}
        </button>
        <button
          className="btn-new btn-new--danger w-100"
          onClick={() => positiveAction()}
        >
          {`${positiveText ? positiveText : "Delete"}`}
        </button>
      </div>
    );
  };
  return (
    <Dialog
      open={open}
      classes={{
        root: "at-confirmation_modal-responsive",
        paper: "at-confirmation_modal-paper",
      }}
      maxWidth={"sm"}
    >
      <AtlasCard
        className={"bg-white"}
        cardContent={renderCardContent()}
        cardFooter={renderCardFooter()}
      />
      {loading && <LoadingModal />}
    </Dialog>
  );
};

export default ConfirmationModal;
