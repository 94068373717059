import React, { Component } from "react";
import { compose } from "redux";
import { IoIosRefresh, IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import ShareModal from "components/Share";
import AlertBox from "components/AlertBox";
import VideoPlayer from "components/VideoPlayer";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import PaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import RejectConfirmationModal from "components/Modal/delete";
import CreateModal from "./Create";

import VideoCreatorHOC from "./action";
import SubsalesStatusHOC from "actions/subsalesStatus";
import { columnData, adminColumnData } from "./assets";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

import "./index.scss";

class VideoCreator extends Component {
  state = {
    adminView: false,
  };

  componentDidMount = () => {
    initGA("/dashboard/video-creator");
    this.props.getCountryDictionary();
    this.props.getCommRate();
    this.getVideoList();
  };

  getVideoList = (refresh) => {
    const { href } = window.location;
    const { lastView } = this.props.data.lastViewReducer;
    this.setState({ adminView: href.indexOf("/admin/") !== -1 }, () => {
      if (this.state.adminView) {
        refresh
          ? this.props.getAdminPersonalisedVideo(
              lastView.currentPage,
              lastView.searchParams
            )
          : this.props.getAdminPersonalisedVideo(1, "");
      } else {
        refresh
          ? this.props.getPersonalisedVideo(
              lastView.currentPage,
              lastView.searchParams
            )
          : this.props.getPersonalisedVideo(1, "");
        this.props.getVideoTemplate(1, "");
      }
    });
  };

  renderUpdateConfirmation = () => {
    return (
      <ConfirmationModal
        open={this.props.showUpdateConfirmation}
        message={"Are you sure you want to create a new personalised video ?"}
        title={" "}
        positiveAction={() => {
          Promise.all([
            this.props.onChangeVideoCreatorHOC(false, "showUpdateConfirmation"),
          ]).then(() => {
            this.props.createPersonalisedVideo(
              this.props.selectedVideo.video_template_id
            );
          });
        }}
        negativeAction={() =>
          this.props.onChangeVideoCreatorHOC(false, "showUpdateConfirmation")
        }
      />
    );
  };

  renderDeleteConfirmation = () => {
    return (
      <RejectConfirmationModal
        open={this.props.showDeleteConfirmation}
        message={"Are you sure to delete this video?"}
        title={"Delete Personalised Video"}
        positiveAction={() => {
          Promise.all([
            this.props.onChangeVideoCreatorHOC(false, "showDeleteConfirmation"),
          ]).then(() => {
            if (this.state.adminView)
              this.props.deleteAdminPersonalisedVideo(
                this.props.selectedVideo.id
              );
            else
              this.props.deletePersonalisedVideo(this.props.selectedVideo.id);
          });
        }}
        negativeAction={() =>
          this.props.onChangeVideoCreatorHOC(false, "showDeleteConfirmation")
        }
      />
    );
  };

  renderExtraContent = () => (
    <div className="d-flex align-items-center justify-content-between">
      {!this.state.adminView && (
        <div
          className="d-flex align-items-center fw-600 fs-3"
          style={{ color: "#111827" }}
        >
          <div
            className="at-badge-icon at-badge-icon-warning"
            style={{ width: 36, height: 36 }}
          >
            <AtlasIcon svgHref={"atlas-video-play"} />
          </div>
          Your Personalised Videos
        </div>
      )}
    </div>
  );

  renderCardContent = () => {
    const { can_update, can_destroy } = permissionsChecker(
      "Video Creator",
      this.props.data
    );

    return (
      <PaginatedTable
        rowData={this.props.personalisedVideo.data}
        meta={this.props.personalisedVideo.meta}
        columns={
          this.state.adminView
            ? adminColumnData(this.props)
            : columnData(this.props)
        }
        searchParams={
          this.state.adminView
            ? this.props.adminSearchParams
            : this.props.searchParams
        }
        onChangeSearchParams={(val) =>
          this.state.adminView
            ? this.props.onChangeVideoCreatorHOC(val, "adminSearchParams")
            : this.props.onChangeVideoCreatorHOC(val, "searchParams")
        }
        getListAPI={
          this.state.adminView
            ? this.props.getAdminPersonalisedVideo
            : this.props.getPersonalisedVideo
        }
        totalPages={this.props.personalisedVideoPage}
        // renderExtraContent={ this.renderExtraContent }
        actionColumnContent={
          can_update || can_destroy
            ? [
                {
                  name: "share",
                  disabled: (rowData) => rowData.status_id === 1,
                  onClick: (rowData) => {
                    Promise.all([
                      this.props.onChangeVideoCreatorHOC(
                        rowData,
                        "selectedVideo"
                      ),
                      this.props.getYoutubeLink(rowData.id),
                    ]).then(() => {
                      this.props.onChangeVideoCreatorHOC(
                        true,
                        "showShareModal"
                      );
                    });
                  },
                },
                {
                  name: "delete",
                  onClick: (rowData) => {
                    Promise.all([
                      this.props.onChangeVideoCreatorHOC(
                        rowData,
                        "selectedVideo"
                      ),
                    ]).then(() => {
                      this.props.onChangeVideoCreatorHOC(
                        true,
                        "showDeleteConfirmation"
                      );
                    });
                  },
                },
              ]
            : []
        }
      />
    );
  };

  render = () => {
    const { can_create } = permissionsChecker("Video Creator", this.props.data);
    const { role, status_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const status_permissions = status_id !== 2 || this.props.whitelisted;

    return (
      <>
        <ModuleHeader
          title={this.state.adminView ? "Personalised Videos" : "Video Creator"}
          atlasIcon={"atlas-video-play"}
          showPABadge={role === "Personal Assistant"}
          description={
            !this.state.adminView && (
              <>
                Create and share personalised videos of projects with your photo
                and contact details automatically added by Atlas. You can then
                share the videos in your messaging apps or social media
                marketing platforms to attract potential clients. <br />
                Click on the Add icon to start creating your video. <br />
                Once your video has been generated and the status shows ready,
                use the Share icon to download or share the video.
              </>
            )
          }
          renderAlertContent={
            status_id === 2 && (
              <AlertBox
                mode={"danger"}
                description={
                  "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
                }
              />
            )
          }
          actionButton={[
            {
              label: "New Video",
              onShow: !this.state.adminView && can_create && status_permissions,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeVideoCreatorHOC(true, "showCreateModal"),
            },
            {
              label: "Refresh",
              onShow: true,
              className: `btn-new btn-new--${this.state.adminView ? "primary" : "secondary"}`,
              icon: <IoIosRefresh style={{ height: 18, width: 18 }} />,
              onClick: () => this.getVideoList(true),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.showCreateModal && (
          <ModalDialog
            title={"New Video Request"}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeVideoCreatorHOC(false, "showCreateModal")
            }
            children={
              <CreateModal
                videoTemplate={this.props.videoTemplate}
                videoTemplatePage={this.props.videoTemplatePage}
                onVideoCreatorLoad={this.props.onVideoCreatorLoad}
                selectedProjectListVideo={this.props.selectedProjectListVideo}
                createPersonalisedVideo={this.props.createPersonalisedVideo}
                showCreateModal={this.props.showCreateModal}
                getVideoTemplate={this.props.getVideoTemplate}
                onChangeVideoCreatorHOC={this.props.onChangeVideoCreatorHOC}
                countries={this.props.data.dictionaryReducer.countries}
              />
            }
          />
        )}
        {this.props.showShareModal && (
          <ShareModal
            title={"Share Video"}
            video_template_id={this.props.selectedVideo.video_template_id}
            youtubeLink={this.props.youtubeLink}
            mediaURL={this.props.selectedVideo.url}
            shareLink={this.props.selectedVideo.shared_link}
            showElement={[
              "Whatsapp",
              "Telegram",
              "Facebook",
              "Download",
              "Copy Link",
              "Youtube",
            ]}
            downloadTooltip={"Download Video"}
            onClickDownload={() =>
              this.props.updateDownloadCount(this.props.selectedVideo.id)
            }
            onClickClose={() =>
              this.props.onChangeVideoCreatorHOC(false, "showShareModal")
            }
          />
        )}
        {this.props.showVideoPlayer && (
          <VideoPlayer
            videoURL={this.props.selectedVideo.url}
            onClickClose={() =>
              this.props.onChangeVideoCreatorHOC(false, "showVideoPlayer")
            }
          />
        )}
        {this.props.showDeleteConfirmation && this.renderDeleteConfirmation()}
        {this.props.showUpdateConfirmation && this.renderUpdateConfirmation()}
        {this.props.onVideoCreatorLoad && <LoadingModal />}
      </>
    );
  };
}

export default compose(VideoCreatorHOC, SubsalesStatusHOC)(VideoCreator);
