import React, { useEffect } from "react";
import { compose } from "redux";
import _ from "lodash";

import Tabs from "components/Tab";
import AtlasCloseButton from "components/Button/prev";

import DetailTab from "./tabs/Details";
import PhotosTab from "./tabs/Photo";
import FloorPlanTab from "./tabs/FloorPlan";
import JuwaiSubsTab from "./tabs/JuwaiSubs";
import MediaLink from "./tabs/MediaLink";
import RecommendationSettings from "./tabs/RecommendationSettings";

import SubsalesListingPreview from "../components/subsalesListingPreview";

import DetailsHOC from "../actions/detail";
import EditHOC from "../actions/edit";

import "../index.scss";
import "stylesheets/containers/personal/subsales.scss";

const sections = [
  "Details",
  "Photos",
  "Floor Plan",
  "Media Links",
  "Juwai Subscription",
  "Recommendation Settings",
];

const SubsalesType = [
  { id: 1, name: "Sale" },
  { id: 2, name: "Rental" },
];

const Edit = (props) => {
  useEffect(() => {
    props.onChangeEditHOC("Details", "selectedSection");
  }, []);

  useEffect(() => {
    const tmpSelectedSubsales = props.subsalesEntryCreationProps;
    let editSubsalesType = _.find(SubsalesType, {
      id: tmpSelectedSubsales.type_id,
    });
  }, [props.subsalesEntryCreationProps]);

  const renderCardContent = () => {
    if (props.selectedSection === "Details") {
      return <DetailTab {...props} />;
    } else if (props.selectedSection === "Photos") {
      return <PhotosTab {...props} />;
    } else if (props.selectedSection === "Floor Plan") {
      return <FloorPlanTab {...props} />;
    } else if (props.selectedSection === "Juwai Subscription") {
      return (
        <JuwaiSubsTab
          agent_juwai_account={
            props.subsalesEntryCreationProps.agent_juwai_account
          }
        />
      );
    }

    if (props.selectedSection === "Media Links") {
      return <MediaLink {...props} />;
    }

    if (props.selectedSection === "Recommendation Settings") {
      return <RecommendationSettings {...props} />;
    }
  };

  return (
    <>
      <Tabs
        sections={sections}
        selectedSection={props.selectedSection}
        onSelectSection={(val) =>
          props.onChangeSubsalesHOC(val, "selectedSection")
        }
      />
      {renderCardContent()}
      <SubsalesListingPreview {...props} />
    </>
  );
};

export default compose(EditHOC, DetailsHOC)(Edit);
