import React, { Component } from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  Button,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import _ from "lodash";
import { AiFillFilePdf } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import PaymentForm from "containers/iPay88";

import iPayLogo from "assets/ipay88/ipay88.png";
import iPayWebsiteBanner from "assets/ipay88/website_banner.jpg";

import WithPayments from "../action/Payment";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./selection.scss";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class SelectionPage extends Component {
  componentDidMount = () => {
    const { branch_id } = this.props.profileReducer;

    this.props.getPrice(1, branch_id);
  };

  renderPaymentContent = () => {
    const {
      tabContentType,
      paymentMethodId,
      profileReducer,
      payment_proof,
      uploadedPaymentUrl,
      employment_proof,
      employmentUrl,
      agent_via_recruitment_campaign,
      showPaymentGateway,

      onClickSubmitPaymentProof,
      onChangeHOC,
      onChangeFile,
    } = this.props;
    return (
      <>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: agent_via_recruitment_campaign
                ? "flex-start"
                : "center",
              marginBottom: 10,
            }}
          >
            {showPaymentGateway &&
              !agent_via_recruitment_campaign &&
              (paymentMethodId === 2 || paymentMethodId === 0) && (
                <button
                  style={{ marginLeft: 10 }}
                  className={`at-event__checkout-payment-option ${tabContentType === "Online Payment" ? "at-event__checkout-payment-option-selected" : ""}`}
                  onClick={() => {
                    onChangeHOC("Online Payment", "tabContentType");
                  }}
                >
                  Online Payment
                </button>
              )}
            {!agent_via_recruitment_campaign &&
              (paymentMethodId === 1 || paymentMethodId === 0) && (
                <button
                  style={{ marginLeft: 10 }}
                  className={`at-event__checkout-payment-option  ${tabContentType === "Bank in" ? "at-event__checkout-payment-option-selected" : ""}`}
                  onClick={() => {
                    onChangeHOC("Bank in", "tabContentType");
                  }}
                >
                  <FaRegMoneyBillAlt style={{ width: 50, height: 25 }} />
                  Bank in
                </button>
              )}
            {agent_via_recruitment_campaign && (
              <button
                style={{ marginLeft: 10 }}
                className={`at-btn ${tabContentType === "Recruitment Campaign" ? "at-btn--secondary" : ""}`}
                onClick={() => {
                  onChangeHOC("Recruitment Campaign", "tabContentType");
                }}
              >
                Recruitment Campaign
              </button>
            )}
          </div>
        </Grid>
        <Card style={{ width: "100%" }}>
          <CardContent>
            <Grid container spacing={16}>
              {showPaymentGateway && tabContentType === "Online Payment" && (
                <Grid item md={12} xs={12} className="payment-container">
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 300,
                      fontSize: 16,
                    }}
                  >
                    Instructions
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 300,
                      fontSize: 16,
                    }}
                  >
                    1. Click on your preferred payment method below.
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 300,
                      fontSize: 16,
                    }}
                  >
                    2. You will then be redirected to iPay88 to make a secure
                    payment.
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 300,
                      fontSize: 16,
                    }}
                  >
                    3. Please do not close the page until the transaction is
                    complete.
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 300,
                      fontSize: 16,
                    }}
                  >
                    4. You will be redirected back to this page upon completion
                    of the payment process.
                  </Typography>
                  <hr />
                  <img
                    src={iPayLogo}
                    className="ipay-logo"
                    style={{ width: "20%", minWidth: 100 }}
                  />
                  <div className="ipay-frame d-flex flex-wrap justify-content-center">
                    <FormHelperText className="my-4 w-100 text-center">
                      Choose a payment method. You will then be redirected to a
                      secure page to complete the payment.
                    </FormHelperText>
                    <PaymentForm
                      payment_id={2}
                      productDesc={"IQI agent registration."}
                      userInfo={profileReducer}
                      refNo={this.props.refNo}
                      amount={this.props.amountString}
                      amountSHA256={this.props.amountString.replace(".", "")}
                      remark={"Payment to IQI for agent registration."}
                    />
                    <PaymentForm
                      payment_id={16}
                      productDesc={"IQI agent registration."}
                      userInfo={profileReducer}
                      refNo={this.props.refNo}
                      amount={this.props.amountString}
                      amountSHA256={this.props.amountString.replace(".", "")}
                      remark={"Payment to IQI for agent registration."}
                    />
                    <PaymentForm
                      payment_id={210}
                      productDesc={"IQI agent registration."}
                      userInfo={profileReducer}
                      refNo={this.props.refNo}
                      amount={this.props.amountString}
                      amountSHA256={this.props.amountString.replace(".", "")}
                      remark={"Payment to IQI for agent registration."}
                    />
                    <PaymentForm
                      payment_id={523}
                      productDesc={"IQI agent registration."}
                      userInfo={profileReducer}
                      refNo={this.props.refNo}
                      amount={this.props.amountString}
                      amountSHA256={this.props.amountString.replace(".", "")}
                      remark={"Payment to IQI for agent registration."}
                    />
                    <PaymentForm
                      payment_id={538}
                      productDesc={"IQI agent registration."}
                      userInfo={profileReducer}
                      refNo={this.props.refNo}
                      amount={this.props.amountString}
                      amountSHA256={this.props.amountString.replace(".", "")}
                      remark={"Payment to IQI for agent registration."}
                    />
                    <PaymentForm
                      payment_id={542}
                      productDesc={"IQI agent registration."}
                      userInfo={profileReducer}
                      refNo={this.props.refNo}
                      amount={this.props.amountString}
                      amountSHA256={this.props.amountString.replace(".", "")}
                      remark={"Payment to IQI for agent registration."}
                    />
                    <PaymentForm
                      payment_id={801}
                      productDesc={"IQI agent registration."}
                      userInfo={profileReducer}
                      refNo={this.props.refNo}
                      amount={this.props.amountString}
                      amountSHA256={this.props.amountString.replace(".", "")}
                      remark={"Payment to IQI for agent registration."}
                    />
                  </div>
                  <img
                    src={iPayWebsiteBanner}
                    style={{ width: "100%", marginTop: 20 }}
                  />
                </Grid>
              )}
              {tabContentType === "Bank in" && uploadedPaymentUrl && (
                <Grid item md={3} xs={12}>
                  {uploadedPaymentUrl.indexOf(".pdf") === -1 && (
                    <Tooltip title="Click to view" placement="top">
                      <Card>
                        <img
                          src={uploadedPaymentUrl}
                          onClick={() =>
                            onChangeHOC(true, "showPaymentProofModal")
                          }
                          style={{ width: "100%", cursor: "pointer" }}
                        />
                      </Card>
                    </Tooltip>
                  )}
                  {uploadedPaymentUrl.indexOf(".pdf") > -1 && (
                    <Tooltip title="Click to view pdf" placement="top">
                      <AiFillFilePdf
                        style={{
                          width: 150,
                          height: 150,
                          color: "#f18e03",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(uploadedPaymentUrl, "_blank")
                        }
                      />
                    </Tooltip>
                  )}
                </Grid>
              )}
              {tabContentType === "Bank in" && (
                <Grid item md={5} xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel>Proof of payment</FormLabel>
                    <Typography
                      style={{
                        textTransform: "none",
                        fontWeight: 300,
                        fontSize: 14,
                        marginTop: 5,
                      }}
                    >
                      Please ensure that the transaction number, recipient's
                      name and transfer amount are visible in your proof of
                      payment.
                    </Typography>
                    <FilePond
                      ref={(ref) => (this.pond = ref)}
                      allowMultiple={false}
                      labelIdle={"Click here to upload the proof of payment"}
                      fullWidth
                      acceptedFileTypes={["image/*", "application/pdf"]}
                      maxFiles={1}
                      onupdatefiles={(fileItems) =>
                        onChangeFile(fileItems, "Bank In")
                      }
                    />
                    <FormHelperText>
                      Accepted file types: jpg, jpeg, png.( Required )
                    </FormHelperText>
                  </FormControl>
                  <div className={"d-flex justify-content-end mt-20"}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="medium"
                      disabled={_.isEmpty(payment_proof)}
                      onClick={() => {
                        onClickSubmitPaymentProof(this.props.amountString);
                        this.pond.removeFile();
                      }}
                    >
                      Upload
                    </Button>
                  </div>
                </Grid>
              )}
              {agent_via_recruitment_campaign &&
                tabContentType === "Recruitment Campaign" &&
                employmentUrl && (
                  <Grid item md={3} xs={12}>
                    {employmentUrl.indexOf(".pdf") === -1 && (
                      <Tooltip title="Click to view" placement="top">
                        <Card>
                          <img
                            src={employmentUrl}
                            onClick={() =>
                              onChangeHOC(true, "showPaymentProofModal")
                            }
                            style={{ width: "100%", cursor: "pointer" }}
                          />
                        </Card>
                      </Tooltip>
                    )}
                    {employmentUrl.indexOf(".pdf") > -1 && (
                      <Tooltip title="Click to view pdf" placement="top">
                        <AiFillFilePdf
                          style={{
                            width: 150,
                            height: 150,
                            color: "#f18e03",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(employmentUrl, "_blank")}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                )}
              {agent_via_recruitment_campaign &&
                tabContentType === "Recruitment Campaign" && (
                  <Grid item md={5} xs={12}>
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel>Recruitment Campaign</FormLabel>
                      <Typography
                        style={{
                          textTransform: "none",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5,
                        }}
                      >
                        Please ensure the employee name, employee number,
                        company name are visible in your proof of employment.
                      </Typography>
                      <FilePond
                        ref={(ref) => (this.pond = ref)}
                        allowMultiple={false}
                        fullWidth
                        acceptedFileTypes={["image/*", "application/pdf"]}
                        labelIdle={
                          "Click here to upload the proof of employment"
                        }
                        maxFiles={1}
                        onupdatefiles={(fileItems) =>
                          onChangeFile(fileItems, "Employment")
                        }
                      />
                      <FormHelperText>
                        Accepted file types: jpg, jpeg, png.( Required )
                      </FormHelperText>
                    </FormControl>
                    <div className={"d-flex justify-content-end mt-20"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        disabled={_.isEmpty(employment_proof)}
                        onClick={() => {
                          this.pond.removeFile();
                          onClickSubmitPaymentProof(
                            this.props.amountString,
                            "Employment"
                          );
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  </Grid>
                )}
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  render = () => {
    const {
      prefix_path,
      paymentMethod,
      paymentMethodId,
      profileReducer,
      history,
      agent_via_recruitment_campaign,
      currencyCode,
      amountString,
      bankName,
      bankOwnerName,
      bankAccountNumber,

      onChangeHOC,
      countryId,
    } = this.props;

    const tmpPath = window.location.href.includes("/admin-impersonate")
      ? prefix_path
      : "";

    return (
      <CardContent>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ textDecoration: "underlined", textTransform: "none" }}
            >
              You’re almost there! One more step to complete your registration.
            </Typography>
            {!agent_via_recruitment_campaign && (
              <>
                <Typography
                  style={{
                    textTransform: "none",
                    fontWeight: "300",
                    fontSize: 16,
                  }}
                >
                  {`There is an agent registration fee and you now need to make payment. The easiest option is to use your credit card/ 
                    online banking to make an online payment of ${currencyCode} ${amountString} to ${bankOwnerName} using bank account number ${bankAccountNumber} ( ${bankName} ). 
                    If you have transferred the money directly to the appropriate bank account, you can also upload proof of that and our team will manually check the payment status`}
                </Typography>
                <br />
                {countryId === 1 && (
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 300,
                      fontSize: 16,
                    }}
                  >
                    In the event, you fail, refuse and/or neglect to proceed
                    with the registration within the stipulated period herein or
                    such other period as may be determined by IQI, for
                    whatsoever reasons, you shall be liable for and shall pay
                    IQI administration fee of RM50.
                  </Typography>
                )}
              </>
            )}
            <br />
            <Typography
              style={{ textTransform: "none", fontWeight: "300", fontSize: 16 }}
            >
              Click to understand more about the{" "}
              <a
                onClick={() => onChangeHOC(true, "showPolicyRefundModal")}
                style={{ textDecoration: "underline" }}
              >
                refund policy
              </a>{" "}
              and{" "}
              <a
                onClick={() => onChangeHOC(true, "showPrivacyPolicyModal")}
                style={{ textDecoration: "underline" }}
              >
                privacy policy.
              </a>
            </Typography>
            <br />
            <Typography
              style={{ textTransform: "none", fontWeight: "300", fontSize: 16 }}
            >
              In the meantime, we have created a temporary account for you in
              IQI Atlas. You will receive your account details on your
              registered mobile device. You will now be able to access a limited
              set of features on IQI Atlas. Be sure to settle your payment
              within 24 hours of your registration to enjoy the complete IQI
              Atlas experience. Not doing so can result in account deletion.
            </Typography>
          </Grid>
          {paymentMethodId === 0 && this.renderPaymentContent()}
        </Grid>
      </CardContent>
    );
  };
}

export default WithPayments(SelectionPage);
