import React, { useMemo } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { MoonLoader } from "react-spinners";
import ReactHTMLParser from "react-html-parser";

import ModalDialog from "components/Modal/ModalDialog";
import CustomButton from "components/Button";

export default function UploadModalContent({
  title,
  uploadingFiles,

  onClose,
}) {
  let isUploadingInProgress = useMemo(() => {
    return uploadingFiles.some((item) => item.status === "loading");
  }, [uploadingFiles]);

  return (
    <ModalDialog
      title={title || "Uploading Files"}
      onClose={onClose}
      children={
        <>
          <ul className="my-4">
            {uploadingFiles.map((item, index) => (
              <li
                key={index}
                className={
                  "d-flex justify-content-between align-items-center px-3 py-2"
                }
                style={{
                  backgroundColor:
                    item.status === "loading" ? "#F3F4F6" : "white",
                  border: "1px solid #E5E5E5",
                  borderTop: index !== 0 ? "none" : "1px solid #E5E5E5",
                  borderBottom: "1px solid #E5E5E5",
                  borderTopLeftRadius: index === 0 ? 8 : 0,
                  borderTopRightRadius: index === 0 ? 8 : 0,
                  borderBottomLeftRadius:
                    index === uploadingFiles.length - 1 ? 8 : 0,
                  borderBottomRightRadius:
                    index === uploadingFiles.length - 1 ? 8 : 0,
                }}
              >
                <p className="fs-2">
                  <span style={{ color: "#1F2937" }}>
                    {item.image_file_name}
                  </span>
                  {item.status === "failed" && (
                    <i
                      className={"text-danger d-block"}
                      style={{ marginTop: 5 }}
                    >
                      {ReactHTMLParser(item.message)}
                    </i>
                  )}
                </p>
                {item.status === "loading" && (
                  <MoonLoader sizeunit={"px"} size={15} color={"#16232c"} />
                )}
                {item.status === "failed" && (
                  <AiFillCloseCircle style={{ width: 25, color: "#D93335" }} />
                )}
                {item.status === "success" && (
                  <AiFillCheckCircle style={{ width: 25, color: "#2ECC71" }} />
                )}
              </li>
            ))}
          </ul>
        </>
      }
      footer={
        <CustomButton
          className="btn-new btn-new--outline-secondary"
          children={"Close"}
          onClick={() => onClose()}
          disabled={isUploadingInProgress}
        />
      }
    />
  );
}
