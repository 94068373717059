import { useState } from "react";
import { Card } from "@material-ui/core";

import EmailEditor from "./EmailEditor";
import EmailPreview from "./EmailPreview";

const Design = ({
  handleClose,
  selectedEmailTemplate,
  toggleSetup,
  updateEmailTemplate,
}) => {
  const [showEmailPreview, setShowEmailPreview] = useState(true);

  const toggleEmailPreview = () => setShowEmailPreview(!showEmailPreview);

  return (
    <Card className={"at-email-templates__form__card"}>
      {showEmailPreview && (
        <EmailPreview
          handleClose={handleClose}
          selectedEmailTemplate={selectedEmailTemplate}
          toggleEmailPreview={toggleEmailPreview}
          toggleSetup={toggleSetup}
        />
      )}
      {!showEmailPreview && (
        <EmailEditor
          selectedEmailTemplate={selectedEmailTemplate}
          toggleEmailPreview={toggleEmailPreview}
          updateEmailTemplate={updateEmailTemplate}
        />
      )}
    </Card>
  );
};

export default Design;
