import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";
import DatePicker from "react-datepicker";
import {
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
  Card,
  FormHelperText,
} from "@material-ui/core";
import { IoMdDocument, IoIosClose, IoIosAlert, IoMdAdd } from "react-icons/io";

import AtlasSelect from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import ModuleFormHeader from "components/Form/header";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasAutosuggest from "components/Autosuggest";
import CustomTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";
import CustomFormInput from "components/Input/formInput";
import CustomFileInput from "components/Input/imageUpload";
import SearchContent from "./searchContent";
import SummaryContent from "./summary";
import RENCard from "./RENCard";

import checkValid from "utils/checkValid";
import { numberWithCommas } from "utils/thousandSeparator";
import EditHOC from "../actions/form";
import ProjectsHOC from "../actions/projects";
import { StatusColor } from "../assets";
import { getColorBadge } from "dictionary/badgeColor";
import { requestError } from "utils/requestHandler";

const FieldData = [
  {
    label: "Unit Number",
    value: "unit_number",
    type: "text",
  },
  {
    label: "Buyer",
    value: "buyer_name",
    type: "text",
  },
  {
    label: "Booking Date",
    value: "booking_date",
    type: "date",
  },
  {
    label: "Size",
    value: "size",
    type: "text",
  },
  {
    label: "SPA Price",
    value: "purchase_price",
    type: "number",
  },
  {
    label: "Net Price",
    value: "nett_price",
    type: "number",
  },
  {
    label: "Package",
    value: "package",
    type: "text",
    instruction: "Maximum of 155 character.",
    maxLength: 155,
  },
];

const typeData = [
  { label: "Main", value: "1" },
  { label: "Ad-hoc", value: "2" },
];

class SaleForms extends Component {
  componentDidMount = () => {
    const { selectedFormData, formData, formMode } = this.props;
    const { profileReducer, currentSignInProfileReducer } = this.props.data;
    this.props.getActiveProjects("0");
    this.props.getProjectLocations(`0`);
    let tempFormData = selectedFormData;

    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? currentSignInProfileReducer
      : profileReducer;

    if (formMode === "create") {
      let tempAgent = {
        user_id: tmpProfileReducer.id,
        agent_name: tmpProfileReducer.full_name,
        agent_email: tmpProfileReducer.email,
        agent_mobile_number: tmpProfileReducer.mobile_contact_number,
        agent_team: tmpProfileReducer.team_name,
        avatar_url: tmpProfileReducer.avatar_url,
        percentage: 0,
      };
      tempFormData = {
        ...formData,
        project_sale_agents_attributes: [tempAgent],
      };
    }
    this.props.onChangeEditHOC(tempFormData, "formData");
  };

  componentDidUpdate = (pp) => {
    const { selectedFormData } = this.props;

    if (
      pp.selectedFormData !== this.props.selectedFormData &&
      selectedFormData
    ) {
      this.props.onChangeEditHOC(selectedFormData, "formData");
    }
  };

  onHandleDeleteREN = (item) => {
    const { formData } = this.props;

    if (item.id) {
      let tempFormData = _.cloneDeep(formData);
      let agentIndex = _.findIndex(formData.project_sale_agents_attributes, {
        id: item.id,
      });
      tempFormData.project_sale_agents_attributes[agentIndex]._destroy = "1";
      tempFormData.project_sale_agents_attributes[agentIndex].percentage = 0;

      return this.props.onChangeEditHOC(tempFormData, "formData");
    }

    return this.props.onDeleteREN(item.user_id);
  };

  onChangeCountryField = (val) => {
    let temp = _.cloneDeep(this.props.formData);
    temp.country_id = val;
    temp.project_name = "";
    temp.project_location_id = null;

    this.props.onChangeEditHOC(temp, "formData");
    this.props.getActiveProjects(val, "1");
    this.props.getProjectLocations(val);
  };

  onChangeProjectLocation = (refId) => {
    let temp = _.cloneDeep(this.props.formData);
    temp.project_location_id = refId;
    temp.project_name = "";

    if (refId) {
      this.props.getActiveProjects(refId, "2");
      this.props.onChangeEditHOC(temp, "formData");
    }
  };

  renderDialogContent = () => {
    const {
      formData,
      formMode,
      showAssignModal,
      showSubmitConfirmation,
      showPDFModal,
      onLoadSales,
      projectListings,

      onChangeField,
      submitProjectSales,
      resubmitProjectSales,
    } = this.props;

    let tempNonDestroyAgent = _.filter(
      formData.project_sale_agents_attributes,
      (item) => item._destroy !== "1",
    );
    return (
      <>
        {showPDFModal && (
          <ModalDialog
            title={`View Document`}
            onClose={() => this.props.onChangeEditHOC(false, "showPDFModal")}
            children={
              <>
                {checkValid(formData.booking_form).indexOf("pdf") > -1 && (
                  <iframe
                    className={"w-100 h-100"}
                    src={formData.booking_form}
                  ></iframe>
                )}
                {checkValid(formData.booking_form).indexOf("pdf") === -1 && (
                  <img src={formData.booking_form} alt="booking form image" />
                )}
              </>
            }
          />
        )}
        {showSubmitConfirmation && (
          <ModalDialog
            title={
              formData.status_id === "4"
                ? "Resubmit project sale"
                : "New project sale"
            }
            fullWidth={true}
            onClose={() =>
              this.props.onChangeSalesHOC(false, "showSubmitConfirmation")
            }
            children={
              <SummaryContent
                formData={formData}
                isRejectedForm={formData.status_id === "4"}
                summaryMode={"create"}
                showLoading={onLoadSales}
                onClickSubmit={() => {
                  if (formData.status_id === "4") {
                    return resubmitProjectSales(
                      {
                        ...formData,
                        id: formData.id,
                      },
                      projectListings,
                    );
                  }

                  return submitProjectSales(
                    {
                      ...formData,
                      id: formMode === "create" ? 0 : formData.id,
                    },
                    projectListings,
                  );
                }}
                onClose={() =>
                  this.props.onChangeSalesHOC(false, "showSubmitConfirmation")
                }
              />
            }
          />
        )}
        {showAssignModal && (
          <ModalDialog
            title={`Add co-broke agent`}
            maxWidth={"lg"}
            onClose={() => this.props.onChangeEditHOC(false, "showAssignModal")}
            children={
              <SearchContent
                renInfo={tempNonDestroyAgent}
                onAddREN={(val) => {
                  onChangeField(
                    [...formData.project_sale_agents_attributes, val],
                    "project_sale_agents_attributes",
                  );
                  this.props.onChangeSalesHOC(true, "unsavedChanges");
                }}
                onClose={() =>
                  this.props.onChangeEditHOC(false, "showAssignModal")
                }
              />
            }
          />
        )}
      </>
    );
  };

  render = () => {
    const {
      formMode,
      unsavedChanges,
      projectListings,
      projectLocations,
      formData,
      onLoadActiveProjects,
      onLoadSales,

      onChangeField,
    } = this.props;
    const { countries = [] } = this.props.data.dictionaryReducer;
    let totalRENValue = 0;

    let tempNonDestroyAgent = _.filter(
      formData.project_sale_agents_attributes,
      (item) => item._destroy !== "1",
    );

    tempNonDestroyAgent.map((item) => {
      let intVal = item.percentage ? parseFloat(item.percentage) : 0;
      totalRENValue = totalRENValue + intVal;
    });
    const isDisabled =
      totalRENValue !== 100 ||
      _.keys(formData).some(
        (item) =>
          _.findIndex(
            [...FieldData, { value: "project_name" }],
            (field) => field.value === item && !formData[item],
          ) > -1,
      ) ||
      tempNonDestroyAgent.some(
        (item) => !item.percentage || item.percentage === "0",
      );

    const selectedProject = _.find(projectListings, {
      name: formData.project_name,
    });
    const { colorName = "" } =
      _.find(StatusColor, { value: formData.status }) || {};

    return (
      <>
        <div className="at-card__header">
          <p className="fs-2 mt-10">
            To start adding a new project sale that you've done, choose the type
            of project and then search for the project in the Project field. If
            its an ad-hoc project and you can’t find the project name, you can
            type it in if needed. Then, enter the additional information such as
            the unit number, size, buyers names, etc and upload a good quality
            image or pdf of the booking form (if you have it). If the deal is
            cobroked, add all the cobroke agents information by clicking the add
            button, enter the agents Atlas mobile search and add. Ensure that
            the total percentage equals to 100%. Only one of the agents needs to
            add a unit.
          </p>
        </div>
        <div className="at-form__content mt-20">
          <ModuleFormHeader
            title={"Sales Details"}
            className={"mb-3"}
            moduleIcon={"atlas-document-text"}
          />
          <div className="row">
            <div className="col-12">
              <h2 className="at-form-input__title">{"Project Type"}</h2>
              <AtlasRadioGroup
                horizontal={true}
                checkedValue={formData.type_id}
                selectedRadioValue={(val) => {
                  formData.status_id !== "4" && onChangeField(val, "type_id");
                }}
                options={typeData}
              />
            </div>
            {formMode === "edit" && (
              <>
                <div className="col-lg-6 col-12">
                  <h2 className="at-form-input__title">{"Status"}</h2>
                  <div
                    className="at-status_badge"
                    style={{ ...getColorBadge(colorName) }}
                  >
                    {formData.status}
                    {formData.status === "Draft" && (
                      <span className={"text-danger ml-2"}>
                        (not yet submitted)
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <h2 className="at-form-input__title">{"Assigned to"}</h2>
                  <i>{formData.assigned_admin}</i>
                </div>
              </>
            )}
            <div className="col-12">
              <hr className="m-0" />
            </div>
            <div className="col-lg-6">
              <h2 className="at-form-input__title">
                {"Filter the projects by country"}
              </h2>
              <AtlasSelect
                value={formData.country_id || ""}
                onChange={(e) => this.onChangeCountryField(e.target.value)}
              >
                {countries.map((item) => (
                  <MenuItem
                    classes={{ root: `at-select__dropdown-item` }}
                    key={item.reference_id}
                    value={item.reference_id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </AtlasSelect>
            </div>
            <div className="col-lg-6 mb-0" />
            <div className="col-lg-6">
              <h2 className="at-form-input__title">{"Project Location"}</h2>
              <CustomTypeahead
                filterBy={["name"]}
                typeaheadId={"project-list"}
                options={projectLocations || []}
                selectedValue={_.find(projectLocations, {reference_id: formData.project_location_id})
                  ? [_.find(projectLocations, {reference_id: formData.project_location_id})]
                  : []
                }
                labelKey={"name"}
                onSearch={(val) => {
                  let temp = _.cloneDeep(formData);
                  temp.project_location_id = null;
                  this.props.onChangeEditHOC(temp, "formData");
                }}
                onSelect={(val) => {
                  if (val && val.length > 0) {
                    return this.onChangeProjectLocation(val[0].reference_id);
                  }
                  return this.onChangeProjectLocation(null);
                }}
                childrenHead={(rowItem) => <p>{rowItem.name}</p>}
              />
            </div>
            <div className="col-lg-6">
              <h2 className="at-form-input__title" required>{"Project"}</h2>
              <AtlasAutosuggest
                required={true}
                disabled={formData.status_id === "4"}
                placeholder={"Select a project from the list"}
                value={formData.project_name || ""}
                updateValue={(val) => onChangeField(val, "project_name")}
                options={projectListings}
              />
            </div>
            {FieldData.map((item) => (
              <div className="col-lg-6" key={item.value}>
                <h2 className="at-form-input__title" required>
                  {item.label}
                </h2>
                {["text", "number"].indexOf(item.type) > -1 && (
                  <CustomFormInput
                    type={"text"}
                    numericOnly={item.type === "number"}
                    instruction={item.instruction ? item.instruction : ""}
                    maxLength={item.maxLength ? item.maxLength : null}
                    disabled={
                      item.value === "unit_number" && formData.status_id === "4"
                    }
                    value={formData[item.value]}
                    onChangeValue={(val) => {
                      let temp =
                        item.type === "number"
                          ? numberWithCommas(`${val.replace(/[^$0-9.]/g, "")}`)
                          : val;

                      onChangeField(temp, item.value);
                    }}
                    required={true}
                    placeholder={item.placeholder}
                  />
                )}
                {item.type === "date" && (
                  <div className="at-form-input">
                    <AtlasIcon
                      className={"at-form-input__calendar"}
                      svgHref={"atlas-calendar-input"}
                    />
                    <DatePicker
                      dateFormat="DD MMM YYYY"
                      className="at-form-input__input"
                      maxDate={Moment()}
                      value={formData.booking_date}
                      onChange={(val) =>
                        onChangeField(
                          Moment(val).format("DD MMM YYYY"),
                          "booking_date",
                        )
                      }
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="col-lg-6">
              <h2 className="at-form-input__title">
                Confirmation Form Upload
              </h2>
              {formData.booking_form && (
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "grey",
                    cursor: "pointer",
                    marginTop: 10,
                    borderRadius: 5,
                    width: 130,
                  }}
                >
                  <Tooltip title={"Click to view uploaded document."}>
                    <IoMdDocument
                      onClick={() =>
                        this.props.onChangeEditHOC(true, "showPDFModal")
                      }
                    />
                  </Tooltip>
                  <IconButton
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: 0,
                    }}
                    onClick={() => {
                      this.props.onChangeFile(null);
                    }}
                  >
                    <IoIosClose
                      style={{ width: 25, height: 25, fill: "white" }}
                    />
                  </IconButton>
                </div>
              )}
              {!formData.booking_form && (
                <>
                  <CustomFileInput
                    fullWidth
                    ref={(ref) => (this.pond = ref)}
                    maxFiles={1}
                    files={[]}
                    required={selectedProject?.is_booking_form_required}
                    allowMultiple={false}
                    acceptedFileTypes={["image/png, image/jpeg, image/jpg, application/pdf"]}
                    accept={"image/png, image/jpeg, image/jpg, application/pdf"}
                    onRemoveFile={() => this.props.onChangeFile([])}
                    onChangeFile={(fileItems) =>
                      this.props.onChangeFile(fileItems)
                    }
                  />
                  <FormHelperText>
                    Supported file types: jpg, png and pdf
                  </FormHelperText>
                </>
              )}
            </div>
            <div className="col-12">
              <h2 className="at-form-input__title">Remark</h2>
              <CustomTextarea
                rows="10"
                value={formData.remark}
                placeholder={"Please enter any additional information."}
                onChangeValue={(val) => onChangeField(val, "remark")}
              />
            </div>
          </div>
        </div>
        <div className="at-form__content mt-20 mb-20">
          <div className="d-flex mb-3">
            <ModuleFormHeader
              title={"Agent Information"}
              moduleIcon={"atlas-user-tie"}
            />
            {tempNonDestroyAgent.length < 6 && (
              <button
                className="btn-new btn-new--primary ml-2"
                onClick={() =>
                  this.props.onChangeEditHOC(true, "showAssignModal")
                }
              >
                <IoMdAdd
                  style={{
                    width: 18,
                    height: 18,
                    fill: "#FFFF",
                    marginRight: 10,
                  }}
                />
                Add Agent
              </button>
            )}
          </div>
          <div className="row">
            <div className="col-12" style={{ marginBottom: 5 }}>
              <p style={{ color: totalRENValue !== 100 ? "#FB404B" : "#000" }}>
                {`Total percentage: ${totalRENValue}% out of total 100%`}
                {totalRENValue !== 100 && (
                  <Tooltip
                    title={
                      "Please ensure the total percentage to be 100% before submitting."
                    }
                  >
                    <IoIosAlert
                      style={{
                        width: 19,
                        color: "#FB404B",
                        marginLeft: 10,
                        display: "none",
                      }}
                      className={"d-md-inline-block"}
                    />
                  </Tooltip>
                )}
              </p>
            </div>
            {tempNonDestroyAgent.length < 1 && (
              <div className="col-lg-4 col-md-6 col-xs-12">
                <Card
                  classes={{ root: "at-assigned-card at-assigned-empty-card" }}
                >
                  <Typography variant="h6" gutterBottom>
                    {"No agent has been added yet."}
                  </Typography>
                </Card>
              </div>
            )}
            {tempNonDestroyAgent.length > 0 &&
              tempNonDestroyAgent.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-xs-12">
                  <RENCard
                    mode={"edit"}
                    index={index}
                    item={item}
                    onDeleteREN={(item) => this.onHandleDeleteREN(item)}
                    onChangeRENPercentage={this.props.onChangeRENPercentage}
                  />
                </div>
              ))}
          </div>
        </div>
        {formMode === "edit" && unsavedChanges && (
          <strong className={"mt-10 d-block"}>
            There's an unsaved changes. Please save the changes.
          </strong>
        )}
        <div className="at-modal_dialog-container-footer">
          {formMode === "edit" && formData.status_id !== "4" && (
            <button
              disabled={
                formData.project_name.length < 1 ||
                !formData.project_location_id
              }
              className={`btn-new btn-new--success mr-10`}
              onClick={() =>
                this.props.onClickSubmit(formData, projectListings)
              }
            >
              {"Update"}
            </button>
          )}
          <button
            disabled={isDisabled}
            className={`btn-new btn-new--secondary mr-10`}
            onClick={() => {
              if (selectedProject?.is_booking_form_required && !formData.booking_form) {
                requestError("Confirmation form is required.")
              } else {
                this.props.onChangeSalesHOC(true, "showSubmitConfirmation")
              }
            }}
          >
            {formData.status_id === "4"
              ? "Continue to resubmit"
              : "Continue to submit"}
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onClose()}
          >
            {formMode === "edit" ? "Close" : "Cancel"}
          </button>
        </div>
        {this.renderDialogContent()}
        {(onLoadSales || onLoadActiveProjects) && <LoadingModal />}
      </>
    );
  };
}

export default compose(EditHOC, ProjectsHOC)(SaleForms);
