import React from "react";
import { Grid } from "@material-ui/core";
import DatePicker from "react-datepicker";
import Moment from "moment";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";

import isEmptyValue from "utils/isEmpty";

const updateInputLibrary = [
  {
    id: 0,
    label: "Pricing Title",
    value: "title",
    type: "text",
  },
  {
    id: 1,
    label: "Price",
    value: "price",
    type: "number",
  },
  {
    id: 2,
    label: "Start Date",
    value: "start_date",
    type: "date",
  },
  {
    id: 3,
    label: "End Date",
    value: "end_date",
    type: "date",
  },
  {
    id: 4,
    label: "Allow to joint tickets",
    value: "allowed_join_ticket",
    value_type: "boolean",
    type: "radio",
    options: [
      {
        id: 0,
        label: "Yes",
        value: true,
      },
      {
        id: 1,
        label: "No",
        value: false,
      },
    ],
  },
];

const UpdateTicketPricing = ({
  onLoad,
  selectedTicketPricing = {},

  onClose,
  onChange,
  updateTicketPricing,
}) => {
  const onChangeField = (value, context) => {
    let temp = _.cloneDeep(selectedTicketPricing);
    temp[context] = value;
    onChange(temp, "selectedTicketPricing");
  };

  return (
    <ModalDialog
      title={"Edit Ticket Pricing"}
      onLoad={onLoad}
      onClose={onClose}
      children={
        <>
          {updateInputLibrary.map((item) => (
            <Grid key={item.id} container spacing={16} className={"mb-3"}>
              {item.type === "radio" && (
                <Grid item md={7} xs={12}>
                  <h4 className="at-form-input__title">{item.label}</h4>
                  <AtlasRadioGroup
                    horizontal={true}
                    checkedValue={selectedTicketPricing[item.value]}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => onChangeField(val, item.value)}
                    options={item.options}
                  />
                </Grid>
              )}
              {["text", "number"].indexOf(item.type) > -1 && (
                <Grid item md={7} xs={12}>
                  <h4 className="at-form-input__title">{item.label}</h4>
                  <CustomFormInput
                    type={item.type}
                    required={true}
                    value={selectedTicketPricing[item.value]}
                    onChangeValue={(val) => onChangeField(val, item.value)}
                  />
                </Grid>
              )}
              {item.type === "date" && (
                <Grid item md={7} xs={12}>
                  <h2 className="at-form-input__title">{item.label}</h2>
                  <div className="at-form-input">
                    <AtlasIcon
                      className={"at-form-input__calendar"}
                      svgHref={"atlas-calendar2"}
                    />
                    <DatePicker
                      className="at-form-input__input"
                      value={selectedTicketPricing[item.value]}
                      onChange={(value) =>
                        onChangeField(
                          Moment(value).format("DD MMM YYYY"),
                          item.value,
                        )
                      }
                      placeholderText={"DD MMM YYYY"}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          ))}
          <Grid container md={12} xs={12}>
            <CustomButton
              containerStyle={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
              onClick={() => updateTicketPricing(selectedTicketPricing.id)}
              isIconButton={false}
              disabled={updateInputLibrary.some((item) =>
                isEmptyValue(selectedTicketPricing[item.value]),
              )}
              className={"d-flex btn-new btn-new--success"}
              children={"Update"}
              tooltip={false}
              tooltipChildren={"Update"}
            />
            <CustomButton
              containerStyle={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
              onClick={onClose}
              isIconButton={false}
              className={"d-flex btn-new btn-new--outline-secondary ml-2"}
              children={"Close"}
              tooltip={false}
              tooltipChildren={"Cancel"}
            />
          </Grid>
        </>
      }
    />
  );
};

export default UpdateTicketPricing;
