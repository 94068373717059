import React, { useCallback } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import AtlasMultiSelect from "components/Select/multi";
import CustomRadioGroup from "components/RadioGroup";
import SubsalesTooltip from "../../../../components/tooltip";
import "../../../../index.scss";

const subsaleTypeOptions = [
  {
    value: 1,
    label: "Sale",
  },
  {
    value: 2,
    label: "Rental",
  },
];

const BasicInfo = ({
  selectedCategory,
  selectedListingCategory,
  tmpPropertyTypes,
  selectedPropertyType,
  type_id,
  unit_types,
  selectedUnitType,
  code,
  tenure_types,
  selectedTenureType,

  title_types,
  selectedTitleType,
  furnishing_statuses,
  selectedFurnishingStatus,
  occupancies,
  selectedOccupancies,

  onChangeValue,
  categories,
}) => {
  return (
    <div className="grid-control mt-10">
      <section className="grid-full-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title">Code: {code}</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "code",
            }}
          />
        </div>
      </section>
      <section className="grid-full-col">
        <h2 className="at-form-input__title">Type</h2>
        <CustomRadioGroup
          checkedValue={type_id ? type_id : ""}
          options={subsaleTypeOptions}
          selectedRadioValue={(val) => onChangeValue("Type", val)}
        />
      </section>
      <section className="grid-full-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title">Listing Category</h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "listingCategory",
            }}
          />
        </div>
        <CustomRadioGroup
          checkedValue={selectedCategory ? selectedCategory.id : ""}
          options={categories}
          labelKey={"name"}
          selectedRadioValue={(val) => selectedListingCategory(val)}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>
          Property Type
        </h2>
        <AtlasMultiSelect
          required={true}
          selectType={"section"}
          className="mb-30 w-100"
          selectItems={tmpPropertyTypes ? tmpPropertyTypes : []}
          updateSelect={(val) => onChangeValue("Property Types", val)}
          currentlySelected={selectedPropertyType}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            Unit Type
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "unitType",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          labelKey={"name"}
          valueKey={"name"}
          selectItems={unit_types}
          currentlySelected={selectedUnitType}
          updateSelect={(val) => onChangeValue("Unit Type", val)}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            Tenure
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showEdgeTooltip: true,
              toolTip: "tenure",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={tenure_types}
          currentlySelected={selectedTenureType}
          updateSelect={(val) => onChangeValue("Tenure", val)}
        />
      </section>
      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>
          Title Type
        </h2>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={title_types}
          currentlySelected={selectedTitleType}
          updateSelect={(val) => onChangeValue("Title Type", val)}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            Furnishing
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              showEdgeTooltip: true,
              toolTip: "furnishing",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={furnishing_statuses}
          currentlySelected={selectedFurnishingStatus}
          updateSelect={(val) => onChangeValue("Furnishing Status", val)}
        />
      </section>
      <section className="grid-half-col">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2 className="at-form-input__title" required>
            Occupancies
          </h2>
          <SubsalesTooltip
            data={{
              showToolTip: true,
              toolTip: "occupancies",
            }}
          />
        </div>
        <CustomSelect
          required={true}
          className="mb-30 w-100"
          selectItems={occupancies}
          currentlySelected={selectedOccupancies}
          updateSelect={(val) => onChangeValue("Occupancies", val)}
        />
      </section>
    </div>
  );
};

export default BasicInfo;
