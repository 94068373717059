import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment/moment";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";

import AlertBox from "components/AlertBox";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomTextarea from "components/Input/textarea";
import CustomFormInput from "components/Input/formInput";
import CustomDatepicker from "components/Input/datetimepicker";
import CustomCheckbox from "components/Checkbox";
import ModalDialog from "components/Modal/ModalDialog";
import TermsAndConditions from "./components/TermsAndConditions";

const durationOptions = [
  { label: "Mins", value: 30 },
  { label: "Mins", value: 60 },
  { label: "Mins", value: 90 },
  { label: "Mins Max", value: 120 },
];

const isWeekend = (calendarDate) => {
  return calendarDate.day() === 6 || calendarDate.day() === 0;
};

class Create extends Component {
  state = {
    calendarDate: ""
  };

  componentDidMount = () => {
    const { date, selectedRoom, getCalendarEvent, onChangeMeetingHOC } = this.props;

    let searchDate = Moment(date);
    getCalendarEvent(selectedRoom.id, searchDate.format("YYYY-MM-DD"));
    let temp = _.cloneDeep(this.props.newBooking);
    temp["meeting_room_id"] = selectedRoom.id;
    onChangeMeetingHOC(temp, "newBooking");
  };

  componentDidUpdate = (pp) => {
    const {
      event_subject,
      start_time,
      end_time,
      is_have_read_terms_and_conditions,
    } = this.props.newBooking;
    if (
      event_subject !== "" &&
      start_time &&
      end_time &&
      is_have_read_terms_and_conditions
    ) {
      this.props.onChangeMeetingHOC(false, "disableBook");
    } else {
      this.props.onChangeMeetingHOC(true, "disableBook");
    }
  };

  render = () => {
    const {
      event_subject,
      purpose,
      end_time,
      duration,
      remark,
      add_ons,
      is_have_read_terms_and_conditions,
    } = this.props.newBooking;
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <div className="row px-3">
          <div className="col-md-6 p-0 px-md-2">
            <div className="at-card-booking-form_room_name">
              {" "}
              {this.props.selectedRoom.title}{" "}
            </div>
            <div className="at-card-booking-form_room_detail">
              <div className="d-flex aling-items-center g-3">
                <div className="at-card--meeting__detail">
                  <AtlasIcon
                    svgHref={"atlas-location"}
                    style={{ marginRight: 2 }}
                  />
                  {this.props.selectedRoom.location}
                </div>
                <div className="at-card--meeting__detail">
                  <AtlasIcon
                    svgHref={"atlas-user"}
                    style={{ marginRight: 2 }}
                  />
                  {this.props.selectedRoom.capacity} seats
                </div>
              </div>
              <div className="at-card--meeting__detail">
                <AtlasIcon
                  svgHref={"atlas-clock"}
                  style={{ fill: "#3B82F6" }}
                />
                {this.props.selectedRoom.operating_start_time} -{" "}
                {this.props.selectedRoom.operating_end_time}
              </div>
            </div>
            {this.props.selectedRoom?.meeting_room_type_id === 2 && (
              <AlertBox
                mode={"info"}
                disabledClose={true}
                containerClassName={"mb-3"}
                description={
                  "Kindly ensure that bookings must be arranged with a minimum of 2 weeks in advance of the desired date to ensure availability."
                } />
            )}
            <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
              <section key="edit-booking-name" className="grid-full-col">
                <h2 className="at-form-input__title" required>
                  Event Subject
                </h2>
                <CustomFormInput
                  type="text"
                  className={"mt-2"}
                  value={event_subject}
                  onChangeValue={(val) => {
                    let temp = _.cloneDeep(this.props.newBooking);
                    temp["event_subject"] = val;
                    this.props.onChangeMeetingHOC(temp, "newBooking");
                  }}
                  placeholder="Event Subject"
                />
                <h2 className="mt-3 at-form-input__title" required>
                  Event Purpose
                </h2>
                <CustomFormInput
                  type="text"
                  className={"mt-2"}
                  value={purpose}
                  onChangeValue={(val) => {
                    let temp = _.cloneDeep(this.props.newBooking);
                    temp["purpose"] = val;
                    this.props.onChangeMeetingHOC(temp, "newBooking");
                  }}
                  placeholder="Event Purpose"
                />
              </section>
              <section key="edit-booking-start-time" className="grid-half-col">
                <h2 className="at-form-input__title" required>
                  Start Date Time
                </h2>
                <div className="at-form-input">
                  <CustomDatepicker
                    id={"start_date_time"}
                    type="datetime-local"
                    value={this.state.calendarDate}
                    shouldDisableTime={(date) => {
                      if (this.props.selectedRoom) {
                        const operatingStartTime = Moment(date).set({
                          hour: Moment(
                            this.props.selectedRoom.operating_start_time,
                            "hh:mm a",
                          ).format("HH"),
                          minute: Moment(
                            this.props.selectedRoom.operating_start_time,
                            "hh:mm a",
                          ).format("mm"),
                          second: 0,
                        });
                        const operatingEndTime = Moment(date)
                          .set({
                            hour: Moment(
                              this.props.selectedRoom.operating_end_time,
                              "hh:mm a",
                            ).format("HH"),
                            minute: Moment(
                              this.props.selectedRoom.operating_end_time,
                              "hh:mm a",
                            ).format("mm"),
                            second: 0,
                          })
                          .subtract(30, "minutes");
                        return (
                          Moment(date) < operatingStartTime ||
                          Moment(date) > operatingEndTime
                        );
                      }
                      return (
                        Moment(date) <
                          Moment(date).set({ hour: 9, minute: 0, second: 0 }) ||
                        Moment(date) >
                          Moment(date).set({ hour: 20, minute: 0, second: 0 })
                      );
                    }}
                    minDateTime={this.props.selectedRoom?.meeting_room_type_id === 2
                      ? new Date(Moment().add(14, "days").startOf('days'))
                      : (new Date() > new Date(Moment(this.props.selectedRoom.operating_start_time,"hh:mm a"))
                        ? Moment()
                        : new Date(Moment(this.props.selectedRoom.operating_start_time,"hh:mm a")))
                    }
                    maxDateTime={this.props.selectedRoom?.meeting_room_type_id === 1
                      ? new Date(Moment().add(7, "days"))
                      : new Date(Moment().add(60, "days").startOf('days'))}
                    minutesStep={30}
                    onChange={(val) => {
                      let temp = _.cloneDeep(this.props.newBooking);
                      temp["end_time"] = "";
                      temp["duration"] = 0;
                      if (Moment(val).isValid()) {
                        temp["start_time"] = val;
                        this.props.getCalendarEvent(
                          this.props.selectedRoom.id,
                          Moment(val).format("YYYY-MM-DD"),
                        );
                        this.setState({ calendarDate: val });
                      } else {
                        this.setState({ calendarDate: "" });
                      }

                      if (val && isWeekend(val) && this.props.selectedRoom?.add_ons?.length > 0 ) {
                        this.props.selectedRoom?.add_ons?.map((addon, index) => {
                          if (addon.mandatory_for_weekend) {
                            if (!temp.add_ons.some((item) => item.add_on_id === addon.id)) {
                              temp.add_ons.push({
                                add_on_id: addon.id,
                                price: addon.price,
                              });
                            }
                          }
                        })    
                      } else if (this.props.selectedRoom?.add_ons?.length > 0) {
                        const weekendAddOnIds = new Set(this.props.selectedRoom.add_ons
                          .filter((addon) => addon.mandatory_for_weekend)
                          .map((addon) => addon.id)
                        )
                        temp.add_ons = temp.add_ons.filter((item) => !weekendAddOnIds.has(item.add_on_id))
                      }
                      this.props.onChangeMeetingHOC(temp, "newBooking");
                      this.props.onChangeMeetingHOC(true, "disableBook");
                    }}
                  />
                </div>
              </section>
              <section key="edit-booking-end-time" className="grid-half-col">
                <h2 className="at-form-input__title">End Date Time</h2>
                <div className={`at-form-input ${this.props.selectedRoom?.meeting_room_type_id === 1 ? 'at-form-input__disabled' : ''}`}>
                  <CustomDatepicker
                    id={"end_date_time"}
                    type="datetime-local"
                    textFieldClass={
                      `at-custom-datetimepicker-root at-form-input 
                      ${this.props.selectedRoom?.meeting_room_type_id === 1 ? 'disabled' : ''}`
                    }
                    value={end_time}
                    disabled={this.props.selectedRoom?.meeting_room_type_id === 1}
                    minDateTime={this.state.calendarDate || new Date()}
                    maxDateTime={this.state.calendarDate ? Moment(this.state.calendarDate).endOf('days') : new Date()}
                    shouldDisableTime={(date) => {
                      if (this.props.selectedRoom?.meeting_room_type_id === 2) {
                        const endTime = Moment(date).set({
                          hour: Moment(
                            this.props.selectedRoom.operating_end_time,
                            "hh:mm a",
                          ).format("HH"),
                          minute: Moment(
                            this.props.selectedRoom.operating_end_time,
                            "hh:mm a",
                          ).format("mm"),
                          second: 0,
                        });
                        return Moment(date) > endTime
                      }
                    }}
                    onChange={(val) => {
                      let temp = _.cloneDeep(this.props.newBooking);
                      if (Moment(val).isValid()) {
                        temp["end_time"] = val;
                        temp["duration"] = Moment(val).diff(
                          Moment(this.state.calendarDate),
                          "minutes",
                        );
                      } else {
                        temp["end_time"] = "";
                        temp["duration"] = 0;
                      }
                      this.props.onChangeMeetingHOC(temp, "newBooking");
                    }}
                  />
                </div>
              </section>
              {this.props.selectedRoom?.meeting_room_type_id === 1 && (
                <>
                  <section key="edit-booking-duration" className="grid-full-col">
                    <h2 className="at-form-input__title" required>
                      Meeting Duration
                    </h2>
                    <div className={"d-flex flex-wrap g-2"}>
                      {durationOptions.map((option, optionIndex) => {
                        let withinDuration = true;
                        if (this.state.calendarDate !== "") {
                          let meetingEndTime = Moment(this.state.calendarDate).set({
                            hour: Moment(
                              this.props.selectedRoom.operating_end_time,
                              "hh:mm a",
                            ).format("HH"),
                            minute: Moment(
                              this.props.selectedRoom.operating_end_time,
                              "hh:mm a",
                            ).format("mm"),
                            second: 0,
                          });
                          if (
                            Moment(this.state.calendarDate)
                              .add(option.value, "minutes")
                              .isSameOrBefore(meetingEndTime)
                          ) {
                            withinDuration = false;
                          }
                        }
                        return (
                          <button
                            key={`paginated_table-radio_option-${optionIndex}`}
                            className={`btn-radio ${duration === option.value ? "btn-radio-selected" : "btn-radio-outline"} `}
                            style={{
                              textAlign: "center",
                              padding: "0px 12px",
                              width: 98,
                              height: 68,
                            }}
                            disabled={withinDuration}
                            onClick={() => {
                              let temp = _.cloneDeep(this.props.newBooking);
                              temp["end_time"] = Moment(
                                this.state.calendarDate,
                              ).add(option.value, "minutes");
                              temp["duration"] = option.value;
                              this.props.onChangeMeetingHOC(temp, "newBooking");
                            }}
                          >
                            <div>
                              <div className="at-card-booking-form_duration_value">
                                {option.value}
                              </div>
                              <div className="at-card-booking-form_duration_label">
                                {option.label}
                              </div>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </section>
                </>
              )}
              {this.props.selectedRoom?.meeting_room_type_id === 2 && (
                <>
                  {['Admin', 'Super Admin'].includes(role) && (
                    <section className="grid-full-col">
                      <h2 className="at-form-input__title">Admin Remarks</h2>
                      <CustomTextarea
                        rows="3"
                        type="text"
                        value={remark}
                        placeholder={'Remarks'}
                        onChangeValue={val => {
                          let temp = _.cloneDeep(this.props.newBooking);
                          temp.remark = val;
                          this.props.onChangeMeetingHOC(temp, "newBooking");
                        }}
                        style={{ width: "100%", display: "block" }}
                      />
                    </section>
                  )}
                  <section className="grid-full-col">
                    <div className="at-card-booking-form_pricing">
                      <h2 className="at-card-booking-form_room_name mb-1">Pricing</h2>
                      {(this.props.selectedRoom?.business_lounge_pricings && this.props.selectedRoom?.business_lounge_pricings.length > 0) && this.props.selectedRoom?.business_lounge_pricings.map((item, index) => (
                        <div key={index}>
                          <div className="d-flex align-items-center g-2">
                            <AtlasIcon svgHref={"atlas-money"} />
                            <label>{item.name}</label>
                          </div>
                          <span style={{minWidth: '30%'}}>RM {item.price}</span>
                        </div>
                      ))}
                      <div>
                        <label className="w-100 text-right">Price Amount</label>
                        <span style={{minWidth: '30%'}} className="fw-700">RM {this.props.selectedRoom.business_lounge_pricings?.reduce((acc, item) => {
                          return acc + item.price
                        }, 0)}</span>
                      </div>
                      {this.props.selectedRoom?.add_ons?.length > 0 && (
                        <>
                          <h2 className="at-card-booking-form_room_name mt-3 mb-1">Add On</h2>
                          {this.props.selectedRoom?.add_ons?.map((addon, index) => (
                            <div>
                              <CustomCheckbox
                                content={
                                  <>
                                    <label className="w-100">{addon.name}</label>
                                    {addon.description && <span>{addon.description}</span>}
                                  </>
                                }
                                disabled={addon.mandatory_for_weekend}
                                checked={add_ons?.some((item) => item.add_on_id === addon.id)}
                                onChangeCheckboxValue={(e) => {
                                  let temp = _.cloneDeep(this.props.newBooking);
                                  if (e.target.checked) {
                                    temp.add_ons.push({
                                      add_on_id: addon.id,
                                      price: addon.price,
                                      quantity: 1,
                                    });
                                  } else {
                                    temp.add_ons = temp.add_ons.filter((item) => item.add_on_id !== addon.id);
                                  }
                                  this.props.onChangeMeetingHOC(temp, "newBooking");
                                }}
                              />
                              <span className="d-flex align-items-center g-2" style={{minWidth: '30%'}}>
                                RM {addon.price}
                                {addon.quantity_required && (
                                  <CustomFormInput
                                    type="number"
                                    className={"mt-2"}
                                    containerStyle={{ width: 100 }}
                                    value={add_ons?.find((item) => item.add_on_id === addon.id)?.quantity}
                                    placeholder="Quantity"
                                    onChangeValue={(val) => {
                                      if (val <= this.props.selectedRoom.capacity) {
                                        let temp = _.cloneDeep(this.props.newBooking);
                                        temp.add_ons = temp.add_ons.map((item) => {
                                          if (item.add_on_id === addon.id) {
                                            item.quantity = val;
                                          }
                                          return item;
                                        });
                                        this.props.onChangeMeetingHOC(temp, "newBooking");
                                      }
                                    }}
                                  />
                                )}
                              </span>
                            </div>
                          ))}
                          <div>
                            <label className="w-100 text-right">Add On Amount</label>
                            <span style={{minWidth: '30%'}} className="fw-700">RM {add_ons?.reduce((acc, item) => {
                              if (item.quantity)
                                return acc + (item.price * item.quantity)
                              else
                                return acc + item.price
                            }, 0)}</span>
                          </div>
                        </>
                      )}
                      <div>
                        <label className="fs-3 fw-600 w-100 text-right" style={{color: '#F0631D'}}>Total Summary</label>
                        <span className="fs-3 fw-600" style={{minWidth: '30%',color: '#F0631D'}}>RM 
                          {add_ons?.reduce((acc, item) => {
                          if (item.quantity)
                            return acc + (item.price * item.quantity)
                          else
                            return acc + item.price
                          }, 0) +
                          this.props.selectedRoom.business_lounge_pricings?.reduce((acc, item) => {return acc + item.price}, 0)}
                        </span>
                      </div>
                    </div>
                  </section>
                </>
              )}
              <section className="grid-full-col">
                <CustomCheckbox
                  content={'I have read and agreed to the'}
                  checked={is_have_read_terms_and_conditions}
                  contentStyle={{ fontWeight: 600 }}
                  onChangeCheckboxValue={(e) => {
                    let temp = _.cloneDeep(this.props.newBooking);
                    temp["is_have_read_terms_and_conditions"] =
                      e.target.checked;
                    this.props.onChangeMeetingHOC(temp, "newBooking");
                  }}
                />
                <span
                  style={{ color: "#2563EB", fontSize: 14, fontWeight: 600, cursor: "pointer"}}
                  onClick={() => this.props.onChangeMeetingHOC(true, "showTermsAndConditions")}
                >
                  terms and conditions
                </span>
              </section>
            </form>
          </div>
          <div className="col-md-6 p-0 px-md-2">
            <div className="at-calendar-cont">
              <div className="at-calendar-header_date">
                {this.state.calendarDate
                  ? Moment(this.state.calendarDate).format("DD MMM YYYY")
                  : Moment().format("DD MMM YYYY")}
              </div>
              <Calendar
                localizer={momentLocalizer(Moment)}
                defaultView="day"
                toolbar={false}
                date={
                  this.state.calendarDate
                    ? Moment(this.state.calendarDate)
                    : Moment()
                }
                onNavigate={(val) => this.setState({ calendarDate: val })}
                min={
                  this.state.calendarDate
                    ? Moment(this.state.calendarDate.toDate()).set({
                        hour: Moment(
                          this.props.selectedRoom.operating_start_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          this.props.selectedRoom.operating_start_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      })
                    : Moment().set({
                        hour: Moment(
                          this.props.selectedRoom.operating_start_time,
                          "hh:mm a",
                        ).format("HH"),
                        minute: Moment(
                          this.props.selectedRoom.operating_start_time,
                          "hh:mm a",
                        ).format("mm"),
                        second: 0,
                      })
                }
                max={
                  this.state.calendarDate
                    ? Moment(this.state.calendarDate.toDate())
                        .set({
                          hour: Moment(
                            this.props.selectedRoom.operating_end_time,
                            "hh:mm a",
                          ).format("HH"),
                          minute: Moment(
                            this.props.selectedRoom.operating_end_time,
                            "hh:mm a",
                          ).format("mm"),
                          second: 0,
                        })
                        .add(1, "hours")
                    : Moment()
                        .set({
                          hour: Moment(
                            this.props.selectedRoom.operating_end_time,
                            "hh:mm a",
                          ).format("HH"),
                          minute: Moment(
                            this.props.selectedRoom.operating_end_time,
                            "hh:mm a",
                          ).format("mm"),
                          second: 0,
                        })
                        .add(1, "hours")
                }
                events={this.state.calendarDate ? this.props.calendarEvent : []}
                startAccessor="start"
                endAccessor="end"
              />
            </div>
          </div>
        </div>
        {this.props.showTermsAndConditions && (
          <ModalDialog
            title={"Terms And Conditions"}
            maxWidth={"md"}
            children={<TermsAndConditions isBussinessLounge={this.props.selectedRoom?.meeting_room_type_id === 2} />}
            onClose={() =>
              this.props.onChangeMeetingHOC(false, "showTermsAndConditions")
            }
          />
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(Create);
