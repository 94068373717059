import React, { useState } from "react";
import { CircularProgress, Typography, Tooltip } from "@material-ui/core";
import LoadingModal from "components/LoadingModal";
import ShareModal from "components/Share";
import PartyCard from "components/Card/partyCard";
import permissionsChecker from "utils/permissionsChecker";
import ResendContractHOC from "../../../actions/resendContract";

const ProcessSection = ({
  status,
  data,
  tempPercentage,
  onClickRefresh,
  onLoadResend,
  resendContract,
  eProcessPartyData,
  selectedAuthorisationForm,
  onClickViewActivity,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [recipient, setRecipient] = useState("");
  const { can_update } = permissionsChecker("Forms", data);

  return (
    <div className={"mb-20"}>
      <div className="at-form__content d-flex flex-column grid_gap-2">
        <div className="d-flex flex-wrap grid_gap-1">
          <h5
            style={{ fontWeight: 500, height: "fit-content", margin: "auto 0" }}
          >
            {"Recipients in the signing process"}
          </h5>
          <button
            className={"btn-new btn-new--outline-grey color-black"}
            onClick={() => onClickViewActivity()}
          >
            View Activity
          </button>
        </div>
        <div className={"d-flex align-items-center"}>
          <Tooltip title={"Percentage of signed recipients"}>
            <div
              className={"d-flex justify-content-center align-items-center"}
              style={{ position: "relative", width: 40 }}>
              <CircularProgress
                size={40}
                style={{ background: "#FFFF", borderRadius: "50%" }}
                thickness={4}
                color={"primary"}
                variant="determinate"
                value={tempPercentage}/>
              <Typography
                style={{ position: "absolute" }}
                variant="caption"
                color="textSecondary">
                {`${parseInt(tempPercentage)}%`}
              </Typography>
            </div>
          </Tooltip>
          <strong
            style={{
              color: status === "Stopped" ? "#D93335" : "#2ECC71",
              marginLeft: 10,
            }}>
            e-Signing for authorisation form is now
            <span style={{ textTransform: "lowercase" }}>{` ${status}`}</span>
          </strong>
        </div>
        <h5 className={"at-card__description fs-2"}>
          Signing Order is set as below:
        </h5>
      </div>
      {eProcessPartyData.length > 0 && (
        <div className="party-card_cont mb-3">
          {eProcessPartyData.map((partyData, index) => (
            <PartyCard
              key={index}
              containerClass={"party-card-new"}
              type={"authorisation"}
              index={index}
              data={partyData}
              can_create={can_update}
              onClickRefresh={onClickRefresh}
              resendContract={() =>
                resendContract({
                  form_id: selectedAuthorisationForm.id,
                  form_type: 4,
                  form_contact_id: partyData.form_contact_id,
                })
              }
              onClickShare={(val) => {
                setShareLink(val.signing_link);
                setRecipient(val.full_name);
                setShowShareModal(true);
              }}
            />
          ))}
        </div>
      )}
      {showShareModal && (
        <ShareModal
          title={"Share the e-signing link"}
          shareLink={shareLink}
          containerClass={"at-share-modal"}
          containerStyle={{minHeight: "initial"}}
          encodedLink={`Hi ${recipient}, here is your Digital Authorisation Form for property at ${selectedAuthorisationForm.property_address}\n ${encodeURIComponent(shareLink)}`}
          showElement={["Whatsapp", "Telegram", "Open in New Tab"]}
          onClickClose={() => setShowShareModal(false)}
        />
      )}
      {onLoadResend && <LoadingModal />}
    </div>
  );
};

export default ResendContractHOC(ProcessSection);
