import React from "react";
import _ from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";
import ReactHtmlParser from "react-html-parser";
import CurrencyInput from "react-currency-input-field";

import CustomCheckbox from "components/Checkbox";
import AtlasRadioGroup from "components/RadioGroup";
import FormDialog from "../../../components/formDialog";

import { validationCheck } from "utils/checkNull";
import subrentForms from "../assets/subrentForms";
import { Rules, DurationTypeRadioOptions } from "../assets/index.js";
import { validateNumber } from "utils/checkNull";
import { replaceAll } from "utils/replaceAll";

const branchInfo = (id, branches) => {
  let tmp = _.find(branches, { id: id });
  return {
    name: tmp ? tmp.name : "",
    contact_details: tmp.contact_details || "",
    registration_number: tmp.registration_number || "",
  };
};

const TempSubrentLetter = ({
  data,
  switchMode,
  autoCalculatedList,
  invoicing_branches,

  onChange,
  closedEditing,
}) => {
  const {
    conditions,
    invoicing_branch_id,
    transaction_number,
    appointment_date,
    remind_me,
    day_to_remind_after_appointed,
    property_address,
    rental_amount,
    intended_tenure_years,
    renewal_tenure_years,
    advanced_rental_months,
    advanced_rental_amount,
    security_deposit_months,
    security_deposit_amount,
    utility_deposit_amount,
    sales_tax_percentage,
    fee_payable_amount,
    basis_id,
    valid_from,
    valid_to,
    stake_holder_id,
    stake_holder_company,
    stake_holder_registration_number,
    duration_of_tenancy,
    max_fee,
    duration_type_id,
  } = data;

  const tmpAddress = validationCheck(property_address) ? property_address : "";

  const renderContractLayout = () => (
    <>
      <h5 className="fw-600">Branch Information</h5>
      <hr />
      <div className="at-project-details__label">
        {
          branchInfo(invoicing_branch_id, invoicing_branches)
            .registration_number
        }
      </div>
      <div className="at-project-details__label">
        {ReactHtmlParser(
          replaceAll(
            branchInfo(invoicing_branch_id, invoicing_branches)
              .contact_details || "",
            "|",
            "<br/>",
          ),
        )}
      </div>
      <hr />
      <div className="d-flex flex-wrap justify-content-between align-items-end">
        <h5 className="fw-600">
          {" "}
          Appointment to Act as an Estate Agent (To Secure A Tenant){" "}
        </h5>
        <div>
          <p style={{ marginBottom: 10 }}>{`${transaction_number}`}</p>
          <div>
            {`Date: `}
            <div style={{ display: "inline-block" }}>
              <DatePicker
                className="subsales-datepicker"
                value={
                  Moment(appointment_date).isValid()
                    ? Moment(appointment_date).format("DD MMM YYYY")
                    : appointment_date
                }
                onChange={(val) =>
                  onChange(
                    "appointment_date",
                    Moment(val).format("YYYY-MM-DD"),
                  )
                }
                popperPlacement={"bottom-end"}
                placeholderText={"DD MMM YYYY"}
                disabled={closedEditing}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        I/We
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section) `}
        </span>
        NRIC No/Passport No:
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section) `}
        </span>
        of
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section) `}
        </span>
        hereby appoint IQI REALTY SDN BHD to secure a tenant for the Property
        mentioned below:
        <div className="d-flex flex-wrap my-2">
          <p>
            <b>{tmpAddress || "N/A"}</b>
          </p>
        </div>
        whereby IQI Realty Sdn Bhd is permitted to display signboard, record
        360-degree video, conduct virtual tour, advertise in any media or in any
        form to promote the rental of above mentioned Property, subject to the
        following terms and conditions:
      </div>
      <ol>
        <li>
          The monthly rental for the above mentioned property shall be at RM
          <CurrencyInput
            className="subsales-letter-input"
            style={{ paddingLeft: "0.2rem" }}
            name={"rental_amount"}
            defaultValue={1000}
            disabled={closedEditing}
            value={rental_amount}
            onValueChange={(value) => onChange("rental_amount", value)}
          />
          or on nearest offer.
        </li>
        <li>
          Intended tenure of Tenancy is
          <input
            type={"number"}
            className="subsales-letter-input"
            value={intended_tenure_years}
            onChange={(e) => onChange("intended_tenure_years", e.target.value)}
            disabled={closedEditing}
          />
          year(s) with an option to renew for a further period of
          <input
            className="subsales-letter-input"
            type={"number"}
            value={renewal_tenure_years}
            onChange={(e) => onChange("renewal_tenure_years", e.target.value)}
            disabled={closedEditing}
          />
          year(s).
        </li>
        <li>
          <p> Payments are as follows: </p>
          <ol type={"a"}>
            <li>
              Advanced Rental equivalent to
              <input
                className="subsales-letter-input"
                type={"number"}
                value={advanced_rental_months}
                onChange={(e) =>
                  onChange("advanced_rental_months", e.target.value)
                }
                disabled={closedEditing}
              />
              month(s) rental: RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"advanced_rental_amount"}
                defaultValue={1000}
                value={advanced_rental_amount}
                disabled={closedEditing}
                onValueChange={(value) =>
                  onChange("advanced_rental_amount", value)
                }
              />
            </li>
            <li>
              Refundable Security Deposit equivalent to
              <input
                className="subsales-letter-input"
                type={"number"}
                value={security_deposit_months}
                onChange={(e) =>
                  onChange("security_deposit_months", e.target.value)
                }
                disabled={closedEditing}
              />
              month(s) rental: RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"security_deposit_amount"}
                defaultValue={1000}
                value={security_deposit_amount}
                disabled={closedEditing}
                onValueChange={(value) =>
                  onChange("security_deposit_amount", value)
                }
              />
            </li>
            <li>
              Refundable Utility Deposit: RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"utility_deposit_amount"}
                defaultValue={1000}
                value={utility_deposit_amount}
                disabled={closedEditing}
                onValueChange={(value) =>
                  onChange("utility_deposit_amount", value)
                }
              />
            </li>
          </ol>
        </li>
        <li>
          Condition:
          <input
            type={"text"}
            style={{ width: "100%", maxWidth: "100%" }}
            className="subsales-letter-input"
            value={conditions}
            onChange={(e) => onChange("conditions", e.target.value)}
            disabled={closedEditing}
          />
        </li>
        <li>
          I/We hereby agree to pay IQI Realty Sdn Bhd a Professional Fee as
          follows:-
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={duration_type_id}
            disabled={closedEditing}
            selectedRadioValue={(val) => onChange("duration_type_id", val)}
            options={DurationTypeRadioOptions}
          />
          {duration_type_id === 1 && (
            <>
              The full Professional Fee payable to IQI Realty Sdn Bhd shall be
              RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"fee_payable_amount"}
                defaultValue={1000}
                value={fee_payable_amount || ""}
                disabled={closedEditing}
                onValueChange={(value) => onChange("fee_payable_amount", value)}
              />
              + RM
              <strong>
                {
                  +validateNumber(
                    (fee_payable_amount * sales_tax_percentage) / 100,
                    2,
                  )
                }
              </strong>{" "}
              ({sales_tax_percentage}% SST amount) = RM
              <strong>
                {validateNumber(
                  +fee_payable_amount +
                    +((fee_payable_amount * sales_tax_percentage) / 100),
                  2,
                )}{" "}
              </strong>
              Only.
              <p className={"mt-2"}>
                In accordance with Schedule 7(C)(2) under Rule 48(a) of the
                Valuers, Appraisers and Estate Agents Rules 1986, the scale of
                the Professional Fee to be charged by registered agents for
                their professional services shall be:
              </p>
              <table
                className="subrent-letter-table"
                style={{ margin: 0, marginTop: 10 }}
              >
                <thead>
                  <tr>
                    <td> Duration of Tenancy/Leases </td>
                    <td> Maximum Fee (equivalent to) </td>
                  </tr>
                </thead>
                <tbody>
                  {Rules.map((item, index) => {
                    return (
                      <tr key={`RulesTable-${index}`}>
                        <td>{item.duration}</td>
                        <td>
                          <p>{item.fee}</p>
                          {item.feeExtra ? (
                            <p>{item.feeExtra}</p>
                          ) : (
                            <span></span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
          {duration_type_id === 2 && (
            <ol type={"a"}>
              <li>
                The scale of the Professional Fee to be charged by Agency for
                our professional services shall be:
                <table className="subrent-letter-table mt-10 mb-10">
                  <thead>
                    <tr>
                      <td> Duration of Tenancy/Leases </td>
                      <td> Maximum Fee (equivalent to) </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="subsales-letter-input"
                          value={duration_of_tenancy}
                          onChange={(e) =>
                            onChange("duration_of_tenancy", e.target.value)
                          }
                          disabled={closedEditing}
                        />
                        years
                      </td>
                      <td>
                        <input
                          className="subsales-letter-input"
                          type={"number"}
                          inputmode={"decimal"}
                          pattern={"d*"}
                          value={max_fee}
                          onChange={(e) => onChange("max_fee", e.target.value)}
                          disabled={closedEditing}
                        />
                        month gross rental
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                The full Professional Fee payable to IQI Realty Sdn Bhd shall be
                RM
                <CurrencyInput
                  className="subsales-letter-input"
                  style={{ paddingLeft: "0.2rem" }}
                  name={"fee_payable_amount"}
                  defaultValue={1000}
                  value={fee_payable_amount}
                  disabled={closedEditing}
                  onValueChange={(value) =>
                    onChange("fee_payable_amount", value)
                  }
                />
                + RM
                <strong>
                  {
                    +validateNumber(
                      (fee_payable_amount * sales_tax_percentage) / 100,
                      2,
                    )
                  }
                </strong>{" "}
                ({sales_tax_percentage} % SST amount) = RM
                <strong>
                  {validateNumber(
                    +fee_payable_amount +
                      +((fee_payable_amount * sales_tax_percentage) / 100),
                    2,
                  )}{" "}
                </strong>
                Only.
              </li>
            </ol>
          )}
        </li>
        <li>
          The Professional Fee is payable in full to IQI Realty Sdn Bhd upon the
          signing of the Tenancy Agreement or upon presentation of vacant
          possession whichever is earlier.{" "}
        </li>
        <li>
          In the event that the earnest deposit is paid but the transaction is
          aborted before the Tenancy Agreement is signed or before possession is
          given, I/We agree to pay you a Professional Fee equivalent to 50% of
          the forfeited earnest deposit or 50% of the agreed Professional Fee,
          whichever is lesser.
        </li>
        <li>
          This appointment is on an{" "}
          <input
            type={"radio"}
            name={"appt-basis"}
            checked={basis_id === 1}
            value={1}
            onChange={(e) => onChange("basis_id", +e.target.value)}
            disabled={closedEditing}
          />
          {`Exclusive basis  `}
          <input
            type={"radio"}
            name={"appt-basis"}
            checked={basis_id === 2}
            value={2}
            onChange={(e) => onChange("basis_id", +e.target.value)}
            disabled={closedEditing}
          />
          Ad-hoc basis.
          {basis_id === 1 && (
            <ol type={"i"}>
              <li>
                {"This shall be valid from   "}
                <div style={{ display: "inline-block" }}>
                  <DatePicker
                    className="subsales-datepicker"
                    value={
                      Moment(valid_from).isValid()
                        ? Moment(valid_from).format("DD MMM YYYY")
                        : valid_from
                    }
                    onChange={(val) =>
                      onChange("valid_from", Moment(val).format("YYYY-MM-DD"))
                    }
                    placeholderText={"DD/MM/YYYY"}
                    disabled={closedEditing}
                    popperPlacement={"bottom"}
                  />
                </div>
                to{" "}
                <div style={{ display: "inline-block" }}>
                  <DatePicker
                    className="subsales-datepicker"
                    value={
                      Moment(valid_to).isValid()
                        ? Moment(valid_to).format("DD MMM YYYY")
                        : valid_to
                    }
                    onChange={(val) =>
                      onChange("valid_to", Moment(val).format("YYYY-MM-DD"))
                    }
                    placeholderText={"DD/MM/YYYY"}
                    disabled={closedEditing}
                    popperPlacement={"bottom"}
                  />
                </div>
                and deemed renewable for a period equivalent to the original
                term unless either party gives notice of termination in writing.
              </li>
              <li>
                IQI Realty Sdn Bhd is entitled to the Professional Fee if any
                introduction made during the period of this appointment leads to
                a successful letting, even if this appointment has expired or
                has been terminated at the time of completion of the
                transaction.
              </li>
            </ol>
          )}
        </li>
        <li>
          {"I/We hereby authorize  ("}
          <input
            type={"radio"}
            name={"party-selection"}
            value={1}
            checked={stake_holder_id === 1}
            onChange={(e) => onChange("stake_holder_id", +e.target.value)}
            disabled={closedEditing}
          />
          {"IQI REALTY SDN BHD  "}
          <input
            type={"radio"}
            name={"party-selection"}
            value={2}
            checked={stake_holder_id === 2}
            onChange={(e) => onChange("stake_holder_id", +e.target.value)}
            disabled={closedEditing}
          />
          {"Lawyer)  "}
          {stake_holder_id === 2 && (
            <div style={{ border: "1px solid #000", padding: 8 }}>
              <div>
                <span> Lawyer Company Name: </span>
                <input
                  type={"text"}
                  className="subsales-letter-input"
                  style={{ width: "1000px" }}
                  value={stake_holder_company}
                  onChange={(e) =>
                    onChange("stake_holder_company", e.target.value)
                  }
                  disabled={closedEditing}
                />
              </div>
              <div>
                <span> Lawyer Company Registration Number: </span>
                <input
                  type={"text"}
                  className="subsales-letter-input"
                  style={{ width: "1000px" }}
                  value={stake_holder_registration_number}
                  onChange={(e) =>
                    onChange("stake_holder_registration_number", e.target.value)
                  }
                  disabled={closedEditing}
                />
              </div>
            </div>
          )}
          to act as stakeholder and to accept on my/our behalf the earnest
          deposit and I/We irrevocably instruct IQI Realty Sdn Bhd to deduct the
          agreed Professional Fee from the earnest deposit and release the
          remaining earnest deposit (if any) to us and/or our appointed lawyer
          once the Tenancy Agreement is signed.
        </li>
        <li>
          I/We fully indemnify IQI Realty Sdn Bhd against all claims by the
          Tenant(s) if I/We for any reason whatsoever, do not proceed to execute
          the Tenancy Agreement after earnest deposit is collected.
        </li>
        <li>
          All signatories to this document hereby acknowledge and agree to comply with the Malaysia Anti-Corruption Commission Act 2009 and Amendment Act - Section 17A of the MACC Act 2009 (MACC 2009), Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 (AML/CFT), 
          Personal Data Protection Act 2010 and further authorise IQI Realty Sdn Bhd and/or its group companies to disclose their information to third parties as necessary, inter alia, 
          at the request of regulatory authorities. For reference, parties may refer to our website at <a target="_blank" href="https://www.iqiglobal.com/amla">https://www.iqiglobal.com/amla</a> and <a target="_blank" href="https://www.iqiglobal.com/privacy-policy">https://www.iqiglobal.com/privacy-policy</a>.
        </li>
      </ol>
      <hr />
      <CustomCheckbox
        disabled={closedEditing}
        checked={remind_me}
        content={
          <span>
            Remind me about this instruction after
            <input
              type={"text"}
              disabled={closedEditing}
              className="subsales-reminder-days-input"
              value={day_to_remind_after_appointed}
              onChange={(e) =>
                onChange("day_to_remind_after_appointed", e.target.value)
              }
            />
            days.
          </span>
        }
        onChangeCheckboxValue={(e) => onChange("remind_me", e.target.checked)}
      />
    </>
  );

  const renderFormListLayout = () => {
    return (
      <FormDialog
        formData={subrentForms}
        data={data}
        autoCalculatedList={autoCalculatedList}
        closedEditing={closedEditing}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      {switchMode === "Contract View" && renderContractLayout()}
      {switchMode === "Form View" && renderFormListLayout()}
    </>
  );
};

export default TempSubrentLetter;
