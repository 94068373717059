export const modulesPermissions = [
  {
    moduleName: "Dashboard",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Campaign Tracker",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Members",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "My Hierarchy",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Project Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Performance",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Team Birthdays",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Recruitment Growth Target",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "New Agent Approvals",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "My Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Payment Vouchers",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Forms",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Subsales Claims",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "My Contacts",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Leads",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Project Centre",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "Listing Centre",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Co Broke Center",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Presentation Centre",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Video Creator",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "My Trainings",
    checkboxes: ["can_create", "can_read"],
  },
  {
    moduleName: "IQI Academy",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Tutorials",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Valuations",
    checkboxes: ["can_create", "can_read"],
  },
  {
    moduleName: "Top Sales",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "IQI Drive",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "Company Events",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "Global Network",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "IQI Store",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Atlas Store",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  // Admin Permission
  {
    moduleName: "Team",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "Admin Roles",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Sessions",
    checkboxes: ["can_read", "can_destroy"],
  },
  {
    moduleName: "OTP Verification",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Co-Agencies",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "Agent's Subsales Accessibility",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Atlas Store Products",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Atlas Store Orders",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "Atlas Store Coupons",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Atlas Store Name Card Batches",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "Training Attendances",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Exam Results",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "Agreements",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Analytics Centre",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "Customer Due Diligence",
    checkboxes: ["can_read"],
  },
  {
    moduleName: "MOTD",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Users",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "Newsfeeds",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "App Themes",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Permission Groups",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "Graphic Templates",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Graphic Studio",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Email Templates",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "QR Based Door Entry",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Entity Settings",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Projects - Project Units",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Projects - Reservations",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "Projects - Reservation Status",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "Projects - Discount Schemes",
    checkboxes: ["can_create", "can_read", "can_update"],
  },
  {
    moduleName: "Projects - Project Unit Types",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Exam Integration",
    checkboxes: ["can_read", "can_update"],
  },
  {
    moduleName: "REA License Renewal",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Meeting Hub",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Projects",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Public Holiday",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Meeting Hub - Booking History",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Meeting Hub - Room Management",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
  {
    moduleName: "Events",
    checkboxes: ["can_create", "can_read", "can_update", "can_destroy"],
  },
];
