import React, { useEffect, useState } from "react";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import CustomUpload from "components/Input/upload";
import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";
import AtlasDialog from "components/Dialog";
import CustomButton from "components/Button";

export default function CreateAttachment({
  mode,
  selectedAttachment,
  createAttachment,
  updateAttachment,
  onLoad,
  onClose,
  onChangeAttachmentHOC,
}) {
  const [attachementData, onChangeeAttachmentData] = useState({
    title: "",
    image: "",
    image_file_name: "",
  });

  useEffect(() => {
    mode === "edit" &&
      onChangeeAttachmentData({
        title: selectedAttachment.title,
        image: selectedAttachment.image_url,
        image_file_name: selectedAttachment.image_file_name,
      });
  }, []);

  const onChangeValue = (value, context) => {
    mode === "create"
      ? onChangeeAttachmentData((prev) => ({
          ...prev,
          [context]: value,
        }))
      : onChangeAttachmentHOC(value, context);
  };

  return (
    <AtlasDialog open={true}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton onClick={() => onClose()} />
              <h2 className="at-card__title text-capitalize">
                {`${mode}`} Attachment
              </h2>
            </div>
            <div className="grid-control">
              <section className="grid-full-col mb-10">
                <h2 className="at-form-input__title" required>
                  Title
                </h2>
                <CustomFormInput
                  type="text"
                  required={true}
                  multiple={false}
                  lineStyle={{ display: "none" }}
                  value={attachementData.title}
                  onChangeValue={(val) => onChangeValue(val, "title")}
                  placeholder={"Enter button label"}
                />
              </section>
              <section className="grid-full-col mb-10">
                <h2 className="at-form-input__title" required>
                  Attachment
                </h2>
                <CustomUpload
                  required={true}
                  multiple={false}
                  accepts={"application/*, image/*"}
                  className="at-profile__main-avatar"
                  style={{ height: 375 }}
                  imageUrl={attachementData.image}
                  uploadImage={(files) => {
                    onChangeeAttachmentData((prev) => ({
                      ...prev,
                      image: files[0].source,
                      image_file_name: files[0].name,
                    }));
                  }}
                />
              </section>
              <section className="grid-full-col d-flex">
                <CustomButton
                  disabled={_.values(attachementData).some(
                    (item) => item === "" || _.isEmpty(item)
                  )}
                  className="btn-new btn-new--secondary"
                  children={mode === "create" ? "Create" : "Update"}
                  onClick={() => {
                    mode === "create"
                      ? createAttachment(attachementData)
                      : updateAttachment(
                          attachementData,
                          selectedAttachment.id
                        );
                  }}
                />
                <CustomButton
                  className="btn-new btn-new--outline-secondary ml-2"
                  children={"Close"}
                  onClick={() => onClose()}
                />
              </section>
            </div>
            {onLoad && <LoadingModal />}
          </>
        }
      />
    </AtlasDialog>
  );
}
