import { Fragment } from "react";

const DescriptionList = ({ data = [] }) => {
  const filteredData = data.filter((item) => !item.hidden);

  return (
    <>
      <div className={"row"}>
        {filteredData?.map((item, index) => (
          <Fragment key={index}>
            <div className="col-3 fs-2 fw-600">{item.title}</div>
            <div className="col-9 fs-2">
              <span>{item.description || "N/A"}</span>
            </div>

            {filteredData.length - 1 !== index && (
              <hr
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#D1D5DB",
                  border: "none",
                  marginTop: 0,
                  marginBottom: 16,
                }}
              />
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default DescriptionList;
