import React from "react";
import Moment from "moment";

import AtDialog from "components/Dialog";
import AtlasCard from "components/Card";
import AtlasTable from "components/NewTable";
import AtlasCloseButton from "components/Button/close";
import JsonPrettifier from "components/JsonPrettifier";
import isEmptyValue from "utils/isEmpty";

const columnData = [
  {
    header: "Created At",
    accessor: "created_at",
    searchFlag: "created_at",
    columnStyle: { width: "100%" },
    renderColumn: (rowData) => (
      <p>{Moment(rowData.created_at).format("DD MMM YYYY")}</p>
    ),
  },
  {
    header: "Description",
    accessor: "description",
    searchFlag: "description",
    columnStyle: { width: "100%" },
  },
  {
    header: "Paid amount / Pricing",
    accessor: "pricing",
    hideFromSearch: true,
    searchFlag: "pricing",
    columnStyle: { width: "100%" },
    renderColumn: (rowData) => (
      <span>
        {`${isEmptyValue(rowData.amount) ? "N/A" : rowData.amount} 
          / 
          ${isEmptyValue(rowData.pricing) ? "N/A" : rowData.pricing}`}
      </span>
    ),
  },
  {
    header: "Gateway",
    accessor: "gateway",
    searchFlag: "gateway",
    columnStyle: { width: "100%" },
  },
  {
    header: "Request IP",
    accessor: "request_ip",
    searchFlag: "request_ip",
    columnStyle: { width: "100%" },
  },
  {
    header: "Response",
    accessor: "response",
    searchFlag: "response",
    renderColumn: (rowData) => (
      <p>
        {!isEmptyValue(rowData.response) &&
          JsonPrettifier(JSON.parse(rowData.response))}
      </p>
    ),
    columnStyle: { width: "100%" },
  },
  {
    header: "Status",
    accessor: "status",
    searchFlag: "status",
    columnStyle: { width: "100%" },
  },
];

const PaymentDetail = ({
  open,
  attendeePaymentDetail,

  onClickClose,
}) => {
  const renderCardContent = () => (
    <>
      <div className="d-flex align-items-center">
        <h2 className="at-form-input__title">Payment Details</h2>
        <AtlasCloseButton
          containerStyle={{ marginLeft: "auto" }}
          onClick={() => onClickClose()}
        />
      </div>
      <hr />
      <AtlasTable
        className={"mb-100"}
        columns={columnData}
        pagination={true}
        rowData={attendeePaymentDetail || []}
        actionColumnContent={[]}
      />
    </>
  );

  return (
    <AtDialog open={open}>
      <AtlasCard
        className="h-100"
        containerStyle={{ maxWidth: "inherit" }}
        cardContent={renderCardContent()}
      />
    </AtDialog>
  );
};

export default PaymentDetail;
