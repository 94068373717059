import React from "react";

import Dialog from "@material-ui/core/Dialog";
import AtlasCloseButton from "components/Button/close";
import ReactPlayer from "react-player";

import "stylesheets/components/videoplayer/index.scss";

const VideoPlayer = ({ onClickClose, videoURL }) => {
  return (
    <Dialog
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          maxWidth: "none",
          margin: "0px",
        },
      }}
    >
      <div className="at-video-player__container">
        <AtlasCloseButton
          containerClass="at-video-player_close"
          onClick={() => onClickClose()}
        />
        <ReactPlayer
          url={videoURL}
          playing={true}
          controls={true}
          className={"at-video-player_player"}
          style={{ backgroundColor: "black" }}
        />
      </div>
    </Dialog>
  );
};

export default VideoPlayer;
