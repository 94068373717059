import React, { useState, useRef, useEffect, useCallback } from "react";
import _ from "lodash";
import { FormControl, FormGroup, IconButton } from "@material-ui/core";
import { FaShareAlt, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { TiTabsOutline } from "react-icons/ti";

import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import LanguageHOC from "actions/language";

const EmailDraftContent = ({
  languages = [],
  agentName,
  selectedPresentation,
  showLoading,
  onLoadLanguage,

  onClose,
  deliverPresentationEmail,
  getLanguages,
}) => {
  useEffect(() => {
    getLanguages(28);
  }, []);

  const [selectedClients, onChangeClients] = useState([]);
  const [selectedLang, onChangeLang] = useState("en");

  const {
    id: selectedPresentationId,
    clients: clientsData,
    user_info,
  } = selectedPresentation;

  return (
    <>
      <ModalDialog
        title={"Share Presentation"}
        onClose={onClose}
        children={
          <>
            {clientsData.length < 1 && (
              <i>
                Please create at least one client record to be to send an email.
              </i>
            )}
            {clientsData.length > 0 && (
              <>
                <FormControl className={"w-100"}>
                  <p className={"mb-10"}>
                    Please select at least one client to send the presentation
                    link to their email address.
                  </p>
                  <FormGroup>
                    {!_.isEmpty(clientsData) && (
                      <CustomCheckbox
                        checked={selectedClients.length === clientsData.length}
                        onChangeCheckboxValue={() => {
                          let temp = _.cloneDeep(selectedClients);
                          if (!_.isEmpty(selectedClients)) {
                            temp = [];
                          } else {
                            temp = clientsData.map((item) => item.id);
                          }
                          onChangeClients(temp);
                        }}
                        content={<span>Select All</span>}
                      />
                    )}
                    {clientsData.map((client) => (
                      <CustomCheckbox
                        checked={selectedClients.indexOf(client.id) !== -1}
                        onChangeCheckboxValue={() => {
                          let temp = _.cloneDeep(selectedClients);
                          let tempIndex = temp.indexOf(client.id);
                          if (tempIndex === -1) {
                            temp.push(client.id);
                          } else {
                            temp.splice(tempIndex, 1);
                          }
                          onChangeClients(temp);
                        }}
                        content={
                          <>
                            <span>
                              {client.full_name} ({client.email})
                            </span>
                            <ShareContent
                              client={client}
                              user_info={user_info}
                              agentName={agentName}
                              selectedLang={selectedLang}
                            />
                          </>
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                <FormControl
                  style={{ width: "100%", marginTop: 20, marginBottom: 10 }}
                >
                  <label className="at-form-input__title" required>
                    Language Option
                  </label>
                  <CustomSelect
                    required={true}
                    labelKey={"language"}
                    valueKey={"language_code"}
                    className="at-table__select w-50"
                    selectItems={languages}
                    updateSelect={(val) => onChangeLang(val.language_code)}
                    currentlySelected={selectedLang}
                  />
                </FormControl>
              </>
            )}
          </>
        }
        footer={
          <button
            className={"btn-new btn-new--secondary"}
            disabled={selectedClients.length < 1 || !selectedLang}
            onClick={() =>
              deliverPresentationEmail(
                selectedPresentationId,
                selectedClients,
                selectedLang,
              )
            }
          >
            Email Now
          </button>
        }
      />
      {(showLoading || onLoadLanguage) && <LoadingModal />}
    </>
  );
};

const MessageContent = (client, agentName, selectedLang) =>
  `Hi ${client.full_name || "N/A"},\n\n${agentName} has prepared a personalised presentation of properties that takes into account the requirements that you had. To view the presentation, click the link below or copy and paste it into your browser.\n ${client.link + `?lang=${selectedLang}`} \n\nPlease review the properties and contact ${agentName} for further enquiries.`;

const ShareContent = ({ client, agentName, user_info, selectedLang }) => {
  const [showShareMenu, onToggleShare] = useState(false);
  let dropDownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", (e) => handleOnClose(e), true);
  }, []);

  let handleOnClose = useCallback(
    (event) => {
      if (
        dropDownRef &&
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target)
      ) {
        onToggleShare(false);
      }
    },
    [showShareMenu],
  );
  return (
    <div className="position-relative d-inline">
      <IconButton
        style={{ padding: 2, marginLeft: 10 }}
        disabled={!selectedLang}
        onClick={() => onToggleShare(!showShareMenu)}
      >
        <FaShareAlt style={{ width: 16, height: 16 }} />
      </IconButton>
      <div
        ref={dropDownRef}
        className={"at-presentation__dropbear--modal-root"}
        style={{
          top: 40,
          width: "max-content",
          display: showShareMenu ? "block" : "none",
        }}
      >
        <div className={`at-presentation__dropbear--list`}>
          <button
            onClick={() => window.open(client.link + `?lang=${selectedLang}`)}
          >
            <TiTabsOutline style={{ width: 20, height: 20, marginRight: 3 }} />
            <span> Open presentation link in a new tab.</span>
          </button>
          <button
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send?text=${encodeURI(MessageContent(client, agentName, selectedLang))}`,
              );
            }}
          >
            <FaWhatsapp style={{ width: 20, height: 20, marginRight: 3 }} />
            <span> Share presentation link via Whatsapp.</span>
          </button>
          <button
            onClick={() =>
              window.open(
                `https://telegram.me/share/url?url=${encodeURI(MessageContent(client, agentName, selectedLang))}`,
              )
            }
          >
            <FaTelegramPlane
              style={{ width: 20, height: 20, marginRight: 3 }}
            />
            <span> Share presentation link via Telegram.</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LanguageHOC(EmailDraftContent);
