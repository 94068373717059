import React, { useState } from "react";
import { Card } from "@material-ui/core";
import { IoIosPlay } from "react-icons/io";
import { MdLibraryAdd } from "react-icons/md";
import { MdFilterList } from "react-icons/md";

import CustomButton from "components/Button";
import VideoPlayer from "components/VideoPlayer";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasConfirmationDialog from "components/Modal/confirmation";
import TablePagination from "components/NewPaginatedTable/Pagination";
import TableSearchParams from "components/NewPaginatedTable/SearchParams";
import EmptyState from "components/EmptyState";

import DefaultThumbnail from "assets/images/default_thumbnail.jpg";

const Create = ({
  onChangeVideoCreatorHOC,
  videoTemplate,
  selectedProjectListVideo,
  createPersonalisedVideo,
  onVideoCreatorLoad,
  getVideoTemplate,
  videoTemplatePage,
  countries,
}) => {
  const [showSearchModal, onToggleSearchModal] = useState(false);
  const [showSearchContent, setShowSearch] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [searchParams, setSearchParams] = useState([
    {
      label: "Title",
      value: "title_cont",
      type: "input",
      param: "",
    },
    {
      label: "Project",
      value: "project_internal_name_cont",
      type: "input",
      param: "",
    },
    {
      label: "Country",
      value: "country_id_eq",
      type: "select",
      param: "",
      options: countries,
    },
  ]);
  const { meta } = videoTemplate;

  const renderConfirmation = () => (
    <AtlasConfirmationDialog
      mode={"alert"}
      open={showConfirmation}
      message={
        "Click yes button to proceed to generate personalised video using this template. The video processing might take several minute, please check the status periodically."
      }
      positiveAction={() => {
        Promise.all([
          createPersonalisedVideo(selectedProjectListVideo.id),
        ]).then(() => {
          setConfirmation(false);
        });
      }}
      negativeAction={() => setConfirmation(false)}
    />
  );

  const renderProjectCard = () => {
    if (videoTemplate.data.length > 0) {
      return (
        <div className="row px-0">
          {videoTemplate.data.map((item, index) => {
            return (
              <div
                className="col-md-6 col-xl-4"
                style={{ padding: 0 }}
                key={index}
              >
                <Card className="mt-2 mx-2 at-video-template__item">
                  <img
                    src={
                      item.thumbnail_url ? item.thumbnail_url : DefaultThumbnail
                    }
                    alt={`thumnail-${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="at-video-creator__project_detail d-flex"
                    style={{ top: 0 }}
                  >
                    <CustomButton
                      className={"at-video-create__play-btn"}
                      tooltip={true}
                      tooltipChildren={"Preview Video"}
                      tooltipPosition={"top"}
                      tooltipID={`video-preview-${index}`}
                      onClick={() => {
                        setShowVideoPlayer(true);
                        setVideoURL(item.url);
                      }}
                    >
                      <IoIosPlay style={{ width: "12px", color: "#F0631D" }} />
                      {item.duration}
                    </CustomButton>
                    <CustomButton
                      containerStyle={{ marginLeft: "auto" }}
                      style={{
                        padding: 10,
                        backgroundColor: "#F0631D ",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        Promise.all([
                          onChangeVideoCreatorHOC(
                            item,
                            "selectedProjectListVideo",
                          ),
                        ]).then(() => {
                          setConfirmation(true);
                        });
                      }}
                      className={"d-flex"}
                      tooltip={true}
                      tooltipChildren={"Add to process list"}
                      tooltipPosition={"top"}
                      tooltipID={`video-request-${index}`}
                      children={
                        <MdLibraryAdd
                          style={{ width: "15px", color: "white" }}
                        />
                      }
                    />
                  </div>
                  <div
                    className="at-video-creator__project_detail"
                    style={{ bottom: 0 }}
                  >
                    <strong>{item.title} </strong>
                    <p style={{ marginTop: 8 }}>{item.description}</p>
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <EmptyState
          title={`You don't have any videos`}
          renderIcon={<AtlasIcon svgHref="atlas-video-play" />}
        />
      );
    }
  };

  const renderCardContent = () => (
    <>
      <p className="at-video-creator__help-container mb-10">
        To get started, search for an available video template by the project
        name or title. When you have found the template you want to be
        personalised, select it and then click on the Add icon. Atlas will then
        generate your personalised video - when the video is ready, you will get
        a notification.
      </p>
      {searchParams.length > 0 && (
        <div className="mb-10">
          <button
            className={
              "btn-new btn-new--outline-secondary at-mobile-view__controller"
            }
            style={{ width: "100%" }}
            onClick={() => onToggleSearchModal(!showSearchModal)}
          >
            <MdFilterList style={{ width: 20 }} />
            <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
              Filter & Search
            </h5>
          </button>
          <div
            className="at-table__search-cont at-desktop-view__controller"
            style={{ boxShadow: "none" }}
          >
            <TableSearchParams
              mode={"desktop"}
              searchParams={searchParams}
              onChangeSearchParams={(val) => setSearchParams(val)}
              getListAPI={getVideoTemplate}
            />
          </div>
        </div>
      )}
      {renderProjectCard()}
      <div className="at-pagination__cont">
        <TablePagination
          meta={meta}
          searchParams={searchParams}
          getListAPI={getVideoTemplate}
          totalPages={videoTemplatePage}
        />
        {meta && meta.count > 0 && (
          <p className={`at-table__pagination-info`}>
            Showing
            <span> {meta && meta.from} </span>-<span> {meta && meta.to} </span>
            of
            <span> {meta && meta.count} </span>
            results
          </p>
        )}
      </div>
      {showSearchModal && (
        <TableSearchParams
          mode={"mobile"}
          searchParams={searchParams}
          onToggleSearchModal={onToggleSearchModal}
          onChangeSearchParams={(val) => setSearchParams(val)}
          getListAPI={getVideoTemplate}
        />
      )}
    </>
  );

  return (
    <>
      {renderCardContent()}
      {renderConfirmation()}
      {onVideoCreatorLoad && <LoadingModal />}
      {showVideoPlayer && (
        <VideoPlayer
          videoURL={videoURL}
          onClickClose={() => setShowVideoPlayer(false)}
        />
      )}
    </>
  );
};

export default Create;
