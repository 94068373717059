import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { dateValidation } from "utils/checkNull";
import { Get, Put, Post, GetFile } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import { getAllDictionaryData } from "actions/dictionary";
import { storeSubsalesAddress } from "actions/subsales/address";
import { getInvoicingBranches } from "actions/branch";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Agent Name/Email",
    value: "user_full_name_or_user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Form Number",
    value: `transaction_number_cont`,
    type: "input",
    param: "",
  },
  {
    label: "Property Address",
    value: "property_address_cont",
    type: "input",
    param: "",
  },
  {
    label: "Participant Name",
    value: "form_contacts_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Participant Email",
    value: "form_contacts_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Participant Mobile Number",
    value: "form_contacts_mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Submitted",
    value: "is_claimed_true",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Yes" },
      { value: "0", label: "No" },
    ],
  },
];

const initialPropertyData = {
  unit_no: "",
  address: "",
  name_s_lower: "",
  property_name: "",
  postcode: "",
  built_up: 0,
  land_area: 0,
  category_id: "",
  property_type_id: "",
  tenure_type_id: "",
  unit_type_id: "",
  area_s_lower: "",
  state_s_lower: "",
  street_name_s_lower: "",
  address: "",
  country_id: "",
  id: "",
  source_id: "",
  source_type: "",
  state_id: "",
  township_id: "",
};

const ConfirmationForms = (WrappedComponent) => {
  class ConfirmationFormsHOC extends Component {
    state = {
      confirmationForms: {
        data: [],
        meta: {},
      },
      agentList: [],
      contactList: [],
      searchParams: searchParams,
      initialConfirmationForm: null,
      selectedConfirmationForm: {
        id: 0,
        invoicing_branch_id: null,
        transaction_number: "",
        confirmation_date: null,
        execution_days: 0,
        buyers_name: "",
        buyers_nric: "",
        buyers_contact: "",
        buyers_address: "",
        owners_name: "",
        owners_nric: "",
        owners_contact: "",
        owners_address: "",
        property_address: "",
        stake_holder_id: 1,
        stake_holder_company: "",
        stake_holder_address: "",
        stake_holder_registration_number: "",
        stake_holder_contact_number: "",
        stake_holder_bank_id: 1,
        stake_holder_bank_account_number: "",
        earnest_deposit_amount: 0,
        deposit_cheque_number: "",
        deposit_payment_method_id: 1,
        monthly_rental_amount: 0,
        one_month_earnest_deposit: 0,
        advanced_rental_amount: 0,
        advanced_rental_months: 0,
        security_deposit_amount: 0,
        security_deposit_months: 0,
        utility_deposit_amount: 0,
        utility_deposit_months: 0,
        disbursement_and_stamping_fee: 0,
        before_handover_amount: 0,
        intended_tenure_years: 0,
        renewal_tenure_years: 0,
        commencement_date: null,
        other_conditions: "",
        has_extra_conditions: false,
        extra_conditions: "",
        special_conditions: "",
        sales_tax_percentage: 6,
        sales_tax_amount: 0,
        agency_fee_months: 0,
        agency_fee_amount: 0,
        purchase_price: 0,
        down_payment_percentage: 0,
        down_payment_amount: 0,
        balance_pay_within_days: 0,
        balance_pay_within_another_days: 0,
        balance_deposit_amount: 0,
        balance_deposit_wording: "",
        balance_deposit_percentage_wording: "",
        balance_deposit_percentage: 0,
        balance_purchase_price: 0,
        balance_purchase_price_wording: "",
        balance_purchase_price_percentage_wording: "",
        balance_purchase_price_percentage: 0,
        chargeable_interest_percentage: 0,
        professional_fee_percentage: 0,
        type_id: 1,
        is_claimed: false,
        tmp_claimed: false,
        down_payment_wording: "",
        vacant_posession_day: 0,
        expiration_day: 0,
        annum_interest_rate: 0,
      },
      selectedPropertyData: initialPropertyData,
      loading: false,

      showDownloadConfirmation: false,
      showConfirmToggleStatus: false,
      showSpecialConditionRTE: false,
      showResetConfirmation: false,
      showCreationForm: false,
      showCloneConfirmationModal: false,
      showSubmitClaimForm: false,
      showSubmitConfirmationClaimForm: false,
      showDetachConfirmation: false,
      showEditModal: false,
      selectedDownloadId: false,

      purchaserNTenant: false,
      selectedCloneId: null,
      disableSubmitButton: false,
      totalPages: [],
      tempEProcess: null,
      resetContent: {
        confirmation_form: false,
        temp_receipt: false,
      },
      ownshipsList: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeConfirmationFormHOC = (key, val) => this.setState({ [key]: val });

    closeSubmitConfirmation = () =>
      this.setState({
        showSubmitConfirmationClaimForm: false,
        showSubmitClaimForm: false,
        showSelectForm: false,
        showDetachConfirmation: false,
      });

    getCurrentFormContactList = (form_id) =>
      Get(
        `/admin/forms/form_contacts/search_contact?type_id=1&form_id=${form_id}`,
        (payload) => this.setState({ contactList: payload }),
        this.getCurrentFormContactListError,
        this.load,
      );
    getCurrentFormContactListError = (error) => requestError(error);

    getConfirmationForm = (id) =>
      Get(
        `/admin/forms/confirmation_forms/${id}`,
        this.getConfirmationFormSuccess,
        this.getConfirmationFormError,
        this.load,
      );
    getConfirmationFormSuccess = (payload) => {
      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_confirmation", "rent_confirmation"].find((type) => {
        if (tmpPayload[type] && tmpPayload[type] !== null)
          return tmpPayload[type];
      });

      let tmpSelectedConfirmationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        id: payload.id,
        tmp_claimed: tmpPayload.is_claimed
      };

      if(payload.type_id === 1) {
        tmpSelectedConfirmationForm = {
          ...tmpSelectedConfirmationForm,
          purchase_price: parseFloat(tmpPayload?.sale_confirmation?.purchase_price ?? 0)?.toFixed(2),
          down_payment_amount: parseFloat(tmpPayload?.sale_confirmation?.down_payment_amount ?? 0)?.toFixed(2),
          balance_deposit_amount: parseFloat(tmpPayload?.sale_confirmation?.balance_deposit_amount ?? 0)?.toFixed(2),
          balance_purchase_price: parseFloat(tmpPayload?.sale_confirmation?.balance_purchase_price ?? 0)?.toFixed(2),
          earnest_deposit_amount: parseFloat( tmpPayload?.earnest_deposit_amount ?? 0)?.toFixed(2)
        }
        
      } else if(payload.type_id === 2) {
        tmpSelectedConfirmationForm = {
          ...tmpSelectedConfirmationForm,
          monthly_rental_amount: parseFloat(tmpPayload?.rent_confirmation.monthly_rental_amount ?? 0)?.toFixed(2),
          advanced_rental_amount: parseFloat(tmpPayload?.rent_confirmation.advanced_rental_amount ?? 0)?.toFixed(2),
          one_month_earnest_deposit: parseFloat(tmpPayload?.rent_confirmation.one_month_earnest_deposit ?? 0)?.toFixed(2),
          security_deposit_amount: parseFloat(tmpPayload?.rent_confirmation.security_deposit_amount ?? 0)?.toFixed(2),
          utility_deposit_amount: parseFloat(tmpPayload?.rent_confirmation.utility_deposit_amount ?? 0)?.toFixed(2),
          stamping_fee: parseFloat(tmpPayload?.rent_confirmation.stamping_fee ?? 0)?.toFixed(2),
          disbursement_amount: parseFloat(tmpPayload?.rent_confirmation.disbursement_amount ?? 0)?.toFixed(2),
          access_key_and_car_park_deposit_amount: parseFloat(tmpPayload?.rent_confirmation.access_key_and_car_park_deposit_amount ?? 0)?.toFixed(2),
          before_handover_amount: parseFloat(tmpPayload?.rent_confirmation.before_handover_amount ?? 0)?.toFixed(2),
          earnest_deposit_amount: parseFloat(tmpPayload?.earnest_deposit_amount ?? 0)?.toFixed(2),
          agency_fee_amount: parseFloat(tmpPayload?.rent_confirmation.agency_fee_amount ?? 0)?.toFixed(2)
        }
        
      }

      delete tmpSelectedConfirmationForm.sale_confirmation;
      delete tmpSelectedConfirmationForm.rent_confirmation;

      tmpSelectedConfirmationForm.property_location =
        tmpPayload.property_location ?? initialPropertyData;
      this.setState({
        selectedPropertyData: tmpSelectedConfirmationForm.property_location,
      });

      for (const name in tmpSelectedConfirmationForm) {
        if (name.includes("date")) {
          tmpSelectedConfirmationForm[name] = dateValidation(
            tmpSelectedConfirmationForm[name],
            "YYYY-MM-DD",
            "DD MMM YYYY",
          );
        }
      }

      return this.setState({
        initialConfirmationForm: tmpSelectedConfirmationForm,
        selectedConfirmationForm: tmpSelectedConfirmationForm,
        tempEProcess: payload.e_process,
        showEditModal: true,
      });
    };
    getConfirmationFormError = (error) => requestError(error);

    getConfirmationForms = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/forms/confirmation_forms?${search}page=${page}`,
        this.getConfirmationFormsSuccess,
        this.getConfirmationFormsError,
        this.load,
      );
    };
    getConfirmationFormsSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];
      if (payload.data && payload.data.length > 0) {
        payload.data.map((item) => {
          let data = {
            ...item,
            confirmation_date: dateValidation(
              item.confirmation_date,
              "YYYY-MM-DD",
              "DD MMM YYYY",
            ),
          };
          tmp.push(data);
        });
        for (let i = 0; i < payload.meta.pages; i++) {
          tmpTotalPages.push(i);
        }
      }
      this.setState({
        confirmationForms: { ...payload, data: tmp },
        totalPages: tmpTotalPages,
      });
    };
    getConfirmationFormsError = (error) => requestError(error);

    getSelectedTownShip = (val) =>
      Get(
        `/townships?state_id=${val}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) =>
      this.setState({
        townshipsList: _.map(payload, (town) => ({
          ...town,
          value: town.name,
        })),
      });
    getSelectedTownShipError = (error) => requestError(error);

    unlockConfirmationForm = (id) =>
      Put(
        `/admin/forms/confirmation_forms/${id}/toggle_status`,
        {},
        this.unlockConfirmationFormSuccess,
        this.unlockConfirmationFormError,
        this.load,
      );
    unlockConfirmationFormSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getConfirmationForms(currentPage, searchParams);
      requestSuccess("Form is updated successfully.");
      this.setState({
        showConfirmToggleStatus: false,
      });
    };
    unlockConfirmationFormError = (error) => {
      requestError(error);
      this.setState({
        showConfirmToggleStatus: false,
      });
    };

    saveTnC = () => {
      if (this.state.selectedConfirmationForm.owners_address !== "") {
        this.props.storeSubsalesAddress(
          this.state.selectedConfirmationForm.owners_address,
        );
      }
      if (this.state.selectedConfirmationForm.buyers_address !== "") {
        this.props.storeSubsalesAddress(
          this.state.selectedConfirmationForm.buyers_address,
        );
      }
      if (this.state.selectedConfirmationForm.property_address !== "") {
        this.props.storeSubsalesAddress(
          this.state.selectedConfirmationForm.property_address,
        );
      }
      if (this.state.selectedConfirmationForm.stake_holder_address !== "") {
        this.props.storeSubsalesAddress(
          this.state.selectedConfirmationForm.stake_holder_address,
        );
      }
      this.updateConfirmationForm({
        ...this.state.selectedConfirmationForm,
        expiration_day:
          this.state.selectedConfirmationForm.balance_pay_within_days,
      });
    };

    updateConfirmationForm = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);

      Put(
        `/admin/forms/confirmation_forms/${temp.id}`,
        temp,
        this.updateConfirmationFormSuccess,
        this.updateConfirmationFormError,
        this.load,
      );
    };
    updateConfirmationFormSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      const tmpPayload = _.cloneDeep(payload);
      let tmpType = ["sale_confirmation", "rent_confirmation"].find((type) => {
        if (tmpPayload[type] && tmpPayload[type] !== null)
          return tmpPayload[type];
        return ''
      });

      let tmpSelectedConfirmationForm = {
        ...tmpPayload,
        ...tmpPayload[tmpType],
        id: payload.id,
        tmp_claimed: tmpPayload.is_claimed,
      };

      delete tmpSelectedConfirmationForm.sale_confirmation;
      delete tmpSelectedConfirmationForm.rent_confirmation;

      for (const name in tmpSelectedConfirmationForm) {
        if (name.includes("date")) {
          tmpSelectedConfirmationForm[name] = dateValidation(
            tmpSelectedConfirmationForm[name],
            "YYYY-MM-DD",
            "DD MMM YYYY",
          );
        }
      }

      this.setState({
        initialConfirmationForm: tmpSelectedConfirmationForm,
        selectedConfirmationForm: tmpSelectedConfirmationForm,
        tempEProcess: payload.e_process,
      });
      this.getConfirmationForms(currentPage, searchParams);
      this.getConfirmationForm(payload.id);
      return requestSuccess("Confirmation form updated successfully.");
    };
    updateConfirmationFormError = (error) => requestError(error);

    claimedConfirmationForm = () =>
      Put(
        `/admin/forms/confirmation_forms/${this.state.selectedConfirmationForm.id}/toggle_is_claimed`,
        {},
        this.claimedConfirmationFormSuccess,
        this.claimedConfirmationFormError,
        this.load,
      );
    claimedConfirmationFormSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      Promise.all([
        this.getConfirmationForms(currentPage, searchParams),
        this.getConfirmationForm(this.state.selectedConfirmationForm.id),
      ]).then(() => {
        requestSuccess(
          this.state.selectedConfirmationForm.is_claimed
            ? "Confirmation form marked as submitted to claim."
            : "Confirmation form marked as pending to claim.",
        );
      });
    };
    claimedConfirmationFormError = (error) => requestError(error);

    cloneConfirmationForm = (id) =>
      Post(
        `/admin/forms/confirmation_forms/${id}/copy`,
        "",
        this.cloneConfirmationFormSuccess,
        this.cloneConfirmationFormError,
        this.load,
      );
    cloneConfirmationFormSuccess = payload => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.getConfirmationForms(currentPage, searchParams);
      this.getConfirmationForm(payload.id)
      requestSuccess("New form has been created successfully");

      this.setState({
        showCloneConfirmationModal: false,
      });
    };
    cloneConfirmationFormError = (error) => requestError(error);

    updateInventoryList = (dataToSubmit) =>
      Put(
        `/admin/forms/confirmation_forms/${dataToSubmit.id}/update_inventory`,
        dataToSubmit,
        (payload) => this.updateInventoryListSuccess(payload, dataToSubmit),
        this.updateInventoryListError,
        this.load,
      );
    updateInventoryListSuccess = (payload, dataToSubmit) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      let tmpSelectedConfirmationForm = {
        ...this.state.selectedConfirmationForm,
        include_inventory: payload.rent_confirmation.include_inventory,
        inventory_groups: payload.rent_confirmation.inventory_groups,
      };
      this.setState({
        initialConfirmationForm: tmpSelectedConfirmationForm,
        selectedConfirmationForm: tmpSelectedConfirmationForm,
      });
      this.getConfirmationForms(currentPage, searchParams);
      if (dataToSubmit.inventory_groups) {
        requestSuccess("Inventory List saved successfully.");
      }
    };
    updateInventoryListError = (error) => requestError(error);

    downloadConfirmationForm = (id, filename) =>
      GetFile(
        `/admin/forms/confirmation_forms/${id}/download?with_data=true`,
        filename,
        () => {},
        this.downloadConfirmationFormError,
        this.load,
      );
    downloadConfirmationFormError = (error) => requestError(error);

    onChangeConfirmationFormValue = (title, context) => {
      let tmp = _.cloneDeep(this.state.selectedConfirmationForm);
      tmp[title] = context;
      return this.setState({ selectedConfirmationForm: tmp });
    };

    downloadAckReceipt = (id, filename) =>
      GetFile(
        `/admin/forms/confirmation_forms/${id}/receipt`,
        filename,
        () => {},
        this.downloadAckReceiptError,
        this.load,
      );
    downloadAckReceiptError = (error) => requestError(error);

    createConfirmationForm = (dataToSubmit) =>
      Post(
        `/admin/forms/confirmation_forms`,
        dataToSubmit,
        this.createConfirmationFormSuccess,
        this.createConfirmationFormError,
        this.load,
      );
    createConfirmationFormSuccess = (payload) => {
      this.setState({
        showCreationForm: false,
        disableSubmitButton: false,
      });
      this.getConfirmationForms();
      this.getConfirmationForm(payload.id);
      window.location.href.includes("/admin-impersonate")
        ? this.props.getCurrentSignInProfile()
        : this.props.getCurrentUserProfile();
    };
    createConfirmationFormError = (error) => {
      requestError(error);
      this.setState({ disableSubmitButton: false });
    };

    resetDownloadCount = (id) =>
      Put(
        `/admin/forms/confirmation_forms/${id}/reset_download_count`,
        this.state.resetContent,
        this.resetDownloadCountSuccess,
        this.resetDownloadCountError,
        this.load,
      );
    resetDownloadCountSuccess = (payload) => {
      this.setState({
        showResetConfirmation: false,
        resetContent: {
          confirmation_form: false,
          temp_receipt: false,
        },
      });
      requestSuccess(payload.message);
    };
    resetDownloadCountError = (error) => requestError(error);

    getAgentsBySearch = (val) =>
      Get(
        `/admin/users/search?type=1&name=${val}`,
        this.getAgentsBySearchSuccess,
        this.getAgentsBySearchError,
        this.load,
      );
    getAgentsBySearchSuccess = (payload) =>
      this.setState({ agentList: payload.agent_selections });
    getAgentsBySearchError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            searchParams={this.state.searchParams}
            agentList={this.state.agentList}
            contactList={this.state.contactList}
            tempEProcess={this.state.tempEProcess}
            confirmationForms={this.state.confirmationForms}
            onLoadConfirmationForms={this.state.loading}
            showEditModal={this.state.showEditModal}
            showConfirmToggleStatus={this.state.showConfirmToggleStatus}
            purchaserNTenant={this.state.purchaserNTenant}
            disableSubmitButton={this.state.disableSubmitButton}
            initialConfirmationForm={this.state.initialConfirmationForm}
            selectedConfirmationForm={this.state.selectedConfirmationForm}
            showSpecialConditionRTE={this.state.showSpecialConditionRTE}
            showCloneConfirmationModal={this.state.showCloneConfirmationModal}
            showDownloadConfirmation={this.state.showDownloadConfirmation}
            showResetConfirmation={this.state.showResetConfirmation}
            selectedCloneId={this.state.selectedCloneId}
            showCreationForm={this.state.showCreationForm}
            totalPages={this.state.totalPages}
            resetContent={this.state.resetContent}
            selectedDownloadId={this.state.selectedDownloadId}
            selectedPropertyData={this.state.selectedPropertyData}
            townshipsList={this.state.townshipsList}
            showSubmitClaimForm={this.state.showSubmitClaimForm}
            showSubmitConfirmationClaimForm={
              this.state.showSubmitConfirmationClaimForm
            }
            showDetachConfirmation={this.state.showDetachConfirmation}
            closeSubmitConfirmation={this.closeSubmitConfirmation}
            saveTnC={this.saveTnC}
            resetDownloadCount={this.resetDownloadCount}
            updateInventoryList={this.updateInventoryList}
            createConfirmationForm={this.createConfirmationForm}
            getCurrentFormContactList={this.getCurrentFormContactList}
            downloadAckReceipt={this.downloadAckReceipt}
            downloadConfirmationForm={this.downloadConfirmationForm}
            cloneConfirmationForm={this.cloneConfirmationForm}
            claimedConfirmationForm={this.claimedConfirmationForm}
            getConfirmationForms={this.getConfirmationForms}
            updateConfirmationForm={this.updateConfirmationForm}
            unlockConfirmationForm={this.unlockConfirmationForm}
            onChangeConfirmationFormValue={this.onChangeConfirmationFormValue}
            onChangeConfirmationFormHOC={this.onChangeConfirmationFormHOC}
            getAgentsBySearch={this.getAgentsBySearch}
            getConfirmationForm={this.getConfirmationForm}
            getSelectedTownShip={this.getSelectedTownShip}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    refreshToken,
    getAllDictionaryData,
    storeSubsalesAddress,
    getInvoicingBranches,
    getCurrentUserProfile,
    getCurrentSignInProfile,
  })(ConfirmationFormsHOC);
};

export default ConfirmationForms;
