import { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
);

const CustomFileInput = ({
  acceptedFileTypes,
  maxFiles,
  maxFileSize,
  onChangeFile,
}) => {
  const [files, setFiles] = useState([]);

  const handleUpdate = (fileItems) => {
    setFiles(fileItems.map((fileItem) => fileItem.file));

    if (
      fileItems.length === 1 &&
      fileItems[0].fileSize <= maxFileSize &&
      acceptedFileTypes.includes(fileItems[0].fileType)
    ) {
      onChangeFile(fileItems[0].file);
    } else {
      onChangeFile(null);
    }
  };

  return (
    <FilePond
      allowDrop={true}
      allowFileSizeValidation={true}
      allowFileTypeValidation={true}
      allowMultiple={maxFiles > 1}
      maxFiles={maxFiles}
      maxFileSize={maxFileSize}
      acceptedFileTypes={acceptedFileTypes}
      files={files}
      onupdatefiles={handleUpdate}
    />
  );
};

export default CustomFileInput;
