import React, { Component } from "react";
import _ from "lodash";

import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      selectedConfirmationHistory: [],
      selectedReceiptHistory: [],
    };

    load = (param) => this.setState({ loading: param });

    getDownloadHistory = (id) =>
      Get(
        `/admin/forms/confirmation_forms/${id}/forms_download_details`,
        this.getDownloadHistorySuccess,
        this.getDownloadHistoryError,
        this.load,
      );
    getDownloadHistorySuccess = ({ forms_downloads = [] }) => {
      let tempConfirmationLetter = [];
      let tempReceipt = [];

      forms_downloads.map((item) => {
        item.type_id === 1 &&
          tempConfirmationLetter.push({
            ...item,
            count: tempConfirmationLetter.length + 1,
          });

        item.type_id === 2 &&
          tempReceipt.push({
            ...item,
            count: tempReceipt.length + 1,
          });
      });
      this.setState({
        selectedConfirmationHistory: tempConfirmationLetter,
        selectedReceiptHistory: tempReceipt,
      });
    };
    getDownloadHistoryError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadHistory={this.state.loading}
            selectedReceiptHistory={this.state.selectedReceiptHistory}
            selectedConfirmationHistory={this.state.selectedConfirmationHistory}
            getDownloadHistory={this.getDownloadHistory}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
