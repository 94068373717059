import React, { useState, useRef, useEffect } from "react";
import Moment from "moment";
import _ from "lodash";
import { HiCheck } from "react-icons/hi";
import DatePicker from "react-datepicker";
import { MenuItem } from "@material-ui/core";

import CustomButton from "components/Button";
import CustomSelect from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import StepIndicator from "components/StepIndicator";
import AtlasTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";

const bankOptions = [
  { label: "MBB - Maybank 564164441931 IQI REALTY SDN. BHD.", value: 1 },
  { label: "PBB - Public Bank 3197608334 IQI REALTY SDN. BHD.", value: 6 },
];

const UploadBankDetail = ({
  onLoadOCR,
  OCRContent,
  selectedDocType,
  selectedDocument,
  selected_claim_id,
  onLoadSubsaleClaims,

  onClickClose,
  bankInSlipOCR,
  uploadDocument,
  updateBankInSlip,
}) => {
  const mode = _.isEmpty(selectedDocument) ? "new" : "existing";
  const [step, setStep] = useState("1");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bankSlip, setBankSlip] = useState({
    date: "",
    amount: 0,
    bank_id: "",
    reference_no: "",
    snapshot: "",
    snapshot_file_name: "",
  });

  useEffect(() => {
    let selectedBankSlip = selectedDocument.bank_in_detail;
    if (selectedBankSlip) {
      setStep("2");
      setBankSlip({
        date: Moment(selectedBankSlip.transacted_at).format("DD-MM-YYYY"),
        amount: `${selectedBankSlip.amount}`,
        bank_id: selectedBankSlip.bank_id,
        reference_no: selectedBankSlip.reference_no,
        snapshot: null,
        snapshot_file_name: selectedDocument.snapshot_file_name,
      });
    }
  }, []);

  useEffect(() => {
    if ((mode === "new" || bankSlip.snapshot) && !_.isEmpty(OCRContent)) {
      let temp = _.cloneDeep(bankSlip);
      setBankSlip({
        ...temp,
        amount: OCRContent.amount[0],
        bank_id: OCRContent.beneficiary.details[0]
          ? _.find(bankOptions, { label: OCRContent.beneficiary.details[0] })
              .value
          : "",
        reference_no: OCRContent.remark.toString().replace(",", ", "),
      });
    }
  }, [OCRContent]);

  const onChangeField = (context, val) => {
    let tmp = _.cloneDeep(bankSlip);
    tmp[context] = val;
    setBankSlip(tmp);
  };

  const uploadFile = (fileItems) => {
    if (fileItems) {
      Object.keys(fileItems).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let tmp = _.cloneDeep(bankSlip);
          tmp.snapshot = e.target.result;
          tmp.snapshot_file_name = fileItems[key].name;
          setBankSlip(tmp);
          bankInSlipOCR({ file: e.target.result }, () => setStep("2"));
        };
        reader.readAsDataURL(fileItems[key]);
      });
    }
  };

  const reUploadDocument = () => {
    setStep("1");
    setBankSlip({
      date: "",
      amount: 0,
      bank_id: "",
      reference_no: "",
      snapshot: "",
      snapshot_file_name: "",
    });
  };

  const onClickSubmit = () => {
    Promise.all([onClickClose()]).then(() => {
      mode === "new"
        ? uploadDocument(selected_claim_id, {
            ...bankSlip,
            transacted_at: Moment(bankSlip.date, "DD-MM-YYYY HH:mm").toString(),
            type_id: selectedDocType,
          })
        : updateBankInSlip(selected_claim_id, selectedDocument.id, bankSlip);
    });
  };

  let filePond = useRef(null);

  return (
    <ModalDialog
      title={"Upload Bank in Slip"}
      onLoad={onLoadOCR}
      onClose={onClickClose}
      footer={
        <div className="d-flex g-3">
          <CustomButton
            disabled={_.values(bankSlip).some(
              (item, index) => (item === "" || item === 0) && index !== 3,
            )}
            className={"btn-new btn-new--primary"}
            children={"Submit"}
            onClick={() => setShowConfirmation(true)}
          />
          <CustomButton
            className="btn-new btn-new--secondary"
            children={"Re-upload"}
            onClick={() => reUploadDocument()}
          />
          <CustomButton
            className="btn-new btn-new--outline-secondary"
            children={"Close"}
            onClick={() => onClickClose()}
          />
        </div>
      }
    >
      <>
        <div className="at-bankslip__container">
          <StepIndicator
            mode={"gradual"}
            progress={step}
            hideLabel={true}
            stepList={["1", "2"]}
            onClick={() => {}}
            lineStyle={step === "2" ? { backgroundColor: "#3B82F6" } : {}}
            containerStyle={{ width: 300, margin: "auto" }}
            renderStepIcon={(item) => {
              if (item === step) {
                return (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      background: "#3B82F6",
                      borderRadius: "50%",
                    }}
                  ></div>
                );
              } else if (item < step) {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#3B82F6",
                      borderRadius: "50%",
                    }}
                  >
                    <HiCheck style={{ width: 32, height: 32, padding: 6 }} />
                  </div>
                );
              }
            }}
          />
          {step === "1" && (
            <>
              <div
                className="d-flex align-items-center grid_gap-2"
                style={{ fontSize: 16, fontWeight: 500 }}
              >
                <div className="at-badge-icon at-badge-icon-primary">
                  <AtlasIcon svgHref={"atlas-receipt-1"} />
                </div>
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "150%",
                      color: "#1D4ED8",
                    }}
                  >
                    Step 1
                  </p>
                  Upload File
                </div>
              </div>
              <p style={{ color: "#374151", fontSize: 14, margin: "16px 0px" }}>
                Upload a clear scan or picture of the transaction/bank-in slip
                and Atlas will use new AI technology to automatically fill in
                the details. Click Upload File and upload the PDF or image file.
              </p>
              <div className="at-bankslip__upload">
                <div className="at-badge-icon at-badge-icon-warning m-auto">
                  <AtlasIcon svgHref={"atlas-receipt-1"} />
                </div>
                {bankSlip.snapshot && bankSlip.snapshot_file_name && (
                  <p
                    style={{ color: "#111827", fontSize: 14, fontWeight: 500 }}
                  >
                    {bankSlip.snapshot_file_name}
                  </p>
                )}
                {!(bankSlip.snapshot && bankSlip.snapshot_file_name) && (
                  <>
                    <div>
                      <p
                        style={{
                          color: "#111827",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        Single Transaction Bank in Slip
                      </p>
                      <p style={{ color: "#374151", fontSize: 14 }}>
                        PNG, JPG, JPEG, JFIF or PDF
                      </p>
                    </div>
                    <input
                      ref={(ref) => (filePond = ref)}
                      type="file"
                      style={{ display: "none" }}
                      accept="image/jpeg, image/jpg, image/png, application/pdf"
                      onChange={(e) => uploadFile(e.target.files)}
                    />
                    <button
                      className="btn-new btn-new--secondary m-auto"
                      onClick={() => filePond.click()}
                    >
                      Upload a file
                    </button>
                  </>
                )}
              </div>
            </>
          )}
          {step === "2" && (
            <>
              <div
                className="d-flex align-items-center grid_gap-2"
                style={{ fontSize: 16, fontWeight: 500 }}
              >
                <div className="at-badge-icon at-badge-icon-primary">
                  <AtlasIcon svgHref={"atlas-task-square"} />
                </div>
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "150%",
                      color: "#1D4ED8",
                    }}
                  >
                    Step 2
                  </p>
                  Payment Information
                </div>
              </div>
              <p style={{ color: "#374151", fontSize: 14, margin: "16px 0px" }}>
                Kindly check the auto-filled information and edit as needed if
                any of it is incorrect.
              </p>
              <div className="mb-3">
                <label className="at-form-input__title" required>
                  Uploaded File
                </label>
                <div
                  className="d-flex align-items-center  grid_gap-2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  <div className="at-badge-icon at-badge-icon-warning">
                    <AtlasIcon svgHref={"atlas-receipt-1"} />
                  </div>
                  <p style={{ fontSize: 12, lineHeight: "150%" }}>
                    {bankSlip.snapshot_file_name}
                  </p>
                </div>
              </div>
              <div style={{ display: "grid", gridGap: 10 }}>
                <section>
                  <label className="at-form-input__title" required>
                    Payment Date
                  </label>
                  <div className="at-form-input">
                    <AtlasIcon
                      className={"at-form-input__calendar"}
                      svgHref={"atlas-calendar-input"}
                    />
                    <DatePicker
                      withPortal
                      dateFormat={"DD-MM-YYYY"}
                      className="at-form-input__input"
                      maxDate={Moment()}
                      placeholderText="Please enter date"
                      value={bankSlip.date}
                      onChange={(val) =>
                        onChangeField("date", Moment(val).format("DD-MM-YYYY"))
                      }
                    />
                  </div>
                </section>
                <section>
                  <label className="at-form-input__title" required>
                    Bank In To
                  </label>
                  <CustomSelect
                    required={true}
                    value={bankSlip.bank_id}
                    placeholder={
                      "Select the bank to which client has made the transfer"
                    }
                    onChange={(e) => onChangeField("bank_id", e.target.value)}
                  >
                    {bankOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{ fontSize: 14 }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </section>
                <section>
                  <label className="at-form-input__title" required>
                    Amount
                  </label>
                  <CustomTypeahead
                    typeaheadId={"bankslip_amount"}
                    options={OCRContent.amount || [bankSlip.amount]}
                    selectedValue={bankSlip.amount ? [bankSlip.amount] : []}
                    onSearch={(val) => onChangeField("amount", val)}
                    onSelect={(val) =>
                      val && val.length > 0 && onChangeField("amount", val[0])
                    }
                    childrenHead={(rowItem) => <p>{rowItem}</p>}
                  />
                </section>
                <section>
                  <label className="at-form-input__title" required>
                    Reference
                  </label>
                  <AtlasTextarea
                    className={"mb-0"}
                    required={true}
                    value={bankSlip.reference_no}
                    onChangeValue={(val) => onChangeField("reference_no", val)}
                    instruction={
                      "Please state payment reference for (instant transfer, foreign transfer & IBG)"
                    }
                  />
                </section>
              </div>
            </>
          )}
        </div>
        <ConfirmationModal
          open={showConfirmation}
          title={"Confirm uploading bank in slip?"}
          message={
            <div>
              <p className="fs-2">
                Kindly ensure that only (1) bank-in slip is attached per upload.
              </p>
              <br />
              <p className="fs-2">
                If you have more than 1 slip, please repeat the step to upload
                another after upload this has been successfully submitted for
                review.
              </p>
              <br />
              <p className="fs-2">Are you sure you want to continue upload now?</p>
            </div>
          }
          loading={onLoadSubsaleClaims}
          positiveAction={() => onClickSubmit()}
          negativeAction={() => setShowConfirmation(false)}
        />
      </>
    </ModalDialog>
  );
};

export default UploadBankDetail;
