import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "../../assets";
import "../../index.scss";

const StepCounters = ({ bodyContentType, modalSequenceLibrary, language }) => {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} className="step-counters-stepper-header">
        <Typography className={bodyContentType === 0 ? "step-counters-stepper-header-active" : ""} >
          {getTranslation("agent.profile.title1", language)}
        </Typography>
        <i className="fa fa-angle-right at-header__angle" />
        <Typography className={bodyContentType === 1 ? "step-counters-stepper-header-active" : ""} >
          {getTranslation("agent.profile.title2", language)}
        </Typography>
        <i className="fa fa-angle-right at-header__angle" />
        <Typography className={bodyContentType === 2 ? "step-counters-stepper-header-active" : "" } >
          {getTranslation("agent.profile.title3", language)}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <div style={{ fontSize: "12px", marginTop: "15px", marginBottom: "5px" }}>
          {getTranslation("step", language)} {bodyContentType + 1}
        </div>
        <div style={{ fontSize: "24px", fontWeight: "600", display: "block" }}>
          {getTranslation(_.find(modalSequenceLibrary, {id: bodyContentType + 1,}).label, language)}
        </div>
        <div className="register-stepper-icon-container">
          <AtlasIcon
            svgHref={"atlas-user"}
            className={"register-stepper-atlas-icon"}
            style={{ display: bodyContentType !== 0 && "none" }}
          />
          <AtlasIcon
            svgHref={"clipboard-tick"}
            className={"register-stepper-atlas-icon"}
            style={{ display: bodyContentType !== 1 && "none" }}
          />
          <AtlasIcon
            svgHref={"atlas-document-upload"}
            className={"register-stepper-atlas-icon"}
            style={{ display: bodyContentType !== 2 && "none" }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default StepCounters;
