import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { getCountryDictionary } from "actions/dictionary";

import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [
  {
    label: "Title",
    value: "title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Project",
    value: "project_name_or_project_internal_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "2", label: "Published" },
      { value: "1", label: "Draft" },
    ],
  },
  {
    label: "Updated Date from",
    value: "mg_updated_at_dategteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
  {
    label: "Updated Date to",
    value: "mg_updated_at_datelteq",
    type: "date",
    format: "YYYY-MM-DD",
    param: "",
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      projectLoading: false,

      searchParams: searchParams,
      selectedTemplate: {},
      projectList: [],
      videoTemplate: "",
      videoTemplatePage: null,

      showGenerateName: false,
      showVideoPlayer: false,
      showUpdateModal: false,
    };

    load = (param) => this.setState({ loading: param });
    projectLoad = (param) => this.setState({ projectLoading: param });

    onChangeVideoTemplateHOC = (val, context) =>
      this.setState({ [context]: val });

    getVideoTemplate = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/video_templates?${search}page=${page}`,
        this.getVideoTemplateSuccess,
        this.getVideoTemplateError,
        this.load,
      );
    };
    getVideoTemplateSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      this.setState({
        videoTemplate: payload,
        videoTemplatePage: tmpTotalPages,
      });
    };
    getVideoTemplateError = (error) => requestError(error);

    getSelectedVideoTemplate = (id) =>
      Get(
        `/admin/video_templates/${id}`,
        this.getSelectedVideoTemplateSuccess,
        this.getSelectedVideoTemplateError,
        this.load,
      );
    getSelectedVideoTemplateSuccess = (payload) =>
      this.setState({ selectedTemplate: payload, showUpdateModal: true });
    getSelectedVideoTemplateError = (error) => requestError(error);

    updateVideoTemplate = (dataToSubmit) => {
      let body = new FormData();
      if (dataToSubmit.change_image) {
        body.append("thumbnail", dataToSubmit.thumbnail_url);
        body.append("thumbnail_file_name", dataToSubmit.thumbnail_name);
      }
      body.append("title", dataToSubmit.title || "");
      body.append("description", dataToSubmit.description || "");
      body.append("status_id", dataToSubmit.status_id);
      body.append("country_id", dataToSubmit.country_id || 1);
      body.append("exclude_logo", dataToSubmit.exclude_logo);
      body.append(
        "exclude_property_details",
        dataToSubmit.exclude_property_details,
      );
      body.append("url", dataToSubmit.url);
      body.append("mg_filename", dataToSubmit.mg_filename);

      Put(
        `/admin/video_templates/${dataToSubmit.id}`,
        body,
        this.updateVideoTemplateSuccess,
        this.updateVideoTemplateError,
        this.load,
      );
    };
    updateVideoTemplateSuccess = (payload) => {
      requestSuccess("Successfully Updated");
      this.getVideoTemplate(1, "");
      this.setState({ showUpdateModal: false });
    };
    updateVideoTemplateError = (error) => requestError(error);

    getProjectListing = (project) =>
      Get(
        `/projects/active_project_listings?q[internal_name_cont]=${project}&is_searching=1`,
        this.getProjectListingSuccess,
        this.getProjectListingError,
        this.projectLoad,
      );
    getProjectListingSuccess = (payload) =>
      this.setState({ projectList: payload.projects });
    getProjectListingError = (error) => requestError(error);

    syncVideoTemplate = () =>
      Post(
        `/admin/video_templates/sync`,
        {},
        this.syncVideoTemplateSuccess,
        this.syncVideoTemplateError,
        this.load,
      );
    syncVideoTemplateSuccess = (payload) => {
      let lastView = this.props.data.lastViewReducer.lastView;
      this.getVideoTemplate(lastView.currentPage, lastView.searchParams);
    };
    syncVideoTemplateError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onVideoTemplateLoad={this.state.loading}
            onProjectListLoad={this.state.projectLoading}
            searchParams={this.state.searchParams}
            videoTemplate={this.state.videoTemplate}
            videoTemplatePage={this.state.videoTemplatePage}
            selectedTemplate={this.state.selectedTemplate}
            projectList={this.state.projectList}
            showGenerateName={this.state.showGenerateName}
            showVideoPlayer={this.state.showVideoPlayer}
            showUpdateModal={this.state.showUpdateModal}
            syncVideoTemplate={this.syncVideoTemplate}
            getProjectListing={this.getProjectListing}
            getSelectedVideoTemplate={this.getSelectedVideoTemplate}
            updateVideoTemplate={this.updateVideoTemplate}
            getVideoTemplate={this.getVideoTemplate}
            onChangeVideoTemplateHOC={this.onChangeVideoTemplateHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    getCountryDictionary,
  })(WithHOC);
};

export default HOC;
