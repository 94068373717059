import React from "react";
import AtlasIcon from "../Icon/atlasIcon";

import "./header.scss";

const ModuleFormHeader = ({ title, className, moduleIcon }) => {
  return (
    <div className={`at_form_header--cont ${className}`}>
      <div className={"form-header-icon"}>
        <AtlasIcon svgHref={moduleIcon || "atlas-note"} />
      </div>
      <p>{title}</p>
    </div>
  );
};

export default ModuleFormHeader;
