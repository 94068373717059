import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import LoadingModal from "components/LoadingModal";
import CreatePaperForm from "./components/Create";
import UpdatePaperForm from "./components/Update";
import ModalDialog from "components/Modal/ModalDialog";

import PaperFormHOC from "./actions";
import FormManagementHOC from "../actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import { initGA } from "utils/ga";

export const StatusColor = [
  { value: "Lost", colorName: "Grey" },
  { value: "Submitted", colorName: "Green" },
  { value: "Taken", colorName: "Red" },
];

const ColumnData = [
  {
    header: "Form No.",
    accessor: "tracking_number",
  },
  {
    header: "Agent",
    renderColumn: ({ agent }) => (
      <>
        <p>{agent.full_name || "N/A"}</p>
        <p>{agent.mobile_contact_number || "N/A"}</p>
        <p>{agent.email || "N/A"}</p>
        <p>{agent.team_name || "N/A"}</p>
      </>
    ),
    columnStyle: { width: "130%" },
  },
  { header: "Type", accessor: "type", columnStyle: { width: "80%" } },
  { header: "Form type", accessor: "form_type" },
  { header: "Date Taken", accessor: "date_taken" },
  {
    header: "Created By",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: ({ created_by_admin }) => {
      return <span>{created_by_admin || "N/A"}</span>;
    },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: ({ status }) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: status || "" }) || {};

      return (
        <div
          className="at-status_badge mt-1"
          style={{ ...getColorBadge(colorName) }}
        >
          {status}
        </div>
      );
    },
  },
  {
    header: "Claim Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: ({ claim_status }) => {
      const { colorName = "" } = _.find(legendsArray, { desc: claim_status });
      return (
        <div className={"d-flex justify-content-center"}>
          <div
            className="at-status_badge mt-1"
            style={{ ...getColorBadge(colorName) }}
          >
            {claim_status}
          </div>
        </div>
      );
    },
  },
];

const legendsArray = [
  {
    colorName: "Blue",
    colorCode: "#3498db",
    desc: "Pending",
  },
  {
    colorName: "Green",
    colorCode: "#fad25a",
    desc: "Submitted",
  },
  {
    colorName: "Grey",
    colorCode: "#d3d3d3",
    desc: "Form Cancelled",
  },
];

class PaperFormManagement extends Component {
  componentDidMount = () => {
    this.props.getPaperForms(1, "");
    initGA("/dashboard/admin/forms/paper-forms-management");

    const id = this.props.history?.location?.state?.id;
    if (id) {
      this.props.getSelectedPaperForm(id);
    }
  };

  editClick = (val) => this.props.getSelectedPaperForm(val.id);

  renderUpdate = () => (
    <AtlasDialog open={this.props.showUpdate}>
      <CustomCard
        className={"h-100"}
        cardContent={
          <UpdatePaperForm
            {...this.props}
            history={this.props.history}
            permissionsChecker={permissionsChecker("Forms", this.props.data)}
            selectedPaperForm={this.props.selectedPaperForm}
            showDetachConfirmation={this.props.showDetachConfirmation}
            onLoadPaperForms={this.props.onLoadPaperForms}
            onLoadForms={this.props.onLoadForms}
            onClose={() => this.props.onChangePaperFormHOC("showUpdate", false)}
            updatePaperForm={this.props.updatePaperForm}
            detachForm={this.props.detachForm}
            onChangePaperFormHOC={this.props.onChangePaperFormHOC}
          />
        }
      />
    </AtlasDialog>
  );

  renderCreate = () => this.props.showCreate && (
    <ModalDialog
      title={"New Form"}
      fullWidth={true}
      responsiveSize={"lg"}
      children={
        <CreatePaperForm
          onLoadPaperForms={this.props.onLoadPaperForms}
          onChangePaperFormHOC={this.props.onChangePaperFormHOC}
          createPaperForm={this.props.createPaperForm}
          onClose={() => this.props.onChangePaperFormHOC("showCreate", false)}
        />
      }
      onClose={() => this.props.onChangePaperFormHOC("showCreate", false)
      }
    />
  );

  renderCardContent = () => {
    const { can_update, can_create } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={"Other Forms"}
          atlasIcon={"atlas-document-sketch"}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
              ),
              onClick: () =>
                this.props.onChangePaperFormHOC("showCreate", true),
            },
          ]}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <AtPaginatedTable
              columns={ColumnData}
              rowData={
                this.props.paperForms.data ? this.props.paperForms.data : []
              }
              meta={this.props.paperForms.meta && this.props.paperForms.meta}
              actionColumnContent={[
                {
                  name: can_update ? "edit" : "view",
                  onClick: (rowData) =>
                    this.props.getSelectedPaperForm(rowData.id),
                },
              ]}
              searchParams={this.props.searchParams}
              onChangeSearchParams={(val) =>
                this.props.onChangePaperFormHOC("searchParams", val)
              }
              getListAPI={this.props.getPaperForms}
              totalPages={this.props.paperFormPages}
            />
          }
        />
        {this.props.onLoadPaperForms && <LoadingModal />}
      </>
    );
  };

  render = () => {
    return (
      <>
        {this.renderCardContent()}
        {this.renderCreate()}
        {this.renderUpdate()}
      </>
    );
  };
}

export default compose(
  PaperFormHOC,
  FormManagementHOC,
  VersionCheckHOC,
)(PaperFormManagement);
