import { useState } from "react";

import AtlasCloseButton from "components/Button/prev";
import Tabs from "components/Tab";
import Activity from "./Activity";
import Summary from "./Summary";

const Preview = ({ selectedEmailCampaign, onChangeEmailCampaignsHOC }) => {
  const [activeTab, setActiveTab] = useState("Summary");

  const handleClose = () => {
    setActiveTab("Summary");
    onChangeEmailCampaignsHOC("emailCampaignDialog", "");
    onChangeEmailCampaignsHOC("selectedCampaignDialog", {});
  };

  return (
    <div className={"at-email-campaigns__preview-campaign"}>
      <div className={"at-card__header"}>
        <AtlasCloseButton onClick={handleClose} />
        <h5 className={"at-card__title"}>{selectedEmailCampaign.name}</h5>
      </div>

      <Tabs
        containerClass={"at-email-campaigns__tabs"}
        sections={["Summary", "Activity"]}
        selectedSection={activeTab}
        onSelectSection={setActiveTab}
      />

      {activeTab === "Summary" && (
        <Summary selectedEmailCampaign={selectedEmailCampaign} />
      )}

      {activeTab === "Activity" && (
        <Activity selectedEmailCampaign={selectedEmailCampaign} />
      )}
    </div>
  );
};

export default Preview;
