import React, { useEffect, useState } from "react";
import ReactHTMLParser from "react-html-parser";

import CKEditor from "components/CKEditor";
import CustomCard from "components/Card";
import AtlasCloseButton from "components/Button/prev";
import AtlasButton from "components/Button";

const TncModal = ({
  mode,
  selectedEvent = "",
  onClose,
  onClickPreview,
  onSave,
}) => {
  const { terms_and_conditions, enable_payment_gateway, enable_bank_in } =
    selectedEvent;

  const [tncText, onChangeTncText] = useState("");

  useEffect(() => {
    onChangeTncText(terms_and_conditions);
  }, [terms_and_conditions]);

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={onClose} />
        <h4 className="at-card__title">
          {mode === "edit" ? "Edit " : ""}
          Terms and Conditions
        </h4>
      </div>
      {mode === "edit" && (
        <CKEditor
          data={terms_and_conditions}
          onChange={(event, editor) => {
            const data = editor.getData();

            onChangeTncText(data);
          }}
        />
      )}
      {mode === "preview" && (
        <CustomCard
          className="h-100 bg-white"
          cardContent={<div>{ReactHTMLParser(terms_and_conditions)}</div>}
        />
      )}
      <div className="d-flex mt-3">
        {mode === "edit" && (
          <>
            <AtlasButton
              className={`btn-new btn-new--success mr-2`}
              onClick={() =>
                onSave({
                  id: selectedEvent.id,
                  is_payment_needed: true,
                  enable_payment_gateway,
                  enable_bank_in,
                  terms_and_conditions: tncText,
                })
              }
              children={"Save"}
            />
            <AtlasButton
              className={"btn-new btn-new--secondary mr-2"}
              onClick={() => onClickPreview()}
              children={"Preview"}
            />
          </>
        )}
        <AtlasButton
          className={"btn-new btn-new--outline-secondary"}
          onClick={() => onClose()}
          children={"Close"}
        />
      </div>
    </>
  );
};

export default TncModal;
