import { Dialog } from "@material-ui/core";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import LoadingModal from "components/LoadingModal";
import BackgroundLoader from "components/LoadingModal/background";
import AppointmentLetterModal from "components/Modal/appointment_letter";
import NotificationDetailsModal from "components/Modal/notificationDetails";
import MotdOverlay from "components/MotdModal";
import Sidebar from "components/Sidebar";
import MobileSidebar from "components/Sidebar/mobile";
import Topbar from "components/Topbar";
import TrainingStatusModal from "components/TrainingStatusModal";
import WelcomeModal from "components/WelcomeModal";

import { getMyCart } from "actions/myCart";
import { toggleSideMenu } from "actions/sideMenu";
import { getPermissions } from "actions/permissions";
import { updateAppSetting } from "actions/appSetting";
import { updateLastOpenMotd } from "actions/lastOpenMotd";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import AnnouncementHOC from "actions/announcement";
import NotificationHOC from "actions/notification";
import adminLogOut from "actions/adminLogOut";
import WithLogout from "actions/logout";
import WithToken from "actions/token";
import MotdHOC from "actions/motd";

import { requestError, requestSuccess } from "utils/requestHandler";
import { clearItem } from "utils/tokenStore";

class DashboardTemplate extends Component {
  state = {
    from: "",
    onLoadImpersonateProfile: false,
    ajaxCallProgress: "",
    loading: false,
    isOpenReleaseModal: false,
    scrollTop: 0,
  };

  componentDidMount = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.dashboardRef = createRef();
    this.props.history.listen((location, action) => {
      let pathName = location.pathname;
      if (pathName.includes("/register/ref=")) {
        document.cookie = "";
        clearItem("IQI_ATLAS_JWT_TOKEN");
        clearItem("IQI_ATLAS_JWT_AGENT_TOKEN");
      }
    });
    this.props.getMOTD();
    this.props.getUnreadNotifications(1);
    if (window.location.href.includes("/admin-impersonate")) {
      this.props.getCurrentSignInProfile();
      this.setState({
        from: "IMPERSONATE_BEGIN",
        onLoadImpersonateProfile: true,
      });
    }
    tmpProfileReducer.role === "Agent" && this.props.getMyCart(1, "");
  };

  componentDidUpdate = (prevProps, prevState) => {
    let {
      ajaxCallProgress,
      ajaxCallProgressImpersonate,
      ajaxSuccessImpersonate,
      ajaxErrorImpersonate,
      ajaxErrorMessageImpersonate,
    } = this.props.data.ajaxStatusReducer;

    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const profileChecking = window.location.href.includes("./admin-impersonate")
      ? this.props.data.currentSignInProfileReducer !==
        prevProps.data.currentSignInProfileReducer
      : this.props.data.profileReducer !== prevProps.data.profileReducer;

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    if (
      ajaxCallProgress !== prevProps.data.ajaxStatusReducer.ajaxCallProgress
    ) {
      if (ajaxCallProgress === 0) {
        if (
          tmpProfileReducer.status_id === 6 &&
          tmpProfileReducer.agent_status_id === 0
        ) {
          this.props.history.push({ pathname: `${tmpPath}/register/payment/` });
        }
      }
    }

    if (profileChecking) {
      if (
        [
          "Associated Agent",
          "Personal Assistant",
          "Admin",
          "Project Manager",
        ].indexOf(tmpProfileReducer.role) !== -1
      ) {
        this.props.getPermissions(tmpProfileReducer.role);
      }
    }

    if (
      ajaxCallProgressImpersonate !==
        prevProps.data.ajaxStatusReducer.ajaxCallProgressImpersonate &&
      ajaxCallProgressImpersonate === 0
    ) {
      if (this.state.from === "IMPERSONATE_BEGIN" && ajaxSuccessImpersonate) {
        this.setState(
          {
            onLoadImpersonateProfile: false,
            from: "",
          },
          () => {
            requestSuccess(
              `You are now impersonating ${tmpProfileReducer.full_name || tmpProfileReducer.display_name}`
            );
          }
        );
      }
      if (ajaxErrorImpersonate) {
        this.setState({
          onLoadImpersonateProfile: false,
          from: "",
        });
        requestError(ajaxErrorMessageImpersonate);
      }
    }
  };

  onChangeTemplate = (value, context) => this.setState({ [context]: value });

  markAnnouncementAsRead = (id) => this.props.markAnnouncementAsRead(id);

  markNotificationAsRead = (id) => this.props.markNotificationAsRead(id);

  handleReleaseModalClose = () => this.setState({ isOpenReleaseModal: false });

  render = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const {
      content,
      history,
      getSSOToken,
      unreadNotification,
      showUnreadDetailModal,
      selectedUnreadNotification,
      onChangeNotificationHOC,
      unreadNotificationPage,
    } = this.props;
    const { status } = tmpProfileReducer;
    const { totalCount } = this.props.data.myCartReducer;
    const { sideMenuExpand } = this.props.data.sideMenuReducer;

    const idPath = window.location.href.includes("/admin-impersonate")
      ? this.props.data.adminSignInReducer.prefix_path
      : "";

    return (
      <>
        {status !== "Pending" && (
          <>
            <Sidebar
              expand={sideMenuExpand}
              cartCount={totalCount}
              history={history}
              idPath={idPath}
              getSSOToken={getSSOToken}
              data={this.props.data}
            />
            <MobileSidebar
              expand={sideMenuExpand}
              history={history}
              idPath={idPath}
              cartCount={totalCount}
              getSSOToken={getSSOToken}
              data={this.props.data}
              onCloseSidebarModal={() =>
                this.props.toggleSideMenu(!sideMenuExpand)
              }
            />
            <div
              className={`main-panel ${sideMenuExpand ? "menu-expand" : "menu-shrink"}`}
            >
              <Topbar
                history={history}
                idPath={idPath}
                sendLogoutRequest={this.props.sendLogoutRequest}
                adminLogOut={() => {
                  this.setState(
                    {
                      from: "admin_logout",
                    },
                    () => {
                      this.props.adminLogOut();
                    }
                  );
                }}
                profileData={tmpProfileReducer}
                appSettingReducer={this.props.data.appSettingReducer}
                adminSignInReducer={this.props.data.adminSignInReducer}
                onLoadModalNotification={this.props.onLoadModalNotification}
                getUnreadNotifications={this.props.getUnreadNotifications}
                toggleSidebarExpand={() =>
                  this.props.toggleSideMenu(!sideMenuExpand)
                }
                markNotificationAsRead={(id) => this.markNotificationAsRead(id)}
                markAllNotificationAsRead={this.props.markAllNotificationAsRead}
                unreadNotificationPage={unreadNotificationPage}
                unreadNotification={unreadNotification}
                updateAppSetting={this.props.updateAppSetting}
                getMOTDTrigger={this.props.getMOTDTrigger}
              />
              <div
                ref={this.dashboardRef}
                onScroll={() =>
                  this.setState({
                    scrollTop: this.dashboardRef.current.scrollTop,
                  })
                }
                className="content at-content"
              >
                {content(this.state.scrollTop)}
              </div>
            </div>
          </>
        )}
        {showUnreadDetailModal && (
          <Dialog open={true} classes={{ paper: "m-0" }} maxWidth={"md"}>
            <NotificationDetailsModal
              closeDialog={() =>
                onChangeNotificationHOC(false, "showUnreadDetailModal")
              }
              selectedNotification={selectedUnreadNotification}
            />
          </Dialog>
        )}
        {status === "Pending" && (
          <>
            {tmpProfileReducer.agent_status_id === 1 && (
              <WelcomeModal isOpen={true} history={history} />
            )}
            {tmpProfileReducer.agent_status_id === 2 && (
              <TrainingStatusModal isOpen={true} history={history} />
            )}
            {tmpProfileReducer.agent_status_id === 3 && (
              <AppointmentLetterModal history={history} />
            )}
          </>
        )}
        {this.props.showMOTD && (
          <MotdOverlay
            onChangeMOTDModalHOC={this.props.onChangeMOTDModalHOC}
            motd={this.props.motd}
            onLoad={this.props.onLoadMOTD}
            updateMOTDActivity={this.props.updateMOTDActivity}
          />
        )}
        {this.props.onLoadLogoutRequest &&
          this.state.onLoadImpersonateProfile && <LoadingModal />}
        {this.state.loading && <BackgroundLoader showLoadingOverlay={true} />}
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default compose(
  connect(mapStateToProps, {
    adminLogOut,
    getMyCart,
    toggleSideMenu,
    getCurrentSignInProfile,
    updateLastOpenMotd,
    updateAppSetting,
    getPermissions,
  }),
  AnnouncementHOC,
  NotificationHOC,
  WithLogout,
  WithToken,
  MotdHOC
)(DashboardTemplate);
