import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Post, Put } from "utils/axios";
import { storeLastView } from "actions/lastView";

const searchParams = [
  {
    label: "Board Registration No.",
    value: "registration_number_cont",
    type: "input",
    col: 4,
    param: "",
  },
  {
    label: "Agency Name",
    value: "name_cont",
    type: "input",
    col: 4,
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    type: "radio",
    col: 4,
    param: "",
    options: [
      {
        label: "All",
        value: ""
      },
      {
        label: "Pending",
        value: 1
      },
      {
        label: "Active",
        value: 2
      },
      {
        label: "Inactive",
        value: 3
      },
    ]
  },
  {
    label: "SST Registered",
    value: "sst_registered_eq",
    type: "radio",
    col: 4,
    param: "",
    options: [
      {
        label: "All",
        value: ""
      },
      {
        label: "Yes",
        value: true
      },
      {
        label: "No",
        value: false
      },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      showCreateDialog: false,
      showEditDialog: false,

      coAgencyPages: [],
      coAgency: {},
      selectedAgency: null,
      searchParams: searchParams,
    };

    load = (param) => this.setState({ loading: param });

    onChangeCoAgencyHOC = (val, context) => this.setState({ [context]: val });

    getCoAgency = (page, search) =>
      Get(
        `/admin/agencies?${search}page=${page}`,
        (payload) => this.getCoAgencySuccess(payload, page, search),
        this.getCoAgencyError,
        this.load,
      );
    getCoAgencySuccess = (payload, page, search) => {
      let tempPages = [];
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);

      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        coAgency: payload,
        coAgencyPages: tempPages,
      });
    };
    getCoAgencyError = (error) => requestError(error);

    getSelectedAgency = (id) =>
      Get(
        `/admin/agencies/${id}`,
        this.getSelectedAgencySuccess,
        this.getSelectedAgencyError,
        this.load,
      );
    getSelectedAgencySuccess = (payload) =>
      this.setState({
        selectedAgency: payload,
        showEditDialog: true,
      });
    getSelectedAgencyError = (error) => requestError(error);

    updateAgency = (dataToSubmit) =>
      Put(
        `/admin/agencies/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateAgencySuccess,
        this.updateAgencyError,
        this.load,
      );
    updateAgencySuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getCoAgency(currentPage, searchParams);
      requestSuccess("Agency information updated successfully.");
    };
    updateAgencyError = (error) => requestError(error);

    createAgency = (dataToSubmit) =>
      Post(
        `/admin/agencies`,
        dataToSubmit,
        this.createAgencySuccess,
        this.createAgencyError,
        this.load,
      );
    createAgencySuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      this.getCoAgency(currentPage, searchParams);
      this.setState({ showCreateDialog: false });
      requestSuccess("Agency created successfully.");
    };
    createAgencyError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            selectedAgency={this.state.selectedAgency}
            showEditDialog={this.state.showEditDialog}
            showCreateDialog={this.state.showCreateDialog}
            searchParams={this.state.searchParams}
            coAgency={this.state.coAgency}
            coAgencyPages={this.state.coAgencyPages}
            onLoadCoAgency={this.state.loading}
            getCoAgency={this.getCoAgency}
            updateAgency={this.updateAgency}
            createAgency={this.createAgency}
            getSelectedAgency={this.getSelectedAgency}
            onChangeCoAgencyHOC={this.onChangeCoAgencyHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(WithHOC);
};

export default HOC;
