import React, { useState, useCallback, useMemo } from "react";
import _ from "lodash";

import CustomSelect from "components/Select";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";

const MediaType = [
  { id: 1, label: "Matterport" },
  { id: 2, label: "Youtube" },
];

export default function Form({
  onLoadMediaLink,

  onClose,
  onSubmit,
}) {
  const urlRegex =
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  const [formData, onChangeFormData] = useState({
    type_id: null,
    title: "",
    url: "",
  });

  const requiredCheck = useMemo(() => {
    return (
      _.values(formData).some((item) => !item) || !urlRegex.test(formData.url)
    );
  }, [formData]);

  let onChangeField = useCallback(
    (val, context) => {
      let temp = _.cloneDeep(formData);
      temp[context] = val;

      onChangeFormData(temp);
    },
    [formData],
  );

  return (
    <>
      <ModalDialog
        title={"New Media Link"}
        children={
          <div className="grid-control">
            <section className="grid-full-col">
              <h2 className="at-form-input__title" required>
                {"Title"}
              </h2>
              <CustomFormInput
                value={formData.title}
                required={true}
                onChangeValue={(val) => onChangeField(val, "title")}
              />
            </section>
            <section className="grid-full-col row">
              <div className="col-md-4">
                <h2 className="at-form-input__title" required>
                  {"Media Type"}
                </h2>
                <CustomSelect
                  className="w-100"
                  required={true}
                  valueKey={"id"}
                  selectItems={MediaType}
                  updateSelect={(item) => onChangeField(item.id, "type_id") }
                  currentlySelected={_.find(MediaType, {
                    id: formData.type_id,
                  })}
                />
              </div>
              <div className="col-md-8">
                <h2 className="at-form-input__title" required>
                  {"URL"}
                </h2>
                <CustomFormInput
                  value={formData.url}
                  inputError={
                    formData.url
                      ? urlRegex.test(formData.url)
                        ? ""
                        : "Incorrect URL format."
                      : ""
                  }
                  required={true}
                  onChangeValue={(val) => onChangeField(val, "url")}
                />
              </div>
            </section>
          </div>
        }
        footer={
          <div className="d-flex g-3">
            <button
              type={"button"}
              disabled={requiredCheck}
              className="btn-new btn-new--primary"
              onClick={() => onSubmit(formData)}
            >
              Submit
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        }
        onClose={onClose}
      />
      {onLoadMediaLink && <LoadingModal />}
    </>
  );
}
