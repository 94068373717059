import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put, Post } from "utils/axios";
import { storeSubsalesAddress } from "actions/subsales/address";
import { getAllDictionaryData } from "actions/dictionary";
import { storeLastView } from "actions/lastView";
import { getBranches } from "actions/branch";
import { refreshToken } from "actions/login";

const ClaimTypeOptions = [
  { value: "1", label: "Claim Commission" },
  { value: "2", label: "Refund" },
  { value: "3", label: "Forfeit" },
  { value: "4", label: "Renewal" },
  { value: "5", label: "Release" },
  { value: "6", label: "Cancel Form" },
  { value: "7", label: "Request Invoice" },
  { value: "8", label: "Request Official Receipt" },
  { value: "9", label: "Request TA Stamping" },
];

const radioFilters = [
  {
    label: "All",
    type: "radio",
    value: "",
  },
  {
    label: "Submitted",
    type: "radio",
    value: "1",
  },
  {
    label: "Processing",
    type: "radio",
    value: "2",
  },
  {
    label: "Cancelled",
    type: "radio",
    value: "3",
  },
  {
    label: "Paid",
    type: "radio",
    value: "4",
  },
  {
    label: "Completed",
    type: "radio",
    value: "5",
  },
  {
    label: "Rejected",
    type: "radio",
    value: "6",
  },
  {
    label: "Reviewing",
    type: "radio",
    value: "7",
  },
];

const searchParams = [
  {
    label: "Confirmation Form Number",
    type: "input",
    value: `confirmation_form_number_cont`,
    param: "",
  },
  {
    label: "Property Address",
    type: "input",
    value: "property_address_cont",
    param: "",
  },
  {
    label: "Unit ID",
    type: "input",
    value: "unit_id_eq",
    param: "",
  },
  {
    label: "Submission Type",
    value: "sub_sale_claim_submissions_type_id_eq",
    param: "",
    type: "select",
    options: ClaimTypeOptions,
  },
  {
    label: "Status",
    value: "case_closed_true",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "0", label: "Open" },
      { value: "1", label: "Closed" },
    ],
  },
  {
    label: "Is urgent",
    value: "sub_sale_claim_submissions_is_urgent_eq",
    type: "radio",
    param: "",
    options: [
      { label: "All", value: "" },
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
  {
    label: "Submission Status",
    value: "sub_sale_claim_submissions_status_id_eq",
    type: "radio",
    col: 12,
    param: "",
    options: radioFilters,
  },
  {
    label: "Representative",
    value: "representative_id_eq",
    type: "radio",
    col: 12,
    param: "",
    options: [
      {
        id: "",
        label: "All",
        value: "",
      },
      {
        id: 1,
        label: "Represent Landlord/Vendor",
        value: 1,
      },
      {
        id: 2,
        label: "Represent Tenant/Purchaser",
        value: 2,
      },
      {
        id: 3,
        label: "Represent Both",
        value: 3,
      },
      {
        id: 4,
        label: "None",
        value: true,
      },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      claims: {
        data: [],
        meta: {
          page: 1,
        },
      },
      totalPages: [],
      activities: [],
      townshipsList: [],
      selected_claim: {},
      selectedType: "Sale",
      loading: false,
      showCreate: false,
      showExternalAgent: false,
      showUpdateClaimFormModal: false,
      showAssignContactModal: false,

      searchParams: searchParams,
    };

    load = (param) => this.setState({ loading: param });

    onChangeSubsaleClaimsHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeUpdateSelectedClaim = (key, val) => {
      let tmp = _.cloneDeep(this.state.selected_claim);
      tmp[key] = val;
      if (key === "admin_fee_amount") {
        this.calculateResult(key, val);
      }
      return this.setState({ selected_claim: tmp });
    };

    calculateResult = (context, val) => {
      let res = 0;
      if (context === "admin_fee_amount") {
        res = parseFloat(val) * 0.06;
        this.onChangeUpdateSelectedClaim("sales_tax_amount", res.toFixed(2));
      }
    };

    getClaimsList = (page, query = '') => {
      
      let tmp = _.cloneDeep(query);

      if(tmp.includes("q[representative_id_eq]=true")) {
        tmp = _.replace(
          tmp,
          `q[representative_id_eq]=true`,
          `q[representative_id_null]=true`,
        );
      }

      let temp = {
        currentPage: page,
        searchParams: tmp,
      };
      this.props.storeLastView(temp);

      Get(
        `/sub_sale_claims?${tmp}page=${page}`,
        this.getClaimsListSuccess,
        this.getClaimsListError,
        this.load,
      );
    };
    getClaimsListSuccess = (payload) => {
      let tmpTotalPages = [];
      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      let tempData = _.map(payload.data, (item) => {
        let tempSubmission = _.map(item.sub_sale_claim_submissions, (sub) => ({
          ...sub,
          submitted_date: sub.submitted_date
            ? Moment(sub.submitted_date, "DD-MM-YYYY HH:mm").format(
                "DD MMM YYYY HH:mm",
              )
            : "N/A",
        }));

        return {
          ...item,
          sub_sale_claim_submissions: tempSubmission,
        };
      });
      this.setState({
        claims: {
          data: tempData,
          meta: payload.meta,
        },
        totalPages: tmpTotalPages,
      });
    };
    getClaimsListError = (error) => requestError(error);

    getSelectedTownShip = (id) =>
      Get(
        `/townships?state_id=${id}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) =>
      this.setState({
        townshipsList: _.map(payload, (town) => ({
          ...town,
          value: town.name,
        })),
      });
    getSelectedTownShipError = (error) => requestError(error);

    submitSelectedClaim = (id) =>
      Put(
        `/sub_sale_claims/${id}/submit`,
        {},
        this.submitSelectedClaimSuccess,
        this.submitSelectedClaimError,
        this.load,
      );
    submitSelectedClaimSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getClaimsList(currentPage, searchParams);
      requestSuccess("Claim had been submitted successfully!");
      this.getSelectedClaim(payload.id);
    };
    submitSelectedClaimError = (error) => requestError(error);

    getSelectedClaim = (id) =>
      Get(
        `/sub_sale_claims/${id}`,
        this.getSelectedClaimSuccess,
        this.getSelectedClaimError,
        this.load,
      );
    getSelectedClaimSuccess = (payload) => {
      let tmp = _.cloneDeep(payload);
      tmp.property_location_attributes = { ...tmp.property_location };
      delete tmp.property_location;
      const {
        property_name = "",
        postcode = "",
        built_up = 0,
        land_area = 0,
        category_id = "",
        property_type_id = "",
        tenure_type_id = "",
        unit_type_id = "",
      } = tmp.property_location_attributes;

      return this.setState({
        selected_claim: {
          ...tmp,
          external_agency_bank_id: [0, null].indexOf(tmp.external_agency_bank_id) === -1 
            ? tmp.external_agency_bank_id 
            : "",
          claim_status: "Draft",
          property_type_id: category_id,
          property_location_attributes: {
            ...tmp.property_location_attributes,
            property_name: property_name,
            postcode: postcode,
            built_up: built_up,
            land_area: land_area,
            category_id: category_id,
            property_type_id: property_type_id,
            tenure_type_id: tenure_type_id,
            unit_type_id: unit_type_id,
          },
        },
        showUpdateClaimFormModal: true
      });
    };
    getSelectedClaimError = (error) => requestError(error);

    updateSelectedClaim = (id, dataToSubmit, enableExternalAgentBankValidation) => {
      const { property_address = "" } = dataToSubmit;
      this.setState({ enableValidateBank: false })

      if (property_address !== "") {
        this.props.storeSubsalesAddress(dataToSubmit.property_address);
      }
      // All forms which can add External Agency need to validate external agent bank info under condition:
      // Bank fields of External Agent cannot be empty if external agent is selected, stakeholder type is IQI and commission fee cant be empty
      let validateIqiStakeholder = (dataToSubmit.external_agency_id > 0 && dataToSubmit.stakeholder_type_id === 1) && dataToSubmit.revised_co_agency_fee_amount > 0
      let checkExternalAgentBankInfoError = (!(dataToSubmit.external_agency_bank_id > 0) || dataToSubmit.external_agency_bank_account_number === "")
      if(enableExternalAgentBankValidation) {
        if(validateIqiStakeholder && checkExternalAgentBankInfoError) {
          this.setState({ showExternalAgent: true, enableValidateBank: true })
        } else {
          Put(
            `/sub_sale_claims/${id}`,
            {
              ...dataToSubmit,
              revised_co_agency_fee_amount: dataToSubmit?.revised_co_agency_fee_amount > 0 
                ? dataToSubmit?.revised_co_agency_fee_amount
                : 0
            },
            this.updateSelectedClaimSuccess,
            this.updateSelectedClaimError,
            this.load,
          );
        }
      } else {
        Put(
          `/sub_sale_claims/${id}`,
          {
            ...dataToSubmit,
            revised_co_agency_fee_amount: dataToSubmit?.revised_co_agency_fee_amount > 0
              ? dataToSubmit?.revised_co_agency_fee_amount
              : 0
          },
          this.updateSelectedClaimSuccess,
          this.updateSelectedClaimError,
          this.load,
        );
      }
    };
    updateSelectedClaimSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.setState({ showExternalAgent: false })
      this.getClaimsList(currentPage, searchParams);
      requestSuccess("Claim updated successfully.");
      this.getSelectedClaim(payload.id);
    };
    updateSelectedClaimError = (error) => requestError(error);

    submitForm = (dataToSubmit) =>
      Post(
        `/forms/confirmation_forms`,
        dataToSubmit,
        this.submitFormSuccess,
        this.submitFormError,
        this.load,
      );
    submitFormSuccess = (payload) => requestSuccess(payload.message);
    submitFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showCreate={this.state.showCreate}
            enableValidateBank={this.state.enableValidateBank}
            searchParams={this.state.searchParams}
            claims={this.state.claims}
            totalPages={this.state.totalPages}
            activities={this.state.activities}
            selected_claim={this.state.selected_claim}
            onLoadClaims={this.state.loading}
            showExternalAgent={this.state.showExternalAgent}
            showUpdateClaimFormModal={this.state.showUpdateClaimFormModal}
            showAssignContactModal={this.state.showAssignContactModal}
            townshipsList={this.state.townshipsList}
            getSelectedTownShip={this.getSelectedTownShip}
            onChangeUpdateSelectedClaim={this.onChangeUpdateSelectedClaim}
            assignContact={this.assignContact}
            getSelectedClaim={this.getSelectedClaim}
            getClaimsList={this.getClaimsList}
            updateSelectedClaim={this.updateSelectedClaim}
            onChangeSubsaleClaimsHOC={this.onChangeSubsaleClaimsHOC}
            submitSelectedClaim={this.submitSelectedClaim}
            submitForm={this.submitForm}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    getAllDictionaryData,
    storeSubsalesAddress,
    storeLastView,
    refreshToken,
    getBranches,
  })(WithHOC);
};

export default HOC;
