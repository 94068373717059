import React, { useEffect, useState } from "react";
import _ from "lodash";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import DeleteConfirmation from "components/Modal/delete";
import PublicHoliday from "./PublicHoliday";
import BookingLimit from "./BookingLimit";
import MeetingRoomForm from "./form";

import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";
import defaultImage from "assets/images/iqi_logo_only.png";
import MeetingHubHOC from "./actions";
import "./index.scss";

const columnData = [
  {
    header: "Meeting Room Name",
    renderColumn: (rowData) => (
      <div className="at-meeting-room__image">
        <img
          src={rowData.image_url || defaultImage}
          alt={`meeting room ${rowData.id}`}
        />
        {rowData.name}
      </div>
    ),
  },
  { header: "Branch", accessor: "location" },
  {
    header: "Capacity",
    renderColumn: (rowData) => `${rowData.capacity} seats`,
  },
  {
    header: "Type",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => (
      <div
        className="at-status_badge"
        style={{
          ...getColorBadge(rowData?.meeting_room_type === "Business Lounge" ? "Yellow" : "Blue"),
        }}
      >
        {rowData?.meeting_room_type ?? "N/A"}
      </div>
    ),
  },
  {
    header: "Availability",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => (
      <div
        className="at-status_badge"
        style={{
          ...getColorBadge(rowData?.status === "Available" ? "Green" : ""),
        }}
      >
        {rowData?.status ?? "N/A"}
      </div>
    ),
  },
];

const MeetingHub = (props) => {
  const manageMentPermission = permissionsChecker("Meeting Hub - Room Management", props.data);
  const holidayPermission = permissionsChecker("Public Holiday", props.data);

  const [tabOptions, setTabOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState("Room Management");

  useEffect(() => {
    let temp = []
    if (manageMentPermission.can_read) {
      temp = ["Room Management", "Settings"]
    }
    if (holidayPermission.can_read) {
      temp.splice(1, 0, "Public Holiday")
    }

    setTabOptions(temp)
    props.getBranches();
    props.getMeetingRoom(1, "");
    props.getAllDictionaryData();
  }, []);

  useEffect(() => {
    let temp = _.cloneDeep(props.searchParams);
    temp[1].options = props.branches;
    props.onChangeMeetingHubHOC(temp, "searchParams");
  }, [props.branches]);

  const renderCheckBoxActions = () => (
    <div className="at-meeting-room__table-title">
      <h4>Meeting Room</h4>
      {manageMentPermission.can_create && (
        <button
          className="btn-new-sm btn-new--primary ml-2"
          onClick={() => props.onChangeMeetingHubHOC(true, "showCreateModal")}
        >
          <AtlasIcon svgHref={"atlas-add-linear"} />
          New Room
        </button>
      )}
    </div>
  );

  const renderMeetingRooms = () => (
    <>
      <AtPaginatedTable
        columns={columnData}
        rowData={props.meetingRoom || []}
        meta={props.meetingRoomMeta}
        actionColumnContent={[
          {
            name: "edit",
            onShow: () => manageMentPermission.can_update,
            onClick: (rowData) => props.getSelectedMeetingRoom(rowData.id),
          },
          {
            name: "delete",
            onShow: () => manageMentPermission.can_destroy,
            onClick: (rowData) =>
              Promise.all([
                props.onChangeMeetingHubHOC(rowData, "selectedMeetingRoom"),
              ]).then(() =>
                props.onChangeMeetingHubHOC(true, "showDeleteConfirmation")
              ),
          },
        ]}
        searchParams={props.searchParams}
        onChangeSearchParams={(val) =>
          props.onChangeMeetingHubHOC(val, "searchParams")
        }
        getListAPI={props.getMeetingRoom}
        totalPages={props.meetingRoomPage}
        renderCheckBoxActions={renderCheckBoxActions}
      />
      {props.showCreateModal && (
        <ModalDialog
          title={"New Meeting Rooms"}
          fullWidth={true}
          fullHeight={true}
          onLoad={props.onLoadMeetingHub}
          onClose={() => props.onChangeMeetingHubHOC(false, "showCreateModal")}
          children={
            <MeetingRoomForm
              {...props}
              mode={"create"}
              onClose={() =>
                props.onChangeMeetingHubHOC(false, "showCreateModal")
              }
            />
          }
        />
      )}
      {props.showUpdateModal && (
        <ModalDialog
          title={"Update Meeting Rooms"}
          fullWidth={true}
          fullHeight={true}
          onLoad={props.onLoadMeetingHub}
          onClose={() => props.onChangeMeetingHubHOC(false, "showUpdateModal")}
          children={
            <MeetingRoomForm
              {...props}
              mode={"update"}
              onClose={() =>
                props.onChangeMeetingHubHOC(false, "showUpdateModal")
              }
            />
          }
        />
      )}
      <DeleteConfirmation
        open={props.showDeleteConfirmation}
        title={"Delete Meeting Room"}
        message={"Are you sure you want to remove this meeting room?"}
        negativeAction={() =>
          props.onChangeMeetingHubHOC(false, "showDeleteConfirmation")
        }
        positiveAction={() =>
          props.deleteMeetingRoom(props.selectedMeetingRoom.id)
        }
      />
    </>
  );

  return (
    <>
      <ModuleHeader title={"Meeting Rooms"} atlasIcon={"atlas-meeting-rooms"} />
      <CustomCard
        cardContent={
          <>
            <CustomTab
              sections={tabOptions}
              selectedSection={selectedSection}
              onSelectSection={setSelectedSection}
            />
            {selectedSection === "Room Management" && renderMeetingRooms()}
            {selectedSection === "Public Holiday" && <PublicHoliday branches={props.branches} />}
            {selectedSection === "Settings" && <BookingLimit branches={props.branches} />}
          </>
        }
      />
      {props.onLoadMeetingHub && <LoadingModal />}
    </>
  );
};

export default MeetingHubHOC(MeetingHub);