import React, { Component } from "react";
import _ from "lodash";
import { Get } from "utils/axios";

import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: [],
      loading: false,

      reassignSubmissions: {},

      mediaLink: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeMediaLinkHOC = (val, context) => this.setState({ [context]: val });

    getMediaLinks = (id) => {
      Get(
        `/admin/sub_sales/${id}/media_links`,
        this.getMediaLinksSuccess,
        this.getMediaLinksError,
        this.load,
      );
    };
    getMediaLinksSuccess = (payload) => {
      this.setState({ mediaLink: payload });
    };
    getMediaLinksError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            mediaLink={this.state.mediaLink}
            onLoadMediaLink={this.state.loading}
            onChangeMediaLinkHOC={this.onChangeMediaLinkHOC}
            getMediaLinks={this.getMediaLinks}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
