import { useState } from "react";

import AtlasButton from "components/Button";
import AtlasPrevButton from "components/Button/prev";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import CustomFormInput from "components/Input/formInput";
import ConfirmationDialog from "components/Modal/confirmation";

import Content from "./Content";
import From from "./From";
import PreviewAndTest from "./PreviewAndTest";
import ScheduleDialog from "./Schedule";
import Subject from "./Subject";
import To from "./To";

const Update = ({
  selectedEmailCampaign,
  onChangeEmailCampaignsHOC,
  updateEmailCampaign,
}) => {
  const [dialog, setDialog] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [activePanel, setActivePanel] = useState("");

  const handleClose = () => {
    setDialog("");
    onChangeEmailCampaignsHOC("emailCampaignDialog", "");
    onChangeEmailCampaignsHOC("selectedEmailCampaign", {});
  };

  const allStepsCompleted = [
    "name",
    "sender_email",
    "sender_name",
    "email_list_ids",
    "subject",
    "preview_text",
    "email_template_id",
  ].every((value) => selectedEmailCampaign[value]);

  const renderScheduleDialog = () => (
    <ScheduleDialog
      handleClose={() => setDialog("")}
      handleUpdateDialogClose={handleClose}
      selectedEmailCampaign={selectedEmailCampaign}
      updateEmailCampaign={updateEmailCampaign}
    />
  );

  const renderPreviewDialog = () => (
    <AtlasDialog open={dialog === "preview"}>
      <CustomCard
        cardContent={
          <PreviewAndTest
            handleClose={() => setDialog("")}
            selectedEmailCampaign={selectedEmailCampaign}
          />
        }
      />
    </AtlasDialog>
  );

  const renderExitDialog = () => (
    <ConfirmationDialog
      open={showConfirmationDialog}
      title={"Exit"}
      message={"Please make sure you have saved all the changes before exit."}
      positiveAction={handleClose}
      negativeAction={() => setShowConfirmationDialog(false)}
      positiveText={"Exit"}
      negativeText={"Cancel"}
    />
  );

  const panelProps = {
    handleAccordionChange: (panel) => () => setActivePanel(panel),
    handleAccordionClose: () => setActivePanel(""),
    selectedEmailCampaign,
    onChangeEmailCampaignsHOC,
    updateEmailCampaign,
  };

  return (
    <>
      <div className={"at-card__header"}>
        <AtlasPrevButton onClick={handleClose} />
        <h5 className={"at-card__title"}>Edit Email Campaign</h5>
      </div>

      <div className={"mb-3"}>
        <label htmlFor={"name"} className={"at-form-input__title"} required>
          Campaign Name
        </label>
        <CustomFormInput
          type={"text"}
          value={selectedEmailCampaign.name || ""}
          onChangeValue={(val) => {
            onChangeEmailCampaignsHOC("selectedEmailCampaign", {
              ...selectedEmailCampaign,
              name: val,
            });
          }}
          maxLength={128}
          required={true}
        />

        <small
          className={"position-relative form-text text-muted"}
          style={{ top: -12 }}
        >
          {selectedEmailCampaign.name ? selectedEmailCampaign.name.length : 0}
          /128
        </small>

        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--success"}
          onClick={() => {
            updateEmailCampaign({
              ...selectedEmailCampaign,
              status: 1,
            });
          }}
        >
          Save
        </AtlasButton>
      </div>

      <div className={"mb-4"}>
        <From isActive={activePanel === "from"} {...panelProps} />
        <To isActive={activePanel === "to"} {...panelProps} />
        <Subject isActive={activePanel === "subject"} {...panelProps} />
        <Content isActive={activePanel === "content"} {...panelProps} />
      </div>

      <div className={"d-flex g-3"}>
        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--primary"}
          disabled={!allStepsCompleted}
          onClick={() => setDialog("schedule")}
          tooltip={true}
          tooltipChildren={
            "Please complete all required steps to schedule a campaign."
          }
          tooltipPosition={"top"}
          tooltipID={"schedule-email-campaign"}
        >
          Schedule
        </AtlasButton>

        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--secondary"}
          disabled={!allStepsCompleted}
          onClick={() => setDialog("preview")}
          tooltip={true}
          tooltipChildren={
            "Please complete all required steps to see the preview & send test email."
          }
          tooltipPosition={"top"}
          tooltipID={"preview-and-test-email-campaign"}
        >
          Preview &amp; Test
        </AtlasButton>

        <AtlasButton
          type={"button"}
          className={"btn-new btn-new--outline-secondary"}
          onClick={() => setShowConfirmationDialog(true)}
        >
          Close
        </AtlasButton>
      </div>

      {dialog === "schedule" && renderScheduleDialog()}
      {renderPreviewDialog()}
      {renderExitDialog()}
    </>
  );
};

export default Update;
