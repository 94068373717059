import React, { Component } from "react";
import _, { cloneDeep } from "lodash";
import { Grid } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import AtlasIcon from "components/Icon/atlasIcon";
import AlertBox from "components/AlertBox";
import CustomInput from "components/Input/formInput";
import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasConfirmationForm from "components/Modal/confirmation";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 20,
  },
}))(Tooltip);

const ClaimTypeOptions = [
  { id: 1, name: "Claim Commission", value: 1, label: "Claim Commission" },
  { id: 2, name: "Refund", value: 2, label: "Refund" },
  { id: 3, name: "Forfeit", value: 3, label: "Forfeit" },
  { id: 4, name: "Renewal", value: 4, label: "Renewal" },
  { id: 5, name: "Release", value: 5, label: "Release" },
  { id: 6, name: "Cancel Form", value: 6, label: "Cancel Form" },
  { id: 7, name: "Request Invoice", value: 7, label: "Request Invoice" },
  {
    id: 8,
    name: "Request Official Receipt",
    value: 8,
    label: "Request Official Receipt",
  },
  {
    id: 9,
    name: "Request TA Stamping",
    value: 9,
    label: "Request TA Stamping",
  },
];

const getRecipients = (representative_id, sub_sale_type_id, external_agency_id) => {
  let tmp = [];
  if ([1, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_owner", label: sub_sale_type_id === 1 ? "Vendor" : "Landlord" });
  }
  if ([2, 3, null].indexOf(representative_id) > -1) {
    tmp.push({ value: "issue_to_client", label: sub_sale_type_id === 1 ? "Purchaser" : "Tenant" });
  }
 
  if (external_agency_id && external_agency_id !== null) {
    tmp.push({ value: "issue_to_agency", label: "Co-Agency" });
  }

  return tmp;
};

const ClaimParty = (sub_sale_type_id) => [
  {
    id: 1,
    value: 1,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Vendor' : 'Landlord' } portion`,
  },
  {
    id: 2,
    value: 2,
    label: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
    name: `Claim Commission from ${ sub_sale_type_id === 1 ? 'Purchaser' : 'Tenant' } portion`,
  }
]

const RepresentativeOptions = [
  { value: 0, label: "Vendor/Landlord", id: 0 },
  { value: 1, label: "Purchase/Tenant", id: 1 },
];

class Create extends Component {
  state = {
    issueToArray: [],
    newSubmission: {
      type_id: "",
      description: "",
      claim_party_id: 0,
      issue_to_owner: false,
      issue_to_client: false,
      issue_to_agency: false,
    },
    showTooltip: false
  };

  onChangeNewSubmission = (val, context) => {
    let tmp = _.cloneDeep(this.state.newSubmission);
    tmp[context] = val;

    return this.setState({ newSubmission: tmp });
  };

  onChangeType = (val) => {
    let tmp = _.cloneDeep(this.state.newSubmission);
    tmp.type_id = val;
    tmp.issue_to_owner = false;
    tmp.issue_to_client = false;
    tmp.issue_to_agency = false;
    if (val !== 2 && tmp.refund_details_attributes) {
      delete tmp.refund_details_attributes;
    }

    if ([7, 8].indexOf(val) > -1) {
      const tempIssueToArray = getRecipients(this.props.selected_claim.representative_id, this.props.selected_claim.sub_sale_type_id, this.props.selected_claim.external_agency_id) || [];
      
      this.setState({
        issueToArray: tempIssueToArray
      });
    }

    return this.setState({ newSubmission: tmp });
  };

  onCloseModalDialog = () => {
    if(this.props.isCreateSubmissionError) {
      this.props.onChangeSubmissionHOC("showCloseConfirmation", true)
    } else {
      this.props.onChangeSubmissionHOC("showCreateSubmission", false)
    }
  }

  onCopyRemarks = () => {
    Promise.all([
      navigator.clipboard.writeText(this.state.newSubmission.description)
    ]).then(() => {
      this.setState({ showTooltip: true });
    })
  }

  renderRefundSubmission = () => {
    if(this.props.refundParticipants?.some(item => item.participants?.length > 0) ) {
      return (
        <>
          <section className="grid-full-col at-claim_submission-participants_cont">
            {this.props.refundParticipants.map((item, index) => {
              const { refund_details_attributes = [] } = this.state.newSubmission;
              const tmpAttributes = _.cloneDeep(refund_details_attributes);
  
              return (
                item.participants.length > 0 && (
                  <div
                    key={`participant-type-${index}`}
                    className="at-claim_submission-participant_item_cont"
                  >
                    <>
                      <p className="at-form-input__title">{item.type}</p>
                      <div className="d-flex flex-column grid_gap-2">
                        {item.participants.map((participant, nestedIndex) => {
                          let tmpItem = refund_details_attributes.find(
                            (attribute) =>
                              attribute.participant_id === participant.id,
                          );
                          let tmpIndex = tmpAttributes.findIndex(
                            (attribute) =>
                              attribute.participant_id === participant.id,
                          );
  
                          return (
                            <div
                              key={`participant-${nestedIndex}`}
                              className="at-claim_submission-participant d-flex flex-wrap grid_gap-2">
                              <CustomCheckbox
                                labelClassname="m-0"
                                contentStyle={{ fontWeight: 500 }}
                                content={participant.name}
                                checked={tmpItem}
                                onChangeCheckboxValue={(event) => {
                                  const tmpOriData = _.cloneDeep(tmpItem);
                                  if (!tmpOriData) {
                                    let tmp = {
                                      amount: 0,
                                      remark: "",
                                      ...tmpOriData,
                                      type_id: item.type_id,
                                      participant_type: item.participant_type,
                                      participant_id: participant.id,
                                    };
                                    tmpAttributes.push(tmp);
                                  } else {
                                    tmpAttributes.splice(tmpIndex, 1);
                                  }
                                  this.onChangeNewSubmission(
                                    tmpAttributes,
                                    "refund_details_attributes",
                                  );
                                }}
                              />
                              <CustomInput
                                disabled={!tmpItem}
                                type={"number"}
                                placeholder="Refund amount"
                                containerStyle={{ width: 200 }}
                                value={tmpAttributes[tmpIndex]?.amount || ""}
                                onChangeValue={(val) => {
                                  let tmpRefundAttributes =
                                    _.cloneDeep(tmpAttributes);
                                  tmpRefundAttributes[tmpIndex].amount = val;
                                  this.onChangeNewSubmission(
                                    tmpRefundAttributes,
                                    "refund_details_attributes",
                                  );
                                }}
                              />
                              <AtlasTextarea
                                rows={1}
                                placeholder={"Remark"}
                                containerClassName="at-claim_submission-participant-remark"
                                containerStyle={{
                                  width: "calc(100% - 482px - 2rem)",
                                  minWidth: 200,
                                }}
                                style={{ minHeight: 41 }}
                                disabled={!tmpItem}
                                value={tmpAttributes[tmpIndex]?.remark || ""}
                                onChangeValue={(val) => {
                                  let tmpRefundAttributes =
                                    _.cloneDeep(tmpAttributes);
                                  tmpRefundAttributes[tmpIndex].remark = val;
                                  this.onChangeNewSubmission(
                                    tmpRefundAttributes,
                                    "refund_details_attributes",
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  </div>
                )
              );
            })}
          </section>
        </>
      );
    }
    return (
      <div className="grid-full-col at-empty-state" style={{borderRadius: 8}}>
        <div className={`at-empty-state__icon`}>
          <AtlasIcon svgHref="atlas-document-text"/>
        </div>
        <h4 className="title">{`There is no participant available for the submission.`}</h4>
      </div>
    )
  };

  render = () => {
    return (
      <>
        <ModalDialog
          title={"Submit My Claims"}
          onClose={this.onCloseModalDialog}
          children={
            <form
              className="at-profile__container grid-control grid_gap-x-2"
              onSubmit={(e) => e.preventDefault()}
            >
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Submission Type"}</h2>
                <Grid container spacing={0} style={{ marginTop: 0 }}>
                  {ClaimTypeOptions.map((item) => {
                    return (
                      <Grid item key={item.id} md={4} xs={12} style={{ padding: "0px 8px" }}
                        className={"subsaleclaim-claimform-submitclaims-create-radiogrpclaimtypes"}>
                        <input
                          className="at-radio__input"
                          type="radio"
                          key={item.id}
                          id={item.value}
                          value={item.value}
                          checked={
                            item.value === this.state.newSubmission.type_id
                          }
                          readOnly={true}
                        />
                        <label
                          className="at-radio__label m-0"
                          onClick={() => {
                            this.onChangeType(item.value);
                            if([2, 3, 5].indexOf(item.value) > -1) {
                              this.props.getRefundParticipants(
                                this.props.selected_claim.id,
                                item.value
                              );
                            }
                          }}>
                          <div className="at-radio__checker"></div>
                          <p className="at-radio__content">{item.label}</p>
                        </label>
                      </Grid>
                    );
                  })}
                </Grid>
              </section>
              {[7, 8].indexOf(this.state.newSubmission.type_id) !== -1 && (
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">Issue To</h2>
                  {this.state.issueToArray.map((item) => (
                    <CustomCheckbox
                      key={item.value}
                      content={item.label}
                      checked={this.state.newSubmission[item.value]}
                      onChangeCheckboxValue={(event) =>{
                        this.onChangeNewSubmission(
                          event.target.checked,
                          item.value,
                        )}
                      }
                    />
                  ))}
                </section>
              )}
              {this.state.newSubmission.type_id !== "" &&
                this.state.newSubmission.type_id === 1 && (
                  <section className="grid-full-col">
                    <h2 className="at-form-input__title">{"Claim Party"}</h2>
                    <AtlasRadioGroup
                      mode="old"
                      options={ClaimParty(this.props.selected_claim.sub_sale_type_id)}
                      checkedValue={this.state.newSubmission.claim_party_id}
                      selectedRadioValue={(val) =>
                        this.onChangeNewSubmission(val, "claim_party_id")
                      }
                    />
                  </section>
                )}
              {[2, 3, 5].indexOf(this.state.newSubmission.type_id) > -1 &&
                this.renderRefundSubmission()}
              {!([2, 3, 5].indexOf(this.state.newSubmission.type_id) > -1) && (
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">{"Remark"}</h2>
                  <AtlasTextarea
                    rows={5}
                    className={"mb-0"}
                    value={this.state.newSubmission.description}
                    onChangeValue={(val) =>
                      this.onChangeNewSubmission(val, "description")
                    }
                  />
                </section>
              )}
              <section className="grid-full-col">
                <AlertBox
                  mode={"info"}
                  containerClassName="mb-3"
                  description={
                    <>
                      <p>
                        1. Before you clicked the button to submit, please check
                        all the supported documents had been uploaded for
                        approval.
                      </p>
                      <p>
                        2. Please state in the description as much details as
                        possible, for example Amount for Requested Invoice
                        (include/exclude SST), requested invoice for co-agency /
                        owner or other party, percentage of the claim commission
                        and etc.
                      </p>
                    </>
                  }
                />
              </section>
              {this.props.onLoadSubmissions && <LoadingModal />}
            </form>
          }
          footer={
            <div className="d-flex g-3">
              <button
                disabled={!this.props.showSubmitButton}
                className="btn-new btn-new--primary"
                onClick={() => this.props.onChangeSubmissionHOC("showConfirmCreateSubmission", true)}>
                Submit to Admin
              </button>
              <button
                type={"button"}
                className="btn-new btn-new--outline-secondary"
                onClick={this.onCloseModalDialog}>
                Cancel
              </button>
            </div>
          }
        />
        {
          this.props.showCloseConfirmation && (
            <ModalDialog
              title={"Copy Remarks"}
              responsiveSize={"sm"}
              onLoad={this.props.onLoadSubmissions}
              children={
                <>
                  <p className="fs-2">We noticed you entered remarks. To keep them for future reference, copy them to your clipboard before closing this window.</p>
                  <div className="mt-3">
                    <h2 className="at-form-input__title">Remarks</h2>
                    <AtlasTextarea
                      rows={5}
                      className={"mb-0"}
                      containerClassName={"w-100"}
                      value={this.state.newSubmission.description}
                      onChangeValue={(val) =>this.onChangeNewSubmission(val, "description")}
                    />
                  </div>
                </>
              }
              onClose={() => this.props.onCloseCopyRemarkConfirmation()}
              footer={
                <div className="d-flex g-3">
                  <DarkTooltip
                    open={this.state.showTooltip}
                    classes={{
                      tooltip: `tooltip-arrow top`,
                    }}
                    onClose={() => this.setState({ showTooltip: false })}
                    placement={"top"}
                    enterTouchDelay={50}
                    title={"Copied!"}>
                    <span
                      data-tip={"Copied!"}
                      data-for={"copy_remark"}>
                      <button
                        type={"button"}
                        className="btn-new btn-new--primary"
                        onClick={ () => this.onCopyRemarks() }>
                        Copy
                      </button>
                    </span>
                  </DarkTooltip>
                  <button
                    type={"button"}
                    className="btn-new btn-new--outline-secondary"
                    onClick={this.props.onCloseCopyRemarkConfirmation}>
                    Cancel
                  </button>
                </div>
              }
            />
          )
        }
        {this.props.onLoadSubmissions && <LoadingModal />}
        {/* Pop up confirmtion dialog to copy remark if submission failed */}
        <AtlasConfirmationForm
          open={this.props.showConfirmCreateSubmission}
          maxWidth={"sm"}
          message={
            <>
              {this.state.newSubmission.type_id !== 6 && (
                <span className="text-danger">
                  Please note that if this submission is made during the
                  weekend, public holidays, or after working hours, the request
                  will be processed by the admin on the next business day.
                  <br />
                  <br />
                </span>
              )}
              Would you like to proceed with submitting your request to the
              admin?
            </>
          }
          loading={this.props.onLoadSubmissions}
          positiveAction={() =>
            this.props.createSubmission(
              this.props.selected_claim.id,
              this.state.newSubmission,
            )
          }
          negativeAction={() =>
            this.props.onChangeSubmissionHOC(
              "showConfirmCreateSubmission",
              false,
            )
          }
        />
      </>
    );
  };
}

export default Create;
