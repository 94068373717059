import React, { Component } from "react";

import ModalDialog from "components/Modal/ModalDialog";

import "./index.scss";

class AppointmentLetter extends Component {
  render = () => {
    const {board_registration_type_id} = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (this.props.showAppointmentLetterModal) {
      return (
        <ModalDialog
          title={"My IQI Appointment Letter"}
          maxWidth={"md"}
          children={
            <p className="at-card__description mt-2">
              {board_registration_type_id === 2 && `This document is for internal use only and should not be submitted for PEA applications to BOVEAP. For any inquiries related to PEA appointment letter or application, please email rentag@iqiglobal.com, or contact your branch admin.`}
              {board_registration_type_id === 3 && `Please note that this document is for internal use only and serves as a record of the NDA. We will prepare a REA Contract Of Service for you to proceed with signing. For any inquiries regarding REA Contract or registration, or if you require a copy of your contract, please email rentag@iqiglobal.com or contact the admin in charge of REA.`}
              {![2, 3].includes(board_registration_type_id) && `This document is for internal use only. For any inquiries related to REN appointment letter or tag application, please email rentag@iqiglobal.com, or contact your branch admin.`}
            </p>
          }
          footer={
            <div className="d-flex align-items-center g-3">
              <button
                className="btn-new btn-new--primary"
                onClick={() => this.props.downloadDoc()}
              >
                Download
              </button>
              <button
                className="btn-new btn-new--secondary"
                onClick={() => this.props.sendEmail()}
              >
                Send to Email
              </button>
              <button
                className="btn-new btn-new--outline-secondary"
                onClick={() =>
                  this.props.onChangeProfileHOC(
                    false,
                    "showAppointmentLetterModal",
                  )
                }
              >
                Close
              </button>
            </div>
          }
          onClose={() =>
            this.props.onChangeProfileHOC(false, "showAppointmentLetterModal")
          }
        />
      );
    }
  };
}

export default AppointmentLetter;
