import React from "react";
import { Button, Tooltip } from "@material-ui/core";

import TelegramImage from "assets/images/telegram_support.png";

import "./index.scss";

const TelegramButton = () => {
  return (
    <Tooltip placement={"left"} title={"Talk to the IQI Global Bot!"}>
      <button
        className="at-support-group-btn telegram"
        onClick={() => window.open(`https://t.me/IqiGlobalBot`)}
        onTouchStart={() => window.open(`https://t.me/IqiGlobalBot`)}
      >
        <img src={TelegramImage} style={{ width: "40px", height: "40px" }} />
      </button>
    </Tooltip>
  );
};

export default TelegramButton;
