import React from "react";
import { Grid, Typography } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "../assets";
import "../index.scss";

const StepCounters = ({ bodyContentType, language }) => {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography>
          {getTranslation("agent.signup.subtitle", language)}
        </Typography>
      </Grid>
      <Grid item xs={12} className="step-counters-stepper-header">
        <Typography
          className={
            bodyContentType === 0 ? "step-counters-stepper-header-active" : ""
          }
        >
          {getTranslation("agent.signup.progress1", language)}
        </Typography>
        <i className="fa fa-angle-right at-header__angle" />
        <Typography
          className={
            bodyContentType === 1 ? "step-counters-stepper-header-active" : ""
          }
        >
          {getTranslation("agent.signup.progress2", language)}
        </Typography>
        <i className="fa fa-angle-right at-header__angle" />
        <Typography
          className={
            bodyContentType === 2 ? "step-counters-stepper-header-active" : ""
          }
        >
          {getTranslation("agent.signup.progress3", language)}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <div
          style={{ fontSize: "12px", marginTop: "15px", marginBottom: "5px" }}
        >
          {`${getTranslation('step', language)} ${bodyContentType + 1}`}
        </div>
        <div style={{ fontSize: "24px", fontWeight: "600", display: "block" }}>
          {getTranslation(bodyContentType === 0
            ? "agent.signup.progress1"
            : (bodyContentType === 1
              ? "agent.signup.progress2"
              : "agent.signup.progress3"
            ), language)}
        </div>
        <div className="register-stepper-icon-container">
          <AtlasIcon
            svgHref={"atlas-2-user"}
            className={"register-stepper-atlas-icon"}
            style={{
              display: bodyContentType !== 0 && "none",
            }}
          />
          <AtlasIcon
            svgHref={"atlas-user"}
            className={"register-stepper-atlas-icon"}
            style={{
              display: bodyContentType !== 1 && "none",
            }}
          />
          <AtlasIcon
            svgHref={"clipboard-tick"}
            className={"register-stepper-atlas-icon"}
            style={{
              display: bodyContentType !== 2 && "none",
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default StepCounters;
